import { FC } from 'react';

import MainPanel from './components/MainPanel';
import Shortcuts from './components/Shortcuts';
import OrderSearch from 'js/scenes/Home/scenes/OrderSearch';

const Home: FC = () => (
  <div className='home-page'>
    <MainPanel />
    <OrderSearch withFormTitle />
    <Shortcuts />
  </div>
);

export default Home;
