import { FC, useMemo } from 'react';
import { RootState } from 'js/store/reducer';

import FreightProductInfo from './components/FreightProductInfo';
import TableV2 from 'js/components/TableV2';

import { FreightProduct } from 'src/interfaces/interfaces.generated';
import t, { tu } from 'js/utils/translate';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate } from 'react-router-dom';

interface Props {
  freightProducts: FreightProduct[];
}

const FreightProductsTable: FC<Props> = ({ freightProducts }) => {
  const navigate = useNavigate();

  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const columns = useMemo(
    () => [
      {
        Header: tu('general.id'),
        accessor: 'freightProductId',
        inHeader: true
      },
      {
        Header: tu('general.name'),
        accessor: 'name',
        inHeader: true
      },
      {
        Header: tu('general.description'),
        accessor: (freightProduct) => (
          <div>
            <div className='freight-product-description'>{freightProduct.description ?? '-'}</div>
            <FreightProductInfo freightProduct={freightProduct} />
          </div>
        )
      }
    ],
    []
  );

  const onClickRow = (freightProductId) => {
    navigate(`${freightProductId}/edit`);
  };

  return (
    <TableV2
      className={isDesktop ? 'freight-products-desktop-table' : 'freight-products-mobile-table'}
      columns={columns}
      data={freightProducts}
      idAccessor='freightProductId'
      rowIcon='pencil-alt'
      onClickRow={onClickRow}
      goToLabel={t('freight.product.edit')}
      mobileTableVariant='withLargeEditButton'
      pagination
    />
  );
};

export default FreightProductsTable;
