import { FC } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import Toggle from 'js/components/Form/components/Toggle';
import Text from 'js/components/Form/components/Text';
import SelectV2 from 'js/components/Form/components/SelectV2';
import t from 'js/utils/translate';
import './index.sass';
import Input from 'js/components/Form/components/Input';
import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';

interface CustomerMessageProps {
  index: number;
}

const CustomerMessage: FC<CustomerMessageProps> = ({ index }) => {
  const freightProducts = useAppSelector((state: RootState) => state.freightProducts.data);
  const freightProductsLoaded = useAppSelector(
    (state: RootState) => state.freightProducts.isLoaded
  );

  const context = useFormContext();
  const values = context.getValues();
  const customerMessageId = values.customerMessages[index]?.customerMessageId;
  const activeMessage = values.customerMessages[index]?.active;

  return (
    <div className='customer-message'>
      <div className='customer-message__template-key'>{`${t(
        'customer.communication.customer.message.' + customerMessageId
      )}`}</div>
      <div className='customer-message__row'>
        <Text name={`customerMessages[${index}][channel]`} />
        <Toggle name={`customerMessages[${index}][active]`} label='Aktiver melding' />
        <SelectV2
          name={`customerMessages[${index}][freightProducts]`}
          label={t('customer.communication.freightProducts').toUpperCase()}
          isLoading={!freightProductsLoaded}
          options={freightProducts?.map((freightProduct) => ({
            value: freightProduct.freightProductId,
            label: freightProduct.name
          }))}
          selectAll
          isMulti
          optional={!activeMessage}
          hideOptional
        />
        <Input
          name={`customerMessages[${index}][additionalText]`}
          label={t('customer.communication.additionalText').toUpperCase()}
          optional
        />
      </div>
    </div>
  );
};

export default CustomerMessage;
