import { FC } from 'react';

import Label from 'js/components/Label';

import { tu } from 'js/utils/translate';

interface Props {
  name: string;
  disabled?: boolean;
  onBlur?: () => void;
}

const AddressHelperInput: FC<Props> = ({ name, disabled = false, onBlur }) => (
  <div className={`form-group ${name}`}>
    <Label label={tu(`addressHelper.${name}`)} />
    <input
      type='text'
      className={`form-control input ${name}${disabled ? 'Label' : 'Input'}`}
      disabled={disabled}
      onBlur={onBlur}
    />
    {disabled ? null : <div className={`${name}Container`} />}
  </div>
);

export default AddressHelperInput;
