import { FC } from 'react';
import Tag from 'js/components/Tag/index';
import t from 'js/utils/translate';

interface Props {
  objects: any[];
  limit: number;
  translationKeyPrefix?: string;
}

const LimitedTagList: FC<Props> = ({ objects, limit, translationKeyPrefix }) => {
  return (
    <div className='tag-list' id='tag-list'>
      {objects.slice(0, limit).map((obj, i) => (
        <Tag key={i} text={t(translationKeyPrefix ? `${translationKeyPrefix}.${obj}` : obj)} />
      ))}
      {objects.length > limit && (
        <span
          id={'tag-more'}
          className={'tag-more'}
          data-text={objects
            .slice(limit)
            .map((obj) => t(translationKeyPrefix ? `${translationKeyPrefix}.${obj}` : obj))
            .join(', ')}
        >
          <Tag key={'more'} text={`+ ${objects.length - limit} ${t('tagList.showMore')}`} />
        </span>
      )}
    </div>
  );
};

export default LimitedTagList;
