import { FC } from 'react';
import { useValue } from '@distribution-innovation/form-hooks';

import Input from 'js/components/Form/components/Input';
import SelectShops from './components/SelectShops';
import ColoredSection from 'js/components/ColoredSection';
import InputGroup from 'js/components/Form/components/InputGroup';
import Text from 'js/components/Form/components/Text';

import { findShopById } from 'js/utils/find';
import t, { tu } from 'js/utils/translate';

import { RootState } from 'js/store/reducer';

import './index.sass';
import { ApiServiceSupplier } from 'src/interfaces/interfaces.generated';
import { useAppSelector } from 'js/utils/hooks';

interface Props {
  serviceSupplier: ApiServiceSupplier | undefined;
}

const ServiceSupplierShops: FC<Props> = ({ serviceSupplier }) => {
  const shops = useAppSelector((state: RootState) => state?.freight?.shops?.data);
  const shopsLoaded = useAppSelector((state: RootState) => state?.freight?.shops?.isLoaded);

  const name = 'serviceSupplier.shopAndExternalId';
  const [selectedShops, setSelectedShops] = useValue(name);

  return (
    <div className='service-supplier'>
      <div className='row'>
        <Input name='serviceSupplier.name' label={tu('service.supplier.name')} />
        <SelectShops
          serviceSupplier={serviceSupplier}
          selectedShops={selectedShops}
          setSelectedShops={setSelectedShops}
        />
      </div>
      <ColoredSection
        defaultText={
          (!selectedShops || selectedShops?.length === 0) &&
          t('service.supplier.select.shop.add.externalid')
        }
        label={tu('service.supplier.externalId.selected.shop')}
        showChildren={(shopsLoaded && selectedShops?.length > 0) || false}
        optional
      >
        {selectedShops?.map((selectedShop, index) => (
          <InputGroup key={selectedShop?.shopId} name={name} className='shop-and-external-id'>
            <Text className='hidden' name={`${name}[${index}]shopId`} />
            <Input
              name={`${name}[${index}]externalSupplierId`}
              label={findShopById(shops, selectedShop.shopId)?.name.toUpperCase()}
              optional
            />
          </InputGroup>
        ))}
      </ColoredSection>
    </div>
  );
};

export default ServiceSupplierShops;
