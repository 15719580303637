import { FC } from 'react';

import SelectV2 from 'js/components/Form/components/SelectV2';
import ColoredSection from 'js/components/ColoredSection';

import { Option } from 'js/components/SelectV2/interfaces';
import t, { tu } from 'js/utils/translate';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';
import { RootState } from 'js/store/reducer';
import { PartyType } from 'js/scenes/Orders/scenes/NewOrder/index.types';
import MessageForUser from 'js/components/MessageForUser';

interface Props {
  consigneeType: PartyType;
  consignorType: PartyType;
}

const InvolvedParties: FC<Props> = ({ consigneeType, consignorType }) => {
  const involvedPartsOptions: Option[] = [
    { value: 'shop', label: t('orders.new.order.involvedParts.shop') },
    { value: 'custom', label: t('orders.new.order.involvedParts.custom') }
  ];

  // If a new order is being created from another order.
  const originalOrder = useAppSelector((state: RootState) => state?.orders?.getFullOrder?.data);
  if (originalOrder?.consignee?.name && originalOrder?.consignor?.name) {
    involvedPartsOptions.push({
      value: 'consignee',
      label: t('orders.new.order.involvedParts.originalConsignee')
    });
    involvedPartsOptions.push({
      value: 'consignor',
      label: t('orders.new.order.involvedParts.originalConsignor')
    });
  }

  const validateInvolvedParts = () => {
    if (
      consigneeType === consignorType &&
      consigneeType !== 'custom' &&
      consignorType !== 'custom'
    ) {
      return t('orders.new.order.involvedParts.partiesMustBeDifferent');
    }
  };

  return (
    <div className='involved-parties'>
      <ColoredSection
        className='involved-parts'
        label={tu('orders.new.order.involvedParts')}
        info={t('orders.new.order.involvedParts.partiesMustBeDifferent')}
      >
        <SelectV2
          name='consigneeType'
          label={tu('orders.new.order.involvedParts.consignee')}
          options={involvedPartsOptions}
        />
        <SelectV2
          name='consignorType'
          label={tu('orders.new.order.involvedParts.consignor')}
          options={involvedPartsOptions}
        />
      </ColoredSection>
      {validateInvolvedParts() && (
        <MessageForUser variant='warning' message={validateInvolvedParts()} />
      )}
    </div>
  );
};

export default InvolvedParties;
