import { createSlice } from '@reduxjs/toolkit';

import * as api from '../../api';
import { locationChanged } from 'js/store/actions';

export interface EditOrderReducerState {
  error: unknown;
  isCancelling: boolean;
}

const initialState: EditOrderReducerState = {
  error: null,
  isCancelling: false
};

const editOrderSlice = createSlice({
  name: 'Parcel/editOrder',
  initialState,
  reducers: {
    cancelParcelRequest(state) {
      state.error = null;
      state.isCancelling = true;
    },
    cancelParcelSuccess(state) {
      state.isCancelling = false;
    },
    cancelParcelFailed(state, action) {
      state.error = action.payload;
      state.isCancelling = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { cancelParcelRequest, cancelParcelSuccess, cancelParcelFailed } =
  editOrderSlice.actions;

export default editOrderSlice.reducer;

export const cancelOrder =
  (orderId: number | undefined) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(cancelParcelRequest());
      await api.cancelOrder(orderId);
      dispatch(cancelParcelSuccess());
      return true;
    } catch (err) {
      dispatch(cancelParcelFailed(err));
    }
  };
