import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import FormV2 from 'js/components/FormV2';
import Input from 'js/components/Form/components/Input';
import SplitPage from 'js/components/SplitPage';
import Spinner from 'js/components/Spinner';
import PageWrapper from 'js/components/PageWrapper';
import AddressInput from '../../components/AddressInput';

import { tu } from 'js/utils/translate';
import { editShop } from '../../store/editShopReducer';
import { getShop } from '../../store/shopReducer';
import { buildJsonPatchRequest } from 'js/utils/ajax/utils';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate, useParams } from 'react-router-dom';

const EditShopEconomy: FC = () => {
  const shop = useAppSelector((state: RootState) => state?.freight?.shop?.shop?.data);
  const shopLoaded = useAppSelector((state: RootState) => state?.freight?.shop?.shop?.isLoaded);

  const error = useAppSelector((state: RootState) => state?.freight?.shop?.editShop?.error);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const shopId = params?.shopId;

  useEffect(() => {
    if (shopId) dispatch(getShop(+shopId));
  }, []);

  const redirectTo = shopId ? `/freight/shop/${shopId}` : '/freight';

  const handleSubmit = async (values) => {
    await dispatch(editShop(buildJsonPatchRequest(shop, values), +shopId!)).then(
      (response: boolean | undefined) => {
        if (response) navigate(redirectTo);
      }
    );
  };

  if (!shopLoaded) return <Spinner />;

  return (
    <SplitPage>
      <PageWrapper className='edit-shop-economy-page'>
        <FormV2 onSubmit={handleSubmit} initialValues={shop} error={error} redirectTo={redirectTo}>
          <div className='edit-shop-economy-page__form-wrapper'>
            <Input
              className='wide-input'
              name='billingReceiver'
              label={tu('shop.accordion.economy.header.billingReceiver')}
              optional
            />
            <Input
              className='wide-input'
              name='billingEmail'
              label={tu('shop.accordion.economy.header.billingEmail')}
              optional
            />
            <div className='billing-wrapper'>
              <Input
                name='billingCustomerNumber'
                label={tu('shop.accordion.economy.header.billingCustomerNumber')}
                optional
              />
              <Input
                name='billingReference'
                label={tu('shop.accordion.economy.header.billingReference')}
                optional
              />
            </div>
            <AddressInput label={tu('table.address')} namePrefix='billing' optional />
          </div>
        </FormV2>
      </PageWrapper>
    </SplitPage>
  );
};

export default EditShopEconomy;
