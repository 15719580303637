import { FC } from 'react';

import { concat } from 'lodash';
import { toInt } from '@distribution-innovation/form-hooks';

import RadioGroup from 'js/components/Form/components/RadioGroup';

import t from 'js/utils/translate';

interface Props {
  name: string;
  label: string;
  weightBases: any[];
  isLoading?: boolean;
  translatePrefix: string;
}

const WeightBaseRadios: FC<Props> = ({ name, label, weightBases, isLoading, translatePrefix }) => {
  const nullOption = {
    label: t(`${translatePrefix}.weightBase.none`),
    value: ''
  };

  const weightBaseOptions = weightBases?.map(
    (weightBase) =>
      ({
        label: t(`${translatePrefix}.${weightBase.name}`),
        value: weightBase.id
      }) || []
  );

  const radios = concat(nullOption, weightBaseOptions);

  return (
    <RadioGroup label={label} name={name} radios={radios} isLoading={isLoading} convert={toInt} />
  );
};

export default WeightBaseRadios;
