import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { ApiOwnerParty } from 'src/interfaces/interfaces.generated';

import * as api from '../../utils/rest';

export interface OwnerPartiesReducerState {
  error: unknown;
  data: ApiOwnerParty[];
  isLoaded: boolean;
}

const initialState: OwnerPartiesReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const ownerPartiesSlice = createSlice({
  name: 'ownerParties',
  initialState,
  reducers: {
    getOwnerPartiesRequest(state) {
      state.isLoaded = false;
      state.error = null;
      state.data = [];
    },
    getOwnerPartiesSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getOwnerPartiesFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getOwnerPartiesRequest, getOwnerPartiesSuccess, getOwnerPartiesFailed } =
  ownerPartiesSlice.actions;

export default ownerPartiesSlice.reducer;

export const getOwnerParties =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getOwnerPartiesRequest());
      const response: ApiOwnerParty[] = await api.getOwnerParties();
      dispatch(getOwnerPartiesSuccess(response));
      return true;
    } catch (error) {
      dispatch(getOwnerPartiesFailed(error));
    }
  };
