import { FC, useEffect, useState } from 'react';
import './Users.sass';
import Tabs from 'js/components/Tabs';
import Users from 'js/scenes/UserAdministration/Users';
import { UserTab } from 'js/scenes/UserAdministration/Users.types';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { toastOptions } from 'js/scenes/UserAdministration/utils/utils';

const UserAdministration: FC = () => {
  const tabs: UserTab[] = [
    { name: 'userAdministration.flow.tabName', type: 'flow' },
    { name: 'userAdministration.api.tabName', type: 'api' }
  ];

  const { state } = useLocation();
  const selectedTab = state?.type ? tabs.filter((tab) => tab.type === state?.type)[0] : undefined;

  const [activeTab, setActiveTab] = useState<UserTab>(selectedTab || tabs[0]);

  useEffect(() => {
    if (state?.toastMessage) {
      toast(state?.toastMessage, toastOptions());
      window.history.replaceState({}, '');
    }
  }, []);

  return (
    <>
      <Tabs
        className='page-padding'
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        allCaps={false}
      />
      {tabs
        .filter(({ type }) => type === activeTab.type)
        .map(({ type }) => (
          <Users key={type} type={type} />
        ))}
    </>
  );
};

export default UserAdministration;
