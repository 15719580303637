import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { UserInfoShortcut } from 'src/interfaces/interfaces.generated';

import * as api from '../../utils/rest';

export interface ShortcutsReducerState {
  error: unknown;
  data: UserInfoShortcut[];
  isLoaded: boolean;
}

const initialState: ShortcutsReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const shortcutsSlice = createSlice({
  name: 'shortcuts',
  initialState,
  reducers: {
    getShortcutsRequest(state) {
      state.isLoaded = false;
      state.error = null;
      state.data = [];
    },
    getShortcutsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getShortcutsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getShortcutsRequest, getShortcutsSuccess, getShortcutsFailed } =
  shortcutsSlice.actions;

export default shortcutsSlice.reducer;

export const getShortcuts =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getShortcutsRequest());
      const response: UserInfoShortcut[] = await api.getShortcuts();
      dispatch(getShortcutsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getShortcutsFailed(error));
    }
  };
