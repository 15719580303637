import { combineReducers } from 'redux';

import getInvoiceBatchesReducer, {
  GetInvoiceBatchesReducerState
} from './store/getInvoiceBatchesReducer';
import generateInvoiceBatchReducer, {
  GenerateInvoiceBatchReducerState
} from './scenes/NewInvoice/store/generateInvoiceBatchReducer';
import handleInvoiceBatchReducer, {
  HandleInvoiceBatchReducerState
  // eslint-disable-next-line max-len
} from './components/GeneratedInvoiceBatchesTable/components/Actions/components/HandleInvoice/store/handleInvoiceBatchReducer';

const invoiceBatchesReducer = combineReducers({
  getInvoiceBatches: getInvoiceBatchesReducer,
  generateInvoiceBatch: generateInvoiceBatchReducer,
  handleInvoiceBatch: handleInvoiceBatchReducer
});

export default invoiceBatchesReducer;

export interface InvoiceBatchesReducerState {
  getInvoiceBatches: GetInvoiceBatchesReducerState;
  generateInvoiceBatch: GenerateInvoiceBatchReducerState;
  handleInvoiceBatch: HandleInvoiceBatchReducerState;
}
