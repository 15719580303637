import { FC } from 'react';
import classNames from 'classnames';

import ButtonV2 from 'js/components/ButtonV2';
import LinkV2 from 'js/components/LinkV2';

import t from 'js/utils/translate';
import { canGoBack } from 'js/utils/redirect';

import './index.sass';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

export interface FormActionsProps {
  className?: string;
  type?: 'save' | 'create' | 'getReport' | 'getLabels' | 'remove';
  cancel?: boolean;
  disabled?: boolean;
  redirectTo?: string;
  loading?: boolean;
  onCancel?: () => void;
  submitButtonVariant?: 'primary' | 'inverse' | 'danger';
}

const FormActions: FC<FormActionsProps> = ({
  type = 'save',
  cancel = true,
  disabled,
  redirectTo,
  loading,
  className = '',
  onCancel,
  submitButtonVariant
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBackOrHome = () => {
    canGoBack(location) ? navigate(-1) : navigate('/');
  };

  const handleRedirect = () => {
    redirectTo ? navigate(redirectTo) : goBackOrHome();
  };

  return (
    <div className={classNames('form-actions', className)}>
      <div className='form-actions__action-button'>
        <ButtonV2
          className={`form-actions-${type}`}
          text={t(`form.actions.${type}`)}
          name={t(`form.actions.${type}`)}
          submit
          loading={loading}
          disabled={disabled}
          variant={submitButtonVariant}
        />
      </div>
      {cancel && (
        <LinkV2
          className='form-actions__cancel-button'
          onClick={onCancel ?? handleRedirect}
          text={t('general.cancel')}
          disabled={loading}
        />
      )}
    </div>
  );
};

export default FormActions;
