import { FC } from 'react';

import { findUrlEnv } from 'js/utils/find';

import t from 'js/utils/translate';

interface ResetPasswordLinkProps {
  loginPage?: boolean;
}

const ResetPasswordLink: FC<ResetPasswordLinkProps> = ({ loginPage = true }) => (
  <a
    className='reset-password-link link'
    href={`https://${findUrlEnv(process.env.APP_ENV)}app.di.no/auth/static/password/reset`}
  >
    {loginPage ? t('login.password.expired.link') : t('reset.password.link')}
  </a>
);

export default ResetPasswordLink;
