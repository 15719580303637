import DILogo from 'images/Flow.svg';
import HHLogo from 'images/Flow - Helthjem.svg';
import DoorisLogo from 'images/Flow - Dooris.svg';

import FaviconDILogo from 'favicons/di/favicon-32x32.png';
import FaviconHHLogo from 'favicons/hh/favicon.ico';
import FaviconDoorisLogo from 'favicons/dooris/favicon.ico';

export const activeOwnerPartyLogoAttributes = (context, ownerPartyId) => {
  switch (context) {
    case 'name':
      switch (ownerPartyId) {
        case 1:
          return 'helthjem';
        case 11:
          return 'dooris';
        default:
          return 'di';
      }
    case 'logo':
      switch (ownerPartyId) {
        case 1:
          return <HHLogo preserveAspectRatio='xMinYMid meet' viewBox='0 0 49 49' />;
        case 11:
          return <DoorisLogo preserveAspectRatio='xMinYMid meet' viewBox='0 0 49 49' />;
        default:
          return <DILogo preserveAspectRatio='xMinYMid meet' viewBox='0 0 49 49' />;
      }
    case 'faviconLogo':
      switch (ownerPartyId) {
        case 1:
          return FaviconHHLogo;
        case 11:
          return FaviconDoorisLogo;
        default:
          return FaviconDILogo;
      }
  }
};
