import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Icon from 'js/components/Icon';
import Dropdown from './components/Dropdown';

import { tu } from 'js/utils/translate';

import './index.sass';

const NavLink = ({ to, name, dropdownSections, location }) => {
  const findActive = () =>
    to === location.pathname
      ? true
      : dropdownSections.find((section) => section.to === location.pathname);

  const [activeMainRoute, setActiveMainRoute] = useState(findActive());

  useEffect(() => {
    setActiveMainRoute(findActive());
  }, [location.pathname]);

  return (
    <div className='navigation-bar__item'>
      <Link
        to={to}
        className={classNames('nav-link', {
          'nav-link--active': activeMainRoute
        })}
      >
        <span className='nav-link__name'>
          {tu(`general.${name.replace(/\s/g, '').toLowerCase()}`)}
        </span>
        {dropdownSections && dropdownSections.length > 0 && (
          <div className='nav-link__icon'>
            <Icon icon='caret-down' />
          </div>
        )}
      </Link>
      <Dropdown dropdownSections={dropdownSections} />
    </div>
  );
};

export default NavLink;
