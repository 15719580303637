import { FC, useEffect, useState } from 'react';

import ButtonV2 from 'js/components/ButtonV2';
import Counter from './components/Counter';

import t from 'js/utils/translate';

import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';
import { useSearchParams } from 'react-router-dom';
import { searchParamKeys } from 'js/utils/searchParameterKeys';

import './index.sass';

interface Props {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount?: number | null;
  // eslint-disable-next-line no-unused-vars
  onChange: (pageNumber: number) => void;
  usedByTable?: boolean;
}

const navigation = {
  previous: -1,
  next: 1
};

const Pagination: FC<Props> = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  onChange,
  usedByTable
}) => {
  const numberOfPages =
    !totalItemsCount || totalItemsCount === 0 ? 1 : Math.ceil(totalItemsCount / itemsCountPerPage);

  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const [currentPage, setCurrentPage] = useState<number>(activePage);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const pageNum = Number(searchParams.get(searchParamKeys.page) ?? '1');
    currentPage !== pageNum && setCurrentPage(pageNum);
    // Table stores all the data and counts pages from 0, while elsewhere it is used to fetch a page from backend,
    // and it starts at 1
    usedByTable ? onChange(pageNum - 1) : onChange(pageNum);
  }, [searchParams.get(searchParamKeys.page)]);

  const handlePageChangeInput = (event) => {
    if (event.key === 'Enter') {
      setSearchParams({ [searchParamKeys.page]: event.target.value });
    }
  };

  const handlePageClick = (value: number) => {
    setCurrentPage((currentValue) => {
      const newValue = currentValue + value;
      setSearchParams({ [searchParamKeys.page]: newValue.toString() });
      return newValue;
    });
  };

  return (
    <div className='pagination'>
      <ButtonV2
        className='pagination-left'
        icon='angle-left'
        onClick={() => {
          handlePageClick(navigation.previous);
        }}
        disabled={currentPage === 1}
      />
      <Counter activePage={currentPage} totalPages={numberOfPages} />
      {isDesktop && (
        <div className='pagination__input'>
          <div>{t('pagination.input.text')}</div>
          <input
            defaultValue={currentPage}
            type='number'
            onKeyPress={(e) => {
              handlePageChangeInput(e);
            }}
            disabled={numberOfPages === 1}
            max={numberOfPages}
            min='1'
          />
        </div>
      )}
      <ButtonV2
        className='pagination-right'
        icon='angle-right'
        onClick={() => {
          handlePageClick(navigation.next);
        }}
        disabled={currentPage === numberOfPages}
      />
    </div>
  );
};

export default Pagination;
