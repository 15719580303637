import { useMutation, useQuery } from 'react-query';
import { Role, Shop, UserType } from 'js/scenes/UserAdministration/Users.types';
import Ajax from 'js/utils/ajax/ajax';
import {
  ApiConnectUser,
  ApiCreateUser,
  ApiUpdateUser
} from 'js/scenes/UserAdministration/api/api.types';
import { isUsernameValid } from 'js/scenes/UserAdministration/utils/utils';
import {
  availableUserOptions,
  usernameAvailable,
  usersList
} from 'js/scenes/UserAdministration/api/queryKeys';

export const url = '/users/v1';
const availableUserOptionsUrl = `${url}/userOptions`;
const availableUsernameUrl = `${url}/username/:username/available`;
const connectUsers = `${url}/connect`;
const disconnectUsers = `${url}/disconnect`;

export const useUsersListWithTypeQuery = (type: UserType) =>
  useQuery([usersList, type], async () => Ajax.get(url, { userType: type.toUpperCase() }));

export const useAvailableUserOptionsQuery = () =>
  useQuery(
    [availableUserOptions],
    async (): Promise<{
      shops: Shop[];
      roles: Role[];
      webServices: number[];
      apiMandatoryRoles: Role[];
      flowMandatoryRoles: Role[];
    }> => Ajax.get(availableUserOptionsUrl)
  );

export const useUsernameAvailableQuery = (username: string) =>
  useQuery(
    [usernameAvailable, username],
    async () => Ajax.get(availableUsernameUrl.replace(':username', username)),
    { enabled: isUsernameValid(username) && username !== 'ws' }
  );

export const useCreateUserMutation = (onSuccess: any) =>
  useMutation(async (user: ApiCreateUser) => Ajax.post(url, user), {
    onSuccess: () => {
      return onSuccess();
    }
  });

export const useUpdateUserMutation = (onSuccess: any) =>
  useMutation(async (user: ApiUpdateUser) => Ajax.put(url, user), {
    onSuccess: () => {
      return onSuccess();
    }
  });

export const useConnectUserMutation = (onSuccess: any) =>
  useMutation(async (users: ApiConnectUser) => Ajax.post(connectUsers, users), {
    onSuccess: () => {
      return onSuccess();
    }
  });

export const useDisconnectUserMutation = (onSuccess: any) =>
  useMutation(async (users: ApiConnectUser) => Ajax.post(disconnectUsers, users), {
    onSuccess: () => {
      return onSuccess();
    }
  });
