import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { ApiFlowShopSimple } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';

export interface UsersReducerState {
  error: unknown;
  isLoaded: boolean;
  data: ApiFlowShopSimple[];
}

const initialState: UsersReducerState = {
  error: null,
  isLoaded: false,
  data: []
};

const usersSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    getUsersRequest(state) {
      state.error = null;
      state.isLoaded = false;
      state.data = [];
    },
    getUsersSuccess(state, action) {
      state.isLoaded = false;
      state.data = action.payload;
    },
    getUsersFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getUsersRequest, getUsersSuccess, getUsersFailed } = usersSlice.actions;

export default usersSlice.reducer;

export const getUsers =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getUsersRequest());
      const response: ApiFlowShopSimple[] = await api.getAuthUsers();
      dispatch(getUsersSuccess(response));
      return true;
    } catch (error) {
      dispatch(getUsersFailed(error));
    }
  };
