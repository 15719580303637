import { FC } from 'react';

import { useRegister, useValue } from '@distribution-innovation/form-hooks';

interface Props {
  name: string;
  className?: string;
}

const Text: FC<Props> = ({ name, className }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useValue(name);
  useRegister({ name, value });

  return <span className={className}>{value}</span>;
};

export default Text;
