import { FC } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';
import { isEmpty } from 'lodash';

import MessageForUser from 'js/components/MessageForUser';
import t from 'js/utils/translate';

import './ValidationErrors.sass';

const ValidationErrors: FC<{ translationPrefix: string }> = ({ translationPrefix }) => {
  const context = useFormContext();
  const errors = context.getErrors();

  const filteredErrors = Object.keys(errors)
    ?.map((errorName) => {
      if (context.isSubmitted) {
        return {
          errorName,
          errorType: errors[errorName]
        };
      }
      return undefined;
    })
    .filter(Boolean);

  if (isEmpty(errors)) return <></>;

  return (
    <div className='new-order__validation-errors'>
      {filteredErrors?.map((error) => (
        <MessageForUser
          key={error?.errorName}
          variant='error'
          message={t(
            'validationError.format',
            t(`validationError.message.${error?.errorType}`),
            t(`${translationPrefix}.${error?.errorName}`)
          )}
        />
      ))}
    </div>
  );
};

export default ValidationErrors;
