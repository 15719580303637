import { FreightProduct } from 'src/interfaces/interfaces.generated';

export const filterFreightProducts = (
  freightProducts: FreightProduct[],
  search: string
): FreightProduct[] =>
  freightProducts.filter(
    (freightProduct) =>
      freightProduct.name?.toLowerCase().includes(search) ||
      freightProduct.freightProductId?.toString().includes(search)
  );
