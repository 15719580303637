import { LabelStatus } from '../interfaces';

export const doneStatusIds = [1, 16, 17, 20];
export const proccessingStatusIds = [12, 13, 14, 15, 19];
export const failedStatusIds = [2, 3, 21];

export const findLabelStatusById = (statusId: number): LabelStatus | undefined => {
  if (doneStatusIds.includes(statusId)) return 'done';
  if (proccessingStatusIds.includes(statusId)) return 'proccessing';
  if (failedStatusIds.includes(statusId)) return 'failed';
  else return undefined;
};
