// eslint-disable-next-line no-unused-vars
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import classNames from 'classnames';

import Shops from './scenes/Shops';
import Concerns from './scenes/Concerns/index';
import ServiceSuppliers from './scenes/ServiceSuppliers';
import Tabs from 'js/components/Tabs';
import PageHeaderButtons from './components/PageHeaderButtons';

import { tabs } from './utils';
import { getShops } from 'js/scenes/Freight/store/shopsReducer';

import './index.sass';
import { RootState } from 'js/store/reducer';
import { ApiFlowShopSimple } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const Freight: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const isDesktop: boolean = useAppSelector((state: RootState) => state.mediaDevice.isDesktop);
  const userRoles = useAppSelector((state: RootState) => state.user?.data?.roles);
  const shopsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shops?.isLoaded
  );
  const shops: ApiFlowShopSimple[] = useAppSelector(
    (state: RootState) => state?.freight?.shops?.data
  );

  const [activeTab, setActiveTab] = useState(tabs(userRoles)[0]);

  useEffect(() => {
    if (!shopsLoaded) dispatch(getShops());
    if (location?.state?.activeTab) setActiveTab(location.state.activeTab);
  }, []);

  return (
    <div className='freight'>
      <Tabs
        className='freight-tabs page-padding'
        tabs={tabs(userRoles)}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {!isDesktop && <PageHeaderButtons userRoles={userRoles} />}
      <div className={classNames('freight-table', `freight-table__${activeTab.name}`)}>
        {activeTab.name === 'shops' && (
          <Shops activeTabName={activeTab.name} shops={shops} shopsLoaded={shopsLoaded} />
        )}
        {activeTab.name === 'concerns' && <Concerns activeTabName={activeTab.name} shops={shops} />}
        {activeTab.name === 'serviceSuppliers' && (
          <ServiceSuppliers activeTabName={activeTab.name} shops={shops} />
        )}
      </div>
    </div>
  );
};

export default Freight;
