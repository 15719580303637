import { FC } from 'react';
import { TransportPriceTable } from 'src/interfaces/interfaces.generated';
// eslint-disable-next-line max-len
import CostPriceHistoryTable from 'js/scenes/Economy/scenes/CostPrices/components/CostPriceChanges/components/CostPriceHistoryTable';

interface CostPriceCollapseBodyProps {
  freightProductPrices: TransportPriceTable;
}

const CostPriceCollapseBody: FC<CostPriceCollapseBodyProps> = ({ freightProductPrices }) => (
  <div className='cost-price-change'>
    <CostPriceHistoryTable priceChangeStatistics={freightProductPrices.priceChangeStatistics} />
  </div>
);

export default CostPriceCollapseBody;
