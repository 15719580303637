import { FC, useEffect } from 'react';

import SelectFreightProducts from './components/SelectFreightProducts';
import SelectV2 from 'js/components/Form/components/SelectV2';

import t, { tu } from 'js/utils/translate';
import { serviceSupplierOptions, shopOptions } from 'js/components/Select/utils';
import { getServiceSuppliersForLabels } from '../../store/serviceSuppliersForLabelsReducer';

import { RootState } from 'js/store/reducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const SelectSection: FC = () => {
  const dispatch = useAppDispatch();

  const shops = useAppSelector((state: RootState) => state?.freight?.shops?.data);
  const shopsLoaded = useAppSelector((state: RootState) => state?.freight?.shops?.isLoaded);

  const serviceSuppliersForLabels = useAppSelector(
    (state: RootState) => state.orders?.serviceSuppliersForLabels.data
  );
  const serviceSuppliersForLabelsLoaded = useAppSelector(
    (state: RootState) => state.orders?.serviceSuppliersForLabels.isLoaded
  );

  useEffect(() => {
    if (!serviceSuppliersForLabelsLoaded) dispatch(getServiceSuppliersForLabels());
  }, []);

  return (
    <>
      <SelectV2
        name='shopIds'
        options={shopOptions(shops)}
        isLoading={!shopsLoaded}
        label={tu('general.shops')}
        placeholder={t('select.shops')}
        isMulti
        selectAll
        selectFirst
      />
      <SelectFreightProducts shops={shops} />
      {serviceSuppliersForLabels.length > 0 && (
        <SelectV2
          name='serviceSupplierIds'
          options={serviceSupplierOptions(serviceSuppliersForLabels)}
          isLoading={!serviceSuppliersForLabelsLoaded}
          label={tu('general.serviceSuppliers')}
          placeholder={t('select.serviceSuppliers')}
          optional
          isMulti
          selectAll
          selectFirst
        />
      )}
    </>
  );
};

export default SelectSection;
