import t, { tu } from 'js/utils/translate';

import ColoredSectionWithHeader from '../../../ColoredSectionWithHeader';

const ExternalCustomerIds = ({ translateKeyPrefix, shop, freightProducts }) => (
  <ColoredSectionWithHeader header={tu(`${translateKeyPrefix}.external.customerIDs`)}>
    {shop?.customerNumbers?.length > 0 ? (
      shop.customerNumbers.map((cn) => (
        <div key={cn.freightProductId}>
          <div>
            {freightProducts.find((fp) => fp.freightProductId === cn.freightProductId)?.name}
          </div>
          <div>{cn.customerNumber}</div>
        </div>
      ))
    ) : (
      <div>{t('config.no.customer.numbers')}</div>
    )}
  </ColoredSectionWithHeader>
);

export default ExternalCustomerIds;
