import { FC } from 'react';
import { RootState } from 'js/store/reducer';
import t from 'js/utils/translate';
import { simpleHash } from 'js/utils/string';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

const LaunchNotesButton: FC = () => {
  const userId = useAppSelector((state: RootState) => state.user.data.userId);

  if (!userId) {
    return <></>;
  }

  const token = 'public_vGkbS6xnSHKayDZupLWLkdKR';
  const project = 'pro_Fd3hCYI4mvyyk';
  const selector = 'launch-notes-button';

  const embed = `
    <launchnotes-embed
      token="${token}"
      project="${project}"
      categories='["flow", "flow-api"]'
      toggle-selector="#${selector}"
      heading="${t('launch.notes.heading')}"
      heading-color="#000000"
      subheading="${t('launch.notes.subheading')}"
      subheading-color="#000000"
      primary-color="#F5F6F8"
      widget-placement="auto"
      widget-offset-skidding="-140"
      widget-offset-distance="10"
      show-unread
      anonymous-user-id="${simpleHash(userId.toString())}"
      unread-placement="right"
      unread-offset-skidding="0"
      unread-offset-distance="-5"
      unread-background-color="#237AC2"
      unread-text-color="#ffffff"
    ></launchnotes-embed>
  `;

  return (
    <>
      <div id={selector}>{t('launch.notes.button.title')}</div>
      <div dangerouslySetInnerHTML={{ __html: embed }} />
    </>
  );
};

export default LaunchNotesButton;
