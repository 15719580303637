import { FC, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { RootState } from 'js/store/reducer';

import FormV2 from 'js/components/FormV2';
import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import Spinner from 'js/components/Spinner';
import AccordionV2 from 'js/components/AccordionV2';

import { shopConfigurationsAccordion } from './components/ShopConfigurationAccordion';
import { editShop } from '../../store/editShopReducer';
import { getShop } from '../../store/shopReducer';
import { getContractServices } from './store/contractServicesReducer';
import { buildJsonPatchRequest } from 'js/utils/ajax/utils';
import { buildSubmit, shopHasContractService } from './utils';

import { ApiFlowShop } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate, useParams } from 'react-router-dom';

const EditShopConfigurations: FC = () => {
  const { shopId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const shop: ApiFlowShop = useAppSelector((state: RootState) => state?.freight?.shop?.shop?.data);
  const savingShop = useAppSelector((state: RootState) => state?.freight?.shop?.editShop?.isSaving);
  const error = useAppSelector((state: RootState) => state?.freight?.shop?.editShop?.error);

  const contractServices = useAppSelector(
    (state: RootState) => state?.freight?.shop?.contractServices?.data
  );
  const contractServicesLoaded = useAppSelector(
    (state: RootState) => state?.freight?.shop?.contractServices?.isLoaded
  );

  const redirectTo = `/freight/shop/${shop.shopId}`;

  const handleSubmit = (values) => {
    if (shop?.shopId) {
      const updatedShop = cloneDeep(values);
      const patch = buildJsonPatchRequest(shop, buildSubmit(contractServices, updatedShop));
      dispatch(editShop(patch, shop.shopId)).then((response: boolean | undefined) => {
        if (response) navigate(redirectTo);
      });
    }
  };

  useEffect(() => {
    if (!contractServicesLoaded) dispatch(getContractServices());
  }, []);

  useEffect(() => {
    if (!shop?.shopId) dispatch(getShop(+shopId!));
  }, []);

  if (!shop?.shopId) return <Spinner />;

  return (
    <SplitPage>
      <PageWrapper className='edit-configuration-page'>
        <FormV2
          onSubmit={handleSubmit}
          initialValues={{
            A1: shopHasContractService(shop, 'A1'),
            ASB: shopHasContractService(shop, 'ASB'),
            ASD: shopHasContractService(shop, 'ASD'),
            ASC: shopHasContractService(shop, 'ASC'),
            ASA: shopHasContractService(shop, 'ASA'),
            RNL: shopHasContractService(shop, 'RNL'),
            ASE: shopHasContractService(shop, 'ASE'),
            RNLdefaultOn:
              shopHasContractService(shop, 'RNL') &&
              shop.shopContractServices?.find((service) => service.code === 'RNL')?.defaultOn,
            POD: shopHasContractService(shop, 'POD'),
            ...shop
          }}
          loading={savingShop}
          error={error}
          redirectTo={redirectTo}
        >
          <AccordionV2
            className='edit-configurations-accordion'
            name='shopConfigurationsAccordion'
            accordion={shopConfigurationsAccordion(shop, 'colored')}
            withToggleAllOption
          />
        </FormV2>
      </PageWrapper>
    </SplitPage>
  );
};

export default EditShopConfigurations;
