import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';
import { ApiOrderSimple } from 'src/interfaces/interfaces.generated';
import { SearchInput } from '../../interfaces';

import * as rest from '../../api';

export interface OrderSearchReducerState {
  error: any;
  data: ApiOrderSimple[];
  isLoaded: boolean;
  isSearching: boolean;
  searchInput: SearchInput;
}

const initialState = {
  error: null,
  data: [],
  isLoaded: false,
  isSearching: false,
  searchInput: {}
};

const ordersSlice = createSlice({
  name: 'Orders',
  initialState,
  reducers: {
    getOrdersRequest(state, action) {
      state.isSearching = true;
      state.isLoaded = false;
      state.error = null;
      state.searchInput = action.payload.searchInput;
    },
    getOrdersSuccess(state, action) {
      state.isSearching = false;
      state.isLoaded = true;
      state.data = action.payload;
    },
    getOrdersFailed(state, action) {
      state.isSearching = false;
      state.isLoaded = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getOrdersRequest, getOrdersSuccess, getOrdersFailed } = ordersSlice.actions;

export default ordersSlice.reducer;

export const getOrders =
  (identifier = '', customer = '', reference = '') =>
  async (dispatch): Promise<ApiOrderSimple[] | undefined> => {
    try {
      dispatch(
        getOrdersRequest({
          searchInput: { identifier, customer, reference }
        })
      );
      const response: ApiOrderSimple[] = await rest.getOrders(identifier, customer, reference);
      dispatch(getOrdersSuccess(response));
      return response;
    } catch (err) {
      dispatch(getOrdersFailed(err));
    }
  };
