import { FC } from 'react';
import PageWrapper from 'js/components/PageWrapper';
import MessageForUser from 'js/components/MessageForUser';
import './index.sass';
import t from 'js/utils/translate';

interface Props {
  error?: string;
}

const Error: FC<Props> = ({ error }) => {
  return (
    <PageWrapper>
      <MessageForUser
        variant={'error'}
        message={error ?? t('error.somethingWentWrong')}
        className={'error-box'}
      />
    </PageWrapper>
  );
};

export default Error;
