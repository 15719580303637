import { FC } from 'react';
import CostPriceSelects from './components/CostPriceSelects';
import FormV2 from 'js/components/FormV2';
import CostPrice from './components/CostPrice';
import CostPriceChanges from './components/CostPriceChanges';
import './index.sass';

const CostPrices: FC = () => (
  <div className='cost-prices'>
    <FormV2 actions={false}>
      <div className='cost-prices__body'>
        <CostPriceSelects />
        <CostPrice />
      </div>
      <CostPriceChanges />
    </FormV2>
  </div>
);

export default CostPrices;
