import { FC } from 'react';

import t from 'js/utils/translate';

interface Props {
  activePage: number;
  totalPages: number;
}

const Counter: FC<Props> = ({ activePage, totalPages }) => (
  <div className='pagination__counter'>
    {`${activePage}  ${t('general.of')} ${activePage > totalPages ? '-' : totalPages}`}
  </div>
);

export default Counter;
