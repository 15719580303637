import PropTypes from 'prop-types';

import CommaSeparatedString from 'js/components/CommaSeparatedNames/components/CommaSeparatedString';

const CommaSeparatedNames = ({ ids, list, find }) => (
  <CommaSeparatedString
    accessor='name'
    data={ids?.map((id) => ({ id, name: find(list, id)?.name }))}
  />
);

export default CommaSeparatedNames;

CommaSeparatedNames.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  find: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired
};
