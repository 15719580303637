import { formatDistance } from 'date-fns';
import nb from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-US';

import { LabelGenerationHistory } from 'src/interfaces/interfaces.generated';
import { LabelStatus } from '../../../../../../../interfaces';
import { safariSafeDateString } from 'js/utils/dateTime';

export const timeToGenerate = (
  labelStatus: LabelStatus | undefined,
  labelExcerpt: LabelGenerationHistory,
  selectedLanguage: string
): string => {
  if (labelStatus === 'proccessing' || (!labelExcerpt.generated && !labelExcerpt.ordered))
    return '-';

  const generatedDate = new Date(safariSafeDateString(labelExcerpt.generated));
  const orderedDate = new Date(safariSafeDateString(labelExcerpt.ordered));

  if (!generatedDate.getDate() || !orderedDate.getDate()) return '-';

  const distance = formatDistance(generatedDate, orderedDate, {
    addSuffix: false,
    locale: selectedLanguage === 'no' ? nb : en
    // includeSeconds: true Typefeil
  });
  return distance;
};
