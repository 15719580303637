import { FC, Dispatch, SetStateAction } from 'react';
import classnames from 'classnames';

import ShowMoreButton from 'js/components/ShowMoreButton';

import { LabelStatus } from '../../interfaces';
import t from 'js/utils/translate';

import './index.sass';

interface Props {
  className: string;
  showLabelExcerptOptions: boolean;
  toggleShowLabelExcerptOptions: Dispatch<SetStateAction<boolean>>;
  showLabels: boolean;
  toggleShowLabels: Dispatch<SetStateAction<boolean>>;
  labelStatus?: LabelStatus;
}

const ShowMoreButtons: FC<Props> = ({
  className,
  showLabelExcerptOptions,
  toggleShowLabelExcerptOptions,
  showLabels,
  toggleShowLabels,
  labelStatus
}) => (
  <div className={classnames('show-more-buttons', className)}>
    <ShowMoreButton
      show={showLabelExcerptOptions}
      toggleShow={toggleShowLabelExcerptOptions}
      hideText={t('orders.labelhistory.hide.label.excerpt.options')}
      showText={t('orders.labelhistory.show.label.excerpt.options')}
    />
    {labelStatus === 'done' && (
      <ShowMoreButton
        show={showLabels}
        toggleShow={toggleShowLabels}
        hideText={t('orders.labelhistory.hide.labels')}
        showText={t('orders.labelhistory.show.labels')}
      />
    )}
  </div>
);

export default ShowMoreButtons;
