import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as api from '../../api';
import { TransportSolution } from 'src/interfaces/interfaces.generated';
import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';

export interface ShopTransportSolutionsReducerState {
  data: TransportSolution[];
  error: unknown;
  isSaving: boolean;
  isLoading: boolean;
  shopId: number | undefined;
}

const initialState: ShopTransportSolutionsReducerState = {
  data: [],
  error: null,
  isSaving: false,
  isLoading: false,
  shopId: undefined
};

const shopTransportSolutionsSlice = createSlice({
  name: 'ShopTransportSolutions',
  initialState,
  reducers: {
    getShopTransportSolutionsRequest(state, action) {
      state.isLoading = true;
      state.shopId = action.payload;
    },
    getShopTransportSolutionsSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    getShopTransportSolutionsFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.shopId = undefined;
    },
    editShopTransportSolutionsRequest(state) {
      state.error = null;
      state.isSaving = true;
    },
    editShopTransportSolutionsSuccess(state, action) {
      state.isSaving = false;
      state.data = action.payload;
    },
    editShopTransportSolutionsFailed(state, action) {
      state.error = action.payload;
      state.isSaving = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  editShopTransportSolutionsRequest,
  editShopTransportSolutionsSuccess,
  editShopTransportSolutionsFailed,
  getShopTransportSolutionsRequest,
  getShopTransportSolutionsSuccess,
  getShopTransportSolutionsFailed
} = shopTransportSolutionsSlice.actions;

export default shopTransportSolutionsSlice.reducer;

export const getShopTransportSolutions =
  (shopId: number) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getShopTransportSolutionsRequest(shopId));
      const response: TransportSolution[] = await api.getShopTransportSolutions(shopId);
      dispatch(getShopTransportSolutionsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getShopTransportSolutionsFailed(error));
    }
  };

export const editShopTransportSolutions =
  (shopId, shopTransportSolutions) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(editShopTransportSolutionsRequest());
      const response: TransportSolution[] = await api.editShopTransportSolutions(
        shopId,
        shopTransportSolutions
      );
      dispatch(editShopTransportSolutionsSuccess(response));
      return true;
    } catch (error) {
      dispatch(editShopTransportSolutionsFailed(error));
    }
  };

export const useShopTransportSolutions = (): ShopTransportSolutionsReducerState =>
  useAppSelector((state: RootState) => state.freight.shop.shopTransportSolutions);
