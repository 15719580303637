import classNames from 'classnames';

import t, { tu } from 'js/utils/translate';

import './index.sass';

const Tabs = ({ className, tabs, activeTab, setActiveTab, allCaps = true }) => (
  <div className={classNames('tab-list', className)}>
    {tabs.map(
      (tab) =>
        tab && (
          <li
            className={classNames('tab-list-item', {
              'tab-list-active': tab.name === activeTab.name
            })}
            onClick={() => setActiveTab(tab)}
            key={tab.name}
          >
            {(allCaps && tu(tab.label || tab.name)) || t(tab.label || tab.name)}
          </li>
        )
    )}
  </div>
);

export default Tabs;
