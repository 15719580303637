import { FC, useState } from 'react';
import ShowMoreButton from 'js/components/ShowMoreButton';
import { TransportPriceTable } from 'src/interfaces/interfaces.generated';
import { findPrice, samePrice } from '../../../../utils';
import t from 'js/utils/translate';
import './index.sass';

interface MobileMatrixBodyProps {
  freightProductPrices: TransportPriceTable;
  currentPrices: boolean;
  rowIndex: number;
  selectedFilter: string | undefined;
}

const MobileMatrixBody: FC<MobileMatrixBodyProps> = ({
  freightProductPrices,
  currentPrices,
  rowIndex,
  selectedFilter
}) => {
  const [showZones, toggleShowZones] = useState(false);
  return (
    <div className='mobile-price-matrix-body'>
      {showZones && (
        <>
          {freightProductPrices?.columns?.map((zone, columnIndex) => (
            <>
              <div key={zone} className='mobile-price-matrix-body__zone'>
                <div>{t('economy.costprice.matrix.zone', zone)}</div>
                <div>
                  {samePrice(
                    currentPrices,
                    freightProductPrices?.priceMatrix?.[rowIndex],
                    selectedFilter
                  )
                    ? '-'
                    : findPrice(
                        currentPrices,
                        freightProductPrices?.priceMatrix?.[rowIndex]?.[columnIndex]?.costPrices,
                        selectedFilter
                      )}
                </div>
              </div>
              {freightProductPrices?.columns?.length === columnIndex + 1 && (
                <div className='mobile-price-matrix-body__zone'>
                  <div>{t('economy.costprice.matrix.all.zones')}</div>
                  <div>
                    {samePrice(currentPrices, freightProductPrices?.priceMatrix?.[rowIndex])
                      ? findPrice(
                          currentPrices,
                          freightProductPrices?.priceMatrix?.[rowIndex]?.[columnIndex]?.costPrices,
                          selectedFilter
                        )
                      : '-'}
                  </div>
                </div>
              )}
            </>
          ))}
        </>
      )}
      <ShowMoreButton
        show={showZones}
        toggleShow={toggleShowZones}
        hideText={t('economy.costprice.matrix.close.zone')}
        showText={t('economy.costprice.matrix.show.zone')}
      />
    </div>
  );
};

export default MobileMatrixBody;
