import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { ApiUser } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';

export interface ShopUsersReducerState {
  error: unknown;
  isLoaded: boolean;
  data: ApiUser[];
}

const initialState: ShopUsersReducerState = {
  error: null,
  isLoaded: false,
  data: []
};

const shopUsersSlice = createSlice({
  name: 'ShopUsers',
  initialState,
  reducers: {
    getShopUsersRequest(state) {
      state.error = null;
      state.isLoaded = false;
      state.data = [];
    },
    getShopUsersSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getShopUsersFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getShopUsersRequest, getShopUsersSuccess, getShopUsersFailed } =
  shopUsersSlice.actions;

export default shopUsersSlice.reducer;

export const getShopUsers =
  (shopId: number) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getShopUsersRequest());
      const response: ApiUser[] = await api.getShopUsers(shopId);
      dispatch(getShopUsersSuccess(response));
      return true;
    } catch (error) {
      dispatch(getShopUsersFailed(error));
    }
  };
