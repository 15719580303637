import { useState } from 'react';
import { Lightbox } from 'react-modal-image';
import classNames from 'classnames';

import './index.sass';

const Image = ({ height = '200px', width = '120px', alt, src, className }) => {
  const [fullSize, toggleFullSize] = useState(false);

  return fullSize ? (
    <Lightbox
      large={src}
      alt={alt}
      onClose={() => {
        toggleFullSize(!fullSize);
      }}
      className={className}
    />
  ) : (
    <img
      src={src}
      width={width}
      height={height}
      onClick={() => {
        toggleFullSize(!fullSize);
      }}
      alt={alt}
      className={classNames('image', className)}
    />
  );
};

export default Image;
