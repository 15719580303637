import { FC } from 'react';
import classNames from 'classnames';

import Tag from 'js/components/Tag';

import t from 'js/utils/translate';

import './index.sass';

interface Props {
  list: any[];
  translationKeyPrefix?: string;
  accessor?: string;
  hidden?: boolean;
}

const Tags: FC<Props> = ({ list, translationKeyPrefix = '', accessor, hidden }) => (
  <div className={classNames('tag-list', { 'tag-list--hidden': hidden })}>
    {list.map((element, index) => (
      <Tag
        key={index}
        text={
          translationKeyPrefix
            ? t(`${translationKeyPrefix}.${accessor ? element[accessor] : element}`)
            : element
        }
      />
    ))}
  </div>
);

export default Tags;
