import { FC } from 'react';
import { useValue } from '@distribution-innovation/form-hooks';

import CreateUsers from './components/CreateUsers';
import ConnectUsers from './components/ConnectUsers';

import t from 'js/utils/translate';

import './index.sass';

interface Props {
  isApiContext?: boolean;
}

const AddUsers: FC<Props> = ({ isApiContext }) => {
  const createUsersName = 'newUsers';
  const [newUsers, setNewUsers] = useValue(createUsersName);
  const addNewUser = () => setNewUsers([...(newUsers || []), {}]);

  const connectUsersName = 'connectedUsers';
  const [connectUsers, setConnectUsers] = useValue(connectUsersName);
  const addConnectUser = () => setConnectUsers([...(connectUsers || []), {}]);

  return (
    <div className='add-users'>
      <ConnectUsers
        isApiContext={isApiContext}
        connectUsers={connectUsers}
        setConnectUsers={setConnectUsers}
        name={connectUsersName}
      />
      <CreateUsers
        isApiContext={isApiContext}
        newUsers={newUsers}
        setNewUsers={setNewUsers}
        name={createUsersName}
      />
      <div className='add-users-links'>
        <div className='link connect-exisiting-user' onClick={addConnectUser}>
          {t('freight.shop.users.connect', isApiContext ? t('general.apiUser') : t('general.user'))}
        </div>
        <div className='link create-new-user' onClick={addNewUser}>
          {t('freight.shop.users.create', isApiContext ? t('general.apiUser') : t('general.user'))}
        </div>
      </div>
    </div>
  );
};

export default AddUsers;
