import { createSlice } from '@reduxjs/toolkit';
import { ApiUser } from 'src/interfaces/interfaces.generated';
import * as api from '../../api';

export interface WebserviceUsersReducerState {
  error: unknown;
  isLoaded: boolean;
  data: ApiUser[];
}

const initialState: WebserviceUsersReducerState = {
  error: null,
  isLoaded: false,
  data: []
};

const wsUsersSlice = createSlice({
  name: 'WebserviceUsers',
  initialState,
  reducers: {
    getWebserviceUsersRequest(state) {
      state.error = null;
      state.isLoaded = false;
    },
    getWebserviceUsersSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getWebserviceUsersFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  }
});

export const { getWebserviceUsersRequest, getWebserviceUsersSuccess, getWebserviceUsersFailed } =
  wsUsersSlice.actions;

export default wsUsersSlice.reducer;

export const getWebserviceUsers =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getWebserviceUsersRequest());
      const response: ApiUser[] = await api.getWSUsers();
      dispatch(getWebserviceUsersSuccess(response));
      return true;
    } catch (error) {
      dispatch(getWebserviceUsersFailed(error));
    }
  };
