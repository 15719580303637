import { FC } from 'react';

import Icon from 'js/components/Icon';

import t from 'js/utils/translate';
import { LabelGenerationHistory } from 'src/interfaces/interfaces.generated';

import './index.sass';

interface Props {
  labelExcerpt: LabelGenerationHistory;
}

const LabelHistoryLabels: FC<Props> = ({ labelExcerpt }) => (
  <div className='label-history-row-body'>
    <Icon icon='level-up-alt fa-rotate-90' size='sm' />
    <div className='label-history-row-body__links'>
      {labelExcerpt.labelAwsLinks?.length > 0 ? (
        labelExcerpt.labelAwsLinks.map((labelLink, index) => (
          <a className='link' target='_blank' rel='noreferrer' href={labelLink} key={labelLink}>
            {t('orders.labelhistory.get.labels', index + 1, labelExcerpt.labelAwsLinks.length)}
          </a>
        ))
      ) : (
        <div>{t('orders.labelhistory.no.longer.available')}</div>
      )}
    </div>
  </div>
);

export default LabelHistoryLabels;
