export const comma = (listIndex, numElements) => {
  if (listIndex > numElements - 1) return undefined;
  return listIndex < numElements - 1 ? ', ' : '';
};

export const nameString = (element, listIndex, numElements, accessor) => {
  if (listIndex > numElements - 1) return undefined;
  if (!element[accessor]) return undefined;
  return `${element[accessor]}${comma(listIndex, numElements)}`;
};
