import { FC } from 'react';
import classNames from 'classnames';

import t from 'js/utils/translate';
import { Filter } from 'js/components/FilterButtons/interfaces';

import './index.sass';

interface Props {
  filter: Filter;
  selectedFilter: string;
  setSelectedFilter;
}

const FilterButton: FC<Props> = ({ filter, selectedFilter, setSelectedFilter }) => (
  <span
    className={classNames('filter-button', 'link', {
      'filter-button--active': selectedFilter === filter.name
    })}
    onClick={() => setSelectedFilter(filter.name)}
  >
    {t(filter.text)}
  </span>
);

export default FilterButton;
