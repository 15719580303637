import { FC } from 'react';

import { Column } from '../../interfaces';

import './index.sass';

interface Props {
  columns: Column[];
}

const DesktopTableHeader: FC<Props> = ({ columns }) => (
  <div className='desktop-table-header columns'>
    {columns.map(({ Header }) => (
      <h3 key={Header} className='column'>
        {' '}
        {Header}{' '}
      </h3>
    ))}
  </div>
);

export default DesktopTableHeader;
