import { Dispatch, FC, SetStateAction } from 'react';
import { RootState } from 'js/store/reducer';

import ButtonV2 from 'js/components/ButtonV2';
import FormActions from 'js/components/Form/components/FormActions';

import t from 'js/utils/translate';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

interface Props {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  nextDisabled: boolean;
  nextIsSubmit: boolean;
  fromOrder?: string;
}

const ActionButtons: FC<Props> = ({
  currentStep,
  setCurrentStep,
  nextDisabled,
  nextIsSubmit,
  fromOrder
}) => {
  const navigate = useNavigate();

  const isCreatingOrder: boolean = useAppSelector(
    (state: RootState) => state?.orders?.newOrder?.isCreating
  );

  return (
    <div className={`action-buttons action-buttons--${nextIsSubmit ? 'submit' : 'next-step'}`}>
      <ButtonV2
        className='button-previous'
        variant='inverse'
        text={t('general.previous')}
        onClick={() => {
          setCurrentStep(currentStep - 1);
        }}
        disabled={currentStep === 1}
      />
      {nextIsSubmit ? (
        <FormActions
          type='create'
          redirectTo='/'
          cancel={false}
          loading={isCreatingOrder}
          disabled={nextDisabled}
        />
      ) : (
        <ButtonV2
          className='button-next'
          variant='inverse'
          text={t('general.next')}
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
          disabled={nextDisabled}
        />
      )}
      <div className='cancel-button'>
        <span
          className='link'
          onClick={() => {
            navigate(fromOrder ? `/orderSearch/${fromOrder}` : '/orders');
          }}
        >
          {t('general.cancel')}
        </span>
      </div>
    </div>
  );
};

export default ActionButtons;
