import { useEffect } from 'react';

import SelectV2 from 'js/components/Form/components/SelectV2';

import t, { tu } from 'js/utils/translate';
import { getConcerns } from 'js/scenes/Freight/scenes/Concerns/store/getConcernsReducer';
import { concernOptions } from 'js/components/Select/utils';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const SelectConcerns = ({ name }) => {
  const dispatch = useAppDispatch();

  const concerns = useAppSelector((state) => state?.freight?.concerns?.getConcerns?.data);
  const concernsLoaded = useAppSelector((state) => state.freight?.concerns?.getConcerns?.isLoaded);

  useEffect(() => {
    if (!concernsLoaded) dispatch(getConcerns());
  }, []);

  return (
    <SelectV2
      name={name}
      label={tu('general.concerns')}
      placeholder={concernsLoaded ? t('select.concerns') : ''}
      isLoading={!concernsLoaded}
      isMulti
      selectAll
      options={concernOptions(concerns)}
      optional
    />
  );
};

export default SelectConcerns;
