import { createSlice } from '@reduxjs/toolkit';
import { ApiUser } from 'src/interfaces/interfaces.generated';
import * as api from '../../api';

export interface WebserviceUserReducerState {
  error: unknown;
  isCreated: boolean;
  data: ApiUser;
}

const initialState: WebserviceUserReducerState = {
  error: null,
  isCreated: false,
  data: {}
};

const wsUserSlice = createSlice({
  name: 'WebserviceUser',
  initialState,
  reducers: {
    createWebserviceUserRequest(state) {
      state.error = null;
      state.isCreated = false;
      state.data = {};
    },
    createWebserviceUserSuccess(state, action) {
      state.isCreated = true;
      state.data = action.payload;
    },
    createWebserviceUserFailed(state, action) {
      state.error = action.payload;
      state.isCreated = false;
    }
  }
});

export const {
  createWebserviceUserRequest,
  createWebserviceUserSuccess,
  createWebserviceUserFailed
} = wsUserSlice.actions;

export default wsUserSlice.reducer;

export const createWebserviceUser =
  (shopId: number, shopName: string, user: ApiUser) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(createWebserviceUserRequest());
      const response: ApiUser = await api.createWSUser(
        shopId,
        shopName.substr(0, 20).replaceAll('/', ''),
        user
      );
      dispatch(createWebserviceUserSuccess(response));
      return true;
    } catch (error) {
      dispatch(createWebserviceUserFailed(error));
    }
  };
