import { FC, useEffect, useMemo } from 'react';

import Spinner from 'js/components/Spinner';
import InfoBox from 'js/components/InfoBox';
import TableV2 from 'js/components/TableV2';

import t from 'js/utils/translate';
import { findFreightProductById } from 'js/utils/find';

import './index.sass';
import {
  getShopTransportSolutions,
  ShopTransportSolutionsReducerState,
  useShopTransportSolutions
} from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/shopTransportSolutionsReducer';
import { FreightProduct, TransportSolution } from 'src/interfaces/interfaces.generated';
import Tag from 'js/components/Tag';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface ShopTransportSolutionsBodyProps {
  shopId: number | undefined;
  freightProducts: FreightProduct[];
}

const ShopTransportSolutionsBody: FC<ShopTransportSolutionsBodyProps> = ({
  shopId,
  freightProducts
}) => {
  const dispatch = useAppDispatch();

  const shopTransportSolutionsState: ShopTransportSolutionsReducerState =
    useShopTransportSolutions();
  const shopTransportSolutions = shopTransportSolutionsState.data;
  const shopTransportSolutionsLoading = shopTransportSolutionsState.isLoading;
  const shopIdForTransportSolutions = shopTransportSolutionsState.shopId;
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);

  useEffect(() => {
    if (shopId && shopId !== shopIdForTransportSolutions)
      dispatch(getShopTransportSolutions(shopId));
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: t('shop.accordion.table.header.id'),
        accessor: 'transportSolutionId',
        inHeader: true
      },
      {
        Header: t('shop.accordion.transportSolution.table.header.transportSolution'),
        accessor: 'name',
        inHeader: true
      },
      {
        Header: t('shop.accordion.transportSolution.table.header.freightProducts'),
        accessor: (transportSolution: TransportSolution) => (
          <div className='tag-list'>
            {transportSolution.freightProductIds?.map((fpid) => (
              <Tag key={fpid} text={findFreightProductById(freightProducts, fpid)?.name} />
            ))}
          </div>
        )
      },
      {
        Header: t('shop.accordion.transportSolution.table.header.description'),
        accessor: 'description',
        inHeader: true
      },
      {
        Header: t('shop.accordion.transportSolution.table.header.ediCode'),
        accessor: 'ediCode',
        inHeader: true
      },
      {
        Header: t('shop.accordion.transportSolution.table.header.ownerParty'),
        accessor: 'ownerPartyName'
      }
    ],
    [freightProducts]
  );

  if (!shopTransportSolutions || shopTransportSolutionsLoading) return <Spinner />;

  if (shopTransportSolutions.length === 0) {
    return <InfoBox text={t('shop.accordion.empty', t('general.transportSolutions'))} />;
  }

  return (
    <TableV2
      className={isDesktop ? 'shop-transport-solutions-body' : ''}
      columns={columns}
      data={shopTransportSolutions}
      mobileTableVariant='withSmallEditIcon'
    />
  );
};

export default ShopTransportSolutionsBody;
