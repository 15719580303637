import { combineReducers } from 'redux';

import zipGroupsReducer, { ZipGroupsReducerState } from './store/zipGroupsReducer';
import zoneIdsReducer, { ZoneIdsReducerState } from './store/zoneIdsReducer';
import dimensionGroupsReducer, {
  DimensionGroupsReducerState
} from './store/dimensionGroupsReducer';
import weightGroupReducer, { WeightGroupReducerState } from './store/weightGroupsReducer';
import weightBasesReducer, { WeightBasesReducerState } from './store/weightBasesReducer';
import invoiceSystemsReducer, { InvoiceSystemsReducerState } from './store/invoiceSystemsReducer';
import freightProductInvoiceSetupReducer, {
  FreightProductInvoiceSetupReducerState
} from './store/freightProductInvoiceSetup';

const _invoiceSetupReducer = combineReducers({
  zipGroups: zipGroupsReducer,
  zoneIds: zoneIdsReducer,
  dimensionGroups: dimensionGroupsReducer,
  weightGroups: weightGroupReducer,
  weightBases: weightBasesReducer,
  invoiceSystems: invoiceSystemsReducer,
  freightProductInvoiceSetup: freightProductInvoiceSetupReducer
});

export default _invoiceSetupReducer;

export interface _InvoiceSetupReducerState {
  zipGroups: ZipGroupsReducerState;
  zoneIds: ZoneIdsReducerState;
  dimensionGroups: DimensionGroupsReducerState;
  weightGroups: WeightGroupReducerState;
  weightBases: WeightBasesReducerState;
  invoiceSystems: InvoiceSystemsReducerState;
  freightProductInvoiceSetup: FreightProductInvoiceSetupReducerState;
}
