import { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { RootState } from 'js/store/reducer';

import ButtonV2 from 'js/components/ButtonV2';
import PageWrapper from 'js/components/PageWrapper';
import Spinner from 'js/components/Spinner';
import Pagination from 'js/components/Pagination';
import InfoBox from 'js/components/InfoBox';
import GeneratedInvoiceBatchesTable from './components/GeneratedInvoiceBatchesTable';

import { findPageHeaderActions } from 'js/routes';
import { getInvoiceBatches } from './store/getInvoiceBatchesReducer';
import t from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate } from 'react-router-dom';

const ITEMS_PER_PAGE = 20;

const InvoiceBatches: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const isLoading = useAppSelector(
    (state: RootState) => state?.economy?.invoiceBatches?.getInvoiceBatches?.isLoading
  );
  const isLoaded = useAppSelector(
    (state: RootState) => state?.economy?.invoiceBatches?.getInvoiceBatches?.isLoaded
  );
  const allInvoiceBatches = useAppSelector(
    (state: RootState) => state?.economy?.invoiceBatches?.getInvoiceBatches?.data
  );

  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    dispatch(getInvoiceBatches(activePage)).then((response) => {
      if (response) window.scrollTo(0, 0);
    });
  }, [activePage]);

  const userRoles = useAppSelector((state: RootState) => state.user?.data?.roles);
  const pageHeaderAction = findPageHeaderActions(location.pathname, userRoles);

  if (isLoading) return <Spinner pageSpinner />;

  if (isLoaded && isEmpty(allInvoiceBatches)) return <InfoBox text={t('ingen data')} />;

  if (isLoaded) {
    return (
      <PageWrapper className='generate-invoice-page' withoutPadding={!isDesktop}>
        {!isDesktop && pageHeaderAction && (
          <ButtonV2
            className='new-invoice-button'
            onClick={() => {
              navigate(pageHeaderAction[0].path);
            }}
            text={t(pageHeaderAction[0].text)}
          />
        )}
        <div className='invoice-batches'>
          {activePage === 1 && (
            <GeneratedInvoiceBatchesTable
              allowActions
              invoiceBatches={allInvoiceBatches.unprocessedInvoiceBatchesByStatus}
              context='unhandled'
              activePage={activePage}
            />
          )}
          <GeneratedInvoiceBatchesTable
            invoiceBatches={allInvoiceBatches.processedInvoiceBatches}
            context='handled'
          />
        </div>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={ITEMS_PER_PAGE}
          totalItemsCount={allInvoiceBatches.totalProcessedInvoiceBatches}
          onChange={setActivePage}
        />
      </PageWrapper>
    );
  }

  return <></>;
};

export default InvoiceBatches;
