import { useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import PageWrapper from 'js/components/PageWrapper';
import Spinner from 'js/components/Spinner';
import PriceAdjustmentsTable from './components/PriceAdjustmentsTable';
import NewPriceAdjustmentButton from './components/NewPriceAdjustmentButton';

import { getFuturePriceAdjustments } from './store/priceAdjustmentsReducer';
import { getShops } from 'js/scenes/Freight/store/shopsReducer';
import { getConcerns } from 'js/scenes/Freight/scenes/Concerns/store/getConcernsReducer';
import { getFreightProducts } from 'js/store/freightProductsReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const PriceAdjustments = () => {
  const dispatch = useAppDispatch();

  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const futurePriceAdjustmentsLoaded = useAppSelector(
    (state: RootState) => state?.economy?.priceAdjustments?.isLoaded
  );
  const futurePriceAdjustments = useAppSelector(
    (state: RootState) => state?.economy?.priceAdjustments?.data
  );

  const shopsLoaded = useAppSelector((state: RootState) => state?.freight?.shops?.isLoaded);
  const shops = useAppSelector((state: RootState) => state?.freight?.shops?.data);

  const concerns = useAppSelector(
    (state: RootState) => state?.freight?.concerns?.getConcerns?.data
  );
  const concernsLoaded = useAppSelector(
    (state: RootState) => state.freight?.concerns?.getConcerns?.isLoaded
  );

  const freightProductsLoaded = useAppSelector(
    (state: RootState) => state.freightProducts?.isLoaded
  );
  const freightProducts = useAppSelector((state: RootState) => state.freightProducts?.data);

  useEffect(() => {
    if (!freightProductsLoaded) dispatch(getFreightProducts());
    if (!futurePriceAdjustmentsLoaded) dispatch(getFuturePriceAdjustments());
    if (!shopsLoaded) dispatch(getShops());
    if (!concernsLoaded) dispatch(getConcerns());
  }, []);

  if (!futurePriceAdjustmentsLoaded || !shopsLoaded || !concernsLoaded || !freightProductsLoaded)
    return <Spinner pageSpinner />;

  return (
    <PageWrapper withoutPadding={!isDesktop}>
      {!isDesktop && <NewPriceAdjustmentButton />}
      <PriceAdjustmentsTable
        futurePriceAdjustments={futurePriceAdjustments}
        shops={shops}
        concerns={concerns}
        freightProducts={freightProducts}
      />
    </PageWrapper>
  );
};

export default PriceAdjustments;
