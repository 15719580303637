import Ajax from './ajax';
// import { everyDay } from 'lib/clock';
import { encode } from './utils';

const cache = {};
const cacheOptions = {};
const expireTimers = {};

const expire = (url) => {
  const onRemove = cacheOptions[url].onRemove;

  delete cache[url];
  delete cacheOptions[url];
  onRemove && onRemove();
};

const initExpire = (url, options) => {
  clearTimeout(expireTimers[url]);

  if (options.expiresInMinutes) {
    expireTimers[url] = setTimeout(() => expire(url), options.expiresInMinutes * 60 * 1000);
  }
};

const noTransform = (data) => data;

/**
 * options:
 *   disableReset: never remove the data from the cache
 *   expiresInMinutes: remove the data from the cache after expiresInMinutes minutes
 *   onRemove: called when the data is removed from the cache
 *   transform: called with the response data before it's put in the cache
 */
const cached = (key, options = {}, ajaxFunc) => {
  if (!cache[key]) {
    cache[key] = ajaxFunc().then(options.transform || noTransform);
    cacheOptions[key] = options;
    initExpire(key, options);
  }

  return cache[key];
};

export const getCached = (url, options) => cached(url, options, Ajax.get.bind(null, url));

export const postCached = (url, data, options) =>
  cached(url + encode(data), options, Ajax.post.bind(null, url, data));

export const resetCache = () =>
  Object.keys(cache)
    .filter((url) => !cacheOptions[url].disableReset)
    .forEach(expire);
