import { FooterBox } from 'js/components/FooterBoxes/interfaces';
import Invoice from 'images/icons/Fakturagrunnlag.svg';
import InvoiceSetup from 'images/icons/Fakturaoppsett.svg';
import PriceAdjustments from 'images/icons/Prisjusteringer.svg';
import CostPrices from 'images/icons/Kostpriser.svg';
import Labels from 'images/icons/Labels.svg';
import LabelHistory from 'images/icons/LabelHistory.svg';
import NewOrder from 'images/icons/NewOrder.svg';
import BulkOrder from 'images/icons/BulkOrder.svg';

export const economyFooterBoxes: FooterBox[] = [
  {
    headerText: 'generateInvoice',
    link: '/economy/generateInvoice',
    icon: Invoice
  },
  {
    headerText: 'invoice',
    link: '/economy/invoice',
    icon: Invoice
  },
  {
    headerText: 'priceAdjustments',
    link: '/economy/priceAdjustments',
    icon: PriceAdjustments
  },
  {
    headerText: 'costPriceForFreight',
    link: '/economy/costPrices',
    icon: CostPrices
  },
  {
    headerText: 'invioceSetupForFreightProducts',
    link: '/economy/invoiceSetup',
    icon: InvoiceSetup
  }
];

export const orderFooterBoxes: FooterBox[] = [
  { headerText: 'labels', link: '/orders/labels', icon: Labels },
  {
    headerText: 'labelHistory',
    link: '/orders/labelHistory',
    icon: LabelHistory
  },
  { headerText: 'bulkOrder', link: '/orders/bulkOrder', icon: BulkOrder },
  { headerText: 'newOrder', link: '/orders/newOrder', icon: NewOrder }
];
