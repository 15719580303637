import { findFreightProductById, findShopById } from 'js/utils/find';
import { format } from 'date-fns';

import {
  ApiFlowShopSimple,
  ApiOrderSimple,
  FreightProduct
} from 'src/interfaces/interfaces.generated';

export const isPartOf = (data: string | undefined, filterInput: string): boolean | undefined =>
  data?.toLowerCase().includes(filterInput.toLowerCase());

const containsShopId = (shopId: number | undefined, filterInput: string) => shopId === +filterInput;

const containsDate = (date: string | undefined, filterInput: string) =>
  date ? format(new Date(date), 'dd.MM.yyyy').includes(filterInput) : false;

export const filterOrders = (
  orders: ApiOrderSimple[],
  filterInput: string,
  shops: ApiFlowShopSimple[],
  freightProducts: FreightProduct[]
): ApiOrderSimple[] =>
  orders.filter(
    (order) =>
      isPartOf(order?.consignee?.name, filterInput) ||
      isPartOf(order?.consignee?.zipCode, filterInput) ||
      isPartOf(order?.consignee?.postalName, filterInput) ||
      isPartOf(order?.consignor?.name, filterInput) ||
      isPartOf(order?.consignor?.zipCode, filterInput) ||
      isPartOf(order?.consignor?.postalName, filterInput) ||
      isPartOf(order?.trackingReference, filterInput) ||
      isPartOf(order?.shipmentId, filterInput) ||
      isPartOf(findShopById(shops, order?.shopId)?.name, filterInput) ||
      isPartOf(
        findFreightProductById(freightProducts, order?.freightProductId)?.name,
        filterInput
      ) ||
      isPartOf(order?.pickup?.pickupCode, filterInput) ||
      containsShopId(order?.shopId, filterInput) ||
      containsDate(order?.importedDate, filterInput) ||
      containsDate(order?.pickup?.senderDropDate, filterInput)
  );
