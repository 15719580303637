import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../api';
import { SystemInfoState } from '../interfaces';

const initialState: SystemInfoState = {
  data: [],
  isLoaded: false,
  isSaving: false,
  error: undefined
};

const systemInfoSlice = createSlice({
  name: 'systemInfo',
  initialState,
  reducers: {
    getSystemInfoRequest(state) {
      state.error = undefined;
      state.isLoaded = false;
    },
    getSystemInfoSuccess(state, action) {
      state.data = action.payload;
      state.isLoaded = true;
    },
    getSystemInfoFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    },
    updateSystemInfoRequest(state) {
      state.error = undefined;
      state.isSaving = true;
    },
    updateSystemInfoSuccess(state, action) {
      const index: number = state.data.findIndex(
        ({ systemInfoId }) => systemInfoId === action.payload.systemInfoId
      );
      state.isSaving = false;
      if (state.data) state.data[index] = action.payload;
    },
    updateSystemInfoFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    }
  }
});

export const {
  getSystemInfoRequest,
  getSystemInfoSuccess,
  getSystemInfoFailed,
  updateSystemInfoRequest,
  updateSystemInfoSuccess,
  updateSystemInfoFailed
} = systemInfoSlice.actions;

export default systemInfoSlice.reducer;

export const getSystemInfo = () => async (dispatch) => {
  try {
    dispatch(getSystemInfoRequest());
    const response = await rest.getSystemInfo();
    dispatch(getSystemInfoSuccess(response));
  } catch (error) {
    dispatch(getSystemInfoFailed(error));
  }
};

export const updateSystemInfo =
  (systemInfo) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(updateSystemInfoRequest());
      const response = await rest.updateSystemInfo(systemInfo);
      dispatch(updateSystemInfoSuccess(response));
      return true;
    } catch (error) {
      dispatch(updateSystemInfoFailed(error));
    }
  };
