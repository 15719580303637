/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import {
  useFormContext,
  useRegister,
  useValue,
  withError
} from '@distribution-innovation/form-hooks';
import { RootState } from 'js/store/reducer';

import _AddressHelper from 'js/components/AddressHelper';
import InputError from '../Validation/InputError';
import Spinner from 'js/components/Spinner';
import { getOwnerParties } from 'js/store/ownerPartiesReducer';
import { findCountryCode } from './utils';

import { ApiOwnerParty } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface Props {
  name?: string;
  optional?: boolean;
  error?: any;
}

const AddressHelper: FC<Props> = ({ name = 'address', optional = false, error }) => {
  const required = !optional;

  const context = useFormContext();
  const dispatch = useAppDispatch();

  const [selectedAddress, setSelectedAddress] = useValue(name);

  const handleBlur = () => context.getTouched(name) || context.setTouched(name);

  const validate = (address) =>
    required && !address?.isComplete ? 'addressNotComplete' : undefined;

  useRegister({ name, value: selectedAddress, validate });

  useEffect(() => context.setTouched(name), []);

  const activeOwnerParty: number = useAppSelector(
    (state: RootState) => state?.user?.data?.activeOwnerParty
  );
  const ownerParties: ApiOwnerParty[] = useAppSelector(
    (state: RootState) => state?.ownerParties?.data
  );
  const ownerPartiesLoaded: boolean = useAppSelector(
    (state: RootState) => state?.ownerParties?.isLoaded
  );

  const [countryCode, setCountryCode] = useState<string | undefined>();

  useEffect(() => {
    if (ownerPartiesLoaded) setCountryCode(findCountryCode(ownerParties, activeOwnerParty));
    else dispatch(getOwnerParties());
  }, [ownerPartiesLoaded]);

  if (!countryCode) return <Spinner />;

  return (
    <>
      <_AddressHelper
        setAddress={setSelectedAddress}
        address={selectedAddress}
        handleBlur={handleBlur}
        countryCode={countryCode}
      />
      {error && <InputError error={error} />}
    </>
  );
};

export default withError()(AddressHelper);
