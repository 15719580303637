import { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { RootState } from 'js/store/reducer';
import { activeOwnerPartyLogoAttributes } from 'js/utils/activeOwnerPartyLogoAttributes';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';

const CompanyLogo: FC = () => {
  const activeOwnerParty = useAppSelector(
    (state: RootState) => state?.user?.data?.activeOwnerParty
  );

  return (
    <Link to='/'>
      <div className='company-logo'>
        <div
          className={classNames('logo', {
            logo__helthjem: activeOwnerParty === 1
          })}
        >
          {activeOwnerPartyLogoAttributes('logo', activeOwnerParty)}
        </div>
      </div>
    </Link>
  );
};

export default CompanyLogo;
