import Icon from 'js/components/Icon';

import t from 'js/utils/translate';
import { CustomerSystemChannelPreference } from '../../../interfaces';

export const showCheckmarkWithNameIfActive = (data, name) => (
  <>
    {data ? <Icon icon='check' /> : <Icon icon='horizontal-rule' />}
    <span>{t(`shop.accordion.${name}`)}</span>
  </>
);

export const findCustomerSystemChannelPreferenceNameById = (
  preferences: CustomerSystemChannelPreference[],
  preferenceId: number | undefined
): string | undefined =>
  preferenceId
    ? preferences.find((preferences) => preferences.value === +preferenceId)?.label
    : undefined;
