import Ajax from 'js/utils/ajax/ajax';
import { ApiOrder, OrderParty } from 'src/interfaces/interfaces.generated';
import { OrderPartyType } from '../interfaces';

export const editOrderParty = (
  trackingReference: string,
  orderPartyType: OrderPartyType,
  shipment: OrderParty
): Promise<void> =>
  Ajax.patch(`/orders/v2/${trackingReference}/orderparties/${orderPartyType}`, shipment);

export const getFullOrder = (trackingReference: string): Promise<ApiOrder> =>
  Ajax.get(`/orders/v2/${trackingReference}`);
