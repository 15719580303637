import PropTypes from 'prop-types';

import './index.sass';

const InfoBox = ({ text }) => <div className='info-box'>{text}</div>;

export default InfoBox;

InfoBox.propTypes = {
  text: PropTypes.string.isRequired
};
