import { FC } from 'react';
import classNames from 'classnames';

import Info from './components/Info';
import t from 'js/utils/translate';

import './index.sass';
import Tooltip from 'js/components/Tooltip';

interface Props {
  className?: string;
  label: string;
  htmlFor?: string;
  subLabel?: boolean;
  info?: string;
  tooltip?: boolean;
  optional?: boolean;
  hideOptional?: boolean;
  children?: any;
}

const Label: FC<Props> = ({
  className,
  label,
  htmlFor,
  subLabel,
  info,
  tooltip,
  optional = false,
  hideOptional = false,
  children
}) => (
  <label
    htmlFor={htmlFor}
    className={classNames('label-wrapper', className, { sublabel: subLabel })}
  >
    {children && children}
    <span className='label'>{label}</span>
    {info && ((tooltip && <Tooltip text={info} />) || <Info info={info} />)}
    {optional && !hideOptional && <Info info={t('general.optional')} />}
  </label>
);

export default Label;
