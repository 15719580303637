import t from 'js/utils/translate';
import { Role, Shop, User, UserType } from 'js/scenes/UserAdministration/Users.types';
import {
  toastDelayMs,
  validUsername,
  webServiceTranslationPrefix
} from 'js/scenes/UserAdministration/utils/constants';
import { Slide, ToastOptions } from 'react-toastify';

export const filterUsers = (users: User[], searchWord: string) => {
  const searchWordFormatted = searchWord.toLowerCase().trim();
  return users?.filter((user: User) => {
    if (
      `${user?.firstName} ${user?.lastName}`.toLowerCase().includes(searchWordFormatted) ||
      user?.username?.toLowerCase().includes(searchWordFormatted) ||
      user?.email?.toLowerCase().includes(searchWordFormatted) ||
      user?.shops?.some((shop) => shop?.name?.toLowerCase().includes(searchWordFormatted)) ||
      user?.roles?.some((role) => role?.name?.toLowerCase().includes(searchWordFormatted)) ||
      user?.webServices?.some((webService) =>
        t(`${webServiceTranslationPrefix}.${webService}`)
          .toLowerCase()
          .includes(searchWordFormatted)
      )
    ) {
      return user;
    }
    return false;
  });
};

export const shopOptions = (shops: Shop[]) =>
  shops?.map((shop) => ({
    label: shop.name,
    value: shop.id
  }));

export const roleOptions = (roles: Role[]) =>
  roles?.map((role) => ({
    label: t(role.name),
    value: role.roleId
  }));

export const webServiceOptions = (webServices: number[]) =>
  webServices?.map((webService) => ({
    label: t(`${webServiceTranslationPrefix}.${webService}`),
    value: webService
  }));

export const userOptions = (users: User[]) =>
  users.map((user) => ({ label: user.username, value: user }));

export const isUsernameValid = (username: string) => validUsername.test(username);

export const usernameValidationStatus = (
  editMode: boolean,
  usernameAvailable: {
    data?: { available: boolean };
    isLoading: boolean;
  }
) =>
  !editMode &&
  ((usernameAvailable.isLoading && t('input.validation.loading')) ||
    (usernameAvailable.data?.available && t('input.validation.usernameAvailable')));

export const usernameErrorStatus = (
  editMode: boolean,
  username: string,
  usernameAvailable: {
    data?: { available: boolean };
    isLoading: boolean;
    isIdle: boolean;
    isError: boolean;
  }
) =>
  (!editMode &&
    username.trim() !== '' &&
    ((!isUsernameValid(username) && 'usernameInvalid') ||
      (!usernameAvailable.isLoading &&
        !usernameAvailable.isIdle &&
        (usernameAvailable.isError || !usernameAvailable.data?.available) &&
        'usernameUnavailable'))) ||
  undefined;

export const usernameInfoLabel = (isEditMode: boolean, type: UserType) =>
  !isEditMode && type === 'api' ? t('userAdministration.form.username.info') : undefined;

// Prepend 'ws' when it's api user
export const getUsername = (type: UserType, username: string) =>
  `${type === 'api' ? 'ws' : ''}${username}`;

export const toastOptions: (delayMs?: number) => ToastOptions = (delayMs = toastDelayMs) => ({
  position: 'bottom-center',
  autoClose: delayMs,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: 'di',
  transition: Slide
});
