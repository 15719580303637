import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';
import t from 'js/utils/translate';

const InvoiceSystem = ({ invoiceSystemCode }) => {
  const invoiceSystems = useAppSelector(
    (state: RootState) => state.economy?.invoiceSetup?.invoiceSystems?.data
  );

  return (
    <div>
      {t(
        'economy.invoiceSetup.invoiceSystem',
        invoiceSystems?.name ? ` (${invoiceSystems.name})` : '',
        invoiceSystemCode ?? t('general.none')
      )}
    </div>
  );
};

export default InvoiceSystem;
