import { FC, ReactElement } from 'react';
import classNames from 'classnames';

import ButtonV2, { ButtonProps } from 'js/components/ButtonV2';
import Icon from 'js/components/Icon';

import { RootState } from 'js/store/reducer';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';

type StatusHeaderButtonProps = Pick<
  ButtonProps,
  'className' | 'text' | 'disabled' | 'variant' | 'onClick'
>;

interface StatusHeaderProps {
  color: 'green' | 'yellow';
  statusText: string | ReactElement;
  buttons?: StatusHeaderButtonProps[];
  info?: string | ReactElement | boolean;
  mobileToggleSection?: any;
  showMobileSection?: boolean;
}

const StatusHeader: FC<StatusHeaderProps> = ({
  color = 'green',
  statusText = '',
  buttons,
  info,
  mobileToggleSection,
  showMobileSection
}) => {
  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  return (
    <div className='status-header' onClick={mobileToggleSection || undefined}>
      <div className={classNames('status-header__status', `status-header__status--${color}`)}>
        {statusText && <span className='status-header__status-text'>{statusText}</span>}
        {!isDesktop && mobileToggleSection !== undefined && (
          <Icon icon={showMobileSection ? 'angle-down' : 'angle-right'} size='2x' light />
        )}
      </div>
      <div className='status-header__buttons'>
        {buttons?.map((button) => (
          <ButtonV2
            key={button?.text}
            className={button?.className}
            text={button?.text}
            disabled={button?.disabled}
            variant={button?.variant}
            onClick={button?.onClick}
          />
        ))}
      </div>

      {info && <div className='status-header__info'>{info}</div>}
    </div>
  );
};

export default StatusHeader;
