import { FC, useEffect, useMemo } from 'react';
import { RootState } from 'js/store/reducer';

import Spinner from 'js/components/Spinner';
import InfoBox from 'js/components/InfoBox';
import TableV2 from 'js/components/TableV2';
import LabeledItem from 'js/components/LabeledItem';
import Tags from '../Tags';

import t, { tu } from 'js/utils/translate';
import { findAdminRights } from 'js/scenes/Freight/components/Users/components/SelectAdminRights/utils';
import { getShopUsers } from '../../../../store/shopUsersReducer';
import { filteredShopUsers } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/utils';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { Column } from 'js/components/TableV2/interfaces';

interface Props {
  shopId?: number;
}

const ShopUsersBody: FC<Props> = ({ shopId }) => {
  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const dispatch = useAppDispatch();

  const usersLoaded = useAppSelector(
    (state: RootState) => state?.freight?.shop?.shopUsers?.isLoaded
  );
  const users = useAppSelector((state: RootState) => state?.freight?.shop?.shopUsers?.data);

  useEffect(() => {
    if (shopId) dispatch(getShopUsers(shopId));
  }, []);

  const shopUsers = filteredShopUsers(users) ?? false;

  const columns: Array<Column<any>> = useMemo(
    () => [
      {
        Header: t('general.name'),
        accessor: ({ firstName, lastName }) => (
          <span className='shop-users-table__name'>{`${firstName} ${lastName}`}</span>
        ),
        inHeader: true
      },
      {
        Header: t('general.username'),
        accessor: ({ userName }) =>
          isDesktop ? userName : <LabeledItem label={tu('general.username')} item={userName} />
      },
      {
        Header: t('general.email'),
        accessor: ({ email }) =>
          isDesktop ? email : <LabeledItem label={tu('general.email')} item={email} />
      },
      {
        Header: t('general.language'),
        accessor: ({ language }) =>
          isDesktop ? (
            t(`general.${language}`)
          ) : (
            <LabeledItem label={tu('general.language')} item={t(`general.${language}`)} />
          )
      },
      {
        Header: t('general.roles'),
        accessor: (user) => {
          const roles = (
            <Tags list={findAdminRights(user)} translationKeyPrefix='user.adminRight' />
          );
          return isDesktop ? roles : <LabeledItem label={tu('general.apiAccess')} item={roles} />;
        }
      }
    ],
    [isDesktop]
  );

  if (!usersLoaded) return <Spinner />;

  if (!shopUsers) return <Spinner />;

  if (shopUsers.length === 0) {
    return <InfoBox text={t('shop.accordion.empty', t('general.users'))} />;
  }

  return (
    <TableV2
      className={isDesktop ? 'shop-users-desktop-table' : 'shop-users-mobile-table'}
      columns={columns}
      data={shopUsers}
      mobileTableVariant='withSmallEditIcon'
    />
  );
};

export default ShopUsersBody;
