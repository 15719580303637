import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface CarNumbersReducerState {
  error: unknown;
  isLoaded: boolean;
  data: string[];
}

const initialState: CarNumbersReducerState = {
  error: null,
  isLoaded: false,
  data: []
};

const carNumbersSlice = createSlice({
  name: 'CarNumbers',
  initialState,
  reducers: {
    getCarNumbersRequest(state) {
      state.error = null;
      state.isLoaded = false;
    },
    getCarNumbersSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getCarNumbersFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  }
});

export const { getCarNumbersRequest, getCarNumbersSuccess, getCarNumbersFailed } =
  carNumbersSlice.actions;

export default carNumbersSlice.reducer;

export const getCarNumbers = () => async (dispatch) => {
  try {
    dispatch(getCarNumbersRequest());
    const response: string[] = await rest.getMainCarNumbers();
    dispatch(getCarNumbersSuccess(response));
  } catch (err) {
    dispatch(getCarNumbersFailed(err));
  }
};
