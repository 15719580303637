import { FC } from 'react';

import DateTime from 'js/components/DateTime';
import EventIcon from './components/EventIcon';
import VerticalLine from './components/VerticalLine';

import t from 'js/utils/translate';
import { ApiFlowLogEvent } from 'src/interfaces/interfaces.generated';

import './index.sass';
import { safariSafeDateString } from 'js/utils/dateTime';

interface Props {
  orderEvent: ApiFlowLogEvent;
  hasConnectedLine: boolean;
}

const OrderEvent: FC<Props> = ({ orderEvent, hasConnectedLine }) => (
  <div className='order-event'>
    {hasConnectedLine && <VerticalLine />}
    <EventIcon eventGroupId={orderEvent?.eventGroup?.id} />
    <div className='order-event__info'>
      <div>
        {t(`${orderEvent?.eventType?.i18nKey}`)}
        {orderEvent?.additionalInfo?.i18nKey ? t(`${orderEvent.additionalInfo.i18nKey}`) : ''}
      </div>
      {orderEvent?.eventTime && (
        <DateTime datetime={new Date(safariSafeDateString(orderEvent.eventTime))} row />
      )}
      <div>{orderEvent.locationContext ?? orderEvent.comChannelType}</div>
      <div>{orderEvent.changeUser}</div>
    </div>
  </div>
);

export default OrderEvent;
