import { FC } from 'react';

import t from 'js/utils/translate';

import './index.sass';

const Error: FC = () => (
  <div className='link-error'>{t('form.error.oops.something.went.wrong')}</div>
);

export default Error;
