import { FC, useState } from 'react';
import { RootState } from 'js/store/reducer';

import classNames from 'classnames';

import MessageForUser from 'js/components/MessageForUser';

import t from 'js/utils/translate';
import ShowMoreButton from 'js/components/ShowMoreButton';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

const EditStatusInfo: FC = () => {
  const isDesktop = useAppSelector((state: RootState) => state.mediaDevice?.isDesktop);
  const [showMore, toggleShowMore] = useState(isDesktop);

  return (
    <MessageForUser variant='info' className={classNames('edit-status-info')}>
      <div>
        <div className='info__top-text'>{t('edit.order.status.info.header')}</div>
        {showMore && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: t('edit.order.status.info.body')
              }}
            />
            <div className='info__bottom-text'>{t('edit.order.status.info.footnote')}</div>
          </>
        )}
      </div>
      {!isDesktop && (
        <ShowMoreButton
          hideText={t('edit.order.status.info.hide')}
          showText={t('edit.order.status.info.show')}
          show={showMore}
          toggleShow={toggleShowMore}
        />
      )}
    </MessageForUser>
  );
};

export default EditStatusInfo;
