import { FC, useEffect, useState } from 'react';
import { usersList } from 'js/scenes/UserAdministration/api/queryKeys';
import { userAdministrationPath } from 'js/scenes/UserAdministration/utils/constants';
import { useDisconnectUserMutation } from 'js/scenes/UserAdministration/api/api';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import DisconnectUserView from 'js/scenes/UserAdministration/UserActions/Disconnect/DisconnectUser.view';
import { DisconnectUserProps } from 'js/scenes/UserAdministration/UserActions/Disconnect/DisconnectUser.types';
import t from 'js/utils/translate';
import * as amplitude from '@amplitude/analytics-browser';
import { Amplitude } from 'js/scenes/UserAdministration/utils/amplitude';

const DisconnectUser: FC<DisconnectUserProps> = ({ setShowModal }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [error, setError] = useState<boolean>(false);

  async function onMutationSuccess() {
    amplitude.track(Amplitude.USER_DISCONNECT_SUCCESS, Amplitude.getMetricProperties(state?.type));
    queryClient.removeQueries([usersList]);
    navigate(userAdministrationPath, {
      state: { type: state?.type, toastMessage: t('userAdministration.toast.disconnected') }
    });
  }

  const disconnectUser = useDisconnectUserMutation(onMutationSuccess);

  useEffect(() => {
    amplitude.track(
      Amplitude.USER_DISCONNECT_INITIALIZED,
      Amplitude.getMetricProperties(state?.type)
    );
  }, []);

  function handleSubmit() {
    const userId = state?.user?.userId;
    if (userId) {
      disconnectUser.mutate({ userIds: [userId] });
    } else {
      setError(true);
    }
  }

  return (
    <>
      <DisconnectUserView
        isError={disconnectUser.isError || error}
        isLoading={disconnectUser.isLoading}
        handleSubmit={handleSubmit}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default DisconnectUser;
