import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router';

import EnvironmentRibbon from '../components/EnvironmentRibbon';
import CompanyLogo from '../components/CompanyLogo';
import SettingsButton from '../components/SettingsButton';
import HamburgerMenuIcon from './components/HamburgerMenuIcon';
import HamburgerMenu from './components/HamburgerMenu';
import PageWrapper from 'js/components/PageWrapper';

import './index.sass';
import JobsButton from 'js/components/AppHeader/components/JobsButton';
import { useAppSelector } from 'js/utils/hooks';

const MobileHeader = () => {
  const location = useLocation();
  const [open, setOpen] = useState(null);

  const handleHamburgerMenuClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (open) setOpen(false);
  }, [location]);

  const isMenuClosed = (open) => !(open === null) && !open;

  const hamburgerMenu = useRef(null);
  const hamburgerMenuIcon = useRef(null);

  const handleClickOutside = (event) => {
    if (
      !hamburgerMenu.current.contains(event.target) &&
      !hamburgerMenuIcon.current.contains(event.target)
    ) {
      if (open) setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hamburgerMenu, hamburgerMenuIcon, open]);

  const userRoles = useAppSelector((state) => state?.user?.data?.roles);

  return (
    <>
      <PageWrapper className='mobile-header'>
        <EnvironmentRibbon />
        <div className='mobile-header__content-container'>
          <CompanyLogo />
        </div>
        <div className='mobile-header__content-container'>
          {userRoles.di && <JobsButton />}
          <SettingsButton />
          <div
            ref={hamburgerMenuIcon}
            className='hamburger-icon'
            onClick={handleHamburgerMenuClick}
          >
            <HamburgerMenuIcon open={open} />
          </div>
        </div>
      </PageWrapper>
      <div
        ref={hamburgerMenu}
        className={classNames(
          'mobile-menu',
          { 'mobile-menu--open': open },
          { 'mobile-menu--closed': isMenuClosed(open) }
        )}
      >
        <HamburgerMenu open={open} />
      </div>
    </>
  );
};

export default MobileHeader;
