import { FC } from 'react';

import DashboardDataText from './components/DashboardDataText';
import DashboardDataScope from './components/DashboardDataScope';

import './index.sass';

interface Props {
  text: string;
  scope: string;
}

const DasboardDataDescription: FC<Props> = ({ text, scope }) => (
  <div className='dashboard-data-description'>
    <DashboardDataText text={text} />
    <DashboardDataScope scope={scope} />
  </div>
);

export default DasboardDataDescription;
