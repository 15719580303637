import { isBefore } from 'date-fns';
import { TransportPriceTable, ApiPriceChangeStatistic } from 'src/interfaces/interfaces.generated';

export const findChangeStat = (
  freightProductPrices: TransportPriceTable
): ApiPriceChangeStatistic | undefined => {
  if (freightProductPrices?.priceChangeStatistics) {
    const filteredStats = freightProductPrices.priceChangeStatistics.filter(
      (statistic) => statistic.fromDate && isBefore(new Date(), new Date(statistic.fromDate))
    );

    if (filteredStats && filteredStats.length > 0) return filteredStats[0];
    else return undefined;
  } else return undefined;
};
