import { FC, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import Spinner from 'js/components/Spinner';
import FormV2 from 'js/components/FormV2';
import Input from 'js/components/Form/components/Input';
import TextArea from 'js/components/Form/components/TextArea';
import SelectRebook from './components/SelectRebook';
import SelectWantedConversion from './components/SelectWantedConversion';
import NotFound from 'js/components/NotFound';

import t, { tu } from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import { editFreightProduct, resetFreightProductError } from 'js/store/freightProductsReducer';

import './index.sass';
import { RootState } from 'js/store/reducer';
import { findFreightProductById } from 'js/utils/find';
import RadioGroup from 'js/components/Form/components/RadioGroup';
import Label from 'js/components/Label';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
// eslint-disable-next-line max-len
import SelectProofOfDelivery from 'js/scenes/Freight/scenes/FreightProducts/scenes/EditFreightProduct/components/SelectProofOfDelivery';

const EditFreightProduct: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const redirectTo = '/freight/freightProducts';

  const freightProducts = useAppSelector((state: RootState) => state.freightProducts.data);
  const freightProductLoaded = useAppSelector(
    (state: RootState) => state.freightProducts?.isLoaded
  );
  const error = useAppSelector((state: RootState) => state?.freightProducts?.error);

  const freightProduct = findFreightProductById(freightProducts, params.freightProductId);

  const yesNoOptions = [
    {
      value: 'true',
      label: t('general.yes')
    },
    {
      value: 'false',
      label: t('general.non')
    }
  ];

  useEffect(() => {
    dispatch(resetFreightProductError());
  }, []);

  const handleSubmit = (values) => {
    const updatedFreightProduct = cloneDeep({
      ...values,
      notifyAboutDisabledTracking: values.notifyAboutDisabledTracking === 'true'
    });

    // frontend uses 0 for not selected, but backend expects null or positive integer
    updatedFreightProduct.rebookTransportSolutionId ||= null;
    updatedFreightProduct.wantedConversionTransportSolutionId ||= null;

    dispatch(editFreightProduct(updatedFreightProduct)).then((success) => {
      if (success) {
        location.state ? navigate(-1) : navigate(redirectTo);
      }
    });
  };

  if (!freightProductLoaded && !error) return <Spinner />;
  if (!freightProduct?.freightProductId) return <NotFound />;

  const initialValues = {
    ...freightProduct,
    notifyAboutDisabledTracking: freightProduct.notifyAboutDisabledTracking ? 'true' : 'false'
  };

  return (
    <SplitPage>
      <PageWrapper className='edit-freight-product'>
        <FormV2
          onSubmit={handleSubmit}
          initialValues={initialValues}
          redirectTo={redirectTo}
          error={error}
        >
          <div className='edit-freight-product__form-children-wrapper'>
            <Input name='name' label={tu('general.name')} autoFocus />
            <Input name='labelName' label={tu('general.labelname')} />
            <TextArea
              name='description'
              label={tu('general.description')}
              className='freight-product-description__text-area'
            />
            <div className='edit-freight-product__select-wrapper'>
              <SelectRebook name='rebookTransportSolutionId' />
              <SelectWantedConversion name='wantedConversionTransportSolutionId' />
            </div>
            <div className='edit-freight-product__select-wrapper'>
              <SelectProofOfDelivery
                name='proofOfDelivery'
                initialValue={freightProduct.proofOfDelivery}
                label={tu('edit.freightProduct.proofOfDelivery')}
              />
            </div>
            <Input
              name='maxWeightGrams'
              label={tu('edit.freightProduct.form.weight')}
              info={t('edit.freightProduct.form.info.gram')}
              optional
            />
            <Input
              name='maxValue'
              label={tu('edit.freightProduct.form.value')}
              info={t('edit.freightProduct.form.info.kr')}
              optional
            />
            <Input
              name='maxWidthMM'
              label={tu('edit.freightProduct.form.volume')}
              info={t('edit.freightProduct.form.info.width')}
              optional
            />
            <Input
              name='maxHeightMM'
              label={tu('edit.freightProduct.form.volume')}
              info={t('edit.freightProduct.form.info.height')}
              optional
            />
            <Input
              name='maxLengthMM'
              label={tu('edit.freightProduct.form.volume')}
              info={t('edit.freightProduct.form.info.length')}
              optional
            />
            <div className='edit-freight-product__section'>
              <Label
                label={tu('edit.freightProduct.form.notifyAboutDisabledTracking')}
                info={t('edit.freightProduct.form.info.notifyAboutDisabledTracking')}
              />
              <RadioGroup
                name='notifyAboutDisabledTracking'
                radios={yesNoOptions}
                align='row'
                label=''
              />
            </div>
          </div>
        </FormV2>
      </PageWrapper>
    </SplitPage>
  );
};

export default EditFreightProduct;
