import { FC, ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import classNames from 'classnames';

import './index.sass';

interface LinkProps {
  text: string | ReactNode;
  internal: boolean;
  to: string | false;
  active?: boolean;
  noUnderline?: boolean;
}

const Link: FC<LinkProps> = ({ text, internal, to, active, noUnderline }) =>
  to ? (
    <ReactRouterLink
      to={to}
      className={classNames('link', {
        'link--internal': internal,
        'link--active': active,
        'link--no-underline': noUnderline
      })}
    >
      {text}
    </ReactRouterLink>
  ) : (
    <div
      className={classNames('link', {
        'link--internal': internal,
        'link--active': active,
        'link--no-underline': noUnderline
      })}
    >
      {' '}
      {text}
    </div>
  );
export default Link;
