import { listBody } from 'js/components/CollapseV2/variants/listBody';
import InfoBox from 'js/components/InfoBox';
import Address from '../../../Address';

import t from 'js/utils/translate';
import { content } from '../../../../utils';

import './index.sass';

const hasEconomyInformation = ({
  billingReceiver,
  billingEmail,
  billingCustomerNumber,
  billingReference,
  billingStreetName,
  billingStreetNumber,
  billingPostalCode,
  billingCity,
  billingCountry
}) =>
  content(billingReceiver) &&
  content(billingEmail) &&
  content(billingCustomerNumber) &&
  content(billingReference) &&
  content(billingStreetName) &&
  content(billingStreetNumber) &&
  content(billingPostalCode) &&
  content(billingCity) &&
  content(billingCountry);

const EconomyBody = ({ shop }) => {
  const translateKeyPrefix = 'shop.accordion.economy.header.';
  const className = 'shop-economy-body';

  if (!hasEconomyInformation(shop)) {
    return <InfoBox text={t('shop.accordion.empty', t('general.economy.information'))} />;
  }

  return listBody(
    [
      {
        label: 'billingReceiver',
        content: content(shop.billingReceiver),
        orientation: 'row'
      },
      {
        label: 'billingEmail',
        content: content(shop.billingEmail),
        orientation: 'row'
      },
      {
        label: 'billingCustomerNumber',
        content: content(shop.billingCustomerNumber),
        orientation: 'row'
      },
      {
        label: 'billingReference',
        content: content(shop.billingReference),
        orientation: 'row'
      },
      {
        label: 'address',
        content: <Address data={shop} accessorPrefix='billing' />,
        orientation: 'row'
      }
    ],
    translateKeyPrefix,
    className
  );
};

export default EconomyBody;
