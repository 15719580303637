import { useEffect } from 'react';

import SplitPage from 'js/components/SplitPage';
import Spinner from 'js/components/Spinner';
import PageWrapper from 'js/components/PageWrapper';
import TransportSolutionForm from '../../components/TransportSolutionForm';

import { findTransportSolutionById } from 'js/utils/find';

import './index.sass';
import {
  editTransportSolution,
  getTransportSolutions,
  useTransportSolutions
} from 'js/store/transportSolutionsReducer';
import { useAppDispatch } from 'js/utils/hooks';
import { useNavigate, useParams } from 'react-router-dom';

const EditTransportSolutions = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const transportSolutionsState = useTransportSolutions();
  const transportSolutionsLoaded = transportSolutionsState.isLoaded;
  const transportSolutions = transportSolutionsState.data;

  useEffect(() => {
    if (!transportSolutionsLoaded) dispatch(getTransportSolutions());
  }, [transportSolutionsLoaded]);

  const redirectTo = '/freight/transportSolutions';

  const handleSubmit = async (values) => {
    await dispatch(editTransportSolution(values)).then((response) => {
      response && navigate(redirectTo);
    });
  };

  if (!transportSolutionsLoaded) return <Spinner />;

  return (
    <SplitPage>
      <PageWrapper className='edit-transport-solutions-page'>
        <TransportSolutionForm
          handleSubmit={handleSubmit}
          initialValues={findTransportSolutionById(transportSolutions, params.transportSolutionId)}
          redirectTo={redirectTo}
        />
      </PageWrapper>
    </SplitPage>
  );
};

export default EditTransportSolutions;
