import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import ColoredSection from 'js/components/ColoredSection';
import Input from 'js/components/Form/components/Input';

import { tu } from 'js/utils/translate';

import './index.sass';
import ZipCode from 'js/scenes/Freight/scenes/Shops/scenes/Shop/components/AddressInput/components/ZipCode';

const AddressInput = ({ className, label, namePrefix, optional }) => (
  <ColoredSection
    className={classNames('address-input', className)}
    label={label}
    optional={optional}
  >
    <Input
      className='street-name'
      name={`${namePrefix}StreetName`}
      label={tu('general.street')}
      optional={optional}
      hideOptional={optional}
    />
    <Input
      className='street-number'
      name={`${namePrefix}StreetNumber`}
      label={tu('general.streetNumber')}
      optional={optional}
      hideOptional={optional}
    />
    <Input
      className='entrance'
      name={`${namePrefix}Entrance`}
      label={tu('general.apartment')}
      optional={optional}
      hideOptional={optional}
    />
    <ZipCode namePrefix={namePrefix} optional={optional} />
    <Input
      className='city'
      name={`${namePrefix}City`}
      label={tu('general.postalCity')}
      optional={optional}
      hideOptional={optional}
    />
    <Input
      className='country-code'
      name={`${namePrefix}Country`}
      label={tu('general.country')}
      maxLength={2}
      optional={optional}
      hideOptional={optional}
    />
  </ColoredSection>
);

export default AddressInput;

AddressInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  namePrefix: PropTypes.string,
  optional: PropTypes.bool
};
