import './index.sass';

import { Link } from 'react-router-dom';

import ribbon_dev from 'images/ribbon_dev.png';
import ribbon_stg from 'images/ribbon_stg.png';

const Ribbon = ({ children }) => (
  <div className='environment-ribbon'>
    <Link to='/'>{children}</Link>
  </div>
);

const EnvironmentRibbon = () => {
  switch (process.env.APP_ENV) {
    case 'dev':
    case 'local':
    case 'mock':
      return (
        <Ribbon>
          <img alt='dev environment' className='env-ribbon' src={ribbon_dev} />
        </Ribbon>
      );
    case 'stg':
      return (
        <Ribbon>
          <img alt='test environment' className='env-ribbon' src={ribbon_stg} />
        </Ribbon>
      );
    default:
      return null;
  }
};

export default EnvironmentRibbon;
