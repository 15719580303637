import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as api from '../../api';
import { ApiContractService } from 'src/interfaces/interfaces.generated';

export interface ContractServicesReducerState {
  error: unknown;
  data: ApiContractService[];
  isLoaded: boolean;
}

const initialState: ContractServicesReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const contractServicesSlice = createSlice({
  name: 'ContractServices',
  initialState,
  reducers: {
    getContractServicesRequest(state) {
      state.error = null;
      state.isLoaded = false;
      state.data = [];
    },
    getContractServicesSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getContractServicesFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getContractServicesRequest, getContractServicesSuccess, getContractServicesFailed } =
  contractServicesSlice.actions;

export default contractServicesSlice.reducer;

export const getContractServices =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getContractServicesRequest());
      const response: ApiContractService[] = await api.getContractServices();
      dispatch(getContractServicesSuccess(response));
      return true;
    } catch (error) {
      dispatch(getContractServicesFailed(error));
    }
  };
