import { useEffect } from 'react';

import FormV2 from 'js/components/FormV2';
import Input from 'js/components/Form/components/Input';
import SelectV2 from 'js/components/Form/components/SelectV2';

import { tu } from 'js/utils/translate';
import { freightProductOptions } from 'js/components/Select/utils';
import { getFreightProducts } from 'js/store/freightProductsReducer';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const TransportSolutionForm = ({ handleSubmit, initialValues, redirectTo }) => {
  const dispatch = useAppDispatch();

  const freightProducts = useAppSelector((state) => state.freightProducts?.data);
  const freightProductsLoaded = useAppSelector((state) => state.freightProducts?.isLoaded);
  const error = useAppSelector((state) => state?.transportSolutions?.error);
  const saving = useAppSelector((state) => state?.transportSolutions?.saving);

  useEffect(() => {
    if (!freightProductsLoaded) dispatch(getFreightProducts());
  }, [freightProductsLoaded]);

  return (
    <FormV2
      className='edit-transport-solutions-form'
      onSubmit={handleSubmit}
      initialValues={initialValues}
      error={error}
      loading={saving}
      redirectTo={redirectTo}
    >
      <Input autoFocus name='name' label={tu('general.transportSolution')} />
      <Input
        name='description'
        label={tu('freight.transportSolutions.description')}
        optional={true}
      />
      <Input
        name='ediCode'
        label={tu('freight.transportSolutions.ediCode')}
        optional={true}
        maxLength={3}
      />
      <SelectV2
        name='freightProductIds'
        options={freightProductOptions(freightProducts)}
        label={tu('general.freightProducts')}
        isLoading={!freightProductsLoaded}
        isMulti
        selectAll
      />
    </FormV2>
  );
};

export default TransportSolutionForm;
