import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { RootState } from 'js/store/reducer';

import LabeledInput from 'js/components/LabeledInput';

import {
  ApiFlowShopSimple,
  ApiOrderSimple,
  FreightProduct
} from 'src/interfaces/interfaces.generated';
import { filterOrders } from './utils';
import t, { tu } from 'js/utils/translate';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  setFilteredOrders: Dispatch<SetStateAction<ApiOrderSimple[]>>;
  orders: ApiOrderSimple[];
}

const FilterOrderSearchResult: FC<Props> = ({ orders, setFilteredOrders }) => {
  const [filterInput, setFilterInput] = useState('');

  const shops: ApiFlowShopSimple[] = useAppSelector(
    (state: RootState) => state?.freight?.shops?.data
  );
  const freightProducts: FreightProduct[] = useAppSelector(
    (state: RootState) => state.freightProducts?.data
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterInput(event.target.value);
  };

  useEffect(() => {
    setFilteredOrders(filterOrders(orders, filterInput, shops, freightProducts));
  }, [filterInput]);

  return (
    <LabeledInput
      className='filter-order-search-result'
      info={t('orderSearch.result.filter.info')}
      label={tu('general.filter')}
      onChange={(e) => {
        handleChange(e);
      }}
    />
  );
};

export default FilterOrderSearchResult;
