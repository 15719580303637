import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

import { ApiInvoiceBatch } from 'src/interfaces/interfaces.generated';

export interface ProcessedInvoiceBatchesForShopReducerState {
  error: unknown;
  data: ApiInvoiceBatch[];
  isLoading: boolean;
}

const initialState: ProcessedInvoiceBatchesForShopReducerState = {
  error: null,
  data: [],
  isLoading: false
};

const processedInvoicesSlice = createSlice({
  name: 'ProcessedInvoiceBatchesForShop',
  initialState,
  reducers: {
    getProcessedInvoiceBatchesForShopRequest(state) {
      state.isLoading = true;
    },
    getProcessedInvoiceBatchesForShopSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    getProcessedInvoiceBatchesForShopFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const {
  getProcessedInvoiceBatchesForShopRequest,
  getProcessedInvoiceBatchesForShopSuccess,
  getProcessedInvoiceBatchesForShopFailed
} = processedInvoicesSlice.actions;

export default processedInvoicesSlice.reducer;

export const getProcessedInvoiceBatchesForShop = (shopId: number) => async (dispatch) => {
  try {
    dispatch(getProcessedInvoiceBatchesForShopRequest());
    const response: ApiInvoiceBatch[] = await rest.getProcessedInvoiceBatchesForShop(shopId);
    dispatch(getProcessedInvoiceBatchesForShopSuccess(response));
  } catch (error) {
    dispatch(getProcessedInvoiceBatchesForShopFailed(error));
  }
};
