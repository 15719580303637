import { createSlice } from '@reduxjs/toolkit';

import * as api from '../../api';

import { ApiOrder, FreightProduct } from 'src/interfaces/interfaces.generated';

export interface SingleAddressCheckReducerState {
  error: unknown;
  data: FreightProduct;
  isChecking: boolean;
  isChecked: boolean;
}

const initialState: SingleAddressCheckReducerState = {
  error: null,
  data: {}, // singleAddressCheckResponse
  isChecking: false, // checkingAddress
  isChecked: false // addressChecked
};

const singleAddressCheckSlice = createSlice({
  name: 'SingleAddressCheck',
  initialState,
  reducers: {
    singleAddressCheckRequest(state) {
      state.error = null;
      state.isChecking = true;
      state.isChecked = false;
    },
    singleAddressCheckSuccess(state, action) {
      state.isChecking = false;
      state.isChecked = true;
      state.data = action.payload;
    },
    singleAddressCheckFailed(state, action) {
      state.error = action.payload;
      state.isChecking = false;
      state.isChecked = false;
    }
  }
});

export const { singleAddressCheckRequest, singleAddressCheckSuccess, singleAddressCheckFailed } =
  singleAddressCheckSlice.actions;

export default singleAddressCheckSlice.reducer;

export const singleAddressCheck = (order: ApiOrder) => async (dispatch) => {
  try {
    dispatch(singleAddressCheckRequest());
    const response: FreightProduct = await api.singleAddressCheck(order);
    dispatch(singleAddressCheckSuccess(response));
  } catch (err: any) {
    dispatch(singleAddressCheckFailed(err));
  }
};
