import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as rest from '../../api';

export interface GenerateInvoiceBatchReducerState {
  error: unknown;
  isSaving: boolean;
}

const initialState: GenerateInvoiceBatchReducerState = {
  error: null,
  isSaving: false
};

const invoiceBatchSlice = createSlice({
  name: 'invoiceBatch',
  initialState,
  reducers: {
    generateInvoiceBatchRequest(state) {
      state.isSaving = true;
      state.error = null;
    },
    generateInvoiceBatchSuccess(state) {
      state.isSaving = false;
    },
    generateInvoiceBatchFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  generateInvoiceBatchRequest,
  generateInvoiceBatchSuccess,
  generateInvoiceBatchFailed
} = invoiceBatchSlice.actions;

export default invoiceBatchSlice.reducer;

export const generateInvoiceBatch =
  (invoiceBatch) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(generateInvoiceBatchRequest());
      await rest.generateInvoiceBatch(invoiceBatch);
      dispatch(generateInvoiceBatchSuccess());
      return true;
    } catch (error) {
      dispatch(generateInvoiceBatchFailed(error));
    }
  };
