import { FC, useEffect, useState } from 'react';

import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import PriceAdjustmentForm from '../../components/PriceAdjustmentForm';

import { getFuturePriceAdjustments } from '../../store/priceAdjustmentsReducer';
import { findInitialValues, findPriceAdjustmentById } from './utils';

import { RootState } from 'js/store/reducer';
import { ApiPriceAdjustment } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useParams } from 'react-router-dom';

const EditPriceAdjustment: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const priceAdjustmentId = params.priceAdjustmentId;

  const futurePriceAdjustmentsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.economy?.priceAdjustments?.isLoaded
  );
  const futurePriceAdjustments: ApiPriceAdjustment[] = useAppSelector(
    (state: RootState) => state?.economy?.priceAdjustments?.data
  );

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (futurePriceAdjustmentsLoaded) {
      setInitialValues(
        findInitialValues(
          futurePriceAdjustmentsLoaded
            ? findPriceAdjustmentById(futurePriceAdjustments, priceAdjustmentId)
            : {}
        )
      );
    } else {
      dispatch(getFuturePriceAdjustments());
    }
  }, [futurePriceAdjustmentsLoaded]);

  return (
    <SplitPage>
      <PageWrapper className='new-price-adjustment'>
        <PriceAdjustmentForm type='save' initialValues={initialValues} disabled />
      </PageWrapper>
    </SplitPage>
  );
};

export default EditPriceAdjustment;
