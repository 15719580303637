import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface ZoneIdsReducerState {
  error: unknown;
  data: any[];
  isLoaded: boolean;
}

const initialState: ZoneIdsReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const zoneIdsSlice = createSlice({
  name: 'ZoneIds',
  initialState,
  reducers: {
    getZoneIdsRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getZoneIdsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getZoneIdsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  }
});

export const { getZoneIdsRequest, getZoneIdsSuccess, getZoneIdsFailed } = zoneIdsSlice.actions;

export default zoneIdsSlice.reducer;

export const getZoneIds = () => async (dispatch) => {
  try {
    dispatch(getZoneIdsRequest());
    const zoneIds = await rest.getZoneIds();
    dispatch(getZoneIdsSuccess(zoneIds));
  } catch (err) {
    dispatch(getZoneIdsFailed(err));
  }
};
