import { useEffect } from 'react';

import SelectV2 from 'js/components/Form/components/SelectV2';
import t, { tu } from 'js/utils/translate';

import { shopOptions } from 'js/components/Select/utils';
import { getShops } from 'js/scenes/Freight/store/shopsReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const SelectShops = ({ name }) => {
  const dispatch = useAppDispatch();

  const shopsLoaded = useAppSelector((state) => state?.freight?.shops?.isLoaded);
  const shops = useAppSelector((state) => state?.freight?.shops?.data);

  useEffect(() => {
    if (!shopsLoaded) dispatch(getShops());
  }, []);

  const billableShops = shops?.filter((shop) => shop.billingCustomerNumber);

  return (
    <SelectV2
      isMulti
      selectAll
      name={name}
      label={tu('general.shops')}
      info={t('economy.invoice.generateInvoice.selectOnly.billable')}
      placeholder={shopsLoaded ? t('select.shops') : ''}
      options={shopOptions(billableShops)}
      isLoading={!shopsLoaded}
    />
  );
};

export default SelectShops;
