import { FC } from 'react';
import classNames from 'classnames';

import './index.sass';

interface Props {
  className?: string;
  text: string;
  variant?: 'success' | 'danger' | 'warning' | 'label' | 'error' | 'info';
}

const Tag: FC<Props> = ({ className, text, variant = 'label' }) => (
  <div className={classNames('tag', `tag--${variant}`, className)}>{text}</div>
);

export default Tag;
