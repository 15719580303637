import classNames from 'classnames';

import LabeledTags from '../LabeledTags';
import LabelHistoryDate from './components/LabelHistoryDate';

import { findFreightProductById, findServiceSupplierById } from 'js/utils/find';
import t, { tu } from 'js/utils/translate';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';

const LabelHistoryAdditionalInfo = ({ labelExcerpt }) => {
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);

  const freightProducts = useAppSelector((state) => state.freightProducts?.data);
  const serviceSuppliers = useAppSelector(
    (state) => state?.freight?.serviceSuppliers?.serviceSuppliers?.data
  );

  const rowClasses = (modifier) =>
    classNames(
      'label-history-additional-info__row',
      `label-history-additional-info__row--${modifier}`
    );

  return (
    <div className='label-history-additional-info'>
      <div
        className={isDesktop ? rowClasses('spaced') : 'label-history-additional-info__tag-lists'}
      >
        <LabeledTags
          labelExcerpt={labelExcerpt}
          accessor='freightProductIds'
          data={freightProducts}
          findFunction={findFreightProductById}
          label={tu('general.freightProducts')}
        />
        <LabeledTags
          labelExcerpt={labelExcerpt}
          accessor='serviceSupplierIds'
          data={serviceSuppliers}
          findFunction={findServiceSupplierById}
          label={tu('general.serviceSupplier')}
        />
        <LabeledTags
          labelExcerpt={labelExcerpt}
          accessor='orderBy'
          data={serviceSuppliers}
          label={tu('general.sorting')}
        />
      </div>
      <div className={rowClasses('padded')}>
        <LabelHistoryDate
          fromText={t('orders.labelhistory.import.date.from')}
          toText={t('orders.labelhistory.import.date.to')}
          fromDate={labelExcerpt.fromImportedDate}
          toDate={labelExcerpt.toImportedDate}
        />
        <LabelHistoryDate
          fromText={t('orders.labelhistory.delivery.date.from')}
          toText={t('orders.labelhistory.delivery.date.to')}
          fromDate={labelExcerpt.fromDesiredDeliveryDate}
          toDate={labelExcerpt.toDesiredDeliveryDate}
        />
        {labelExcerpt.includeExportedLabels && (
          <div className='label-history-additional-info__include-prev-labels'>
            {t('orders.labelhistory.includeExported')}
          </div>
        )}
        {labelExcerpt.includeOnlyAsaLabelOrders && (
          <div className='label-history-additional-info__include-prev-labels'>
            {t('orders.labelhistory.include.only.asa.label.orders')}
          </div>
        )}
      </div>
    </div>
  );
};

export default LabelHistoryAdditionalInfo;
