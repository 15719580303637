import { useEffect, useMemo } from 'react';

import Spinner from 'js/components/Spinner';
import InfoBox from 'js/components/InfoBox';
import TableV2 from 'js/components/TableV2';
import LabeledItem from 'js/components/LabeledItem';
import Tags from '../Tags';

import t, { tu } from 'js/utils/translate';
import { getShopUsers } from '../../../../store/shopUsersReducer';
import { filteredWebserviceUsers } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/utils';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const ShopApiUsersBody = ({ shopId }) => {
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);

  const dispatch = useAppDispatch();

  const shopUsersLoaded = useAppSelector((state) => state?.freight?.shop?.shopUsers?.isLoaded);
  const shopUsers = useAppSelector((state) => state?.freight?.shop?.shopUsers?.data);

  useEffect(() => {
    if (shopId) dispatch(getShopUsers(shopId));
  }, []);

  const shopApiUsers = filteredWebserviceUsers(shopUsers) ?? false;

  const columns = useMemo(
    () => [
      {
        Header: t('general.username'),
        accessor: 'userName',
        inHeader: true
      },
      {
        Header: t('general.email'),
        accessor: ({ email }) =>
          isDesktop ? email : <LabeledItem label={tu('general.email')} item={email} />
      },
      {
        Header: t('general.apiAccess'),
        accessor: ({ apiWebserviceList }) => {
          const tags = (
            <Tags
              list={apiWebserviceList}
              accessor='webserviceId'
              translationKeyPrefix='shop.accordion.apiUsers.webservices'
            />
          );
          return isDesktop ? tags : <LabeledItem label={tu('general.apiAccess')} item={tags} />;
        }
      },
      {
        Header: t('general.language'),
        accessor: ({ language }) =>
          isDesktop ? (
            t(`general.${language}`)
          ) : (
            <LabeledItem label={tu('general.language')} item={t(`general.${language}`)} />
          )
      }
    ],
    [isDesktop]
  );

  if (!shopUsersLoaded) return <Spinner />;

  if (!shopApiUsers) return <Spinner />;

  if (shopApiUsers.length === 0) {
    return <InfoBox text={t('shop.accordion.empty', t('general.apiUsers'))} />;
  }

  return (
    <TableV2
      className={isDesktop ? 'shop-api-users-desktop-table' : 'shop-api-users-mobile-table'}
      columns={columns}
      data={shopApiUsers}
      mobileTableVariant='withSmallEditIcon'
    />
  );
};

export default ShopApiUsersBody;
