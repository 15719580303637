import { FC, useEffect, useState } from 'react';
import {
  useAvailableUserOptionsQuery,
  useCreateUserMutation,
  useUpdateUserMutation
} from 'js/scenes/UserAdministration/api/api';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { userAdministrationPath } from 'js/scenes/UserAdministration/utils/constants';
import { UserForm as IUserForm } from 'js/scenes/UserAdministration/UserActions/CreateAndEdit/UserForm.types';
import { User, UserType } from 'js/scenes/UserAdministration/Users.types';
import LoadComponent from 'js/components/LoadComponent';
import { getUsername } from 'js/scenes/UserAdministration/utils/utils';
import { useQueryClient } from 'react-query';
import { usersList } from 'js/scenes/UserAdministration/api/queryKeys';
import UserFormView from 'js/scenes/UserAdministration/UserActions/CreateAndEdit/UserForm.view';
import FormV2 from 'js/components/FormV2';
import ValidationErrors from 'js/scenes/UserAdministration/UserActions/ValidationErrors';
import t from 'js/utils/translate';
import * as amplitude from '@amplitude/analytics-browser';
import { Amplitude } from 'js/scenes/UserAdministration/utils/amplitude';

const UserForm: FC = () => {
  const queryClient = useQueryClient();

  const { state } = useLocation();
  const user: User = state?.user || {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    shops: [],
    roles: [],
    webServices: []
  };
  const isEditMode = state !== null && state?.user?.userId;

  const navigate = useNavigate();

  const availableUserOptions = useAvailableUserOptionsQuery();

  const [userType, setUserType] = useState<UserType>(state?.type || 'flow');

  async function onMutationSuccess() {
    amplitude.track(
      isEditMode ? Amplitude.USER_EDIT_SUCCESS : Amplitude.USER_CREATE_SUCCESS,
      Amplitude.getMetricProperties(userType)
    );
    queryClient.removeQueries([usersList]);
    navigate(userAdministrationPath, {
      state: {
        type: userType,
        toastMessage: isEditMode
          ? t('userAdministration.toast.updated')
          : t('userAdministration.toast.created')
      }
    });
  }

  const createUser = useCreateUserMutation(onMutationSuccess);
  const updateUser = useUpdateUserMutation(onMutationSuccess);

  const initialValues: IUserForm = {
    type: userType,
    firstName: user.firstName,
    lastName: user.lastName,
    username: user.username,
    email: user.email,
    shops: user.shops.map((shop) => shop.id),
    roles: user.roles.map((role) => role.roleId),
    webServices: user.webServices
  };

  useEffect(() => {
    amplitude.track(
      isEditMode ? Amplitude.USER_EDIT_INITIALIZED : Amplitude.USER_CREATE_INITIALIZED,
      Amplitude.getMetricProperties(isEditMode ? userType : undefined)
    );
  }, []);

  const handleSubmit = (values: IUserForm) => {
    // Add mandatory roles for the type
    values?.type === 'api'
      ? availableUserOptions.data?.apiMandatoryRoles.map((role) => values?.roles.push(role.roleId))
      : availableUserOptions.data?.flowMandatoryRoles.map(
          (role) => values?.roles.push(role.roleId)
        );

    if (isEditMode) {
      updateUser.mutate({
        userId: state.user.userId,
        email: values.email,
        shops: values?.shops,
        roles: values?.roles,
        webServices: values?.webServices
      });
    } else {
      createUser.mutate({
        firstName: values?.firstName,
        lastName: values?.lastName,
        username: getUsername(values.type, values.username),
        email: values.email,
        userType: values?.type,
        shops: values?.shops,
        roles: values?.roles,
        webServices: values?.webServices
      });
    }
  };

  return (
    <>
      <LoadComponent
        isLoading={availableUserOptions.isLoading}
        isError={availableUserOptions.isError}
      >
        <FormV2
          initialValues={initialValues}
          onSubmit={handleSubmit}
          error={createUser.error || updateUser.error}
          loading={createUser.isLoading || updateUser.isLoading}
          redirectTo={userAdministrationPath}
        >
          <UserFormView
            availableUserOptions={availableUserOptions}
            isEditMode={isEditMode}
            setUserType={setUserType}
          />
          <ValidationErrors translationPrefix={'general'} />
        </FormV2>
      </LoadComponent>
    </>
  );
};

export default UserForm;
