import { FC, RefObject, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import Icon from 'js/components/Icon';

import { downloadLabels } from 'js/utils/rest';
import t from 'js/utils/translate';
import { listBody } from 'js/components/CollapseV2/variants/listBody';
import { getShops } from 'js/scenes/Freight/store/shopsReducer';
import { getFreightProducts } from 'js/store/freightProductsReducer';
import { getTransportSolutions, useTransportSolutions } from 'js/store/transportSolutionsReducer';
import {
  ApiFreightProduct,
  ApiOrderSimple,
  ApiTransportSolution,
  FlowShop,
  FreightProduct
} from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import { findFreightProductById, findShopById, findTransportSolutionById } from 'js/utils/find';
import { Link } from 'react-router-dom';

interface Props {
  order: ApiOrderSimple;
  shipmentInfoRef: RefObject<HTMLDivElement>;
}

const ShipmentBody: FC<Props> = ({ order, shipmentInfoRef }) => {
  const dispatch = useAppDispatch();

  const shops: FlowShop[] = useAppSelector((state: RootState) => state?.freight?.shops?.data);
  const shopsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shops?.isLoaded
  );

  const freightProductLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freightProducts?.isLoaded
  );
  const freightProducts: FreightProduct[] = useAppSelector(
    (state: RootState) => state?.freightProducts?.data
  );

  const transportSolutionsState = useTransportSolutions();
  const transportSolutionsLoaded = transportSolutionsState.isLoaded;
  const transportSolutions = transportSolutionsState.data;

  useEffect(() => {
    if (!shopsLoaded) dispatch(getShops());
    if (!freightProductLoaded) dispatch(getFreightProducts());
    if (!transportSolutionsLoaded) dispatch(getTransportSolutions());
  }, []);

  const freightProduct: ApiFreightProduct | undefined = findFreightProductById(
    freightProducts,
    order.freightProductId
  );
  const transportSolution: ApiTransportSolution | undefined = findTransportSolutionById(
    transportSolutions,
    order.transportSolutionId
  );
  const shop: FlowShop | undefined = findShopById(shops, order.shopId);

  const onClick = async () => {
    if (!order.noLabel) {
      await downloadLabels(`orders/v1/export/labels/${order?.shipmentId}`);
    }
  };

  return listBody(
    [
      { label: 'shopId', content: order?.shopId ?? '-', orientation: 'row' },
      {
        label: 'shopName',
        content: (
          <Link to={`/freight/shop/${order?.shopId}`} className={'link'}>
            {shop?.name ?? '-'}
          </Link>
        ),
        orientation: 'row'
      },
      {
        label: 'transportSolution',
        content: transportSolution?.name ?? '-',
        orientation: 'row'
      },
      {
        label: 'shipmentReference',
        content: (
          <span
            ref={shipmentInfoRef}
            onClick={() => onClick}
            className={order.noLabel ? '' : 'link'}
          >
            {' '}
            <Icon icon='tags' /> {order?.shipmentId ?? '-'}{' '}
          </span>
        ),
        orientation: 'row'
      },
      {
        label: 'desiredDeliveryDate',
        content: order.desiredDeliveryDate ?? '-',
        orientation: 'row'
      },
      {
        label: 'desiredDeliveryInterval',
        content: order.desiredDeliveryInterval ?? '-',
        orientation: 'row'
      },
      {
        label: 'freightProduct',
        content: freightProduct?.name ?? '-',
        orientation: 'row'
      },
      {
        label: 'terms',
        content: order?.termIds ? t('general.answer.yes') : t('general.answer.no'),
        orientation: 'row'
      },
      {
        label: 'numberParcels',
        content: order?.totalNumberOfPackages ?? '-',
        orientation: 'column'
      },
      {
        label: 'messageForTransporter',
        content: order?.messageToTransporter ?? '-',
        orientation: 'column'
      },
      {
        label: 'additionalServices',
        content: (
          <div>
            {order?.additionalServices && order.additionalServices.length > 0 ? (
              <ul>
                {order.additionalServices.map((service) => (
                  <li key={service.key}>
                    {service.key} - {service.value}
                  </li>
                ))}
              </ul>
            ) : (
              '-'
            )}
          </div>
        ),
        orientation: 'column'
      },
      {
        label: 'messageForReciever',
        content: order?.messageToConsignee ?? '-',
        orientation: 'column'
      },
      {
        label: 'orderStatus',
        content: order?.orderStatusId ? t(`general.orderStatus.${order.orderStatusId}`) : '-',
        orientation: 'column'
      },
      {
        label: 'bookedByUserName',
        content: order?.bookedByUserName ?? '-',
        orientation: 'column'
      },
      {
        label: 'externalBookingError',
        content: order?.externalBookingError ?? '-',
        orientation: 'column'
      }
    ],
    'orderDetails.accordion.shipment.'
  );
};

export default ShipmentBody;
