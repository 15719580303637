import Ajax from 'js/utils/ajax/ajax.js';

import {
  ApiBookingResponse,
  ApiOrder,
  ApiServicePoint,
  FreightProduct
} from 'src/interfaces/interfaces.generated';

export const createNewOrder = (order: ApiOrder): Promise<ApiBookingResponse> =>
  Ajax.post('/orders/v2', order);

export const singleAddressCheck = (order: ApiOrder): Promise<FreightProduct> =>
  Ajax.post('/orders/v1/addresscheck', order);

export const getFullOrder = (trackingReference: number): Promise<ApiOrder> =>
  Ajax.get(`/orders/v2/${trackingReference}`);

export const loadServicePoints = (
  zipCode: string,
  countryCode: string,
  shopId: number,
  transportSolutionId: number
): Promise<ApiServicePoint[]> =>
  Ajax.get('/servicePoint/v1', {
    zipCode,
    countryCode,
    shopId,
    transportSolutionId
  });
