import { FC } from 'react';

import Icon from 'js/components/Icon';
import InvoiceLines from './components/InvoiceLines';

import { ApiInvoice } from 'src/interfaces/interfaces.generated';

import './index.sass';

interface Props {
  invoices?: ApiInvoice[];
}

const Expand: FC<Props> = ({ invoices }) => {
  if (!invoices) return <div>-</div>;

  return (
    <div className='invoice-summary'>
      <Icon icon='level-up-alt fa-rotate-90' size='sm' />
      <div className='invoices'>
        {invoices?.map((invoice: ApiInvoice) => (
          <div className='invoice' key={invoice.invoiceId}>
            <div className='invoice__customer-name'>{invoice.customerName}</div>
            <InvoiceLines invoiceId={invoice.invoiceId} invoiceLines={invoice.invoiceLines} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Expand;
