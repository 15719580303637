import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.sass';

const Icon = ({
  icon,
  size,
  regular = false,
  light = false,
  duoTone = false,
  brands = false,
  solid = !regular && !light && !duoTone && !brands,
  fixedWidth = false,
  handleClick,
  className = '',
  spanClassName = ''
}) => {
  if (regular || light || duoTone || brands) solid = false;

  return (
    <span className={spanClassName} onClick={() => handleClick?.()} key={`icon ${icon}`}>
      <i
        className={classNames(
          'icon',
          { fas: solid },
          { far: regular },
          { fal: light },
          { fad: duoTone },
          { fab: brands },
          { 'fa-fw': fixedWidth },
          { 'fa-fw': fixedWidth },
          { [`fa-${icon}`]: icon },
          { [`fa-${size}`]: size },
          className
        )}
      />
    </span>
  );
};

export default Icon;

Icon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.string,
  regular: PropTypes.bool,
  light: PropTypes.bool,
  duoTone: PropTypes.bool,
  brands: PropTypes.bool,
  solid: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  spanClassName: PropTypes.string
};
