import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector, useTimeout } from 'js/utils/hooks';
import { RootState } from 'js/store/reducer';

import InfoBox from 'js/components/InfoBox';
import MessageForUser from 'js/components/MessageForUser';
import Spinner from 'js/components/Spinner';
import UserForm from '../../components/UserForm';

import { getShop } from '../../store/shopReducer';
import { filteredShopUsers } from '../../utils';
import { createShopUser } from './store/shopUserReducer';
import { editUser, giveShopAccess } from '../../../../../../store/userReducer';
import { getShopUsers } from '../../store/shopUsersReducer';
import { buildUser } from 'js/scenes/Freight/components/Users/utils';
import t from 'js/utils/translate';

import './index.sass';
import { useNavigate, useParams } from 'react-router-dom';

const EditShopUsers: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { shopId } = useParams();

  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const shopUsersLoaded = useAppSelector(
    (state: RootState) => state?.freight?.shop?.shopUsers?.isLoaded
  );
  const shopUsers = useAppSelector((state: RootState) => state?.freight?.shop?.shopUsers?.data);
  const createShopUserError = useAppSelector(
    (state: RootState) => state?.freight?.shop?.shopUser?.error
  );
  const userError = useAppSelector((state: RootState) => state?.freight?.user?.error);

  useEffect(() => {
    if (shopId) dispatch(getShopUsers(+shopId));
  }, []);

  const error = createShopUserError || userError;

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isEmpty(error)) setIsSubmitting(false);
  }, [error]);

  useTimeout(
    () => {
      setIsSubmitting(false);
      navigate(`/freight/shop/${shopId}`);
      dispatch(getShop(+shopId!));
    },
    isSubmitting ? 4000 : null
  );

  const editAcitveUser = (user) => {
    if (user.active) {
      dispatch(editUser(user.userId, buildUser(user)));
    }
  };

  const handleSubmit = ({ users, newUsers, connectedUsers }) => {
    if (shopId) {
      setIsSubmitting(true);
      if (users) {
        users.forEach((user) => {
          editAcitveUser(user);
        });
      }
      if (newUsers) {
        newUsers.forEach((user) => {
          dispatch(createShopUser(+shopId, buildUser(user)));
        });
      }
      if (connectedUsers) {
        connectedUsers.forEach((user) => {
          dispatch(giveShopAccess(+shopId, { userId: user.userId })).then((response?: boolean) => {
            if (response) editAcitveUser(user);
          });
        });
      }
    }
  };

  if (!shopId) return <InfoBox text={t('general.noInformationFound')} />;

  if (!shopUsersLoaded) return <Spinner />;

  const users = filteredShopUsers(shopUsers);

  return (
    <div className={classNames('edit-users-page', { 'page-padding': isDesktop })}>
      <MessageForUser
        variant='warning'
        message={t('freight.shop.edit.apiAccess.warningMessage', t('general.user'))}
      />
      <UserForm
        users={users}
        onSubmit={handleSubmit}
        error={error}
        redirectTo={shopId ? `/freight/shop/${shopId}` : '/freight'}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default EditShopUsers;
