import { FC } from 'react';
import classNames from 'classnames';

import Label from 'js/components/Label';
import Spinner from 'js/components/Spinner';
import Radio from 'js/components/Form/components/Radio';
import { RadioType } from './interfaces';

import './index.sass';

interface Props {
  className?: string;
  name: string; // html radio group name
  label: string;
  onChange?: any;
  radios: RadioType[];
  convert?: any;
  isLoading?: boolean;
  align?: 'row' | 'column';
}

const RadioGroup: FC<Props> = ({
  className,
  name,
  label,
  radios,
  convert,
  isLoading,
  align = 'column'
}) => {
  if (isLoading) return <Spinner />;

  return (
    <div className={classNames('radio-group', className)}>
      <Label label={label} />
      <div className={classNames('radio-group__radios', `radio-group__radios--${align}`)}>
        {radios.map(({ label, value }) => (
          <Radio key={label} name={name} label={label} value={value} convert={convert} />
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
