import { useEffect, useState } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import ColoredSection from 'js/components/ColoredSection';
import Matrix from './components/Matrix';
import Tabs from 'js/components/Tabs';
import { getCostPrices } from 'js/scenes/Economy/scenes/CostPrices/store';
import FilterButtons from 'js/components/FilterButtons';

import { ApiFlowShopSimple, TransportPriceTable } from 'src/interfaces/interfaces.generated';
import { Filters } from 'js/components/FilterButtons/interfaces';

import { RootState } from 'js/store/reducer';

import t from 'js/utils/translate';
import './index.sass';

const tabs = [
  { label: 'economy.costprice.tab.currentPrices', name: 'currentPrices' },
  { label: 'economy.costprice.tab.futurePrices', name: 'futurePrices' }
];

export const filters: Filters = [
  { name: 'SMALL', text: 'economy.costprice.matrix.filter.small' },
  { name: 'MEDIUM', text: 'economy.costprice.matrix.filter.medium' },
  { name: 'LARGE', text: 'economy.costprice.matrix.filter.large' }
];

const CostPrice = () => {
  const { getValue } = useFormContext();
  const freightProductId: number | undefined = getValue('freightProductId');
  const selectedShop: ApiFlowShopSimple | undefined = getValue('selectedShop');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (freightProductId && selectedShop?.shopId) {
      dispatch(getCostPrices(selectedShop?.shopId, freightProductId));
    }
  }, [freightProductId, selectedShop]);

  const freightProductPrices: TransportPriceTable = useAppSelector(
    (state: RootState) => state.economy.costPrices.data
  );
  const freightProductPricesLoaded: boolean = useAppSelector(
    (state: RootState) => state.economy.costPrices.isLoaded
  );

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [selectedFilter, setSelectedFilter] = useState(filters[0].name);

  const hasDifferentSizes = freightProductId === 15;
  const currentPrices = activeTab === tabs[0];
  const hasFuturePrices = !!freightProductPrices?.futureFromDates?.length;
  const showGrayMatrix = !hasFuturePrices && !currentPrices;

  return (
    <div className='cost-price'>
      {freightProductId && freightProductPricesLoaded ? (
        <>
          <Tabs
            className='freight-tabs page-padding'
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {hasDifferentSizes && (
            <FilterButtons
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              filters={filters}
            />
          )}
          <Matrix
            freightProductPrices={freightProductPrices}
            currentPrices={currentPrices}
            selectedFilter={hasDifferentSizes ? selectedFilter : 'ALL'}
            showGrayMatrix={showGrayMatrix}
          />
        </>
      ) : (
        <ColoredSection defaultText={t('economy.costprice.select.to.show')} />
      )}
    </div>
  );
};

export default CostPrice;
