import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import LinkV2 from 'js/components/LinkV2';

import t from 'js/utils/translate';
import { clearUserPasswordState, resetPassword } from './store/userPasswordReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface Props {
  userId?: number;
  setEditUserActive: Dispatch<SetStateAction<boolean | null>>;
  editUserActive: boolean | null;
}

const NewUserPassword: FC<Props> = ({ userId, setEditUserActive, editUserActive }) => {
  const dispatch = useAppDispatch();

  const isResetting = useAppSelector(
    (state: RootState) => state?.freight?.shop?.userPassword?.isResetting
  );
  const error = useAppSelector((state: RootState) => state?.freight?.shop?.userPassword?.error);
  const isReset = useAppSelector((state: RootState) => state?.freight?.shop?.userPassword?.isReset);
  const id = useAppSelector((state: RootState) => state?.freight?.shop?.userPassword?.id);

  useEffect(() => {
    if (!editUserActive) dispatch(clearUserPasswordState());
  }, [editUserActive]);

  const handleNewPassword = () => {
    if (userId) {
      dispatch(resetPassword(userId)).then((response: boolean | undefined) => {
        if (response && editUserActive)
          setTimeout(() => {
            setEditUserActive(false);
          }, 2000);
      });
    }
  };

  const isCurrentUser = userId === id;

  if (isReset && isCurrentUser) {
    return <div>{t('freight.shop.edit.user.createPassword.emailSent')}</div>;
  }

  return (
    <LinkV2
      onClick={handleNewPassword}
      text={t('freight.shop.edit.user.createPassword.create')}
      loading={isResetting && isCurrentUser}
      error={!!(error && isCurrentUser)}
    />
  );
};

export default NewUserPassword;
