import { FC } from 'react';
import { useRegister, useValue } from '@distribution-innovation/form-hooks';

import { Toggle as ToggleType } from './interfaces';

import _Toggle from './components/Toggle';

type Props = ToggleType;

const Toggle: FC<Props> = ({ className, name, label, sublabel, disabled = false }) => {
  const [selectedValue, setSelectedValue] = useValue(name);

  useRegister({ name, value: !!selectedValue });

  const handleChange = () => setSelectedValue(!selectedValue);

  return (
    <_Toggle
      className={className}
      name={name}
      disabled={disabled}
      value={selectedValue}
      handleChange={handleChange}
      label={label}
      sublabel={sublabel}
    />
  );
};

export default Toggle;
