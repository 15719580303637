import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import Icon from 'js/components/Icon';
import EditButton from './components/EditButton';
import * as amplitude from '@amplitude/analytics-browser';

import { Collapse } from './interfaces';

import './index.sass';
import { Amplitude } from 'js/components/amplitude';

export type Props = Collapse;

const CollapseV2: FC<Props> = ({
  expanded,
  title,
  info,
  body,
  canEdit,
  onCollapseClick,
  onEditClick,
  variant = 'default',
  inAccordion,
  accordionOpen,
  accordionToggleOpen,
  collapseIndex,
  className
}) => {
  const [collapsed, toggleCollapsed] = useState(inAccordion ? accordionOpen : !expanded);

  useEffect(() => {
    if (inAccordion) toggleCollapsed(!accordionOpen);
  }, [accordionOpen]);

  const doEditClick = () => {
    amplitude.track(Amplitude.COLLAPSIBLE_PANEL_EDIT_CLICKED, {
      [Amplitude.COLLAPSIBLE_PANEL__CLASS]: className
    });
    if (onEditClick) {
      onEditClick();
    }
  };

  return (
    <div className={classNames('collapse-v2', `collapse-v2--${variant}`, className)}>
      <div
        className={classNames(
          'collapse-v2__heading',
          { 'collapse-v2__heading--collapsed': collapsed },
          `collapse-v2__heading--${variant}`
        )}
        onClick={() => {
          amplitude.track(Amplitude.COLLAPSIBLE_PANEL_TOGGLED, {
            [Amplitude.COLLAPSIBLE_PANEL__CLASS]: className,
            [Amplitude.COLLAPSIBLE_PANEL__OPEN]: collapsed
          });
          if (inAccordion && accordionToggleOpen && collapseIndex !== undefined) {
            accordionToggleOpen(collapseIndex);
          } else {
            if (collapsed && onCollapseClick !== undefined) {
              onCollapseClick();
            }
            toggleCollapsed(!collapsed);
          }
        }}
      >
        {variant === 'default' ? (
          <div className='large-title'>{title}</div>
        ) : (
          <div className='small-title'>{title}</div>
        )}
        <Icon
          key={`icon ${collapsed ? 'angle-right' : 'angle-down'}`}
          light
          size='2x'
          icon={collapsed ? 'angle-right' : 'angle-down'}
        />
      </div>
      {!collapsed && (
        <div className={`collapse-v2__content collapse-v2__content--${variant}`}>
          {variant === 'default' && (
            <div className='header'>
              {canEdit && <EditButton onClick={doEditClick} />}
              <div className='header__info'>{info}</div>
            </div>
          )}
          <div className='body'>{body}</div>
        </div>
      )}
    </div>
  );
};

export default CollapseV2;
