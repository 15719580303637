const half = (width) => width / 2;

export const userFeedback = (copyIconPos, feedback, desiredPosition, scrollY) => {
  let posX = 0;
  let posY = 0;
  switch (desiredPosition) {
    case 'top':
      posX = half(feedback.offsetWidth) - half(copyIconPos.width);
      posY = 20;
      break;
    case 'right':
      posX = -22;
      posY = -3;
      break;
  }
  feedback.style.left = `${copyIconPos.x - posX}px`;
  feedback.style.top = `${scrollY + copyIconPos.y - posY}px`;
};
