import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import SelectV2 from 'js/components/Form/components/SelectV2';
import PageWrapper from 'js/components/PageWrapper';
import SplitPage from 'js/components/SplitPage';
import AdditionalTransportSolutionField from './components/AdditionalTransportSolutionField';
import TextArea from 'js/components/Form/components/TextArea';
import InvolvedParties from './components/InvolvedParties';

import t, { tu } from 'js/utils/translate';
import { buildPartyObject } from './utils';
import { getShop } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/shopReducer';
import {
  getShopTransportSolutions,
  ShopTransportSolutionsReducerState,
  useShopTransportSolutions
} from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/shopTransportSolutionsReducer';
import { transportSolutionOptionsWithId } from 'js/components/Select/utils';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { ApiFlowShop } from 'src/interfaces/interfaces.generated';
import { PartyType } from 'js/scenes/Orders/scenes/NewOrder/index.types';

interface Props {
  setNextDisabled: Dispatch<SetStateAction<boolean>>;
  consigneeType: PartyType;
  setConsigneeType: Dispatch<SetStateAction<PartyType>>;
  consignorType: PartyType;
  setConsignorType: Dispatch<SetStateAction<PartyType>>;
}

const FirstStep: FC<Props> = ({
  setNextDisabled,
  consigneeType,
  setConsigneeType,
  consignorType,
  setConsignorType
}) => {
  const dispatch = useAppDispatch();
  const context = useFormContext();
  const values = context.getValues();

  const shops: ApiFlowShop[] = useAppSelector((state) => state?.freight?.shops?.data);
  const shopsLoaded: boolean = useAppSelector((state) => state?.freight?.shops?.isLoaded);
  const selectedShop: ApiFlowShop = useAppSelector((state) => state?.freight?.shop?.shop?.data);

  const shopTransportSolutionsState: ShopTransportSolutionsReducerState =
    useShopTransportSolutions();
  const shopTransportSolutions = shopTransportSolutionsState.data;
  const shopTransportSolutionsLoading = shopTransportSolutionsState.isLoading;
  const shopIdForTransportSolutions = shopTransportSolutionsState.shopId;

  useEffect(() => {
    setConsigneeType(values?.consigneeType);
  }, [values?.consigneeType]);

  useEffect(() => {
    setConsignorType(values?.consignorType);
  }, [values?.consignorType]);

  useEffect(() => {
    if (values?.shopId) {
      dispatch(getShop(values?.shopId));
      if (values?.shopId !== shopIdForTransportSolutions)
        dispatch(getShopTransportSolutions(values?.shopId));
    }
  }, [values?.shopId]);

  useEffect(() => {
    setNextDisabled(
      (consigneeType === consignorType &&
        consigneeType !== 'custom' &&
        consignorType !== 'custom') ||
        !values?.transportSolutionId
    );
  }, [consigneeType, consignorType, values?.transportSolutionId]);

  useEffect(() => {
    if (selectedShop.shopId) {
      if (consigneeType === 'shop') {
        const shop = buildPartyObject(selectedShop);
        context.setValue('consignee', shop);
      } else if (consignorType === 'shop') {
        const shop = buildPartyObject(selectedShop);
        context.setValue('consignor', shop);
      }
    }
  }, [consigneeType, consignorType, selectedShop]);

  return (
    <SplitPage>
      <PageWrapper className='first-step'>
        <SelectV2
          name='shopId'
          label={tu('general.shop')}
          options={shops
            ?.filter((shop) => shop.requiredSetupComplete)
            .map((shop) => ({ value: shop.shopId, label: shop.name }))}
          isLoading={!shopsLoaded}
          placeholder={shopsLoaded ? t('select.shop') : ''}
        />
        <SelectV2
          name='transportSolutionId'
          label={tu('general.transportSolution')}
          options={transportSolutionOptionsWithId(shopTransportSolutions)}
          disabled={!values?.shopId}
          isLoading={!!(values?.shopId && shopTransportSolutionsLoading)}
          placeholder={
            !shopTransportSolutionsLoading && values?.shopId ? t('select.transportSolution') : ''
          }
        />
        <AdditionalTransportSolutionField />
        <TextArea
          name='messageToConsignee'
          label={tu('orders.new.order.message.to.consignee')}
          optional
        />
        <InvolvedParties consignorType={consignorType} consigneeType={consigneeType} />
      </PageWrapper>
    </SplitPage>
  );
};

export default FirstStep;
