import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import Select from 'js/components/Select';

import { tu } from 'js/utils/translate';
import { shopOptions } from 'js/components/Select/utils';
import { getProcessedInvoiceBatchesForShop } from '../../store/processedInvoiceBatchesForShop';

import { ApiFlowShopSimple } from 'src/interfaces/interfaces.generated';
import { Option } from 'js/components/SelectV2/interfaces';

import './index.sass';
import { useAppDispatch } from 'js/utils/hooks';

interface Props {
  shopsLoaded: boolean;
  shops: ApiFlowShopSimple[];
  setShopSelected: Dispatch<SetStateAction<boolean>>;
}

const SelectShop: FC<Props> = ({ shopsLoaded, shops, setShopSelected }) => {
  const dispatch = useAppDispatch();

  const [selectedShop, setSelectedShop] = useState<Option>({});

  const hasOneShop = shopsLoaded && shops.length === 1;

  useEffect(() => {
    if (hasOneShop) setSelectedShop({ label: shops[0].name, value: shops[0].shopId });
  }, [shopsLoaded]);

  useEffect(() => {
    if (!isEmpty(selectedShop)) {
      setShopSelected(true);
      if (selectedShop.value && typeof selectedShop.value === 'number')
        dispatch(getProcessedInvoiceBatchesForShop(selectedShop.value));
    }
  }, [selectedShop]);

  return (
    <Select
      name='selectShopForInvoice'
      className='select-shop-for-invoice'
      label={tu('general.shop')}
      isLoading={!shopsLoaded}
      options={shopOptions(shops)}
      onChange={(e) => {
        setSelectedShop(e);
      }}
      selectedValues={[selectedShop]}
      disabled={hasOneShop}
    />
  );
};

export default SelectShop;
