// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Amplitude {
  public static readonly ACCORDION_TOGGLE_ALL = '[Accordion] Toggle all';
  public static readonly ACCORDION__OPEN = 'Open';
  public static readonly ACCORDION__CLASS = 'Class';

  public static readonly BUTTON_CLICKED = '[Button] Clicked';
  public static readonly BUTTON__CLASS = 'Class';

  public static readonly COLLAPSIBLE_PANEL_TOGGLED = '[Collapsible panel] Toggled';

  public static readonly COLLAPSIBLE_PANEL_EDIT_CLICKED = '[Collapsible panel] Edit Clicked';

  public static readonly COLLAPSIBLE_PANEL__CLASS = 'Class';
  public static readonly COLLAPSIBLE_PANEL__OPEN = 'Open';

  public static readonly INPUT_FOCUSED = '[Form Input] Focused';
  public static readonly INPUT_FOCUSED__CLASS = 'Class';
}
