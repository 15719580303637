import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import HamburgerMenuMainSection from './components/MainSection';
import LogoutButton from '../../../components/LogoutButton';

import { findNavLinks } from 'js/routes';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  open: boolean;
}

const HamburgerMenu: FC<Props> = ({ open }) => {
  const userRoles = useAppSelector((state: RootState) => state?.user?.data?.roles);
  const activeOwnerParty = useAppSelector(
    (state: RootState) => state?.user?.data?.activeOwnerParty
  );

  const navLinks = findNavLinks(userRoles, activeOwnerParty);

  return (
    <nav className='hamburger-menu'>
      {navLinks.map((navPage) => (
        <HamburgerMenuMainSection key={navPage.name} navPage={navPage} open={open} />
      ))}
      <LogoutButton />
    </nav>
  );
};

export default HamburgerMenu;
