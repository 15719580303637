import { FC, useEffect } from 'react';
import { useValue } from '@distribution-innovation/form-hooks';

import PackageContent from './components/PackageContent';
import PageWrapper from 'js/components/PageWrapper';

import t from 'js/utils/translate';

import './index.sass';

const ParcelsStep: FC = () => {
  const [parcels, setParcels] = useValue('parcels');
  const addParcel = () => setParcels([...(parcels || []), {}]);

  const removeParcel = (index) => setParcels(parcels?.filter((parcel, i) => i !== index));

  useEffect(() => {
    if (!parcels || parcels.length === 0) addParcel();
  }, []);

  return (
    <PageWrapper className='parcels-step'>
      <h2>{t('orders.new.order.parcels.and.articles')}</h2>
      <div>
        {parcels?.map((parcel, i) => (
          <PackageContent key={i} index={i} removeParcel={removeParcel} />
        ))}
      </div>
      <div className='link add-parcel' onClick={addParcel}>
        {t('orders.new.order.add.parcel')}
      </div>
    </PageWrapper>
  );
};

export default ParcelsStep;
