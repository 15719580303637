import { createSlice } from '@reduxjs/toolkit';

import { set as setLocalStorage, get as getLocalStorage } from 'js/utils/storage';

interface InitialState {
  data: boolean[];
}

const initialState: InitialState = {
  data: []
};

const accordionSlice = createSlice({
  name: 'accordion',
  initialState,
  reducers: {
    setStates(state, action) {
      state.data = action.payload;
    },
    toggleStates(state, action) {
      const toggledValues = state.data.map(() => action.payload.value);
      state.data = [...toggledValues];
      if (action.payload.storageName) setLocalStorage(action.payload.storageName, toggledValues);
    }
  }
});

export const { setStates, toggleStates } = accordionSlice.actions;

export default accordionSlice.reducer;

export const setAccordionStates =
  (storageName: string | undefined, states: boolean[]) => (dispatch) => {
    if (storageName) setLocalStorage(storageName, states);
    dispatch(setStates(states));
  };

export const getAccordionStates =
  (storageName: string | undefined) =>
  async (dispatch): Promise<boolean[] | undefined> => {
    const states = await getLocalStorage(storageName)
      ?.split(',')
      ?.map((state) => state === 'true');
    if (states) dispatch(setStates(states));
    return states;
  };

export const toggleAccordionStates =
  (value: boolean, storageName: string | undefined) => (dispatch) => {
    dispatch(toggleStates({ value, storageName }));
  };
