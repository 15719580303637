import { FC } from 'react';
import { format } from 'date-fns';
import { RootState } from 'js/store/reducer';

import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import FormV2 from 'js/components/FormV2';
import SelectShops from './components/SelectShops';
import SelectConcerns from './components/SelectConcerns';
import DatepickerRange from 'js/components/Form/components/DatepickerRange';

import { yesterday } from 'js/utils/dateTime';
import { generateInvoiceBatch } from './store/generateInvoiceBatchReducer';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

const NewInvoice: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const error = useAppSelector(
    (state: RootState) => state?.economy?.invoiceBatches?.generateInvoiceBatch?.error
  );
  const isSaving = useAppSelector(
    (state: RootState) => state?.economy?.invoiceBatches?.generateInvoiceBatch?.isSaving
  );

  const buildSubmit = (invoiceBatch) => {
    const { interval, ...rest } = invoiceBatch;
    return {
      periodFrom: format(interval.fromDate, 'yyyy-MM-dd'),
      periodTo: format(interval.toDate, 'yyyy-MM-dd'),
      ...rest
    };
  };

  const handleSubmit = (values) => {
    dispatch(generateInvoiceBatch(buildSubmit(values))).then((response: boolean | undefined) => {
      if (response) navigate('/economy/generateInvoice');
    });
  };

  const invoiceBatch = {
    shopIds: [],
    concernIds: [],
    interval: {}
  };

  return (
    <SplitPage>
      <PageWrapper className='new-invoice'>
        <FormV2
          className='new-invoice-form'
          onSubmit={handleSubmit}
          actions
          initialValues={invoiceBatch}
          error={error}
          loading={isSaving}
        >
          <SelectShops name='shopIds' />
          <SelectConcerns name='concernIds' />
          <DatepickerRange name='interval' maxDate={yesterday()} />
        </FormV2>
      </PageWrapper>
    </SplitPage>
  );
};

export default NewInvoice;
