import { FC } from 'react';
import FreightProductPriceRow from './components/FreightProductPriceRow';
import t from 'js/utils/translate';

import { MatrixProps } from '../../interfaces';

import './index.sass';

const DesktopMatrix: FC<MatrixProps> = ({
  freightProductPrices,
  currentPrices = true,
  selectedFilter,
  showGrayMatrix
}) => (
  <table className='price-matrix'>
    <thead>
      <tr>
        <th className='price-matrix__column-header'>
          {t('economy.costprice.matrix.column.header')}
        </th>
        {freightProductPrices?.columns?.map((zone) => (
          <th key={zone} className='price-matrix__zones'>
            {zone}
          </th>
        ))}
        <th className='price-matrix__all-zones'>{t('economy.costprice.matrix.all.zones')}</th>
      </tr>
    </thead>
    <tbody>
      {freightProductPrices?.rows?.map((weightClass, index) => (
        <FreightProductPriceRow
          key={weightClass}
          weightClass={weightClass}
          index={index}
          priceRow={freightProductPrices?.priceMatrix?.[index]}
          currentPrices={currentPrices}
          selectedFilter={selectedFilter}
          showGrayMatrix={showGrayMatrix}
        />
      ))}
    </tbody>
  </table>
);

export default DesktopMatrix;
