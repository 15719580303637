import { FC, useMemo } from 'react';

import InfoBox from 'js/components/InfoBox';
import TableV2 from 'js/components/TableV2';

import t from 'js/utils/translate';
import { ApiOrderArticleSimple } from 'interfaces/interfaces.generated';
import { useAppSelector } from 'js/utils/hooks';

interface Props {
  articles: ApiOrderArticleSimple[];
}

const ArticlesBody: FC<Props> = ({ articles }) => {
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);

  if (!articles || articles.length === 0) return <InfoBox text={t('general.noInformationFound')} />;

  const articlesColumns = useMemo(
    () => [
      {
        Header: t('table.id'),
        inHeader: true,
        accessor: 'id'
      },
      {
        Header: t('table.name'),
        inHeader: true,
        accessor: 'name'
      },
      {
        Header: t('table.quantity'),
        accessor: 'quantity'
      }
    ],
    []
  );

  return (
    <TableV2
      className={isDesktop ? 'order-accordion__articles-desktop-table' : ''}
      data={articles}
      columns={articlesColumns}
      mobileTableVariant='withSmallEditIcon'
    />
  );
};

export default ArticlesBody;
