import isFuture from 'date-fns/isFuture';
import { ApiFreightPrice, ApiFreightPriceCostPrice } from 'src/interfaces/interfaces.generated';

export const findPrice = (
  currentPrices: boolean,
  costPrices?: ApiFreightPriceCostPrice[],
  selectedFilter?: string
): number | boolean | undefined =>
  costPrices
    ?.filter((costPrice) => costPrice.priceType === selectedFilter)
    .map((costPrice) => {
      if (costPrice && costPrice.fromDate) {
        if (currentPrices && !isFuture(new Date(costPrice.fromDate))) return costPrice.costPrice;
        if (!currentPrices && isFuture(new Date(costPrice.fromDate))) return costPrice.costPrice;
      }
      return false;
    })
    .find(Boolean);

export const samePrice = (
  currentPrices: boolean,
  priceRow?: ApiFreightPrice[],
  selectedFilter?: string
): boolean | undefined =>
  priceRow?.every(
    (price) =>
      findPrice(currentPrices, price?.costPrices, selectedFilter) ===
      findPrice(currentPrices, priceRow?.[0]?.costPrices, selectedFilter)
  );
