import { findNavLinks } from 'js/routes';
import { useLocation } from 'react-router';

import NavLink from './components/NavLink/';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';

const NavigationBar = () => {
  const location = useLocation();

  const findActive = () =>
    findNavLinks().find(
      (page) =>
        !!page.dropdownSections.find((dropdownSection) => dropdownSection.to === location.pathname)
    );

  const activeMainRoute = findActive();

  const userRoles = useAppSelector((state) => state?.user?.data?.roles);
  const activeOwnerParty = useAppSelector((state) => state?.user?.data?.activeOwnerParty);

  const navLinks = findNavLinks(userRoles, activeOwnerParty);

  return (
    <nav className='navigation-bar'>
      {navLinks.map((navPage) => (
        <NavLink
          to={navPage.to}
          name={navPage.name}
          key={navPage.name}
          activeMainRoute={activeMainRoute?.name}
          dropdownSections={navPage.dropdownSections}
          location={location}
          exact
        />
      ))}
    </nav>
  );
};

export default NavigationBar;
