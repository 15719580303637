import { FC, useRef } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';
import {
  apiUserFormColumns,
  flowUserFormColumns
} from 'js/scenes/UserAdministration/table/columns';
import t from 'js/utils/translate';
import { UserType } from 'js/scenes/UserAdministration/Users.types';
import RadioGroup from 'js/components/RadioGroup';
import UserSearch from 'js/scenes/UserAdministration/UserActions/Connect/UserSearch';
import TableV2 from 'js/components/TableV2';
import { UserFormViewProps } from 'js/scenes/UserAdministration/UserActions/Connect/UserForm.types';

const UserFormView: FC<UserFormViewProps> = ({ setUserType }) => {
  const searchInputRef = useRef<any>(null);
  const context = useFormContext();

  const userType = context?.getValue('type');

  function resetValues() {
    context?.setValue('search', []);
    searchInputRef.current.setValue([]);
  }

  const handleChange = (value: UserType) => {
    context?.setValue('type', value);
    setUserType(value);
    resetValues();
  };

  return (
    <div className='user-form'>
      <RadioGroup
        name='type'
        label={t('userAdministration.userType')}
        onChange={(e) => {
          handleChange(e.target.value as UserType);
        }}
        radios={[
          {
            label: t('userAdministration.flow.tabName'),
            value: 'flow',
            checked: userType === 'flow'
          },
          {
            label: t('userAdministration.api.tabName'),
            value: 'api',
            checked: userType === 'api'
          }
        ]}
      />
      <div className='user-form'>
        <UserSearch userType={userType} searchInputRef={searchInputRef} />
        <TableV2
          columns={userType === 'flow' ? flowUserFormColumns(t) : apiUserFormColumns(t)}
          data={context?.getValue('search')}
          mobileTableVariant={'withSmallEditIcon'}
        />
      </div>
    </div>
  );
};

export default UserFormView;
