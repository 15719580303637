import { FC, useEffect, useState } from 'react';
import { format } from 'date-fns';

import FormV2 from 'js/components/FormV2';
import Datepicker from 'js/components/Form/components/Datepicker';
import WeightClass from './components/WeightClass';
import Spinner from 'js/components/Spinner';

import { saveCostPrices } from '../../../../store';
import t, { tu } from 'js/utils/translate';
import { tomorrow } from 'js/utils/dateTime';
import {
  buildSubmit,
  findInitialFreightPrices,
  findWeightClassPricesForAllZones,
  findWeightIds
} from './utils';

import { RootState } from 'js/store/reducer';
import { ApiFreightPrice, TransportPriceTable } from 'src/interfaces/interfaces.generated';
import { FormValues, PriceType } from './interfaces';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface Props {
  shopId: number;
  freightProductId: number;
  freightProductPrices: TransportPriceTable;
  freightProductPricesLoaded: boolean;
  redirect: () => void;
}

const EditCostPricesForm: FC<Props> = ({
  shopId,
  freightProductId,
  freightProductPrices,
  freightProductPricesLoaded,
  redirect
}) => {
  const dispatch = useAppDispatch();

  const hasDifferentSizes = Number(freightProductId) === 15;
  const priceTypes: PriceType[] = hasDifferentSizes ? ['SMALL', 'MEDIUM', 'LARGE'] : ['ALL'];
  const weightIds = findWeightIds(freightProductPrices.priceMatrix);

  const weightClassPricesForAllZones = freightProductPrices?.priceMatrix
    ? findWeightClassPricesForAllZones(freightProductPrices.priceMatrix, priceTypes)
    : [];

  const freightProductPricesSaving: boolean = useAppSelector(
    (state: RootState) => state.economy.costPrices.isSaving
  );
  const savingError: boolean = !!useAppSelector(
    (state: RootState) => state?.economy?.costPrices?.error
  );

  const [initialValues, setInitialValues] = useState<FormValues | undefined>();

  useEffect(() => {
    if (
      shopId &&
      freightProductId &&
      freightProductPricesLoaded &&
      freightProductPrices?.priceMatrix
    ) {
      setInitialValues({
        freightPrices: findInitialFreightPrices(freightProductPrices?.priceMatrix),
        weightClassPricesForAllZones,
        fromDate: tomorrow()
      });
    }
  }, [freightProductPricesLoaded]);

  const handleSubmit = (values: FormValues) => {
    if (shopId && freightProductId && weightIds && freightProductPrices?.columns) {
      const submit: ApiFreightPrice[] = buildSubmit(
        values,
        freightProductPrices.columns,
        weightClassPricesForAllZones,
        hasDifferentSizes,
        weightIds
      );
      dispatch(
        saveCostPrices(shopId, freightProductId, format(values.fromDate, 'yyyy-MM-dd'), submit)
      ).then((response: boolean | undefined) => {
        if (response) redirect();
      });
    }
  };

  if (!initialValues) return <Spinner />;

  return (
    <FormV2
      className='edit-cost-prices-form'
      onSubmit={handleSubmit}
      initialValues={initialValues}
      loading={freightProductPricesSaving}
      onCancel={redirect}
      error={savingError}
    >
      {freightProductPrices.rows?.map((weightClass, weightClassIndex) => (
        <WeightClass
          key={weightClass}
          weightId={weightIds?.[weightClassIndex]}
          row={weightClass}
          columns={freightProductPrices?.columns ?? []}
          weightClassIndex={weightClassIndex}
          priceTypes={priceTypes}
          weightClassPricesForAllZones={weightClassPricesForAllZones}
        />
      ))}
      <h4>{t('general.date')}</h4>
      <Datepicker name='fromDate' label={tu('general.dateFrom')} minDate={tomorrow()} />
    </FormV2>
  );
};

export default EditCostPricesForm;
