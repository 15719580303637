import { FC } from 'react';
import { delay } from 'lodash';

import PageWrapper from 'js/components/PageWrapper';
import SplitPage from 'js/components/SplitPage';
import Input from 'js/components/Form/components/Input';
import FormV2 from 'js/components/FormV2';
import TimeInterval from './components/TimeInterval';
import SelectSection from './components/SelectSection';
import MessageForUser from 'js/components/MessageForUser';
import MoreChoices from './components/MoreChoices';
import SelectLabelOrdering from './components/SelectLabelOrdering';
import { UserFeedback } from './components/UserFeedback';

import { buildSubmit } from './utils';
import t, { tu } from 'js/utils/translate';

import { getLabels } from './store/labelsReducer';
import { getCarDepartures } from './store/carDeparturesReducer';
import { getCarNumbers } from './store/carNumbersReducer';
import { getPreTransportCarNumbers } from './store/preTransportCarNumbersReducer';
import { getSubTransportCarNames } from './store/subTransportCarNamesReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import { RootState } from 'js/store/reducer';

import './index.sass';
import { useNavigate } from 'react-router-dom';

const Labels: FC = () => {
  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ownerParty: number = useAppSelector(
    (state: RootState) => state?.user?.data?.activeOwnerParty
  );

  const orderingLabels: boolean = useAppSelector(
    (state: RootState) => state?.orders?.labels?.isLoading
  );
  const error = useAppSelector((state: RootState) => state?.orders?.labels?.error);
  const foundLabels: boolean | undefined = useAppSelector(
    (state: RootState) => state?.orders?.labels?.isFound
  );

  const mainCarNumbers: string[] = useAppSelector(
    (state: RootState) => state?.orders?.carNumbers?.data
  );

  const preTransportCarNumbers: string[] = useAppSelector(
    (state: RootState) => state?.orders?.preTransportCarNumbers?.data
  );

  const subTransportCarNames: string[] = useAppSelector(
    (state: RootState) => state?.orders?.subTransportCarNames?.data
  );

  const mainCarPlannedDepartures: string[] = useAppSelector(
    (state: RootState) => state?.orders?.carDepartures?.data
  );
  const userEmail: string = useAppSelector((state: RootState) => state.user.data.email);

  const handleSubmit = (values) => {
    const submitValues = buildSubmit(values);
    dispatch(getLabels(submitValues)).then((response) => {
      if (response) {
        delay(() => {
          navigate('/orders/labelHistory');
        }, 2000);
      }
    });
  };

  const handleCollapseClick = () => {
    // TODO is this wanted??? It adds sorting options when "More choices" bellow are opened
    dispatch(getCarNumbers());
    dispatch(getPreTransportCarNumbers());
    dispatch(getSubTransportCarNames());
    dispatch(getCarDepartures());
  };

  return (
    <SplitPage className='generate-labels-page'>
      <div className='left-container'>
        <PageWrapper>
          <FormV2
            className='labels-section'
            onSubmit={handleSubmit}
            initialValues={{
              emailForLabels: userEmail,
              importDateToggle: true,
              importedDates: {
                fromDate: new Date(),
                toDate: new Date()
              },
              deliveryDateToggle: false,
              desiredDeliveryDates: {
                fromDate: new Date(),
                toDate: new Date()
              },
              includeExportedLabels: false,
              includeOnlyAsaLabelOrders: false
            }}
            actions
            type='getLabels'
            loading={orderingLabels}
            error={error}
          >
            <SelectSection />
            <TimeInterval />
            <SelectLabelOrdering
              ownerParty={ownerParty}
              mainCarNumbers={mainCarNumbers}
              preTransportCarNumbers={preTransportCarNumbers}
              subTransportCarNames={subTransportCarNames}
              mainCarPlannedDepartures={mainCarPlannedDepartures}
              key={mainCarNumbers?.length + ' ' + mainCarPlannedDepartures?.length}
            />
            <Input
              name='emailForLabels'
              label={tu('orders.labels.email.for.labels')}
              info={t('orders.labels.email.for.labels.info')}
              subLabel={!isDesktop && t('orders.labels.email.for.labels.could.take.some.time')}
            />
            <MoreChoices
              mainCarNumbers={mainCarNumbers}
              mainCarPlannedDepartures={mainCarPlannedDepartures}
              onCollapseClick={handleCollapseClick}
            />
            {foundLabels !== undefined && <UserFeedback foundLabels={foundLabels} />}
          </FormV2>
        </PageWrapper>
      </div>
      <PageWrapper>
        <MessageForUser variant='info' message={t('orders.labels.info.could.take.time')} />
      </PageWrapper>
    </SplitPage>
  );
};

export default Labels;
