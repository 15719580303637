import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { ApiServiceSupplier } from 'src/interfaces/interfaces.generated';

import * as rest from '../../index.rest';

export interface ServiceSuppliersReducerState {
  error: unknown;
  data: ApiServiceSupplier[];
  isLoading: boolean;
  isLoaded: boolean;
}

const initialState: ServiceSuppliersReducerState = {
  error: null,
  data: [],
  isLoading: false,
  isLoaded: false
};

const serviceSupplierSlice = createSlice({
  name: 'getServiceSuppliers',
  initialState,
  reducers: {
    getServiceSuppliersRequest(state) {
      state.error = null;
      state.data = [];
      state.isLoading = true;
      state.isLoaded = false;
    },
    getServiceSuppliersSuccess(state, action) {
      state.data = action.payload;
      state.isLoading = false;
      state.isLoaded = true;
    },
    getServiceSuppliersFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
      state.isLoaded = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getServiceSuppliersRequest, getServiceSuppliersSuccess, getServiceSuppliersFailed } =
  serviceSupplierSlice.actions;

export default serviceSupplierSlice.reducer;

export const getServiceSuppliers =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getServiceSuppliersRequest());
      const response: ApiServiceSupplier[] = await rest.getServiceSuppliers();
      dispatch(getServiceSuppliersSuccess(response));
      return true;
    } catch (error) {
      dispatch(getServiceSuppliersFailed(error));
    }
  };
