import { FC } from 'react';
import { format } from 'date-fns';

import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import PriceAdjustmentForm from '../../components/PriceAdjustmentForm';

import { addFuturePriceAdjustment } from '../../store/priceAdjustmentsReducer';

import { ApiPriceAdjustment } from 'src/interfaces/interfaces.generated';
import { useAppDispatch } from 'js/utils/hooks';

import './index.sass';
import { useNavigate } from 'react-router-dom';

const NewPriceAdjustment: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const priceAdjustment: ApiPriceAdjustment = {
    change: 0.01,
    concernIds: []
  };

  const buildSubmit = (priceAdjustment): ApiPriceAdjustment => {
    const { dateFrom, ...rest } = priceAdjustment;
    return {
      dateFrom: format(dateFrom, 'yyyy-MM-dd'),
      ...rest
    };
  };

  const handleSubmit = async (values) => {
    await dispatch(addFuturePriceAdjustment(buildSubmit(values))).then(
      (response: boolean | undefined) => {
        if (response) navigate('/economy/priceAdjustments');
      }
    );
  };

  return (
    <SplitPage>
      <PageWrapper className='new-price-adjustment'>
        <PriceAdjustmentForm
          onSubmit={handleSubmit}
          initialValues={priceAdjustment}
          type='create'
        />
      </PageWrapper>
    </SplitPage>
  );
};

export default NewPriceAdjustment;
