import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { ApiServiceSupplier } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';

export interface EditServiceSupplierReducerState {
  error: unknown;
  isUpdating: boolean;
  isUpdated: boolean;
}

const initialState: EditServiceSupplierReducerState = {
  error: null,
  isUpdating: false,
  isUpdated: false
};

const editServiceSupplierSlice = createSlice({
  name: 'EditServiceSupplier',
  initialState,
  reducers: {
    editServiceSupplierRequest(state) {
      state.error = null;
      state.isUpdating = true;
      state.isUpdated = false;
    },
    editServiceSupplierSuccess(state) {
      state.isUpdating = false;
      state.isUpdated = true;
    },
    editServiceSupplierFailed(state, action) {
      state.error = action.payload;
      state.isUpdating = false;
      state.isUpdated = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { editServiceSupplierRequest, editServiceSupplierSuccess, editServiceSupplierFailed } =
  editServiceSupplierSlice.actions;

export default editServiceSupplierSlice.reducer;

export const editServiceSupplier =
  (serviceSupplier: ApiServiceSupplier, serviceSupplierId: number) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(editServiceSupplierRequest());
      // const response: ApiServiceSupplier =
      await api.editServiceSupplier(serviceSupplier, serviceSupplierId);
      dispatch(editServiceSupplierSuccess());
      // return response.id;
      return true;
    } catch (error) {
      dispatch(editServiceSupplierFailed(error));
    }
  };
