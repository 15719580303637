import Ajax from 'js/utils/ajax/ajax';

import { ApiUser } from 'interfaces/interfaces.generated';

export const getServiceSuppliers = () => Ajax.get('/shops/v1/servicesuppliers');

export const giveUserServiceSupplierAccess = (serviceSupplierId: number, user: ApiUser) =>
  Ajax.post(`/useradmin/v1/user/servicesuppliers/${serviceSupplierId}`, user);

export const createServiceSupplierUser = (
  serviceSupplierId: number,
  user: ApiUser
): Promise<ApiUser> => Ajax.post(`/useradmin/v1/servicesupplieruser/${serviceSupplierId}`, user);
