export const upperCaseFirst = (string) => string.replace(/^\w/, (c) => c.toUpperCase());

export const lowerCaseFirst = (string) => string.replace(/^\w/, (c) => c.toLowerCase());

export const simpleHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
};
