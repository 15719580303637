import Toggle from 'js/components/Form/components/Toggle';
import Cars from './components/Cars';
import CollapseV2 from 'js/components/CollapseV2';

import t from 'js/utils/translate';

import './index.sass';

const MoreChoices = ({ mainCarNumbers, mainCarPlannedDepartures, onCollapseClick }) => {
  const collapse = {
    variant: 'colored',
    title: t('orders.labels.more.choices'),
    onCollapseClick,
    body: (
      <div className='labels__more-choices'>
        <Toggle name='includeExportedLabels' label={t('orders.labels.include.exported.labels')} />
        <Toggle
          name='includeOnlyAsaLabelOrders'
          label={t('orders.labels.include.only.asa.label.orders')}
        />
        {mainCarNumbers?.length > 0 && (
          <Cars
            mainCarNumbers={mainCarNumbers}
            mainCarPlannedDepartures={mainCarPlannedDepartures}
          />
        )}
      </div>
    )
  };

  return <CollapseV2 {...collapse} />;
};

export default MoreChoices;
