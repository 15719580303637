import store from 'js/store/store';

import { get as localStorageGet } from 'js/utils/storage';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Amplitude {
  // Create
  public static readonly USER_CREATE_INITIALIZED = '[User Administration] User creation initiated';

  public static readonly USER_CREATE_SUCCESS = '[User Administration] User created';

  // Edit
  public static readonly USER_EDIT_INITIALIZED = '[User Administration] User editing initiated';

  public static readonly USER_EDIT_FIELD = '[User Administration] Field edited on user';

  public static readonly USER_EDIT_SUCCESS = '[User Administration] User edited';

  // Connect
  public static readonly USER_CONNECT_INITIALIZED =
    '[User Administration] User connection initiated';

  public static readonly USER_CONNECT_SUCCESS = '[User Administration] User connected';

  // Disconnect
  public static readonly USER_DISCONNECT_INITIALIZED =
    '[User Administration] User disconnection initiated';

  public static readonly USER_DISCONNECT_SUCCESS = '[User Administration] User disconnected';

  // Properties
  public static readonly OWNER_PARTY = 'Owner party';

  public static readonly USER_TYPE = 'User type';

  public static readonly FIELD_EDITED = 'Field edited';

  static getMetricProperties = (userTypeAffected?: string, fieldEdited?: string) => {
    const activeOwnerParty =
      store?.getState().user?.data?.activeOwnerParty ?? localStorageGet('activeOwnerParty');
    return {
      [this.OWNER_PARTY]: activeOwnerParty,
      [this.USER_TYPE]: userTypeAffected,
      [this.FIELD_EDITED]: fieldEdited
    };
  };

  static getMetricParameters = (
    eventInput: string,
    userTypeAffected?: string,
    fieldEdited?: string
  ) => {
    return {
      eventInput,
      eventProperties: this.getMetricProperties(userTypeAffected, fieldEdited)
    };
  };
}
