import { createSlice } from '@reduxjs/toolkit';
import { OrderParty } from 'src/interfaces/interfaces.generated';
import * as api from '../../api';
import { locationChanged } from 'js/store/actions';
import { OrderPartyType } from '../../interfaces';

export interface EditOrderPartyReducerState {
  error: unknown;
  isSaving: boolean;
  isSaved: boolean;
}

const initialState: EditOrderPartyReducerState = {
  error: null,
  isSaving: false,
  isSaved: false
};

const editOrderPartySlice = createSlice({
  name: 'EditOrderParty',
  initialState,
  reducers: {
    editOrderPartyRequest(state) {
      state.error = null;
      state.isSaving = true;
    },
    editOrderPartySuccess(state) {
      state.isSaving = false;
    },
    editOrderPartyFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { editOrderPartyRequest, editOrderPartySuccess, editOrderPartyFailed } =
  editOrderPartySlice.actions;

export default editOrderPartySlice.reducer;

export const editOrderParty =
  (trackingReference: string, orderPartyType: OrderPartyType, party: OrderParty) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(editOrderPartyRequest());
      await api.editOrderParty(trackingReference, orderPartyType, party);
      dispatch(editOrderPartySuccess());
      return true;
    } catch (error) {
      dispatch(editOrderPartyFailed(error));
    }
  };
