import { FC, useEffect } from 'react';
import { tu } from 'js/utils/translate';
import { RootState } from 'js/store/reducer';

import Input from 'js/components/Form/components/Input';
import SelectV2 from 'js/components/Form/components/SelectV2';

import { getPackageSystemLocations } from '../../../../../../store/packageSystemLocationsReducer';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface Props {
  asbActive: boolean;
}

const ASB: FC<Props> = ({ asbActive }) => {
  const dispatch = useAppDispatch();

  const packageSystemLocationsLoaded = useAppSelector(
    (state: RootState) => state?.freight?.shop?.packageSystemLocations?.isLoaded
  );
  const packageSystemLocations = useAppSelector(
    (state: RootState) => state?.freight?.shop?.packageSystemLocations?.data
  );

  useEffect(() => {
    if (!packageSystemLocationsLoaded) {
      dispatch(getPackageSystemLocations());
    }
  }, [packageSystemLocationsLoaded]);

  return (
    <div className='shop-external-packaging-system'>
      <div className='shop-external-packaging-system__shop-goods-owner'>
        <Input
          name='shopExternalPackagingSystem.shopGoodsOwnerId'
          label={tu('shop.configuration.shopGoodsOwnerId')}
          optional={!asbActive}
          type='number'
        />
        <Input
          name='shopExternalPackagingSystem.shopGoodsOwnerCode'
          label={tu('shop.configuration.shopGoodsOwnerCode')}
          optional={!asbActive}
        />
      </div>
      <SelectV2
        options={packageSystemLocations?.map(({ id, name }) => ({
          value: id,
          label: name
        }))}
        isLoading={!packageSystemLocationsLoaded}
        name='shopPackageSystemLocationIds'
        label={tu('shop.configuration.shopPackageSystemLocation')}
        isMulti
      />
    </div>
  );
};

export default ASB;
