import { createSlice } from '@reduxjs/toolkit';
import { ApiPackageInfo } from 'src/interfaces/interfaces.generated';
import * as api from '../../api';
import { locationChanged } from 'js/store/actions';

export interface PackageInfoReducerState {
  error: unknown;
  data: ApiPackageInfo;
  isLoading: boolean;
  isLoaded: boolean;
}

const initialState: PackageInfoReducerState = {
  error: null,
  data: {},
  isLoading: false,
  isLoaded: false
};

const getPackageInfoSlice = createSlice({
  name: 'GetPackageInfo',
  initialState,
  reducers: {
    getPackageInfoRequest(state) {
      state.error = null;
      state.data = {};
      state.isLoading = true;
      state.isLoaded = false;
    },
    getPackageInfoSuccess(state, action) {
      state.isLoaded = true;
      state.isLoading = false;
      state.data = action.payload;
    },
    getPackageInfoFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
      state.isLoaded = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getPackageInfoRequest, getPackageInfoSuccess, getPackageInfoFailed } =
  getPackageInfoSlice.actions;

export default getPackageInfoSlice.reducer;

export const getPackageInfo =
  (trackingReference: string) =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(getPackageInfoRequest());
      const response: ApiPackageInfo = await api.getPackageInfo(trackingReference);
      dispatch(getPackageInfoSuccess(response));
    } catch (err) {
      dispatch(getPackageInfoFailed(err));
    }
  };
