import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import LoginButton from './components/LoginButton';
import ResetPasswordLink from '../../components/ResetPasswordLink';
import Input from 'js/components/Form/components/Input';
import FormV2 from 'js/components/FormV2';

import { login } from 'js/store/authenticationReducer';
import { tu } from 'js/utils/translate';
import { Credentials } from './interfaces';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate } from 'react-router-dom';

const LoginPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const authenticationError = useAppSelector((state: RootState) => state.authentication.error);
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );

  const handleSubmit = (values: Credentials) => {
    dispatch(login(values)).then(() => {
      if (isAuthenticated) {
        navigate('/');
      }
    });
  };

  if (isAuthenticated) navigate('/');

  return (
    <div className='login-page'>
      <FormV2
        onSubmit={handleSubmit}
        className='login-form'
        actions={false}
        error={authenticationError}
      >
        <Input
          autoFocus
          name='username'
          type='username'
          label={tu('general.username')}
          error={false}
        />
        <Input name='password' type='password' label={tu('general.password')} error={false} />
        <LoginButton />
        <ResetPasswordLink />
      </FormV2>
    </div>
  );
};

export default LoginPage;
