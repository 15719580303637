import { FC } from 'react';

import t from 'js/utils/translate';

import './index.sass';

interface Props {
  scope: string;
}

const DashboardDataScope: FC<Props> = ({ scope }) => (
  <span className='dashboard-data-scope'>{t(`mainPanel.dashboard.${scope}`)}</span>
);
export default DashboardDataScope;
