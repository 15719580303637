import { FC, useEffect } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import SelectV2 from 'js/components/Form/components/SelectV2';

import { tu } from 'js/utils/translate';
import { adminRights, findAdminRights, mapFromAdminRightsToOptions } from './utils';
import { ApiUser } from 'src/interfaces/interfaces.generated';

interface Props {
  user?: ApiUser;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

const SelectAdminRights: FC<Props> = ({ user, name, placeholder, disabled, className }) => {
  const context = useFormContext();

  useEffect(() => {
    if (user) context.setValue(name, findAdminRights(user));
  }, []);

  return (
    <SelectV2
      className={className}
      label={tu('general.roles')}
      name={name}
      placeholder={placeholder}
      options={mapFromAdminRightsToOptions(adminRights)}
      disabled={disabled}
      isMulti
      selectAll
      optional
    />
  );
};

export default SelectAdminRights;
