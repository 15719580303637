import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface DownloadInvoiceBatchReducerState {
  id: null | number;
  isDownloading: boolean;
  isDownloaded: boolean;
  error: unknown;
}

const initialState: DownloadInvoiceBatchReducerState = {
  id: null,
  isDownloading: false,
  isDownloaded: false,
  error: null
};

const downloadInvoiceBatchSlice = createSlice({
  name: 'invoiceBatch',
  initialState,
  reducers: {
    downloadInvoiceBatchRequest(state, action) {
      state.id = action.payload;
      state.isDownloading = true;
      state.isDownloaded = false;
      state.error = null;
    },
    downloadInvoiceBatchSuccess(state) {
      state.isDownloading = false;
      state.isDownloaded = true;
    },
    downloadInvoiceBatchFailed(state, action) {
      state.isDownloading = false;
      state.isDownloaded = false;
      state.error = action.payload;
    }
  }
});

export const {
  downloadInvoiceBatchRequest,
  downloadInvoiceBatchSuccess,
  downloadInvoiceBatchFailed
} = downloadInvoiceBatchSlice.actions;

export default downloadInvoiceBatchSlice.reducer;

export const downloadInvoiceBatch = (invoiceBatchId) => async (dispatch) => {
  try {
    dispatch(downloadInvoiceBatchRequest(invoiceBatchId));
    await rest.downloadInvoiceBatch(invoiceBatchId);
    dispatch(downloadInvoiceBatchSuccess());
  } catch (error) {
    dispatch(downloadInvoiceBatchFailed(error));
  }
};
