import { combineReducers } from 'redux';

import shopReducer, { ShopReducerState } from './scenes/Shops/scenes/Shop/index.reducer';
import shopsReducer, { ShopsReducerState } from './store/shopsReducer';
import usersReducer, { UsersReducerState } from './store/usersReducer';
import userReducer, { UserReducerState } from './store/userReducer';
import serviceSuppliersReducer, {
  ServiceSuppliersReducerRootState
} from './scenes/ServiceSuppliers/index.reducer';
import concernsReducer, { ConcernsReducerState } from './scenes/Concerns/index.reducer';

const freightReducer = combineReducers({
  shop: shopReducer,
  shops: shopsReducer,
  users: usersReducer,
  user: userReducer,
  serviceSuppliers: serviceSuppliersReducer,
  concerns: concernsReducer
});

export default freightReducer;

export interface FreightReducerState {
  shop: ShopReducerState;
  shops: ShopsReducerState;
  users: UsersReducerState;
  user: UserReducerState;
  serviceSuppliers: ServiceSuppliersReducerRootState;
  concerns: ConcernsReducerState;
}
