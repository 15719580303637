import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useRegister, useValue } from '@distribution-innovation/form-hooks';

import Label from 'js/components/Label';

import 'js/components/TextArea/index.sass';

const TextArea = ({ name, label, info, optional = false, className }) => {
  const [selectedValues, setSelectedValues] = useValue(name);

  useRegister({ name, value: selectedValues });

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
  };

  return (
    <div className={classNames('text-area-input', className)}>
      {label && <Label htmlFor={name} label={label} info={info} optional={optional} />}
      <textarea
        name={name}
        value={selectedValues}
        onChange={(e) => {
          handleChange(e);
        }}
        required={!optional}
      />
    </div>
  );
};

export default TextArea;

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  info: PropTypes.string,
  optional: PropTypes.bool,
  className: PropTypes.string
};
