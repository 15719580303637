import { useEffect, useMemo } from 'react';
import {
  useRegister,
  useValue,
  withError,
  useFormContext
} from '@distribution-innovation/form-hooks';
import isEmpty from 'lodash/isEmpty';

import _Select from 'js/components/Select';

const Select = ({
  isMulti,
  name,
  selectAll,
  value,
  options,
  isLoading,
  disabled,
  optional = false,
  required = !optional,
  error,
  hideOptional,
  ...props
}) => {
  const context = useFormContext();

  const [selectedValues, setSelectedValues] = useValue(name);

  useEffect(() => {
    value && setSelectedValues(value);
  }, [value]);

  const onChange = (newValue) => setSelectedValues(newValue);

  const handleSelectAll = () => !disabled && setSelectedValues(options);

  const handleBlur = () => context.getTouched(name) || context.setTouched(name);

  const validate = (newValue) => {
    if ((newValue || (typeof newValue === 'object' && !isEmpty(newValue))) && !required)
      return undefined;
    if ((!newValue || (typeof newValue === 'object' && isEmpty(newValue))) && required)
      return 'valueMissing';
  };

  useRegister({ name, value: selectedValues, validate });
  return useMemo(() => (
    // eslint-disable-next-line react/jsx-pascal-case
    <_Select
      selectAll={selectAll}
      name={name}
      isMulti={isMulti}
      options={options}
      isLoading={isLoading}
      disabled={disabled}
      onChange={onChange}
      selectedValues={selectedValues}
      handleSelectAll={handleSelectAll}
      optional={optional}
      error={error}
      onBlur={handleBlur}
      hideOptional={hideOptional}
      {...props}
    />
  ));
};

export default withError()(Select);
