import { FC, useMemo } from 'react';
import { RootState } from 'js/store/reducer';

import MobileTable from './components/MobileTable';
import TableV2 from 'js/components/TableV2';
import Icon from 'js/components/Icon';
import ShipmentInfo from './components/ShipmentInfo';
import GeneralCellContent from './components/GeneralCellContent';

import t from 'js/utils/translate';
import { format } from 'date-fns';
import { setSelectedOrder } from 'js/scenes/Home/scenes/OrderDetails/store/orderReducer';
import { findFreightProductById, findShopById } from 'js/utils/find';
import { redirectCheck } from './utils';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import {
  ApiFlowShopSimple,
  ApiOrderSimple,
  FreightProduct
} from 'src/interfaces/interfaces.generated';
import { safariSafeDateString } from 'js/utils/dateTime';
import { useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { Amplitude } from 'js/scenes/Home/scenes/OrderSearch/amplitude';

const columns = (freightProducts: FreightProduct[], shops: ApiFlowShopSimple[]) =>
  useMemo(
    () => [
      {
        Header: t('orderSearch.result.table.shop.and.id'),
        accessor: (order: ApiOrderSimple) => (
          <GeneralCellContent
            mainText={`${findShopById(shops, order?.shopId)?.name ?? '-'} (${
              order?.shopId ?? '-'
            })`}
          />
        )
      },
      {
        Header: t('orderSearch.result.table.sender'),
        accessor: (order: ApiOrderSimple) => (
          <GeneralCellContent
            mainText={order?.consignor?.name}
            subTextArr={[
              order?.consignor?.address ?? '',
              `${order?.consignor?.zipCode ?? '-'} ${order?.consignor?.postalName ?? '-'}`,
              order?.consignor?.email ?? '',
              `${order?.consignor?.phone1 ?? '-'} / ${order?.consignor?.phone2 ?? '-'}`
            ]}
          />
        )
      },
      {
        Header: t('orderSearch.result.table.recipient'),
        accessor: (order: ApiOrderSimple) => (
          <GeneralCellContent
            mainText={order?.consignee?.name}
            subTextArr={[
              order?.consignee?.address ?? '',
              `${order?.consignee?.zipCode ?? '-'} ${order?.consignee?.postalName ?? '-'}`,
              order?.consignee?.email ?? '',
              `${order?.consignee?.phone1 ?? '-'} / ${order?.consignee?.phone2 ?? '-'}`
            ]}
          />
        )
      },
      {
        Header: t('orderSearch.result.table.freightProduct'),
        accessor: (order: ApiOrderSimple) => (
          <GeneralCellContent
            mainText={`${
              findFreightProductById(freightProducts, order?.freightProductId)?.name ?? '-'
            }`}
          />
        )
      },
      {
        id: 'date',
        Header: t('orderSearch.result.table.booked'),
        accessor: (order: ApiOrderSimple) =>
          order?.importedDate ? new Date(order.importedDate) : '',
        sortType: 'datetime',
        Cell: ({
          cell: {
            row: { original }
          }
        }) => (
          <div>
            <div>
              <Icon icon='download' />
              {format(new Date(safariSafeDateString(original.importedDate)), 'dd.MM.yyyy')}
            </div>
            {original?.pickup?.senderDropDate && (
              <div>
                <Icon icon='truck' />
                {format(
                  new Date(safariSafeDateString(original?.pickup?.senderDropDate)),
                  'dd.MM.yyyy'
                )}
              </div>
            )}
          </div>
        )
      },
      {
        Header: t('orderSearch.result.table.order'),
        accessor: (order: ApiOrderSimple) => <ShipmentInfo order={order} />
      },
      {
        Header: t('orderSearch.result.table.status'),
        accessor: (order: ApiOrderSimple) => (
          <GeneralCellContent mainText={order?.parcelStatus ? t(order?.parcelStatus) : '-'} />
        )
      }
    ],
    [freightProducts]
  );

interface Props {
  orders: ApiOrderSimple[];
}

const OrderSearchResultTable: FC<Props> = ({ orders }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const freightProducts: FreightProduct[] = useAppSelector(
    (state: RootState) => state?.freightProducts?.data
  );
  const shops: ApiFlowShopSimple[] = useAppSelector(
    (state: RootState) => state?.freight?.shops?.data
  );
  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const onClickRow = (order, e) => {
    if (redirectCheck(e)) {
      dispatch(setSelectedOrder(order));
      amplitude.track(Amplitude.NAVIGATE_TO_DETAIL);
      navigate(`${order.trackingReference}`, order);
    }
  };

  return (
    <TableV2
      pagination
      className={isDesktop ? 'page-padding order-table-desktop' : ''}
      columns={columns(freightProducts, shops)}
      data={orders}
      onClickRow={(order, e) => {
        onClickRow(order.original, e);
      }}
      goToLabel={t('orderSearch.result.table.go.to.details')}
      sortBy={[{ id: 'date', desc: true }]}
      customMobileTable={<MobileTable orders={orders} onClickRow={onClickRow} />}
    />
  );
};

export default OrderSearchResultTable;
