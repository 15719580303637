import classNames from 'classnames';

import Icon from 'js/components/Icon';

import './index.sass';

const BooleanProperty = ({ property }) => (
  <div
    className={classNames('stat', 'boolean-stats__item', {
      'boolean-stats__item--checked': property.value
    })}
  >
    <Icon icon={property.value ? 'check' : 'horizontal-rule'} />
    {property.label}
  </div>
);

const NumericProperty = ({ property }) => (
  <div
    className={classNames('stat', 'numeric-stats__item', {
      'numeric-stats__item--two-lined': property.value?.includes('mm')
    })}
  >
    {`${property.label}:\n${property.value}`}
  </div>
);

const PropertyGroup = ({ group }) => (
  <div className={classNames('property-group')}>
    {group.map((property, index) =>
      typeof property.value === 'boolean' ? (
        <BooleanProperty property={property} key={index} />
      ) : (
        <NumericProperty property={property} key={index} />
      )
    )}
  </div>
);

export default PropertyGroup;
