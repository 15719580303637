import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface SubTransportCarNamesReducerState {
  error: unknown;
  isLoaded: boolean;
  data: string[];
}

const initialState: SubTransportCarNamesReducerState = {
  error: null,
  isLoaded: false,
  data: []
};

const subTransportCarNamesSlice = createSlice({
  name: 'SubTransportCarNames',
  initialState,
  reducers: {
    getSubTransportCarNamesRequest(state) {
      state.error = null;
      state.isLoaded = false;
    },
    getSubTransportCarNamesSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getSubTransportCarNamesFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  }
});

export const {
  getSubTransportCarNamesRequest,
  getSubTransportCarNamesSuccess,
  getSubTransportCarNamesFailed
} = subTransportCarNamesSlice.actions;

export default subTransportCarNamesSlice.reducer;

export const getSubTransportCarNames = () => async (dispatch) => {
  try {
    dispatch(getSubTransportCarNamesRequest());
    const response: string[] = await rest.getSubTransportCarNames();
    dispatch(getSubTransportCarNamesSuccess(response));
  } catch (err) {
    dispatch(getSubTransportCarNamesFailed(err));
  }
};
