import { FC } from 'react';
import classNames from 'classnames';
import { RootState } from 'js/store/reducer';

import PageWrapper from 'js/components/PageWrapper';
import Breadcrumb from './components/Breadcrumb';
import PageHeaderActions from './components/PageHeaderActions';
import LocationHeader from './components/LocationHeader';
import { useAppSelector } from 'js/utils/hooks';

import { PageHeaderAction } from './interfaces';

import './index.sass';

interface Props {
  pageHeaderActions: PageHeaderAction[];
}

const PageHeader: FC<Props> = ({ pageHeaderActions }) => {
  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  return (
    <PageWrapper className='page-header'>
      <div
        className={classNames('upper-section', {
          'upper-section--with-actions': pageHeaderActions?.length > 0
        })}
      >
        {pageHeaderActions && (
          <PageHeaderActions isDesktop={isDesktop} pageHeaderActions={pageHeaderActions} />
        )}
        <Breadcrumb />
      </div>
      <LocationHeader />
    </PageWrapper>
  );
};

export default PageHeader;
