export const findStatusInfo = (id) => {
  switch (id) {
    case 1:
      // Prosessert GRÅ TAG
      return { name: 'parsed', variant: 'info' };
    case 2:
      // Duplikat GRÅ TAG
      return { name: 'duplicate', variant: 'info' };
    case 3:
      // Prosesseringsfeil RØD TAG
      return { name: 'parsing.error', variant: 'error' };
    case 12:
      // Venter på godkjenning før prosessering KNAPP: UTFØR DEKNINGSSJEKK
      return {
        name: 'not.parsed.pending',
        requiresAction: true,
        actionType: 'coverageCheck'
      };
    case 13:
      // I kø for prosessering GRÅ TAG
      return { name: 'parsing.queue', variant: 'info' };
    case 14:
      // Under prosessering GRÅ TAG
      return { name: 'parsing', variant: 'info' };
    case 15:
      // Prosessert, avventer godkjenning KNAPPER: GODKJENN, KANSELLER
      return {
        name: 'parsed.awaiting.approval',
        requiresAction: true,
        actionType: 'generateLabels'
      };
    case 16:
      // Kansellert GRÅ TAG
      return { name: 'canceled', variant: 'info' };
    case 17:
      // Fildata har blitt slettet GRÅ TAG
      return { name: 'deleted', variant: 'info' };
    case 18:
      // I kø for prossesering (etiketter) GRÅ TAG
      return { name: 'labels.parsing.queue', variant: 'info' };
    case 19:
      // Under prosessering (etiketter) GRÅ TAG
      return { name: 'labels.parsing', variant: 'info' };
    case 20:
      // Etiketter generert GRÅ TAG
      return { name: 'labels.generated', variant: 'info' };
    case 21:
      // Prosesseringsfeil (etiketter) RØD TAG
      return { name: 'labels.parsing.error', variant: 'error' };
    case 'unknown':
      // Ukjent id
      return { name: 'unknown', variant: 'info' };
    default:
      return '';
  }
};
