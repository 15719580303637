import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';
import { ApiCustomerSystem } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';

export interface CustomerSystemsReducerState {
  error: unknown;
  isLoaded: boolean;
  data: ApiCustomerSystem[];
}

const initialState: CustomerSystemsReducerState = {
  error: null,
  isLoaded: false,
  data: []
};

const customerSystemsSlice = createSlice({
  name: 'CustomerSystems',
  initialState,
  reducers: {
    getCustomerSystemsRequest(state) {
      state.error = null;
      state.data = [];
      state.isLoaded = false;
    },
    getCustomerSystemsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getCustomerSystemsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getCustomerSystemsRequest, getCustomerSystemsSuccess, getCustomerSystemsFailed } =
  customerSystemsSlice.actions;

export default customerSystemsSlice.reducer;

export const getCustomerSystems =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getCustomerSystemsRequest());
      const response: ApiCustomerSystem[] = await api.getCustomerSystems();
      dispatch(getCustomerSystemsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getCustomerSystemsFailed(error));
    }
  };
