import { FC, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { RootState } from 'js/store/reducer';

import ColoredSectionWithHeader from '../ColoredSectionWithHeader';
import InfoBox from 'js/components/InfoBox';
import Label from 'js/components/Label';
import Spinner from 'js/components/Spinner';
import NotFound from 'js/components/NotFound';

import { showCheckmarkWithNameIfActive } from '../../utils';
import t, { tu } from 'js/utils/translate';
import { listBody } from 'js/components/CollapseV2/variants/listBody';
import { findCustomerSystem } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/utils';
import { getCustomerSystems } from '../../../../store/customerSystemsReducer';
import { ApiCustomerSystem } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';

const CustomerSystemsInfoList = ({ customerSystem, translateKeyPrefix }) =>
  listBody(
    [
      {
        label: 'longName',
        content: customerSystem?.longName ?? '-',
        orientation: 'row'
      },
      {
        label: 'shortName',
        content: customerSystem?.shortName ?? '-',
        orientation: 'row'
      }
    ],
    translateKeyPrefix
  );

interface Props {
  customerSystemId: number | undefined;
}

const CustomerSystemBody: FC<Props> = ({ customerSystemId }) => {
  const translateKeyPrefix = 'shop.accordion.customerSystem.header.';

  const dispatch = useAppDispatch();

  const customerSystems: ApiCustomerSystem[] = useAppSelector(
    (state: RootState) => state?.freight?.shop?.customerSystems?.data
  );
  const customerSystemsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shop?.customerSystems?.isLoaded
  );
  const customerSystemsError = useAppSelector(
    (state: RootState) => state?.freight?.shop?.customerSystems?.error
  );

  useEffect(() => {
    if (!customerSystemsLoaded) dispatch(getCustomerSystems());
  }, [customerSystemsLoaded, dispatch]);

  const customerSystem: ApiCustomerSystem = findCustomerSystem(customerSystems, customerSystemId);

  if (customerSystemsError) return <NotFound />;
  if (!customerSystemsLoaded && !customerSystemsError) return <Spinner />;
  if (customerSystemsLoaded && isEmpty(customerSystem))
    return <InfoBox text={t('shop.accordion.empty', t('general.customerSystem'))} />;

  return (
    <div className='customer-system-body'>
      <ColoredSectionWithHeader>
        <div>
          <Label label={tu(`${translateKeyPrefix}customer.communication`)} />
          <div>
            {showCheckmarkWithNameIfActive(
              customerSystem.sendAddressCollectorEmail,
              'address.collector'
            )}
          </div>
        </div>
        <div>
          <Label label={tu(`${translateKeyPrefix}customer.facing.name`)} />
          <div>{customerSystem?.customerFacingName ?? '-'}</div>
        </div>
      </ColoredSectionWithHeader>
      <CustomerSystemsInfoList
        customerSystem={customerSystem}
        translateKeyPrefix={translateKeyPrefix}
      />
    </div>
  );
};

export default CustomerSystemBody;
