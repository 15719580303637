import { combineReducers } from 'redux';

import getConcernsReducer, { GetConcernsReducerState } from './store/getConcernsReducer';
import newConcernReducer, {
  NewConcernReducerState
} from './scenes/NewConcern/store/newConcernReducer';
import editConcernReducer, {
  EditConcernReducerState
} from './scenes/EditConcern/store/editConcernReducer';

const reducer = combineReducers({
  getConcerns: getConcernsReducer,
  newConcern: newConcernReducer,
  editConcern: editConcernReducer
});

export default reducer;

export interface ConcernsReducerState {
  getConcerns: GetConcernsReducerState;
  newConcern: NewConcernReducerState;
  editConcern: EditConcernReducerState;
}
