export const mapRoles = (roles) => ({
  di: roles.some((role) => ['ROLE_di'].includes(role)),
  userAdmin: roles.some((role) => ['ROLE_di', 'ROLE_role.parcel.user.admin'].includes(role)),
  parcelAdmin: roles.some((role) => ['ROLE_di', 'ROLE_role.parcel.admin'].includes(role)),
  shopAdmin: roles.some((role) =>
    ['ROLE_di', 'ROLE_role.parcel.admin', 'ROLE_role.parcel.shop.admin'].includes(role)
  ),
  shop: roles.some((role) =>
    [
      'ROLE_di',
      'ROLE_role.parcel.admin',
      'ROLE_role.parcel.shop.admin',
      'ROLE_role.parcel.shop'
    ].includes(role)
  ),
  serviceSupplier: roles.some((role) =>
    [
      'ROLE_di',
      'ROLE_role.parcel.admin',
      'ROLE_role.parcel.shop.admin',
      'ROLE_role.parcel.shop',
      'ROLE_role.parcel.service.supplier'
    ].includes(role)
  ),
  label: roles.some((role) =>
    ['ROLE_di', 'ROLE_role.parcel.admin', 'ROLE_role.parcel.label'].includes(role)
  ),
  invoice: roles.some((role) => ['ROLE_di', 'ROLE_role.parcel.invoice'].includes(role)),
  qualityAdmin: roles.some((role) => ['ROLE_di', 'ROLE_role.parcel.quality.admin'].includes(role))
});
