import { ApiFreightPrice } from 'src/interfaces/interfaces.generated';

export const findNameIndex = (
  freightPrices: ApiFreightPrice[],
  zoneId: number,
  priceType: string,
  weightId: number
): number =>
  freightPrices.findIndex(
    (freightPrice: ApiFreightPrice) =>
      freightPrice.weightId === weightId &&
      freightPrice.zoneId === zoneId &&
      freightPrice.priceType &&
      freightPrice.priceType === priceType
  );
