import { isEmpty } from 'lodash';

export const convertToDate = (dateString) => {
  const arr = dateString.split('.');
  const date = new Date(arr[2], arr[1] - 1, arr[0]);
  return date;
};

export const findInitialValues = (obj) => {
  if (isEmpty(obj)) return {};
  const { concernIds, dateFrom, ...rest } = obj;
  return {
    dateFrom: convertToDate(dateFrom),
    concernIds: concernIds ?? [],
    ...rest
  };
};

export const findPriceAdjustmentById = (priceAdjustments, priceAdjustmentId) =>
  priceAdjustments.find(
    (priceAdjustment) => priceAdjustment.priceAdjustmentId === +priceAdjustmentId
  );
