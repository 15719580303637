import { createSlice } from '@reduxjs/toolkit';
import { ApiUser } from 'src/interfaces/interfaces.generated';
import * as api from '../../api';

export interface WebserviceUserPasswordReducerState {
  error: unknown;
  id: number | null;
  isReset: boolean;
  isResetting: boolean;
  password: string | null;
}

const initialState: WebserviceUserPasswordReducerState = {
  error: null,
  id: null,
  isReset: false,
  isResetting: false,
  password: null
};

const wsUserSlice = createSlice({
  name: 'WebserviceUser',
  initialState,
  reducers: {
    resetWebserviceUserPasswordRequest(state, action) {
      state.isResetting = true;
      state.error = null;
      state.isReset = false;
      state.id = action.payload;
      state.password = null;
    },
    resetWebserviceUserPasswordSuccess(state, action) {
      state.isResetting = false;
      state.isReset = true;
      state.password = action.payload;
    },
    resetWebserviceUserPasswordFailed(state, action) {
      state.isResetting = false;
      state.error = action.payload;
      state.isReset = false;
      state.id = null;
    },
    resetState(state) {
      state.error = null;
      state.id = null;
      state.isReset = false;
      state.isResetting = false;
      state.password = null;
    }
  }
});

export const {
  resetWebserviceUserPasswordRequest,
  resetWebserviceUserPasswordSuccess,
  resetWebserviceUserPasswordFailed,
  resetState
} = wsUserSlice.actions;

export default wsUserSlice.reducer;

export const resetWebserviceUserPassword =
  (userId: number) =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(resetWebserviceUserPasswordRequest(userId));
      const response: ApiUser = await api.updatePasswordWSUser(userId);
      dispatch(resetWebserviceUserPasswordSuccess(response.clearTextPassword));
    } catch (error) {
      dispatch(resetWebserviceUserPasswordFailed(error));
    }
  };

export const clearWebserviceUserPasswordState = () => (dispatch) => {
  dispatch(resetState());
};
