import { useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import PageWrapper from 'js/components/PageWrapper';
import SplitPage from 'js/components/SplitPage';
import ToggleGroup from 'js/components/Form/components/ToggleGroup';
import FormV2 from 'js/components/FormV2';
import Spinner from 'js/components/Spinner';
import SelectV2 from 'js/components/Form/components/SelectV2';

import { changeableEventTypeOptions } from 'js/components/Select/utils';
import { getChangeableEventTypes, updateOrderStatus } from './store/editStatusReducer';
import t, { tu } from 'js/utils/translate';

import { ApiOrderSimple } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate, useParams } from 'react-router-dom';
import EditStatusInfo from 'js/scenes/Home/scenes/OrderDetails/scenes/EditStatus/components/EditStatusInfo';

const toggle = [
  {
    name: 'wanted',
    label: 'edit.order.wanted.order'
  }
];

const EditStatus = () => {
  const params = useParams();
  const trackingReference = params.trackingReference;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const redirectTo = `/orderSearch/${trackingReference}`;

  const order: ApiOrderSimple = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.order?.data
  );
  const orderLoaded: boolean = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.order?.isLoaded
  );
  const changeableEventTypes = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.editStatus?.data
  );
  const changeableEventTypesLoaded = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.editStatus?.isLoaded
  );
  const changeableEventTypesError = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.editStatus?.error
  );
  const orderStatusSaving = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.editStatus?.isSaving
  );

  useEffect(() => {
    dispatch(getChangeableEventTypes(trackingReference!));
  }, []);

  const handleSubmit = async (values) => {
    await dispatch(updateOrderStatus(values, trackingReference!)).then((response) => {
      if (response) {
        navigate(redirectTo);
      }
    });
  };

  return (
    <SplitPage>
      <PageWrapper className='edit-status'>
        {orderLoaded ? (
          <FormV2
            onSubmit={handleSubmit}
            error={changeableEventTypesError}
            loading={orderStatusSaving}
            initialValues={{ wanted: order?.wanted, shopId: order?.shopId }}
            redirectTo={redirectTo}
            type='save'
          >
            <div>
              <ToggleGroup toggles={toggle} label={t('edit.order.wanted.order')} />
            </div>
            <SelectV2
              className='edit-status__select-status'
              name='eventTypeApiKey'
              label={tu('edit.order.select.orderStatus')}
              options={changeableEventTypeOptions(changeableEventTypes)}
              isLoading={!changeableEventTypesLoaded}
              optional
            />
          </FormV2>
        ) : (
          <Spinner />
        )}
      </PageWrapper>
      <PageWrapper>
        <EditStatusInfo />
      </PageWrapper>
    </SplitPage>
  );
};

export default EditStatus;
