import { FC } from 'react';
import classNames from 'classnames';

import DashboardDataNumber from './components/DashboardDataNumber';
import DashboardDataDescription from './components/DashboardDataDescription';

import { DashboardDataStatistic } from '../../interfaces';

import './index.sass';

interface Props {
  index: number;
  currentIndex: number;
  selectedInterval: string;
  rightClick: boolean;
  leftClick: boolean;
  statisticColumn: DashboardDataStatistic;
}

const DashboardData: FC<Props> = ({
  index,
  currentIndex,
  selectedInterval,
  rightClick,
  leftClick,
  statisticColumn
}) => (
  <div
    className={classNames(
      'dashboard-data',
      { 'dashboard-data--active': index === currentIndex },
      { 'click--right': rightClick },
      { 'click--left': leftClick }
    )}
  >
    <div className='data'>
      <DashboardDataNumber number={statisticColumn.number} />
      <DashboardDataDescription text={statisticColumn.text} scope={selectedInterval} />
    </div>
  </div>
);

export default DashboardData;
