import { FC, useMemo } from 'react';
import { RootState } from 'js/store/reducer';
import TableV2 from 'js/components/TableV2';

import { JobHistoryEntry } from 'src/interfaces/interfaces.generated';
import t, { tu } from 'js/utils/translate';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  jobHistory: JobHistoryEntry[];
}

const JobHistoryTable: FC<Props> = ({ jobHistory }) => {
  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const columns = useMemo(
    () => [
      {
        Header: tu('jobHistory.status'),
        accessor: 'status',
        inHeader: true
      },
      {
        Header: tu('jobHistory.start'),
        accessor: 'start',
        inHeader: true
      },
      {
        Header: tu('jobHistory.finish'),
        accessor: 'finish',
        inHeader: true
      },
      {
        Header: tu('jobHistory.duration'),
        accessor: 'duration',
        inHeader: true
      },
      {
        Header: tu('jobHistory.errorMessage'),
        accessor: 'errorMessage',
        inHeader: true
      }
    ],
    []
  );

  return (
    <div className={isDesktop ? 'jobs-desktop-table' : 'jobs-mobile-table'}>
      <h2>{t('jobHistory.title')}</h2>
      <TableV2
        columns={columns}
        data={jobHistory}
        idAccessor='start'
        mobileTableVariant='withLargeEditButton'
        pagination
      />
    </div>
  );
};

export default JobHistoryTable;
