import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';
import { getShopsRequest } from 'js/scenes/Freight/store/shopsReducer';

import { ApiFlowShop } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';
import { getShopFreightProducts } from '../shopFreightProductReducer';
import { getShopTransportSolutions } from '../shopTransportSolutionsReducer';
import { getShopUsers } from '../shopUsersReducer';
import { getCustomerCommunication } from '../customerCommunicationReducer';

export interface ShopReducerState {
  error: unknown;
  isLoaded: boolean;
  removing: boolean;
  data: ApiFlowShop;
}

const initialState: ShopReducerState = {
  error: null,
  isLoaded: false,
  removing: false,
  data: {}
};

const shopSlice = createSlice({
  name: 'Shop',
  initialState,
  reducers: {
    getShopRequest(state) {
      state.error = null;
      state.isLoaded = false;
      state.data = {};
    },
    getShopSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getShopFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    },
    removeShopRequest(state) {
      state.error = null;
      state.removing = true;
    },
    removeShopSuccess(state) {
      state.removing = false;
    },
    removeShopFailed(state, action) {
      state.error = action.payload;
      state.removing = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  getShopRequest,
  getShopSuccess,
  getShopFailed,
  removeShopRequest,
  removeShopSuccess,
  removeShopFailed
} = shopSlice.actions;

export default shopSlice.reducer;

// Reloads all the different shop related reducers that may rely on each other
// so the view is updated with the latest data
export const reloadShop =
  (shopId: number) =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(getShop(shopId));
      dispatch(getShopFreightProducts(shopId));
      dispatch(getShopTransportSolutions(shopId));
      dispatch(getShopUsers(shopId));
      dispatch(getCustomerCommunication(shopId));
      dispatch(getShopUsers(shopId));
    } catch (error) {
      dispatch(getShopFailed(error));
    }
  };

export const getShop =
  (shopId: number) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getShopRequest());
      const response: ApiFlowShop = await api.getShop(shopId);
      dispatch(getShopSuccess(response));
      return true;
    } catch (error) {
      dispatch(getShopFailed(error));
    }
  };

export const removeShop =
  (shopId: number) =>
  async (dispatch): Promise<boolean> => {
    try {
      dispatch(removeShopRequest());
      await api.removeShop(shopId);
      dispatch(removeShopSuccess());
      dispatch(getShopsRequest());
      return true;
    } catch (error) {
      dispatch(removeShopFailed(error));
      return false;
    }
  };
