import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { RootState } from 'js/store/reducer';
import { AccordionStates } from '../../interfaces';

import { toggleAccordionStates } from '../../store/accordionReducer';
import t from 'js/utils/translate';
import * as amplitude from '@amplitude/analytics-browser';

import './index.sass';
import { Amplitude } from 'js/components/amplitude';

interface Props {
  storageName?: string;
  className?: string;
}

const ToggleAllButton: FC<Props> = ({ storageName, className }) => {
  const dispatch = useAppDispatch();

  const states: AccordionStates = useAppSelector((state: RootState) => state?.accordion?.data);

  const allIsOpen = states.every((state) => state);

  const [open, setOpen] = useState(allIsOpen);

  useEffect(() => {
    if (allIsOpen) setOpen(true);
  }, [states]);

  const handleOnClick = () => {
    dispatch(toggleAccordionStates(!open, storageName));
    amplitude.track(Amplitude.ACCORDION_TOGGLE_ALL, {
      [Amplitude.ACCORDION__OPEN]: !open,
      [Amplitude.ACCORDION__CLASS]: className
    });
    setOpen(!open);
  };

  return (
    <div className='toggle-all-button'>
      <span className='link' onClick={handleOnClick}>
        {open ? t('general.closeAll') : t('general.openAll')}
      </span>
    </div>
  );
};

export default ToggleAllButton;
