import { FC } from 'react';
import t from 'js/utils/translate';
import MessageForUser from 'js/components/MessageForUser';
import ButtonV2 from 'js/components/ButtonV2';
import LinkV2 from 'js/components/LinkV2';
import { DisconnectUserViewProps } from 'js/scenes/UserAdministration/UserActions/Disconnect/DisconnectUser.types';

const DisconnectUserView: FC<DisconnectUserViewProps> = ({
  isError,
  isLoading,
  handleSubmit,
  setShowModal
}) => {
  return (
    <>
      <h2 id='disconnectUser-title'>{t('userAdministration.disconnect.modal.title')}</h2>
      <p id='disconnectUser-description'>{t('userAdministration.disconnect.modal.text')}</p>
      {isError && <MessageForUser variant='error' message={t('error.somethingWentWrong')} />}
      <div className={'modal-actions'}>
        <ButtonV2
          loading={isLoading}
          text={t('userAdministration.disconnect.button')}
          onClick={handleSubmit}
        />
        <LinkV2
          className='cancel-button'
          onClick={() => {
            setShowModal(false);
          }}
          text={t('general.cancel')}
          disabled={isLoading}
        />
      </div>
    </>
  );
};

export default DisconnectUserView;
