import { FC } from 'react';
import classNames from 'classnames';

import Icon from 'js/components/Icon';
import CopyText from 'js/components/CopyText';

import { downloadLabels } from 'js/utils/rest';

import { ApiOrderSimple } from 'src/interfaces/interfaces.generated';

import './index.sass';

interface Props {
  order: ApiOrderSimple;
}

const ShipmentInfo: FC<Props> = ({ order: { shipmentId, trackingReference, pickup, noLabel } }) => {
  const handleGetLabels = (shipmentId: string) => {
    downloadLabels(`orders/v1/export/labels/${shipmentId}`);
  };

  return (
    <div className='shipment-info'>
      {shipmentId && (
        <div>
          <div
            className={classNames(
              noLabel ? 'shipment-info__container' : 'shipment-info__container--link',
              'download-labels'
            )}
            onClick={() => {
              if (!noLabel) {
                handleGetLabels(shipmentId);
              }
            }}
          >
            <Icon icon='tags' />
            Last ned etikett
          </div>
          <div className='shipment-info__container'>
            <Icon icon='truck' />
            <CopyText text={shipmentId} feedbackPosition='right' />
          </div>
        </div>
      )}
      {trackingReference && (
        <div className='shipment-info__container'>
          <Icon icon='truck' />
          <CopyText text={trackingReference} feedbackPosition='right' />
        </div>
      )}
      {pickup?.pickupCode && (
        <div className='shipment-info__container'>
          <Icon icon='truck' />
          {pickup.pickupCode}
        </div>
      )}
    </div>
  );
};

export default ShipmentInfo;
