import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';
import { ApiCustomerSystem } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';

export interface EditCustomerSystemReducerState {
  error: unknown;
  isSaving: boolean;
}

const initialState: EditCustomerSystemReducerState = {
  error: null,
  isSaving: false
};

const editCustomerSystemSlice = createSlice({
  name: 'EditCustomerSystem',
  initialState,
  reducers: {
    editCustomerSystemRequest(state) {
      state.error = null;
      state.isSaving = true;
    },
    editCustomerSystemSuccess(state) {
      state.isSaving = false;
    },
    editCustomerSystemFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { editCustomerSystemRequest, editCustomerSystemSuccess, editCustomerSystemFailed } =
  editCustomerSystemSlice.actions;

export default editCustomerSystemSlice.reducer;

export const editCustomerSystem =
  (customerSystem: ApiCustomerSystem) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(editCustomerSystemRequest());
      await api.editCustomerSystem(customerSystem);
      dispatch(editCustomerSystemSuccess());
      return true;
    } catch (error) {
      dispatch(editCustomerSystemFailed(error));
    }
  };
