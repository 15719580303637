import { FC } from 'react';

import { formatNumber } from './utils';

import './index.sass';

interface Props {
  number?: string | number;
}

const DashboardDataNumber: FC<Props> = ({ number }) => (
  <div className='dashboard-data-number'>{number === undefined ? '-' : formatNumber(number)}</div>
);

export default DashboardDataNumber;
