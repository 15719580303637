import { FC, useEffect } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';
import classNames from 'classnames';
import { ApiUser } from 'src/interfaces/interfaces.generated';

import _Text from 'js/components/Form/components/Text';

interface Props {
  className?: string;
  name: string;
  user?: ApiUser;
  accessor: string;
}

const Text: FC<Props> = ({ className, name, user, accessor }) => {
  const context = useFormContext();

  useEffect(() => {
    if (user) context.setValue(`${name}[${accessor}]`, user[accessor]);
  }, []);

  return (
    <_Text
      name={`${name}[${accessor}]`}
      className={classNames(`edit-user__${accessor}`, className)}
    />
  );
};

export default Text;
