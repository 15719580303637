import { FC } from 'react';

import LabelHistoryDateTime from './components/LabelHistoryDateTime';
import TimeAndAmountOfLabels from './components/TimeAndAmountOfLabels';

import t from 'js/utils/translate';
import { LabelGenerationHistory } from 'src/interfaces/interfaces.generated';
import { LabelStatus } from '../../../../interfaces';

import './index.sass';

interface Props {
  labelExcerpt: LabelGenerationHistory;
  labelStatus?: LabelStatus;
}

const ExcerptStats: FC<Props> = ({ labelExcerpt, labelStatus }) => (
  <div className='excerpt-stats'>
    <LabelHistoryDateTime
      className='excerpt-stats__stat excerpt-stats__date-time'
      dateTime={labelExcerpt.ordered}
      label={t('orders.labelhistory.labels.ordered')}
    />
    <LabelHistoryDateTime
      className='excerpt-stats__stat excerpt-stats__date-time'
      dateTime={labelExcerpt.generated}
      showDateTime={labelStatus === 'done'}
      label={t('orders.labelhistory.labels.generated')}
    />
    <TimeAndAmountOfLabels
      className='excerpt-stats__stat'
      labelExcerpt={labelExcerpt}
      labelStatus={labelStatus}
    />
  </div>
);

export default ExcerptStats;
