import t from 'js/utils/translate';

const ZoneInterval = ({ toZoneId, fromZoneId }) => {
  const interval =
    !fromZoneId && !toZoneId ? t('general.none') : `${fromZoneId ?? '-'} - ${toZoneId ?? '-'}`;

  return <div>{t('economy.invoiceSetup.zoneInterval', interval)}</div>;
};

export default ZoneInterval;
