import { FC } from 'react';

interface Props {
  mainText?: string;
  subTextArr?: string[];
}

const GeneralCellContent: FC<Props> = ({ mainText, subTextArr = [] }) => {
  const items = [
    <div key='mainText' className='main-text'>
      {mainText ?? '-'}
    </div>
  ];
  let i = 0;
  const subTextItems = subTextArr
    ?.filter((text) => text !== '')
    .map((text) => (
      <div key={i++} className='sub-text'>
        {text}
      </div>
    ));
  items.push(...subTextItems);
  return <div>{items}</div>;
};

export default GeneralCellContent;
