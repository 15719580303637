import { FC, useState } from 'react';
import { isEmpty } from 'lodash';

import SelectUser from './components/SelectUser';
import EditUser from '../../../../../EditUsers/components/User/components/EditUser';

import t from 'js/utils/translate';

import './index.sass';

interface Props {
  isApiContext?: boolean;
  parentName: string;
  removeConnectUser;
  index: number;
}

const ConnectUser: FC<Props> = ({ isApiContext, parentName, removeConnectUser, index }) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [editUserActive, setEditUserActive] = useState<boolean | null>(false);

  return (
    <div className='connect-user'>
      <h4>
        {t(
          'freight.shop.users.connect.header',
          isApiContext ? t('general.apiUser') : t('general.user')
        )}
      </h4>
      <div className='connect-user__form-wrapper'>
        {isEmpty(selectedUser) ? (
          <div>
            <SelectUser isApiContext={isApiContext} setSelectedUser={setSelectedUser} />
          </div>
        ) : (
          <div>
            <EditUser
              isApiContext={isApiContext}
              user={selectedUser}
              name={parentName}
              index={index}
              editUserActive={editUserActive}
              setEditUserActive={setEditUserActive}
            />
          </div>
        )}
      </div>
      <div className='link cancel-connect-user' onClick={() => removeConnectUser(index)}>
        {t(
          'freight.shop.users.connect.cancel',
          isApiContext ? t('general.apiUser') : t('general.user')
        )}
      </div>
    </div>
  );
};

export default ConnectUser;
