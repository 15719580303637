import { FC, useEffect } from 'react';
import { tu } from 'js/utils/translate';
import Input from 'js/components/Form/components/Input';
import { useFormContext } from '@distribution-innovation/form-hooks';

const ZipCode: FC<{ namePrefix: string; optional: boolean }> = ({ namePrefix, optional }) => {
  const context = useFormContext();
  const inputName = `${namePrefix}PostalCode`;

  useEffect(() => {
    // If input contains non-numbers, show error
    if (!/^\d+$/.test(context?.getValue(inputName))) {
      context?.setError(inputName, 'mustBeNumbers');
    }
  }, [context?.getValue(inputName)]);

  return (
    <Input
      className='zip-code'
      name={inputName}
      label={tu('general.zipCode')}
      optional={optional}
      hideOptional={optional}
    />
  );
};

export default ZipCode;
