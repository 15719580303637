import { FC } from 'react';

import FormV2 from 'js/components/FormV2';
import ToggleGroup from 'js/components/Form/components/ToggleGroup';
import Input from 'js/components/Form/components/Input';
import SelectCustomerSystem from './components/SelectCustomerSystem';
import { Toggle } from 'js/components/Form/components/Toggle/interfaces';

import { tu } from 'js/utils/translate';

import './index.sass';

const Toggles = (namePrefix: string): Toggle[] => [
  {
    name: `${namePrefix}CustomerSystem.sendAddressCollectorEmail`,
    label: 'shop.accordion.address.collector'
  }
];

interface Props {
  namePrefix: string;
  initialValues?: unknown;
  handleSubmit: any;
  error: unknown;
  type: 'create' | 'save';
  isLoading: boolean;
  redirectTo: string;
}

const CustomerSystemForm: FC<Props> = ({
  namePrefix,
  initialValues,
  handleSubmit,
  error,
  type,
  isLoading,
  redirectTo
}) => (
  <FormV2
    initialValues={initialValues}
    onSubmit={handleSubmit}
    error={error}
    type={type}
    loading={isLoading}
    redirectTo={redirectTo}
  >
    <div className='edit-customer-system-body'>
      {type === 'save' && <SelectCustomerSystem />}
      <Input
        name={`${namePrefix}CustomerSystem.longName`}
        label={tu('shop.accordion.customerSystem.header.longName')}
        optional
        hideOptional
      />
      <div className='edit-customer-system-body__input-wrapper'>
        <Input
          maxLength='5'
          name={`${namePrefix}CustomerSystem.shortName`}
          label={tu('shop.accordion.customerSystem.header.shortName')}
          optional
          hideOptional
        />
        <Input
          name={`${namePrefix}CustomerSystem.customerFacingName`}
          label={tu('shop.accordion.customerSystem.header.customer.facing.name')}
          optional
        />
      </div>
      <ToggleGroup toggles={Toggles(namePrefix)} label={tu('shop.accordion.config.header.other')} />
    </div>
  </FormV2>
);

export default CustomerSystemForm;
