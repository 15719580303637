import ExternalCustomerNumbers from './components/ExternalCustomerNumbers';
import B2B from './components/B2B';
import AdditionalService from './components/AdditionalService';
import Other from './components/Other';

import t from 'js/utils/translate';
import { Collapse, CollapseVariant } from 'js/components/CollapseV2/interfaces';
import { ApiFlowShop } from 'src/interfaces/interfaces.generated';

export const shopConfigurationsAccordion = (
  shop: ApiFlowShop,
  variant: CollapseVariant
): Collapse[] => [
  {
    title: t('shop.accordion.config.header.external.customerIDs'),
    body: <ExternalCustomerNumbers shop={shop} />,
    variant
  },
  {
    title: t('shop.accordion.config.header.b2b'),
    body: <B2B />,
    variant
  },
  {
    title: t('shop.accordion.config.header.shopContractServices'),
    body: <AdditionalService />,
    variant
  },
  {
    title: t('shop.accordion.config.header.other'),
    body: <Other />,
    variant
  }
];
