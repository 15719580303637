import { combineReducers } from 'redux';

import editServiceSupplierReducer, {
  EditServiceSupplierReducerState
} from './scenes/EditServiceSupplier/store/editServiceSupplierReducer';
import newServiceSupplierReducer, {
  NewServiceSupplierReducerState
} from './scenes/NewServiceSupplier/store/newServiceSupplierReducer';
import serviceSuppliersReducer, {
  ServiceSuppliersReducerState
} from './store/serviceSuppliersReducer';
import serviceSupplierUserReducer, {
  ServiceSupplierUserReducerState
} from './store/serviceSupplierUserReducer';
import removeServiceSupplierUserReducer, {
  RemoveServiceSupplierUserReducerState
  // eslint-disable-next-line max-len
} from 'js/scenes/Freight/components/Users/components/EditUsers/components/User/components/UserActions/components/RemoveServiceSupplierUser/store/removeServiceSupplierUserReducer';

const reducer = combineReducers({
  editServiceSupplier: editServiceSupplierReducer,
  newServiceSupplier: newServiceSupplierReducer,
  serviceSuppliers: serviceSuppliersReducer,
  serviceSupplierUser: serviceSupplierUserReducer,
  removeServiceSupplierUser: removeServiceSupplierUserReducer
});

export default reducer;

export interface ServiceSuppliersReducerRootState {
  editServiceSupplier: EditServiceSupplierReducerState;
  newServiceSupplier: NewServiceSupplierReducerState;
  serviceSuppliers: ServiceSuppliersReducerState;
  serviceSupplierUser: ServiceSupplierUserReducerState;
  removeServiceSupplierUser: RemoveServiceSupplierUserReducerState;
}
