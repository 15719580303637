import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import PageWrapper from 'js/components/PageWrapper';
import DeleteFuturePrices from './components/DeleteFuturePrices';
import Spinner from 'js/components/Spinner';

import EditCostPricesForm from './components/EditCostPricesForm';

import { TransportPriceTable } from 'src/interfaces/interfaces.generated';
import { RootState } from 'js/store/reducer';

import { getCostPrices } from 'js/scenes/Economy/scenes/CostPrices/store';
import { useNavigate, useParams } from 'react-router-dom';

const EditCostPrices: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shopId, freightProductId } = useParams();

  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const freightProductPrices: TransportPriceTable = useAppSelector(
    (state: RootState) => state.economy.costPrices.data
  );
  const freightProductPricesLoaded: boolean = useAppSelector(
    (state: RootState) => state.economy.costPrices.isLoaded
  );

  const hasFuturePrices = !!freightProductPrices?.futureFromDates?.length;

  const redirect = () => {
    navigate('/economy/costPrices', {
      state: {
        shopId,
        freightProductId
      }
    });
  };

  useEffect(() => {
    if (shopId && freightProductId) dispatch(getCostPrices(+shopId, +freightProductId));
  }, []);

  if (!shopId || !freightProductId || !freightProductPrices?.columns) return <Spinner />;

  return (
    <PageWrapper className='edit-cost-prices' withoutPadding={!isDesktop}>
      {hasFuturePrices ? (
        <DeleteFuturePrices
          shopId={+shopId}
          freightProductId={+freightProductId}
          freightProductPrices={freightProductPrices}
          redirect={redirect}
        />
      ) : (
        <EditCostPricesForm
          shopId={+shopId}
          freightProductId={+freightProductId}
          freightProductPrices={freightProductPrices}
          freightProductPricesLoaded={freightProductPricesLoaded}
          redirect={redirect}
        />
      )}
    </PageWrapper>
  );
};

export default EditCostPrices;
