import { FC } from 'react';

import Label from 'js/components/Label';
import nb from 'date-fns/locale/nb';
import classnames from 'classnames';

import './index.sass';
import { safariSafeFormatting } from 'js/utils/dateTime';

interface Props {
  dateTime: string;
  label: string;
  showDateTime?: boolean;
  className: string;
}

const LabelHistoryDateTime: FC<Props> = ({ dateTime, label, showDateTime = true, className }) => (
  <div className={classnames('datetime-wrapper', className)}>
    <Label label={label} />
    <div>
      {showDateTime && dateTime
        ? safariSafeFormatting(dateTime, 'EEEE dd.MM.yyyy / HH:mm', {
            locale: nb
          })
        : '-'}
    </div>
  </div>
);

export default LabelHistoryDateTime;
