import { FC, useEffect } from 'react';

import PageWrapper from 'js/components/PageWrapper';
import SplitPage from 'js/components/SplitPage';
import FormV2 from 'js/components/FormV2';
import SelectV2 from 'js/components/Form/components/SelectV2';
import Spinner from 'js/components/Spinner';

import t, { tu } from 'js/utils/translate';
import { transportSolutionOptionsWithId } from 'js/components/Select/utils';

import {
  editShopTransportSolutions,
  getShopTransportSolutions,
  ShopTransportSolutionsReducerState,
  useShopTransportSolutions
} from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/shopTransportSolutionsReducer';
import { RootState } from 'js/store/reducer';
import { getTransportSolutions, useTransportSolutions } from 'js/store/transportSolutionsReducer';

import './index.sass';
import { reloadShop } from '../../store/shopReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate, useParams } from 'react-router-dom';

const EditShopTransportSolutions: FC = () => {
  const { shopId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const shopTransportSolutionsState: ShopTransportSolutionsReducerState =
    useShopTransportSolutions();
  const shopTransportSolutions = shopTransportSolutionsState.data;
  const shopTransportSolutionsLoading = shopTransportSolutionsState.isLoading;
  const shopTransportSolutionsSaving = shopTransportSolutionsState.isSaving;
  const shopIdForTransportSolutions = shopTransportSolutionsState.shopId;

  const transportSolutionsState = useTransportSolutions();
  const transportSolutionsLoaded = transportSolutionsState.isLoaded;
  const transportSolutions = transportSolutionsState.data;

  const error = useAppSelector((state: RootState) => shopTransportSolutionsState?.error);

  useEffect(() => {
    if (shopId !== shopIdForTransportSolutions) dispatch(getShopTransportSolutions(+shopId!));
  }, [shopId]);

  useEffect(() => {
    if (!transportSolutionsLoaded) dispatch(getTransportSolutions());
  }, [transportSolutionsLoaded]);

  const redirectTo = shopId ? `/freight/shop/${shopId}` : '/freight';

  const handleSubmit = (values) => {
    if (shopId) {
      dispatch(
        editShopTransportSolutions(shopId, {
          transportSolutionIds: values?.transportSolutionIds ?? []
        })
      ).then((response) => {
        if (response) {
          dispatch(reloadShop(+shopId));
          navigate(redirectTo);
        }
      });
    }
  };

  if (shopTransportSolutionsLoading) return <Spinner />;

  return (
    <SplitPage>
      <PageWrapper className='edit-shop-transport-solutions-page'>
        <FormV2
          onSubmit={handleSubmit}
          initialValues={{
            transportSolutionIds: shopTransportSolutions?.map((ts) => ts.transportSolutionId)
          }}
          loading={shopTransportSolutionsSaving}
          redirectTo={redirectTo}
          error={error}
        >
          <SelectV2
            name='transportSolutionIds'
            label={tu('general.transportSolutions')}
            options={transportSolutionOptionsWithId(transportSolutions)}
            isLoading={!transportSolutionsLoaded}
            placeholder={transportSolutionsLoaded ? t('select.transportSolutions') : ''}
            isMulti
            selectAll
            optional
          />
        </FormV2>
      </PageWrapper>
    </SplitPage>
  );
};

export default EditShopTransportSolutions;
