/* global DI */
import loadScript from './loadScript';

const scriptUrl = 'https://www.di.no/addresshelper/diah.js';

const defaultConfig = (countryCode) => ({
  apiKey: 'edM8hTPYGvCesX2MfkPMB1Gz5IS875tG',
  country: countryCode,
  limitToOfficial: false,
  disableHouseholdHelpPanel: true
});

class Addresshelper {
  constructor(address, node, config, onChange, setAddress, countryCode) {
    this.config = { ...defaultConfig(countryCode), ...config };
    this.onChange = onChange;
    this.setAddress = setAddress;

    this._handleSelectionChanged = this._handleSelectionChanged.bind(this);
    this._handlePopulateFailed = this._handlePopulateFailed.bind(this);

    if (address?.apartmentNumber) {
      if ('flat' in address) address.flat = address.apartmentNumber;
      else address = { flat: address.apartmentNumber, ...address };
    }

    loadScript(scriptUrl).then(() => this._init(node, address));
  }

  checkSupport(productId, distributionDate) {
    this.productId = productId;
    this.distributionDate = distributionDate;
    this.diah && this._handleSelectionChanged();
  }

  _init(node, address) {
    this.diah = new DI.AddressHelper(node, this.config);
    this.diah.selectionChangeEvent.subscribe(this._handleSelectionChanged);
    this.diah.populateFailedEvent.subscribe(this._handlePopulateFailed);

    if (address) {
      const {
        streetName,
        streetNumber,
        entrance,
        zip,
        city,
        floorType,
        floor,
        flat,
        apartmentNumber
      } = address;

      this.initialized = false;
      this.diah.parseAddress(
        streetName,
        streetNumber,
        entrance,
        zip,
        city,
        floorType,
        floor,
        flat,
        apartmentNumber
      );
    } else {
      this.initialized = true;
    }
  }

  _handleSelectionChanged() {
    const address = this.getAddress();
    const complete = this.getComplete();

    this._handleChange(address, complete);
  }

  _handlePopulateFailed() {
    this._handleChange(null, false);
  }

  _handleChange(address, complete, unsupported) {
    if (typeof this.onChange === 'function') {
      this.onChange(address, complete, unsupported, !this.initialized);
    }
    this.initialized = true;
  }

  getAddress() {
    let { street, streetNumber, floor, household } = this.diah.getSelectedAddress();

    street = street || {};
    streetNumber = streetNumber || {};
    floor = floor || {};
    household = household || {};

    const address = {
      deliveryPointId: household.deliveryPointId || streetNumber.deliveryPointId,
      houseType: streetNumber.houseType,
      streetName: street.streetName,
      streetNumber: streetNumber.streetNo,
      entrance: streetNumber.entrance || null,
      floorType: floor.floorType,
      floor: floor.floorNo,
      flat: household.flatNo,
      apartmentNumber: household.flatNo,
      zip: +streetNumber.postalCode || null,
      city: street.city,
      country: this.config.country
    };

    this.setAddress({ ...address, isComplete: false });

    return address;
  }

  getComplete() {
    const { address } = this.diah;

    const apiAddress = {
      apartmentNumber: address.household?.flatNo,
      flat: address.household?.flatNo,
      city: address.street?.city,
      countryCode: this.config.country,
      entrance: address.streetNumber?.entrance,
      floor: address.floor?.floorNo,
      floorType: address.floor?.floorType,
      streetName: address.street?.streetName,
      streetNumber: address.streetNumber?.streetNo,
      zip: address.streetNumber?.postalCode
    };

    this.setAddress({ ...apiAddress, isComplete: this.diah.isComplete() });
    return this.diah.isComplete();
  }
}

export default function addresshelper(
  address,
  node,
  config = {},
  onChange,
  setAddress,
  countryCode
) {
  return new Addresshelper(address, node, config, onChange, setAddress, countryCode);
}
