/* eslint-disable no-negated-condition */
import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import SplitPage from 'js/components/SplitPage';
import FormV2 from 'js/components/FormV2';
import Input from 'js/components/Form/components/Input';
import Datepicker from 'js/components/Form/components/Datepicker';
import PageWrapper from 'js/components/PageWrapper';
import Spinner from 'js/components/Spinner';

import { tu } from 'js/utils/translate';
import { wrongOrder } from 'js/scenes/Home/scenes/OrderDetails/utils';
import { getOrder } from '../../store/orderReducer';
import { editShipment } from './store/editShipmentReducer';
import { tomorrow } from 'js/utils/dateTime';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate, useParams } from 'react-router-dom';

const EditShipment: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const trackingReference = params?.trackingReference;

  const orderLoaded = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.order?.isLoaded
  );
  const order = useAppSelector((state: RootState) => state?.home?.orderDetails?.order?.data);

  const isSaving = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.editShipment?.isSaving
  );
  const error = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.editShipment?.error
  );

  useEffect(() => {
    if (wrongOrder(trackingReference!, order, orderLoaded) || !orderLoaded) {
      dispatch(getOrder(trackingReference!));
    }
  }, [trackingReference]);

  const redirectTo = `/orderSearch/${trackingReference}`;

  const handleSubmit = async (values) => {
    if (typeof trackingReference === 'string') {
      await dispatch(editShipment(trackingReference, values)).then((response) => {
        if (response) navigate(redirectTo);
      });
    }
  };

  const disabled = !order?.enableCancellation;

  const orderDate = () => {
    if (order?.pickup?.senderDropDate) return order.pickup.senderDropDate;
    else if (order?.desiredDeliveryDate) return order.desiredDeliveryDate;
    else return 0;
  };

  return (
    <SplitPage>
      <PageWrapper className='edit-shipment-page'>
        {!orderLoaded ? (
          <Spinner />
        ) : (
          <FormV2
            initialValues={{
              desiredDeliveryDate: orderDate() === 0 ? undefined : new Date(orderDate()),
              value: order.contentValue === 0 ? '0' : order.contentValue,
              description: order.description,
              shopId: order.shopId
            }}
            onSubmit={handleSubmit}
            disabled={disabled}
            redirectTo={redirectTo}
            loading={isSaving}
            error={error}
          >
            <div className='form-wrapper'>
              <Datepicker
                className='edit-shipment-form__desired-delivery-date'
                name='desiredDeliveryDate'
                label={tu('order.edit.shipment.desiredDeliveryDate')}
                minDate={tomorrow()}
                disabled={disabled}
                optional
              />
              <Input
                className='edit-shipment-form__content-value'
                name='value'
                type='number'
                label={tu('general.value')}
                disabled={true} // Disabled until editOrder api supports this
              />
              <Input
                name='description'
                label={tu('general.description')}
                optional
                disabled={true} // Disabled until editOrder api supports this
              />
            </div>
          </FormV2>
        )}
      </PageWrapper>
    </SplitPage>
  );
};

export default EditShipment;
