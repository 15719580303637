import { Dispatch, FC, SetStateAction } from 'react';
import classNames from 'classnames';
import { RootState } from 'js/store/reducer';

import Icon from 'js/components/Icon';
import Input from 'js/components/Form/components/Input';
import Text from '../Text';

import SelectApis from 'js/scenes/Freight/components/Users/components/SelectApis';
import SelectLanguage from 'js/scenes/Freight/components/Users/components/SelectLanguage';

import t, { tu } from 'js/utils/translate';
import { numAffectedShopsWS } from './utils';
import { useAppSelector } from 'js/utils/hooks';

interface Props {
  name: string;
  user;
  index: number;
  editUserActive: boolean | null;
  setEditUserActive: Dispatch<SetStateAction<boolean | null>>;
}

const EditUser: FC<Props> = ({ name, user, index, editUserActive, setEditUserActive }) => {
  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const shops = useAppSelector((state: RootState) => state?.freight?.shops?.data);

  return (
    <>
      <div
        className={classNames('edit-user__userName', {
          'edit-user__username--header': !isDesktop
        })}
      >
        <Text name={`${name}[${index}]`} user={user} accessor='userName' />
        {!isDesktop && (
          <Icon
            icon={editUserActive ? 'times' : 'pencil-alt'}
            handleClick={() => {
              setEditUserActive(!editUserActive);
            }}
          />
        )}
      </div>
      <Input
        type='email'
        name={`${name}[${index}]email`}
        className='edit-user__email-input'
        label={tu('general.email')}
        disabled={!editUserActive}
        optional={!editUserActive}
        hideOptional
      />
      <SelectApis
        name={`${name}[${index}]apis`}
        className='edit-user__apis'
        disabled={!editUserActive}
      />
      <SelectLanguage
        name={`${name}[${index}]language`}
        className='edit-user__language'
        disabled={!editUserActive}
        user={user}
      />
      <div className='edit-user__edit-icon'>
        <Icon
          icon={editUserActive ? 'times' : 'pencil-alt'}
          handleClick={() => {
            setEditUserActive(!editUserActive);
          }}
        />
      </div>
      {numAffectedShopsWS(shops, user) > 0 && (
        <div className='affected-shops-count'>
          {t('freight.shop.edit.user.numAffectedShops', numAffectedShopsWS(shops, user))}
        </div>
      )}
    </>
  );
};

export default EditUser;
