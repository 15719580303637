import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import TransportSolutionForm from '../../components/TransportSolutionForm';
import { newTransportSolution } from 'js/store/transportSolutionsReducer';

import '../EditTransportSolution/index.sass';
import { useAppDispatch } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

const NewTransportSolution = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const redirectTo = '/freight/transportSolutions';

  const handleSubmit = async (values) => {
    await dispatch(newTransportSolution(values)).then((response) => {
      response && navigate(redirectTo);
    });
  };

  return (
    <SplitPage>
      <PageWrapper className='new-transport-solution-page'>
        <TransportSolutionForm handleSubmit={handleSubmit} redirectTo={redirectTo} />
      </PageWrapper>
    </SplitPage>
  );
};

export default NewTransportSolution;
