import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import ConcernForm from '../../components/ConcernForm';

import { getConcerns } from '../../store/getConcernsReducer';
import { newConcern } from './store/newConcernReducer';
import { ApiConcern } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate } from 'react-router-dom';

const NewConcern: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const error = useAppSelector((state: RootState) => state?.freight?.concerns?.newConcern?.error);
  const isCreating = useAppSelector(
    (state: RootState) => state?.freight?.concerns?.newConcern?.isCreating
  );

  const redirectTo = {
    pathname: '/freight',
    state: { activeTab: 'concerns' }
  };

  const redirect = () => {
    navigate(redirectTo);
  };

  const handleSubmit = (values: ApiConcern) => {
    dispatch(newConcern(values)).then((response) => {
      if (response) {
        dispatch(getConcerns()).then((response: boolean | undefined) => {
          if (response) redirect();
        });
      }
    });
  };

  return (
    <SplitPage>
      <PageWrapper className='new-concern'>
        <ConcernForm
          onSubmit={handleSubmit}
          type='create'
          loading={isCreating}
          error={error}
          onCancel={redirect}
        />
      </PageWrapper>
    </SplitPage>
  );
};

export default NewConcern;
