import classNames from 'classnames';

import './index.sass';

const CopyTextFeedback = ({ isCopied, feedbackRef, message }) => (
  <div
    ref={feedbackRef}
    className={classNames('copy-text-feedback', {
      'copy-text-feedback--hidden': !isCopied
    })}
  >
    {message}
  </div>
);

export default CopyTextFeedback;
