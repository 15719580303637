import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import Search from './components/Search';
import Spinner from 'js/components/Spinner';
import OrderSearchResult from './components/OrderSearchResult';

import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  withFormTitle?: boolean;
}

const OrderSearch: FC<Props> = ({ withFormTitle = false }) => {
  const isSearching = useAppSelector(
    (state: RootState): boolean => state?.home?.orderSearch?.isSearching
  );

  return (
    <div className='order-search-page'>
      <Search withTitle={withFormTitle} />
      {isSearching ? <Spinner /> : <OrderSearchResult />}
    </div>
  );
};

export default OrderSearch;
