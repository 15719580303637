import { FC } from 'react';
import { UserType } from 'js/scenes/UserAdministration/Users.types';
import UsersView from 'js/scenes/UserAdministration/Users.view';
import { useUsersListWithTypeQuery } from 'js/scenes/UserAdministration/api/api';
import LoadComponent from 'js/components/LoadComponent';

interface Props {
  type: UserType;
}

const Users: FC<Props> = ({ type }) => {
  const { data, isError, isLoading } = useUsersListWithTypeQuery(type);
  return (
    <LoadComponent isLoading={isLoading} isError={isError}>
      <UsersView type={type} data={data} />
    </LoadComponent>
  );
};

export default Users;
