import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as rest from '../../api';

export interface DeletePriceAdjustmentReducerState {
  error: unknown;
  isDeleting: boolean;
}

const initialState: DeletePriceAdjustmentReducerState = {
  error: null,
  isDeleting: false
};

const deletePriceAdjustmentSlice = createSlice({
  name: 'deletePriceAdjustment',
  initialState,
  reducers: {
    deleteFuturePriceAdjustmentRequest(state) {
      state.isDeleting = true;
    },
    deleteFuturePriceAdjustmentSuccess(state) {
      state.isDeleting = false;
    },
    deleteFuturePriceAdjustmentFailed(state, action) {
      state.isDeleting = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  deleteFuturePriceAdjustmentRequest,
  deleteFuturePriceAdjustmentSuccess,
  deleteFuturePriceAdjustmentFailed
} = deletePriceAdjustmentSlice.actions;

export default deletePriceAdjustmentSlice.reducer;

export const deleteFuturePriceAdjustment =
  (priceAdjustmentId: number) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(deleteFuturePriceAdjustmentRequest());
      await rest.deleteFuturePriceAdjustment(priceAdjustmentId);
      dispatch(deleteFuturePriceAdjustmentSuccess());
      return true;
    } catch (err) {
      dispatch(deleteFuturePriceAdjustmentFailed(err));
    }
  };
