import { createSlice, Dispatch } from '@reduxjs/toolkit';
import * as api from '../../utils/rest';

export interface ProofOfDeliveryOptionsReducerState {
  error: unknown;
  data: string[];
  isLoaded: boolean;
  isSaving: boolean;
}

const initialState: ProofOfDeliveryOptionsReducerState = {
  error: null,
  data: [],
  isLoaded: false,
  isSaving: false
};

const proofOfDeliveryOptionsSlice = createSlice({
  name: 'proofOfDeliveryOptions',
  initialState,
  reducers: {
    getProofOfDeliveryOptionsRequest(state) {
      state.isLoaded = false;
      state.error = null;
      state.data = [];
    },
    getProofOfDeliveryOptionsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getProofOfDeliveryOptionsFailed(state, action) {
      state.isLoaded = false;
      state.data = action.payload;
    }
  }
});

export const {
  getProofOfDeliveryOptionsRequest,
  getProofOfDeliveryOptionsSuccess,
  getProofOfDeliveryOptionsFailed
} = proofOfDeliveryOptionsSlice.actions;

export default proofOfDeliveryOptionsSlice.reducer;

export const getProofOfDeliveryOptions =
  () =>
  async (dispatch: Dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getProofOfDeliveryOptionsRequest());
      const response: string[] = await api.getProofOfDeliveries();
      dispatch(getProofOfDeliveryOptionsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getProofOfDeliveryOptionsFailed(error));
    }
  };
