import t from 'js/utils/translate';

import './index.sass';

const DesktopFreightProductHeader = () => (
  <div className='shop-proof-of-delivery__headers'>
    <div>{t('freight.shop.edit.proofOfDelivery.freightProduct')}</div>
    <div>{t('freight.shop.edit.proofOfDelivery.on.freightProduct')}</div>
    <div>{t('freight.shop.edit.proofOfDelivery.follow.rule')}</div>
    <div>{t('freight.shop.edit.proofOfDelivery.on.shop')}</div>
  </div>
);

export default DesktopFreightProductHeader;
