import { FC } from 'react';

import CreateApiUserForm from './components/CreateApiUserForm';
import CreateUserForm from './components/CreateUserForm';

import t from 'js/utils/translate';

import './index.sass';

interface Props {
  isApiContext?: boolean;
  parentName: string;
  index: number;
  // eslint-disable-next-line no-unused-vars
  removeNewUser: (index: number) => void;
}

const CreateUser: FC<Props> = ({ isApiContext, parentName, index, removeNewUser }) => (
  <div className='create-user'>
    <h4>
      {t(
        'freight.shop.users.create.header',
        isApiContext ? t('general.apiUser') : t('general.user')
      )}
    </h4>
    {isApiContext ? (
      <CreateApiUserForm parentName={parentName} index={index} />
    ) : (
      <CreateUserForm parentName={parentName} index={index} />
    )}
    <div
      className='link cancel-create-user'
      onClick={() => {
        removeNewUser(index);
      }}
    >
      {t(
        'freight.shop.users.create.cancel',
        isApiContext ? t('general.apiUser') : t('general.user')
      )}
    </div>
  </div>
);

export default CreateUser;
