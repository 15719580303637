import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from 'js/components/Label';

import t from 'js/utils/translate';

import './index.sass';
import InputError from '../Form/components/Validation/InputError';
import * as amplitude from '@amplitude/analytics-browser';

const Select = ({
  label,
  info,
  disabled,
  optional,
  selectAll,
  isMulti,
  options,
  handleSelectAll,
  subLabel,
  setFormValue,
  formValue,
  isLoading,
  hideOptional = false,
  className = '',
  placeholder = null,
  error,
  onBlur,
  selectFirst,
  onChangeTrack
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (formValue === null) setValue(null);
    if (!isMulti && formValue) {
      const option = options.find((option) => option.value === formValue);
      if (option) setValue(option);
    }
    if (isMulti && formValue) {
      setValue(
        options.filter((option) => formValue.find((_formValue) => option.value === _formValue))
      );
    }
  }, [formValue, options]);

  const handleChange = (selectedValues) => {
    if (selectedValues === null) {
      return setFormValue(null);
    }
    if (!isMulti) setFormValue(selectedValues.value);
    if (isMulti) {
      setFormValue(selectedValues.map((selectedValues) => selectedValues.value));
    }
    if (onChangeTrack) {
      amplitude.track(
        onChangeTrack.eventInput,
        onChangeTrack?.eventProperties,
        onChangeTrack?.eventOptions
      );
    }
  };

  useEffect(() => {
    if (options?.length === 1 && selectFirst && !value?.length > 0) {
      if (isMulti) {
        setFormValue([options[0].value]);
      } else {
        setFormValue(options[0].value);
      }
    }
  }, [isLoading]);

  return (
    <div className={classNames('labeled-multi-select', className)}>
      {(label || selectAll) && (
        <div className='labeled-multi-select__header'>
          {label && <Label label={label} info={info} optional={!hideOptional && optional} />}
          {selectAll && isMulti && (
            <span
              className={classNames('select-all-btn', {
                'select-all-btn--disabled': disabled
              })}
              onClick={!disabled ? handleSelectAll : undefined}
            >
              {t('select.all')}
            </span>
          )}
        </div>
      )}
      <div className={classNames('select', { 'select--invalid': error })}>
        <ReactSelect
          isDisabled={disabled}
          placeholder={placeholder}
          isMulti={isMulti}
          options={options}
          isClearable={optional || isMulti}
          value={value}
          onChange={(e) => handleChange(e, [])}
          label={false}
          isLoading={isLoading}
          onBlur={onBlur}
        />
      </div>
      {subLabel && <Label label={subLabel} subLabel />}
      {error && <InputError error={error} />}
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  selectAll: PropTypes.bool,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  handleSelectAll: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  optional: PropTypes.bool,
  selectFirst: PropTypes.bool
};

export default Select;
