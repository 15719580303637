import { FC, useEffect, useState } from 'react';
import { RootState } from 'js/store/reducer';

import Spinner from 'js/components/Spinner';
import DashboardArrow from './components/DashboardArrow';
import DashboardData from './components/DashboardData';

import { getDashboardPackageData } from './store/dashboardPackageDataReducer';
import { timeDecimalString } from './utils';

import { DashboardDataStatistic } from './interfaces';
import { DashboardDataInterval } from '../../interfaces';
import { ApiDashboardPackageData } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  selectedInterval: DashboardDataInterval;
}

const PackageDashboard: FC<Props> = ({ selectedInterval }) => {
  const dispatch = useAppDispatch();

  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const isLoading: boolean = useAppSelector(
    (state: RootState) => state?.home?.dashboardPackageData?.isLoading
  );
  const dashboardPackageData: ApiDashboardPackageData = useAppSelector(
    (state: RootState) => state?.home?.dashboardPackageData?.data
  );

  useEffect(() => {
    dispatch(getDashboardPackageData(selectedInterval));
  }, [selectedInterval]);

  const numStatisticColumns = 3; // NB hardkodet
  const initialIndex = 0; // max value: (numStatisticColumns-1)
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const [leftClick, toggleLeftClick] = useState(false);
  const [rightClick, toggleRightClick] = useState(false);

  const handleLeftClick = () => {
    toggleLeftClick(true);
    toggleRightClick(false);
    currentIndex > 0 ? setCurrentIndex(currentIndex - 1) : setCurrentIndex(numStatisticColumns - 1);
  };

  const handleRightClick = () => {
    toggleRightClick(true);
    toggleLeftClick(false);
    currentIndex < numStatisticColumns - 1 ? setCurrentIndex(currentIndex + 1) : setCurrentIndex(0);
  };

  const StatisticColumns = (data: ApiDashboardPackageData): DashboardDataStatistic[] => [
    {
      number: data?.bookedCount ?? '-',
      text: 'booked'
    },
    {
      number: data?.deliveredCount ?? '-',
      text: 'delivered'
    },
    {
      number: timeDecimalString(data) ?? '-',
      text: 'deliveryTime.days'
    }
  ];

  if (isLoading) return <Spinner />;

  return (
    <div className='dashboard-package-data'>
      {!isDesktop && <DashboardArrow variant='left' handleClick={handleLeftClick} />}
      {StatisticColumns(dashboardPackageData).map((statisticColumn, index) => (
        <DashboardData
          key={statisticColumn.text}
          selectedInterval={selectedInterval}
          index={index}
          currentIndex={currentIndex}
          rightClick={rightClick}
          leftClick={leftClick}
          statisticColumn={statisticColumn}
        />
      ))}
      {!isDesktop && <DashboardArrow variant='right' handleClick={handleRightClick} />}
    </div>
  );
};

export default PackageDashboard;
