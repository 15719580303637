import { createRoot } from 'react-dom/client';
import store from 'js/store/store';
import { Provider } from 'react-redux';
import '@fortawesome/fontawesome-pro/js/all.js';
import App from './js/components/App';
import { QueryClient, QueryClientProvider } from 'react-query';

import * as Sentry from '@sentry/react';

import * as amplitude from '@amplitude/analytics-browser';

const queryClient = new QueryClient();

function render() {
  Sentry.init({
    dsn: 'https://e90cb76ff74d4bada6f2324bc6e7f997@o37388.ingest.sentry.io/5338060',
    environment: process.env.NODE_ENV
  });

  if (process.env.AMPLITUDE_API_KEY) {
    amplitude.init(process.env.AMPLITUDE_API_KEY, {
      serverZone: 'EU',
      defaultTracking: {
        pageViews: {
          trackHistoryChanges: 'all'
        }
      }
    });
  }

  const container = document.getElementById('app');
  const root = createRoot(container);

  root.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
      </Provider>
    </QueryClientProvider>
  );
}

render();
module.hot && module.hot.accept('js/components/App', render);
