import { FC } from 'react';

import Input from 'js/components/Form/components/Input';
import SystemMessageIcons from './components/SystemMessageIcons';
import DatepickerRange from 'js/components/Form/components/DatepickerRange';
import Datepicker from 'js/components/Form/components/Datepicker';

import { tu } from 'js/utils/translate';

import './index.sass';

interface Props {
  index: number;
  disabled: boolean;
  setEditingSystemMessageIndex: (index) => typeof index;
}

export const SystemMessage: FC<Props> = ({ index, disabled, setEditingSystemMessageIndex }) => (
  <div className='system-messages__row'>
    <Input
      className='system-message'
      label={tu('settings.system.info.message')}
      name={`systemInfo[${index}]message`}
      disabled={disabled}
      optional={disabled}
      hideOptional
    />
    <DatepickerRange
      name={`systemInfo[${index}].dates`}
      showTimeInput
      disabled={disabled}
      minDate={new Date()}
      optional
    />
    <Datepicker
      label={tu('settings.system.info.importantFromDate')}
      name={`systemInfo[${index}]importantFromDate`}
      showTimeInput
      disabled={disabled}
      optional
    />
    <SystemMessageIcons
      disabled={disabled}
      className='system-info-icon'
      index={index}
      setEditingSystemMessageIndex={setEditingSystemMessageIndex}
    />
  </div>
);

export default SystemMessage;
