import { createSlice } from '@reduxjs/toolkit';

import { ApiInvoiceBatches } from 'src/interfaces/interfaces.generated';

import * as rest from '../../api';

export interface GetInvoiceBatchesReducerState {
  error: unknown;
  isLoading: boolean;
  isLoaded: boolean;
  data: ApiInvoiceBatches;
}

const initialState: GetInvoiceBatchesReducerState = {
  error: null,
  isLoading: false,
  isLoaded: false,
  data: {}
};

const invoiceBatchSlice = createSlice({
  name: 'invoiceBatch',
  initialState,
  reducers: {
    getInvoiceBatchesRequest(state) {
      state.error = null;
      state.isLoading = true;
      state.isLoaded = false;
    },
    getInvoiceBatchesSuccess(state, action) {
      state.isLoading = false;
      state.isLoaded = true;
      state.data = action.payload;
    },
    getInvoiceBatchesFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
      state.isLoaded = false;
    }
  }
});

export const { getInvoiceBatchesRequest, getInvoiceBatchesSuccess, getInvoiceBatchesFailed } =
  invoiceBatchSlice.actions;

export default invoiceBatchSlice.reducer;

export const getInvoiceBatches = (page) => async (dispatch) => {
  try {
    dispatch(getInvoiceBatchesRequest());
    const response = await rest.getInvoiceBatches(page);
    dispatch(getInvoiceBatchesSuccess(response));
    return response;
  } catch (error) {
    dispatch(getInvoiceBatchesFailed(error));
  }
};
