import { useMemo } from 'react';

import InfoBox from 'js/components/InfoBox';
import TableV2 from 'js/components/TableV2';
import LabeledItem from 'js/components/LabeledItem';
import CommaSeparatedNames from 'js/components/CommaSeparatedNames';

import { findConcernById, findFreightProductById, findShopById } from 'js/utils/find';

import t, { tu } from 'js/utils/translate';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

const PriceAdjustmentsTable = ({ futurePriceAdjustments, shops, concerns, freightProducts }) => {
  const navigate = useNavigate();
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);
  const rowIcon = 'pencil-alt';

  const goToEditPage = (priceAdjustmentId) => {
    if (priceAdjustmentId) navigate(`/economy/priceAdjustments/${priceAdjustmentId}/edit`);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('general.shop'),
        accessor: ({ shopIds }) => (
          <CommaSeparatedNames ids={shopIds} find={findShopById} list={shops} />
        ),
        inHeader: true
      },
      {
        Header: t('general.concern'),
        accessor: ({ concernIds }) => {
          const item = (
            <CommaSeparatedNames ids={concernIds} find={findConcernById} list={concerns} />
          );
          if (isDesktop) return item;
          return <LabeledItem label={tu('general.concern')} item={item} />;
        }
      },
      {
        Header: t('general.freightProduct'),
        accessor: ({ freightProductIds }) => {
          const item = (
            <CommaSeparatedNames
              ids={freightProductIds}
              find={findFreightProductById}
              list={freightProducts}
            />
          );
          if (isDesktop) return item;
          return <LabeledItem label={tu('general.freightProduct')} item={item} />;
        }
      },
      {
        Header: t('general.dateFrom'),
        accessor: ({ dateFrom }) =>
          isDesktop ? dateFrom : <LabeledItem label={tu('general.dateFrom')} item={dateFrom} />
      },
      {
        Header: t('general.change'),
        accessor: ({ change }) =>
          isDesktop ? change : <LabeledItem label={tu('general.change')} item={change} />
      }
    ],
    [isDesktop]
  );

  if (futurePriceAdjustments?.length < 1) return <InfoBox text={t('general.noInformationFound')} />;

  return (
    <TableV2
      className={!isDesktop && 'price-adjustments-mobile-table'}
      columns={columns}
      data={futurePriceAdjustments}
      rowIcon={rowIcon}
      goToLabel={t('economy.priceAdjustments.edit')}
      onClickRow={goToEditPage}
      idAccessor='priceAdjustmentId'
      mobileTableVariant='withSmallEditIcon'
    />
  );
};

export default PriceAdjustmentsTable;
