import PropTypes from 'prop-types';

import Icon from 'js/components/Icon';

import { format } from 'date-fns';

import './index.sass';

const FormattedTime = ({ datetime }) => {
  if (!datetime.getDate()) {
    return null;
  }

  return (
    <span className='formatted-time'>
      <Icon icon='clock' regular /> {format(datetime, 'HH:mm:ss')}
    </span>
  );
};

export default FormattedTime;

FormattedTime.propTypes = {
  datetime: PropTypes.instanceOf(Date).isRequired
};
