import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../api';

import { ApiDashboardPackageData } from 'src/interfaces/interfaces.generated';
import { DashboardDataInterval } from 'js/scenes/Home/components/MainPanel/interfaces';

export interface DashboardPackageDataReducerState {
  error: unknown;
  data: ApiDashboardPackageData;
  isLoading: boolean;
}

const initialState = {
  error: null,
  data: {},
  isLoading: false
};

const dashboardPackageDataSlice = createSlice({
  name: 'DashboardPackageData',
  initialState,
  reducers: {
    getDashboardPackageDataRequest(state) {
      state.error = null;
      state.isLoading = true;
      state.data = {};
    },
    getDashboardPackageDataSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    getDashboardPackageDataFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});

export const {
  getDashboardPackageDataRequest,
  getDashboardPackageDataSuccess,
  getDashboardPackageDataFailed
} = dashboardPackageDataSlice.actions;

export default dashboardPackageDataSlice.reducer;

export const getDashboardPackageData =
  (interval: DashboardDataInterval) =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(getDashboardPackageDataRequest());
      const response = await api.getDashboardPackageData(interval);
      dispatch(getDashboardPackageDataSuccess(response));
    } catch (err) {
      dispatch(getDashboardPackageDataFailed(err));
    }
  };
