import { FC, useEffect } from 'react';
import { formatISO } from 'date-fns';

import PageWrapper from 'js/components/PageWrapper';
import FormV2 from 'js/components/FormV2';

import DatepickerRange from 'js/components/Form/components/DatepickerRange';
import SelectV2 from 'js/components/Form/components/SelectV2';
import ReportTypeRadios from './components/ReportTypeRadios';

import { tu } from 'js/utils/translate';
import { initialDate, radioButtons, setFreightProductOptions, setShopOptions } from './utils';
import { downloadReport } from './store';
import { getServiceSuppliersForLabels } from 'js/scenes/Orders/scenes/Labels/store/serviceSuppliersForLabelsReducer';
import { serviceSupplierOptions } from 'js/components/Select/utils';
import { RootState } from 'js/store/reducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';

const Reports: FC = () => {
  const dispatch = useAppDispatch();

  const shops = useAppSelector((state: RootState) => state?.freight?.shops?.data);
  const shopsLoaded = useAppSelector((state: RootState) => state?.freight?.shops?.isLoaded);

  const serviceSuppliersForLabels = useAppSelector(
    (state: RootState) => state.orders?.serviceSuppliersForLabels.data
  );
  const serviceSuppliersForLabelsLoaded = useAppSelector(
    (state: RootState) => state.orders?.serviceSuppliersForLabels.isLoaded
  );

  useEffect(() => {
    if (!serviceSuppliersForLabelsLoaded) dispatch(getServiceSuppliersForLabels());
  }, []);

  const freightProducts = useAppSelector((state: RootState) => state?.freightProducts?.data);
  const freightProductsLoaded = useAppSelector(
    (state: RootState) => state?.freightProducts?.isLoaded
  );

  const activeOwnerParty = useAppSelector(
    (state: RootState) => state?.user?.data?.activeOwnerParty
  );
  const roles = useAppSelector((state: RootState) => state?.user?.data?.roles);
  const reportsLoading = useAppSelector((state: RootState) => state?.home.reports?.loading);
  const error = useAppSelector((state: RootState) => state?.home.reports?.error);

  const shopOptions = setShopOptions(shops);
  const freightProductOptions = setFreightProductOptions(freightProducts);

  const handleSubmit = ({ shopId, serviceSupplierId, freightProductId, dates, report }) => {
    let url = 'reports/v1/export/';

    url += `${report.toLowerCase()}?fromDate=${encodeURIComponent(
      formatISO(dates.fromDate)
    )}&toDate=${encodeURIComponent(formatISO(dates.toDate))}`;

    if (shopId > 0) url += `&shopId=${shopId}`;

    if (freightProductId > 0) url += `&freightProductId=${freightProductId}`;
    if (serviceSupplierId) url += `&serviceSupplierId=${serviceSupplierId}`;

    dispatch(downloadReport(url));
  };
  return (
    <PageWrapper className='reports'>
      <FormV2
        onSubmit={handleSubmit}
        initialValues={{
          dates: { fromDate: initialDate(), toDate: initialDate() },
          shopId: shopOptions[0].value,
          freightProductId: freightProductOptions[0].value,
          report: radioButtons(activeOwnerParty, roles)[0]?.value
        }}
        type='getReport'
        loading={reportsLoading}
        error={error}
        actions
      >
        <div className='input-container'>
          <SelectV2
            className='input-container__select'
            label={tu('general.shop')}
            name='shopId'
            options={shopOptions}
            isLoading={!shopsLoaded}
            selectAll
          />
          <SelectV2
            className='input-container__select'
            label={tu('general.serviceSupplier')}
            name='serviceSupplierId'
            options={serviceSupplierOptions(serviceSuppliersForLabels)}
            isLoading={!serviceSuppliersForLabelsLoaded}
            optional
            selectFirst
          />
          <SelectV2
            className='input-container__select'
            label={tu('general.freightProduct')}
            name='freightProductId'
            options={freightProductOptions}
            isLoading={!freightProductsLoaded}
            selectAll
          />
        </div>
        <DatepickerRange name='dates' interval={31} maxDate={new Date()} showTimeInput={true} />
        <ReportTypeRadios name='report' radios={radioButtons(activeOwnerParty, roles)} />
      </FormV2>
    </PageWrapper>
  );
};

export default Reports;
