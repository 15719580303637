import { useMemo } from 'react';
import { RootState } from 'js/store/reducer';

import FreightProductInvoiceSetup from './components/FreightProductInvoiceSetup';
import Spinner from 'js/components/Spinner';
import TableV2 from 'js/components/TableV2';
import { useAppSelector } from 'js/utils/hooks';

import t from 'js/utils/translate';

import { findFreightProductById } from 'js/utils/find';

import './index.sass';
import { useNavigate } from 'react-router-dom';

const InvoiceSetupTable = () => {
  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const navigate = useNavigate();

  const freightProductsLoaded = useAppSelector(
    (state: RootState) => state.freightProducts?.isLoaded
  );
  const freightProducts = useAppSelector((state: RootState) => state.freightProducts?.data);
  const freightProductInvoiceSetupsLoaded = useAppSelector(
    (state: RootState) => state.economy?.invoiceSetup?.freightProductInvoiceSetup?.isLoaded
  );
  const freightProductInvoiceSetups = useAppSelector(
    (state: RootState) => state.economy?.invoiceSetup?.freightProductInvoiceSetup?.data
  );

  const goToEditPage = (freightProductId) => {
    navigate(`/economy/invoiceSetup/freightProducts/${freightProductId}/edit`);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('economy.invoiceSetup.table.header.name'),
        accessor: ({ freightProductId }) =>
          findFreightProductById(freightProducts, freightProductId)?.name,
        inHeader: true
      },
      {
        Header: t('economy.invoiceSetup.table.header.description'),
        accessor: (freightProductInvoiceSetup) => (
          <FreightProductInvoiceSetup freightProductInvoiceSetup={freightProductInvoiceSetup} />
        )
      }
    ],
    []
  );

  if (!freightProductInvoiceSetupsLoaded || !freightProductsLoaded) return <Spinner />;

  return (
    <TableV2
      className={isDesktop ? 'invoice-setup-desktop-table' : 'invoice-setup-mobile-table'}
      columns={columns}
      data={freightProductInvoiceSetups}
      rowIcon='pencil-alt'
      goToLabel={t('economy.invoiceSetup.edit')}
      onClickRow={goToEditPage}
      mobileTableVariant='withLargeEditButton'
      idAccessor='freightProductId'
    />
  );
};

export default InvoiceSetupTable;
