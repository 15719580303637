import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import LinkV2 from 'js/components/LinkV2';

import { getServiceSuppliers } from 'js/scenes/Freight/scenes/ServiceSuppliers/store/serviceSuppliersReducer';
import { removeServiceSupplierUser } from './store/removeServiceSupplierUserReducer';
import t from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useParams } from 'react-router-dom';

interface Props {
  userId?: number;
  setEditUserActive;
}

const RemoveUser: FC<Props> = ({ userId, setEditUserActive }) => {
  const dispatch = useAppDispatch();
  const { serviceSupplierId } = useParams();

  const id = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.removeServiceSupplierUser?.id
  );
  const isRemoving = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.removeServiceSupplierUser?.isRemoving
  );
  const isRemoved = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.removeServiceSupplierUser?.isRemoved
  );
  const error = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.removeServiceSupplierUser?.error
  );

  const onRemoveClick = () => {
    if (serviceSupplierId && userId) {
      dispatch(removeServiceSupplierUser(+serviceSupplierId, userId)).then(
        (response: boolean | undefined) => {
          if (response) {
            setEditUserActive(false);
            dispatch(getServiceSuppliers());
          }
        }
      );
    }
  };

  const isCurrentUser = id === userId;

  return (
    <LinkV2
      onClick={onRemoveClick}
      text={
        isRemoved && isCurrentUser
          ? `${t('general.removed')}!`
          : t('freight.shop.edit.user.removeFromShop')
      }
      loading={isRemoving && isCurrentUser}
      error={!!(error && isCurrentUser)}
    />
  );
};

export default RemoveUser;
