import { FC } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import SelectV2 from 'js/components/Form/components/SelectV2';

import { findFreightProductOptions } from './utils';
import t, { tu } from 'js/utils/translate';

import { RootState } from 'js/store/reducer';
import { ApiFlowShopSimple, FreightProduct } from 'src/interfaces/interfaces.generated';
import { useAppSelector } from 'js/utils/hooks';

interface Props {
  shops: ApiFlowShopSimple[];
}

const SelectFreightProducts: FC<Props> = ({ shops }) => {
  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const { getValue } = useFormContext();
  const shopIds: number[] = getValue('shopIds');
  const selectedShops = shops.filter(({ shopId }) => shopId && shopIds?.includes(shopId));

  const freightProducts: FreightProduct[] = useAppSelector(
    (state: RootState) => state.freightProducts?.data
  );

  const freightProductOptions = findFreightProductOptions(selectedShops, freightProducts);
  const disabled = !freightProductOptions.length;

  return (
    <SelectV2
      name='freightProductIds'
      options={freightProductOptions}
      isLoading={!disabled && !freightProductOptions}
      label={tu('general.freightProducts')}
      info={isDesktop && t('orders.labels.select.shop.first')}
      disabled={disabled}
      placeholder={disabled ? '' : t('select.freightProducts')}
      isMulti
      selectAll
      selectFirst
    />
  );
};

export default SelectFreightProducts;
