import { FC } from 'react';
import classnames from 'classnames';

import FormattedDate from './components/FormattedDate';
import FormattedTime from './components/FormattedTime';

import './index.sass';

interface DateTimeProps {
  datetime: Date;
  row?: boolean;
}

const DateTime: FC<DateTimeProps> = ({ datetime, row = false }) => (
  <div className={classnames('date-time', { 'date-time--row': row })}>
    <FormattedDate datetime={datetime} />
    <FormattedTime datetime={datetime} />
  </div>
);

export default DateTime;
