import { FC, useState } from 'react';
import { useValue } from '@distribution-innovation/form-hooks';

import LinkV2 from 'js/components/LinkV2';
import PriceForAllZones from './components/PriceForAllZones';
import FreightPrice from './components/FreightPrice';

import { PriceType } from '../../../../interfaces';

import { buildFreightPrice } from '../../../../utils';
import t from 'js/utils/translate';

import './index.sass';

interface Props {
  columns: string[];
  priceType: PriceType;
  priceTypeIndex: number;
  weightClassIndex: number;
  weightClassPricesForAllZones;
  weightId;
}

const Zones: FC<Props> = ({
  columns,
  priceType,
  priceTypeIndex,
  weightClassIndex,
  weightClassPricesForAllZones,
  weightId
}) => {
  const [freightPrices, setFreightPrices] = useValue('freightPrices');

  const freightPrice = weightClassPricesForAllZones[priceTypeIndex][weightClassIndex];

  const [viewZonePrices, setViewZonePrices] = useState<boolean>(freightPrice === undefined);

  const filteredFreightPrices = freightPrices.filter(
    (freightPrice) => !(weightId === freightPrice.weightId && priceType === freightPrice.priceType)
  );

  const handleViewZonePrices = () => {
    if (viewZonePrices) {
      setViewZonePrices(false);
      setFreightPrices(filteredFreightPrices);
    } else {
      setViewZonePrices(true);
      const zonePrices = columns.map((zoneId) =>
        buildFreightPrice(freightPrice, undefined, priceType, weightId, +zoneId)
      );
      setFreightPrices([...filteredFreightPrices, ...zonePrices]);
    }
  };

  return (
    <div className='zones'>
      <PriceForAllZones
        priceType={priceType}
        priceTypeIndex={priceTypeIndex}
        weightClassIndex={weightClassIndex}
        disabled={viewZonePrices}
      />
      {viewZonePrices &&
        columns.map((zone, zoneIndex) => (
          <FreightPrice
            key={zone}
            priceType={priceType}
            zoneId={zoneIndex + 1}
            zone={zone}
            weightId={weightId}
          />
        ))}
      <LinkV2
        className='view-zones-link'
        onClick={handleViewZonePrices}
        text={t(`economy.edit.costprice.edit${viewZonePrices ? '.all' : ''}.zones`)}
      />
    </div>
  );
};

export default Zones;
