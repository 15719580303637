import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { ApiUser } from 'src/interfaces/interfaces.generated';

import * as rest from '../../index.rest';

export interface ServiceSupplierUserReducerState {
  error: unknown;
  data: ApiUser;
  isCreating: boolean;
  isCreated: boolean;
  isConnecting: boolean;
  isConnected: boolean;
}

const initialState: ServiceSupplierUserReducerState = {
  error: null,
  data: {},
  isCreating: false,
  isCreated: false,
  isConnecting: false,
  isConnected: false
};

const serviceSupplierUserSlice = createSlice({
  name: 'serviceSupplierUser',
  initialState,
  reducers: {
    createServiceSupplierUserRequest(state) {
      state.error = null;
      state.isCreated = false;
      state.isCreating = true;
      state.data = {};
    },
    createServiceSupplierUserSuccess(state, action) {
      state.isCreated = true;
      state.isCreating = false;
      state.data = action.payload;
    },
    createServiceSupplierUserFailed(state, action) {
      state.error = action.payload;
      state.isCreated = false;
      state.isCreating = false;
    },
    connectServiceSupplierUserRequest(state) {
      state.error = null;
      state.isConnected = false;
      state.isConnecting = true;
    },
    connectServiceSupplierUserSuccess(state) {
      state.isConnected = true;
      state.isConnecting = false;
    },
    connectServiceSupplierUserFailed(state, action) {
      state.error = action.payload;
      state.isConnected = false;
      state.isConnecting = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  createServiceSupplierUserRequest,
  createServiceSupplierUserSuccess,
  createServiceSupplierUserFailed,
  connectServiceSupplierUserRequest,
  connectServiceSupplierUserSuccess,
  connectServiceSupplierUserFailed
} = serviceSupplierUserSlice.actions;

export default serviceSupplierUserSlice.reducer;

export const createServiceSupplierUser =
  (serviceSupplierId: number, user: ApiUser) =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(createServiceSupplierUserRequest());
      const response: ApiUser = await rest.createServiceSupplierUser(serviceSupplierId, user);
      dispatch(createServiceSupplierUserSuccess(response));
    } catch (error) {
      dispatch(createServiceSupplierUserFailed(error));
    }
  };

export const connectServiceSupplierUser =
  (serviceSupplierId: number, user: ApiUser) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(connectServiceSupplierUserRequest());
      await rest.giveUserServiceSupplierAccess(serviceSupplierId, user);
      dispatch(connectServiceSupplierUserSuccess());
      return true;
    } catch (error) {
      dispatch(connectServiceSupplierUserFailed(error));
    }
  };
