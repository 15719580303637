import Ajax from 'js/utils/ajax/ajax';

export const getZipGroups = () => Ajax.get('/freightproducts/v1/zipgroups');

export const getZoneIds = () => Ajax.get('/freightproducts/v1/zoneids');

export const getInvoiceSystems = () => Ajax.get('/freightproducts/v1/invoicesystems');

export const getDimensionGroups = () => Ajax.get('/freightproducts/v1/dimensiongroups');

export const getWeightGroups = () => Ajax.get('/freightproducts/v1/weightgroups');

export const getWeightBases = () => Ajax.get('/freightproducts/v1/weightbases');

export const getInvoiceSetups = () => Ajax.get('/freightproducts/v2/invoicesetups');

export const editInvoiceSetup = (freightProductId, invoiceSetup) =>
  Ajax.patch(`/freightproducts/v2/${freightProductId}/invoicesetup`, invoiceSetup);
