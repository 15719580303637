import { createSlice } from '@reduxjs/toolkit';
import { ApiWebservice } from 'src/interfaces/interfaces.generated';
import * as api from '../../api';

export interface WebservicesReducerState {
  error: unknown;
  isLoaded: boolean;
  data: ApiWebservice[];
}

const initialState: WebservicesReducerState = {
  error: null,
  isLoaded: false,
  data: []
};

const webservicesSlice = createSlice({
  name: 'webservices',
  initialState,
  reducers: {
    getWebservicesRequest(state) {
      state.error = null;
      state.isLoaded = false;
    },
    getWebservicesSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getWebservicesFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  }
});

export const { getWebservicesRequest, getWebservicesSuccess, getWebservicesFailed } =
  webservicesSlice.actions;

export default webservicesSlice.reducer;

export const getWebservices =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getWebservicesRequest());
      const response: ApiWebservice[] = await api.getWebservices();
      dispatch(getWebservicesSuccess(response));
      return true;
    } catch (error) {
      dispatch(getWebservicesFailed(error));
    }
  };
