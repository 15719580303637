import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import SelectV2 from 'js/components/Form/components/SelectV2';

import { getCustomerSystems } from '../../../../../../store/customerSystemsReducer';
import t, { tu } from 'js/utils/translate';
import { customerSystemOptions } from 'js/components/Select/utils';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const SelectCustomerSystem: FC = () => {
  const dispatch = useAppDispatch();

  const customerSystems = useAppSelector(
    (state: RootState) => state?.freight?.shop?.customerSystems?.data
  );
  const customerSystemsLoaded = useAppSelector(
    (state: RootState) => state?.freight?.shop?.customerSystems?.isLoaded
  );

  useEffect(() => {
    if (!customerSystemsLoaded) dispatch(getCustomerSystems());
  }, [customerSystemsLoaded]);

  return (
    <SelectV2
      name='editedCustomerSystem'
      isLoading={!customerSystemsLoaded}
      placeholder={customerSystemsLoaded ? t('select.customerSystem') : ''}
      options={customerSystemOptions(customerSystems)}
      label={tu('shop.accordion.customerSystem.chosen.customersystem')}
    />
  );
};

export default SelectCustomerSystem;
