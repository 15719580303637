import { FC } from 'react';

import FormV2 from 'js/components/FormV2';
import Input from 'js/components/Form/components/Input';
import SelectV2 from 'js/components/Form/components/SelectV2';

import t, { tu } from 'js/utils/translate';
import { RootState } from 'js/store/reducer';
import { ApiConcern } from 'src/interfaces/interfaces.generated';
import { shopOptions } from 'js/components/Select/utils';
import { shopsNotInExistingConcern } from '../../utils';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  concernId?: number;
  initialValues?: ApiConcern;
  onSubmit: any; // () => void; // TODO fiks denne
  type: 'save' | 'create';
  loading: boolean;
  error: unknown;
  onCancel: () => void;
}

const ConcernForm: FC<Props> = ({
  concernId,
  initialValues,
  onSubmit,
  type,
  loading,
  error,
  onCancel
}) => {
  const shops = useAppSelector((state: RootState) => state?.freight?.shops?.data);
  const shopsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shops?.isLoaded
  );
  const concerns: ApiConcern[] = useAppSelector(
    (state: RootState) => state?.freight?.concerns?.getConcerns?.data
  );

  const shopsNotInConcerns = concernId
    ? shopsNotInExistingConcern(concerns, shops, concernId)
    : shops;

  return (
    <FormV2
      className='concern-form'
      initialValues={initialValues}
      onSubmit={onSubmit}
      type={type}
      loading={loading}
      error={error}
      onCancel={onCancel}
    >
      <Input className='concern-name' name='name' label={tu('new.concern.name')} />
      <SelectV2
        name='shopIds'
        options={shopOptions(shopsNotInConcerns)}
        label={tu('new.concern.shops')}
        isLoading={!shopsLoaded}
        placeholder={shopsLoaded && t('select.shops')}
        isMulti
        selectAll
      />
    </FormV2>
  );
};

export default ConcernForm;
