import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface WeightBasesReducerState {
  error: unknown;
  data: any[];
  isLoaded: boolean;
}

const initialState: WeightBasesReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const weightBasesSlice = createSlice({
  name: 'WeightBases',
  initialState,
  reducers: {
    getWeightBasesRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getWeightBasesSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getWeightBasesFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  }
});

export const { getWeightBasesRequest, getWeightBasesSuccess, getWeightBasesFailed } =
  weightBasesSlice.actions;

export default weightBasesSlice.reducer;

export const getWeightBases = () => async (dispatch) => {
  try {
    dispatch(getWeightBasesRequest());
    const weightBases = await rest.getWeightBases();
    dispatch(getWeightBasesSuccess(weightBases));
  } catch (err) {
    dispatch(getWeightBasesFailed(err));
  }
};
