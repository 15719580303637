import { FC, useEffect, useState } from 'react';
import { RootState } from 'js/store/reducer';

import PageWrapper from 'js/components/PageWrapper';
import SelectShop from './components/SelectShop';
import ProcessedInvoicesForShopTable from './components/ProcessedInvoicesForShopTable';

import { getShops } from 'js/scenes/Freight/store/shopsReducer';

import { ApiFlowShopSimple, ApiInvoiceBatch } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const InvoiceBatchesForShop: FC = () => {
  const dispatch = useAppDispatch();

  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const shopsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shops?.isLoaded
  );
  const shops: ApiFlowShopSimple[] = useAppSelector(
    (state: RootState) => state?.freight?.shops?.data
  );

  const processedInvoiceBatchesForShop: ApiInvoiceBatch[] = useAppSelector(
    (state: RootState) => state?.economy?.processedInvoiceBatchesForShop?.data
  );

  useEffect(() => {
    if (!shopsLoaded) dispatch(getShops());
  }, []);

  const [shopSelected, setShopSelected] = useState(false);

  return (
    <PageWrapper withoutPadding={!isDesktop}>
      <SelectShop shopsLoaded={shopsLoaded} shops={shops} setShopSelected={setShopSelected} />
      {shopSelected && (
        <ProcessedInvoicesForShopTable
          invoiceBatches={processedInvoiceBatchesForShop}
          shops={shops}
        />
      )}
    </PageWrapper>
  );
};

export default InvoiceBatchesForShop;
