import { FC } from 'react';
import classNames from 'classnames';

import Tag from 'js/components/Tag';

import t from 'js/utils/translate';
import { LabelStatus } from '../../../../../../interfaces';

interface Props {
  className?: string;
  labelStatus: LabelStatus;
}

const Status: FC<Props> = ({ className, labelStatus }) => {
  const tagVariant = (
    labelStatus: 'failed' | 'done' | 'proccessing'
  ): 'error' | 'success' | 'info' => {
    if (labelStatus === 'failed') return 'error';
    if (labelStatus === 'done') return 'success';
    else return 'info';
  };

  return (
    <Tag
      className={classNames('excerpt-status', className)}
      text={t(`orders.labelhistory.status.${labelStatus}`)}
      variant={tagVariant(labelStatus)}
    />
  );
};

export default Status;
