import { FC } from 'react';
import t from 'js/utils/translate';

import ButtonV2 from 'js/components/ButtonV2';
import { useNavigate } from 'react-router-dom';

interface Props {
  text: string;
  path: string;
  mobileIcon?: any;
  variant?: 'inverse' | 'primary' | 'danger';
  isDesktop?: boolean;
}

const PageHeaderButton: FC<Props> = ({ text, path, variant, mobileIcon, isDesktop }) => {
  const navigate = useNavigate();

  const SVGIcon = mobileIcon;

  return (
    <div>
      {isDesktop || !mobileIcon ? (
        <ButtonV2
          className={text}
          onClick={() => {
            navigate(path);
          }}
          text={t(text)}
          variant={variant}
        />
      ) : (
        <span
          onClick={() => {
            navigate(path);
          }}
        >
          {' '}
          <SVGIcon />{' '}
        </span>
      )}
    </div>
  );
};

export default PageHeaderButton;
