import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import LabeledTags from '../../../../../LabeledTags';

import { tu } from 'js/utils/translate';
import { findShopById } from 'js/utils/find';
import { ApiFlowShopSimple, LabelGenerationHistory } from 'src/interfaces/interfaces.generated';
import { useAppSelector } from 'js/utils/hooks';

interface Props {
  labelExcerpt: LabelGenerationHistory;
}

const Shops: FC<Props> = ({ labelExcerpt }) => {
  const shops: ApiFlowShopSimple[] = useAppSelector(
    (state: RootState) => state?.freight?.shops?.data
  );

  return (
    <LabeledTags
      labelExcerpt={labelExcerpt}
      accessor='shopIds'
      data={shops}
      findFunction={findShopById}
      label={tu('general.shops')}
      className='label-history-info__shop-tags'
    />
  );
};

export default Shops;
