import { FC } from 'react';

import t from 'js/utils/translate';

import './index.sass';

interface Props {
  currentStep: number;
  maxSteps: number;
}

const StepIndicator: FC<Props> = ({ currentStep, maxSteps }) => (
  <div className='new-order__step-indicator'>{t('orders.new.step.of', currentStep, maxSteps)}</div>
);

export default StepIndicator;
