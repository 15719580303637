import t from 'js/utils/translate';

export const findOrderByParams = (
  mainCarNumbers,
  preTransportCarNumbers,
  subTransportCarNames,
  mainCarPlannedDepartures,
  ownerParty
) => {
  const showAllOptionsForTheseOwnerParties = [8]; // Morgenlevering
  const showAllOptions = showAllOptionsForTheseOwnerParties.includes(ownerParty);

  const params = [
    { label: t('general.shop'), value: 'shopId' },
    { label: t('general.freightProduct'), value: 'freightProductId' },
    { label: t('labels.sorting.importedDate'), value: 'importedDate' },
    { label: t('labels.sorting.deliveryDate'), value: 'desiredDeliveryDate' }
  ];
  if (
    mainCarNumbers ||
    preTransportCarNumbers ||
    subTransportCarNames ||
    mainCarPlannedDepartures ||
    showAllOptions
  ) {
    if (mainCarNumbers?.length > 0 || showAllOptions) {
      params.push({
        value: 'mainCarNumber',
        label: t('labels.sorting.mainCarNumber')
      });
      params.push({
        value: 'mainCarSort',
        label: t('labels.sorting.mainCarSort')
      });
      params.push({
        value: 'articleName',
        label: t('labels.sorting.articleName')
      });
    }

    if (preTransportCarNumbers?.length > 0 || showAllOptions) {
      params.push({
        value: 'preTransportCarNumber',
        label: t('labels.sorting.preTransportCarNumber')
      });
    }

    if (subTransportCarNames?.length > 0 || showAllOptions) {
      params.push({
        value: 'subTransportCarName',
        label: t('labels.sorting.subTransportCarName')
      });
    }

    if (mainCarPlannedDepartures?.length > 0 || showAllOptions) {
      params.push({
        value: 'mainCarPlannedDeparture',
        label: t('labels.sorting.mainCarPlannedDeparture')
      });
      params.push({
        value: 'transportDepartureSort',
        label: t('labels.sorting.transportDepartureSort')
      });
    }
  }
  return params;
};
