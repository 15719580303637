import { Dispatch, FC, SetStateAction } from 'react';
import { RootState } from 'js/store/reducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { isEmpty } from 'lodash';

import LinkV2 from 'js/components/LinkV2';

import t from 'js/utils/translate';
import { upperCaseFirst } from 'js/utils/string';
import { downloadInvoiceBatch } from './store/downloadInvoiceBatchReducer';

import './index.sass';

interface Props {
  invoiceBatchId?: number;
  viewSummary: boolean;
  toggleViewSummary: Dispatch<SetStateAction<boolean>>;
  context: 'handled' | 'unhandled' | 'shop';
}

const Links: FC<Props> = ({ invoiceBatchId, viewSummary, toggleViewSummary, context }) => {
  const dispatch = useAppDispatch();

  const id = useAppSelector((state: RootState) => state?.economy?.downloadInvoiceBatch?.id);
  const isDownloading = useAppSelector(
    (state: RootState) => state?.economy?.downloadInvoiceBatch?.isDownloading
  );
  const error = useAppSelector((state: RootState) => state?.economy?.downloadInvoiceBatch?.error);

  const handleDownloadInvoiceBatch = () => {
    if (invoiceBatchId) dispatch(downloadInvoiceBatch(invoiceBatchId));
  };

  return (
    <div className='invoice-summary-links'>
      <LinkV2
        text={upperCaseFirst(
          t(
            'economy.generateInvoice.invoiceBatches.summary.view',
            viewSummary ? t('general.hide') : t('general.view')
          )
        )}
        onClick={() => {
          toggleViewSummary(!viewSummary);
        }}
      />
      {context !== 'shop' && (
        <LinkV2
          text={upperCaseFirst(t('economy.generateInvoice.invoiceBatches.summary.download'))}
          onClick={handleDownloadInvoiceBatch}
          loading={isDownloading && invoiceBatchId === id}
          error={!isEmpty(error) && invoiceBatchId === id}
        />
      )}
    </div>
  );
};

export default Links;
