import { RootState } from 'js/store/reducer';

import InvoiceSystem from './components/InvoiceSystem';
import ZoneInterval from './components/ZoneInterval';
import Group from './components/Group';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

const FreightProductInvoiceSetup = ({ freightProductInvoiceSetup }) => {
  const zipGroups = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.zipGroups?.data
  );
  const dimensionGroups = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.dimensionGroups?.data
  );
  const weightGroups = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.weightGroups?.data
  );

  return (
    <div className='freight-product-invoice-setup'>
      <div className='freight-product-invoice-setup__section'>
        <InvoiceSystem invoiceSystemCode={freightProductInvoiceSetup.invoiceSystemCode} />
        <Group group='zipGroup' groups={zipGroups} id={freightProductInvoiceSetup.zipGroupId} />
      </div>
      <div className='freight-product-invoice-setup__section'>
        <Group
          group='dimensionGroup'
          groups={dimensionGroups}
          id={freightProductInvoiceSetup.dimensionGroupId}
        />
        <Group
          group='weightGroup'
          groups={weightGroups}
          id={freightProductInvoiceSetup.weightGroupId}
        />
      </div>
      <div className='freight-product-invoice-setup__section'>
        <ZoneInterval
          toZoneId={freightProductInvoiceSetup.toZoneId}
          fromZoneId={freightProductInvoiceSetup.fromZoneId}
        />
      </div>
    </div>
  );
};

export default FreightProductInvoiceSetup;
