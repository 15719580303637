import { FC, useEffect, useState } from 'react';

import ServiceSupplierTable from './components/ServiceSupplierTable';
import SearchFilter from 'js/components/SearchFilter';
import Spinner from 'js/components/Spinner';

import t from 'js/utils/translate';
import { filterServiceSuppliers } from './utils';

import { TabName } from '../../interfaces';
import { RootState } from 'js/store/reducer';
import { ApiFlowShopSimple, ApiServiceSupplier } from 'src/interfaces/interfaces.generated';
import { getServiceSuppliers } from './store/serviceSuppliersReducer';
import { getShops } from 'js/scenes/Freight/store/shopsReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface Props {
  activeTabName: TabName;
  shops: ApiFlowShopSimple[];
}

const ServiceSuppliers: FC<Props> = ({ activeTabName, shops }) => {
  const dispatch = useAppDispatch();

  const serviceSuppliers: ApiServiceSupplier[] = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSuppliers?.data
  );
  const serviceSuppliersLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSuppliers?.isLoaded
  );
  const shopsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shops?.isLoaded
  );

  const [search, setSearch] = useState('');
  const [filteredServiceSuppliers, setFilteredServiceSuppliers] =
    useState<ApiServiceSupplier[]>(serviceSuppliers);

  useEffect(() => {
    if (!serviceSuppliersLoaded) dispatch(getServiceSuppliers());
    if (!shopsLoaded) dispatch(getShops());
  }, [serviceSuppliersLoaded]);

  useEffect(() => {
    setFilteredServiceSuppliers(
      filterServiceSuppliers(serviceSuppliers, shops, search.toLowerCase())
    );
  }, [search, serviceSuppliers, shops]);

  if (!serviceSuppliersLoaded) return <Spinner />;

  return (
    <div>
      <SearchFilter
        info={t(`freight.search.${activeTabName}.info`)}
        inputValue={search}
        setSearch={setSearch}
      />
      <ServiceSupplierTable filteredServiceSuppliers={filteredServiceSuppliers} shops={shops} />
    </div>
  );
};

export default ServiceSuppliers;
