import classNames from 'classnames';
import PropTypes from 'prop-types';

const PageWrapper = ({ children, className, withoutPadding }) => (
  <div className={classNames({ 'page-padding': !withoutPadding }, className)}>{children}</div>
);

export default PageWrapper;

PageWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  withoutPadding: PropTypes.bool
};
