import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { ApiTransportSolution } from 'src/interfaces/interfaces.generated';

import * as api from '../../utils/rest';
import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';

export interface TransportSolutionsReducerState {
  error: unknown;
  data: ApiTransportSolution[];
  isLoaded: boolean;
  isSaving: boolean;
}

const initialState: TransportSolutionsReducerState = {
  error: null,
  data: [],
  isLoaded: false,
  isSaving: false
};

const transportSolutionsSlice = createSlice({
  name: 'transportSolutions',
  initialState,
  reducers: {
    getTransportSolutionsRequest(state) {
      state.isLoaded = false;
      state.error = null;
      state.data = [];
    },
    getTransportSolutionsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getTransportSolutionsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    },
    editTransportSolutionRequest(state) {
      state.isSaving = true;
      state.error = null;
    },
    editTransportSolutionSuccess(state, action) {
      state.isSaving = false;
      state.data = state.data.map((transportSolution: ApiTransportSolution) =>
        transportSolution.transportSolutionId === action.payload.transportSolutionId
          ? { ...transportSolution, ...action.payload }
          : { ...transportSolution }
      );
      state.error = null;
    },
    editTransportSolutionFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    },
    createTransportSolutionRequest(state) {
      state.isSaving = true;
      state.error = null;
    },
    createTransportSolutionSuccess(state, action) {
      state.isSaving = false;
      state.data = [...state.data, action.payload];
    },
    createTransportSolutionFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    },
    clearTransportSolutionErrors(state) {
      state.error = null;
    }
  }
});

export const {
  getTransportSolutionsRequest,
  getTransportSolutionsSuccess,
  getTransportSolutionsFailed,
  editTransportSolutionRequest,
  editTransportSolutionSuccess,
  editTransportSolutionFailed,
  createTransportSolutionRequest,
  createTransportSolutionSuccess,
  createTransportSolutionFailed,
  clearTransportSolutionErrors
} = transportSolutionsSlice.actions;

export default transportSolutionsSlice.reducer;

export const getTransportSolutions =
  () =>
  async (dispatch: Dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getTransportSolutionsRequest());
      const response: ApiTransportSolution[] = await api.getTransportSolutions();
      dispatch(getTransportSolutionsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getTransportSolutionsFailed(error));
    }
  };

export const editTransportSolution =
  (transportSolution: ApiTransportSolution) =>
  async (dispatch: Dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(editTransportSolutionRequest());
      const response: ApiTransportSolution = await api.editTransportSolution(transportSolution);
      response.transportSolutionId = transportSolution.transportSolutionId;
      dispatch(editTransportSolutionSuccess(response));
      return true;
    } catch (error) {
      dispatch(editTransportSolutionFailed(error));
    }
  };

export const newTransportSolution =
  (transportSolution: ApiTransportSolution) =>
  async (dispatch: Dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(createTransportSolutionRequest());
      const response: ApiTransportSolution = await api.newTransportSolution(transportSolution);
      dispatch(createTransportSolutionSuccess(response));
      return true;
    } catch (error) {
      dispatch(createTransportSolutionFailed(error));
    }
  };

export const useTransportSolutions = (): TransportSolutionsReducerState =>
  useAppSelector((state: RootState) => state.transportSolutions);
