import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from 'js/components/Label';

import './index.sass';
import * as amplitude from '@amplitude/analytics-browser';
import { Amplitude } from 'js/components/amplitude';

const LabeledInput = ({
  className,
  label,
  subLabel,
  info,
  optional,
  value,
  autoFocus,
  onChange,
  type,
  defaultValue,
  tooltip = false,
  onKeyPress = undefined,
  disabled = false
}) => {
  const inputFocused = () => {
    amplitude.track(Amplitude.INPUT_FOCUSED, {
      [Amplitude.INPUT_FOCUSED__CLASS]: className
    });
  };

  return (
    <div className={classNames('labeled-input', className)}>
      {label && <Label label={label} info={info} tooltip={tooltip} optional={optional} />}
      <input
        value={value}
        autoFocus={autoFocus}
        onChange={onChange}
        defaultValue={defaultValue}
        type={type}
        onKeyPress={onKeyPress}
        disabled={disabled}
        onFocus={inputFocused}
      />
      {subLabel && <Label subLabel>{subLabel}</Label>}
    </div>
  );
};

export default LabeledInput;

LabeledInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  info: PropTypes.string,
  optional: PropTypes.bool,
  value: PropTypes.any,
  autoFocus: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  type: PropTypes.string
};
