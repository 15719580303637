import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import PageWrapper from 'js/components/PageWrapper';
import SplitPage from 'js/components/SplitPage';
import Spinner from 'js/components/Spinner';
import Input from 'js/components/Form/components/Input';
import FormV2 from 'js/components/FormV2';

import { OrderPartyType } from './interfaces';
import { ApiOrder, ApiOrderSimple, OrderParty } from 'src/interfaces/interfaces.generated';
import { tu } from 'js/utils/translate';
import { wrongOrder } from 'js/scenes/Home/scenes/OrderDetails/utils';
import { getOrder } from '../../store/orderReducer';
import { editOrderParty } from './store/editOrderPartyReducer';

import './index.sass';
import AddressHelper from 'js/components/Form/components/AddressHelper';
import { getFullOrder } from './store/getFullOrderReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';

const EditOrderParty: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trackingReference } = useParams();
  const { pathname } = useLocation();
  const orderPartyType: OrderPartyType = pathname.includes('sender') ? 'consignor' : 'consignee';

  const redirectTo = `/orderSearch/${trackingReference}`;

  const order: ApiOrderSimple = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.order?.data
  );
  const orderLoaded: boolean = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.order?.isLoaded
  );
  const fullOrder: ApiOrder = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.getFullOrder?.data
  );
  const fullOrderLoaded: boolean = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.getFullOrder?.isLoaded
  );

  const isSaving: boolean = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.editOrderParty?.isSaving
  );
  const error = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.editOrderParty?.error
  );

  useEffect(() => {
    if (wrongOrder(trackingReference!, order, orderLoaded) || !orderLoaded) {
      dispatch(getOrder(trackingReference!));
    }
    dispatch(getFullOrder(trackingReference!));
  }, [trackingReference, orderLoaded]);

  const handleSubmit = (values: OrderParty) => {
    dispatch(editOrderParty(trackingReference!, orderPartyType, values)).then(
      (response: boolean | undefined) => {
        if (response) {
          navigate(redirectTo);
        }
      }
    );
  };

  if (!orderLoaded || !fullOrderLoaded) return <Spinner />;

  const initialValues = {
    name: fullOrder[orderPartyType]?.name,
    address: fullOrder[orderPartyType]?.address,
    co: fullOrder[orderPartyType]?.co,
    email: fullOrder[orderPartyType]?.email,
    phone: fullOrder?.[orderPartyType]?.phone,
    shopId: fullOrder.shopId
  };

  const disabled = !(order?.enableCancellation ?? order?.canEdit);

  return (
    <PageWrapper>
      <SplitPage>
        <FormV2
          onSubmit={handleSubmit}
          initialValues={initialValues}
          className='edit-recipient'
          loading={isSaving}
          error={error}
          redirectTo={redirectTo}
          disabled={disabled}
        >
          <Input name='name' label={tu('general.name')} disabled={disabled} />
          <AddressHelper name='address' color='none' />
          <Input name='co' label={tu('orders.new.order.co')} disabled={disabled} optional />
          <Input name='phone' label={tu('general.phone')} disabled={disabled} optional />
          <Input name='email' label={tu('general.email')} disabled={disabled} optional />
        </FormV2>
      </SplitPage>
    </PageWrapper>
  );
};

export default EditOrderParty;
