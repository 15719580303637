import classNames from 'classnames';

import SelectV2 from 'js/components/Form/components/SelectV2';
import Toggle from 'js/components/Form/components/Toggle';
import Input from 'js/components/Form/components/Input';

import t, { tu } from 'js/utils/translate';
import { findFreightProductById } from 'js/utils/find';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';

const ORDER_CONFIRMATION_PARTY_OPTIONS = [
  {
    value: 'NONE',
    label: t('shop.freightProduct.orderConfirmationParty.NONE')
  },
  {
    value: 'CUSTOMER',
    label: t('shop.freightProduct.orderConfirmationParty.CUSTOMER')
  },
  { value: 'SHOP', label: t('shop.freightProduct.orderConfirmationParty.SHOP') }
];

const FreightProductRow = ({ index, shopFreightProduct, isDesktop }) => {
  const freightProducts = useAppSelector((state) => state.freightProducts?.data);

  const freightProduct = findFreightProductById(
    freightProducts,
    shopFreightProduct?.freightProductId
  );

  return (
    <div className='shop-freight-product__row'>
      <div className={classNames({ 'mobile-header': !isDesktop })}>{freightProduct?.name}</div>
      <SelectV2
        name={`shopFreightProducts[${index}][orderConfirmationParty]`}
        options={ORDER_CONFIRMATION_PARTY_OPTIONS}
        label={!isDesktop && tu('shop.freightProduct.labelRecipient')}
      />
      <Input
        name={`shopFreightProducts[${index}]maxValue`}
        disabled={!freightProduct?.maxValueFromShop}
        label={!isDesktop && tu('shop.freightProduct.maxValue')}
        optional
      />
      <Input
        name={`shopFreightProducts[${index}]defaultWeightGrams`}
        disabled={!freightProduct?.defaultWeightFromShop}
        label={!isDesktop && tu('shop.freightProduct.weight')}
        optional
      />
      <Toggle
        name={`shopFreightProducts[${index}]accessible`}
        label={!isDesktop && t('shop.freightProduct.active')}
      />
    </div>
  );
};

export default FreightProductRow;
