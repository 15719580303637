import classNames from 'classnames';

import './index.sass';

const HamburgerMenuIcon = ({ open }) => {
  const isClosed = () => !(open === null) && !open;

  const classes = classNames(
    'hamburger-menu-icon',
    { 'hamburger-menu-icon--open': open },
    { 'hamburger-menu-icon--closed': isClosed(open) }
  );

  return (
    <div className={classes}>
      <i />
      <i />
      <i />
    </div>
  );
};

export default HamburgerMenuIcon;
