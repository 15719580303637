import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { getOrders } from 'js/scenes/Home/scenes/OrderSearch/store/orderSearchReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate } from 'react-router-dom';
import LabeledInput from 'js/components/LabeledInput';
import t, { tu } from 'js/utils/translate';
import SearchButton from 'js/scenes/Home/scenes/OrderSearch/components/Search/components/SearchButton';

interface Props {
  withTitle?: boolean;
}

const Search: FC<Props> = ({ withTitle = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const shipmentId = location?.state?.shipmentId;

  const searchInput = useAppSelector((state) => state?.home?.orderSearch?.searchInput);

  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);

  const [identifier, setIdentifier] = useState(searchInput?.identifier);
  const [customer, setCustomer] = useState(searchInput?.customer);
  const [reference, setReference] = useState(searchInput?.reference);

  useEffect(() => {
    if (searchInput) {
      setIdentifier(searchInput.identifier);
      setCustomer(searchInput.customer);
      setReference(searchInput.reference);
    }
  }, [searchInput]);

  useEffect(() => {
    if (shipmentId) {
      setIdentifier(shipmentId);
      dispatch(getOrders(shipmentId));
    }
  }, [shipmentId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getOrders(identifier, customer, reference)).then((response) => {
      if (response) navigate('/orderSearch');
    });
  };

  return (
    <div className='order-search-container'>
      <div className='title'>{withTitle && <h1>{t('order.search.title')}</h1>}</div>
      <span className='subtitle'>{t('order.search.subtitle')}</span>
      <div className='search'>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <LabeledInput
            className='order-search-input order-search-input__identifier'
            label={tu('order.search.parcel')}
            info={t('order.search.identifier.info')}
            tooltip={!!isDesktop}
            value={identifier}
            onChange={(e) => {
              setIdentifier(e.target.value);
            }}
          />
          <LabeledInput
            className='order-search-input order-search-input__customer'
            label={tu('order.search.customer')}
            info={t('order.search.customer.info')}
            tooltip={!!isDesktop}
            value={customer}
            onChange={(e) => {
              setCustomer(e.target.value);
            }}
          />
          <LabeledInput
            className='order-search-input order-search-input__reference'
            label={tu('order.search.reference')}
            value={reference}
            onChange={(e) => {
              setReference(e.target.value);
            }}
          />
          <SearchButton />
        </form>
      </div>
    </div>
  );
};

export default Search;
