import { FC, useEffect } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';
import { RootState } from 'js/store/reducer';

import Toggle from 'js/components/Form/components/Toggle';
import Input from 'js/components/Form/components/Input';
import Label from 'js/components/Label';
import SelectV2 from 'js/components/Form/components/SelectV2';

import { getRetailerProducts } from '../../../../store/retailerProductsReducer';
import { tu } from 'js/utils/translate';
import { ApiRetailerProduct } from 'src/interfaces/interfaces.generated';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const B2B: FC = () => {
  const dispatch = useAppDispatch();
  const retailerProductsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shop?.retailerProducts?.isLoaded
  );
  const retailerProducts: ApiRetailerProduct[] = useAppSelector(
    (state: RootState) => state?.freight?.shop?.retailerProducts?.data
  );

  const formContext = useFormContext();
  const selectedRetailerProduct: ApiRetailerProduct = formContext.getValue('retailerProductId');

  useEffect(() => {
    if (!retailerProductsLoaded) dispatch(getRetailerProducts());
  }, []);

  return (
    <div className='b2b'>
      <SelectV2
        name='retailerProductId'
        label={tu('shop.accordion.b2b.retailerProduct')}
        options={retailerProducts?.map((retailerProduct) => ({
          value: retailerProduct.id,
          label: retailerProduct.longName
        }))}
        isLoading={!retailerProductsLoaded}
        optional
      />
      <div className='nowrap'>
        <div className='toggle-wrapper'>
          <Label label={tu('shop.accordion.b2b.preTransportRequired')} />
          <Toggle name='preTransportRequired' disabled={!selectedRetailerProduct} />
        </div>
        <Input
          className='deadline'
          name='transportCutOff'
          label={tu('shop.accordion.b2b.deadline')}
          optional
          disabled={!selectedRetailerProduct}
        />
      </div>
    </div>
  );
};

export default B2B;
