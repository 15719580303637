import { FC, useEffect, useState } from 'react';

import SearchFilter from 'js/components/SearchFilter';
import ShopsTable from './components/ShopsTable';

import t from 'js/utils/translate';
import { filterShops } from './utils';
import { TabName } from 'js/scenes/Freight/interfaces';
import { ApiFlowShopSimple } from 'src/interfaces/interfaces.generated';

interface ShopsProps {
  activeTabName: TabName;
  shops: ApiFlowShopSimple[];
  shopsLoaded: boolean;
}

const Shops: FC<ShopsProps> = ({ activeTabName, shops, shopsLoaded }) => {
  const [search, setSearch] = useState('');
  const [filteredShops, setFilteredShops] = useState(shops);

  useEffect(() => {
    setFilteredShops(filterShops(shops, search.toLowerCase()));
  }, [search, shopsLoaded]);

  return (
    <div>
      <SearchFilter
        info={t(`freight.search.${activeTabName}.info`)}
        inputValue={search}
        setSearch={setSearch}
      />
      <ShopsTable shops={filteredShops} shopsLoaded={shopsLoaded} />
    </div>
  );
};

export default Shops;
