import { FC, useState, useRef, useEffect } from 'react';

import AHInput from './components/AddressHelperInput';

import addressHelper from './utils/addressHelper';

import './index.sass';

interface Props {
  address;
  setAddress;
  countryCode: string;
  disabled?: boolean;
  handleBlur?: () => void;
}

const AddressHelper: FC<Props> = ({ address, setAddress, countryCode, disabled, handleBlur }) => {
  const node = useRef(null);

  const [, setDiah] = useState<any>();

  useEffect(() => {
    if (node?.current) {
      setDiah(addressHelper(address, node.current, {}, undefined, setAddress, countryCode));
    }
  }, [node]);

  return (
    <div className={`address-helper${disabled ? ' disabled' : ''}`} ref={node}>
      <div className='address-helper__address'>
        <input type='hidden' className='selectionCache' />
        <AHInput onBlur={handleBlur} name='street' />
        <AHInput onBlur={handleBlur} name='streetNumber' />
        <AHInput onBlur={handleBlur} name='floor' />
        <AHInput onBlur={handleBlur} name='household' />
        <AHInput name='zip' disabled />
        <AHInput name='city' disabled />
      </div>
    </div>
  );
};

export default AddressHelper;
