import { FC, Dispatch, SetStateAction, useEffect } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import Text from './components/Text';
import EditApiUserForm from './components/EditApiUserForm';
import EditUserForm from './components/EditUserForm';

import { resetValues } from './utils';

import './index.sass';

interface Props {
  isApiContext?: boolean;
  name: string;
  user;
  index: number;
  editUserActive: boolean | null;
  setEditUserActive: Dispatch<SetStateAction<boolean | null>>;
}

const EditUser: FC<Props> = ({
  isApiContext,
  name,
  user,
  index,
  editUserActive,
  setEditUserActive
}) => {
  const context = useFormContext();

  useEffect(() => {
    if (editUserActive) context.setValue(`${name}[${index}]active`, true);
    if (!editUserActive && editUserActive !== null) {
      resetValues(name, context, user, index, isApiContext);
      context.setValue(`${name}[${index}]active`, false);
    }
  }, [editUserActive]);

  return (
    <div className='edit-user'>
      {isApiContext ? (
        <EditApiUserForm
          name={name}
          user={user}
          index={index}
          editUserActive={editUserActive}
          setEditUserActive={setEditUserActive}
        />
      ) : (
        <EditUserForm
          name={name}
          user={user}
          index={index}
          editUserActive={editUserActive}
          setEditUserActive={setEditUserActive}
        />
      )}
      <Text className='hidden' name={`${name}[${index}]`} user={user} accessor='userId' />
      <Text className='hidden' name={`${name}[${index}]`} user={user} accessor='active' />
    </div>
  );
};

export default EditUser;
