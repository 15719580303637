import { FC } from 'react';
import classnames from 'classnames';
import MobileMatrixBody from './components/MobileMatrixBody';

import { MatrixProps } from '../../interfaces';
import './index.sass';

const MobileMatrix: FC<MatrixProps> = ({
  freightProductPrices,
  currentPrices = true,
  selectedFilter,
  showGrayMatrix
}) => (
  <div className='mobile-price-matrix'>
    {freightProductPrices?.rows?.map((weightClass, rowIndex) => (
      <div
        key={weightClass}
        className={classnames('mobile-price-matrix__row', {
          'mobile-price-matrix__row--gray': showGrayMatrix
        })}
      >
        <div className='mobile-price-matrix__row--header'>{weightClass}</div>
        <MobileMatrixBody
          rowIndex={rowIndex}
          freightProductPrices={freightProductPrices}
          currentPrices={currentPrices}
          selectedFilter={selectedFilter}
        />
      </div>
    ))}
  </div>
);

export default MobileMatrix;
