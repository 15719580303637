import { FC, useEffect } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import Input from 'js/components/Form/components/Input';
import Toggle from 'js/components/Form/components/Toggle';
import ColoredSection from 'js/components/ColoredSection';

import t, { tu } from 'js/utils/translate';

import './index.sass';

interface Props {
  party: 'consignee' | 'consignor';
}

const CommunicationChannel: FC<Props> = ({ party }) => {
  const smsToggleName = `${party}.smsToggle`;
  const emailToggleName = `${party}.emailToggle`;

  const { getValue } = useFormContext();
  const smsToggle = getValue(smsToggleName);
  const emailToggle = getValue(emailToggleName);

  const { setValue } = useFormContext();

  useEffect(() => {
    if (!smsToggle) setValue(`${party}.phone`, undefined);
  }, [smsToggle]);

  useEffect(() => {
    if (!emailToggle) setValue(`${party}.email`, undefined);
  }, [emailToggle]);

  return (
    <ColoredSection
      className='communication-channel'
      label={tu('orders.new.order.communication.channel')}
    >
      <div>
        <Toggle name={smsToggleName} label={t('orders.new.order.communicationChannel.sms')} />
        <Input
          label={tu('orders.new.order.communicationChannel.phone')}
          name={`${party}.phone`}
          disabled={!smsToggle}
          optional
          hideOptional
        />
      </div>
      <div>
        <Toggle name={emailToggleName} label={t('orders.new.order.communicationChannel.email')} />
        <Input
          label={tu('orders.new.order.communicationChannel.email')}
          name={`${party}.email`}
          disabled={!emailToggle}
          optional
          hideOptional
        />
      </div>
    </ColoredSection>
  );
};

export default CommunicationChannel;
