import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import DashboardDataFilterButtons from './components/DashboardDataFilterButtons';
import PackageDashboard from './components/PackageDashboard';
import Statistikk from 'images/icons/Statistikk.svg';

import t from 'js/utils/translate';

import { DashboardDataInterval } from './interfaces';

import './index.sass';

const MainPanel: FC = () => {
  const [selectedInterval, setSelectedInterval] = useState<DashboardDataInterval>('DAILY');

  useNavigate();

  return (
    <div className='main-panel'>
      <div className='main-panel__icon-wrapper'>
        <Statistikk />
      </div>
      <PackageDashboard selectedInterval={selectedInterval} />
      <DashboardDataFilterButtons
        selectedInterval={selectedInterval}
        setSelectedInterval={setSelectedInterval}
      />
      <div className='main-panel__reports-btn'>
        <Link className='btn btn--inverse btn--nowrap' to='/reports'>
          {t('show.reports')}
        </Link>
      </div>
    </div>
  );
};

export default MainPanel;
