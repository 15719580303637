import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../api';
import { ApiCommunicationPreference } from '../../interfaces';
import { locationChanged } from 'js/store/actions';

export interface ResendPickupCodeReducerState {
  error: unknown;
  data: ApiCommunicationPreference;
  sentPickupCode: boolean;
}

const initialState: ResendPickupCodeReducerState = {
  error: null,
  data: {},
  sentPickupCode: false
};

const resendPickupCodeSlice = createSlice({
  name: 'ResendPickupCode',
  initialState,
  reducers: {
    resendPickupCodeRequest(state) {
      state.error = null;
      state.sentPickupCode = false;
    },
    resendPickupCodeSuccess(state, action) {
      state.sentPickupCode = true;
      state.data = action.payload;
    },
    resendPickupCodeFailed(state, action) {
      state.error = action.payload;
      state.sentPickupCode = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { resendPickupCodeRequest, resendPickupCodeSuccess, resendPickupCodeFailed } =
  resendPickupCodeSlice.actions;

export default resendPickupCodeSlice.reducer;

export const resendPickupCode =
  (trackingReference: string, sendBy: ApiCommunicationPreference) =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(resendPickupCodeRequest());
      const response: ApiCommunicationPreference = await api.resendPickupCode(
        trackingReference,
        sendBy
      );
      dispatch(resendPickupCodeSuccess(response));
    } catch (err) {
      dispatch(resendPickupCodeFailed(err));
    }
  };
