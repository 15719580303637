import { useEffect, useState, FC } from 'react';
import { isWithinInterval, isPast } from 'date-fns';
import { SystemInfo } from 'js/scenes/Settings/components/SystemMessages/interfaces';

import MessageForUser from 'js/components/MessageForUser';

export const StatusBar: FC<SystemInfo> = ({
  systemInfoId,
  fromDate,
  importantFromDate,
  toDate,
  message
}) => {
  const [important, setImportant] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const now = new Date();
    if (importantFromDate) {
      setImportant(isPast(new Date(importantFromDate)));
    }
    setShow(
      isWithinInterval(now, {
        start: new Date(fromDate),
        end: new Date(toDate)
      })
    );
  }, [systemInfoId]);

  return show ? (
    <MessageForUser variant={important ? 'warning' : 'info'} message={message} />
  ) : (
    <></>
  );
};
