import './index.sass';

import EnvironmentRibbon from '../components/EnvironmentRibbon';
import CompanyLogo from '../components/CompanyLogo';
import SettingsButton from '../components/SettingsButton';
import LogoutButton from '../components/LogoutButton';
import NavigationBar from './components/NavigationBar';
import JobsButton from 'js/components/AppHeader/components/JobsButton';
import LaunchNotesButton from '../components/LaunchNotesButton';
import { useAppSelector } from 'js/utils/hooks';

const DesktopHeader = () => {
  const userRoles = useAppSelector((state) => state?.user?.data?.roles);

  return (
    <div className='desktop-header'>
      <EnvironmentRibbon />
      <div className='desktop-header__content-container'>
        <CompanyLogo />
        <NavigationBar />
      </div>
      <div className='desktop-header__content-container'>
        <LaunchNotesButton />
        {userRoles.di && <JobsButton />}
        <SettingsButton />
        <LogoutButton />
      </div>
    </div>
  );
};

export default DesktopHeader;
