import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useTimeout } from 'js/utils/hooks';
import { RootState } from 'js/store/reducer';
import { isEmpty } from 'lodash';

import ServiceSupplierForm from '../../components/ServiceSupplierForm';
import PageWrapper from 'js/components/PageWrapper';
import { ApiServiceSupplier } from 'src/interfaces/interfaces.generated';
import { getServiceSuppliers } from 'js/scenes/Freight/scenes/ServiceSuppliers/store/serviceSuppliersReducer';
import { createServiceSupplier } from './store/newServiceSupplierReducer';
import { editUser } from '../../../../store/userReducer';
import { ExistingUser, NewUser } from '../../../../interfaces';
import { buildUser } from 'js/scenes/Freight/components/Users/utils';

import {
  connectServiceSupplierUser,
  createServiceSupplierUser
} from '../../store/serviceSupplierUserReducer';
import { useNavigate } from 'react-router-dom';

const ServiceSupplier: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const createServiceSupplierError = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.newServiceSupplier?.error
  );
  const editServiceSupplierError = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.editServiceSupplier?.error
  );
  const serviceSupplierUserError = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSupplierUser?.error
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const error = createServiceSupplierError || editServiceSupplierError || serviceSupplierUserError;

  const redirect = () => {
    navigate('/freight', {
      state: {
        activeTab: {
          name: 'serviceSuppliers',
          label: 'general.serviceSuppliers'
        }
      }
    });
  };

  useEffect(() => {
    if (!isEmpty(error)) setIsSubmitting(false);
  }, [error]);

  useTimeout(
    () => {
      setIsSubmitting(false);
      redirect();
      dispatch(getServiceSuppliers());
    },
    isSubmitting ? 4000 : null
  );

  const handleSubmit = ({
    serviceSupplier,
    newUsers,
    connectedUsers
  }: {
    serviceSupplier: ApiServiceSupplier;
    newUsers: NewUser[];
    connectedUsers: ExistingUser[];
  }) => {
    if (serviceSupplier) {
      setIsSubmitting(true);
      dispatch(createServiceSupplier(serviceSupplier)).then((serviceSupplierId?: number) => {
        if (serviceSupplierId) {
          if (newUsers) {
            newUsers.forEach((user) => {
              dispatch(createServiceSupplierUser(serviceSupplierId, buildUser(user)));
            });
          }
          if (connectedUsers) {
            connectedUsers.forEach((user) => {
              dispatch(
                connectServiceSupplierUser(serviceSupplierId, {
                  userId: user.userId
                })
              ).then((response?: boolean) => {
                if (response && user.active) dispatch(editUser(user.userId, buildUser(user)));
              });
            });
          }
        }
      });
    }
  };

  return (
    <PageWrapper withoutPadding={!isDesktop}>
      <ServiceSupplierForm
        handleSubmit={handleSubmit}
        type='create'
        onCancel={() => {
          redirect();
        }}
        isSubmitting={isSubmitting}
        error={error}
      />
    </PageWrapper>
  );
};

export default ServiceSupplier;
