import { FC } from 'react';

import ButtonV2 from 'js/components/ButtonV2';

import { findPageHeaderActions } from 'js/routes';
import t from 'js/utils/translate';

import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

const NewPriceAdjustmentButton: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userRoles = useAppSelector((state: RootState) => state?.user?.data?.roles);
  const pageHeaderButtons = findPageHeaderActions(location.pathname, userRoles);

  if (!pageHeaderButtons) return <></>;

  return (
    <div className='new-price-adjustment-button'>
      {pageHeaderButtons?.map(
        (action) =>
          action.onlyDesktop && (
            <ButtonV2
              className={`new-price-button-${action.text}`}
              key={action.text}
              text={t(action.text)}
              variant={action.variant}
              onClick={() => {
                navigate(action.path);
              }}
            />
          )
      )}
    </div>
  );
};

export default NewPriceAdjustmentButton;
