import { useLocation } from 'react-router';

import Button from 'js/components/Button';

import { findPageHeaderActions } from 'js/routes';
import t from 'js/utils/translate';

import './index.sass';
import { useNavigate } from 'react-router-dom';

const PageHeaderButtons = ({ userRoles }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const createButtons = findPageHeaderActions(location.pathname, userRoles);

  return (
    <div className='freight__create-buttons page-padding'>
      {createButtons?.map(
        (action) =>
          action.onlyDesktop && (
            <Button
              key={action.text}
              name={t(action.text)}
              inverse={action.variant === 'inverse'}
              handleClick={() => {
                navigate(action.path);
              }}
            />
          )
      )}
    </div>
  );
};

export default PageHeaderButtons;
