import { useCallback, useMemo } from 'react';

import DateTime from 'js/components/DateTime';
import Status from './components/Status';
import NameAndSize from './components/NameAndSize';
import TableV2 from 'js/components/TableV2';
import t from 'js/utils/translate';
import { findStatusInfo } from './components/Status/utils';

import './index.sass';
import { safariSafeDateString } from 'js/utils/dateTime';
import ShowMoreButton from 'js/components/ShowMoreButton';
import Icon from 'js/components/Icon';
import { useAppSelector } from 'js/utils/hooks';

const MobileTable = ({ bulkOrders }) => (
  <div className='imported-orders-desktop-table'>
    {bulkOrders?.map((bulkOrder) => (
      <div key={bulkOrder.uploadedDate} className='row'>
        <span>{bulkOrder.name ?? '-'}</span>
        <span>{bulkOrder.shopName ?? '-'}</span>
        {bulkOrder?.parsedDate ? (
          <DateTime datetime={new Date(safariSafeDateString(bulkOrder.parsedDate))} />
        ) : (
          t('bulkOrder.table.not.processed')
        )}
        <Status statusId={bulkOrder.statusId} importFileId={bulkOrder.importFileId} />
      </div>
    ))}
  </div>
);

const ImportedOrdersTable = ({ bulkOrders, pagination }) => {
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);

  const translateKeyPrefix = 'bulkOrder.table.header';

  const columns = useMemo(
    () => [
      {
        Header: t(`${translateKeyPrefix}.filename`),
        accessor: (bulkOrder) => <NameAndSize name={bulkOrder.name} size={bulkOrder.size} />
      },
      {
        Header: t(`${translateKeyPrefix}.shop`),
        accessor: 'shopName'
      },
      {
        Header: t(`${translateKeyPrefix}.transportSolution`),
        accessor: 'transportSolutionName'
      },
      {
        id: 'uploadedDate',
        Header: t(`${translateKeyPrefix}.uploaded`),
        accessor: (bulkOrder) => new Date(safariSafeDateString(bulkOrder.uploadedDate)),
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => <DateTime datetime={value} />
      },
      {
        Header: t(`${translateKeyPrefix}.parsed`),
        accessor: (bulkOrder) =>
          bulkOrder.parsedDate ? new Date(safariSafeDateString(bulkOrder.parsedDate)) : null,
        sortType: 'datetime',
        Cell: ({ cell: { value } }) =>
          value ? <DateTime datetime={value} /> : t('bulkOrder.table.not.processed')
      },
      {
        Header: t(`${translateKeyPrefix}.status`),
        accessor: (bulkOrder) => (
          <Status statusId={bulkOrder.statusId} importFileId={bulkOrder.importFileId} />
        )
      },
      {
        Header: t(`${translateKeyPrefix}.labels`),
        expander: true,
        id: 'expander',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Cell: ({ row }) =>
          findStatusInfo(row.original.statusId).name === 'labels.generated' && (
            <ShowMoreButton
              show={row.isExpanded}
              toggleShow={() => row.toggleRowExpanded()}
              hideText={t('bulkOrder.table.labelLinks.hide.label')}
              showText={t('bulkOrder.table.labelLinks.show.label')}
            />
          )
      }
    ],
    []
  );

  const expandedLinks = useCallback(
    ({ row }) =>
      row.isExpanded ? (
        <td colSpan={columns.length} className='expanded'>
          <div className='label-links-row-body'>
            <Icon icon='level-up-alt fa-rotate-90' size='sm' />
            <div>
              {row.original.labelAwsLinks?.length > 0 ? (
                row.original.labelAwsLinks.map((labelLink, index) => (
                  <a
                    className='link'
                    target='_blank'
                    rel='noreferrer'
                    href={labelLink}
                    key={labelLink}
                  >
                    {t(
                      'bulkOrder.table.labelLinks.get.labels',
                      index + 1,
                      row.original.labelAwsLinks.length
                    )}
                  </a>
                ))
              ) : (
                <div>{t('bulkOrder.table.labelLinks.no.longer.available')}</div>
              )}
            </div>
          </div>
        </td>
      ) : null,
    [columns]
  );

  return (
    <TableV2
      className={isDesktop ? 'imported-orders-desktop-table' : ''}
      pagination={pagination}
      columns={columns}
      data={bulkOrders}
      sortBy={[{ id: 'uploadedDate', desc: true }]}
      customMobileTable={<MobileTable bulkOrders={bulkOrders} />}
      subRows={[expandedLinks]}
    />
  );
};

export default ImportedOrdersTable;
