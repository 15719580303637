import { FC } from 'react';
import Label from 'js/components/Label';

interface LabeledItemProps {
  label: string;
  item: any;
}

const LabeledItem: FC<LabeledItemProps> = ({ label, item }) => (
  <div className='labeled-item'>
    <Label label={label} />
    <span>{item ?? '-'}</span>
  </div>
);

export default LabeledItem;
