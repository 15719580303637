export const notFullAccess = (freightProducts) =>
  freightProducts.every((freightProduct) => !freightProduct.fullAccess);

export const findDimensionGroupById = (dimensionGroups, dimensionGroupId) =>
  dimensionGroups.find((group) => group.id === dimensionGroupId);

export const findWeightGroupById = (weightGroups, weightGroupId) =>
  weightGroups.find((group) => group.id === weightGroupId);

export const weightGroupOption = (weightGroup) => ({
  value: weightGroup.id,
  label: weightGroup.name
});

export const weightGroupOptions = (weightGroups) =>
  weightGroups?.map((group) => weightGroupOption(group));

export const findZipGroupById = (zipGroups, zipGroupId) =>
  zipGroups.find((group) => group.id === zipGroupId);

export const zipGroupOption = (zipGroup) => ({
  value: zipGroup.id,
  label: zipGroup.name
});

export const zipGroupOptions = (zipGroups) => zipGroups?.map((group) => zipGroupOption(group));

export const dimensionGroupOption = (dimensionGroup) => ({
  value: dimensionGroup.id,
  label: dimensionGroup.name
});

export const dimensionGroupOptions = (dimensionGroups) =>
  dimensionGroups?.map((group) => dimensionGroupOption(group));
