import { FC } from 'react';
import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import UserForm from 'js/scenes/UserAdministration/UserActions/CreateAndEdit/UserForm';

const CreateOrEditUser: FC = () => {
  return (
    <SplitPage>
      <PageWrapper className='user-page'>
        <UserForm />
      </PageWrapper>
    </SplitPage>
  );
};

export default CreateOrEditUser;
