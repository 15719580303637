import { combineReducers } from 'redux';

import invoiceSetupReducer, {
  _InvoiceSetupReducerState as InvoiceSetupReducerState
} from './scenes/InvoiceSetup/index.reducer';
import priceAdjustmentsReducer, {
  PriceAdjustmentsReducerState
} from './scenes/PriceAdjustments/store/priceAdjustmentsReducer';
import deletePriceAdjustmentReducer, {
  DeletePriceAdjustmentReducerState
  // eslint-disable-next-line max-len
} from './scenes/PriceAdjustments/components/PriceAdjustmentForm/components/PriceAdjustmentInfo/store/deletePriceAdjustmentReducer';
import costPricesReducer, { CostPricesReducerState } from './scenes/CostPrices/store';
import downloadInvoiceBatchReducer, {
  DownloadInvoiceBatchReducerState
} from './components/InvoiceBatchesTable/components/Row/components/Links/store/downloadInvoiceBatchReducer';
import downloadInvoiceReducer, {
  DownloadInvoiceReducerState
  // eslint-disable-next-line max-len
} from 'js/scenes/Economy/components/InvoiceBatchesTable/components/Row/components/Expand/components/InvoiceLines/components/DownloadInvoice/store/downloadInvoiceReducer';
import invoiceBatchesReducer, {
  InvoiceBatchesReducerState
} from './scenes/InvoiceBatches/index.reducer';
import processedInvoiceBatchesForShopReducer, {
  ProcessedInvoiceBatchesForShopReducerState
} from './scenes/InvoiceBatchesForShop/store/processedInvoiceBatchesForShop';

const economyReducer = combineReducers({
  priceAdjustments: priceAdjustmentsReducer,
  deletePriceAdjustment: deletePriceAdjustmentReducer,
  invoiceSetup: invoiceSetupReducer,
  costPrices: costPricesReducer,
  downloadInvoice: downloadInvoiceReducer,
  downloadInvoiceBatch: downloadInvoiceBatchReducer,
  invoiceBatches: invoiceBatchesReducer,
  processedInvoiceBatchesForShop: processedInvoiceBatchesForShopReducer
});

export default economyReducer;

export interface EconomyReducerState {
  priceAdjustments: PriceAdjustmentsReducerState;
  deletePriceAdjustment: DeletePriceAdjustmentReducerState;
  invoiceSetup: InvoiceSetupReducerState;
  costPrices: CostPricesReducerState;
  downloadInvoice: DownloadInvoiceReducerState;
  downloadInvoiceBatch: DownloadInvoiceBatchReducerState;
  invoiceBatches: InvoiceBatchesReducerState;
  processedInvoiceBatchesForShop: ProcessedInvoiceBatchesForShopReducerState;
}
