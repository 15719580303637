import classNames from 'classnames';
import { useTable, usePagination } from 'react-table';

import Button from 'js/components/Button';
import Icon from 'js/components/Icon';
import Pagination from 'js/components/Pagination';

import t from 'js/utils/translate';
import { mapFromColumnsToRows } from './utils';
import { Row } from './interfaces';
import { Column } from '../../interfaces';

import './index.sass';

interface Props<DataType extends object = object> {
  variant: 'withSmallEditIcon' | 'withLargeEditButton';
  className?: string;
  columns: Array<Column<DataType>>;
  data: DataType[];
  rowIcon?: string;
  idAccessor?: string;
  // eslint-disable-next-line no-unused-vars
  onClickRow?: any;
  pagination?: boolean;
  pageSize?: number;
}

const MobileTable = <DataType extends object = object>({
  variant,
  className,
  columns,
  data,
  rowIcon,
  idAccessor,
  onClickRow,
  pagination,
  pageSize = 10
}: Props<DataType>) => {
  const {
    prepareRow,
    rows,
    state: { pageIndex },
    gotoPage
  } = useTable(
    {
      columns,
      data
    },
    usePagination
  );

  const rowsCustom: Row[] = mapFromColumnsToRows(
    columns,
    data,
    prepareRow,
    rows,
    rowIcon,
    idAccessor
  );

  const page = rowsCustom.filter((row, index) => {
    const min: number = pageIndex * pageSize;
    const max: number = (pageIndex + 1) * pageSize - 1;
    return index >= min && index <= max;
  });

  const rowsOrPage = pagination ? page : rowsCustom;

  if (variant === 'withSmallEditIcon') {
    return (
      <div className={classNames('mobile-table-with-small-edit-icon', className)}>
        {rowsOrPage?.map(({ id, row, headerContent, headerIcon, bodyContent }) => (
          <div key={id} className='row' onClick={(e) => onClickRow(row, e)}>
            <div className='row__header'>
              <div className='row__header--content'>{headerContent}</div>
              {headerIcon && (
                <div className='row__header--icon'>
                  <Icon icon={headerIcon} />
                </div>
              )}
            </div>
            <div className='row__body'>
              <div className='row__body--content'>{bodyContent}</div>
            </div>
          </div>
        ))}
        {pagination && (
          <Pagination
            activePage={pageIndex + 1}
            itemsCountPerPage={pageSize}
            onChange={gotoPage}
            totalItemsCount={data.length}
            usedByTable
          />
        )}
      </div>
    );
  }

  // if (variant === withLargeEditButton')
  return (
    <div className={classNames('mobile-table-with-edit-button', className)}>
      {rowsCustom.map(({ id, row, headerContent, headerIcon, bodyContent }) => (
        <div key={id} className='mobile-table-section'>
          {headerContent && <div className='mobile-table-section__header'>{headerContent}</div>}
          {bodyContent && <div className='mobile-table-section__main-content'>{bodyContent}</div>}
          <div className='mobile-table-section__edit-button'>
            <Button
              disabled={!onClickRow}
              name={t('general.edit')}
              handleClick={(e) => onClickRow(row, e)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobileTable;
