import { FC } from 'react';

import HandleInvoice from './components/HandleInvoice';

import { ApiInvoiceBatch } from 'src/interfaces/interfaces.generated';

import './index.sass';

interface Props {
  allowActions?: boolean;
  invoiceBatch: ApiInvoiceBatch;
  activePage?: number;
}

const Actions: FC<Props> = ({ allowActions, invoiceBatch, activePage }) => {
  const disabled = invoiceBatch.statusId !== 1;
  return (
    <div className='invoice-batch-actions'>
      {allowActions && (
        <div className='invoice-batch-actions__buttons'>
          <HandleInvoice
            invoiceBatchId={invoiceBatch.invoiceBatchId!}
            disabled={disabled}
            activePage={activePage}
            context='approve'
          />
          <HandleInvoice
            invoiceBatchId={invoiceBatch.invoiceBatchId!}
            disabled={disabled}
            activePage={activePage}
            context='disapprove'
          />
        </div>
      )}
    </div>
  );
};

export default Actions;
