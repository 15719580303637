import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { ApiUser } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';

export interface UserReducerState {
  error: unknown;
  isSaving: boolean;
  isSaved: boolean;
  hasAccess: boolean;
}

const initialState: UserReducerState = {
  error: null,
  isSaving: false,
  isSaved: false,
  hasAccess: false
};

const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    editUserRequest(state) {
      state.error = null;
      state.isSaving = true;
      state.isSaved = false;
    },
    editUserSuccess(state) {
      state.isSaving = false;
      state.isSaved = true;
    },
    editUserFailed(state, action) {
      state.error = action.payload;
      state.isSaving = false;
      state.isSaved = false;
    },
    giveShopAccessRequest(state) {
      state.error = null;
      state.hasAccess = false;
    },
    giveShopAccessSuccess(state) {
      state.hasAccess = true;
    },
    giveShopAccessFailed(state, action) {
      state.error = action.payload;
      state.hasAccess = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  editUserRequest,
  editUserSuccess,
  editUserFailed,
  giveShopAccessRequest,
  giveShopAccessSuccess,
  giveShopAccessFailed
} = userSlice.actions;

export default userSlice.reducer;

export const editUser =
  (userId: number, user: ApiUser) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(editUserRequest());
      await api.editUser(userId, user);
      dispatch(editUserSuccess());
      return true;
    } catch (error) {
      dispatch(editUserFailed(error));
    }
  };

export const giveShopAccess =
  (shopId: number, user: ApiUser) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(giveShopAccessRequest());
      await api.giveShopAccess(shopId, user);
      dispatch(giveShopAccessSuccess());
      return true;
    } catch (error) {
      dispatch(giveShopAccessFailed(error));
    }
  };
