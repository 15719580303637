import PartyBody from './components/PartyBody';
import ServicePointBody from './components/ServicePointBody';
import ShipmentBody from './components/ShipmentBody';
import ParcelBody from './components/ParcelBody';
import ArticlesBody from './components/ArticlesBody';
import DeliveryBody from './components/DeliveryBody';
import LoadCarrierBody from './components/LoadCarrierBody';
import Consignee from 'images/icons/Mottaker.svg';
import Consignor from 'images/icons/Avsender.svg';

import t from 'js/utils/translate';
// eslint-disable-next-line max-len
import { orderHasServicePointInfoAndHitFilter } from 'js/scenes/Home/scenes/OrderDetails/components/OrderInfo/components/OrderAccordion/utils';

export const orderAccordion = (
  order,
  canEditOrder,
  fromNewOrderPage,
  shipmentInfoRef,
  navigate
) => [
  {
    title: (
      <div className='order-accordion__header-with-icon'>
        <Consignee />
        <span>{t('general.consignee')}</span>
      </div>
    ),
    info: t('orderDetails.accordion.info.regarding', t('general.consignee')),
    expanded: true,
    canEdit: canEditOrder,
    onEditClick: () => {
      if (order?.trackingReference) {
        navigate(`/orderSearch/${order.trackingReference}/edit/recipient`);
      }
    },
    body: <PartyBody party={order?.consignee} />,
    className: 'order-accordion-consignee'
  },
  {
    title: (
      <div className='order-accordion__header-with-icon'>
        <Consignor />
        <span>{t('general.consignor')}</span>
      </div>
    ),
    info: t('orderDetails.accordion.info.regarding', t('general.consignor')),
    expanded: true,
    canEdit: canEditOrder,
    onEditClick: () => {
      if (order?.trackingReference) navigate(`/orderSearch/${order.trackingReference}/edit/sender`);
    },
    body: <PartyBody party={order?.consignor} />,
    className: 'order-accordion-consignor'
  },
  {
    title: t('general.servicePoint'),
    info: t('orderDetails.accordion.info.regarding', t('general.servicePoint')),
    canEdit: orderHasServicePointInfoAndHitFilter(order),
    onEditClick: () => {
      if (order?.trackingReference) {
        navigate(`/orderSearch/${order.trackingReference}/edit/servicePoint`);
      }
    },
    body: <ServicePointBody order={order} />,
    className: 'order-accordion-service-point'
  },
  {
    title: t('general.shipment'),
    info: t('orderDetails.accordion.info.regarding', t('general.shipment')),
    canEdit: canEditOrder,
    onEditClick: () => {
      if (order?.trackingReference)
        navigate(`/orderSearch/${order.trackingReference}/edit/shipment`);
    },
    expanded: fromNewOrderPage,
    body: <ShipmentBody order={order} shipmentInfoRef={shipmentInfoRef} />,
    className: 'order-accordion-shipment'
  },
  {
    title: t('general.package'),
    info: t('orderDetails.accordion.info.regarding', t('general.package')),
    body: <ParcelBody order={order} />,
    className: 'order-accordion-package'
  },
  {
    title: t('general.articles'),
    info: t('orderDetails.accordion.info.regarding', t('general.articles')),
    body: <ArticlesBody articles={order?.articles} />,
    className: 'order-accordion-articles'
  },
  {
    title: t('orderDetails.accordion.title.delivery'),
    info: t('orderDetails.accordion.info.delivery'),
    body: (
      <DeliveryBody
        trackingReference={order?.trackingReference}
        hasBudBuddyInfo={order?.budBuddyImgs}
        plannedProofOfDelivery={order?.plannedProofOfDelivery}
        actualProofOfDelivery={order?.actualProofOfDelivery}
      />
    ),
    className: 'order-accordion-additional-info'
  },
  {
    title: t('orderDetails.accordion.title.loadCarriers'),
    info: t('orderDetails.accordion.info.loadCarriers'),
    body: <LoadCarrierBody loadCarriers={order?.loadCarriers} />,
    className: 'order-accordion-load-carriers'
  }
];
