import { FC } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import ColoredSection from 'js/components/ColoredSection';
import Datepicker from 'js/components/Form/components/Datepicker';

import { tomorrow } from 'js/utils/dateTime';
import t, { tu } from 'js/utils/translate';

const AdditionalTransportSolutionField: FC = () => {
  const transportSolutionSelected = !!useFormContext().getValue('transportSolutionId');

  return (
    <ColoredSection
      label={tu('orders.new.order.additional.field.transportsolution')}
      defaultText={
        transportSolutionSelected ? '' : t('orders.new.order.select.transportsolution.first')
      }
      className='additional-transport-solution-field'
    >
      {transportSolutionSelected && (
        <Datepicker
          name='desiredDeliveryDate'
          label={tu('orders.newOrder.date')}
          sublabel={t('orders.newOrder.date.sublabel')}
          minDate={tomorrow()}
          optional
        />
      )}
    </ColoredSection>
  );
};

export default AdditionalTransportSolutionField;
