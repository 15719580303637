import { FC } from 'react';

import SelectV2 from 'js/components/Form/components/SelectV2';

import { transportSolutionOptionsWithId } from 'js/components/Select/utils';
import { tu } from 'js/utils/translate';
import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';

interface Props {
  name: string;
}

const SelectWantedConversion: FC<Props> = ({ name }) => {
  const transportSolutions = useAppSelector((state: RootState) => state.transportSolutions?.data);
  const transportSolutionsLoaded = useAppSelector(
    (state: RootState) => state.transportSolutions?.isLoaded
  );

  return (
    <SelectV2
      name={name}
      label={tu('edit.freightProduct.wantedConversion')}
      isLoading={!transportSolutionsLoaded}
      options={transportSolutionOptionsWithId(transportSolutions)}
      optional
    />
  );
};

export default SelectWantedConversion;
