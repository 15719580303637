import './index.sass';

import { Link } from 'react-router-dom';
import Icon from 'js/components/Icon';
import t from 'js/utils/translate';

const JobsButton = () => (
  <div className='jobs-button'>
    <Link to='/jobs'>
      <Icon icon='server' size='1x' />
      <div className='jobs-button__text'>{t('general.jobs')}</div>
    </Link>
  </div>
);

export default JobsButton;
