import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import LinkV2 from 'js/components/LinkV2';

import { removeShopUser } from './store/removeShopUser';
import { getShopUsers } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/shopUsersReducer';
import t from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useParams } from 'react-router-dom';

interface Props {
  userId?: number;
  setEditUserActive;
}

const RemoveUser: FC<Props> = ({ userId, setEditUserActive }) => {
  const dispatch = useAppDispatch();
  const { shopId } = useParams();

  const id = useAppSelector((state: RootState) => state?.freight?.shop?.removeShopUser?.id);
  const isRemoving = useAppSelector(
    (state: RootState) => state?.freight?.shop?.removeShopUser?.isRemoving
  );
  const isRemoved = useAppSelector(
    (state: RootState) => state?.freight?.shop?.removeShopUser?.isRemoved
  );
  const error = useAppSelector((state: RootState) => state?.freight?.shop?.removeShopUser?.error);

  const onRemoveClick = () => {
    if (shopId && userId) {
      dispatch(removeShopUser(+shopId, userId)).then((response: boolean | undefined) => {
        if (response) {
          setEditUserActive(false);
          dispatch(getShopUsers(+shopId));
        }
      });
    }
  };

  const isCurrentUser = id === userId;

  return (
    <LinkV2
      onClick={onRemoveClick}
      text={
        isRemoved && isCurrentUser
          ? `${t('general.removed')}!`
          : t('freight.shop.edit.user.removeFromShop')
      }
      loading={isRemoving && isCurrentUser}
      error={!!(error && isCurrentUser)}
    />
  );
};

export default RemoveUser;
