import { FC, useState } from 'react';
import { User, UserType } from 'js/scenes/UserAdministration/Users.types';
import { useAppSelector } from 'js/utils/hooks';
import { RootState } from 'js/store/reducer';
import { filterUsers } from 'js/scenes/UserAdministration/utils/utils';
import LabeledInput from 'js/components/LabeledInput';
import t from 'js/utils/translate';
import PageWrapper from 'js/components/PageWrapper';
import InfoBox from 'js/components/InfoBox';
import TableV2 from 'js/components/TableV2';
import { apiUsersColumns, flowUsersColumns } from 'js/scenes/UserAdministration/table/columns';
import { useNavigate } from 'react-router-dom';
import { userAdministrationPath } from 'js/scenes/UserAdministration/utils/constants';

const UsersView: FC<{ type: UserType; data: User[] }> = ({ type, data }) => {
  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const navigate = useNavigate();
  const rowIcon = 'pencil-alt';

  const [filteredUsers, setFilteredUsers] = useState<User[]>(data);
  const [search, setSearch] = useState('');

  const searchValueNames: string =
    type === 'flow'
      ? `${t('general.name')}, ${t('general.username')}, ${t('general.email')}, ${t(
          'general.shops'
        )}, ${t('general.roles')}`
      : `${t('general.username')}, ${t('general.email')}, ${t('general.shops')}, ${t(
          'general.apiAccess'
        )}`;

  const handleOnClickRow = (user: User) => {
    navigate(`${userAdministrationPath}/edit/${user?.userId}`, { state: { type, user } });
  };

  return (
    <>
      <LabeledInput
        className='search-filter'
        label={t('general.search')}
        info={searchValueNames}
        value={search}
        onChange={(e) => {
          setSearch(() => {
            setFilteredUsers(filterUsers(data, e.target.value));
            return e.target.value;
          });
        }}
      />
      <PageWrapper withoutPadding={!isDesktop}>
        {filteredUsers.length === 0 ? (
          <InfoBox text={t('userAdministration.none')} />
        ) : (
          <TableV2
            columns={type === 'flow' ? flowUsersColumns(t) : apiUsersColumns(t)}
            mobileTableVariant={'withSmallEditIcon'}
            data={filteredUsers}
            sortBy={[{ id: 'userId', desc: true }]}
            goToLabel={t('general.edit')}
            onClickRow={(row: { original: User }) => {
              handleOnClickRow(row.original);
            }}
            rowIcon={rowIcon}
            pagination
          />
        )}
      </PageWrapper>
    </>
  );
};

export default UsersView;
