import { useMemo } from 'react';
import {
  useRegister,
  useValue,
  withError,
  useFormContext
} from '@distribution-innovation/form-hooks';
import isEmpty from 'lodash/isEmpty';

import _Select from 'js/components/SelectV2';
import * as amplitude from '@amplitude/analytics-browser';

const Select = ({
  isMulti,
  name,
  selectAll,
  options,
  isLoading,
  disabled,
  label,
  subLabel,
  info,
  className,
  hideOptional,
  placeholder,
  optional = false,
  required = !optional,
  error,
  selectFirst,
  onChangeTrack,
  onSelectAllTrack
}) => {
  const context = useFormContext();

  const [value, setValue] = useValue(name);

  const handleSelectAll = () => {
    setValue(options.map((option) => option.value));
    if (onSelectAllTrack) {
      amplitude.track(
        onSelectAllTrack.eventInput,
        onSelectAllTrack?.eventProperties,
        onSelectAllTrack?.eventOptions
      );
    }
  };

  const handleBlur = () => context.getTouched(name) || context.setTouched(name);

  const validate = (newValue) => {
    if ((newValue || (typeof newValue === 'object' && !isEmpty(newValue))) && !required)
      return undefined;
    if ((!newValue || (typeof newValue === 'object' && isEmpty(newValue))) && required)
      return 'valueMissing';
  };

  useRegister({ name, value, validate });

  return useMemo(() => (
    // eslint-disable-next-line react/jsx-pascal-case
    <_Select
      name={name}
      label={label}
      info={info}
      disabled={disabled}
      optional={optional}
      selectAll={selectAll}
      isMulti={isMulti}
      options={options}
      handleSelectAll={handleSelectAll}
      subLabel={subLabel}
      isLoading={isLoading}
      className={className}
      hideOptional={hideOptional}
      setFormValue={setValue}
      formValue={value}
      error={error}
      placeholder={placeholder}
      onBlur={handleBlur}
      selectFirst={selectFirst}
      onChangeTrack={onChangeTrack}
    />
  ));
};
export default withError()(Select);
