import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface DownloadInvoiceReducerState {
  id: null | number;
  isDownloading: boolean;
  isDownloaded: boolean;
  error: unknown;
}

const initialState: DownloadInvoiceReducerState = {
  id: null,
  isDownloading: false,
  isDownloaded: false,
  error: null
};

const downloadInvoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    downloadInvoiceRequest(state, action) {
      state.id = action.payload;
      state.isDownloading = true;
      state.isDownloaded = false;
      state.error = null;
    },
    downloadInvoiceSuccess(state) {
      state.isDownloading = false;
      state.isDownloaded = true;
    },
    downloadInvoiceFailed(state, action) {
      state.isDownloading = false;
      state.isDownloaded = false;
      state.error = action.payload;
    }
  }
});

export const { downloadInvoiceRequest, downloadInvoiceSuccess, downloadInvoiceFailed } =
  downloadInvoiceSlice.actions;

export default downloadInvoiceSlice.reducer;

export const downloadInvoice = (invoiceId) => async (dispatch) => {
  try {
    dispatch(downloadInvoiceRequest(invoiceId));
    await rest.downloadInvoice(invoiceId);
    dispatch(downloadInvoiceSuccess());
  } catch (error) {
    dispatch(downloadInvoiceFailed(error));
  }
};
