import { ApiFlowShop, ApiParty } from 'interfaces/interfaces.generated';

export const buildPartyObject = (shop: ApiFlowShop): ApiParty => ({
  address: {
    zip: shop?.warehousePostalCode?.toString() ?? '',
    streetNumber: shop?.warehouseStreetNumber,
    streetName: shop?.warehouseStreetName,
    entrance: shop?.warehouseEntrance,
    countryCode: shop?.warehouseCountry,
    city: shop?.warehouseCity
  },
  email: shop?.contactPersonEmail,
  name: shop?.name ?? '',
  phone: shop?.contactPersonPhone
});
