export const findFreightProductById = (freightProducts, freightProductId) =>
  freightProducts?.find((freightProduct) => freightProduct.freightProductId === +freightProductId);

export const findJobById = (jobs, jobId) => jobs?.find((job) => job.jobId === +jobId);

export const findShopById = (shops, shopId) => shops?.find((shop) => shop.shopId === +shopId);

export const findServiceSupplierById = (serviceSuppliers, serviceSupplierId) =>
  serviceSuppliers?.find((serviceSupplier) => serviceSupplier.id === +serviceSupplierId);

export const findTransportSolutionById = (transportSolutions, transportSolutionId) =>
  transportSolutions?.find(
    (transportSolution) => transportSolution.transportSolutionId === +transportSolutionId
  );

export const findConcernById = (concerns, concernId) =>
  concerns?.find((concern) => concern.concernId === +concernId);

export const findConcernByShopId = (concerns, shopId) =>
  concerns?.find((concern) => concern.shopIds.find((concernShopId) => concernShopId === +shopId));

export const findCustomerSystemById = (customerSystems, customerSystemId) =>
  customerSystemId
    ? customerSystems?.find(
        (customerSystem) => customerSystem.customerSystemId === +customerSystemId
      )
    : undefined;

export const findUserById = (users, userId) => users?.find((user) => user.userId === +userId);

export const findUrlEnv = (env) => {
  switch (env) {
    case 'dev':
      return 'dev-';
    case 'stg':
      return 'stg-';
    default:
      return '';
  }
};
