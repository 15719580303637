import { useEffect, useState } from 'react';
import classNames from 'classnames';

import ImportedOrdersTable from './components/ImportedOrdersTable';
import Spinner from 'js/components/Spinner';
import Button from 'js/components/Button';
import SearchFilter from 'js/components/SearchFilter';

import t from 'js/utils/translate';
import { findRouteByName } from 'js/routes';
import { getBulkOrders } from './store/bulkOrderReducer';
import { filterOrders, findNotProcessedBulkOrders, findProcessedBulkOrders } from './utils';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

const BulkOrder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);
  const ordersWasImported = useAppSelector((state) => state?.orders?.bulkOrder?.isLoaded);
  const importedOrders = useAppSelector((state) => state?.orders?.bulkOrder?.data);
  const [search, setSearch] = useState('');
  const [filteredOrders, setFilteredOrders] = useState(importedOrders);
  const fileWasHandled = useAppSelector((state) => state?.orders?.bulkOrder?.isHandled);

  useEffect(() => {
    if (!ordersWasImported) dispatch(getBulkOrders());
  }, []);

  useEffect(() => {
    if (fileWasHandled) dispatch(getBulkOrders());
  }, [fileWasHandled]);

  useEffect(() => {
    if (ordersWasImported) setFilteredOrders(importedOrders);
  }, [ordersWasImported]);

  useEffect(() => {
    if (ordersWasImported) setFilteredOrders(filterOrders(importedOrders, search));
  }, [search]);

  const processedOrders = findProcessedBulkOrders(filteredOrders);
  const notProcessedOrders = findNotProcessedBulkOrders(filteredOrders);

  const pageHeaderActions = findRouteByName('Bulk Order')?.pageHeaderActions ?? [];

  return (
    <div className='bulk-orders-page'>
      {!isDesktop && (
        <div className='new-bulk-order-button'>
          {pageHeaderActions?.map((action) => (
            <Button
              key={action.text}
              handleClick={() => {
                navigate(action.path);
              }}
              name={t(action.text)}
            />
          ))}
        </div>
      )}
      <SearchFilter
        inputValue={search}
        info={t('orders.bulkOrder.search.info')}
        setSearch={setSearch}
      />
      {ordersWasImported ? (
        <div
          className={classNames('tables-wrapper', {
            'page-padding': isDesktop
          })}
        >
          {notProcessedOrders.length > 0 && <ImportedOrdersTable bulkOrders={notProcessedOrders} />}
          {processedOrders.length > 0 && (
            <ImportedOrdersTable pagination bulkOrders={processedOrders} />
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default BulkOrder;
