import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as rest from '../../api';

import { ApiOrder, ApiBookingResponse } from 'src/interfaces/interfaces.generated';

export interface NewOrderReducerState {
  error: unknown;
  isCreating: boolean;
  data: ApiBookingResponse;
}

const initialState: NewOrderReducerState = {
  error: null,
  isCreating: false,
  data: {}
};

const newOrderSlice = createSlice({
  name: 'NewOrder',
  initialState,
  reducers: {
    createNewOrderRequest(state) {
      state.error = null;
      state.isCreating = true;
    },
    createNewOrderSuccess(state, action) {
      state.isCreating = false;
      state.data = action.payload;
    },
    createNewOrderFailed(state, action) {
      state.error = action.payload;
      state.isCreating = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { createNewOrderRequest, createNewOrderSuccess, createNewOrderFailed } =
  newOrderSlice.actions;

export default newOrderSlice.reducer;

export const createNewOrder =
  (order: ApiOrder) =>
  async (dispatch): Promise<ApiBookingResponse | undefined> => {
    try {
      dispatch(createNewOrderRequest());
      const response: ApiBookingResponse = await rest.createNewOrder(order);
      dispatch(createNewOrderSuccess(response));
      return response;
    } catch (err) {
      dispatch(createNewOrderFailed(err));
    }
  };
