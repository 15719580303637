import { createSlice } from '@reduxjs/toolkit';
import * as rest from '../../api';
import { getOrder } from '../../../../store/orderReducer';
import { getOrderEvents } from '../../../../components/OrderHistory/store/orderEventsReducer';
import { ApiEventType, OrderStatusChange } from 'src/interfaces/interfaces.generated';

export interface EditStatusReducerState {
  error: unknown;
  data: ApiEventType[]; // TODO finn ut hva changableEventTypes er
  isLoaded: boolean;
  isSaving: boolean;
}

const initialState: EditStatusReducerState = {
  error: null,
  data: [],
  isLoaded: false,
  isSaving: false
};

const changeableEventTypesSlice = createSlice({
  name: 'ChangeableEventTypes',
  initialState,
  reducers: {
    getChangeableEventTypesRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getChangeableEventTypesSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getChangeableEventTypesFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    },
    updateOrderStatusRequest(state) {
      state.isSaving = true;
      state.error = null;
    },
    updateOrderStatusSuccess(state) {
      state.isSaving = false;
    },
    updateOrderStatusFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    }
  }
});

export const {
  getChangeableEventTypesRequest,
  getChangeableEventTypesSuccess,
  getChangeableEventTypesFailed,
  updateOrderStatusRequest,
  updateOrderStatusSuccess,
  updateOrderStatusFailed
} = changeableEventTypesSlice.actions;

export default changeableEventTypesSlice.reducer;

export const getChangeableEventTypes =
  (trackingReference: string) =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(getChangeableEventTypesRequest());
      const changeableEventTypes: ApiEventType[] =
        await rest.getChangeableEventTypes(trackingReference);
      dispatch(getChangeableEventTypesSuccess(changeableEventTypes));
    } catch (err) {
      dispatch(getChangeableEventTypesFailed(err));
    }
  };

export const updateOrderStatus =
  (orderStatusChange: OrderStatusChange, trackingReference: string) => async (dispatch) => {
    try {
      dispatch(updateOrderStatusRequest());
      const response = await rest.updateOrderStatus(orderStatusChange, trackingReference);
      dispatch(getOrder(trackingReference));
      dispatch(getOrderEvents(trackingReference));
      dispatch(updateOrderStatusSuccess());
      return response;
    } catch (err) {
      dispatch(updateOrderStatusFailed(err));
    }
  };
