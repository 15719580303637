import { FC } from 'react';

import Shops from './components/Shops';
import Status from './components/Status';

import { LabelGenerationHistory } from 'src/interfaces/interfaces.generated';
import { LabelStatus } from '../../../../interfaces';

import './index.sass';

interface Props {
  labelExcerpt: LabelGenerationHistory;
  labelStatus?: LabelStatus;
}

const ExcerptInfo: FC<Props> = ({ labelExcerpt, labelStatus }) => (
  <div className='excerpt-info'>
    <div className='excerpt-info__user'>
      <span>{labelExcerpt.username}</span>
      <span>{labelExcerpt.email}</span>
    </div>
    {labelExcerpt?.shopIds?.length > 0 && <Shops labelExcerpt={labelExcerpt} />}
    {labelStatus && <Status className='excerpt-info--end' labelStatus={labelStatus} />}
  </div>
);

export default ExcerptInfo;
