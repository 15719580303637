import { combineReducers } from 'redux';

import orderEventsReducer, {
  OrderEventsReducerState
} from './components/OrderHistory/store/orderEventsReducer';
import budBuddyImagesReducer, { BudBuddyImagesReducerState } from './store/budBuddyImagesReducer';
import linkedParcelNumbersReducer, {
  LinkedParcelNumbersReducerState
} from './store/linkedParcelNumbersReducer';
import orderReducer, { OrderReducerState } from './store/orderReducer';
import editOrderReducer, { EditOrderReducerState } from './scenes/EditOrder/store/editOrderReducer';
import editOrderPartyReducer, {
  EditOrderPartyReducerState
} from './scenes/EditOrderParty/store/editOrderPartyReducer';
import editShipmentReducer, {
  EditShipmentReducerState
} from './scenes/EditShipment/store/editShipmentReducer';
import packageInfoReducer, { PackageInfoReducerState } from './store/packageInfoReducer';
import extendPickupDeadlineReducer, {
  ExtendPickupDeadlineReducerState
} from './scenes/EditServicePoint/store/extendPickupDeadlineReducer';
import editStatusReducer, {
  EditStatusReducerState
} from './scenes/EditStatus/store/editStatusReducer';
import resendPickupCodeReducer, {
  ResendPickupCodeReducerState
} from './scenes/EditServicePoint/store/resendPickupCodeReducer';
import getFullOrderReducer, {
  FullOrderReducerState
} from './scenes/EditOrderParty/store/getFullOrderReducer';

const orderDetailsReducer = combineReducers({
  order: orderReducer,
  orderEvents: orderEventsReducer,
  budBuddyImages: budBuddyImagesReducer,
  linkedParcelNumbers: linkedParcelNumbersReducer,
  editOrder: editOrderReducer,
  editOrderParty: editOrderPartyReducer,
  editShipment: editShipmentReducer,
  packageInfo: packageInfoReducer,
  extendPickupDeadline: extendPickupDeadlineReducer,
  editStatus: editStatusReducer,
  resendPickupCode: resendPickupCodeReducer,
  getFullOrder: getFullOrderReducer
});

export default orderDetailsReducer;

export interface OrderDetailsReducerState {
  order: OrderReducerState;
  orderEvents: OrderEventsReducerState;
  budBuddyImages: BudBuddyImagesReducerState;
  editOrder: EditOrderReducerState;
  editOrderParty: EditOrderPartyReducerState;
  editShipment: EditShipmentReducerState;
  packageInfo: PackageInfoReducerState;
  extendPickupDeadline: ExtendPickupDeadlineReducerState;
  editStatus: EditStatusReducerState;
  resendPickupCode: ResendPickupCodeReducerState;
  linkedParcelNumbers: LinkedParcelNumbersReducerState;
  getFullOrder: FullOrderReducerState;
}
