import { isEmpty } from 'lodash';

export const formatAddress = (data) =>
  `${data?.address ?? '-'} \n${data?.zipCode ?? ''} ${data?.postalName ?? ''} \n${
    data?.countryCode ?? ''
  }`;

export const formatPhone = (number) => {
  if (number) {
    if (number.length === 8) {
      let test = '';
      number.split('').forEach((element, i) => {
        if (i === 3 || i === 5) {
          test += ' ';
        }
        test += number.charAt(i);
      });
      return test;
    } else {
      return number;
    }
  } else {
    return '-';
  }
};

export const nullContent = (object) => Object.values(object).every((value) => value === null);

const servicePointFreightProductIds = [56, 55, 58];

export const orderHasServicePointInfoAndHitFilter = (order) =>
  !isEmpty(order.servicePoint) &&
  order.freightProductId &&
  servicePointFreightProductIds.includes(order.freightProductId);

export const orderHasServicePointInfoAndIncludeTagOnFreightProduct = (order, freightProduct) =>
  !isEmpty(order.servicePoint) && freightProduct?.includeServicePoint === true;
