import Select from 'js/components/Form/components/Select';

import { findOrderByParams } from './utils';
import t, { tu } from 'js/utils/translate';

const SelectLabelOrdering = ({
  mainCarNumbers,
  preTransportCarNumbers,
  subTransportCarNames,
  mainCarPlannedDepartures,
  ownerParty
}) => (
  <Select
    name='orderByList'
    options={findOrderByParams(
      mainCarNumbers,
      preTransportCarNumbers,
      subTransportCarNames,
      mainCarPlannedDepartures,
      ownerParty
    )}
    label={tu('orders.labels.sort.labels')}
    optional
    isMulti
    selectAll
    subLabel={t('orders.labels.prioritized.from.left')}
    placeholder={t('select.sort.labels')}
  />
);

export default SelectLabelOrdering;
