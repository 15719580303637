import { FC } from 'react';
import classnames from 'classnames';

import Icon from 'js/components/Icon';
import t from 'js/utils/translate';

import { FooterBox as FooterBoxObject } from '../../interfaces';

import './index.sass';
import { useNavigate } from 'react-router-dom';

type Props = FooterBoxObject;

const FooterBox: FC<Props> = ({
  topLeft = false,
  topRight = false,
  bottomLeft = false,
  bottomRight = false,
  link,
  icon,
  headerText,
  className
}) => {
  const navigate = useNavigate();

  const SVGIcon = icon;

  return (
    <div
      onClick={() => {
        navigate(link!);
      }}
      className={classnames('footer-box', className)}
    >
      <div className='footer-box__header'>
        {icon && <SVGIcon />}
        <h3 className='footer-box--text'>{t(`footerBox.header.${headerText}`).toUpperCase()}</h3>
      </div>
      <div className='footer-box__body'>
        {topLeft && <div className='footer-box__body--top footer-box__body--left' />}
        {topRight && <div className='footer-box__body--top footer-box__body--right' />}
        {bottomLeft && <div className='footer-box__body--bottom footer-box__body--left' />}
        {bottomRight && <div className='footer-box__body--bottom footer-box__body--right' />}
        <div className='footer-box__body--text'>{t(`footerBox.body.${headerText}`)}</div>
      </div>
      <div className='footer-box__footer'>
        <div className='footer-box__footer--text'>
          {t('general.goto', t(`footerBox.header.${headerText}`).toLowerCase())}
        </div>
        <Icon icon='angle-right' light />
      </div>
    </div>
  );
};

export default FooterBox;
