import React from 'react';
import { AppDispatch } from 'js/store/store';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'js/store/reducer';

// https://stackoverflow.com/questions/53090432/react-hooks-right-way-to-clear-timeouts-and-intervals
// Timeout is interrupted when delay = null (not number?)
export const useTimeout = (callback, delay) => {
  const timeoutRef = React.useRef<number | undefined>();
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [delay]);

  return timeoutRef;
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
