/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useMemo } from 'react';
import {
  useFormContext,
  useRegister,
  useValue,
  withError
} from '@distribution-innovation/form-hooks';

import _DatepickerRange from 'js/components/DatepickerRange';
import InputError from 'js/components/Form/components/Validation/InputError';

import { fromDateIsAfterToDate, selectedIsOutsideInterval, requiredAndMissing } from './utils';

import './index.sass';

interface Props {
  align?: 'row' | 'column';
  name: string;
  optional?: boolean;
  minDate?: Date;
  maxDate?: Date;
  interval?: number;
  disabled?: boolean;
  showTimeInput?: boolean;
  error;
}

const DatepickerRange: FC<Props> = ({
  align,
  name,
  optional,
  minDate,
  maxDate,
  interval,
  disabled,
  showTimeInput,
  error
}) => {
  const [fromDate, setFromDate] = useValue(`${name}.fromDate`);
  const [toDate, setToDate] = useValue(`${name}.toDate`);

  const context = useFormContext();
  const values = context.getValues();

  const dates = values[name] ?? { fromDate: undefined, toDate: undefined };

  const validate = ({ fromDate, toDate }: { fromDate: Date; toDate: Date }): string | undefined => {
    if (fromDateIsAfterToDate(fromDate, toDate)) return 'fromDateCannotBeAfterToDate';
    else if (selectedIsOutsideInterval(fromDate, toDate, interval)) return 'outsideInterval';
    else if (requiredAndMissing(fromDate, toDate, optional)) return 'valueMissing';
    else return undefined;
  };

  const handleBlur = () => context.getTouched(name) || context.setTouched(name);

  useRegister({ name, value: { fromDate, toDate }, validate });

  useEffect(() => context.setError(name, validate(dates)), [dates]);

  return useMemo(
    () => (
      <div className='form-datepicker-range'>
        <_DatepickerRange
          align={align}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          minDate={minDate}
          maxDate={maxDate}
          handleBlur={handleBlur}
          interval={interval}
          disabled={disabled}
          showTimeInput={showTimeInput}
          error={error}
        />
        {error && <InputError error={error} interval={interval} />}
      </div>
    ),
    [error, fromDate, toDate, disabled]
  );
};

export default withError()(DatepickerRange);
