import MessageForUser from 'js/components/MessageForUser';
import { FC } from 'react';
import t from 'js/utils/translate';

interface Props {
  foundLabels: boolean;
}

export const UserFeedback: FC<Props> = ({ foundLabels }) => {
  if (!foundLabels) {
    return <MessageForUser variant='warning' message={t('orders.labels.info.no.labels.found')} />;
  }
  return <MessageForUser variant='info' message={t('orders.labels.info.labels.found')} />;
};
