import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { Form } from '@distribution-innovation/form-hooks';
import Error from './validation/Error';
import FormActions from 'js/components/Form/components/FormActions';

import './index.sass';

interface FormProps {
  actions?: boolean;
  children: ReactNode;
  error?: unknown;
  type?: 'save' | 'create' | 'getReport' | 'getLabels' | 'remove';
  redirectTo?: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  initialValues?: unknown;
  debug?: boolean;
  noValidate?: boolean;
  onSubmit?: (values: any) => typeof values;
  onCancel?: () => void;
  submitButtonVariant?: 'primary' | 'inverse' | 'danger';
}

const FormV2: FC<FormProps> = ({
  actions = true,
  children,
  error,
  type,
  redirectTo,
  loading,
  disabled,
  className,
  onSubmit,
  onCancel,
  submitButtonVariant = 'primary',
  ...props
}) => (
  <Form noValidate {...props} onSubmit={onSubmit} className={classNames('form-v2', className)}>
    {children}
    {error && <Error error={error} />}
    {actions && (
      <FormActions
        type={type}
        redirectTo={redirectTo}
        loading={loading}
        disabled={disabled}
        onCancel={onCancel}
        submitButtonVariant={submitButtonVariant}
      />
    )}
  </Form>
);

export default FormV2;
