import { ApiWebservice } from 'src/interfaces/interfaces.generated';
import { isEmpty } from 'lodash';

const findWebservice = (api: number, webservices: ApiWebservice[]): ApiWebservice =>
  webservices.find((ws) => ws.webserviceId === api) || {};

export const findWebservices = (
  selectedApis: number[],
  webservices: ApiWebservice[]
): ApiWebservice[] =>
  selectedApis?.map((api) => findWebservice(api, webservices)).filter((ws) => !isEmpty(ws)) || [];
