import { FC } from 'react';

import EnvironmentRibbon from '../components/EnvironmentRibbon/';
import CompanyLogo from '../components/CompanyLogo/';

import './index.sass';

const LoginHeader: FC = () => (
  <div className='login-header'>
    <EnvironmentRibbon />
    <CompanyLogo />
  </div>
);

export default LoginHeader;
