import { useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import PageWrapper from 'js/components/PageWrapper';
import SplitPage from 'js/components/SplitPage';
import FormV2 from 'js/components/FormV2';
import Spinner from 'js/components/Spinner';
import WeightBaseRadios from './components/WeightBaseRadios';

import Input from 'js/components/Form/components/Input';
import SelectV2 from 'js/components/Form/components/SelectV2';

import t, { tu } from 'js/utils/translate';

import { getZipGroups } from '../../store/zipGroupsReducer';
import { getWeightGroups } from '../../store/weightGroupsReducer';
import { getDimensionGroups } from '../../store/dimensionGroupsReducer';
import { getWeightBases } from '../../store/weightBasesReducer';
import { getInvoiceSystems } from '../../store/invoiceSystemsReducer';

import { dimensionGroupOptions, weightGroupOptions, zipGroupOptions } from '../../utils';

import './index.sass';
import {
  editInvoiceSetup,
  getFreightProductInvoiceSetups
} from 'js/scenes/Economy/scenes/InvoiceSetup/store/freightProductInvoiceSetup';
import { ApiInvoiceSetup } from 'interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate, useParams } from 'react-router-dom';

const EditInvoiceSetup = () => {
  const navigate = useNavigate();
  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const dispatch = useAppDispatch();
  const { freightProductId } = useParams();

  const dimensionGroupsLoaded = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.dimensionGroups?.isLoaded
  );
  const dimensionGroups = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.dimensionGroups?.data
  );
  const weightGroupsLoaded = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.weightGroups?.isLoaded
  );
  const weightGroups = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.weightGroups?.data
  );
  const zipGroupsLoaded = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.zipGroups?.isLoaded
  );
  const zipGroups = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.zipGroups?.data
  );
  const invoiceSystemsLoaded = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.invoiceSystems?.isLoaded
  );
  const invoiceSystems = useAppSelector(
    (state: RootState) => state.economy?.invoiceSetup?.invoiceSystems?.data
  );
  const weightBasesLoaded = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.weightBases?.isLoaded
  );
  const weightBases = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.weightBases?.data
  );
  const invoiceSetups: ApiInvoiceSetup[] = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.freightProductInvoiceSetup?.data
  );
  const invoiceSetupsLoaded = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.freightProductInvoiceSetup?.isLoaded
  );
  const saving = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.freightProductInvoiceSetup?.isSaving
  );
  const error = useAppSelector(
    (state: RootState) => state?.economy?.invoiceSetup?.freightProductInvoiceSetup?.error
  );

  useEffect(() => {
    if (!invoiceSetupsLoaded) dispatch(getFreightProductInvoiceSetups());
    if (!dimensionGroupsLoaded) dispatch(getDimensionGroups());
    if (!weightGroupsLoaded) dispatch(getWeightGroups());
    if (!zipGroupsLoaded) dispatch(getZipGroups());
    if (!invoiceSystemsLoaded) dispatch(getInvoiceSystems());
    if (!weightBasesLoaded) dispatch(getWeightBases());
  }, []);

  const redirectTo = '/economy/invoiceSetup';

  const handleSubmit = ({ invoiceSetup }) => {
    if (invoiceSetup) {
      dispatch(editInvoiceSetup(freightProductId, invoiceSetup)).then(() => {
        navigate(redirectTo);
      });
    }
  };
  const invoiceSetup = invoiceSetups.find(
    (invoiceSetup) => invoiceSetup.freightProductId === +freightProductId!
  );

  if (!freightProductId || !invoiceSetup?.freightProductId) return <Spinner />;

  const translatePrefix = 'economy.invoiceSetup.edit';

  const { weightBaseId, ...rest } = invoiceSetup;
  const values = {
    weightBaseId: weightBaseId ? String(weightBaseId) : '',
    ...rest
  };

  return (
    <SplitPage>
      <PageWrapper className='edit-invoice-setup'>
        <FormV2
          initialValues={{ invoiceSetup: values }}
          onSubmit={handleSubmit}
          loading={saving}
          error={error}
          actions
          redirectTo={redirectTo}
        >
          <div className='edit-invoice-setup__invoice-system-code'>
            <Input
              name='invoiceSetup.invoiceSystemCode'
              label={tu(
                `${translatePrefix}.invoiceSystemCode`,
                invoiceSystems?.name ? ` (${invoiceSystems.name})` : ''
              )}
              optional
            />
          </div>
          <div className='edit-invoice-setup__zone-interval'>
            <Input
              name='invoiceSetup.fromZoneId'
              label={tu(`${translatePrefix}.fromZone`)}
              subLabel={isDesktop && t(`${translatePrefix}.fromZone.info`)}
              info={!isDesktop && t(`${translatePrefix}.fromZone.info.optional`)}
              optional
              hideOptional={!isDesktop}
            />
            <Input
              name='invoiceSetup.toZoneId'
              label={tu(`${translatePrefix}.toZone`)}
              subLabel={isDesktop && t(`${translatePrefix}.toZone.info`)}
              info={!isDesktop && t(`${translatePrefix}.toZone.info.optional`)}
              optional
              hideOptional={!isDesktop}
            />
          </div>
          <WeightBaseRadios
            name='invoiceSetup.weightBaseId'
            label={tu(`${translatePrefix}.weightBase`)}
            weightBases={weightBases}
            isLoading={!weightBasesLoaded}
            translatePrefix={translatePrefix}
          />
          <SelectV2
            name='invoiceSetup.dimensionGroupId'
            label={tu(`${translatePrefix}.dimensionGroup`)}
            options={dimensionGroupOptions(dimensionGroups)}
            isLoading={!dimensionGroupsLoaded}
            optional
          />
          <SelectV2
            name='invoiceSetup.zipGroupId'
            label={tu(`${translatePrefix}.zipGroup`)}
            options={zipGroupOptions(zipGroups)}
            isLoading={!zipGroupsLoaded}
            optional
          />
          <SelectV2
            name='invoiceSetup.weightGroupId'
            label={tu(`${translatePrefix}.weightGroup`)}
            options={weightGroupOptions(weightGroups)}
            isLoading={!weightGroupsLoaded}
            optional
          />
        </FormV2>
      </PageWrapper>
    </SplitPage>
  );
};

export default EditInvoiceSetup;
