import { ShopProofOfDelivery } from 'src/interfaces/interfaces.generated';

import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as api from '../../api';
import { useAppSelector } from 'js/utils/hooks';
import { RootState } from 'js/store/reducer';

export interface ShopProofOfDeliveryReducerState {
  data: ShopProofOfDelivery[];
  error: unknown;
  isSaving: boolean;
  isLoading: boolean;
}

const initialState: ShopProofOfDeliveryReducerState = {
  data: [],
  error: null,
  isSaving: false,
  isLoading: false
};

const shopProofOfDeliverySlice = createSlice({
  name: 'ShopProofOfDelivery',
  initialState,
  reducers: {
    getShopProofOfDeliveryRequest(state, action) {
      state.isLoading = true;
      state.error = null;
      state.data = [];
    },
    getShopProofOfDeliverySuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    getShopProofOfDeliveryFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    editShopProofOfDeliveryRequest(state) {
      state.error = null;
      state.isSaving = true;
    },
    editShopProofOfDeliverySuccess(state, action) {
      state.isSaving = false;
      state.data = action.payload;
    },
    editShopProofOfDeliveryFailed(state, action) {
      state.error = action.payload;
      state.isSaving = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  getShopProofOfDeliveryRequest,
  getShopProofOfDeliverySuccess,
  getShopProofOfDeliveryFailed,
  editShopProofOfDeliveryRequest,
  editShopProofOfDeliverySuccess,
  editShopProofOfDeliveryFailed
} = shopProofOfDeliverySlice.actions;

export default shopProofOfDeliverySlice.reducer;

export const getShopProofOfDelivery =
  (shopId: number) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getShopProofOfDeliveryRequest(shopId));
      const response: ShopProofOfDelivery[] = await api.getShopProofOfDelivery(shopId);
      dispatch(getShopProofOfDeliverySuccess(response));
      return true;
    } catch (error) {
      dispatch(getShopProofOfDeliveryFailed(error));
    }
  };

export const editShopProofOfDelivery =
  (shopId: number, proofOfDelivery) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(editShopProofOfDeliveryRequest());
      const response: ShopProofOfDelivery[] = await api.editShopProofOfDelivery(
        shopId,
        proofOfDelivery
      );
      dispatch(editShopProofOfDeliverySuccess(response));
      return true;
    } catch (error) {
      dispatch(editShopProofOfDeliveryFailed(error));
    }
  };

export const useShopProofOfDelivery = (): ShopProofOfDeliveryReducerState =>
  useAppSelector((state: RootState) => state.freight.shop.shopProofOfDelivery);
