import { FC } from 'react';
import classnames from 'classnames';

import FooterBox from './components/FooterBox';

import { routeAccess } from 'js/routes';

import { FooterBox as FooterBoxObject } from './interfaces';
import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';
import './index.sass';

interface Props {
  footerBoxes: FooterBoxObject[];
  className?: string;
}

const FooterBoxes: FC<Props> = ({ footerBoxes, className }) => {
  if (!footerBoxes || footerBoxes.length === 0) return <></>;

  const userRoles = useAppSelector((state: RootState) => state?.user?.data?.roles);
  const activeOwnerParty = useAppSelector(
    (state: RootState) => state?.user?.data?.activeOwnerParty
  );

  return (
    <div className={classnames('footer-boxes', className)}>
      {footerBoxes.map(
        ({ headerText, link, icon }) =>
          routeAccess(link, userRoles, activeOwnerParty) && (
            <FooterBox
              key={headerText}
              headerText={headerText}
              link={link}
              icon={icon}
              className='footer-boxes__footer-box'
              topRight
              bottomRight
            />
          )
      )}
    </div>
  );
};

export default FooterBoxes;
