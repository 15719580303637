import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as api from '../../api';
import { Context } from '../../interfaces';

export interface ContextsReducerState {
  error: unknown;
  data: Context[];
  isLoaded: boolean;
}

const initialState: ContextsReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const contextsSlice = createSlice({
  name: 'Contexts',
  initialState,
  reducers: {
    getContextsRequest(state) {
      state.error = null;
      state.isLoaded = false;
      state.data = [];
    },
    getContextsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getContextsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getContextsRequest, getContextsSuccess, getContextsFailed } = contextsSlice.actions;

export default contextsSlice.reducer;

export const getContexts =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getContextsRequest());
      const response: Context[] = await api.getContexts();
      dispatch(getContextsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getContextsFailed(error));
    }
  };
