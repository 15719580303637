import { FC } from 'react';
import classNames from 'classnames';

import InnsiktLeftArrow from 'images/icons/InnsiktLeftArrow.svg';
import InnsiktRightArrow from 'images/icons/InnsiktRightArrow.svg';

import './index.sass';

interface Props {
  variant: 'left' | 'right';
  handleClick: () => void;
}

const DashboardArrow: FC<Props> = ({ variant, handleClick }) => {
  return (
    <div className={classNames('dashboard-arrow', `dashboard-arrow--${variant}`)}>
      {variant === 'left' ? (
        <InnsiktLeftArrow onClick={handleClick} />
      ) : (
        <InnsiktRightArrow onClick={handleClick} />
      )}
    </div>
  );
};

export default DashboardArrow;
