import { isEmpty } from 'lodash';
import { findShopById } from 'js/utils/find';

export const findLocationHeaderFromParams = (params, shops): string | undefined => {
  const accessor: string | undefined = isEmpty(params) ? undefined : Object.keys(params)[0];
  switch (accessor) {
    case 'trackingReference': {
      const trackingReference = params[accessor];
      return trackingReference;
    }
    case 'shopId': {
      const shopId = params[accessor];
      return findShopById(shops, shopId)?.name ?? 'Shop not found';
    }
    default:
      return undefined;
  }
};
