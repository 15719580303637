import { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { RootState } from 'js/store/reducer';

import Select from 'js/components/Select';

import {
  findNewElements,
  findSelectedServiceSupplierShops,
  mapToShopServiceSuppliers
} from './utils';
import t, { tu } from 'js/utils/translate';
import { shopExternalIdShopOptions, shopOptions } from 'js/components/Select/utils';
import { ApiFlowShopSimple, ApiServiceSupplier } from 'src/interfaces/interfaces.generated';
import { Option } from 'js/components/SelectV2/interfaces';
import { useAppSelector } from 'js/utils/hooks';

interface Props {
  serviceSupplier?: ApiServiceSupplier;
  selectedShops;
  setSelectedShops;
}

const SelectShops: FC<Props> = ({ serviceSupplier, selectedShops, setSelectedShops }) => {
  const [selectedServiceSupplierShops, setSelectedServiceSupplierShops] = useState<
    Option[] | undefined
  >([]);

  const shops: ApiFlowShopSimple[] = useAppSelector(
    (state: RootState) => state?.freight?.shops?.data
  );
  const shopsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shops?.isLoaded
  );

  const addSelectedShops = (selectList: Option[]) =>
    setSelectedShops(
      selectedShops
        ? selectedShops.concat(
            mapToShopServiceSuppliers(findNewElements(selectList, selectedShops))
          )
        : mapToShopServiceSuppliers(selectList)
    );

  const removeSelectedShops = (selectList) =>
    setSelectedShops(
      selectedShops.filter((shop) => selectList.find((element) => element.value === shop.shopId))
    );

  const emptyList = () => setSelectedShops([]);

  const [numPrevShops, setNumPrevShops] = useState(0);
  const [shopWasSelected, setShopWasSelected] = useState(false);

  useEffect(() => {
    if (shopsLoaded && !isEmpty(serviceSupplier)) {
      setSelectedServiceSupplierShops(findSelectedServiceSupplierShops(serviceSupplier, shops));
    }
    if (selectedShops && shopWasSelected) {
      setSelectedServiceSupplierShops(shopExternalIdShopOptions(selectedShops, shops));
    }
    if (selectedShops?.length > 0) setNumPrevShops(selectedShops.length);
  }, [selectedShops, serviceSupplier]);

  const handleSelectChange = (newValues) => {
    const numShops = newValues?.length || 0;
    if (newValues) {
      numShops > numPrevShops ? addSelectedShops(newValues) : removeSelectedShops(newValues);
    } else {
      emptyList();
    }
    setNumPrevShops(numShops);
    setShopWasSelected(true);
  };

  return (
    <Select
      label={tu('general.shops')}
      placeholder={t('select.shops')}
      options={shopOptions(shops)}
      isMulti
      selectAll
      isLoading={!shopsLoaded}
      selectedValues={selectedServiceSupplierShops}
      onChange={(newValues: Option[]) => {
        handleSelectChange(newValues);
      }}
    />
  );
};

export default SelectShops;
