import { FC } from 'react';
import classNames from 'classnames';

import t from 'js/utils/translate';

import './index.sass';

interface Props {
  className?: string;
  variant: 'info' | 'warning' | 'error';
  message?: string;
  children?: any;
}

const MessageForUser: FC<Props> = ({ className, variant = 'info', message, children }) => (
  <div
    className={classNames(className, 'message-for-user', `message-for-user__variant--${variant}`)}
  >
    <div className='message-for-user__variant'>{t(`general.${variant}`)}</div>
    {message && <div>{message}</div>}
    {children && children}
  </div>
);

export default MessageForUser;
