import { FC } from 'react';
import Icon from 'js/components/Icon';
import t from 'js/utils/translate';
import DateTime from 'js/components/DateTime';
import './LastLoggedIn.sass';

const LastLoggedIn: FC<{
  value?: any;
}> = ({ value }) => {
  return value ? (
    <DateTime datetime={new Date(value)} />
  ) : (
    <div className='neverLoggedIn'>
      <div>
        <Icon icon='calendar' regular /> <Icon icon='clock' regular />
      </div>
      {t('userAdministration.neverLoggedIn')}
    </div>
  );
};

export default LastLoggedIn;
