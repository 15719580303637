import { FC, useMemo } from 'react';

import InfoBox from 'js/components/InfoBox';
import PageWrapper from 'js/components/PageWrapper';
import CommaSeparatedString from 'js/components/CommaSeparatedNames/components/CommaSeparatedString';
import TableV2 from 'js/components/TableV2';

import t from 'js/utils/translate';
import { findShopById } from 'js/utils/find';

import { RootState } from 'js/store/reducer';
import { ApiFlowShopSimple, ApiServiceSupplier } from 'src/interfaces/interfaces.generated';
import { Column } from 'js/components/TableV2/interfaces';
import { useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

interface Props {
  filteredServiceSuppliers: ApiServiceSupplier[];
  shops: ApiFlowShopSimple[];
}

const ServiceSupplierTable: FC<Props> = ({ filteredServiceSuppliers, shops }) => {
  const navigate = useNavigate();
  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const rowIcon = 'pencil-alt';

  const columns: Array<Column<any>> = useMemo(
    () => [
      {
        Header: t('freight.serviceSuppliers.table.header.name'),
        accessor: 'name',
        inHeader: true
      },
      {
        Header: t('freight.serviceSuppliers.table.header.shops'),
        accessor: (serviceSupplier) => (
          <CommaSeparatedString
            data={serviceSupplier.shopAndExternalId.map((shopAndExternalId) => ({
              name: findShopById(shops, shopAndExternalId.shopId)?.name
            }))}
          />
        )
      },
      {
        Header: t('freight.serviceSuppliers.table.header.numberOfUsers'),
        accessor: (serviceSupplier) => serviceSupplier?.users?.length ?? 0,
        onlyDesktop: true
      }
    ],
    []
  );

  if (filteredServiceSuppliers.length === 0) {
    return (
      <PageWrapper>
        <InfoBox text={t('freight.serviceSuppliers.notFound')} />
      </PageWrapper>
    );
  }

  const onClickRow = (serviceSupplierId: number) => {
    navigate(`servicesupplier/${serviceSupplierId}/edit`);
  };

  return (
    <TableV2
      className={isDesktop ? 'page-padding' : 'service-suppliers-mobile-table'}
      columns={columns}
      data={filteredServiceSuppliers}
      rowIcon={rowIcon}
      goToLabel={t('freight.serviceSuppliers.edit')}
      onClickRow={onClickRow}
      idAccessor='id'
      mobileTableVariant='withSmallEditIcon'
      pagination
    />
  );
};

export default ServiceSupplierTable;
