import { FC } from 'react';
import Input from 'js/components/Form/components/Input';

import t, { tu } from 'js/utils/translate';

import './index.sass';

interface Props {
  index: number;
  parcelIndex: number;
  // eslint-disable-next-line no-unused-vars
  removeArticle: (index: number) => void;
}

const Article: FC<Props> = ({ index, parcelIndex, removeArticle }) => (
  <div className='article__row'>
    <Input
      className='article__row--medium'
      name={`parcels[${parcelIndex}]articles[${index}][articleNumber]`}
      label={tu('orders.new.order.article.number')}
    />
    <Input
      className='article__row--medium'
      name={`parcels[${parcelIndex}]articles[${index}][amount]`}
      label={tu('orders.new.order.article.quantity')}
    />
    <Input
      className='article__row--medium'
      name={`parcels[${parcelIndex}]articles[${index}][name]`}
      label={tu('orders.new.order.article.name')}
    />
    <div
      className='link'
      onClick={() => {
        removeArticle(index);
      }}
    >
      {t('orders.new.order.remove.article')}
    </div>
  </div>
);

export default Article;
