import { findCustomerSystemById } from 'js/utils/find';
import { ApiCustomerSystem } from 'src/interfaces/interfaces.generated';

const WEBSERVICE_NAME = 'Web service';

export const filteredWebserviceUsers = (users) =>
  users?.filter((user) => user.firstName === WEBSERVICE_NAME);

export const filteredShopUsers = (users) =>
  users?.filter((user) => user.firstName !== WEBSERVICE_NAME);

export const content = (element) => {
  if (element) {
    if (element !== '') {
      return element;
    }
  }
  return '-';
};

export const findCustomerSystem = (
  customerSystems: ApiCustomerSystem[],
  customerSystemId: number | null | undefined
): ApiCustomerSystem =>
  customerSystemId === null || !findCustomerSystemById(customerSystems, customerSystemId)
    ? {}
    : findCustomerSystemById(customerSystems, customerSystemId);
