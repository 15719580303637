import { FC } from 'react';

import OrderEvent from './components/OrderEvent';

import { ApiFlowLogEvent } from 'src/interfaces/interfaces.generated';

import './index.sass';

interface Props {
  toIndex: number;
  events: ApiFlowLogEvent[];
}

const Events: FC<Props> = ({ events, toIndex }) => (
  <div className='events'>
    {events.map(
      (orderEvent, index) =>
        index < toIndex && (
          <OrderEvent
            key={index}
            orderEvent={orderEvent}
            hasConnectedLine={index < toIndex - 1 && index < events.length - 1}
          />
        )
    )}
  </div>
);

export default Events;
