import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { ApiRetailerProduct } from 'src/interfaces/interfaces.generated';
import * as api from '../../api';

export interface RetailerProductsReducerState {
  error: unknown;
  data: ApiRetailerProduct[];
  isLoaded: boolean;
}

const initialState: RetailerProductsReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const retailerProductsSlice = createSlice({
  name: 'RetailerProducts',
  initialState,
  reducers: {
    getRetailerProductsRequest(state) {
      state.error = null;
      state.isLoaded = false;
      state.data = [];
    },
    getRetailerProductsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getRetailerProductsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getRetailerProductsRequest, getRetailerProductsSuccess, getRetailerProductsFailed } =
  retailerProductsSlice.actions;

export default retailerProductsSlice.reducer;

export const getRetailerProducts =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getRetailerProductsRequest());
      const response: ApiRetailerProduct[] = await api.getRetailerProducts();
      dispatch(getRetailerProductsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getRetailerProductsFailed(error));
    }
  };
