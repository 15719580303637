import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../api';
import { ApiConcern } from 'src/interfaces/interfaces.generated';

export interface NewConcernReducerState {
  error: unknown;
  isCreating: boolean;
  isCreated: boolean;
}

const initialState: NewConcernReducerState = {
  error: null,
  isCreating: false,
  isCreated: false
};

const newConcernSlice = createSlice({
  name: 'NewConcern',
  initialState,
  reducers: {
    newConcernRequest(state) {
      state.error = null;
      state.isCreating = true;
      state.isCreated = false;
    },
    newConcernSuccess(state) {
      state.isCreating = false;
      state.isCreated = true;
    },
    newConcernFailed(state, action) {
      state.error = action.payload;
      state.isCreating = false;
      state.isCreated = false;
    }
  }
});

export const { newConcernRequest, newConcernSuccess, newConcernFailed } = newConcernSlice.actions;

export default newConcernSlice.reducer;

export const newConcern =
  (concern: ApiConcern) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(newConcernRequest());
      await api.newConcern(concern);
      dispatch(newConcernSuccess());
      return true;
    } catch (error) {
      dispatch(newConcernFailed(error));
    }
  };
