import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector, useTimeout } from 'js/utils/hooks';
import { RootState } from 'js/store/reducer';
import { isEmpty } from 'lodash';

import InfoBox from 'js/components/InfoBox';
import MessageForUser from 'js/components/MessageForUser';
import Spinner from 'js/components/Spinner';
import UserForm from '../../components/UserForm';

import { getShopUsers } from '../../store/shopUsersReducer';
import { findShopById } from 'js/utils/find';
import { filteredWebserviceUsers } from '../../utils';
import { getShops } from 'js/scenes/Freight/store/shopsReducer';
import { getShop } from '../../store/shopReducer';
import { getWebservices } from '../../store/wsReducer';
import { editUser, giveShopAccess } from '../../../../../../store/userReducer';
import { createWebserviceUser } from './store/wsUserReducer';
import t from 'js/utils/translate';
import { ApiUser } from 'src/interfaces/interfaces.generated';
import { findWebservices } from './utils';
import { useNavigate, useParams } from 'react-router-dom';

const EditShopUsers: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { shopId } = useParams();

  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const shopsLoaded = useAppSelector((state: RootState) => state?.freight?.shops?.isLoaded);
  const shops = useAppSelector((state: RootState) => state?.freight?.shops?.data);
  const shopUsersLoaded = useAppSelector(
    (state: RootState) => state?.freight?.shop?.shopUsers?.isLoaded
  );
  const shopUsers = useAppSelector((state: RootState) => state?.freight?.shop?.shopUsers?.data);
  const webservicesLoaded = useAppSelector(
    (state: RootState) => state?.freight?.shop?.webservices?.isLoaded
  );
  const webservices = useAppSelector((state: RootState) => state?.freight?.shop?.webservices?.data);
  const createApiUserError = useAppSelector(
    (state: RootState) => state?.freight?.shop?.webserviceUser?.error
  );
  const userError = useAppSelector((state: RootState) => state?.freight?.user?.error);

  const shopName = findShopById(shops, shopId)?.name;

  useEffect(() => {
    if (shopId) dispatch(getShopUsers(+shopId));
    if (!shopsLoaded) dispatch(getShops());
    if (!webservicesLoaded) dispatch(getWebservices());
  }, []);

  const error = createApiUserError || userError;

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isEmpty(error)) setIsSubmitting(false);
  }, [error]);

  useTimeout(
    () => {
      setIsSubmitting(false);
      navigate(`/freight/shop/${shopId}`);
      dispatch(getShop(+shopId!));
    },
    isSubmitting ? 4000 : null
  );

  const editAcitveUser = (user) => {
    if (user.active) {
      const editedUser: ApiUser = {
        firstName: user.firstName,
        apiWebserviceList: findWebservices(user.apis, webservices),
        email: user.email,
        language: user.language,
        userId: user.userId,
        userName: user.userName
      };
      dispatch(editUser(user.userId, editedUser));
    }
  };

  const handleSubmit = ({ users, newUsers, connectedUsers }) => {
    if (shopId) {
      setIsSubmitting(true);
      if (users) {
        users.forEach((user) => {
          editAcitveUser(user);
        });
      }
      if (newUsers && shopsLoaded && webservicesLoaded && shopName) {
        newUsers.forEach((user) => {
          const newUser: ApiUser = {
            apiWebserviceList: findWebservices(user.apis, webservices),
            email: user.email,
            language: user.language
          };
          dispatch(createWebserviceUser(+shopId, shopName, newUser));
        });
      }
      if (connectedUsers) {
        connectedUsers.forEach((user) => {
          dispatch(giveShopAccess(+shopId, { userId: user.userId })).then((response?: boolean) => {
            if (response) editAcitveUser(user);
          });
        });
      }
    }
  };

  if (!shopId) return <InfoBox text={t('general.noInformationFound')} />;

  if (!shopUsersLoaded) return <Spinner />;

  const users = filteredWebserviceUsers(shopUsers).map((user: ApiUser) => ({
    apis: user?.apiWebserviceList?.map((ws) => ws.webserviceId) ?? [],
    ...user
  }));

  return (
    <div className={classNames('edit-users-page', { 'page-padding': isDesktop })}>
      <MessageForUser
        variant='warning'
        message={t('freight.shop.edit.apiAccess.warningMessage', t('general.apiUser'))}
      />
      <UserForm
        users={users}
        onSubmit={handleSubmit}
        error={error}
        isSubmitting={isSubmitting}
        redirectTo={shopId ? `/freight/shop/${shopId}` : '/freight'}
        isApiContext
      />
    </div>
  );
};

export default EditShopUsers;
