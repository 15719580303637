import { FC } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import Input from 'js/components/Form/components/Input';
import Articles from './components/Articles';
import SelectV2 from 'js/components/Form/components/SelectV2';
import AdditionalFields from './components/AdditionalFields';

import t, { tu } from 'js/utils/translate';
import { shopServiceSupplierOptions } from 'js/components/Select/utils';
import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  index: number;
  // eslint-disable-next-line no-unused-vars
  removeParcel: (parcel: number) => void;
}

const PackageContent: FC<Props> = ({ index, removeParcel }) => {
  const serviceSuppliers = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSuppliers?.data
  );
  const shopId = useFormContext().getValue('shopId');

  return (
    <div className='package-content'>
      <h3>{`${t('orders.new.order.parcel')} ${index + 1}`}</h3>
      <div className='package-content__row'>
        <Input
          className='package-content__row--large'
          name={`parcels[${index}][content]`}
          label={tu('orders.new.order.parcel.content')}
          optional
        />
        <Input
          className='package-content__row--small'
          name={`parcels[${index}][weight]`}
          label={tu('orders.new.order.parcel.weight')}
          optional
        />
        <Input
          className='package-content__row--small'
          name={`parcels[${index}][value]`}
          label={tu('orders.new.order.parcel.value')}
          optional
        />
        <SelectV2
          label={tu('general.serviceSupplier')}
          name={`parcels[${index}][serviceSupplierId]`}
          options={shopServiceSupplierOptions(serviceSuppliers, shopId)}
          optional
        />
        {index > 0 && (
          <div
            className='link'
            onClick={() => {
              removeParcel(index);
            }}
          >
            {t('orders.new.order.remove.parcel')}
          </div>
        )}
      </div>
      <AdditionalFields parcelIndex={index} />
      <Articles parcelIndex={index} />
    </div>
  );
};

export default PackageContent;
