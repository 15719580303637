import { FC } from 'react';

import CreateUser from './components/CreateUser';

import { ApiUser } from 'src/interfaces/interfaces.generated';

interface Props {
  isApiContext?: boolean;
  newUsers?: ApiUser[];
  setNewUsers;
  name: string;
}

const CreateUsers: FC<Props> = ({ isApiContext, newUsers, setNewUsers, name }) => {
  const removeNewUser = (index: number) =>
    setNewUsers(newUsers?.filter((newUser, i) => i !== index));

  return (
    <div className='create-users'>
      {newUsers?.map((newUser, index) => (
        <CreateUser
          key={newUser?.userName ?? index}
          index={index}
          isApiContext={isApiContext}
          parentName={name}
          removeNewUser={removeNewUser}
        />
      ))}
    </div>
  );
};

export default CreateUsers;
