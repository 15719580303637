import { FC, useEffect, useState } from 'react';
import { RootState } from 'js/store/reducer';
import { isEmpty } from 'lodash';

import PageWrapper from 'js/components/PageWrapper';
import SplitPage from 'js/components/SplitPage';
import RadioGroup from 'js/components/RadioGroup';
import Spinner from 'js/components/Spinner';
import ExistingCustomerSystem from './components/ExistingCustomerSystem';
import NewCustomerSystem from './components/NewCustomerSystem';

import { getCustomerSystems } from '../../store/customerSystemsReducer';
import t, { tu } from 'js/utils/translate';
import { getShop } from '../../store/shopReducer';
import { RadioType } from 'js/components/RadioGroup/interfaces';
import { ApiCustomerSystem, ApiFlowShop } from 'src/interfaces/interfaces.generated';
import { findCustomerSystem } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/utils';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useParams } from 'react-router-dom';

const radioButtons = (editMode: boolean | undefined): RadioType[] => [
  {
    label: t('shop.accordion.customerSystem.existing'),
    value: 'edit',
    checked: editMode
  },
  {
    label: t('shop.accordion.customerSystem.new'),
    value: 'new',
    checked: !editMode
  }
];

const EditShopCustomerSystems: FC = () => {
  const dispatch = useAppDispatch();
  const { shopId } = useParams();
  const redirectTo = shopId ? `/freight/shop/${shopId}` : '/freight';

  const [customerSystem, setCustomerSystem] = useState<ApiCustomerSystem | undefined>();
  const [editMode, setEditMode] = useState<boolean | undefined>();
  const [initialValues, setInitialValues] = useState<unknown | undefined>();

  const shop: ApiFlowShop = useAppSelector((state: RootState) => state?.freight?.shop?.shop?.data);
  const shopLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shop?.shop?.isLoaded
  );
  const customerSystems: ApiCustomerSystem[] = useAppSelector(
    (state: RootState) => state?.freight?.shop?.customerSystems?.data
  );
  const customerSystemsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shop?.customerSystems?.isLoaded
  );

  useEffect(() => {
    if (shop?.shopId !== shopId) dispatch(getShop(+shopId!));
    if (!customerSystemsLoaded) dispatch(getCustomerSystems());
  }, []);

  useEffect(() => {
    if (customerSystemsLoaded && shopLoaded && shop?.customerSysId) {
      setCustomerSystem(findCustomerSystem(customerSystems, shop.customerSysId));
    }
  }, [customerSystemsLoaded, shopLoaded]);

  useEffect(() => {
    if (customerSystem && !isEmpty(customerSystem)) {
      setEditMode(true);
      setInitialValues({ editedCustomerSystem: customerSystem });
    } else {
      setEditMode(false);
      setInitialValues({});
    }
  }, [customerSystem]);

  if (!initialValues) return <Spinner />;

  return (
    <SplitPage>
      <PageWrapper className='edit-customer-system-page'>
        <RadioGroup
          className='edit-customer-system-page__radio-section'
          name='mode'
          label={tu('shop.accordion.customerSystem.customersystem')}
          radios={radioButtons(editMode)}
          onChange={(e) => {
            setEditMode(e.target.value === 'edit');
          }}
          align='row'
        />
        {editMode ? (
          <ExistingCustomerSystem
            shop={shop}
            redirectTo={redirectTo}
            initialValues={initialValues}
          />
        ) : (
          <NewCustomerSystem shop={shop} redirectTo={redirectTo} initialValues={initialValues} />
        )}
      </PageWrapper>
    </SplitPage>
  );
};

export default EditShopCustomerSystems;
