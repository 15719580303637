import { FC } from 'react';

import InvoiceBatchesTable from 'js/scenes/Economy/components/InvoiceBatchesTable';
import FormattedDate from 'js/components/DateTime/components/FormattedDate';
import Actions from './components/Actions';
import Tag from 'js/components/Tag';

import t, { tLowerFirst, tu } from 'js/utils/translate';

import { ApiInvoiceBatch } from 'src/interfaces/interfaces.generated';
import { Column } from 'js/scenes/Economy/components/InvoiceBatchesTable/interfaces';

interface Props {
  invoiceBatches?: ApiInvoiceBatch[];
  allowActions?: boolean;
  context: 'handled' | 'unhandled';
  activePage?: number;
}

const GeneratedInvoiceBatchesTable: FC<Props> = ({
  allowActions,
  invoiceBatches,
  context,
  activePage
}) => {
  const columns: Column[] = [
    {
      Header: tu('general.id'),
      accessor: 'invoiceBatchId'
    },
    {
      Header: tLowerFirst('general.fromDate'),
      accessor: (invoiceBatch: ApiInvoiceBatch) =>
        invoiceBatch.periodFrom && <FormattedDate datetime={new Date(invoiceBatch.periodFrom)} />
    },
    {
      Header: tLowerFirst('general.toDate'),
      accessor: (invoiceBatch: ApiInvoiceBatch) =>
        invoiceBatch.periodTo && <FormattedDate datetime={new Date(invoiceBatch.periodTo)} />
    },
    {
      Header: tLowerFirst('general.orderer'),
      accessor: 'generatedUserName'
    },
    {
      Header: tLowerFirst('general.status'),
      accessor: (invoiceBatch: ApiInvoiceBatch) => (
        <Tag
          variant={invoiceBatch.statusId === 1 ? 'label' : 'info'}
          text={t(`economy.invoice.generateInvoice.statusId.${invoiceBatch.statusId}`)}
        />
      )
    },
    {
      Header: '',
      accessor: (invoiceBatch) => (
        <Actions allowActions={allowActions} invoiceBatch={invoiceBatch} activePage={activePage} />
      )
    }
  ];

  return (
    <InvoiceBatchesTable invoiceBatches={invoiceBatches} context={context} columns={columns} />
  );
};

export default GeneratedInvoiceBatchesTable;
