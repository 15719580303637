import { FC } from 'react';
import { proofOfDeliveryOptionsUtil } from 'js/components/Select/utils';
import SelectV2 from 'js/components/Form/components/SelectV2';
import { useAppSelector } from 'js/utils/hooks';
import { RootState } from 'js/store/reducer';

interface Props {
  name: string;
  initialValue: string;
  label?: string;
  disabled?: boolean;
}

const SelectProofOfDelivery: FC<Props> = ({ name, initialValue, label, disabled }) => {
  const proofOfDeliveryOptions = useAppSelector(
    (state: RootState) => state.proofOfDeliveryOptions?.data
  );
  const proofOfDeliveryOptionsLoaded = useAppSelector(
    (state: RootState) => state.proofOfDeliveryOptions?.isLoaded
  );

  const NOT_CONFIGURED = 'NOT_CONFIGURED';
  const options =
    initialValue === NOT_CONFIGURED
      ? proofOfDeliveryOptionsUtil([NOT_CONFIGURED])
      : proofOfDeliveryOptionsUtil(proofOfDeliveryOptions);

  return (
    <SelectV2
      name={name}
      label={label}
      isLoading={!proofOfDeliveryOptionsLoaded}
      options={options}
      optional={false}
      disabled={initialValue === NOT_CONFIGURED || disabled}
    />
  );
};

export default SelectProofOfDelivery;
