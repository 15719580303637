const promises = {};

function load(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.onload = resolve;
    script.onerror = reject;

    script.charset = 'utf-8';
    script.async = true;
    script.src = src;

    document.head.appendChild(script);
  });
}
export default function loadScript(src) {
  if (!promises[src]) {
    promises[src] = load(src).catch((error) => {
      promises[src] = null;
      throw error;
    });
  }

  return promises[src];
}
