import PropTypes from 'prop-types';

import Icon from '../Icon';
import classNames from 'classnames';

import './index.sass';

const Button = ({
  handleClick,
  submit = false,
  value,
  loading,
  icon,
  name,
  nowrap,
  className,
  inverse = false,
  disabled = false,
  danger = false
}) => (
  <button
    onClick={handleClick}
    type={submit ? 'submit' : 'button'}
    value={value}
    className={classNames(
      'btn',
      className,
      { 'btn--disabled': disabled },
      { 'btn--inverse': inverse },
      { 'btn--danger': danger },
      { 'btn--primary': !danger && !inverse },
      { 'btn--nowrap': nowrap }
    )}
    disabled={disabled || loading}
    name={name}
  >
    {!loading && name}
    {loading && <Icon icon='spin' className='fa-spinner' />}
    {!loading && icon && <Icon icon={icon} />}
  </button>
);

Button.propTypes = {
  handleClick: PropTypes.func,
  submit: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.string,
  name: PropTypes.string,
  nowrap: PropTypes.bool,
  inverse: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any
};

export default Button;
