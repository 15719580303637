import Tag from 'js/components/Tag';
import StatusActions from './components/Actions';

import { findStatusInfo } from './utils';
import t from 'js/utils/translate';

import './index.sass';

const Status = ({ statusId, importFileId }) => {
  const status = findStatusInfo(statusId);

  const translateKeyPrefix = 'bulkOrder.table.status';

  if (status.requiresAction) {
    return (
      <div className='bulk-order-status'>
        <StatusActions
          actionType={status.actionType}
          importFileId={importFileId}
          translateKeyPrefix={translateKeyPrefix}
        />
      </div>
    );
  }

  return (
    <div className='bulk-order-status'>
      <Tag variant={status.variant} text={t(`${translateKeyPrefix}.${status.name}`)} />
    </div>
  );
};

export default Status;
