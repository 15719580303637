import { addDays, isAfter } from 'date-fns';

export const findMaxDate = (
  interval: number | undefined,
  fromDate: Date | undefined,
  maxDate: Date | undefined
): Date | undefined => {
  if (interval === undefined) {
    return maxDate;
  } else if (fromDate) {
    const intervalMaxDate = addDays(fromDate, interval - 1);
    if (maxDate) {
      if (isAfter(intervalMaxDate, maxDate)) return maxDate;
      else return intervalMaxDate;
    } else return intervalMaxDate;
  } else return maxDate;
};
