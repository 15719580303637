export const filterTransportSolutions = (transportSolutions, searchWord) =>
  transportSolutions?.filter((transportSolution) => {
    if (
      transportSolution?.transportSolutionId === +searchWord ||
      transportSolution?.name?.toLowerCase().includes(searchWord.toLowerCase()) ||
      transportSolution?.description?.toLowerCase().includes(searchWord.toLowerCase()) ||
      transportSolution?.ediCode?.toLowerCase().includes(searchWord.toLowerCase())
    ) {
      return transportSolution;
    }
    return false;
  });
