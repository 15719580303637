import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as rest from '../../api';

import { ApiPriceAdjustment } from 'src/interfaces/interfaces.generated';

export interface PriceAdjustmentsReducerState {
  error: unknown;
  data: ApiPriceAdjustment[];
  isLoaded: boolean;
  isAdding: boolean;
}

const initialState: PriceAdjustmentsReducerState = {
  error: null,
  data: [],
  isLoaded: false,
  isAdding: false
};

const priceAdjustmentSlice = createSlice({
  name: 'priceAdjustments',
  initialState,
  reducers: {
    getFuturePriceAdjustmentsRequest(state) {
      state.isLoaded = false;
    },
    getFuturePriceAdjustmentsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getFuturePriceAdjustmentsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    },
    addFuturePriceAdjustmentRequest(state) {
      state.isAdding = true;
    },
    addFuturePriceAdjustmentSuccess(state, action) {
      state.isAdding = false;
      state.data = action.payload;
    },
    addFuturePriceAdjustmentFailed(state, action) {
      state.isAdding = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  getFuturePriceAdjustmentsRequest,
  getFuturePriceAdjustmentsSuccess,
  getFuturePriceAdjustmentsFailed,
  addFuturePriceAdjustmentRequest,
  addFuturePriceAdjustmentSuccess,
  addFuturePriceAdjustmentFailed
} = priceAdjustmentSlice.actions;

export default priceAdjustmentSlice.reducer;

export const getFuturePriceAdjustments =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getFuturePriceAdjustmentsRequest());
      const response = await rest.getFuturePriceAdjustments();
      dispatch(getFuturePriceAdjustmentsSuccess(response));
      return true;
    } catch (err) {
      dispatch(getFuturePriceAdjustmentsFailed(err));
    }
  };

export const addFuturePriceAdjustment =
  (priceAdjustment: ApiPriceAdjustment) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(addFuturePriceAdjustmentRequest());
      const response = await rest.addFuturePriceAdjustment(priceAdjustment);
      dispatch(addFuturePriceAdjustmentSuccess(response));
      return true;
    } catch (err) {
      dispatch(addFuturePriceAdjustmentFailed(err));
    }
  };
