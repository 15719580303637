import { FC, useEffect, useMemo } from 'react';
import TableV2 from 'js/components/TableV2';
import t from 'js/utils/translate';
import {
  getShopProofOfDelivery,
  useShopProofOfDelivery
} from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/proofOfDeliveryReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import Spinner from 'js/components/Spinner';
import InfoBox from 'js/components/InfoBox';

import './index.sass';
import { ShopProofOfDelivery } from 'interfaces/interfaces.generated';

interface ProofOfDeliveryBodyProps {
  shopId: number | undefined;
}

const ProofOfDeliveryBody: FC<ProofOfDeliveryBodyProps> = ({ shopId }) => {
  const dispatch = useAppDispatch();

  const shopProofOfDeliveryState = useShopProofOfDelivery();
  const shopProofOfDeliveryData = shopProofOfDeliveryState.data;
  const isLoading = shopProofOfDeliveryState.isLoading;
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);

  useEffect(() => {
    if (shopId) {
      dispatch(getShopProofOfDelivery(shopId));
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: t('shop.accordion.proofOfDelivery.table.header.freightProduct'),
        accessor: 'freightProductName',
        inHeader: true
      },
      {
        Header: t('shop.accordion.proofOfDelivery.table.header.proofOfDelivery'),
        accessor: (pod: ShopProofOfDelivery) =>
          t('edit.freightProduct.proofOfDelivery.options.' + pod.shopProofOfDelivery),
        inHeader: true
      }
    ],
    []
  );

  if (!shopProofOfDeliveryData || isLoading) {
    return <Spinner />;
  }

  if (shopProofOfDeliveryData.length === 0) {
    return <InfoBox text={t('shop.accordion.empty', t('general.proofOfDelivery'))} />;
  }

  return (
    <TableV2
      className={isDesktop ? 'shop-proof-of-delivery-body' : ''}
      columns={columns}
      data={shopProofOfDeliveryData}
      mobileTableVariant='withSmallEditIcon'
    />
  );
};

export default ProofOfDeliveryBody;
