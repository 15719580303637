import { FC, useMemo } from 'react';
import { RootState } from 'js/store/reducer';
import TableV2 from 'js/components/TableV2';

import { ApiJob } from 'src/interfaces/interfaces.generated';
import t, { tu } from 'js/utils/translate';

import './index.sass';
import ButtonV2 from 'js/components/ButtonV2';
import {
  activateTemporaryDeactivatedJobs,
  deactivateAllJobsTemporary,
  getJobs
} from 'js/store/jobsReducer';
import PageWrapper from 'js/components/PageWrapper';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

interface Props {
  jobs: ApiJob[];
}

const JobsTable: FC<Props> = ({ jobs }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const columns = useMemo(
    () => [
      {
        Header: tu('general.id'),
        accessor: 'jobId',
        inHeader: true
      },
      {
        Header: tu('general.name'),
        accessor: 'jobName',
        inHeader: true
      },
      {
        Header: tu('general.description'),
        accessor: 'description',
        inHeader: true
      },
      {
        Header: tu('general.status'),
        accessor: 'status',
        inHeader: true
      },
      {
        Header: tu('jobHistory.lastStart'),
        accessor: 'lastExecutionDetails.start',
        inHeader: true
      },
      {
        Header: tu('jobHistory.lastStatus'),
        accessor: 'lastExecutionDetails.status',
        inHeader: true
      }
    ],
    []
  );

  const stopAllJobsForRelease = () => {
    dispatch(deactivateAllJobsTemporary()).then((success) => {
      if (success) {
        dispatch(getJobs());
      }
    });
  };

  const startAllJobsAfterRelease = () => {
    dispatch(activateTemporaryDeactivatedJobs()).then((success) => {
      if (success) {
        dispatch(getJobs());
      }
    });
  };

  const onClickRow = (jobId) => {
    navigate(`${jobId}`);
  };

  return (
    <>
      <PageWrapper>
        <div className='job-actions'>
          <ButtonV2
            onClick={stopAllJobsForRelease}
            text={t('job.stopAllJobsForRelease')}
            className='job-actions-stop-all'
          />
          <ButtonV2
            onClick={startAllJobsAfterRelease}
            text={t('job.startAllJobsAfterRelease')}
            className='job-actions-start-all'
          />
        </div>
      </PageWrapper>
      <TableV2
        className={isDesktop ? 'jobs-desktop-table' : 'jobs-mobile-table'}
        columns={columns}
        data={jobs}
        idAccessor='jobId'
        rowIcon='pencil-alt'
        onClickRow={onClickRow}
        goToLabel={t('job.edit')}
        mobileTableVariant='withLargeEditButton'
        pagination
      />
    </>
  );
};

export default JobsTable;
