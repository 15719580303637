import { FC, useEffect, useState } from 'react';
import FormV2 from 'js/components/FormV2';
import UserFormView from 'js/scenes/UserAdministration/UserActions/Connect/UserForm.view';
import { userAdministrationPath } from 'js/scenes/UserAdministration/utils/constants';
import { useConnectUserMutation } from 'js/scenes/UserAdministration/api/api';
import { usersList } from 'js/scenes/UserAdministration/api/queryKeys';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { UserFormProps as IUserForm } from 'js/scenes/UserAdministration/UserActions/Connect/UserForm.types';
import ValidationErrors from 'js/scenes/UserAdministration/UserActions/ValidationErrors';
import { UserType } from 'js/scenes/UserAdministration/Users.types';
import t from 'js/utils/translate';
import * as amplitude from '@amplitude/analytics-browser';
import { Amplitude } from 'js/scenes/UserAdministration/utils/amplitude';

const UserForm: FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [userType, setUserType] = useState<UserType>('flow');

  const initialValues: IUserForm = {
    type: userType,
    search: []
  };

  async function onMutationSuccess() {
    amplitude.track(Amplitude.USER_CONNECT_SUCCESS, Amplitude.getMetricProperties(userType));
    queryClient.removeQueries([usersList]);
    navigate(userAdministrationPath, {
      state: { type: userType, toastMessage: t('userAdministration.toast.connected') }
    });
  }

  const connectUser = useConnectUserMutation(onMutationSuccess);

  useEffect(() => {
    amplitude.track(Amplitude.USER_CONNECT_INITIALIZED, Amplitude.getMetricProperties());
  }, []);

  const handleSubmit = (values) => {
    const userIds = values.search?.map((user) => user.userId);
    connectUser.mutate({ userIds });
  };

  return (
    <>
      <FormV2
        initialValues={initialValues}
        onSubmit={handleSubmit}
        error={connectUser.error}
        loading={connectUser.isLoading}
        redirectTo={userAdministrationPath}
      >
        <UserFormView setUserType={setUserType} />
        <ValidationErrors translationPrefix={'userAdministration.connect.error'} />
      </FormV2>
    </>
  );
};

export default UserForm;
