import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as rest from '../../api';

import { ApiServicePoint } from 'src/interfaces/interfaces.generated';

export interface ServicePointsReducerState {
  error: unknown;
  areLoading: boolean;
  data: ApiServicePoint[];
}

const initialState: ServicePointsReducerState = {
  error: null,
  areLoading: false,
  data: []
};

const servicePointsSlice = createSlice({
  name: 'ServicePoints',
  initialState,
  reducers: {
    loadServicePointsRequest(state) {
      state.error = null;
      state.areLoading = true;
    },
    loadServicePointsSuccess(state, action) {
      state.areLoading = false;
      state.data = action.payload;
    },
    loadServicePointsFailed(state, action) {
      state.error = action.payload;
      state.areLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { loadServicePointsRequest, loadServicePointsSuccess, loadServicePointsFailed } =
  servicePointsSlice.actions;

export default servicePointsSlice.reducer;

export const loadServicePoints =
  (zip: string, countryCode: string, shopId: number, transportSolutionId: number) =>
  async (dispatch): Promise<ApiServicePoint[] | undefined> => {
    try {
      dispatch(loadServicePointsRequest());
      const response: ApiServicePoint[] = await rest.loadServicePoints(
        zip,
        countryCode,
        shopId,
        transportSolutionId
      );
      dispatch(loadServicePointsSuccess(response));
      return response;
    } catch (err) {
      dispatch(loadServicePointsFailed(err));
    }
  };
