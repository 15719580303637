import { FC } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';
import { isEmpty } from 'lodash';

import MessageForUser from 'js/components/MessageForUser';

import t from 'js/utils/translate';

import './index.sass';

const ValidationErrors: FC = () => {
  const context = useFormContext();
  const errors = context.getErrors();

  const filteredErrors = Object.keys(errors)
    ?.map((errorName) => {
      if (context.getTouched(errorName)) {
        return {
          errorName,
          errorType: errors[errorName]
        };
      }
      return undefined;
    })
    .filter(Boolean);

  if (isEmpty(errors)) return <></>;

  return (
    <div className='new-order__validation-errors'>
      {filteredErrors?.map((error) => (
        <MessageForUser
          key={error?.errorName}
          variant='error'
          message={t(
            'newOrder.validationError',
            t(`newOrder.validationError.message.${error?.errorType}`),
            t(`newOrder.validationError.key.${error?.errorName}`)
          )}
        />
      ))}
    </div>
  );
};

export default ValidationErrors;
