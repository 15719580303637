import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as rest from '../../api';

export interface HandleInvoiceBatchReducerState {
  id: null | number;
  error: unknown;
  isHandling: boolean;
}

const initialState = {
  id: null,
  error: null,
  isHandling: false
};

const handleInvoiceBatchSlice = createSlice({
  name: 'invoiceBatch',
  initialState,
  reducers: {
    approveInvoiceBatchRequest(state, action) {
      state.id = action.payload;
      state.error = null;
      state.isHandling = true;
    },
    approveInvoiceBatchSuccess(state) {
      state.isHandling = false;
    },
    approveInvoiceBatchFailed(state, action) {
      state.error = action.payload;
      state.isHandling = false;
    },
    disapproveInvoiceBatchRequest(state, action) {
      state.id = action.payload;
      state.error = null;
      state.isHandling = true;
    },
    disapproveInvoiceBatchSuccess(state) {
      state.isHandling = false;
    },
    disapproveInvoiceBatchFailed(state, action) {
      state.error = action.payload;
      state.isHandling = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  approveInvoiceBatchRequest,
  approveInvoiceBatchSuccess,
  approveInvoiceBatchFailed,
  disapproveInvoiceBatchRequest,
  disapproveInvoiceBatchSuccess,
  disapproveInvoiceBatchFailed
} = handleInvoiceBatchSlice.actions;

export default handleInvoiceBatchSlice.reducer;

export const approveInvoiceBatch = (invoiceBatchId) => async (dispatch) => {
  try {
    dispatch(approveInvoiceBatchRequest(invoiceBatchId));
    const response = await rest.approveInvoiceBatch(invoiceBatchId);
    dispatch(approveInvoiceBatchSuccess());
    return response;
  } catch (error) {
    dispatch(approveInvoiceBatchFailed(error));
  }
};

export const disapproveInvoiceBatch = (invoiceBatchId) => async (dispatch) => {
  try {
    dispatch(disapproveInvoiceBatchRequest(invoiceBatchId));
    const response = await rest.disapproveInvoiceBatch(invoiceBatchId);
    dispatch(disapproveInvoiceBatchSuccess());
    return response;
  } catch (error) {
    dispatch(disapproveInvoiceBatchFailed(error));
  }
};
