import { findShopById } from 'js/utils/find';
import t from 'js/utils/translate';
import { Option } from 'js/components/SelectV2/interfaces';
import { ApiServicePoint, ApiTransportSolution } from 'src/interfaces/interfaces.generated';

export const freightProductOptions = (freightProducts) =>
  freightProducts?.map((freightProduct) => ({
    value: freightProduct.freightProductId,
    label: freightProduct.name
  }));

export const concernOptions = (concerns) =>
  concerns?.map((concern) => ({
    value: concern.concernId,
    label: concern.name
  }));

export const serviceSupplierOptions = (serviceSuppliers) =>
  serviceSuppliers?.map((serviceSupplier) => ({
    value: serviceSupplier.id,
    label: serviceSupplier.name
  }));

export const shopOptions = (shops) =>
  shops?.map((shop) => ({ value: shop.shopId, label: shop.name }));

export const shopExternalIdShopOptions = (selectedShops, shops) =>
  selectedShops.map((selectedShop) => ({
    value: selectedShop.shopId,
    label: findShopById(shops, selectedShop.shopId).name
  }));

export const userOptions = (users) =>
  users.map((user) => ({ value: user.userId, label: user.userName, ...user }));

export const shopServiceSupplierOptions = (serviceSuppliers, shopId) =>
  serviceSuppliers
    .filter((serviceSupplier) =>
      serviceSupplier.shopAndExternalId.find((sne) => shopId === sne.shopId)
    )
    ?.map((serviceSupplier) => ({
      value: serviceSupplier.id,
      label: serviceSupplier.name
    }));

export const mainCarNumberOptions = (mainCarNumbers) =>
  mainCarNumbers?.map((mainCarNumber) => ({
    label: mainCarNumber,
    value: mainCarNumber
  }));

export const mainCarPlannedDepartureOptions = (mainCarPlannedDepartures) =>
  mainCarPlannedDepartures?.map((mainCarPlannedDeparture) => ({
    label: mainCarPlannedDeparture,
    value: mainCarPlannedDeparture
  }));

export const shortcutsOptions = (shortcuts) =>
  shortcuts?.map((shortcut) => ({
    value: shortcut.id,
    label: t(`general.${shortcut.value}`)
  }));

export const ownerPartiesOptions = (ownerParties) =>
  ownerParties?.map((ownerParty) => ({
    value: ownerParty.id,
    label: ownerParty.name
  }));

export const changeableEventTypeOptions = (changeableEventTypes) =>
  changeableEventTypes?.map((changeableEventType) => ({
    value: changeableEventType.eventTypeApiKey,
    label: t(changeableEventType.i18nKey)
  }));

export const transportSolutionOptions = (transportSolutions: ApiTransportSolution[]): Option[] =>
  transportSolutions.map(
    ({ name, transportSolutionId }) =>
      ({
        label: name,
        value: transportSolutionId
      }) as Option
  );

export const transportSolutionOptionsWithId = (
  transportSolutions: ApiTransportSolution[]
): Option[] =>
  transportSolutions.map(
    ({ name, transportSolutionId }) =>
      ({
        label: `${transportSolutionId} - ${name}`,
        value: transportSolutionId
      }) as Option
  );

export const servicePointOptionsWithId = (servicePoints: ApiServicePoint[]): Option[] =>
  servicePoints.map(
    ({ name, servicePointId }) =>
      ({
        label: `${servicePointId} - ${name}`,
        value: servicePointId
      }) as Option
  );

export const customerSystemOptions = (customerSystems) =>
  customerSystems.map((customerSystem) => ({
    value: customerSystem,
    label: customerSystem?.longName
  }));

export const webserviceOptions = (webservices) =>
  webservices.map((webservice) => ({
    value: webservice.webserviceId,
    label: t(`shop.accordion.apiUsers.webservices.${webservice.webserviceId}`)
  }));

export const languagesOptions = (languages) =>
  languages?.map((language) => ({
    value: language.value,
    label: t(`general.${language.label}`)
  }));

export const customUserFilter = (user, search) => {
  if (search) {
    const lowerCaseSearch = search.toLowerCase();
    const { email, userName, firstName, lastName } = user?.data;
    if (email?.toLowerCase().includes(lowerCaseSearch)) return true;
    if (userName?.toLowerCase().includes(lowerCaseSearch)) return true;
    if (`${firstName} ${lastName}`?.toLowerCase().includes(lowerCaseSearch)) return true;
    return false;
  }
  return true;
};

export const proofOfDeliveryOptionsUtil = (proofOfDeliveryOpts: string[]): Option[] =>
  proofOfDeliveryOpts.map(
    (option) =>
      ({
        label: t('edit.freightProduct.proofOfDelivery.options.'.concat(option)),
        value: option
      }) as Option
  );
