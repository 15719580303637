import { findSelectedShortcutFooterboxes } from 'js/scenes/Home/components/Shortcuts/utils';
import { routeAccess } from 'js/routes';
import { UserInfoShortcut } from 'src/interfaces/interfaces.generated';

export const updateUserRequestBody = (user, shortcuts, activeOwnerParty, language) => ({
  activeOwnerParty,
  language,
  userId: user.userId,
  shortcuts
});

export const accessibleShortcuts = (shortcuts, userRoles, activeOwnerParty): UserInfoShortcut[] => {
  const linksForShortcuts = findSelectedShortcutFooterboxes(
    shortcuts.map((shortcut) => shortcut.id)
  );
  const accessibleIds = linksForShortcuts
    .filter(({ link }) => routeAccess(link, userRoles, activeOwnerParty))
    .map((access) => access.id);

  return shortcuts.filter((shortcut) => accessibleIds.includes(shortcut.id));
};
