import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface PreTransportCarNumbersReducerState {
  error: unknown;
  isLoaded: boolean;
  data: string[];
}

const initialState: PreTransportCarNumbersReducerState = {
  error: null,
  isLoaded: false,
  data: []
};

const preTransportCarNumbersSlice = createSlice({
  name: 'PreTransportCarNumbers',
  initialState,
  reducers: {
    getPreTransportCarNumbersRequest(state) {
      state.error = null;
      state.isLoaded = false;
    },
    getPreTransportCarNumbersSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getPreTransportCarNumbersFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  }
});

export const {
  getPreTransportCarNumbersRequest,
  getPreTransportCarNumbersSuccess,
  getPreTransportCarNumbersFailed
} = preTransportCarNumbersSlice.actions;

export default preTransportCarNumbersSlice.reducer;

export const getPreTransportCarNumbers = () => async (dispatch) => {
  try {
    dispatch(getPreTransportCarNumbersRequest());
    const response: string[] = await rest.getPreTransportCarNumbers();
    dispatch(getPreTransportCarNumbersSuccess(response));
  } catch (err) {
    dispatch(getPreTransportCarNumbersFailed(err));
  }
};
