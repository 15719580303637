import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface CarDeparturesReducerState {
  error: unknown;
  isLoaded: boolean;
  data: string[];
}

const initialState: CarDeparturesReducerState = {
  error: null,
  isLoaded: false,
  data: []
};

const carDeparturesSlice = createSlice({
  name: 'CarDepartures',
  initialState,
  reducers: {
    getCarDeparturesRequest(state) {
      state.error = null;
      state.isLoaded = false;
    },
    getCarDeparturesSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getCarDeparturesFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  }
});

export const { getCarDeparturesRequest, getCarDeparturesSuccess, getCarDeparturesFailed } =
  carDeparturesSlice.actions;

export default carDeparturesSlice.reducer;

export const getCarDepartures = () => async (dispatch) => {
  try {
    dispatch(getCarDeparturesRequest());
    const response: string[] = await rest.getMainCarDepartures();
    dispatch(getCarDeparturesSuccess(response));
  } catch (err) {
    dispatch(getCarDeparturesFailed(err));
  }
};
