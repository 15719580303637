import { createSlice } from '@reduxjs/toolkit';

import { FreightProduct } from 'src/interfaces/interfaces.generated';

import * as api from '../../utils/rest';

export interface FreightProductsReducerState {
  error: unknown;
  data: FreightProduct[];
  isLoaded: boolean;
  isSaving: boolean;
}

const initialState: FreightProductsReducerState = {
  error: null,
  data: [],
  isLoaded: false,
  isSaving: false
};

const freightProductsSlice = createSlice({
  name: 'freightProducts',
  initialState,
  reducers: {
    getFreightProductsRequest(state) {
      state.isLoaded = false;
      state.error = null;
      state.data = [];
    },
    getFreightProductsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getFreightProductsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    },
    editFreightProductRequest(state) {
      state.isSaving = true;
    },
    editFreightProductSuccess(state, action) {
      state.isSaving = false;
      state.data = state.data.map((freightProduct: FreightProduct) =>
        freightProduct.freightProductId === action.payload.freightProductId
          ? { ...freightProduct, ...action.payload }
          : { ...freightProduct }
      );
      state.error = null;
    },
    editFreightProductFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    },
    resetFreightProductError(state) {
      state.error = null;
    }
  }
});

export const {
  getFreightProductsRequest,
  getFreightProductsSuccess,
  getFreightProductsFailed,
  editFreightProductRequest,
  editFreightProductSuccess,
  editFreightProductFailed,
  resetFreightProductError
} = freightProductsSlice.actions;

export default freightProductsSlice.reducer;

export const getFreightProducts =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getFreightProductsRequest());
      const response: FreightProduct[] = await api.getFreightProducts();
      dispatch(getFreightProductsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getFreightProductsFailed(error));
    }
  };

export const editFreightProduct =
  (freightProductId) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(editFreightProductRequest());
      const response: FreightProduct = await api.editFreightProduct(freightProductId);
      dispatch(editFreightProductSuccess(response));
      return true;
    } catch (error) {
      dispatch(editFreightProductFailed(error));
    }
  };
