import { useEffect, useMemo } from 'react';

import Spinner from 'js/components/Spinner';
import InfoBox from 'js/components/InfoBox';
import FreightProductExplanations from './components/FreightProductExplanations';
import LabeledItem from 'js/components/LabeledItem';
import TableV2 from 'js/components/TableV2';

import t, { tu } from 'js/utils/translate';

import './index.sass';
import {
  getShopFreightProducts,
  useShopFreightProducts
} from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/shopFreightProductReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

const translatePrefix = 'shop.accordion.freightProduct.table.header';

const FreightProductBody = ({ shopId, freightProducts }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);

  const shopFreightProductsState = useShopFreightProducts();
  const shopIdForFreightProducts = shopFreightProductsState.shopId;
  const shopFreightProducts = shopFreightProductsState.data;
  const shopFreightProductsLoading = shopFreightProductsState.isLoading;

  useEffect(() => {
    if (shopId !== shopIdForFreightProducts) dispatch(getShopFreightProducts(shopId));
  }, []);

  const goToEditPage = (freightProductId) => {
    navigate(`/economy/costPrices/edit/${shopId}/${freightProductId}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: t(`${translatePrefix}.freightProduct`),
        accessor: (shopFreightProduct) =>
          freightProducts.find(
            ({ freightProductId }) => freightProductId === shopFreightProduct.freightProductId
          )?.name,
        inHeader: true
      },
      {
        Header: t(`${translatePrefix}.prices`),
        accessor: ({ freightProductId }) => (
          <div
            className='link'
            onClick={() => {
              goToEditPage(freightProductId);
            }}
          >
            {t('shop.accordion.freightProduct.edit.cost.prices')}
          </div>
        )
      },
      {
        Header: t(`${translatePrefix}.label.recipient`),
        accessor: ({ orderConfirmationParty }) =>
          isDesktop ? (
            t(`shop.freightProduct.orderConfirmationParty.${orderConfirmationParty}`)
          ) : (
            <LabeledItem
              label={tu(`${translatePrefix}.label.recipient`)}
              item={t(`shop.freightProduct.orderConfirmationParty.${orderConfirmationParty}`)}
            />
          )
      },
      {
        Header: t(`${translatePrefix}.max.value`),
        accessor: isDesktop
          ? 'maxValue'
          : ({ maxValue }) => (
              <LabeledItem label={tu(`${translatePrefix}.max.value`)} item={maxValue} />
            )
      },
      {
        Header: t(`${translatePrefix}.weight`),
        accessor: isDesktop
          ? 'defaultWeightGrams'
          : ({ defaultWeightGrams }) => (
              <LabeledItem label={tu(`${translatePrefix}.weight`)} item={defaultWeightGrams} />
            )
      },
      {
        Header: t(`${translatePrefix}.active`),
        accessor: ({ accessible }) => {
          const isAccessible = accessible ? t('general.answer.yes') : t('general.answer.no');
          return isDesktop ? (
            isAccessible
          ) : (
            <LabeledItem label={tu(`${translatePrefix}.active`)} item={isAccessible} />
          );
        }
      }
    ],
    [isDesktop]
  );

  if (!shopFreightProducts || shopFreightProductsLoading) return <Spinner />;

  if (shopFreightProducts.length === 0) {
    return <InfoBox text={t('shop.accordion.empty', t('general.freightProducts'))} />;
  }

  return (
    <div className='shop-freight-products-body'>
      <FreightProductExplanations />
      <TableV2
        className={isDesktop ? '' : 'shop-freight-products-mobile-table'}
        columns={columns}
        data={shopFreightProducts}
        mobileTableVariant='withSmallEditIcon'
      />
    </div>
  );
};

export default FreightProductBody;
