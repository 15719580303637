import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import { shopAccordion } from './components/ShopAccordion';
import AccordionV2 from 'js/components/AccordionV2';
import Spinner from 'js/components/Spinner';
import NotFound from 'js/components/NotFound';
import StatusHeader from 'js/components/StatusHeader';

import { getShop } from './store/shopReducer';
import { ApiFlowShop } from 'src/interfaces/interfaces.generated';

import t, { tu } from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate, useParams } from 'react-router-dom';

const Shop: FC = () => {
  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const navigate = useNavigate();
  const { shopId } = useParams();

  const userRoles = useAppSelector((state: RootState) => state?.user?.data?.roles);

  const dispatch = useAppDispatch();

  const shop: ApiFlowShop = useAppSelector((state: RootState) => state?.freight?.shop?.shop?.data);
  const shopLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shop?.shop?.isLoaded
  );
  const error: boolean = !!useAppSelector((state: RootState) => state?.freight?.shop?.shop?.error);
  const freightProducts = useAppSelector((state: RootState) => state.freightProducts?.data);

  useEffect(() => {
    if (shopId) dispatch(getShop(+shopId));
  }, [shopId]);

  const canEditShop = userRoles.di || userRoles.parcelAdmin;

  if (!shopLoaded && !error) return <Spinner />;
  if (error) return <NotFound />;

  return (
    <div className='shop-page'>
      {!shopLoaded ? (
        <Spinner />
      ) : (
        <>
          <StatusHeader
            color={shop.requiredSetupComplete ? 'green' : 'yellow'}
            statusText={
              shop.requiredSetupComplete ? tu('shop.setup.complete') : tu('shop.setup.not.complete')
            }
            info={
              shop.requiredSetupComplete
                ? tu('shop.setup.complete.info')
                : tu('shop.setup.not.complete.info')
            }
            buttons={[
              {
                text: t('shop.remove.button'),
                onClick: () => {
                  navigate(`/freight/shop/${shop.shopId}/remove`);
                },
                variant: 'inverse'
              }
            ]}
          />
          <AccordionV2
            name='shopAccordion'
            accordion={shopAccordion(shop, freightProducts, canEditShop, navigate)}
            paddingSize={isDesktop ? 'large' : 'small'}
            withToggleAllOption
          />
        </>
      )}
    </div>
  );
};

export default Shop;
