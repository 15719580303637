import { FC } from 'react';
import Spinner from 'js/components/Spinner';
import Error from 'js/components/Error';

interface Props {
  isLoading?: boolean;
  isError?: boolean;
  errorComponent?: any;
  children: any;
}

const LoadComponent: FC<Props> = ({ children, isLoading, isError, errorComponent = <Error /> }) => {
  if (isLoading) {
    return <Spinner />;
  } else if (isError) {
    return errorComponent;
  } else {
    return children;
  }
};

export default LoadComponent;
