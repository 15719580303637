import Icon from 'js/components/Icon';
import Mottaker from 'images/icons/Mottaker.svg';
import Avsender from 'images/icons/Avsender.svg';

import CopyText from 'js/components/CopyText';

import { findElementFromAccessor } from './utils';

import './index.sass';

const data = [
  {
    accessor: 'consignor.name',
    iconType: 'svg',
    icon: <Avsender />
  },
  {
    accessor: 'shipmentId',
    iconType: 'icon',
    icon: 'tags'
  },
  {
    accessor: 'consignee.name',
    iconType: 'svg',
    icon: <Mottaker />
  },
  {
    accessor: 'trackingReference',
    variant: 'copy',
    iconType: 'icon',
    icon: 'truck'
  },
  {
    accessor: 'pickup.pickupCode',
    iconType: 'icon',
    icon: 'truck'
  }
];

const MobileTable = ({ orders, onClickRow }) => (
  <div className='page-padding order-table-mobile'>
    {orders.map((order) => (
      <div className='order' key={order.shipmentId} onClick={(e) => onClickRow(order, e)}>
        {data.map(
          (element) =>
            findElementFromAccessor(order, element?.accessor) && (
              <div className='data' key={element?.accessor}>
                {element.iconType === 'icon' ? (
                  <Icon icon={element.icon} />
                ) : (
                  <span className='svg-icon'>{element.icon}</span>
                )}
                {element.variant === 'copy' ? (
                  <CopyText text={findElementFromAccessor(order, element?.accessor)} />
                ) : (
                  <span>{findElementFromAccessor(order, element?.accessor)}</span>
                )}
              </div>
            )
        )}
      </div>
    ))}
  </div>
);

export default MobileTable;
