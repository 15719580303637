import { FC, useEffect } from 'react';
import { useMatch } from 'react-router';
import { RootState } from 'js/store/reducer';

import SelectShopUser from './components/SelectShopUser';
import SelectServiceSupplierUser from './components/SelectServiceSupplierUser';

import { getUsers } from 'js/scenes/Freight/store/usersReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  isApiContext?: boolean;
  setSelectedUser;
}

const SelectUser: FC<Props> = ({ isApiContext, setSelectedUser }) => {
  const dispatch = useAppDispatch();
  const route = useMatch('servicesupplier');

  const userContext = route ? 'serviceSupplier' : 'shop';

  const authUsers = useAppSelector((state: RootState) => state?.freight?.users?.data);
  const authUsersLoaded = useAppSelector((state: RootState) => state?.freight?.users?.isLoaded);

  useEffect(() => {
    if (!authUsersLoaded) dispatch(getUsers());
  }, []);

  if (userContext === 'shop') {
    return (
      <SelectShopUser
        isApiContext={isApiContext}
        authUsers={authUsers}
        setSelectedUser={setSelectedUser}
      />
    );
  }

  if (userContext === 'serviceSupplier') {
    return <SelectServiceSupplierUser authUsers={authUsers} setSelectedUser={setSelectedUser} />;
  }

  return <></>;
};

export default SelectUser;
