import flatten from 'lodash/flatten';

import { ApiConcern, ApiFlowShopSimple } from 'src/interfaces/interfaces.generated';

export const filterConcerns = (
  concerns: ApiConcern[],
  shops: ApiFlowShopSimple[],
  search: string
): ApiConcern[] =>
  concerns?.filter(
    (concern) =>
      concern?.shopIds?.some(
        (shopId) =>
          shops?.find((shop) =>
            shop?.shopId === shopId && shop?.name?.toLowerCase().includes(search)
              ? concern
              : concern?.name?.toLowerCase().includes(search)
          )
      )
  );

export const shopsNotInExistingConcern = (
  concerns: ApiConcern[],
  shops: ApiFlowShopSimple[],
  concernId: number
): ApiFlowShopSimple[] => {
  const shopsInOtherConcerns = flatten(
    concerns.map((concern) =>
      concern.concernId !== +concernId && concern.shopIds ? concern.shopIds : []
    )
  );
  return shops.filter((shop) => !shopsInOtherConcerns.includes(shop.shopId));
};
