import { FC } from 'react';
import { isEmpty } from 'lodash';
import { useLocation, useMatch } from 'react-router-dom';
import { RootState } from 'js/store/reducer';

import t from 'js/utils/translate';

import { findLocationHeaderFromParams } from './utils';
import { findRouteName, findPath } from 'js/routes';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

const LocationHeader: FC = () => {
  const shops = useAppSelector((state: RootState) => state?.freight?.shops?.data);

  const location = useLocation();
  const match = useMatch('edit|new|remove');
  const routeName = findRouteName(location.pathname);

  // Extract parameters from the path
  const path = findPath(location.pathname);
  const params = useMatch(path ?? '')?.params;

  const locationHeaderFromParams = findLocationHeaderFromParams(params, shops);

  if (match) {
    return (
      <h1 className='location-header'>{t(`locationHeader.${routeName.replace(/\s/g, '')}`)}</h1>
    );
  }

  if (!isEmpty(params) && locationHeaderFromParams) {
    return <h1 className='location-header'>{locationHeaderFromParams}</h1>;
  }

  return <h1 className='location-header'>{t(`locationHeader.${routeName.replace(/\s/g, '')}`)}</h1>;
};

export default LocationHeader;
