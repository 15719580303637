import { useMemo } from 'react';

import Spinner from 'js/components/Spinner';
import CommaSeparatedString from 'js/components/CommaSeparatedNames/components/CommaSeparatedString';
import TableV2 from 'js/components/TableV2';

import { getShop } from '../../scenes/Shop/store/shopReducer';
import t, { tu } from 'js/utils/translate';
import { findRouteByName, routeAccess } from 'js/routes';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

const ShopsTable = ({ shops, shopsLoaded }) => {
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);
  const userRoles = useAppSelector((state) => state?.user?.data?.roles);
  const activeOwnerParty = useAppSelector((state) => state?.user?.data?.activeOwnerParty);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const route = findRouteByName('Shop');
  const hasShopAccess = routeAccess(route.path, userRoles, activeOwnerParty);

  const columns = useMemo(
    () => [
      {
        Header: tu('id'),
        accessor: 'shopId',
        inHeader: true
      },
      {
        Header: tu('shop.name'),
        accessor: 'name',
        inHeader: true
      },
      {
        Header: tu('api.access'),
        accessor: (shop) => (
          <CommaSeparatedString data={shop?.users?.map((user) => ({ name: user }))} />
        )
      }
    ],
    []
  );

  const goToShop = (shopId) => {
    if (hasShopAccess) {
      dispatch(getShop(shopId)).then((response) => {
        if (response) navigate(`shop/${shopId}`);
      });
    }
  };

  if (!shopsLoaded) return <Spinner />;

  return (
    <TableV2
      className={isDesktop ? 'page-padding' : ''}
      columns={columns}
      data={shops}
      goToLabel={t('freight.shop.go.to.details')}
      onClickRow={goToShop}
      idAccessor='shopId'
      mobileTableVariant='withSmallEditIcon'
      pagination
    />
  );
};

export default ShopsTable;
