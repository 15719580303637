import { FC, useEffect } from 'react';

import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import FormV2 from 'js/components/FormV2';
import { RootState } from 'js/store/reducer';
import { findJobById } from 'js/utils/find';
import cloneDeep from 'lodash/cloneDeep';
import Spinner from 'js/components/Spinner';
import NotFound from 'js/components/NotFound';
import Input from 'js/components/Form/components/Input';
import TextArea from 'js/components/Form/components/TextArea';
import t, { tu } from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import {
  activateJobSchedule,
  deactivateJobSchedule,
  editJob,
  getJob,
  getJobHistory,
  resetJobError,
  triggerJobExecution
} from 'js/store/jobsReducer';

import './index.sass';
import ButtonV2 from 'js/components/ButtonV2';
import { JobHistoryEntry } from 'interfaces/interfaces.generated';
import JobHistoryTable from 'js/scenes/Jobs/components/JobHistoryTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';

const JobDetail: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const redirectTo = '/jobs';

  const jobs = useAppSelector((state: RootState) => state.jobs.data);
  const jobsLoaded = useAppSelector((state: RootState) => state.jobs?.isLoaded);
  const error = useAppSelector((state: RootState) => state?.jobs?.error);

  const job = findJobById(jobs, params.jobId);

  const jobHistory: JobHistoryEntry[] = useAppSelector(
    (state: RootState) => state.jobs?.currentJobHistory
  );
  const jobHistoryLoaded = useAppSelector((state: RootState) => state.jobs?.isHistoryLoaded);

  useEffect(() => {
    dispatch(resetJobError());
    dispatch(getJobHistory(params.jobId));
  }, [params.jobId]);

  const handleSubmit = (values) => {
    const updatedJob = cloneDeep(values);

    dispatch(editJob(updatedJob)).then((success) => {
      if (success) {
        location.state ? navigate(-1) : navigate(redirectTo);
      }
    });
  };

  const reloadJob = () => {
    dispatch(getJob(params.jobId));
    dispatch(getJobHistory(params.jobId));
  };

  const activateSchedule = () => {
    dispatch(activateJobSchedule(job)).then((success) => {
      if (success) {
        reloadJob();
      }
    });
  };

  const deactivateSchedule = () => {
    dispatch(deactivateJobSchedule(job)).then((success) => {
      if (success) {
        reloadJob();
      }
    });
  };

  const triggerExecution = () => {
    dispatch(triggerJobExecution(job)).then((success) => {
      if (success) {
        reloadJob();
      }
    });
  };

  if ((!jobsLoaded || !jobHistoryLoaded) && !error) return <Spinner />;
  if (error && !job?.jobId) return <NotFound />;

  return (
    <SplitPage>
      <PageWrapper className='job-detail'>
        <FormV2 onSubmit={handleSubmit} initialValues={job} redirectTo={redirectTo} error={error}>
          <div className='job-detail__form-children-wrapper'>
            <Input name='jobName' label={tu('general.name')} autoFocus />
            <Input name='javaClass' label={tu('general.javaClass')} readOnly />
            <TextArea
              name='description'
              label={tu('general.description')}
              className='job-description__text-area'
            />
            <Input name='status' label={tu('general.status')} readOnly />
          </div>
        </FormV2>
        <div className='job-actions'>
          <ButtonV2
            onClick={activateSchedule}
            text={t('job.activateSchedule')}
            className='job-actions-activate'
          />
          <ButtonV2
            onClick={deactivateSchedule}
            text={t('job.deactivateSchedule')}
            className='job-actions-deactivate'
          />
          <ButtonV2
            onClick={triggerExecution}
            text={t('job.triggerExecution')}
            className='job-actions-trigger'
          />
        </div>
      </PageWrapper>
      <JobHistoryTable jobHistory={jobHistory} />
    </SplitPage>
  );
};
export default JobDetail;
