import Ajax from './ajax/ajax';

import { Credentials, LoginToken } from '../scenes/Login/interfaces';

export const login = (credentials: Credentials): Promise<LoginToken> =>
  Ajax.post('/login/v1', credentials);

export const logout = () => Ajax.post('/login/v1/logout');

export const getUser = () => Ajax.get('/userinfo/v1');

export const updateUser = (user) => Ajax.put('/userinfo/v1', user);

export const getShortcuts = () => Ajax.get('/userinfo/v1/shortcuts');

export const getOwnerParties = () => Ajax.get('/ownerparties/v1');

export const getFreightProducts = () => Ajax.get('/freightproducts/v1');

export const editFreightProduct = (freightProduct) =>
  Ajax.patch(`/freightproducts/v2/${freightProduct.freightProductId}`, freightProduct);

export const getJobs = () => Ajax.get('/scheduling/v1/jobs');
export const getJob = (jobId) => Ajax.get(`/scheduling/v1/jobs/${jobId}`);
export const getJobHistory = (jobId) => Ajax.get(`/scheduling/v1/jobs/${jobId}/history?limit=100`);

export const editJob = (job) => Ajax.put(`/scheduling/v1/jobs`, job);

export const activateJobSchedule = (job) => Ajax.post(`/scheduling/v1/jobs/${job.jobId}/activate`);
export const deactivateJobSchedule = (job) =>
  Ajax.post(`/scheduling/v1/jobs/${job.jobId}/deactivate`);
export const deactivateAllJobsTemporary = () =>
  Ajax.post(`/scheduling/v1/jobs/deactivate?mode=TEMP`);
export const activateTemporaryDeactivatedJobs = () =>
  Ajax.post(`/scheduling/v1/jobs/activate?mode=TEMP`);
export const triggerJobExecution = (job) => Ajax.post(`/scheduling/v1/jobs/${job.jobId}/execute`);

export const newShop = (shop) => Ajax.post('/shops/v1', shop);

export const getConcerns = () => Ajax.get('/shops/v1/concerns');

export const getTransportSolutions = () => Ajax.get('/transportsolutions/v1');

export const newTransportSolution = (transportSolution) =>
  Ajax.post('/transportsolutions/v1', transportSolution);

export const editTransportSolution = (transportSolution) =>
  Ajax.patch(`/transportsolutions/v1/${transportSolution.transportSolutionId}`, transportSolution);

export const downloadLabels = (url) => Ajax.getFileBlob(url);

export const getProofOfDeliveries = () => Ajax.get('/freightproducts/v2/proofofdelivery');
