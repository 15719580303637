import { FC } from 'react';
import classNames from 'classnames';

import { Toggle as ToggleType } from '../../interfaces';

import './index.sass';

interface Props extends ToggleType {
  handleChange: () => void;
  value?: any;
}

const Toggle: FC<Props> = ({
  className,
  name,
  label,
  sublabel,
  value = false,
  handleChange,
  disabled
}) => (
  <div
    className={classNames('input-toggle', className, {
      'input-toggle--disabled': disabled
    })}
  >
    <input
      disabled={disabled}
      className='toggle-checkbox'
      id={name}
      checked={value}
      value={value}
      name={name}
      type='checkbox'
      onChange={handleChange}
    />
    <label
      className={classNames(
        'toggle-label',
        { 'toggle-label--disabled': disabled },
        { 'toggle-label--on': value }
      )}
      htmlFor={name}
    >
      <span className='toggle-button' />
    </label>
    <div className='input-toggle__label'>
      <span>{label}</span>
      <span className='label__sublabel'>{sublabel}</span>
    </div>
  </div>
);

export default Toggle;
