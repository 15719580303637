import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../api';
import { locationChanged } from 'js/store/actions';

export interface ExtendPickupDeadlineReducerState {
  error: unknown;
  isExtended: boolean;
  isExtending: boolean;
}

const initialState: ExtendPickupDeadlineReducerState = {
  error: null,
  isExtended: false,
  isExtending: false
};

const extendPickupDeadlineSlice = createSlice({
  name: 'ExtendPickupDeadline',
  initialState,
  reducers: {
    extendPickupDeadlineRequest(state) {
      state.error = null;
      state.isExtended = false;
      state.isExtending = true;
    },
    extendPickupDeadlineSuccess(state) {
      state.isExtended = true;
      state.isExtending = false;
    },
    extendPickupDeadlineFailed(state, action) {
      state.isExtended = false;
      state.isExtending = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  extendPickupDeadlineRequest,
  extendPickupDeadlineSuccess,
  extendPickupDeadlineFailed
} = extendPickupDeadlineSlice.actions;

export default extendPickupDeadlineSlice.reducer;

export const extendPackagePickupDeadline =
  (checksum: string) =>
  async (dispatch): Promise<undefined | boolean> => {
    try {
      dispatch(extendPickupDeadlineRequest());
      await api.extendPackagePickupDeadline(checksum);
      dispatch(extendPickupDeadlineSuccess());
      return true;
    } catch (err) {
      dispatch(extendPickupDeadlineFailed(err));
    }
  };
