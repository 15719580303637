import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';
import { ApiCustomerCommunication } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';

export interface CustomerCommunicationReducerState {
  error: unknown;
  isLoaded: boolean;
  isSaving: boolean;
  data: ApiCustomerCommunication;
  shopId: number | undefined;
}

const initialState: CustomerCommunicationReducerState = {
  error: null,
  isLoaded: false,
  isSaving: false,
  data: {
    customerFriendlyName: '',
    customerMessages: []
  },
  shopId: undefined
};

const CustomerCommunicationSlice = createSlice({
  name: 'CustomerCommunication',
  initialState,
  reducers: {
    getCustomerCommunicationRequest(state, action) {
      state.error = null;
      state.data = {
        customerFriendlyName: '',
        customerMessages: []
      };
      state.isLoaded = false;
      state.shopId = action.payload;
    },
    getCustomerCommunicationSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getCustomerCommunicationFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    },
    editCustomerCommunicationRequest(state, action) {
      state.isSaving = true;
      state.error = null;
      state.shopId = action.payload;
    },
    editCustomerCommunicationSuccess(state, action) {
      state.isSaving = false;
      state.data = action.payload;
    },
    editCustomerCommunicationFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  getCustomerCommunicationRequest,
  getCustomerCommunicationSuccess,
  getCustomerCommunicationFailed,
  editCustomerCommunicationRequest,
  editCustomerCommunicationSuccess,
  editCustomerCommunicationFailed
} = CustomerCommunicationSlice.actions;

export default CustomerCommunicationSlice.reducer;

export const getCustomerCommunication =
  (shopId) =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(getCustomerCommunicationRequest(shopId));
      const response: ApiCustomerCommunication = await api.getCustomerCommunication(shopId);
      dispatch(getCustomerCommunicationSuccess(response));
    } catch (error) {
      dispatch(getCustomerCommunicationFailed(error));
    }
  };

export const editCustomerCommunication =
  (shopId, customerCommunication) =>
  async (dispatch): Promise<boolean> => {
    try {
      dispatch(editCustomerCommunicationRequest(shopId));
      const response: ApiCustomerCommunication = await api.editCustomerCommunication(
        shopId,
        customerCommunication
      );
      dispatch(editCustomerCommunicationSuccess(response));
      return true;
    } catch (error) {
      dispatch(editCustomerCommunicationFailed(error));
      return false;
    }
  };
