import AjaxError from './ajaxError';
import { getCached, postCached, resetCache } from './cache';
import store from '../../store/store';
import { encode, getCookie, removeJwtTokenCookie, stringify } from './utils';
import { get as localStorageGet } from '../storage';
import { saveAs } from 'file-saver';
import trim from 'lodash/trim';
import * as Sentry from '@sentry/react';

const TIMEOUT = 4 * 60 * 1000;

const { FLOW_AUTH_URL, FLOW_API_URL, AUTH_URL } = process.env;

export default class Ajax {
  static get(url, params) {
    return new Ajax().ajax('GET', url + encode(params));
  }

  static post(url, data) {
    return new Ajax().ajax('POST', url, data);
  }

  static put(url, data) {
    return new Ajax().ajax('PUT', url, data);
  }

  static patch(url, data) {
    return new Ajax().ajax('PATCH', url, data);
  }

  static jsonPatch(url, data) {
    return new Ajax().ajax('PATCH', url, JSON.stringify(data), 'application/json-patch+json');
  }

  static delete(url, data) {
    return new Ajax().ajax('DELETE', url, data);
  }

  static postMultiPart(url, data) {
    return new Ajax().ajax('POST', url, data, 'multipart/form-data');
  }

  static getFileBlob(url) {
    return new Ajax().downloadFile('GET', url);
  }

  static getCached(url, options) {
    return getCached(url, options);
  }

  static postCached(url, data, options) {
    return postCached(url, data, options);
  }

  static resetCache() {
    resetCache();
  }

  downloadFile(method, url) {
    const correctUrl = `${FLOW_API_URL}/${url}`;

    this.method = method;
    this.url = correctUrl;

    this.xhr = new XMLHttpRequest();
    this.xhr.timeout = TIMEOUT;
    this.xhr.onreadystatechange = this.handleStateChange.bind(this);
    this.xhr.onerror = this.handleError.bind(this);
    this.xhr.ontimeout = this.handleTimeout.bind(this);
    this.xhr.setRequestHeaders = this.setRequestHeaders.bind(this);

    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
      this.resolve = resolve;

      this.reject = (error) => {
        console.log(error);
        if (error.status === 401) {
          removeJwtTokenCookie();
        }
        Sentry.captureException(error);
        reject(error);
      };

      this.xhr.open(this.method, this.url);

      this.setRequestHeaders();

      this.xhr.setRequestHeader('Content-Type', 'application/pdf; charset=UTF-8');

      this.xhr.onload = function () {
        if (this.status === 200) {
          const blob = new Blob([this.response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          let filename = '';
          const disposition = this.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
              filename = trim(filename, [']', ' ']);
            }
          }
          saveAs(blob, filename);
        } else {
          return reject(JSON.parse(this.responseText));
        }
      };
      this.xhr.send();
    });
  }

  ajax(method, url, data, contentType) {
    const correctUrl = this.setRequestUrl(url);

    this.method = method;
    this.url = correctUrl;
    this.data = data;

    this.xhr = new XMLHttpRequest();
    this.xhr.timeout = TIMEOUT;
    this.xhr.onload = this.handleLoad.bind(this);
    this.xhr.ontimeout = this.handleTimeout.bind(this);
    this.xhr.onerror = this.handleError.bind(this);
    this.xhr.setRequestHeaders = this.setRequestHeaders.bind(this);

    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = (error) => {
        console.log(error);
        if (error.status === 401 && !this.url.includes('login')) {
          removeJwtTokenCookie();
          window.location.replace('/login');
        }
        Sentry.captureException(error);
        reject(error);
      };

      this.xhr.open(this.method, this.url);

      this.setRequestHeaders();

      if (contentType === 'multipart/form-data') {
        // Content-type is set automatically by the browser as it requires boundary.
        this.xhr.send(this.data);
      } else if (contentType) {
        this.xhr.setRequestHeader('Content-type', contentType);
        this.xhr.send(this.data);
      } else {
        this.xhr.setRequestHeader('Content-type', 'application/json');
        this.xhr.send(stringify(this.data));
      }
    });
  }

  handleStateChange() {
    if (this.xhr.status >= 200 && this.xhr.status < 300) {
      if (this.xhr.readyState <= 2) this.xhr.responseType = 'blob';
      try {
        return this.resolve();
      } catch (error) {
        this.reject(error);
      }
    }
  }

  handleLoad() {
    if (this.xhr.status >= 200 && this.xhr.status < 300) {
      this.handleResponse();
    } else {
      this.handleError();
    }
  }

  handleResponse() {
    let response = this.xhr.responseText;
    try {
      response = response && JSON.parse(response);
      this.resolve(response);
    } catch (error) {
      this.reject(error);
    }
  }

  handleError() {
    this.reject(new AjaxError(this));
  }

  handleTimeout() {
    this.reject(new AjaxError(this, true));
  }

  setRequestHeaders() {
    const authenticated = store?.getState()?.authentication?.isAuthenticated ?? false;

    const activeOwnerParty =
      store?.getState().user?.user?.activeOwnerParty ?? localStorageGet('activeOwnerParty');

    const jwtToken = getCookie('jwt-token') ?? false;

    if (jwtToken && authenticated) {
      this.xhr.setRequestHeader('Authorization', `Bearer ${jwtToken}`);
      this.xhr.setRequestHeader('OwnerParty-Id', activeOwnerParty);
    }
  }

  setRequestUrl(url) {
    if (url.startsWith('/userAdmin')) {
      const activeOwnerParty =
        store?.getState()?.user?.user?.activeOwnerParty ?? localStorageGet('activeOwnerParty');
      return `${AUTH_URL}/${activeOwnerParty}${url}`;
    } else if (url.startsWith('/login') || url.startsWith('/logout')) {
      return FLOW_AUTH_URL + url;
    }
    return FLOW_API_URL + url;
  }
}
