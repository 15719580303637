import ShopInfoBody from './components/ShopInfoBody';
import ConfigurationBody from './components/ConfigurationBody';
import CustomerSystemBody from './components/CustomerSystemBody';
import FreightProductBody from './components/FreightProductBody';
import ShopTransportSolutionsBody from './components/ShopTransportSolutionsBody';
import EconomyBody from './components/EconomyBody';
import ShopApiUsersBody from './components/ShopApiUsersBody';
import ShopUsersBody from './components/ShopUsersBody';

import { ApiFlowShop } from 'src/interfaces/interfaces.generated';

import t from 'js/utils/translate';
import CustomerCommunicationBody from './components/CustomerCommunicationBody';
// eslint-disable-next-line max-len
import ProofOfDeliveryBody from 'js/scenes/Freight/scenes/Shops/scenes/Shop/components/ShopAccordion/components/ProofOfDeliveryBody';

export const shopAccordion = (shop: ApiFlowShop, freightProducts, canEditShop, navigate) => [
  {
    title: t('shop.accordion.header.shopInfo'),
    info: t('shopAccordion.info.shop'),
    canEdit: canEditShop,
    onEditClick: () => {
      if (shop?.shopId) navigate(`/freight/shop/${shop.shopId}/edit/shopInfo`);
    },
    expanded: true,
    body: <ShopInfoBody shop={shop} />
  },
  {
    title: t('shop.accordion.header.customerSystem'),
    info: t('shopAccordion.info.customerSystem'),
    canEdit: canEditShop,
    onEditClick: () => {
      if (shop?.shopId) navigate(`/freight/shop/${shop.shopId}/edit/customerSystem`);
    },
    body: <CustomerSystemBody customerSystemId={shop?.customerSysId} />
  },
  {
    title: t('shop.accordion.header.transportSolutions'),
    info: t('shopAccordion.info.shopTransportSolutions'),
    canEdit: canEditShop,
    onEditClick: () => {
      if (shop?.shopId) navigate(`/freight/shop/${shop.shopId}/edit/transportSolutions`);
    },
    body: <ShopTransportSolutionsBody shopId={shop.shopId} freightProducts={freightProducts} />
  },
  {
    title: t('shop.accordion.header.freightProducts'),
    info: t('shopAccordion.info.shopFreightProducts'),
    canEdit: true,
    onEditClick: () => {
      if (shop?.shopId) navigate(`/freight/shop/${shop.shopId}/edit/freightProducts`);
    },
    body: <FreightProductBody shopId={shop?.shopId} freightProducts={freightProducts} />
  },
  {
    title: t('shop.accordion.header.proofOfDelivery'),
    info: t('shopAccordion.info.proofOfDelivery'),
    canEdit: true,
    onEditClick: () => {
      if (shop?.shopId) navigate(`/freight/shop/${shop.shopId}/edit/proofOfDelivery`);
    },
    body: <ProofOfDeliveryBody shopId={shop?.shopId} />
  },
  {
    title: t('shop.accordion.header.customerCommunication'),
    info: t('shopAccordion.info.customerCommunication'),
    canEdit: true,
    onEditClick: () => {
      if (shop?.shopId) navigate(`/freight/shop/${shop.shopId}/edit/customerCommunication`);
    },
    body: <CustomerCommunicationBody shop={shop} />
  },
  {
    title: t('shop.accordion.header.economy'),
    info: t('info.regarding', t('general.economy')),
    canEdit: canEditShop,
    onEditClick: () => {
      if (shop?.shopId) navigate(`/freight/shop/${shop.shopId}/edit/economy`);
    },
    body: <EconomyBody shop={shop} />
  },
  {
    title: t('shop.accordion.header.api'),
    info: t('shop.accordion.info.apiUsers'),
    canEdit: canEditShop,
    onEditClick: () => {
      if (shop?.shopId) navigate(`/freight/shop/${shop.shopId}/edit/api`);
    },
    body: <ShopApiUsersBody shopId={shop?.shopId} />
  },
  {
    title: t('shop.accordion.header.configurations'),
    info: t('shopAccordion.info.configurations'),
    canEdit: canEditShop,
    onEditClick: () => {
      if (shop?.shopId) navigate(`/freight/shop/${shop.shopId}/edit/configurations`);
    },
    body: <ConfigurationBody shop={shop} />
  },
  {
    title: t('shop.accordion.header.users'),
    info: t('shop.accordion.info.users'),
    canEdit: canEditShop,
    onEditClick: () => {
      if (shop?.shopId) navigate(`/freight/shop/${shop.shopId}/edit/users`);
    },
    body: <ShopUsersBody shopId={shop?.shopId} />
  }
];
