import { FC } from 'react';

import FormV2 from 'js/components/FormV2';
import Input from 'js/components/Form/components/Input';
import Datepicker from 'js/components/Form/components/Datepicker';
import Text from 'js/components/Form/components/Text';

import { tomorrow } from 'js/utils/dateTime';

import SelectShops from './components/SelectShops';
import SelectConcerns from './components/SelectConcerns';
import SelectFreightProducts from './components/SelectFreightProducts';
import PriceAdjustmentInfo from './components/PriceAdjustmentInfo';
import Spinner from 'js/components/Spinner';

import { tu } from 'js/utils/translate';
import { toFloat } from '@distribution-innovation/form-hooks';

import { ApiPriceAdjustment } from 'src/interfaces/interfaces.generated';
import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  initialValues?: ApiPriceAdjustment;
  onSubmit?: any;
  type: 'save' | 'create';
  disabled?: boolean;
}

const PriceAdjustmentForm: FC<Props> = ({ initialValues, onSubmit, type, disabled }) => {
  const error = useAppSelector((state: RootState) => state?.economy?.priceAdjustments?.error);
  const isAdding = useAppSelector((state: RootState) => state?.economy?.priceAdjustments?.isAdding);

  if (!initialValues) return <Spinner />;

  return (
    <FormV2
      className='price-adjustment-form'
      initialValues={initialValues}
      actions
      type={type}
      onSubmit={onSubmit}
      disabled={disabled}
      error={error}
      loading={isAdding}
    >
      <SelectShops name='shopIds' disabled={disabled} />
      <SelectConcerns name='concernIds' disabled={disabled} />
      <SelectFreightProducts name='freightProductIds' disabled={disabled} />
      <div className='row'>
        <Datepicker
          label={tu('economy.priceAdjustments.dateFrom')}
          name='dateFrom'
          disabled={disabled}
          onlyFutureDates
          minDate={tomorrow()}
        />
        <Input
          type='number'
          step='.01'
          convert={toFloat}
          label={tu('economy.priceAdjustments.change.percentage')}
          name='change'
          disabled={disabled}
          hideOptional
        />
      </div>
      <Text name='priceAdjustmentId' className='hidden' />
      {type === 'save' && <PriceAdjustmentInfo />}
    </FormV2>
  );
};

export default PriceAdjustmentForm;
