import { Dispatch, FC, SetStateAction } from 'react';
import classNames from 'classnames';
import { RootState } from 'js/store/reducer';

import Icon from 'js/components/Icon';
import FullName from './components/FullName';
import Text from '../Text';

import SelectLanguage from 'js/scenes/Freight/components/Users/components/SelectLanguage';
import SelectAdminRights from 'js/scenes/Freight/components/Users/components/SelectAdminRights';
import { useAppSelector } from 'js/utils/hooks';

interface Props {
  name: string;
  user;
  index: number;
  editUserActive: boolean | null;
  setEditUserActive: Dispatch<SetStateAction<boolean | null>>;
}

const EditUser: FC<Props> = ({ name, user, index, editUserActive, setEditUserActive }) => {
  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  return (
    <>
      <div
        className={classNames('edit-user__name', {
          'edit-user__name--header': !isDesktop
        })}
      >
        <FullName name={`${name}[${index}]`} user={user} />
        {!isDesktop && (
          <Icon
            icon={editUserActive ? 'times' : 'pencil-alt'}
            handleClick={() => {
              setEditUserActive(!editUserActive);
            }}
          />
        )}
      </div>
      <Text name={`${name}[${index}]`} user={user} accessor='userName' />
      <Text name={`${name}[${index}]`} user={user} accessor='email' />
      <SelectAdminRights
        name={`${name}[${index}]adminRights`}
        className='edit-user__admin-rights'
        user={user}
        disabled={!editUserActive}
      />
      <SelectLanguage
        name={`${name}[${index}]language`}
        className='edit-user__language'
        disabled={!editUserActive}
        user={user}
      />
      <div className='edit-user__edit-icon'>
        <Icon
          icon={editUserActive ? 'times' : 'pencil-alt'}
          handleClick={() => {
            setEditUserActive(!editUserActive);
          }}
        />
      </div>
    </>
  );
};

export default EditUser;
