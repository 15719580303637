import Ajax from 'js/utils/ajax/ajax.js';

import { ApiLabelQuery, ApiServiceSupplier } from 'src/interfaces/interfaces.generated';

export const orderLabels = (data: ApiLabelQuery): Promise<boolean> => Ajax.post('/labels/v1', data);

export const getMainCarNumbers = (): Promise<string[]> => Ajax.get('/labels/v1/maincarnumbers');

export const getPreTransportCarNumbers = (): Promise<string[]> =>
  Ajax.get('/labels/v1/pretransportcarnumbers');

export const getSubTransportCarNames = (): Promise<string[]> =>
  Ajax.get('/labels/v1/subtransportcarnames');

export const getMainCarDepartures = (): Promise<string[]> =>
  Ajax.get('/labels/v1/maincarplanneddepartures');

export const getServiceSuppliersForLabels = (): Promise<ApiServiceSupplier[]> =>
  Ajax.get('/labels/v1/servicesuppliers');
