import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as api from '../../utils/rest';
import { Credentials, LoginToken } from '../../scenes/Login/interfaces';
import {
  getCookie,
  removeJwtTokenCookie,
  setJwtTokenCookieIfLocalOrMock
} from 'js/utils/ajax/utils';
import * as amplitude from '@amplitude/analytics-browser';

export interface AuthenticationReducerState {
  isLoading: boolean;
  isAuthenticated: boolean | string;
  error: unknown;
}

const initialState: AuthenticationReducerState = {
  isLoading: false,
  isAuthenticated: !!getCookie('jwt-token'),
  error: null
};

const authenticationSlice = createSlice({
  name: 'Authentication',
  initialState,
  reducers: {
    loginRequest(state) {
      state.isLoading = true;
      state.isAuthenticated = false;
      state.error = null;
    },
    loginSuccess(state) {
      state.isLoading = false;
      state.isAuthenticated = true;
    },
    loginFailed(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    logoutRequest(state) {
      state.isLoading = true;
    },
    logoutSuccess(state) {
      state.isLoading = false;
      state.isAuthenticated = false;
    },
    logoutFailed(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  loginRequest,
  loginSuccess,
  loginFailed,
  logoutRequest,
  logoutSuccess,
  logoutFailed
} = authenticationSlice.actions;

export default authenticationSlice.reducer;

export const login =
  (credentials: Credentials) =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(loginRequest());
      const response: LoginToken = await api.login(credentials);
      const validToken = !!response?.token;
      if (validToken) {
        setJwtTokenCookieIfLocalOrMock(response.token);
        dispatch(loginSuccess());
      }
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

export const logout = () => async (dispatch) => {
  try {
    dispatch(logoutRequest());
    await api.logout();
    removeJwtTokenCookie();
    amplitude.flush();
    dispatch(logoutSuccess());
  } catch (error) {
    dispatch(logoutFailed(error));
  }
};
