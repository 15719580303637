import { FC, useEffect } from 'react';
import { useUsernameAvailableQuery } from 'js/scenes/UserAdministration/api/api';
import { useFormContext } from '@distribution-innovation/form-hooks';
import t from 'js/utils/translate';
import {
  getUsername,
  usernameErrorStatus,
  usernameInfoLabel,
  usernameValidationStatus
} from 'js/scenes/UserAdministration/utils/utils';
import Input from 'js/components/Form/components/Input';
import { UsernameInputProps } from 'js/scenes/UserAdministration/UserActions/CreateAndEdit/UserForm.types';

const UsernameInput: FC<UsernameInputProps> = ({ isEditMode }) => {
  const context = useFormContext();
  const usernameFieldName = 'username';

  const userType = context?.getValue('type');
  const username = getUsername(userType, context?.getValue(usernameFieldName));

  const isUsernameAvailable = useUsernameAvailableQuery(username);

  useEffect(() => {
    // Set special error on input if needed. This is also checked in form validation
    const error = usernameErrorStatus(isEditMode, username, isUsernameAvailable);
    if (error) {
      context?.setError(usernameFieldName, error);
    }
  }, [isUsernameAvailable]);

  return (
    <Input
      disabled={isEditMode}
      optional={false}
      hideOptional
      name={usernameFieldName}
      label={t('general.username')}
      info={usernameInfoLabel(isEditMode, userType)}
      subLabel={usernameValidationStatus(isEditMode, isUsernameAvailable)}
    />
  );
};

export default UsernameInput;
