import { FC, useState } from 'react';

import LabelHistoryLabels from './components/LabelHistoryLabels';
import LabelHistoryInfo from './components/LabelHistoryInfo';
import LabelHistoryAdditionalInfo from './components/LabelHistoryAdditionalInfo';
import ShowMoreButtons from './components/ShowMoreButtons';

import { LabelGenerationHistory } from 'src/interfaces/interfaces.generated';
import { LabelStatus } from './interfaces';
import { findLabelStatusById } from './utils';

import './index.sass';

interface Props {
  labelExcerpt: LabelGenerationHistory;
}

const LabelHistoryRow: FC<Props> = ({ labelExcerpt }) => {
  const [showLabels, toggleShowLabels] = useState(false);
  const [showLabelExcerptOptions, toggleShowLabelExcerptOptions] = useState(false);

  const labelStatus: LabelStatus | undefined = findLabelStatusById(labelExcerpt.statusId);

  return (
    <div className='label-history-row'>
      <LabelHistoryInfo labelExcerpt={labelExcerpt} labelStatus={labelStatus} />
      <ShowMoreButtons
        className='label-history-row__show-more-buttons'
        showLabelExcerptOptions={showLabelExcerptOptions}
        toggleShowLabelExcerptOptions={toggleShowLabelExcerptOptions}
        showLabels={showLabels}
        toggleShowLabels={toggleShowLabels}
        labelStatus={labelStatus}
      />
      {showLabelExcerptOptions && <LabelHistoryAdditionalInfo labelExcerpt={labelExcerpt} />}
      {showLabels && <LabelHistoryLabels labelExcerpt={labelExcerpt} />}
    </div>
  );
};

export default LabelHistoryRow;
