import { FC } from 'react';
import { Shop } from 'js/scenes/UserAdministration/Users.types';
import { displayMaxAmountOfTags } from 'js/scenes/UserAdministration/utils/constants';
import LimitedTagList from 'js/components/Tag/LimitedTagList';
import t from 'js/utils/translate';

const Shops: FC<{ allShopsAccess: boolean; shops: Shop[] }> = ({ allShopsAccess, shops }) => {
  return allShopsAccess ? (
    <>{t('userAdministration.allShopsAccess')}</>
  ) : (
    <LimitedTagList objects={shops} limit={displayMaxAmountOfTags} />
  );
};

export default Shops;
