import { FC } from 'react';

import Zones from './components/Zones';
import PageWrapper from 'js/components/PageWrapper';

import { RootState } from 'js/store/reducer';
import { PriceType } from '../../interfaces';

import t from 'js/utils/translate';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface WeightClassProps {
  row: string;
  weightId?: number;
  columns: string[];
  weightClassIndex: number;
  priceTypes: PriceType[];
  weightClassPricesForAllZones;
}

const WeightClass: FC<WeightClassProps> = ({
  row,
  weightId,
  columns,
  weightClassIndex,
  priceTypes,
  weightClassPricesForAllZones
}) => {
  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  return (
    <div className='weight-class'>
      <h4>{t('economy.edit.costprice.weight.class', row)}</h4>
      <PageWrapper className='weight-class__zones' withoutPadding={isDesktop}>
        {priceTypes.map((priceType, priceTypeIndex) => (
          <Zones
            key={priceType}
            columns={columns}
            priceType={priceType}
            priceTypeIndex={priceTypeIndex}
            weightClassIndex={weightClassIndex}
            weightClassPricesForAllZones={weightClassPricesForAllZones}
            weightId={weightId}
          />
        ))}
      </PageWrapper>
    </div>
  );
};

export default WeightClass;
