import { FC, useEffect } from 'react';
import { format } from 'date-fns';

import InfoBox from 'js/components/InfoBox';

import { listBody } from 'js/components/CollapseV2/variants/listBody';
import { formatAddress, formatPhone } from '../../utils';
import { getPackageInfo } from '../../../../../../store/packageInfoReducer';

import { ApiOrderSimple, ApiPackageInfo } from 'src/interfaces/interfaces.generated';
import { RootState } from 'js/store/reducer';

import t from 'js/utils/translate';
// eslint-disable-next-line max-len
import { orderHasServicePointInfoAndIncludeTagOnFreightProduct } from 'js/scenes/Home/scenes/OrderDetails/components/OrderInfo/components/OrderAccordion/utils';
import Spinner from 'js/components/Spinner';
import { findFreightProductById } from 'js/utils/find';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useParams } from 'react-router-dom';

interface Props {
  order: ApiOrderSimple;
}

const ServicePointBody: FC<Props> = ({ order }) => {
  const dispatch = useAppDispatch();
  const { trackingReference } = useParams();
  const { servicePoint } = order;

  const packageInfo: ApiPackageInfo = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.packageInfo?.data
  );

  const freightProducts = useAppSelector((state: RootState) => state.freightProducts.data);
  const freightProductLoaded = useAppSelector(
    (state: RootState) => state.freightProducts?.isLoaded
  );
  const freightProduct = findFreightProductById(freightProducts, order.freightProductId);
  const includeServicePoint = orderHasServicePointInfoAndIncludeTagOnFreightProduct(
    order,
    freightProduct
  );

  useEffect(() => {
    if (includeServicePoint && `00${packageInfo?.trackingReference}` !== trackingReference)
      dispatch(getPackageInfo(trackingReference!));
  }, []);

  if (!freightProductLoaded) return <Spinner />;

  if (!includeServicePoint) return <InfoBox text={t('general.noInformationFound')} />;

  return listBody(
    [
      {
        label: 'name',
        content: servicePoint?.name ?? '-',
        orientation: 'row'
      },
      {
        label: 'address',
        content: formatAddress(servicePoint),
        orientation: 'row'
      },
      {
        label: 'telephone',
        content: formatPhone(servicePoint?.phone1),
        orientation: 'row'
      },
      {
        label: 'email',
        content: servicePoint?.email ?? '-',
        orientation: 'row'
      },
      {
        label: 'externId',
        content: servicePoint?.externalId ?? '-',
        orientation: 'row'
      },
      {
        label: 'additionalTelephone',
        content: formatPhone(servicePoint?.phone2),
        orientation: 'row'
      },
      {
        label: 'pickupCode',
        content: packageInfo?.pickupCode ?? '-',
        orientation: 'row'
      },
      {
        label: 'returnDate',
        content: packageInfo?.deliveryDeadline
          ? format(new Date(packageInfo.deliveryDeadline), 'dd.MM.yyyy')
          : '-',
        orientation: 'row'
      }
    ],
    'orderDetails.accordion.servicePoint.'
  );
};

export default ServicePointBody;
