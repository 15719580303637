import './index.sass';

const NameAndSize = ({ name, size }) => (
  <div className='imported-orders-desktop-table__name-and-size'>
    <span>{name ?? '-'}</span>
    <span className='file-size'>{size ?? '-'} kb</span>
  </div>
);

export default NameAndSize;
