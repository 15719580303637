import { FC, ChangeEvent } from 'react';
import classNames from 'classnames';

import Label from 'js/components/Label';
import Radio from 'js/components/Radio';

import { RadioType } from './interfaces';

import './index.sass';

interface Props {
  className?: string;
  name: string;
  label: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  radios: RadioType[];
  align?: 'row' | 'column';
}

const RadioGroup: FC<Props> = ({ className, name, label, onChange, radios, align = 'column' }) => (
  <div className={classNames('radio-group', className)}>
    <Label label={label} />
    <div className={classNames('radio-group__radios', `radio-group__radios--${align}`)}>
      {radios.map(({ label, value, checked }) => (
        <Radio
          key={label}
          name={name}
          label={label}
          value={value}
          checked={checked}
          onChange={onChange}
        />
      ))}
    </div>
  </div>
);

export default RadioGroup;
