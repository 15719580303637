import classNames from 'classnames';

import Label from 'js/components/Label';

import { tu } from 'js/utils/translate';

import './index.sass';

const ListItem = ({ className, filteredListElement, translationKeyPrefix }) => (
  <div className={className}>
    <Label label={tu(`${translationKeyPrefix}${filteredListElement.label}`)} />
    <span>{filteredListElement.content}</span>
  </div>
);

export const listBody = (list, translationKeyPrefix, className) => (
  <div className={classNames('accordion-list-body', className)}>
    {list.filter((element) => element.orientation === 'row').length > 0 && (
      <div className='accordion-list-body__list-wrapper accordion-list-body__list-wrapper--row'>
        {list
          .filter((element) => element.orientation === 'row')
          .map((filteredListElement) => (
            <ListItem
              className={classNames(
                'accordion-list-body__item-wrapper',
                filteredListElement?.itemClassName
              )}
              filteredListElement={filteredListElement}
              translationKeyPrefix={translationKeyPrefix}
              key={filteredListElement.label}
            />
          ))}
      </div>
    )}
    {list.filter((element) => element.orientation === 'column').length > 0 && (
      <div className='accordion-list-body__list-wrapper accordion-list-body__list-wrapper--column'>
        {list
          .filter((element) => element.orientation === 'column')
          .map((filteredListElement) => (
            <ListItem
              className={classNames(
                'accordion-list-body__item-wrapper',
                filteredListElement?.itemClassName
              )}
              filteredListElement={filteredListElement}
              translationKeyPrefix={translationKeyPrefix}
              key={filteredListElement.label}
            />
          ))}
      </div>
    )}
  </div>
);
