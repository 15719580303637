import { FC, useEffect } from 'react';

import Spinner from 'js/components/Spinner';
import FooterBoxes from 'js/components/FooterBoxes';

import { getUser } from 'js/store/userReducer';
import { findSelectedShortcutFooterboxes } from './utils';

import { RootState } from 'js/store/reducer';
import { FooterBox } from 'js/components/FooterBoxes/interfaces';
import { UserInfo } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const Shortcuts: FC = () => {
  const dispatch = useAppDispatch();

  const user: UserInfo = useAppSelector((state: RootState) => state?.user?.data);
  const userLoaded: boolean = useAppSelector((state: RootState) => state?.user?.isLoaded);

  useEffect(() => {
    if (!userLoaded) dispatch(getUser());
  }, []);

  if (!userLoaded) return <Spinner />;

  const footerBoxes: FooterBox[] = findSelectedShortcutFooterboxes(user.shortcuts);

  return <FooterBoxes footerBoxes={footerBoxes} className='page-padding' />;
};

export default Shortcuts;
