import { uniq, flatMap } from 'lodash';

import { ApiFlowShopSimple, FreightProduct } from 'src/interfaces/interfaces.generated';
import { Option } from 'js/components/SelectV2/interfaces';

export const findFreightProductOptions = (
  selectedShops: ApiFlowShopSimple[],
  freightProducts: FreightProduct[]
): Option[] => {
  const freightProductsIdsForSelectedShops = uniq(
    flatMap(selectedShops, (shop) => shop?.freightProductIds)
  );

  return freightProducts
    .filter(
      ({ labels, freightProductId }) =>
        labels?.length && freightProductsIdsForSelectedShops.includes(freightProductId)
    )
    .map((freightProduct) => ({
      value: freightProduct.freightProductId,
      label: freightProduct.name
    }));
};
