import { FC } from 'react';

import PageWrapper from 'js/components/PageWrapper';
import FooterBoxes from 'js/components/FooterBoxes';

import { economyFooterBoxes } from 'js/components/FooterBoxes/utils';

const Economy: FC = () => (
  <PageWrapper className='economy-landing-page'>
    <FooterBoxes footerBoxes={economyFooterBoxes} />
  </PageWrapper>
);

export default Economy;
