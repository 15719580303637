import { User, UserType } from 'js/scenes/UserAdministration/Users.types';
import Ajax from 'js/utils/ajax/ajax';
import { useDebouncedCallback } from 'use-debounce';
import { url } from 'js/scenes/UserAdministration/api/api';

const searchUsersUrl = `${url}/search/:username`;

const useUserSearchQuery = (type: UserType, selectedUsers?: User[], debounceMs = 1000) =>
  useDebouncedCallback((inputValue, callback) => {
    Ajax.get(searchUsersUrl.replace(':username', inputValue), { userType: type.toUpperCase() })
      .then((response) => {
        const options = response
          ?.filter((user) => !selectedUsers?.find((selected) => selected.userId === user.userId))
          .map((user) => ({
            value: user,
            label: user.username
          }));
        callback(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, debounceMs);

export default useUserSearchQuery;
