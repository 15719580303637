import classNames from 'classnames';
import PropTypes from 'prop-types';

import Label from 'js/components/Label';

import './index.sass';

const ColoredSection = ({
  label,
  optional = false,
  defaultText,
  children,
  showChildren = true,
  info,
  className
}) => (
  <div className={classNames('colored-section', className)}>
    {label && <Label label={label} optional={optional} info={info} />}
    <div
      className={classNames('colored-section--content', className ? `${className}__content` : '')}
    >
      {defaultText && <span>{defaultText}</span>}
      <>{showChildren && children}</>
    </div>
  </div>
);

ColoredSection.propTypes = {
  label: PropTypes.string,
  optional: PropTypes.bool,
  defaultText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
    PropTypes.bool,
    PropTypes.string
  ]),
  showChildren: PropTypes.bool,
  info: PropTypes.string,
  className: PropTypes.string
};

export default ColoredSection;
