import { FC, Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';

import NewWebserviceUserPassword from './components/NewWebserviceUserPassword';
import NewUserPassword from './components/NewUserPassword';
import RemoveServiceSupplierUser from './components/RemoveServiceSupplierUser';
import RemoveUser from './components/RemoveUser';

import { ApiUser } from 'src/interfaces/interfaces.generated';

import './index.sass';

interface Props {
  user: ApiUser;
  domain?: 'serviceSupplier';
  editUserActive: boolean | null;
  setEditUserActive: Dispatch<SetStateAction<boolean | null>>;
  isApiContext?: boolean;
}

const UserActions: FC<Props> = ({
  user,
  domain,
  editUserActive,
  setEditUserActive,
  isApiContext
}) => (
  <div
    className={classNames('user-actions', {
      'user-actions--hidden': !editUserActive
    })}
  >
    {isApiContext ? (
      <NewWebserviceUserPassword userId={user.userId} editUserActive={editUserActive} />
    ) : (
      <NewUserPassword
        userId={user.userId}
        setEditUserActive={setEditUserActive}
        editUserActive={editUserActive}
      />
    )}
    {domain === 'serviceSupplier' ? (
      <RemoveServiceSupplierUser userId={user.userId} setEditUserActive={setEditUserActive} />
    ) : (
      <RemoveUser userId={user.userId} setEditUserActive={setEditUserActive} />
    )}
  </div>
);

export default UserActions;
