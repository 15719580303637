import { FC } from 'react';

import { nameString } from './utils';

interface Props {
  data: unknown[];
  accessor?: string;
}

const CommaSeparatedString: FC<Props> = ({ data, accessor = 'name' }) => {
  if (!data || data.length < 1) return <span>-</span>;

  return <div>{data.map((shop, index) => nameString(shop, index, data.length, accessor))}</div>;
};

export default CommaSeparatedString;
