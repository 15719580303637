import { FC } from 'react';

import Label from 'js/components/Label';
import Radio from 'js/components/Form/components/Radio';
import ColoredSection from 'js/components/ColoredSection';
import InfoBox from 'js/components/InfoBox';

import t, { tu } from 'js/utils/translate';

import './index.sass';

interface Props {
  radios: any[];
  name: string;
}

const ReportTypeRadios: FC<Props> = ({ radios, name }) => (
  <div className='report-type-radios'>
    <Label className='report-type-radios__label' label={tu('report.type')} />
    {!radios || radios.length === 0 ? (
      <InfoBox text={t('report.type.no.accessible.types')} />
    ) : (
      <div className='report-type-radios__radio-buttons'>
        {radios.map(({ label, value, info }) => (
          <div key={label} className='report-type-radio'>
            <Radio name={name} label={t(label)} value={value} />
            <ColoredSection>{t(info)}</ColoredSection>
          </div>
        ))}
      </div>
    )}
  </div>
);

export default ReportTypeRadios;
