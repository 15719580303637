import { forwardRef } from 'react';
import classNames from 'classnames';

import Icon from 'js/components/Icon';

import './index.sass';

interface Props {
  onClick?: any;
  value?: string;
  disabledStyling?: boolean;
  handleBlur?: () => void;
  error?: boolean;
}

const CustomInput = forwardRef<HTMLButtonElement, Props>(
  ({ onClick, handleBlur, value, error, disabledStyling }, ref) => (
    <div
      className={classNames(
        'datepicker-custom-input',
        { 'datepicker-custom-input--error': error },
        { 'datepicker-custom-input--disabled': disabledStyling }
      )}
    >
      <button
        ref={ref}
        onBlur={handleBlur}
        type='button'
        onClick={onClick}
        disabled={disabledStyling}
      >
        <div className='picked-date'>{value}</div>
        <div className='pick-date-icon'>
          <Icon icon='calendar-alt' />
        </div>
      </button>
    </div>
  )
);

CustomInput.displayName = 'CustomInput';

export default CustomInput;
