/* eslint-disable react/jsx-pascal-case */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Input as _Input, withError } from '@distribution-innovation/form-hooks';

import Label from 'js/components/Label';

import './index.sass';
import InputError from '../Validation/InputError';

const Input = ({
  className = '',
  name,
  subLabel,
  type,
  label,
  inlineLabel,
  info,
  disabled = false,
  optional = disabled,
  placeholder,
  hideOptional = false,
  error,
  ...props
}) => (
  <div
    className={classNames('labeled-input__container', className, {
      checkbox: type === 'checkbox'
    })}
  >
    {label && <Label label={label} info={info} optional={!hideOptional && optional} />}
    <_Input
      placeholder={placeholder}
      name={name}
      type={type}
      className={classNames('input', { 'input--invalid': error })}
      required={!optional}
      disabled={disabled}
      {...props}
    />
    {type === 'checkbox' && inlineLabel && <span>{inlineLabel}</span>}
    {subLabel && <Label subLabel>{subLabel}</Label>}
    {error && <InputError error={error} />}
  </div>
);

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  info: PropTypes.string,
  optional: PropTypes.bool,
  placeholder: PropTypes.string
};

export default withError()(Input);
