import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Icon from 'js/components/Icon';
import HamburgerMenuSubSection from '../SubSection';

import { tu } from 'js/utils/translate';

const HamburgerMenuMainSection = ({ navPage, open }) => {
  const [showSubSections, setShowSubSections] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const toggleView = () => {
    setShowSubSections(!showSubSections);
  };

  useEffect(() => {
    if (!open) {
      setShowSubSections(false);
      setIsActive(false);
    } else if (location.pathname === navPage.to) {
      setIsActive(true);
    } else {
      // eslint-disable-next-line array-callback-return
      navPage.dropdownSections.map((section) => {
        if (location.pathname === section.to) {
          setShowSubSections(true);
        }
      });
    }
  }, [open]);

  useEffect(() => {
    location.pathname === navPage.to ? setIsActive(true) : setIsActive(false);
  }, [location.pathname]);

  return (
    <div>
      <div
        className={classNames('menu-item', { 'menu-item--active': isActive })}
        onClick={toggleView}
      >
        <Link to={navPage.to}>
          {tu(`general.${navPage.name.replace(/\s/g, '').toLowerCase()}`)}
        </Link>
        <div>
          {showSubSections && <Icon icon='angle-down' light size='2x' />}
          {!showSubSections && <Icon icon='angle-right' light size='2x' />}
        </div>
      </div>
      {showSubSections && (
        <div className='nav'>
          {navPage.dropdownSections.map((section) => (
            <HamburgerMenuSubSection
              key={section.to}
              section={section}
              showSubSections={showSubSections}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default HamburgerMenuMainSection;
