import classNames from 'classnames';

const ShowMoreButton = ({ toggleShow, show, hideText, showText, disabled = false }) => (
  <div
    className={classNames('link', 'show-more-button', {
      'show-more-button--show': show,
      'show-more-button--disabled': disabled
    })}
    onClick={() => (disabled ? undefined : toggleShow(!show))}
  >
    {show ? hideText : showText}
  </div>
);

export default ShowMoreButton;
