import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CopyTextFeedback from './components/CopyTextFeedback';
import CopyIcon from './components/CopyIcon';

import { userFeedback } from './utils';
import t from 'js/utils/translate';

import './index.sass';

const CopyText = ({
  className,
  text,
  copyIconRef,
  withFeedback = true,
  feedbackPosition = 'top',
  feedbackMessage = t('general.copied')
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const feedbackRef = useRef(null);
  const copyValueRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isCopied) setIsCopied(false);
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [isCopied]);

  const handleCopy = ({ currentTarget }) => {
    const toClipboard = copyValueRef.current;
    toClipboard.type = 'text';
    if (typeof toClipboard.innerText === 'string' || typeof toClipboard.innerText === 'number') {
      toClipboard.select();
      document.execCommand('copy');
      setIsCopied(true);
    }
    toClipboard.type = 'hidden';
    if (withFeedback) {
      const scrollY = window.scrollY;
      userFeedback(
        currentTarget.getBoundingClientRect(),
        feedbackRef.current,
        feedbackPosition,
        scrollY
      );
    }
  };

  if (text) {
    return (
      <div className={classNames('copy-text', className)}>
        <input ref={copyValueRef} type='hidden' readOnly value={text} />
        <span id='copy-value'>{text}</span>
        <CopyIcon handleCopy={handleCopy} copyIconRef={copyIconRef} />
        {withFeedback && (
          <CopyTextFeedback
            isCopied={isCopied}
            feedbackRef={feedbackRef}
            message={feedbackMessage}
          />
        )}
      </div>
    );
  }
  return null;
};

CopyText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  copyIconRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  withFeedback: PropTypes.bool,
  feedbackPosition: PropTypes.oneOf(['top', 'right'])
};

export default CopyText;
