import { createSlice } from '@reduxjs/toolkit';

import * as api from '../api';
import { locationChanged } from 'js/store/actions';

export interface ReportsReducerState {
  loading: boolean;
  success: boolean;
  error: unknown;
}

const initialState: ReportsReducerState = {
  loading: false,
  success: false,
  error: null
};

const reportsSlice = createSlice({
  name: 'Reports',
  initialState,
  reducers: {
    downloadReportRequest(state) {
      state.loading = true;
      state.success = false;
      state.error = null;
    },
    downloadReportSuccess(state) {
      state.loading = false;
      state.success = true;
    },
    downloadReportFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { downloadReportRequest, downloadReportSuccess, downloadReportFailed } =
  reportsSlice.actions;

export default reportsSlice.reducer;

export const downloadReport = (url: string) => async (dispatch) => {
  try {
    dispatch(downloadReportRequest());
    await api.downloadReport(url);
    dispatch(downloadReportSuccess());
  } catch (error) {
    dispatch(downloadReportFailed(error));
  }
};
