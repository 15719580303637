import { FC } from 'react';
import classNames from 'classnames';

import t from 'js/utils/translate';

interface Props {
  interval: string;
  selectedInterval: string;
  setSelectedInterval;
}

const FilterButton: FC<Props> = ({ interval, selectedInterval, setSelectedInterval }) => (
  <span
    className={classNames('dashboard-filter-button', 'link', {
      'link--active': selectedInterval === interval
    })}
    onClick={() => setSelectedInterval(interval)}
  >
    {t(`mainPanel.dashboard.${interval}`)}
  </span>
);

export default FilterButton;
