import { FC, useEffect } from 'react';

import DateTime from 'js/components/DateTime';
import Image from 'js/components/Image';
import Spinner from 'js/components/Spinner';
import InfoBox from 'js/components/InfoBox';
import NotFound from 'js/components/NotFound';

import t from 'js/utils/translate';
import { listBody } from 'js/components/CollapseV2/variants/listBody';
import { getBudBuddyImages } from '../../../../../../store/budBuddyImagesReducer';

import { RootState } from 'js/store/reducer';

import './index.sass';
import { safariSafeDateString } from 'js/utils/dateTime';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface DeliveryBodyProps {
  trackingReference: string;
  hasBudBuddyInfo: boolean;
  plannedProofOfDelivery?: string;
  actualProofOfDelivery?: string;
}

const DeliveryBody: FC<DeliveryBodyProps> = ({
  trackingReference,
  hasBudBuddyInfo,
  plannedProofOfDelivery,
  actualProofOfDelivery
}) => {
  const dispatch = useAppDispatch();

  const budBuddyImages = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.budBuddyImages?.data
  );
  const budBuddyImagesLoaded = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.budBuddyImages?.isLoaded
  );
  const budBuddyImagesError = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.budBuddyImages?.error
  );

  useEffect(() => {
    if (trackingReference && hasBudBuddyInfo) dispatch(getBudBuddyImages(trackingReference));
  }, []);

  if (!hasBudBuddyInfo && !(plannedProofOfDelivery ?? actualProofOfDelivery))
    return <InfoBox text={t('general.noInformationFound')} />;
  if (budBuddyImagesError) return <NotFound />;
  if (
    !budBuddyImagesLoaded &&
    !budBuddyImagesError &&
    !(plannedProofOfDelivery ?? actualProofOfDelivery)
  )
    return <Spinner />;

  return (
    <>
      {listBody(
        [
          {
            label: 'planned',
            content: (
              <div>
                {plannedProofOfDelivery
                  ? t('edit.freightProduct.proofOfDelivery.options.' + plannedProofOfDelivery)
                  : '-'}
              </div>
            ),
            orientation: 'row'
          },
          {
            label: 'actual',
            content: (
              <div>
                {actualProofOfDelivery
                  ? t('edit.freightProduct.proofOfDelivery.options.' + actualProofOfDelivery)
                  : '-'}
              </div>
            ),
            orientation: 'row'
          }
        ],
        'orderDetails.accordion.delivery.proofOfDelivery.'
      )}
      {budBuddyImages.map((budBuddyImage) =>
        listBody(
          [
            {
              label: 'time',
              content: (
                <DateTime datetime={new Date(safariSafeDateString(budBuddyImage?.timestamp))} />
              ),
              orientation: 'row'
            },
            {
              label: 'scanned',
              content: (
                <div>
                  {budBuddyImage?.scanned ? t('general.answer.yes') : t('general.answer.no')}
                </div>
              ),
              orientation: 'row'
            },
            {
              label: 'image',
              content: (
                <div>
                  {budBuddyImage?.images?.map((image) => (
                    <Image
                      className='delivery__delivery-image'
                      height='200px'
                      key={image.imageUrl}
                      src={image.imageUrl}
                      alt={t('orderDetails.accordion.delivery.image')}
                    />
                  ))}
                </div>
              ),
              orientation: 'row',
              itemClassName: 'delivery__delivery-image'
            }
          ],
          'orderDetails.accordion.delivery.'
        )
      )}
    </>
  );
};

export default DeliveryBody;
