const findElement = (data, accessor, id) => {
  switch (typeof accessor) {
    case 'string':
      return <div key={`${id}-${accessor}`}>{data[accessor]}</div>;
    case 'function':
      return <div key={`${id}-${accessor}`}>{accessor(data)}</div>;
    default:
      return false;
  }
};

export const mapFromColumnsToRows = (columns, data, prepareRow, rows, rowIcon, idAccessor) => {
  const headerColumns = columns.filter((column) => column.inHeader);
  const bodyColumns = columns.filter((column) => !column.inHeader && !column.onlyDesktop);

  return rows.map((row) => ({
    id: row.original[idAccessor],
    row,
    headerContent: headerColumns.map(({ accessor }) =>
      findElement(row.original, accessor, row.original[idAccessor])
    ),
    headerIcon: rowIcon,
    bodyContent: bodyColumns.map(({ accessor }) => {
      prepareRow(row);
      return row.cells
        .filter((cell) => cell.column.accessor === accessor)
        .map((cell) => (
          <div key={`${cell.row.original.userId}-${cell.column.Header}`} {...cell.getCellProps()}>
            {cell.render('Cell')}
          </div>
        ));
    })
  }));
};
