import { FC } from 'react';
import FilterButton from './components/FilterButton';

import { Filters } from 'js/components/FilterButtons/interfaces';

interface Props {
  selectedFilter: string;
  setSelectedFilter;
  filters: Filters;
}

const FilterButtons: FC<Props> = ({ selectedFilter, setSelectedFilter, filters }) => (
  <div className='filter-buttons'>
    {filters.map((filter) => (
      <FilterButton
        key={filter.name}
        filter={filter}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
    ))}
  </div>
);

export default FilterButtons;
