import ToggleGroup from 'js/components/Form/components/ToggleGroup';

const toggles = [
  { name: 'passiveReturn', label: 'shop.accordion.passiveReturn' },
  { name: 'vip', label: 'shop.accordion.vip' }
];

const Other = () => <ToggleGroup toggles={toggles} />;

export default Other;
