import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import DownloadInvoice from './components/DownloadInvoice';

import { ApiInvoiceLine } from 'src/interfaces/interfaces.generated';
import { tu } from 'js/utils/translate';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  invoiceId?: number;
  invoiceLines?: ApiInvoiceLine[];
}

const InvoiceLines: FC<Props> = ({ invoiceId, invoiceLines }) => {
  const isDesktop: boolean = useAppSelector((state: RootState) => state.mediaDevice.isDesktop);

  if (!invoiceLines) return <div>-</div>;

  return (
    <div className='invoice-lines'>
      {isDesktop && (
        <div className='lines'>
          {invoiceLines?.map((invoiceLine: ApiInvoiceLine, index) => (
            <div className='invoice-line' key={index}>
              <span className='description'> {invoiceLine.description} </span>
              <div className='quantity'>
                <span>{tu('general.quantity')}: </span>
                <span>{invoiceLine?.quantity?.toFixed(0) ?? '-'}</span>
              </div>
              <div className='price'>
                <span>{tu('general.avgPrice')}: </span>
                <span>{invoiceLine?.averagePricePerUnit?.toFixed(2) ?? '-'}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      <DownloadInvoice invoiceId={invoiceId} />
    </div>
  );
};

export default InvoiceLines;
