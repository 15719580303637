import t from 'js/utils/translate';

const OWNER_PARTY = {
  AMEDIA: 2,
  SVLHL: 4,
  SCHIBSTED: 5,
  MORGENLEVERING: 8,
  POLARIS: 14
};

const articleReportAccess = (activeOwnerParty) =>
  [
    OWNER_PARTY.AMEDIA,
    OWNER_PARTY.SVLHL,
    OWNER_PARTY.SCHIBSTED,
    OWNER_PARTY.MORGENLEVERING,
    OWNER_PARTY.POLARIS
  ].includes(activeOwnerParty);

const articleReportButton = {
  label: 'reports.radio.label.article',
  value: 'articles',
  info: 'reports.radio.info.article'
};
const recipientsReportButton = {
  label: 'reports.radio.label.recipients',
  value: 'receptions',
  info: 'reports.radio.info.recipients'
};
const orderDataReportButton = {
  label: 'reports.radio.label.order.data',
  value: 'orders',
  info: 'reports.radio.info.order.data'
};
const deliveryTimeReportButton = {
  label: 'reports.radio.label.deliverytime',
  value: 'deliverytimelist',
  info: 'reports.radio.info.deliverytime'
};
const notInvoicedReportButton = {
  label: 'reports.radio.label.not.invoiced',
  value: 'notinvoiced',
  info: 'reports.radio.info.not.invoiced'
};
const customsReportButton = {
  label: 'reports.radio.label.customs',
  value: 'customs',
  info: 'reports.radio.info.customs'
};

export const radioButtons = (activeOwnerParty, roles) =>
  [
    articleReportAccess(activeOwnerParty) && articleReportButton,
    activeOwnerParty === 8 && recipientsReportButton,
    orderDataReportButton,
    deliveryTimeReportButton,
    roles?.invoice && notInvoicedReportButton,
    activeOwnerParty === 1 && customsReportButton
  ].filter(Boolean);

export const setShopOptions = (shops) => {
  const singleSelectAllDefaultValue = {
    value: -1,
    label: t('select.all.shops')
  };
  const mappedShops = shops?.map((shop) => ({
    value: shop.shopId,
    label: shop.name
  }));

  return [singleSelectAllDefaultValue, ...mappedShops];
};

export const setFreightProductOptions = (freightProducts) => {
  const singleSelectAllDefaultValue = {
    value: -1,
    label: t('select.all.freightProducts')
  };
  const mappedFreightProducts = freightProducts?.map((freightProduct) => ({
    value: freightProduct.freightProductId,
    label: freightProduct.name
  }));

  return [singleSelectAllDefaultValue, ...mappedFreightProducts];
};

export const initialDate = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};
