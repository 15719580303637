import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface ZipGroupsReducerState {
  error: unknown;
  data: any[];
  isLoaded: boolean;
}

const initialState: ZipGroupsReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const zipGroupsSlice = createSlice({
  name: 'ZipGroups',
  initialState,
  reducers: {
    getZipGroupsRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getZipGroupsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getZipGroupsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  }
});

export const { getZipGroupsRequest, getZipGroupsSuccess, getZipGroupsFailed } =
  zipGroupsSlice.actions;

export default zipGroupsSlice.reducer;

export const getZipGroups = () => async (dispatch) => {
  try {
    dispatch(getZipGroupsRequest());
    const zipGroups = await rest.getZipGroups();
    dispatch(getZipGroupsSuccess(zipGroups));
  } catch (err) {
    dispatch(getZipGroupsFailed(err));
  }
};
