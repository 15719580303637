import { FC, useState } from 'react';
import ButtonV2 from 'js/components/ButtonV2';
import t from 'js/utils/translate';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './DisconnectUser.sass';
import DisconnectUser from 'js/scenes/UserAdministration/UserActions/Disconnect/DisconnectUser';

const DisconnectUserModal: FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <ButtonV2
        variant='inverse'
        text={t('userAdministration.disconnect.button')}
        onClick={() => {
          setShowModal(true);
        }}
      />
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        aria-labelledby='disconnectUser-title'
        aria-describedby='disconnectUser-description'
        classNames={{ modal: 'customModal' }}
      >
        <DisconnectUser setShowModal={setShowModal} />
      </Modal>
    </>
  );
};

export default DisconnectUserModal;
