import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as api from '../../api';
import { PackageSystemLocation } from '../../interfaces';

export interface PackageSystemLocationsReducerState {
  error: unknown;
  data: PackageSystemLocation[];
  isLoaded: boolean;
}

const initialState: PackageSystemLocationsReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const packageSystemLocationsSlice = createSlice({
  name: 'PackageSystemLocations',
  initialState,
  reducers: {
    getPackageSystemLocationsRequest(state) {
      state.error = null;
      state.isLoaded = false;
      state.data = [];
    },
    getPackageSystemLocationsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getPackageSystemLocationsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  getPackageSystemLocationsRequest,
  getPackageSystemLocationsSuccess,
  getPackageSystemLocationsFailed
} = packageSystemLocationsSlice.actions;

export default packageSystemLocationsSlice.reducer;

export const getPackageSystemLocations =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getPackageSystemLocationsRequest());
      const response: PackageSystemLocation[] = await api.getPackageSystemLocations();
      dispatch(getPackageSystemLocationsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getPackageSystemLocationsFailed(error));
    }
  };
