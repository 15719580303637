import { Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.sass';
import { useAppSelector } from 'js/utils/hooks';

const SplitPage = ({ className, children }) => {
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);

  return (
    <div className={classNames('split-page-container', className)}>
      {isDesktop
        ? Children.map(children, (child, index) => (
            <div
              className={classNames('split-page-container__split-page', {
                'split-page-container__split-page--right': index === 1
              })}
            >
              {child}
              {index === 0 && <div className='split-page-container--split' />}
            </div>
          ))
        : children?.length > 0
          ? children?.map((child) => <>{child}</>)
          : children}
    </div>
  );
};

export default SplitPage;

SplitPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  noSplit: PropTypes.bool
};
