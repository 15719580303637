import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useLocation } from 'react-router';
import { Navigate, Route, Routes } from 'react-router-dom';

import Login from 'js/scenes/Login';
import AppHeader from './components/AppHeader';
import PageHeader from 'js/components/PageHeader';
import NotFoundPage from './components/NotFoundPage';
import Spinner from './components/Spinner';
import { activeOwnerPartyLogoAttributes } from 'js/utils/activeOwnerPartyLogoAttributes';

import { locationChanged } from 'js/store/actions';
import routes, {
  findPageHeaderActions,
  findParentPage,
  findPath,
  hasLocationHeader,
  routeAccess
} from './routes';
import { ToastContainer } from 'react-toastify';

const AppRouter = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
  const activeOwnerParty = useAppSelector((state) => state.user.data.activeOwnerParty);
  const userRoles = useAppSelector((state) => state?.user?.data?.roles);
  const userLoaded = useAppSelector((state) => state?.user?.isLoaded);

  const parentPage = findParentPage(location.pathname);
  const locationHeader = hasLocationHeader(location.pathname) || false;
  const pageHeaderActions = findPageHeaderActions(location.pathname, userRoles);

  const userHasRouteAccess = routeAccess(location.pathname, userRoles, activeOwnerParty);

  const route = findPath(location.pathname);

  useEffect(() => {
    dispatch(locationChanged());
  }, [location]);

  if (isAuthenticated && !userLoaded) return <Spinner pageSpinner />;
  if (!route) return <NotFoundPage />;

  return (
    <div className={activeOwnerPartyLogoAttributes('name', activeOwnerParty)}>
      <AppHeader isAuthenticated={isAuthenticated} />
      <ToastContainer />
      <div className='page-wrapper'>
        {(!userRoles || !activeOwnerParty) && isAuthenticated && <Spinner pageSpinner />}
        {(parentPage || locationHeader) && isAuthenticated && (
          <PageHeader pageHeaderActions={pageHeaderActions} />
        )}
        {isAuthenticated && userHasRouteAccess && (
          <Routes>
            {routes.map((route) => (
              <Route key={route.name} path={route.path} element={route.component} />
            ))}
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        )}
        {!userHasRouteAccess && <Navigate to='/' replace />}
        {!isAuthenticated && <Login />}
      </div>
    </div>
  );
};

export default AppRouter;
