import { FC, useEffect, useState } from 'react';

import Spinner from 'js/components/Spinner';
import LinkV2 from 'js/components/LinkV2';
import Events from './components/Events';
import PageWrapper from 'js/components/PageWrapper';

import t from 'js/utils/translate';
import { getOrderEvents } from './store/orderEventsReducer';

import { RootState } from 'js/store/reducer';
import { ApiFlowLogEvent } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface OrderHistoryProps {
  trackingReference?: string;
}

const OrderHistory: FC<OrderHistoryProps> = ({ trackingReference }) => {
  const dispatch = useAppDispatch();

  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const events: ApiFlowLogEvent[] = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.orderEvents?.data
  );
  const eventsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.orderEvents?.isLoaded
  );

  useEffect(() => {
    if (trackingReference) dispatch(getOrderEvents(trackingReference));
  }, [trackingReference]);

  const step = 50;

  const [toIndex, setToIndex] = useState(step);

  const handleLoadMore = () => {
    setToIndex(toIndex + step);
  };

  return (
    <PageWrapper className='order-history' withoutPadding={isDesktop}>
      <h2>{t('general.history')}</h2>
      {!eventsLoaded && <Spinner />}
      {eventsLoaded && events.length === 0 && <div>{t('orderDetails.events.empty')}</div>}
      {eventsLoaded && (
        <>
          <Events events={events} toIndex={toIndex} />
          <LinkV2
            className='order-history__load-more-link'
            text={t('general.loadMore')}
            onClick={handleLoadMore}
            disabled={events.length < toIndex}
          />
        </>
      )}
    </PageWrapper>
  );
};

export default OrderHistory;
