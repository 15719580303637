import Ajax from 'js/utils/ajax/ajax';
import { ApiFreightPriceCostPrice, TransportPriceTable } from 'src/interfaces/interfaces.generated';

export const getCostPrices = (
  shopId: number,
  freightProductId: number
): Promise<TransportPriceTable> =>
  Ajax.get(`/shops/v1/${shopId}/freightproducts/${freightProductId}/prices`);

export const deleteCostPrices = (
  shopId: number,
  freightProductId: number,
  fromDate: string | undefined
): Promise<TransportPriceTable> =>
  Ajax.delete(
    `/shops/v1/${shopId}/freightproducts/${freightProductId}/fromdate/${fromDate}/prices`
  );

export const saveCostPrices = (
  shopId: number,
  freightProductId: number,
  fromDate: string,
  priceMatrix: ApiFreightPriceCostPrice[]
): Promise<TransportPriceTable> =>
  Ajax.patch(
    `/shops/v1/${shopId}/freightproducts/${freightProductId}/fromdate/${fromDate}/prices`,
    priceMatrix
  );
