import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import MessageForUser from 'js/components/MessageForUser';
import Users from 'js/scenes/Freight/components/Users';

import t from 'js/utils/translate';
import { ApiServiceSupplier } from 'src/interfaces/interfaces.generated';
import { findServiceSupplierById } from 'js/utils/find';
import { getServiceSuppliers } from '../../../../store/serviceSuppliersReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  serviceSupplierId?: number;
  loading?: boolean;
}

const ServiceSupplierUsers: FC<Props> = ({ serviceSupplierId }) => {
  const dispatch = useAppDispatch();

  const serviceSuppliers: ApiServiceSupplier[] = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSuppliers?.data
  );
  const serviceSuppliersLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSuppliers?.isLoaded
  );

  useEffect(() => {
    if (!serviceSuppliersLoaded) dispatch(getServiceSuppliers());
  }, [serviceSuppliersLoaded]);

  return (
    <div className='service-supplier-users'>
      <div className='service-supplier-users__header'>
        <h2>{t('general.users')}</h2>
      </div>
      <MessageForUser
        variant='warning'
        message={t('freight.serviceSupplier.edit.user.warningMessage')}
      />
      <Users
        users={findServiceSupplierById(serviceSuppliers, serviceSupplierId)?.users}
        domain='serviceSupplier'
      />
    </div>
  );
};

export default ServiceSupplierUsers;
