import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.sass';

const Spinner = ({ pageSpinner }) => (
  <div className='spinner'>
    <div className={classNames('fa-3x', { 'spinner--page-spinner': pageSpinner })}>
      <i className='fas fa-spinner fa-spin' />
    </div>
  </div>
);

export default Spinner;

Spinner.propTypes = {
  pageSpinner: PropTypes.bool
};
