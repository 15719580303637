import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { tu } from 'js/utils/translate';
import { extractNavlinkDisplayName } from 'js/components/AppHeader/utils';

const HamburgerMenuItemSection = ({ section, showSubSections }) => {
  const [isActive, setIsActive] = useState(false);

  const handleView = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (location.pathname !== section.to) {
      setIsActive(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (showSubSections) {
      if (location.pathname === section.to) {
        setIsActive(true);
      }
    }
  }, [showSubSections]);

  return (
    <div className={classNames('nav-item', { 'nav-item--active': isActive })} onClick={handleView}>
      <Link key={section.to} to={section.to}>
        {tu(`general.${extractNavlinkDisplayName(section.to)}`)}
      </Link>
    </div>
  );
};

export default HamburgerMenuItemSection;
