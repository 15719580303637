import { FC } from 'react';

import Input from 'js/components/Form/components/Input';
import SelectApis from 'js/scenes/Freight/components/Users/components/SelectApis';
import SelectLanguage from 'js/scenes/Freight/components/Users/components/SelectLanguage';

import t, { tu } from 'js/utils/translate';

interface Props {
  parentName: string;
  index: number;
}

const CreateApiUserForm: FC<Props> = ({ parentName, index }) => {
  return (
    <div className='create-user__form-wrapper'>
      <Input label={tu('general.email')} name={`${parentName}[${index}]email`} type='email' />
      <SelectApis
        name={`${parentName}[${index}]apis`}
        placeholder={t('freight.shop.users.create.select.placeholder.apis')}
      />
      <SelectLanguage
        name={`${parentName}[${index}]language`}
        placeholder={t('freight.shop.users.create.select.placeholder.language')}
      />
    </div>
  );
};

export default CreateApiUserForm;
