import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as api from '../../api';
import { ApiShopFreightProductProperty } from 'src/interfaces/interfaces.generated';
import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';

export interface ShopFreightProductsReducerState {
  data: ApiShopFreightProductProperty[];
  error: unknown;
  isSaving: boolean;
  isLoading: boolean;
  shopId: number | undefined;
}

const initialState: ShopFreightProductsReducerState = {
  data: [],
  error: null,
  isSaving: false,
  isLoading: false,
  shopId: undefined
};

const shopFreightProductsSlice = createSlice({
  name: 'ShopFreightProducts',
  initialState,
  reducers: {
    getShopFreightProductsRequest(state, action) {
      state.isLoading = true;
      state.shopId = action.payload;
    },
    getShopFreightProductsSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    getShopFreightProductsFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.shopId = undefined;
    },
    editShopFreightProductsRequest(state) {
      state.error = null;
      state.isSaving = true;
    },
    editShopFreightProductsSuccess(state, action) {
      state.isSaving = false;
      state.data = action.payload;
    },
    editShopFreightProductsFailed(state, action) {
      state.error = action.payload;
      state.isSaving = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  editShopFreightProductsRequest,
  editShopFreightProductsSuccess,
  editShopFreightProductsFailed,
  getShopFreightProductsRequest,
  getShopFreightProductsSuccess,
  getShopFreightProductsFailed
} = shopFreightProductsSlice.actions;

export default shopFreightProductsSlice.reducer;

export const getShopFreightProducts =
  (shopId: number) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getShopFreightProductsRequest(shopId));
      const response: ApiShopFreightProductProperty[] = await api.getShopFreightProducts(shopId);
      dispatch(getShopFreightProductsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getShopFreightProductsFailed(error));
    }
  };

export const editShopFreightProducts =
  (shopId: number, freightProducts) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(editShopFreightProductsRequest());
      const response: ApiShopFreightProductProperty[] = await api.editShopFreightProducts(
        shopId,
        freightProducts
      );
      dispatch(editShopFreightProductsSuccess(response));
      return true;
    } catch (error) {
      dispatch(editShopFreightProductsFailed(error));
    }
  };

export const useShopFreightProducts = (): ShopFreightProductsReducerState =>
  useAppSelector((state: RootState) => state.freight.shop.shopFreightProducts);
