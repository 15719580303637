import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';
import LoginHeader from './LoginHeader';
import { StatusBar } from './components/StatusBar';
import MessageForUser from 'js/components/MessageForUser';
import ResetPasswordLink from 'js/components/ResetPasswordLink';

import { RootState } from 'js/store/reducer';
import { getSystemInfo } from 'js/scenes/Settings/components/SystemMessages/store';

import t from 'js/utils/translate';
import { UserInfo } from 'interfaces/interfaces.generated';
import './index.sass';

interface AppHeaderProps {
  isAuthenticated: boolean;
}

const AppHeader: FC<AppHeaderProps> = ({ isAuthenticated }) => {
  const dispatch = useAppDispatch();

  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const systemInfoLoaded = useAppSelector((state: RootState) => state.systemInfo.isLoaded);
  const systemInfo = useAppSelector((state: RootState) => state.systemInfo.data);
  const user: UserInfo = useAppSelector((state: RootState) => state?.user?.data);

  useEffect(() => {
    if (!systemInfoLoaded) dispatch(getSystemInfo());
  }, [systemInfoLoaded]);

  if (isAuthenticated) {
    return (
      <header>
        {isDesktop ? <DesktopHeader /> : <MobileHeader />}
        {systemInfo.length > 0 && <StatusBar {...systemInfo[0]} />}
        {user.showPasswordExpireWarning && (
          <MessageForUser variant='info'>
            <div>
              {t('password.expiration.message')}
              <ResetPasswordLink loginPage={false} />
            </div>
          </MessageForUser>
        )}
      </header>
    );
  }
  return (
    <header>
      <LoginHeader />
      {systemInfo.length > 0 && <StatusBar {...systemInfo[0]} />}
    </header>
  );
};

export default AppHeader;
