import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useFormContext, useValue } from '@distribution-innovation/form-hooks';

import Input from 'js/components/Form/components/Input';
import AddressHelper from 'js/components/Form/components/AddressHelper';
import CommunicationChannel from './components/CommunicationChannel';
import AddressCheckResult from './components/AddressCheckResult';
import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';

import { FreightProduct, TransportSolution } from 'src/interfaces/interfaces.generated';
import t, { tu } from 'js/utils/translate';
import {
  AddressCheckRequest,
  buildAddressCheckRequest,
  findFreightProductsInSelectedTransportSolution,
  freightProductsArePickup
} from './utils';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { RootState } from 'js/store/reducer';
import SelectV2 from 'js/components/Form/components/SelectV2';
import { servicePointOptionsWithId } from 'js/components/Select/utils';
import { loadServicePoints } from 'js/scenes/Orders/scenes/NewOrder/store/servicePointsReducer';

interface Props {
  party: 'consignor' | 'consignee';
  initialValues: any;
  setNextDisabled: Dispatch<SetStateAction<boolean>>;
  servicePointChanged?: (string) => void;
}

const PartyStep: FC<Props> = ({ party, initialValues, setNextDisabled, servicePointChanged }) => {
  const context = useFormContext();
  const dispatch = useAppDispatch();
  const values = context.getValues();
  const [partyValue, setPartyValue] = useValue(party);

  useEffect(() => {
    if (initialValues) {
      setPartyValue(initialValues);
    }
  }, []);

  const address = partyValue.address;
  const addressComplete = address?.isComplete;
  const name = partyValue.name;
  const externalId = partyValue.id;

  const isConsigneeStep = party === 'consignee';
  const isConsignorStep = party === 'consignor';

  const shopTransportSolutions: TransportSolution[] = useAppSelector(
    (state: RootState) => state.freight.shop.shopTransportSolutions.data
  );
  const freightProducts: FreightProduct[] = useAppSelector((state) => state.freightProducts?.data);

  const { data: servicePoints, areLoading: servicePointsAreLoading } = useAppSelector(
    (state: RootState) => state.orders?.servicePoints
  );

  const [addressCheckOrder, setAddressCheckOrder] = useState<AddressCheckRequest | undefined>();

  useEffect(() => {
    name ? setNextDisabled(false) : setNextDisabled(true);
  }, [name]);

  useEffect(() => {
    if (addressComplete) {
      setAddressCheckOrder(buildAddressCheckRequest(values, party));
    }
  }, [addressComplete]);

  useEffect(() => {
    if (externalId) {
      setAddressCheckOrder(buildAddressCheckRequest(values, party));
    }
  }, [externalId]);

  useEffect(() => {
    if (isConsigneeStep) {
      values.servicePointId = null;

      if (address?.zip) {
        dispatch(
          loadServicePoints(
            address?.zip,
            address?.countryCode,
            values?.shopId,
            values?.transportSolutionId
          )
        );
      }
    }
  }, [address?.zip]);

  const servicePointId = isConsigneeStep ? values?.servicePointId : null;

  useEffect(() => {
    servicePointChanged && servicePointChanged(servicePointId);
  }, [servicePointId]);

  const freightProductsInTransportSolution = findFreightProductsInSelectedTransportSolution(
    shopTransportSolutions,
    values.transportSolutionId,
    freightProducts
  );

  const shouldCheckConsignor = freightProductsArePickup(freightProductsInTransportSolution);

  const checkAddress =
    (isConsigneeStep && !shouldCheckConsignor) || (isConsignorStep && shouldCheckConsignor);

  const shouldShowServicePointSelection =
    isConsigneeStep && !servicePointsAreLoading && servicePoints.length > 0;

  return (
    <SplitPage className='new-order__party-step'>
      <PageWrapper>
        <h2>{`${t('orders.new.order.involved.parts')} - ${t(`orders.new.order.${party}`)}`}</h2>
        <Input name={`${party}.name`} label={tu('orders.new.order.name')} />
        <AddressHelper name={`${party}.address`} color='none' />
        {checkAddress && (
          <AddressCheckResult addressComplete={addressComplete} address={addressCheckOrder} />
        )}
        {shouldShowServicePointSelection && (
          <SelectV2
            name='servicePointId'
            label={tu('general.servicePoint')}
            options={servicePointOptionsWithId(servicePoints)}
            disabled={!address?.zip}
            isLoading={!!(address?.zip && servicePointsAreLoading)}
            placeholder={!servicePointsAreLoading && servicePointId ? t('select.servicePoint') : ''}
            optional={true}
          />
        )}
        <CommunicationChannel party={party} />
        <Input name={`${party}.co`} label={tu('orders.new.order.co')} optional />
        <div className='id-and-reference'>
          <Input className='id' name={`${party}.id`} label={tu('orders.new.order.id')} optional />
          <Input
            className='reference'
            name={`${party}.reference`}
            label={tu('orders.new.order.reference')}
            optional
          />
        </div>
      </PageWrapper>
    </SplitPage>
  );
};

export default PartyStep;
