import { FC } from 'react';

import Icon from 'js/components/Icon';
import Link from 'js/components/Link';

import { tu } from 'js/utils/translate';
import './index.sass';

interface TrackingProps {
  linkedParcelNumbers?: string[];
}

const Tracking: FC<TrackingProps> = ({ linkedParcelNumbers }) => (
  <div className='order-tracking'>
    {tu('tracking.associated.references', linkedParcelNumbers?.length)}
    <div className='dot'>
      <Icon icon='circle' />
    </div>
    <div className='order-tracking__references'>
      {linkedParcelNumbers?.map((linkedParcelNumber) => (
        <Link
          key={linkedParcelNumber}
          text={linkedParcelNumber}
          to={'/orderSearch/' + linkedParcelNumber}
          internal
        />
      ))}
    </div>
  </div>
);

export default Tracking;
