import { FC, useEffect } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';
import { ApiUser } from 'src/interfaces/interfaces.generated';

import Text from 'js/components/Form/components/Text';

interface Props {
  name: string;
  user?: ApiUser;
}

const FullName: FC<Props> = ({ name, user }) => {
  const context = useFormContext();

  useEffect(() => {
    if (user) {
      context.setValue(name, {
        firstName: user.firstName,
        lastName: user.lastName
      });
    }
  }, []);

  return (
    <div className='full-name'>
      <Text name={`${name}firstName`} />
      <Text name={`${name}lastName`} />
    </div>
  );
};

export default FullName;
