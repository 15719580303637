import React, { FC } from 'react';
import classNames from 'classnames';
import Error from './components/Error';

import './index.sass';
import Icon from 'js/components/Icon';
import * as amplitude from '@amplitude/analytics-browser';
import { Amplitude } from 'js/components/amplitude';

export interface ButtonProps {
  className?: string;
  name?: string;
  text?: string;
  loading?: boolean;
  disabled?: boolean;
  submit?: boolean;
  value?: any;
  onClick?: () => void;
  error?: boolean;
  icon?: string;
  variant?: 'primary' | 'inverse' | 'danger' | string;
}

const ButtonV2: FC<ButtonProps> = ({
  className,
  name,
  text,
  loading,
  disabled,
  submit,
  value,
  onClick,
  error,
  icon,
  variant = 'primary'
}) => {
  const doOnClick = () => {
    amplitude.track(Amplitude.BUTTON_CLICKED, {
      [Amplitude.BUTTON__CLASS]: className
    });
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={classNames('button-v2', className)}>
      <button
        name={name}
        className={
          disabled || loading ? `btn-v2 btn-v2--${variant}-disabled` : `btn-v2 btn-v2--${variant}`
        }
        type={submit ? 'submit' : 'button'}
        value={value}
        onClick={doOnClick}
        disabled={disabled || loading}
      >
        {loading && <Icon icon='spin' className='fa-spinner' />}
        {!loading && icon && <Icon icon={icon} />}
        {!loading && !icon && <span>{text}</span>}
      </button>
      {error && <Error />}
    </div>
  );
};

export default ButtonV2;
