import { FC, useEffect, useState } from 'react';
import { RootState } from 'js/store/reducer';

import PageWrapper from 'js/components/PageWrapper';
import Spinner from 'js/components/Spinner';
import LabelHistoryRow from './components/LabelHistoryRow';
import Pagination from 'js/components/Pagination';

import { getLabelHistory } from './store/labelHistoryReducer';
import { LabelGenerationHistory } from 'src/interfaces/interfaces.generated';
import t from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';

const ITEMS_PER_PAGE = 20;

const LabelHistory: FC = () => {
  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const labelHistory: LabelGenerationHistory[] = useAppSelector(
    (state: RootState) => state?.orders?.labelHistory?.data
  );
  const labelHistoryRows = useAppSelector(
    (state: RootState) => state?.orders?.labelHistory?.numRows
  );
  const labelHistoryLoaded: boolean = useAppSelector(
    (state: RootState) => state?.orders?.labelHistory?.isLoaded
  );

  const [activePage, setActivePage] = useState(1);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getLabelHistory(activePage)).then((response: boolean | undefined) => {
      if (response) window.scrollTo(0, 0);
    });
  }, [activePage]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  if (!labelHistoryLoaded) return <Spinner />;

  return (
    <PageWrapper withoutPadding={!isDesktop} className='label-history'>
      {labelHistory.length > 0 ? (
        <>
          {labelHistory?.map((labelExcerpt) => (
            <LabelHistoryRow key={labelExcerpt.labelGenerationId} labelExcerpt={labelExcerpt} />
          ))}
          <Pagination
            activePage={activePage}
            itemsCountPerPage={ITEMS_PER_PAGE}
            totalItemsCount={labelHistoryRows}
            onChange={handlePageChange}
          />
        </>
      ) : (
        <div>{t('orders.labelhistory.no.available.label.excerpts')}</div>
      )}
    </PageWrapper>
  );
};

export default LabelHistory;
