import { AdminRight } from '../../../interfaces';
import { ApiUser } from 'src/interfaces/interfaces.generated';

const hasAdminRight = (right: string, user) =>
  user?.adminRights?.some((adminRight: AdminRight) => adminRight === right);

export const buildUser = (user): ApiUser => ({
  userName: user.username,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  admin: hasAdminRight('admin', user),
  labelsAdmin: hasAdminRight('labels', user),
  qualityAdmin: hasAdminRight('quality', user),
  language: user.language
});
