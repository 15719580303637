import { FC } from 'react';
import { isEmpty } from 'lodash';

import InfoBox from 'js/components/InfoBox';
import ExternalCustomerIds from './components/ExternalCustomerIds';
import B2B from './components/B2B';
import AdditionalServices from './components/AdditionalServices';
import Other from './components/Other';
import { RootState } from 'js/store/reducer';

import { ApiFlowShop } from 'src/interfaces/interfaces.generated';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  shop: ApiFlowShop;
}

const ConfigurationBody: FC<Props> = ({ shop }) => {
  const freightProducts = useAppSelector((state: RootState) => state.freightProducts?.data);

  if (isEmpty(shop)) return <InfoBox text='ingen.butikk' />;

  const translateKeyPrefix = 'shop.accordion.config.header';

  return (
    <div className='configuration-body'>
      <ExternalCustomerIds
        translateKeyPrefix={translateKeyPrefix}
        shop={shop}
        freightProducts={freightProducts}
      />
      <B2B translateKeyPrefix={translateKeyPrefix} shop={shop} />
      <AdditionalServices translateKeyPrefix={translateKeyPrefix} shop={shop} />
      <Other translateKeyPrefix={translateKeyPrefix} shop={shop} />
    </div>
  );
};

export default ConfigurationBody;
