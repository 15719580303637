export default function AjaxError({ method, url, data, xhr }, timeout = false) {
  this.method = method;
  this.url = url;
  this.data = data;
  this.status = xhr.status;
  this.timeout = timeout;

  try {
    const response = JSON.parse(xhr.responseText);
    this.response = response;
    this.message = Array.isArray(response.details) ? response.details.join(' ') : response.message;
  } catch (_) {
    this.response = xhr.response;
    this.message = xhr.responseText;
  }
}

AjaxError.prototype = new Error(); // eslint-disable-line new-parens
AjaxError.prototype.name = 'AjaxError';
AjaxError.prototype.constructor = AjaxError;
