import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

import { ApiServiceSupplier } from 'src/interfaces/interfaces.generated';

export interface ServiceSuppliersForLabelsReducerState {
  error: unknown;
  isLoaded: boolean;
  data: ApiServiceSupplier[];
}

const initialState: ServiceSuppliersForLabelsReducerState = {
  error: null,
  isLoaded: false,
  data: []
};

const serviceSuppliersForLabelsSlice = createSlice({
  name: 'ServiceSuppliersForLabels',
  initialState,
  reducers: {
    getServiceSuppliersForLabelsRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getServiceSuppliersForLabelsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getServiceSuppliersForLabelsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  }
});

export const {
  getServiceSuppliersForLabelsRequest,
  getServiceSuppliersForLabelsSuccess,
  getServiceSuppliersForLabelsFailed
} = serviceSuppliersForLabelsSlice.actions;

export default serviceSuppliersForLabelsSlice.reducer;

export const getServiceSuppliersForLabels =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getServiceSuppliersForLabelsRequest());
      const response: ApiServiceSupplier[] = await rest.getServiceSuppliersForLabels();
      dispatch(getServiceSuppliersForLabelsSuccess(response));
      return true;
    } catch (err) {
      dispatch(getServiceSuppliersForLabelsFailed(err));
    }
  };
