import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import SelectV2 from 'js/components/Form/components/SelectV2';

import { getFreightProducts } from 'js/store/freightProductsReducer';

import { freightProductOptions } from 'js/components/Select/utils';

import { tu } from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface Props {
  name: string;
  disabled?: boolean;
}

const SelectFreightProducts: FC<Props> = ({ name, disabled }) => {
  const dispatch = useAppDispatch();

  const freightProducts = useAppSelector((state: RootState) => state?.freightProducts?.data);
  const freightProductsLoaded = useAppSelector(
    (state: RootState) => state?.freightProducts?.isLoaded
  );

  useEffect(() => {
    if (!freightProductsLoaded) dispatch(getFreightProducts());
  }, []);

  return (
    <SelectV2
      name={name}
      isMulti
      selectAll
      label={tu('general.freightProducts')}
      options={freightProductOptions(freightProducts)}
      isLoading={!(disabled ?? freightProductsLoaded)}
      disabled={disabled}
    />
  );
};

export default SelectFreightProducts;
