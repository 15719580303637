import sprintf from 'sprintf-js';
import * as Sentry from '@sentry/react';
import NO from 'i18n/nb';
import EN from 'i18n/en';
import SV from 'i18n/sv';
import { lowerCaseFirst } from './string';

const translations = { NO, EN, SV };

const fallback = 'NO';

export let language = fallback;

export const setLanguage = (selectedLang) => (language = selectedLang);

if (!translations[language]) language = fallback;

const translationError = (key) => {
  const err = new Error('TranslationError');
  err.name = 'TranslationError';
  err.message = `Translation missing for key: "${key}"`;
  return err;
};

const translation = (key) => translations?.[language]?.[key] || translations?.[fallback]?.[key];

const translate = (key, ...args) => {
  if (translation(key)) {
    return args.length ? sprintf.sprintf(translation(key), ...args) : translation(key);
  } else {
    console.warn(translationError(key));
    Sentry.captureMessage(`Translation missing for key: "${key}"`);
    return key;
  }
};

export const tu = (key, ...args) => translate(key, ...args).toUpperCase();

export const tLowerFirst = (key, ...args) => lowerCaseFirst(translate(key, ...args));

export const languages = [
  { label: 'no', value: 'NO' },
  { label: 'en', value: 'EN' },
  { label: 'sv', value: 'SV' }
];

export default translate;
