import TableV2 from 'js/components/TableV2';
import { FC, useMemo } from 'react';
import t, { tu } from 'js/utils/translate';
import { ApiPriceChangeStatistic } from 'src/interfaces/interfaces.generated';
import { useAppSelector } from 'js/utils/hooks';

interface CostPriceHistoryTableProps {
  priceChangeStatistics: ApiPriceChangeStatistic[] | any;
}

const CostPriceHistoryTable: FC<CostPriceHistoryTableProps> = ({ priceChangeStatistics }) => {
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);
  const translatePrefix = 'economy.costprice.table.header';

  const changeUser = (priceChangeStatistics) =>
    priceChangeStatistics.changeUserFirstName
      ? `${priceChangeStatistics.changeUserFirstName} ${priceChangeStatistics.changeUserLastName}`
      : '-';

  const columns = useMemo(
    () => [
      {
        Header: t(`${translatePrefix}.previous.dates`),
        accessor: (priceChangeStatistics) =>
          `${priceChangeStatistics.fromDate}${
            priceChangeStatistics.toDate ? `-${priceChangeStatistics.toDate}` : ''
          }`,
        inHeader: true
      },
      {
        Header: t(`${translatePrefix}.executed.by`),
        accessor: (priceChangeStatistics) =>
          isDesktop ? (
            changeUser(priceChangeStatistics)
          ) : (
            <div>
              <div>{tu(`${translatePrefix}.executed.by`)}</div>
              <div>{changeUser(priceChangeStatistics)}</div>
            </div>
          )
      },
      {
        Header: t('general.username'),
        accessor: (priceChangeStatistics) =>
          isDesktop ? (
            priceChangeStatistics.changeUserUserName
          ) : (
            <div>
              <div>{tu('general.username')}</div>
              <div>{priceChangeStatistics.changeUserUserName}</div>
            </div>
          )
      }
    ],
    [isDesktop]
  );

  return (
    <TableV2
      className='cost-price-history-table'
      columns={columns}
      data={priceChangeStatistics}
      idAccessor='priceAdjustmentId'
      mobileTableVariant='withSmallEditIcon'
    />
  );
};

export default CostPriceHistoryTable;
