// eslint-disable-next-line no-unused-vars
import { FC, useEffect, useState } from 'react';
import { RootState } from 'js/store/reducer';

import ConcernsTable from './components/ConcernsTable';
import SearchFilter from 'js/components/SearchFilter';
import Spinner from 'js/components/Spinner';

import t from 'js/utils/translate';
import { filterConcerns } from './utils';
import { getConcerns } from 'js/scenes/Freight/scenes/Concerns/store/getConcernsReducer';
import { getShops } from 'js/scenes/Freight/store/shopsReducer';

import { ApiConcern, ApiFlowShopSimple } from 'interfaces/interfaces.generated';
import { TabName } from 'js/scenes/Freight/interfaces';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface ConcernsProps {
  activeTabName: TabName;
  shops: ApiFlowShopSimple[];
}

const Concerns: FC<ConcernsProps> = ({ shops, activeTabName }) => {
  const rowIcon = 'pencil-alt';
  const dispatch = useAppDispatch();

  const concerns: ApiConcern[] = useAppSelector(
    (state: RootState) => state?.freight?.concerns?.getConcerns?.data
  );
  const concernsLoaded: boolean = useAppSelector(
    (state: RootState) => state.freight?.concerns?.getConcerns?.isLoaded
  );
  const shopsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shops?.isLoaded
  );

  useEffect(() => {
    if (!concernsLoaded) dispatch(getConcerns());
    if (!shopsLoaded) dispatch(getShops());
  }, []);

  const [search, setSearch] = useState('');
  const [filteredConcerns, setFilteredConcerns] = useState(concerns);

  useEffect(() => {
    setFilteredConcerns(filterConcerns(concerns, shops, search.toLowerCase()));
  }, [search, concerns, shops]);

  if (!concernsLoaded || !shopsLoaded) return <Spinner />;

  return (
    <div>
      <SearchFilter
        info={t(`freight.search.${activeTabName}.info`)}
        inputValue={search}
        setSearch={setSearch}
      />
      <ConcernsTable
        shops={shops}
        concerns={filteredConcerns}
        rowIcon={rowIcon}
        concernsLoaded={concernsLoaded}
      />
    </div>
  );
};

export default Concerns;
