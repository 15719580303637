import { addDays, format, subDays } from 'date-fns';

export const safariSafeFormatting = (date: string, formatString: string, options?: any) =>
  format(new Date(date.split(' ').join('T')), formatString, options);
export const safariSafeDateString = (date: string | undefined) =>
  date ? date.split(' ').join('T') : '';

export const formatDate = (date) => format(new Date(date), 'dd.MM.yyyy');
export const formatTime = (date) => format(new Date(date), 'HH:mm');

export const tomorrow = (): Date => addDays(new Date(), 1);

export const yesterday = (): Date => subDays(new Date(), 1);
