/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import { Radio as _Radio } from '@distribution-innovation/form-hooks';

import './index.sass';

interface Props {
  name: string;
  label: string;
  value: string;
  convert?: any;
}

const Radio: FC<Props> = ({ name, label, value, convert }) => (
  <div className='radio'>
    <label>
      <_Radio value={value ? String(value) : ''} name={name} convert={convert} />
      <span className='styled-radio'>{label}</span>
    </label>
  </div>
);

export default Radio;
