import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface WeightGroupReducerState {
  error: unknown;
  data: any[];
  isLoaded: boolean;
}

const initialState: WeightGroupReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const weightGroupsSlice = createSlice({
  name: 'WeightGroups',
  initialState,
  reducers: {
    getWeightGroupsRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getWeightGroupsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getWeightGroupsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  }
});

export const { getWeightGroupsRequest, getWeightGroupsSuccess, getWeightGroupsFailed } =
  weightGroupsSlice.actions;

export default weightGroupsSlice.reducer;

export const getWeightGroups = () => async (dispatch) => {
  try {
    dispatch(getWeightGroupsRequest());
    const weightGroups = await rest.getWeightGroups();
    dispatch(getWeightGroupsSuccess(weightGroups));
  } catch (err) {
    dispatch(getWeightGroupsFailed(err));
  }
};
