import { FC, useEffect } from 'react';
import t from 'js/utils/translate';
import MessageForUser from 'js/components/MessageForUser';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { RootState } from 'js/store/reducer';
import FormV2 from 'js/components/FormV2';
import { reloadShop } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/shopReducer';
// eslint-disable-next-line max-len
import EditShopProofOfDeliveryHeader from 'js/scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditShopProofOfDelivery/components/EditShopProofOfDeliveryHeader';
// eslint-disable-next-line max-len
import EditShopProofOfDeliveryRow from 'js/scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditShopProofOfDelivery/components/EditShopProofOfDeliveryRow';
import { useNavigate, useParams } from 'react-router-dom';
import {
  editShopProofOfDelivery,
  getShopProofOfDelivery,
  useShopProofOfDelivery
} from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/proofOfDeliveryReducer';
import Spinner from 'js/components/Spinner';

const EditShopProofOfDelivery: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const shopId = Number(params?.shopId);

  const shopProofOfDeliveryState = useShopProofOfDelivery();
  const shopProofOfDeliveryData = shopProofOfDeliveryState.data;
  const isLoading = shopProofOfDeliveryState.isLoading;
  const isSaving = shopProofOfDeliveryState.isSaving;
  const error = shopProofOfDeliveryState.error;

  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  useEffect(() => {
    if (shopId) {
      dispatch(getShopProofOfDelivery(shopId));
    }
  }, []);

  const redirectTo = shopId ? `/freight/shop/${shopId}` : '/freight';

  const handleSubmit = (values) => {
    if (shopId) {
      dispatch(editShopProofOfDelivery(shopId, values.shopProofOfDeliveryData)).then(() => {
        dispatch(reloadShop(shopId));
        navigate(redirectTo);
      });
    }
  };

  if (!shopId || isLoading || !shopProofOfDeliveryData) {
    return <Spinner />;
  }

  return (
    <div className={classNames('edit-users-page', { 'page-padding': isDesktop })}>
      <MessageForUser variant='info' message={t('freight.shop.edit.proofOfDelivery.infoMessage')} />
      <FormV2
        className='edit-shop-proof-of-delivery-form'
        onSubmit={handleSubmit}
        initialValues={{ shopProofOfDeliveryData }}
        loading={isSaving}
        redirectTo={redirectTo}
        error={error}
      >
        {isDesktop && <EditShopProofOfDeliveryHeader />}
        {shopProofOfDeliveryData.map((pod, index) => (
          <EditShopProofOfDeliveryRow
            key={pod.freightProductId}
            proofOfDelivery={pod}
            isDesktop={isDesktop}
            index={index}
          />
        ))}
      </FormV2>
    </div>
  );
};
export default EditShopProofOfDelivery;
