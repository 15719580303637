import { Column } from 'js/components/TableV2/interfaces';
import { User } from 'js/scenes/UserAdministration/Users.types';
import LimitedTagList from 'js/components/Tag/LimitedTagList';
import DateTime from 'js/components/DateTime';
import {
  displayMaxAmountOfTags,
  webServiceTranslationPrefix
} from 'js/scenes/UserAdministration/utils/constants';
import LastLoggedIn from 'js/scenes/UserAdministration/components/LastLoggedIn/LastLoggedIn';
import Shops from 'js/scenes/UserAdministration/components/Shops/Shops';

// Column definition
const name = (translationFn: (key: string) => string) => ({
  Header: translationFn('general.name'),
  accessor: (row) => `${row?.firstName} ${row?.lastName}`
});
const username = (translationFn: (key: string) => string) => ({
  Header: translationFn('general.username'),
  inHeader: true,
  accessor: (row) => row?.username
});
const email = (translationFn: (key: string) => string) => ({
  Header: translationFn('general.email'),
  accessor: (row) => row?.email
});
const shops = (translationFn: (key: string) => string) => ({
  Header: translationFn('general.shops'),
  accessor: (row) => ({
    allShopsAccess: row.allShopsAccess,
    shops: row?.shops.map((shop) => shop.name)
  }),
  Cell: (cellProps) => (
    <Shops
      allShopsAccess={cellProps.cell.value.allShopsAccess}
      shops={cellProps.cell.value.shops}
    />
  )
});
const roles = (translationFn: (key: string) => string) => ({
  Header: translationFn('general.roles'),
  accessor: (row) => row?.roles.map((role) => role.name),
  Cell: (cellProps) => (
    <LimitedTagList objects={cellProps.cell.value} limit={displayMaxAmountOfTags} />
  )
});
const apiAccess = (translationFn: (key: string) => string) => ({
  Header: translationFn('general.apiAccess'),
  accessor: (row) => row?.webServices,
  Cell: (cellProps) => (
    <LimitedTagList
      objects={cellProps.cell.value}
      limit={displayMaxAmountOfTags}
      translationKeyPrefix={webServiceTranslationPrefix}
    />
  )
});
const lastLoggedIn = (translationFn: (key: string) => string) => ({
  Header: translationFn('userAdministration.header.lastLoggedIn'),
  accessor: (row) => row?.lastLoggedIn,
  Cell: (cellProps) =>
    cellProps.cell.value ? <DateTime datetime={new Date(cellProps.cell.value)} /> : <LastLoggedIn />
});

// Users list
export const flowUsersColumns = (translationFn: (key: string) => string): Array<Column<User>> => [
  name(translationFn),
  username(translationFn),
  email(translationFn),
  shops(translationFn),
  roles(translationFn),
  lastLoggedIn(translationFn)
];

export const apiUsersColumns = (translationFn: (key: string) => string): Array<Column<User>> => [
  username(translationFn),
  email(translationFn),
  shops(translationFn),
  roles(translationFn),
  apiAccess(translationFn),
  lastLoggedIn(translationFn)
];

// Connect User
export const flowUserFormColumns = (
  translationFn: (key: string) => string
): Array<Column<User>> => [
  name(translationFn),
  username(translationFn),
  email(translationFn),
  shops(translationFn),
  roles(translationFn)
];

export const apiUserFormColumns = (translationFn: (key: string) => string): Array<Column<User>> => [
  username(translationFn),
  email(translationFn),
  shops(translationFn),
  roles(translationFn),
  apiAccess(translationFn)
];
