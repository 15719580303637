import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../api';
import { ApiFreightPrice, TransportPriceTable } from 'src/interfaces/interfaces.generated';

export interface CostPricesReducerState {
  isLoaded: boolean;
  isSaving: boolean;
  data: TransportPriceTable;
  error: unknown;
  deleteError: unknown;
}

const initialState: CostPricesReducerState = {
  isLoaded: false,
  isSaving: false,
  data: {},
  error: null,
  deleteError: null
};

const costPriceSlice = createSlice({
  name: 'CostPrices',
  initialState,
  reducers: {
    getCostPricesRequest(state) {
      state.isLoaded = false;
    },
    getCostPricesSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getCostPricesFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    },
    deleteCostPricesRequest(state) {
      state.isLoaded = false;
    },
    deleteCostPricesSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    deleteCostPricesFailed(state, action) {
      state.isLoaded = false;
      state.deleteError = action.payload;
    },
    saveCostPricesRequest(state) {
      state.isSaving = true;
    },
    saveCostPricesSuccess(state, action) {
      state.isSaving = false;
      state.data = action.payload;
    },
    saveCostPricesFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    }
  }
});

export const {
  getCostPricesRequest,
  getCostPricesSuccess,
  getCostPricesFailed,
  deleteCostPricesRequest,
  deleteCostPricesSuccess,
  deleteCostPricesFailed,
  saveCostPricesRequest,
  saveCostPricesSuccess,
  saveCostPricesFailed
} = costPriceSlice.actions;

export default costPriceSlice.reducer;

export const getCostPrices =
  (shopId: number, freightProductId: number) =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(getCostPricesRequest());
      const response: TransportPriceTable = await rest.getCostPrices(shopId, freightProductId);
      dispatch(getCostPricesSuccess(response));
    } catch (error) {
      dispatch(getCostPricesFailed(error));
    }
  };

export const deleteCostPrices =
  (shopId: number, freightProductId: number, fromDate: string | undefined) =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(deleteCostPricesRequest());
      const response: TransportPriceTable = await rest.deleteCostPrices(
        shopId,
        freightProductId,
        fromDate
      );
      dispatch(deleteCostPricesSuccess(response));
    } catch (error) {
      dispatch(deleteCostPricesFailed(error));
    }
  };

export const saveCostPrices =
  (shopId: number, freightProductId: number, fromDate: string, priceMatrix: ApiFreightPrice[]) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(saveCostPricesRequest());
      const response: TransportPriceTable = await rest.saveCostPrices(
        shopId,
        freightProductId,
        fromDate,
        priceMatrix
      );
      dispatch(saveCostPricesSuccess(response));
      return true;
    } catch (error) {
      dispatch(saveCostPricesFailed(error));
    }
  };
