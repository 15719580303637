import SelectV2 from 'js/components/Form/components/SelectV2';

import { tu } from 'js/utils/translate';
import { mainCarNumberOptions, mainCarPlannedDepartureOptions } from 'js/components/Select/utils';

import './index.sass';

const Cars = ({ mainCarNumbers, mainCarPlannedDepartures }) => (
  <div className='main-car'>
    <SelectV2
      name='mainCarNumbers'
      options={mainCarNumberOptions(mainCarNumbers)}
      label={tu('orders.labels.main.car.numbers')}
      optional
      isMulti
      selectAll
    />
    <SelectV2
      name='mainCarPlannedDepartures'
      options={mainCarPlannedDepartureOptions(mainCarPlannedDepartures)}
      label={tu('orders.labels.main.car.planned.departure')}
      optional
      isMulti
      selectAll
    />
  </div>
);

export default Cars;
