import ColoredSectionWithHeader from '../../../ColoredSectionWithHeader';

import { tu } from 'js/utils/translate';
import { showCheckmarkWithNameIfActive } from '../../../../utils';

const Other = ({ translateKeyPrefix, shop }) => {
  const otherSection = {
    header: `${translateKeyPrefix}.other`,
    dataAccessors: ['passiveReturn', 'vip']
  };

  return (
    <ColoredSectionWithHeader header={tu(otherSection.header)}>
      {otherSection.dataAccessors.map((dataAccessor) => (
        <div key={dataAccessor}>
          {showCheckmarkWithNameIfActive(shop[dataAccessor], dataAccessor)}
        </div>
      ))}
    </ColoredSectionWithHeader>
  );
};

export default Other;
