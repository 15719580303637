import { FC, useState } from 'react';
import { RootState } from 'js/store/reducer';

import ButtonV2 from 'js/components/ButtonV2';

import { ApiInvoiceBatch } from 'src/interfaces/interfaces.generated';
import { getInvoiceBatches } from '../../../../../../store/getInvoiceBatchesReducer';
import t from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import { approveInvoiceBatch, disapproveInvoiceBatch } from './store/handleInvoiceBatchReducer';

interface Props {
  invoiceBatchId: number;
  disabled: boolean;
  activePage?: number;
  context: 'approve' | 'disapprove';
}

const HandleInvoice: FC<Props> = ({ invoiceBatchId, disabled, activePage, context }) => {
  const dispatch = useAppDispatch();

  const handledInvoiceBatchId = useAppSelector(
    (state: RootState) => state?.economy?.invoiceBatches?.handleInvoiceBatch?.id
  );
  const isHandling = useAppSelector(
    (state: RootState) => state?.economy?.invoiceBatches?.handleInvoiceBatch?.isHandling
  );
  const error = useAppSelector(
    (state: RootState) => state?.economy?.invoiceBatches?.handleInvoiceBatch?.error
  );

  const [clicked, setClicked] = useState('');

  const handleClick = () => {
    setClicked(context);
    if (context === 'approve') {
      dispatch(approveInvoiceBatch(invoiceBatchId)).then((response?: ApiInvoiceBatch) => {
        if (response && activePage) dispatch(getInvoiceBatches(activePage));
      });
    }
    if (context === 'disapprove') {
      dispatch(disapproveInvoiceBatch(invoiceBatchId)).then((response?: ApiInvoiceBatch) => {
        if (response && activePage) dispatch(getInvoiceBatches(activePage));
      });
    }
  };

  const isCurrentInvoiceBatch = invoiceBatchId === handledInvoiceBatchId;

  return (
    <ButtonV2
      className={`button-${context}`}
      name={`${context}-button-${invoiceBatchId}`}
      text={t(`general.${context}`)}
      variant={context === 'disapprove' ? 'danger' : 'inverse'}
      disabled={disabled}
      loading={context === clicked && isHandling && isCurrentInvoiceBatch}
      error={!!(context === clicked && error && isCurrentInvoiceBatch)}
      onClick={handleClick}
    />
  );
};

export default HandleInvoice;
