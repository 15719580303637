import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';
import { ApiCustomerSystem } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';

export interface CreateCustomerSystemReducerState {
  error: unknown;
  isCreating: boolean;
}

const initialState: CreateCustomerSystemReducerState = {
  error: null,
  isCreating: false
};

const createCustomerSystemSlice = createSlice({
  name: 'CreateCustomerSystem',
  initialState,
  reducers: {
    createCustomerSystemRequest(state) {
      state.error = null;
      state.isCreating = true;
    },
    createCustomerSystemSuccess(state) {
      state.isCreating = false;
    },
    createCustomerSystemFailed(state, action) {
      state.isCreating = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  createCustomerSystemRequest,
  createCustomerSystemSuccess,
  createCustomerSystemFailed
} = createCustomerSystemSlice.actions;

export default createCustomerSystemSlice.reducer;

export const createCustomerSystem =
  (customerSystem: ApiCustomerSystem) =>
  async (dispatch): Promise<number | undefined> => {
    try {
      dispatch(createCustomerSystemRequest());
      const response: ApiCustomerSystem = await api.newCustomerSystem(customerSystem);
      dispatch(createCustomerSystemSuccess());
      return response.customerSystemId;
    } catch (error) {
      dispatch(createCustomerSystemFailed(error));
    }
  };
