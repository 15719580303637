import { FC } from 'react';
import classnames from 'classnames';
import { RootState } from 'js/store/reducer';

import Label from 'js/components/Label';

import { LabelGenerationHistory } from 'src/interfaces/interfaces.generated';
import t from 'js/utils/translate';
import { LabelStatus } from '../../../../../../interfaces';

import { timeToGenerate } from './utils';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  labelExcerpt: LabelGenerationHistory;
  className: string;
  labelStatus?: LabelStatus;
}

const TimeAndAmountOfLabels: FC<Props> = ({ labelExcerpt, className, labelStatus }) => {
  const selectedLanguage = useAppSelector((state: RootState) => state?.user?.data?.language);

  return (
    <div className={classnames('time-and-amount', className)}>
      <Label label={`${t('orders.labelhistory.time')} / ${t('orders.labelhistory.amount')}`} />
      <div className='time-and-amount__text'>
        {`${timeToGenerate(labelStatus, labelExcerpt, selectedLanguage)} 
        / ${t('orders.labelhistory.amount.generated', labelExcerpt.labelsGenerated ?? 0)}`}
      </div>
    </div>
  );
};

export default TimeAndAmountOfLabels;
