import Ajax from 'js/utils/ajax/ajax';

export const getOrder = (trackingReference: string) =>
  Ajax.get(`/orders/v1?customer=&reference=&identifier=${trackingReference}`);

export const getBudBuddyImages = (trackingReference: string) =>
  Ajax.get(`/orders/v2/${trackingReference}/budbuddyimgs`);

export const getLinkedParcelNumbers = (trackingReference: string) =>
  Ajax.get(`/orders/v2/${trackingReference}/linkedparcelnumbers`);

export const getPackageInfo = (trackingReference: string) =>
  Ajax.get(`/packages/v1/info/${trackingReference}`);
