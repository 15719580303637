import { FC } from 'react';

import FormattedDate from 'js/components/DateTime/components/FormattedDate';
import InvoiceBatchCustomer from './components/InvoiceBatchCustomer';
import InvoiceBatchesTable from 'js/scenes/Economy/components/InvoiceBatchesTable';
import Spinner from 'js/components/Spinner';

import { ApiFlowShopSimple, ApiInvoiceBatch } from 'src/interfaces/interfaces.generated';
import { Column } from 'js/scenes/Economy/components/InvoiceBatchesTable/interfaces';
import { RootState } from 'js/store/reducer';

import t from 'js/utils/translate';
import { useAppSelector } from 'js/utils/hooks';

interface Props {
  invoiceBatches: ApiInvoiceBatch[];
  shops: ApiFlowShopSimple[];
}

const ProcessedInvoicesForShopTable: FC<Props> = ({ invoiceBatches, shops }) => {
  const translatePrefix = 'economy.invoice.processed.table.header';

  const invoiceBatchesLoading: boolean = useAppSelector(
    (state: RootState) => state?.economy?.processedInvoiceBatchesForShop?.isLoading
  );

  const columns: Column[] = [
    {
      Header: t(`${translatePrefix}.fromDate`),
      accessor: (invoiceBatch) =>
        invoiceBatch.periodFrom && <FormattedDate datetime={new Date(invoiceBatch.periodFrom)} />
    },
    {
      Header: t(`${translatePrefix}.toDate`),
      accessor: (invoiceBatch) =>
        invoiceBatch.periodTo && <FormattedDate datetime={new Date(invoiceBatch.periodTo)} />
    },
    {
      Header: t(`${translatePrefix}.customer`),
      accessor: (invoiceBatch) => (
        <InvoiceBatchCustomer invoices={invoiceBatch.invoices} shops={shops} />
      )
    }
  ];

  if (invoiceBatchesLoading) return <Spinner />;

  return <InvoiceBatchesTable invoiceBatches={invoiceBatches} context='shop' columns={columns} />;
};

export default ProcessedInvoicesForShopTable;
