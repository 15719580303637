import { FC } from 'react';

import Icon from 'js/components/Icon';

import './index.sass';

interface Props {
  onClick?: () => void;
}

const EditButton: FC<Props> = ({ onClick }) => (
  <button className='edit-button' onClick={onClick}>
    <Icon icon='pencil-alt' />
  </button>
);

export default EditButton;
