import { FC } from 'react';

import { findShopById } from 'js/utils/find';
import { findInvoiceShopId } from './utils';

import { ApiFlowShopSimple, ApiInvoice } from 'src/interfaces/interfaces.generated';

import './index.sass';

interface Props {
  invoices?: ApiInvoice[];
  shops: ApiFlowShopSimple[];
}

const InvoiceBatchCustomer: FC<Props> = ({ invoices, shops }) => {
  const shop = findShopById(shops, findInvoiceShopId(invoices, 'shopId'));

  return <div className='invoice-batch-customer'>{shop?.name ?? '-'}</div>;
};

export default InvoiceBatchCustomer;
