import { FC, Dispatch, SetStateAction } from 'react';

import LabeledInput from 'js/components/LabeledInput';

import { tu } from 'js/utils/translate';

import './index.sass';

interface Props {
  inputValue: string;
  setSearch: Dispatch<SetStateAction<string>>;
  info?: string;
}

const SearchFilter: FC<Props> = ({ inputValue, setSearch, info }) => (
  <LabeledInput
    className='search-filter'
    label={tu('general.search')}
    info={info}
    value={inputValue}
    autoFocus='autofocus'
    onChange={(e) => {
      setSearch(e.target.value);
    }}
  />
);

export default SearchFilter;
