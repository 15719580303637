import { ApiFreightPrice } from 'src/interfaces/interfaces.generated';
import { PriceType, FormValues } from '../interfaces';
import { uniq } from 'lodash';
import { format } from 'date-fns';

export const findWeightIds = (
  priceMatrix: ApiFreightPrice[][] | undefined
): Array<number | undefined> | undefined =>
  priceMatrix?.map((weightClass) => weightClass[0].weightId);

export const buildFreightPrice = (
  costPrice: number | undefined,
  fromDate: Date | undefined,
  priceType: string | undefined,
  weightId: number | undefined,
  zoneId: number | undefined
): ApiFreightPrice => ({
  consumerPrice: undefined,
  costPrices: [
    {
      costPrice,
      fromDate: fromDate ? format(fromDate, 'yyyy-MM-dd') : undefined,
      priceType
    }
  ],
  priceType,
  weightId,
  zoneId
});

export const findInitialFreightPrices = (priceMatrix: ApiFreightPrice[][]): ApiFreightPrice[] =>
  priceMatrix?.flatMap((weightClass) =>
    weightClass.flatMap((zone) =>
      zone?.costPrices && zone?.costPrices?.length > 0
        ? zone.costPrices.map((price) =>
            buildFreightPrice(
              price.costPrice,
              undefined,
              price.priceType,
              zone.weightId,
              zone.zoneId
            )
          )
        : []
    )
  );

const mapFromPriceTypeIndexToPriceType = (
  priceTypeIndex: number | undefined,
  hasDifferentSizes: boolean
): string => {
  switch (priceTypeIndex) {
    case 0:
      if (hasDifferentSizes) return 'SMALL';
      return 'ALL';
    case 1:
      return 'MEDIUM';
    case 2:
      return 'LARGE';
    default:
      return 'ALL';
  }
};

export const findWeightClassPricesForAllZones = (
  priceMatrix: ApiFreightPrice[][],
  priceTypes: PriceType[]
): Array<Array<number | undefined>> =>
  priceTypes.map((priceType) =>
    priceMatrix.map((weightClass) => {
      const zonePrices = weightClass.flatMap((zone) =>
        zone?.costPrices ? zone.costPrices.filter((price) => price.priceType === priceType) : []
      );
      if (zonePrices.length > 0) {
        const zoneCostPrices = zonePrices.map(({ costPrice }) => costPrice);
        if (uniq(zoneCostPrices).length === 1) return uniq(zoneCostPrices)[0];
        return undefined;
      } else return 0;
    })
  );

const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

const nullifyList = (list: any[]) => list.map((element) => element || null);

const nullifyAll = (lists: any[][]) => lists.map((list) => nullifyList(list));

const priceWasChanged = (price, originalPrice) => price !== originalPrice;

const buildAllZonesPrices = (
  pricesForAllZones: Array<Array<number | undefined>>,
  originalPricesForAllZones: Array<Array<number | undefined>>,
  zones: string[],
  fromDate: Date,
  hasDifferentSizes: boolean,
  weightIds: Array<number | undefined>
): ApiFreightPrice[] =>
  pricesForAllZones.flatMap((prices, priceTypeIndex) =>
    prices.flatMap((price, weightClassIndex) => {
      if (
        priceWasChanged(price, originalPricesForAllZones[priceTypeIndex][weightClassIndex]) &&
        !(price === null || price === undefined)
      ) {
        return zones.map((zoneId) =>
          buildFreightPrice(
            price,
            fromDate,
            mapFromPriceTypeIndexToPriceType(priceTypeIndex, hasDifferentSizes),
            weightIds[weightClassIndex],
            +zoneId
          )
        );
      } else return [];
    })
  );

const buildFreightPricesWithDate = (freightPrices, fromDate) =>
  freightPrices.map(({ costPrices, priceType, weightId, zoneId }) =>
    buildFreightPrice(costPrices?.[0].costPrice, fromDate, priceType, weightId, zoneId)
  );

export const buildSubmit = (
  { weightClassPricesForAllZones, freightPrices, fromDate }: FormValues,
  zones: string[],
  originalWeightClassPricesForAllZones: any[][],
  hasDifferentSizes: boolean,
  weightIds: Array<number | undefined>
): ApiFreightPrice[] => {
  const pricesForAllZones = nullifyAll(weightClassPricesForAllZones);
  const originalPricesForAllZones = nullifyAll(originalWeightClassPricesForAllZones);
  if (equals(pricesForAllZones, originalPricesForAllZones)) {
    return buildFreightPricesWithDate(freightPrices, fromDate);
  } else {
    const allZonesPrices = buildAllZonesPrices(
      pricesForAllZones,
      originalPricesForAllZones,
      zones,
      fromDate,
      hasDifferentSizes,
      weightIds
    );
    const filtered = buildFreightPricesWithDate(freightPrices, fromDate).filter(
      (freightPrice) =>
        !allZonesPrices.some(
          (allZonesPrice) =>
            allZonesPrice.weightId === freightPrice.weightId &&
            allZonesPrice.priceType === freightPrice.priceType
        )
    );
    return [...filtered, ...allZonesPrices];
  }
};
