import { FC, useEffect } from 'react';
import { useValue } from '@distribution-innovation/form-hooks';
import Input from 'js/components/Form/components/Input';
import Select from 'js/components/Form/components/SelectV2';
import t, { tu } from 'js/utils/translate';

import './index.sass';
import { ApiFlowShop } from 'src/interfaces/interfaces.generated';
import {
  getShopFreightProducts,
  useShopFreightProducts
} from '../../../../../../store/shopFreightProductReducer';
import { RootState } from 'js/store/reducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

export interface Props {
  shop: ApiFlowShop;
}

const ExternalCustomerNumbers: FC<Props> = ({ shop }) => {
  const dispatch = useAppDispatch();
  const [customerNumbers, setCustomerNumbers] = useValue('customerNumbers');
  const shopFreightProductsState = useShopFreightProducts();
  const shopFreightProducts = shopFreightProductsState.data;
  const allFreightProducts = useAppSelector((state: RootState) => state.freightProducts?.data);

  useEffect(() => {
    if (shop.shopId) {
      dispatch(getShopFreightProducts(shop.shopId));
    }
  }, []);

  const add = () => {
    const numbers = [...customerNumbers];
    numbers.push({
      shopId: shop.shopId,
      freightProductId: 0,
      customerNumber: ''
    });
    setCustomerNumbers(numbers);
  };

  const remove = (indexToRemove: number) => {
    const numbers = customerNumbers.filter((e, index) => index !== indexToRemove);
    setCustomerNumbers(numbers);
  };

  // Filter out already configured freightproducts, so it's not possible to have duplicates
  const options = (freightProductId: number) => {
    const alreadyConfigured = (fpId?: number) =>
      customerNumbers.map((cn) => cn.freightProductId).includes(fpId);
    const currentlySelected = (fpId?: number) => fpId === freightProductId;
    const findName = (fpId?: number) =>
      allFreightProducts.find((afp) => afp.freightProductId === fpId)?.name;

    return shopFreightProducts
      .filter(
        (fp) => currentlySelected(fp.freightProductId) || !alreadyConfigured(fp.freightProductId)
      )
      .map((fp) => ({
        value: fp.freightProductId,
        label: findName(fp.freightProductId)
      }));
  };

  return (
    <div className='external-customer'>
      {customerNumbers.map((cn, index) => (
        <div className='external-customer__customer-id' key={`${index}-${cn.freightProductId}`}>
          <Select
            name={`customerNumbers[${index}]freightProductId`}
            label={tu('shop.accordion.external.customer.freight.product')}
            options={options(cn.freightProductId)}
          />
          <Input
            name={`customerNumbers[${index}]customerNumber`}
            label={tu('shop.accordion.external.customer.number')}
            type='text'
          />
          <div
            onClick={() => {
              remove(index);
            }}
          >
            <span className='link'>{t('shop.accordion.external.customer.number.remove')}</span>
          </div>
        </div>
      ))}
      <span className='link' onClick={add}>
        {t('shop.accordion.external.customer.number.add')}
      </span>
    </div>
  );
};

export default ExternalCustomerNumbers;
