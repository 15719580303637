import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import FormV2 from 'js/components/FormV2';
import RadioGroup from 'js/components/Form/components/RadioGroup';
import SelectV2 from 'js/components/Form/components/SelectV2';
import Spinner from 'js/components/Spinner';
import { SystemMessages } from './components/SystemMessages';

import { languages, tu } from 'js/utils/translate';
import { accessibleShortcuts } from 'js/scenes/Settings/utils';
import {
  languagesOptions,
  ownerPartiesOptions,
  shortcutsOptions
} from 'js/components/Select/utils';
import { getShortcuts } from 'js/store/shortcutsReducer';
import { updateUser } from 'js/store/userReducer';
import { getOwnerParties } from 'js/store/ownerPartiesReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate } from 'react-router-dom';
import { FlowUserInfo } from 'interfaces/interfaces.generated';

const Settings: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ownerParties = useAppSelector((state: RootState) => state?.ownerParties?.data);
  const ownerPartiesLoaded = useAppSelector((state: RootState) => state?.ownerParties?.isLoaded);

  const user = useAppSelector((state: RootState) => state?.user?.data);
  const userLoaded = useAppSelector((state: RootState) => state?.user?.isLoaded);
  const userUpdating = useAppSelector((state: RootState) => state?.user?.isUpdating);
  const error = useAppSelector((state: RootState) => state?.user?.error);

  const shortcuts = useAppSelector((state: RootState) => state?.shortcuts?.data);
  const shortcutsLoaded = useAppSelector((state: RootState) => state?.shortcuts?.isLoaded);

  useEffect(() => {
    if (!shortcutsLoaded) dispatch(getShortcuts());
  }, []);

  const userRoles = useAppSelector((state) => state?.user?.data?.roles);

  const activeOwnerParty = useAppSelector((state) => state?.user?.data?.activeOwnerParty);
  useEffect(() => {
    if (!ownerPartiesLoaded) dispatch(getOwnerParties());
  }, []);

  const handleSubmit = (values: FlowUserInfo) => {
    dispatch(updateUser(values)).then((response) => {
      if (response) {
        navigate('/');
        location.reload();
      }
    });
  };

  if (!shortcutsLoaded || !userLoaded) return <Spinner />;

  const shortcutsForRole = accessibleShortcuts(shortcuts, userRoles, activeOwnerParty);

  return (
    <>
      <SplitPage>
        <PageWrapper className='settings-page'>
          <FormV2
            onSubmit={handleSubmit}
            initialValues={user}
            actions
            loading={userUpdating}
            error={error}
            className='settings-page__container'
          >
            <SelectV2
              name='shortcuts'
              label={tu('settings.shortcuts.from.home')}
              options={shortcutsOptions(shortcutsForRole)}
              optional
              isMulti
              selectAll
            />
            <SelectV2
              name='activeOwnerParty'
              label={tu('settings.select.owner.party')}
              options={ownerPartiesOptions(ownerParties)}
              isLoading={!ownerPartiesLoaded}
            />
            <RadioGroup
              name='language'
              label={tu('settings.select.language')}
              radios={languagesOptions(languages)}
            />
          </FormV2>
        </PageWrapper>
      </SplitPage>
      <PageWrapper className='system-messages-section'>
        {user?.roles?.di && <SystemMessages />}
      </PageWrapper>
    </>
  );
};

export default Settings;
