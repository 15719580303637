import { FC, useState } from 'react';

import Input from 'js/components/Form/components/Input';

import t, { tu } from 'js/utils/translate';

import './index.sass';

interface Props {
  parcelIndex: number;
}

const AdditionalFields: FC<Props> = ({ parcelIndex }) => {
  const [showMoreFields, toggleShowMoreFields] = useState(false);

  return (
    <div className='additional-fields'>
      <div className='additional-fields__row'>
        {showMoreFields && (
          <>
            <Input
              className='additional-fields__row--small'
              name={`parcels[${parcelIndex}][volume]`}
              label={tu('orders.new.order.parcel.volume')}
              optional
            />
            <Input
              className='additional-fields__row--small'
              name={`parcels[${parcelIndex}][length]`}
              label={tu('orders.new.order.parcel.length')}
              optional
            />
            <Input
              className='additional-fields__row--small'
              name={`parcels[${parcelIndex}][width]`}
              label={tu('orders.new.order.parcel.width')}
              optional
            />
            <Input
              className='additional-fields__row--small'
              name={`parcels[${parcelIndex}][height]`}
              label={tu('orders.new.order.parcel.height')}
              optional
            />
          </>
        )}
        <div className='additional-fields__row--link'>
          <span
            className='link'
            onClick={() => {
              toggleShowMoreFields(!showMoreFields);
            }}
          >
            {showMoreFields
              ? t('orders.new.order.parcel.hide.fields')
              : t('orders.new.order.parcel.show.fields')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AdditionalFields;
