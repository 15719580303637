import { Link } from 'react-router-dom';

import t from 'js/utils/translate';
import { extractNavlinkDisplayName } from 'js/components/AppHeader/utils';
import { upperCaseFirst } from 'js/utils/string';

import './index.sass';

const Dropdown = ({ dropdownSections }) => (
  <div className='dropdown-section__container'>
    <div className='dropdown-section'>
      {dropdownSections.map((section) => (
        <Link key={section.to} to={section.to}>
          {upperCaseFirst(t(`general.${extractNavlinkDisplayName(section.to)}`))}
        </Link>
      ))}
    </div>
  </div>
);

export default Dropdown;
