import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { BrowserRouter as Router } from 'react-router-dom';

import AppRouter from 'js/AppRouter';
import ScrollToTop from 'js/components/ScrollToTop';

import { setFavicon } from 'js/utils/favicon';
import { set as setLocalStorage } from 'js/utils/storage';
import { setLanguage } from 'js/utils/translate';
import { getUser } from 'js/store/userReducer';
import { getFreightProducts } from 'js/store/freightProductsReducer';
import { getShops } from 'js/scenes/Freight/store/shopsReducer';
import { getServiceSuppliers } from 'js/scenes/Freight/scenes/ServiceSuppliers/store/serviceSuppliersReducer';
import { getConcerns } from 'js/scenes/Freight/scenes/Concerns/store/getConcernsReducer';
import { getCustomerSystems } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/customerSystemsReducer';
import { getTransportSolutions } from 'js/store/transportSolutionsReducer';
import { setMediaDevice } from 'js/store/mediaDevice';

import './index.sass';
import { getProofOfDeliveryOptions } from 'js/store/proofOfDeliveryOptionsReducer';

const App: FC = () => {
  const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
  const userLoaded = useAppSelector((state) => state?.user?.isLoaded);
  const language = useAppSelector((state) => state.user.data.language);
  const activeOwnerParty = useAppSelector((state) => state.user.data.activeOwnerParty);
  const dispatch = useAppDispatch();

  const [isDesktop, toggleIsDesktop] = useState(window.innerWidth > 1100);

  useEffect(() => {
    setFavicon();
    if (isAuthenticated) dispatch(getUser());
    window.addEventListener('resize', () => {
      toggleIsDesktop(window.innerWidth > 1100);
    });
  }, []);

  useEffect(() => {
    dispatch(setMediaDevice(isDesktop));
  }, [isDesktop]);

  useEffect(() => {
    if (isAuthenticated && !userLoaded) dispatch(getUser());
  }, [isAuthenticated, userLoaded]);

  useEffect(() => {
    setLanguage(language);
  }, [language]);

  useEffect(() => {
    if (activeOwnerParty) {
      setFavicon(activeOwnerParty);
      setLocalStorage('activeOwnerParty', activeOwnerParty);
      dispatch(getFreightProducts());
      dispatch(getTransportSolutions());
      dispatch(getShops());
      dispatch(getServiceSuppliers());
      dispatch(getConcerns());
      dispatch(getCustomerSystems());
      dispatch(getProofOfDeliveryOptions());
    }
  }, [activeOwnerParty]);

  return (
    <Router>
      <ScrollToTop />
      <AppRouter />
    </Router>
  );
};

export default App;
