import { FC } from 'react';
import classNames from 'classnames';

import Toggle from 'js/components/Form/components/Toggle';
import Label from 'js/components/Label';

import { Toggle as ToggleType } from 'js/components/Form/components/Toggle/interfaces';
import './index.sass';
import t from 'js/utils/translate';

interface Props {
  label?: string;
  info?: string;
  toggles: ToggleType[];
  groupDisabled?: boolean;
  align?: 'column' | 'row';
}

const ToggleGroup: FC<Props> = ({
  label,
  info,
  toggles,
  groupDisabled = false,
  align = 'column'
}) => (
  <div
    className={classNames('labeled-toggle-group', {
      'labeled-toggle-group--disabled': groupDisabled
    })}
  >
    {label && <Label label={label} info={info} />}
    <div
      className={classNames(
        'labeled-toggle-group__toggles',
        `labeled-toggle-group__toggles--${align}`
      )}
    >
      {toggles?.map(({ name, label, sublabel, disabled }) => (
        <Toggle
          key={name}
          name={name}
          label={t(label)}
          sublabel={sublabel}
          disabled={disabled ?? groupDisabled}
        />
      ))}
    </div>
  </div>
);

export default ToggleGroup;
