import { FC, useEffect } from 'react';

import Spinner from 'js/components/Spinner';
import { RootState } from 'js/store/reducer';
import { ApiJob } from 'src/interfaces/interfaces.generated';
import JobsTable from './components/JobsTable';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { getJobs } from 'js/store/jobsReducer';

const Jobs: FC = () => {
  const dispatch = useAppDispatch();
  const jobsLoaded: boolean = useAppSelector((state: RootState) => state.jobs?.isLoaded);
  const jobs: ApiJob[] = useAppSelector((state: RootState) => state.jobs?.data);

  useEffect(() => {
    if (!jobsLoaded) dispatch(getJobs());
  }, []);

  if (!jobsLoaded) return <Spinner />;

  return (
    <div>
      <JobsTable jobs={jobs} />
    </div>
  );
};

export default Jobs;
