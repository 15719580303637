import { FC, useEffect } from 'react';

import FormV2 from 'js/components/FormV2';
import Spinner from 'js/components/Spinner';
import DesktopFreightProductHeader from './components/DesktopFreightProductHeader';
import FreightProductRow from './components/FreightProductRow';
import InfoBox from 'js/components/InfoBox';
import PageWrapper from 'js/components/PageWrapper';

import t from 'js/utils/translate';
import {
  editShopFreightProducts,
  getShopFreightProducts,
  useShopFreightProducts
} from '../../store/shopFreightProductReducer';

import './index.sass';
import { reloadShop } from '../../store/shopReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate, useParams } from 'react-router-dom';

const EditShopFreightProducts: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const shopId = Number(params?.shopId);

  const dispatch = useAppDispatch();

  const shopFreightProductsState = useShopFreightProducts();

  const shopFreightProducts = shopFreightProductsState.data;
  const shopIdForFreightProducts = shopFreightProductsState.shopId;
  const shopFreightProductsLoading = shopFreightProductsState.isLoading;
  const savingShopFreightProducts = shopFreightProductsState.isSaving;
  const error = shopFreightProductsState.error;

  const isDesktop = useAppSelector((state) => state.mediaDevice?.isDesktop);

  useEffect(() => {
    if (shopId !== shopIdForFreightProducts) dispatch(getShopFreightProducts(shopId));
  }, []);

  const redirectTo = shopId ? `/freight/shop/${shopId}` : '/freight';

  const handleSubmit = (values) => {
    if (shopId) {
      dispatch(editShopFreightProducts(shopId, values.shopFreightProducts)).then(() => {
        dispatch(reloadShop(shopId));
        navigate(redirectTo);
      });
    }
  };

  if (!shopId || shopFreightProductsLoading || !shopFreightProducts) return <Spinner />;

  if (shopFreightProducts.length === 0) {
    return (
      <PageWrapper>
        <InfoBox text={t('shop.accordion.empty', 'general.freightProducts')} />
      </PageWrapper>
    );
  }

  return (
    <FormV2
      className='edit-shop-freight-products-form'
      onSubmit={handleSubmit}
      initialValues={{ shopFreightProducts }}
      loading={savingShopFreightProducts}
      redirectTo={redirectTo}
      error={error}
    >
      {isDesktop && <DesktopFreightProductHeader />}
      {shopFreightProducts.map((shopFreightProduct, index) => (
        <FreightProductRow
          shopFreightProduct={shopFreightProduct}
          index={index}
          key={shopFreightProduct.freightProductId}
          isDesktop={isDesktop}
        />
      ))}
    </FormV2>
  );
};

export default EditShopFreightProducts;
