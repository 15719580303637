import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import Spinner from 'js/components/Spinner';
import ExcerptInfo from './components/ExcerptInfo';
import ExcerptStats from './components/ExcerptStats';
import { LabelGenerationHistory } from 'src/interfaces/interfaces.generated';
import { LabelStatus } from '../../interfaces';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  labelExcerpt: LabelGenerationHistory;
  labelStatus?: LabelStatus;
}

const LabelHistoryInfo: FC<Props> = ({ labelExcerpt, labelStatus }) => {
  const shopsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shops?.isLoaded
  );

  if (!shopsLoaded) return <Spinner />;
  return (
    <div className='label-history-info'>
      <ExcerptInfo labelExcerpt={labelExcerpt} labelStatus={labelStatus} />
      <ExcerptStats labelExcerpt={labelExcerpt} labelStatus={labelStatus} />
    </div>
  );
};

export default LabelHistoryInfo;
