import { FC } from 'react';
import FilterButton from './components/FilterButton';
import { DashboardDataInterval } from '../../interfaces';

import './index.sass';

interface Props {
  selectedInterval: DashboardDataInterval;
  setSelectedInterval;
}

const intervals: DashboardDataInterval[] = ['DAILY', 'WEEKLY', 'MONTHLY'];

const DashboardDataFilterButtons: FC<Props> = ({ selectedInterval, setSelectedInterval }) => (
  <div className='main-panel__dashboard-filter-buttons'>
    {intervals.map((interval) => (
      <FilterButton
        key={interval}
        interval={interval}
        selectedInterval={selectedInterval}
        setSelectedInterval={setSelectedInterval}
      />
    ))}
  </div>
);

export default DashboardDataFilterButtons;
