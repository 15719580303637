import { FC } from 'react';

import Label from 'js/components/Label';

import './index.sass';

interface Props {
  header?: string;
  children?: any;
}

const ColoredSectionWithHeader: FC<Props> = ({ header, children }) => (
  <div className='colored-section-with-header'>
    {header && <Label label={header} />}
    {children && children}
  </div>
);

export default ColoredSectionWithHeader;
