import { FC } from 'react';

import { ApiUser } from 'src/interfaces/interfaces.generated';

import InfoBox from 'js/components/InfoBox';
import ShopUser from './components/User';

import t from 'js/utils/translate';

interface Props {
  users: ApiUser[];
  isApiContext?: boolean;
  domain?: 'serviceSupplier';
}

const EditUsers: FC<Props> = ({ users, isApiContext, domain }) => {
  if (!users || users.length === 0) return <InfoBox text={t('user.section.no.users.added')} />;
  return (
    <div className='edit-users'>
      {users.map((user, index) => (
        <ShopUser
          key={user.userId}
          user={user}
          index={index}
          isApiContext={isApiContext}
          domain={domain}
        />
      ))}
    </div>
  );
};

export default EditUsers;
