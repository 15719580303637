import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import SelectV2 from 'js/components/Form/components/SelectV2';

import { webserviceOptions } from 'js/components/Select/utils';
import { tu } from 'js/utils/translate';
import { useAppSelector } from 'js/utils/hooks';

interface Props {
  name: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

const SelectApis: FC<Props> = ({ name, placeholder, className, disabled }) => {
  const wsLoaded = useAppSelector(
    (state: RootState) => state?.freight?.shop?.webservices?.isLoaded
  );
  const webservices = useAppSelector((state: RootState) => state.freight?.shop?.webservices?.data);

  return (
    <SelectV2
      className={className}
      label={tu('general.apiAccess')}
      name={name}
      placeholder={placeholder}
      isMulti
      selectAll
      isLoading={!wsLoaded}
      options={webserviceOptions(webservices)}
      disabled={disabled}
      optional
    />
  );
};

export default SelectApis;
