import { FC } from 'react';
import classnames from 'classnames';

import Tag from 'js/components/Tag';
import Label from 'js/components/Label';

import t from 'js/utils/translate';
import { LabelGenerationHistory } from 'src/interfaces/interfaces.generated';

import './index.sass';

interface Props {
  labelExcerpt: LabelGenerationHistory;
  accessor: string;
  data?: any[];
  findFunction?: any;
  label: string;
  className?: string;
}

const LabeledTags: FC<Props> = ({
  labelExcerpt,
  accessor,
  data,
  findFunction,
  label,
  className
}) => {
  const translationPrefix = 'orders.labelhistory.order.by.options.';

  return (
    <div className={classnames('labeled-tags', className)}>
      <Label label={label} />
      <div className='labeled-tags__tags'>
        {labelExcerpt?.[accessor].map((accessorId) => (
          <Tag
            key={accessorId}
            text={
              findFunction
                ? findFunction(data, accessorId)?.name
                : t(`${translationPrefix}${accessorId.toLowerCase()}`)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default LabeledTags;
