import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormContext } from '@distribution-innovation/form-hooks';

import SelectV2 from 'js/components/Form/components/SelectV2';
import Link from 'js/components/Link';
import { freightProductOptions } from 'js/components/Select/utils';
import t, { tu } from 'js/utils/translate';
import { findShopById } from 'js/utils/find';

import { ApiFlowShopSimple, FreightProduct } from 'src/interfaces/interfaces.generated';
import { RootState } from 'js/store/reducer';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';

const CostPriceSelects: FC = () => {
  const shops: ApiFlowShopSimple[] = useAppSelector(
    (state: RootState) => state?.freight.shops?.data
  );
  const shopsLoaded: boolean = useAppSelector((state: RootState) => state?.freight.shops?.isLoaded);

  const freightProducts: FreightProduct[] = useAppSelector(
    (state: RootState) => state.freightProducts?.data
  );

  const location = useLocation();

  const shopIdFromLocation = location.state?.shopId;
  const freightProductIdFromLocation = location.state?.freightProductId;

  const { getValue, setValue } = useFormContext();
  const selectedShop: ApiFlowShopSimple | undefined = getValue('selectedShop');

  const [freightProductsForShop, setFreightProductsForShop] = useState<FreightProduct[]>([]);

  useEffect(() => {
    if (shopIdFromLocation && freightProductIdFromLocation) {
      setValue('selectedShop', findShopById(shops, +shopIdFromLocation));
      setValue('freightProductId', +freightProductIdFromLocation);
    }
  }, [shopIdFromLocation, freightProductIdFromLocation]);

  useEffect(() => {
    if (selectedShop?.freightProductIds) {
      setFreightProductsForShop(
        freightProducts.filter(
          (freightProduct) =>
            selectedShop?.freightProductIds?.includes(Number(freightProduct.freightProductId))
        )
      );
    }
  }, [selectedShop]);

  return (
    <div className='cost-prices__selects'>
      <div>
        <SelectV2
          name='selectedShop'
          options={shops?.map((shop) => ({ value: shop, label: shop.name }))}
          label={tu('general.shop')}
          isLoading={!shopsLoaded}
        />
        <Link
          to={selectedShop?.shopId ? `/freight/shop/${selectedShop.shopId}` : false}
          text={<div className='shop-link'>{t('economy.costprice.go.to.shop')}</div>}
          active
          internal
          noUnderline
        />
      </div>
      <div>
        <SelectV2
          name='freightProductId'
          options={freightProductOptions(freightProductsForShop)}
          label={tu('general.freightProduct')}
          disabled={!selectedShop?.freightProductIds}
        />
      </div>
    </div>
  );
};

export default CostPriceSelects;
