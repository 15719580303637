import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as rest from '../../api';

import { ApiOrder } from 'src/interfaces/interfaces.generated';

export interface FullOrderReducerState {
  error: unknown;
  isLoaded: boolean;
  data: ApiOrder;
}

const initialState: FullOrderReducerState = {
  error: null,
  isLoaded: false,
  data: {
    shopId: 0,
    transportSolutionId: 0,
    consignee: {
      name: ''
    },
    consignor: {
      name: ''
    },
    messageToConsignee: '',
    parcels: []
  }
};

const fullOrderSlice = createSlice({
  name: 'FullOrder',
  initialState,
  reducers: {
    getFullOrderRequest(state) {
      state.error = null;
      state.isLoaded = false;
    },
    getFullOrderSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getFullOrderFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { getFullOrderRequest, getFullOrderSuccess, getFullOrderFailed } =
  fullOrderSlice.actions;

export default fullOrderSlice.reducer;

export const getFullOrder =
  (trackingReference: number) =>
  async (dispatch): Promise<ApiOrder | undefined> => {
    try {
      dispatch(getFullOrderRequest());
      const response: ApiOrder = await rest.getFullOrder(trackingReference);
      dispatch(getFullOrderSuccess(response));
      return response;
    } catch (err) {
      dispatch(getFullOrderFailed(err));
    }
  };
