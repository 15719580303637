import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { ApiServiceSupplier } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';

export interface NewServiceSupplierReducerState {
  error: unknown;
  isCreating: boolean;
  isCreated: boolean;
}

const initialState: NewServiceSupplierReducerState = {
  error: null,
  isCreating: false,
  isCreated: false
};

const newServiceSupplierSlice = createSlice({
  name: 'serviceSupplier',
  initialState,
  reducers: {
    createServiceSupplierRequest(state) {
      state.error = null;
      state.isCreated = false;
      state.isCreating = true;
    },
    createServiceSupplierSuccess(state) {
      state.isCreated = true;
      state.isCreating = false;
    },
    createServiceSupplierFailed(state, action) {
      state.error = action.payload;
      state.isCreated = false;
      state.isCreating = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  createServiceSupplierRequest,
  createServiceSupplierSuccess,
  createServiceSupplierFailed
} = newServiceSupplierSlice.actions;

export default newServiceSupplierSlice.reducer;

export const createServiceSupplier =
  (serviceSupplier: ApiServiceSupplier) =>
  async (dispatch): Promise<number | undefined> => {
    try {
      dispatch(createServiceSupplierRequest());
      const response: ApiServiceSupplier = await api.createServiceSupplier(serviceSupplier);
      dispatch(createServiceSupplierSuccess());
      return response.id;
    } catch (error) {
      dispatch(createServiceSupplierFailed(error));
    }
  };
