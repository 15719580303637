import { FC } from 'react';

import ConnectUser from './components/ConnectUser';

interface Props {
  isApiContext?: boolean;
  connectUsers;
  setConnectUsers;
  name;
}

const ConnectUsers: FC<Props> = ({ isApiContext, connectUsers, setConnectUsers, name }) => {
  const removeConnectUser = (index) =>
    setConnectUsers(connectUsers?.filter((connectUser, i) => i !== index));

  return (
    <div className='connect-users'>
      {connectUsers?.map((connectUser, index) => (
        <ConnectUser
          key={index}
          index={index}
          isApiContext={isApiContext}
          parentName={name}
          removeConnectUser={removeConnectUser}
        />
      ))}
    </div>
  );
};

export default ConnectUsers;
