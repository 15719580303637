import { FC } from 'react';

import t from 'js/utils/translate';

import './index.sass';

import NotFoundImg from 'images/icons/NotFound.svg';

const NotFound: FC = () => (
  <div className='not-found'>
    <h1>{t('not.found.text')}</h1>
    <NotFoundImg />
  </div>
);

export default NotFound;
