import { combineReducers } from 'redux';

import bulkOrderReducer from './scenes/BulkOrder/store/bulkOrderReducer';
import labelHistoryReducer, {
  LabelHistoryReducerState
} from './scenes/LabelHistory/store/labelHistoryReducer';
import labelsReducer, { LabelsReducerState } from './scenes/Labels/store/labelsReducer';
import carNumbersReducer, { CarNumbersReducerState } from './scenes/Labels/store/carNumbersReducer';
import preTransportCarNumbersReducer, {
  PreTransportCarNumbersReducerState
} from './scenes/Labels/store/preTransportCarNumbersReducer';
import subTransportCarNamesReducer, {
  SubTransportCarNamesReducerState
} from './scenes/Labels/store/subTransportCarNamesReducer';
import carDeparturesReducer, {
  CarDeparturesReducerState
} from './scenes/Labels/store/carDeparturesReducer';
import serviceSuppliersForLabelsReducer, {
  ServiceSuppliersForLabelsReducerState
} from './scenes/Labels/store/serviceSuppliersForLabelsReducer';
import newOrderReducer, { NewOrderReducerState } from './scenes/NewOrder/store/newOrderReducer';
import singleAddressCheckReducer, {
  SingleAddressCheckReducerState
} from './scenes/NewOrder/store/singleAddressCheckReducer';
import getFullOrderReducer, {
  FullOrderReducerState
} from './scenes/NewOrder/store/getFullOrderReducer';
import servicePointsReducer, {
  ServicePointsReducerState
} from './scenes/NewOrder/store/servicePointsReducer';

const ordersReducer = combineReducers({
  bulkOrder: bulkOrderReducer,
  labelHistory: labelHistoryReducer,
  labels: labelsReducer,
  serviceSuppliersForLabels: serviceSuppliersForLabelsReducer,
  carNumbers: carNumbersReducer,
  preTransportCarNumbers: preTransportCarNumbersReducer,
  subTransportCarNames: subTransportCarNamesReducer,
  carDepartures: carDeparturesReducer,
  newOrder: newOrderReducer,
  singleAddressCheck: singleAddressCheckReducer,
  getFullOrder: getFullOrderReducer,
  servicePoints: servicePointsReducer
});

export default ordersReducer;

export interface OrdersReducerState {
  bulkOrder: any;
  labelHistory: LabelHistoryReducerState;
  labels: LabelsReducerState;
  serviceSuppliersForLabels: ServiceSuppliersForLabelsReducerState;
  carNumbers: CarNumbersReducerState;
  preTransportCarNumbers: PreTransportCarNumbersReducerState;
  subTransportCarNames: SubTransportCarNamesReducerState;
  carDepartures: CarDeparturesReducerState;
  newOrder: NewOrderReducerState;
  singleAddressCheck: SingleAddressCheckReducerState;
  getFullOrder: FullOrderReducerState;
  servicePoints: ServicePointsReducerState;
}
