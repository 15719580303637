import { FC } from 'react';

import AddUsers from './components/AddUsers';
import EditUsers from './components/EditUsers';

import { ApiUser } from 'src/interfaces/interfaces.generated';

interface Props {
  users: ApiUser[];
  isApiContext?: boolean;
  domain?: 'serviceSupplier';
}

const Users: FC<Props> = ({ users, isApiContext, domain }) => (
  <>
    <EditUsers users={users} isApiContext={isApiContext} domain={domain} />
    <AddUsers isApiContext={isApiContext} />
  </>
);

export default Users;
