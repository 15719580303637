import Button from 'js/components/Button';

import { handleBulkOrder } from '../../../../../../store/bulkOrderReducer';
import t from 'js/utils/translate';

import './index.sass';
import { useAppDispatch } from 'js/utils/hooks';

const StatusActions = ({ actionType, importFileId, translateKeyPrefix }) => {
  const dispatch = useAppDispatch();

  const handleAction = (action) => {
    dispatch(handleBulkOrder(importFileId, action));
  };

  if (actionType === 'coverageCheck') {
    return (
      <div className='bulk-order-actions'>
        <Button
          inverse
          name={t(`${translateKeyPrefix}.action.coverage.check`)}
          handleClick={() => {
            handleAction('approve.file.insert');
          }}
        />
      </div>
    );
  }

  if (actionType === 'generateLabels') {
    return (
      <div className='bulk-order-actions'>
        <Button
          inverse
          name={t(`${translateKeyPrefix}.action.generate.labels.approve`)}
          handleClick={() => {
            handleAction('approve.file.parse.orders');
          }}
        />
        <Button
          danger
          name={t(`${translateKeyPrefix}.action.generate.labels.cancel`)}
          handleClick={() => {
            handleAction('cancel.file.request.data.delete');
          }}
        />
      </div>
    );
  }
};

export default StatusActions;
