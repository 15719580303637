import { useEffect, useState } from 'react';

import SplitPage from 'js/components/SplitPage';
import FormV2 from 'js/components/FormV2';
import Select from 'js/components/Form/components/Select';
import Input from 'js/components/Form/components/Input';
import MessageForUser from 'js/components/MessageForUser';
import FileInput from 'js/components/Form/components/FileInput';
import PageWrapper from 'js/components/PageWrapper';
import BulkOrderInfo from './components/BulkOrderInfo';

import { createBulkOrder, getBulkOrders } from '../../store/bulkOrderReducer';
import {
  getShopTransportSolutions,
  useShopTransportSolutions
} from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/shopTransportSolutionsReducer';

import t, { tu } from 'js/utils/translate';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

const NewBulkOrder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isDesktop = useAppSelector((state) => state.mediaDevice?.isDesktop);
  const shops = useAppSelector((state) => state?.freight?.shops?.data);
  const shopsLoaded = useAppSelector((state) => state?.freight?.shops?.isLoaded);

  const shopTransportSolutionsState = useShopTransportSolutions();
  const shopTransportSolutions = shopTransportSolutionsState.data;
  const shopTransportSolutionsLoading = shopTransportSolutionsState.isLoading;
  const shopIdForTransportSolutions = shopTransportSolutionsState.shopId;

  const isCreating = useAppSelector((state) => state?.orders?.bulkOrder?.isCreating);

  const error = useAppSelector((state) => state?.orders?.bulkOrder?.error);

  const [selectedShopId, setSelectedShopId] = useState(false);
  const [file, setFile] = useState({});

  const handleChange = ({ shop }) => {
    if (shopsLoaded && shop) setSelectedShopId(shop.value);
  };

  useEffect(() => {
    if (selectedShopId !== shopIdForTransportSolutions && selectedShopId) {
      dispatch(getShopTransportSolutions(selectedShopId));
    }
  }, [selectedShopId]);

  const redirectTo = '/orders/bulkOrder';

  const handleSubmit = async ({ shop, transportSolution, email }) => {
    const formData = new FormData();
    formData.append(
      'properties',
      new Blob([JSON.stringify({ email })], {
        type: 'application/json'
      })
    );
    formData.append('file', file);
    await dispatch(createBulkOrder(shop.value, transportSolution.value, formData)).then(
      (response) => {
        if (response) {
          dispatch(getBulkOrders()).then((response) => {
            if (response) {
              // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
              navigate(-1) ?? navigate(redirectTo);
            }
          });
        }
      }
    );
  };

  return (
    <>
      <SplitPage isDesktop={isDesktop}>
        <div className='left-container'>
          <PageWrapper className='new-bulk-order'>
            <FormV2
              onSubmit={handleSubmit}
              onChange={handleChange}
              actions
              type='create'
              redirectTo={redirectTo}
              loading={isCreating}
              error={error}
            >
              <div className='new-bulk-order__form-wrapper'>
                <Select
                  name='shop'
                  label={tu('general.shop')}
                  options={shops.map((shop) => ({
                    value: shop.shopId,
                    label: shop.name
                  }))}
                  placeholder={t('new.bulkOrder.select.shop')}
                />
                <Select
                  name='transportSolution'
                  label={tu('general.transportSolution')}
                  info={t('new.bulkOrder.form.input.trasportSolution.info')}
                  options={shopTransportSolutions?.map((shopTransportSolution) => ({
                    value: shopTransportSolution.transportSolutionId,
                    label: shopTransportSolution.name
                  }))}
                  disabled={!selectedShopId}
                  isLoading={selectedShopId && shopTransportSolutionsLoading}
                  placeholder={selectedShopId ? t('select.transportSolution') : ''}
                />
                <Input name='email' type='email' label={tu('general.email')} />
                {!isDesktop && (
                  <MessageForUser variant='info' message={t('orders.bulkOrder.new.info.mobile')} />
                )}
                <FileInput
                  setFile={setFile}
                  name='filepath'
                  label={tu('new.bulkOrder.form.input.file.label')}
                  info={isDesktop ? t('new.bulkOrder.form.input.file.info') : ''}
                  accept='.csv, .xlsx'
                />
              </div>
            </FormV2>
          </PageWrapper>
        </div>
        <PageWrapper>
          <BulkOrderInfo />
        </PageWrapper>
      </SplitPage>
    </>
  );
};

export default NewBulkOrder;
