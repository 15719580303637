import { FC, useEffect } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import InputGroup from 'js/components/Form/components/InputGroup';
import DatepickerRange from 'js/components/Form/components/DatepickerRange';
import Toggle from 'js/components/Form/components/Toggle';

import { RootState } from 'js/store/reducer';

import t, { tu } from 'js/utils/translate';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

const TimeInterval: FC = () => {
  const context = useFormContext();
  const values = context.getValues();

  const { importDateToggle, deliveryDateToggle } = values;

  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  useEffect(() => {
    const error = importDateToggle || deliveryDateToggle ? undefined : 'atLeastOne';
    context.setError('timeInterval', error);
  }, [importDateToggle, deliveryDateToggle]);

  return (
    <InputGroup
      className='time-interval'
      name='timeInterval'
      info={t('select.at.least.one')}
      label={tu('orders.labels.select.time.interval')}
    >
      <div className='time-interval__interval'>
        <Toggle name='importDateToggle' label={t('orders.labels.imported.date')} />
        <DatepickerRange
          name='importedDates'
          interval={7}
          maxDate={new Date()}
          disabled={!importDateToggle}
          align={isDesktop ? 'row' : 'column'}
        />
      </div>
      <div className='time-interval__interval'>
        <Toggle name='deliveryDateToggle' label={t('orders.labels.delivery.date')} />
        <DatepickerRange
          name='desiredDeliveryDates'
          interval={7}
          disabled={!deliveryDateToggle}
          align={isDesktop ? 'row' : 'column'}
        />
      </div>
    </InputGroup>
  );
};

export default TimeInterval;
