import { createSlice } from '@reduxjs/toolkit';
import { ApiFlowLogEvent } from 'src/interfaces/interfaces.generated';
import * as api from '../../api';

export interface OrderEventsReducerState {
  error: unknown;
  data: ApiFlowLogEvent[];
  isLoaded: boolean;
}

const initialState = {
  error: null,
  data: [],
  isLoaded: false
};

const orderEventsSlice = createSlice({
  name: 'OrderEvents',
  initialState,
  reducers: {
    getOrderEventsRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getOrderEventsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getOrderEventsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  }
});

export const { getOrderEventsRequest, getOrderEventsSuccess, getOrderEventsFailed } =
  orderEventsSlice.actions;

export default orderEventsSlice.reducer;

export const getOrderEvents = (trackingReference: string) => async (dispatch) => {
  try {
    dispatch(getOrderEventsRequest());
    const response: ApiFlowLogEvent[] = await api.getOrderEvents(trackingReference);
    dispatch(getOrderEventsSuccess(response));
  } catch (err) {
    dispatch(getOrderEventsFailed(err));
  }
};
