import { FC } from 'react';
import t from 'js/utils/translate';
import CollapseV2, { Props as Collapse } from 'js/components/CollapseV2';
import SystemMessage from './components/SystemMessage';
import { SystemInfo } from 'js/scenes/Settings/components/SystemMessages/interfaces';

interface Props {
  systemMessages: SystemInfo[];
  editingSystemMessageIndex: number;
  newMessageIndex: number;
  setEditingSystemMessageIndex: (index) => typeof index;
}

export const SystemMessagesCollapse: FC<Props> = ({
  systemMessages,
  editingSystemMessageIndex,
  newMessageIndex,
  setEditingSystemMessageIndex
}) => {
  const collapse: Collapse = {
    title: t('settings.system.info.messages'),
    className: 'system-messages',
    info: false,
    canEdit: false,
    expanded: false,
    body: (
      <>
        {systemMessages?.map((systemMessage, index) => (
          <SystemMessage
            key={`${systemMessage.systemInfoId} ${editingSystemMessageIndex}`}
            index={index}
            disabled={editingSystemMessageIndex !== index}
            setEditingSystemMessageIndex={setEditingSystemMessageIndex}
          />
        ))}
        <h4>Ny systemmelding</h4>
        <SystemMessage
          index={newMessageIndex}
          disabled={editingSystemMessageIndex !== newMessageIndex}
          setEditingSystemMessageIndex={setEditingSystemMessageIndex}
        />
      </>
    )
  };

  return <CollapseV2 {...collapse} />;
};
