import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import CopyText from 'js/components/CopyText';
import LinkV2 from 'js/components/LinkV2';

import t from 'js/utils/translate';
import {
  clearWebserviceUserPasswordState,
  resetWebserviceUserPassword
} from './store/webserviceUserPasswordReducer';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface Props {
  userId?: number;
  editUserActive: boolean | null;
}

const NewWebserviceUserPassword: FC<Props> = ({ userId, editUserActive }) => {
  const dispatch = useAppDispatch();

  const isResetting: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shop?.webserviceUserPassword?.isResetting
  );
  const password: string | null = useAppSelector(
    (state: RootState) => state?.freight?.shop?.webserviceUserPassword?.password
  );
  const isReset: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shop?.webserviceUserPassword?.isReset
  );
  const id: number | null = useAppSelector(
    (state: RootState) => state?.freight?.shop?.webserviceUserPassword?.id
  );
  const error = useAppSelector(
    (state: RootState) => state?.freight?.shop?.webserviceUserPassword?.error
  );

  const handleNewPassword = () => {
    if (userId) dispatch(resetWebserviceUserPassword(userId));
  };

  useEffect(() => {
    if (!editUserActive) dispatch(clearWebserviceUserPasswordState());
  }, [editUserActive]);

  const isCurrentUser = userId === id;

  return (
    <div className='new-webservice-user-password'>
      <div>{t('freight.shop.edit.api.userAction.createPassword.message.info')}</div>
      {isReset && isCurrentUser ? (
        <div className='password-wrapper'>
          <span>{`${t('freight.shop.edit.api.userAction.createPassword.newPassword')}:`}</span>
          <CopyText
            text={password}
            feedbackPosition='right'
            feedbackMessage={t('freight.shop.edit.api.userAction.password.copied')}
          />
        </div>
      ) : (
        <LinkV2
          className='new-password-link'
          onClick={handleNewPassword}
          text={t('freight.shop.edit.api.createPassword.create')}
          loading={isResetting && isCurrentUser}
          error={!!(error && isCurrentUser)}
        />
      )}
      <div></div>
    </div>
  );
};

export default NewWebserviceUserPassword;
