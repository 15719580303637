import { FC, useState } from 'react';

import Links from './components/Links';
import Expand from './components/Expand';

import { ApiInvoiceBatch } from 'src/interfaces/interfaces.generated';
import { Column } from '../../interfaces';

import './index.sass';

interface Props {
  columns: Column[];
  invoiceBatch: ApiInvoiceBatch;
  context: 'handled' | 'unhandled' | 'shop';
}

const Row: FC<Props> = ({ columns, invoiceBatch, context }) => {
  const [viewSummary, toggleViewSummary] = useState(false);

  const isProcessed = invoiceBatch.statusId === 1;
  return (
    <div key={invoiceBatch.invoiceBatchId} className='invoice-batches-table__row'>
      <div className='columns'>
        {columns.map(({ accessor, Header }) => {
          switch (typeof accessor) {
            case 'string':
              return (
                <div key={`${invoiceBatch.invoiceBatchId}${Header}`} className='column'>
                  {invoiceBatch[`${accessor}`]}
                </div>
              );
            case 'function':
              return (
                <div key={`${invoiceBatch.invoiceBatchId}${Header}`} className='column'>
                  {accessor(invoiceBatch)}
                </div>
              );
            default:
              return <></>;
          }
        })}
      </div>
      {isProcessed && (
        <Links
          invoiceBatchId={invoiceBatch?.invoiceBatchId}
          viewSummary={viewSummary}
          toggleViewSummary={toggleViewSummary}
          context={context}
        />
      )}
      {viewSummary && <Expand invoices={invoiceBatch.invoices} />}
    </div>
  );
};

export default Row;
