import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { ApiUser } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';

export interface ShopUserReducerState {
  error: unknown;
  isCreated: boolean;
  data: ApiUser;
}

const initialState: ShopUserReducerState = {
  error: null,
  isCreated: false,
  data: {}
};

const shopUserSlice = createSlice({
  name: 'ShopUser',
  initialState,
  reducers: {
    createShopUserRequest(state) {
      state.error = null;
      state.isCreated = false;
      state.data = {};
    },
    createShopUserSuccess(state, action) {
      state.isCreated = true;
      state.data = action.payload;
    },
    createShopUserFailed(state, action) {
      state.error = action.payload;
      state.isCreated = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { createShopUserRequest, createShopUserSuccess, createShopUserFailed } =
  shopUserSlice.actions;

export default shopUserSlice.reducer;

export const createShopUser =
  (shopId: number, user: ApiUser) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(createShopUserRequest());
      const response: ApiUser = await api.createShopUser(shopId, user);
      dispatch(createShopUserSuccess(response));
      return true;
    } catch (error) {
      dispatch(createShopUserFailed(error));
    }
  };
