import { ApiOrderSimple } from 'src/interfaces/interfaces.generated';

export const wrongOrder = (
  trackingReference: string,
  order: ApiOrderSimple,
  orderLoaded: boolean
) =>
  orderLoaded &&
  !(
    trackingReference === order?.trackingReference ||
    trackingReference === order?.shipmentId ||
    trackingReference === order?.pickup?.pickupCode
  );
