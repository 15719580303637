import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import PageWrapper from 'js/components/PageWrapper';
import SplitPage from 'js/components/SplitPage';
import MessageForUser from 'js/components/MessageForUser';
import FormV2 from 'js/components/FormV2';
import ToggleGroup from 'js/components/Form/components/ToggleGroup';
import Spinner from 'js/components/Spinner';

import t, { tu } from 'js/utils/translate';
import { wrongOrder } from 'js/scenes/Home/scenes/OrderDetails/utils';
import { getOrder } from '../../store/orderReducer';
import { cancelOrder } from './store/editOrderReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate, useParams } from 'react-router-dom';

const toggle = ({ enableCancellation }: { enableCancellation?: boolean }) => [
  {
    name: 'cancel',
    label: 'edit.order.cancel',
    disabled: !enableCancellation
  }
];

const EditOrder: FC = () => {
  const dispatch = useAppDispatch();
  const { trackingReference } = useParams();
  const navigate = useNavigate();
  const redirectTo = `/orderSearch/${trackingReference}`;

  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const order = useAppSelector((state: RootState) => state?.home?.orderDetails?.order?.data);
  const orderLoaded = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.order?.isLoaded
  );

  const cancelOrderError = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.editOrder?.error
  );
  const cancellingOrder = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.editOrder?.isCancelling
  );

  useEffect(() => {
    if (wrongOrder(trackingReference!, order, orderLoaded) || !orderLoaded) {
      dispatch(getOrder(trackingReference!));
    }
  }, []);

  const handleSubmit = (values) => {
    if (values?.cancel) {
      dispatch(cancelOrder(order.orderId)).then((response?: boolean) => {
        if (response) {
          navigate(redirectTo);
        }
      });
    }
  };

  if (!orderLoaded) return <Spinner />;

  return (
    <PageWrapper>
      <SplitPage>
        <FormV2
          onSubmit={handleSubmit}
          className='edit-order'
          redirectTo={redirectTo}
          error={cancelOrderError}
          loading={cancellingOrder}
        >
          <ToggleGroup toggles={toggle(order)} label={tu('edit.order.cancel.order')} />
        </FormV2>
        <PageWrapper className='edit-order__right-container' withoutPadding={!isDesktop}>
          <MessageForUser variant='info' message={t('edit.order.info.cancel')} />
        </PageWrapper>
      </SplitPage>
    </PageWrapper>
  );
};

export default EditOrder;
