export const findNotProcessedBulkOrders = (importedOrders) =>
  importedOrders.filter(
    (file) =>
      file.statusId === 12 || // Venter på godkjenning før prosessering
      file.statusId === 15
  ); // Prosessert, avventer godkjenning

export const findProcessedBulkOrders = (importedOrders) =>
  importedOrders.filter(
    (file) =>
      file.statusId === 1 || // Prosessert
      file.statusId === 2 || // Duplikat
      file.statusId === 3 || // Prosesseringsfeil
      file.statusId === 13 || // I kø for prosessering
      file.statusId === 14 || // Under prosessering
      file.statusId === 16 || // Kansellert
      file.statusId === 17 || // Fildata har blitt slettet
      file.statusId === 18 || // I kø for prossesering (etiketter)
      file.statusId === 19 || // Under prosessering (etiketter)
      file.statusId === 20 || // Etiketter generert
      file.statusId === 21 || // Prosesseringsfeil (etiketter)
      file.statusId === 'unknown'
  );

export const filterOrders = (importedOrders, searchWord) => {
  const check = (value) => value?.toLowerCase().includes(searchWord?.toLowerCase());
  return importedOrders?.filter(
    (bulkOrder) =>
      check(bulkOrder.name) ||
      check(bulkOrder.shopName) ||
      check(bulkOrder.transportSolutionName) ||
      check(bulkOrder.uploadedDate) ||
      check(bulkOrder.parcedDate)
  );
};
