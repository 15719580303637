import { FC } from 'react';

import PageWrapper from 'js/components/PageWrapper';
import FooterBoxes from 'js/components/FooterBoxes';

import { orderFooterBoxes } from 'js/components/FooterBoxes/utils';

const Orders: FC = () => (
  <PageWrapper className='orders-page'>
    <FooterBoxes footerBoxes={orderFooterBoxes} />
  </PageWrapper>
);

export default Orders;
