import t from 'js/utils/translate';

import { AdminRight } from 'js/scenes/Freight/interfaces';
import { Option } from 'js/components/SelectV2/interfaces';
import { ApiUser } from 'src/interfaces/interfaces.generated';

export const adminRights: AdminRight[] = ['labels', 'admin', 'quality'];

export const mapFromAdminRightsToOptions = (adminRights: AdminRight[]): Option[] =>
  adminRights.map((adminRight) => ({
    value: adminRight,
    label: t(`user.adminRight.${adminRight}`)
  }));

export const findAdminRights = (user: ApiUser): AdminRight[] => {
  const adminRights: AdminRight[] = [];
  if (user?.admin) adminRights.push('admin');
  if (user?.labelsAdmin) adminRights.push('labels');
  if (user?.qualityAdmin) adminRights.push('quality');
  return adminRights;
};
