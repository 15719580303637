import { FC } from 'react';
// eslint-disable-next-line import/no-named-default
import { default as ReactSelect } from 'react-select';
import classNames from 'classnames';

import Label from 'js/components/Label';

import t from 'js/utils/translate';

import './index.sass';
import InputError from '../Form/components/Validation/InputError';

interface Props {
  name?: string;
  label: string;
  info?: string;
  disabled?: boolean;
  optional?: boolean;
  selectAll?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  options: unknown[];
  selectedValues?: unknown[];
  handleSelectAll?: any;
  subLabel?: string;
  className?: string;
  hideOptional?: boolean;
  error?: any;
  isLoading?: boolean;
  onChange?: any;
}

const Select: FC<Props> = ({
  name,
  label,
  info,
  disabled,
  optional,
  selectAll,
  placeholder = null,
  isMulti,
  options,
  selectedValues,
  handleSelectAll,
  subLabel,
  className,
  hideOptional = false,
  error,
  isLoading,
  onChange
  // ...props
}) => {
  return (
    <div className={classNames('labeled-multi-select', className)}>
      {(label || selectAll) && (
        <div className='labeled-multi-select__header'>
          {label && (
            <Label
              // eslint-disable-next-line react/no-children-prop
              children='' // TODO Får error hvis children ikke er med
              htmlFor={name}
              label={label}
              info={info}
              hideOptional={hideOptional}
              optional={optional}
            />
          )}
          {selectAll && isMulti && (
            <span
              className={classNames('select-all-btn', {
                'select-all-btn--disabled': disabled
              })}
              onClick={handleSelectAll}
            >
              {t('select.all')}
            </span>
          )}
        </div>
      )}
      <div className={classNames('select', { 'select--invalid': error })}>
        <ReactSelect
          isDisabled={disabled}
          placeholder={placeholder}
          isMulti={isMulti}
          options={options}
          value={selectedValues}
          isLoading={isLoading}
          onChange={onChange}
          // {...props}
        />
      </div>
      {subLabel && (
        <Label
          label={subLabel}
          subLabel
          // eslint-disable-next-line react/no-children-prop
          children=''
        />
      )}
      {error && <InputError error={error} className='' />}
    </div>
  );
};

export default Select;
