import { useExpanded, usePagination, useSortBy, useTable } from 'react-table';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'js/components/Icon';
import Pagination from 'js/components/Pagination';

import './index.sass';

const DesktopTable = ({
  columns,
  data,
  className,
  rowIcon,
  sortBy = [],
  pagination = false,
  onClickRow,
  goToLabel,
  rowLink = true,
  hiddenColumns = [],
  pageSize = 10,
  subRows = []
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    state: { pageIndex },
    gotoPage
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy,
        hiddenColumns,
        pageSize
      }
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  const rowsOrPage = (pagination) => (pagination ? page : rows);

  return (
    <div className={classNames('sorting-table', className)}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <Icon regular className='sort' icon='sort-alt' />
                </th>
              ))}
              {goToLabel && <th />}
            </tr>
          ))}
        </thead>
        {data?.length > 0 ? (
          <tbody {...getTableBodyProps()}>
            {rowsOrPage(pagination)?.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.index}>
                  <tr key={row.id} {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td key={cell.id} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                    {goToLabel && onClickRow && (
                      <td>
                        <div className='link' onClick={(e) => onClickRow(row, e)}>
                          {goToLabel}
                        </div>
                      </td>
                    )}
                  </tr>
                  {subRows.map((subRow, subIndex) => {
                    const renderedSubRow = subRow({ row });
                    return (
                      renderedSubRow && <tr key={`${row.index}-${subIndex}`}>{renderedSubRow}</tr>
                    ); // eslint-disable-line react/no-array-index-key
                  })}
                </Fragment>
              );
            })}
          </tbody>
        ) : (
          <></>
        )}
      </table>
      {pagination && (
        <Pagination
          activePage={pageIndex + 1}
          itemsCountPerPage={pageSize}
          onChange={gotoPage}
          totalItemsCount={data.length}
          usedByTable
        />
      )}
    </div>
  );
};

export default DesktopTable;

DesktopTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  rowIcon: PropTypes.string,
  sortBy: PropTypes.array,
  pagination: PropTypes.bool,
  onClickRow: PropTypes.func,
  rowLink: PropTypes.bool
};
