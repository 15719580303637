import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import FormV2 from 'js/components/FormV2';
import Input from 'js/components/Form/components/Input';
import SplitPage from 'js/components/SplitPage';
import Spinner from 'js/components/Spinner';
import Toggle from 'js/components/Form/components/Toggle';
import Label from 'js/components/Label';
import AddressInput from '../../components/AddressInput';

import { editShop } from '../../store/editShopReducer';
import { getShop } from '../../store/shopReducer';
import { buildJsonPatchRequest } from 'js/utils/ajax/utils';
import { tu } from 'js/utils/translate';
import { findConcernByShopId } from 'js/utils/find';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate, useParams } from 'react-router-dom';

const EditShop: FC = () => {
  const shop = useAppSelector((state: RootState) => state?.freight?.shop?.shop?.data);
  const shopLoaded = useAppSelector((state: RootState) => state?.freight?.shop?.shop?.isLoaded);
  const concerns = useAppSelector(
    (state: RootState) => state?.freight?.concerns?.getConcerns?.data
  );
  const saving = useAppSelector((state: RootState) => state?.freight?.shop?.editShop?.isSaving);
  const error = useAppSelector((state: RootState) => state?.freight?.shop?.editShop?.error);

  const { shopId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!shopLoaded) dispatch(getShop(+shopId!));
  }, []);

  const redirectTo = `/freight/shop/${shopId}`;

  const handleSubmit = async (values) => {
    await dispatch(editShop(buildJsonPatchRequest(shop, values.shop), shop.shopId)).then(
      (response: boolean | undefined) => {
        if (response) navigate(redirectTo);
      }
    );
  };

  const concern = findConcernByShopId(concerns, shop?.shopId);
  const concernName = concern?.name;

  if (!shopLoaded) return <Spinner />;

  return (
    <SplitPage>
      <div className='edit-shop-page page-padding'>
        <FormV2
          className='edit-shop-form'
          initialValues={{ concernName, shop }}
          onSubmit={handleSubmit}
          error={error}
          loading={saving}
          redirectTo={redirectTo}
        >
          <div className='section'>
            <Input className='wide-input' name='shop.name' label={tu('general.name')} />
            <div className='group'>
              <div className='half-section'>
                <Input
                  name='concernName'
                  label={tu('shop.accordion.shopInfo.header.concern')}
                  disabled
                  optional
                  hideOptional
                />
                <Input
                  name='shop.organizationNumber'
                  label={tu('shop.accordion.shopInfo.header.organizationNumber')}
                  optional
                />
                <Input
                  name='shop.delivererEDI'
                  label={tu('shop.accordion.shopInfo.header.delivererEDI')}
                  optional
                />
              </div>
              <div className='half-section'>
                <Input
                  name='shop.shopId'
                  label={tu('shop.accordion.shopInfo.header.shopId')}
                  disabled
                  optional
                  hideOptional
                />
                <Input
                  name='shop.legalCompanyName'
                  label={tu('shop.accordion.shopInfo.header.legalCompanyName')}
                  optional
                />
                <div className='pays-vat-toggle'>
                  <Label label={tu('shop.accordion.shopInfo.header.paysVAT')} />
                  <Toggle name='shop.paysVAT' />
                </div>
              </div>
            </div>
            <Input
              className='wide-input'
              name='shop.platform'
              label={tu('edit.shop.webshop.platform')}
              optional
            />
            <div className='group'>
              <div className='half-section'>
                <Input name='shop.ceo' label={tu('shop.accordion.shopInfo.header.ceo')} optional />
              </div>
              <div className='half-section'>
                <Input
                  name='shop.customerHandler'
                  label={tu('shop.accordion.shopInfo.header.customerHandler')}
                  optional
                />
              </div>
            </div>
            <div className='group'>
              <div className='half-section'>
                <Input
                  name='shop.contactPersonEmail'
                  label={tu('shop.accordion.shopInfo.header.contactPersonEmail')}
                  optional
                />
              </div>
              <div className='half-section'>
                <Input
                  name='shop.contactPersonPhone'
                  label={tu('shop.accordion.shopInfo.header.contactPersonPhone')}
                  optional
                />
              </div>
            </div>
          </div>
          <AddressInput
            label={tu('shop.accordion.shopInfo.header.address')}
            namePrefix='shop.warehouse'
            optional
          />
        </FormV2>
      </div>
    </SplitPage>
  );
};

export default EditShop;
