import PropertyGroup from './components/PropertyGroup';

import t from 'js/utils/translate';
import { findTransportSolutionById } from 'js/utils/find';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';

const FreightProductInfo = ({ freightProduct }) => {
  const transportSolutions = useAppSelector(
    (state) => state.transportSolutions?.transportSolutions
  );

  const rebookTransportSolution = findTransportSolutionById(
    transportSolutions,
    freightProduct?.rebookTransportSolutionId
  );

  const groupedFreightProductProperties = [
    [
      {
        label: t('freight.product.properties.rebook'),
        value: freightProduct?.rebookTransportSolutionId ? rebookTransportSolution?.name : '-'
      }
    ],
    [
      {
        label: t('freight.product.properties.shop.max.value'),
        value: freightProduct.maxValueFromShop
      },
      {
        label: t('freight.product.properties.include.service.point'),
        value: freightProduct.includeServicePoint
      }
    ],
    [
      {
        label: t('freight.product.properties.check.carrier'),
        value: freightProduct.carrierDeliveryCheck
      },
      {
        label: t('freight.product.properties.check.retailer'),
        value: freightProduct.retailerDeliveryCheck
      }
    ],
    [
      {
        label: t('freight.product.properties.constraint.weight'),
        value: ` ${freightProduct.maxWeightGrams ?? '-'} g`
      },
      {
        label: t('freight.product.properties.constraint.value'),
        value: ` ${freightProduct.maxValue ?? '-'} kr`
      }
    ],
    [
      {
        label: t('freight.product.properties.constraint.volume'),
        value: ` ${freightProduct.maxWidthMM ?? '-'} x ${freightProduct.maxHeightMM ?? '-'} x ${
          freightProduct.maxLengthMM ?? '-'
        } mm`
      }
    ]
  ];

  return (
    <div className='freight-product__properties'>
      {groupedFreightProductProperties.map((group, index) => (
        <PropertyGroup group={group} key={index} />
      ))}
    </div>
  );
};

export default FreightProductInfo;
