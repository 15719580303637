import { FC } from 'react';

import PageHeaderButton from './components/PageHeaderButton';

import { PageHeaderAction } from '../../interfaces';

import './index.sass';

interface Props {
  isDesktop: boolean;
  pageHeaderActions: PageHeaderAction[];
}

const PageHeaderActions: FC<Props> = ({ isDesktop, pageHeaderActions }) => (
  <div className='page-header-actions'>
    {pageHeaderActions.map((action) =>
      action.onlyDesktop ? (
        isDesktop && (
          <PageHeaderButton
            key={action.text}
            text={action.text}
            path={action.path}
            variant={action.variant}
            mobileIcon={action.mobileIcon}
            isDesktop={isDesktop}
          />
        )
      ) : (
        <PageHeaderButton
          key={action.text}
          text={action.text}
          path={action.path}
          variant={action.variant}
          mobileIcon={action.mobileIcon}
          isDesktop={isDesktop}
        />
      )
    )}
  </div>
);

export default PageHeaderActions;
