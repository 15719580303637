const setContractServices = (updatedShop, contractServices) =>
  contractServices.forEach((contractService) => {
    const shopHadContractService = !!updatedShop?.shopContractServices.find(
      ({ code }) => code === contractService.code
    );
    const contractServiceActive = updatedShop[contractService.code];

    const addContractService = () => updatedShop.shopContractServices.push(contractService);
    const removeContractService = () => {
      updatedShop.shopContractServices = updatedShop.shopContractServices.filter(
        ({ code }) => code !== contractService.code
      );
    };

    if (contractServiceActive && !shopHadContractService) {
      addContractService();
    } else if (!contractServiceActive && shopHadContractService) {
      removeContractService();
    }
  });

const setRNLdefaultValue = (updatedShopContractServices, RNLdefaultOn) => {
  updatedShopContractServices.forEach((service, index) => {
    if (service.code === 'RNL') {
      if (service?.defaultOn === undefined) {
        updatedShopContractServices[index] = {
          defaultOn: RNLdefaultOn,
          ...service
        };
      } else {
        service.defaultOn = RNLdefaultOn;
      }
    }
  });
};

export const buildSubmit = (contractServices, updatedShop) => {
  setContractServices(updatedShop, contractServices);

  setRNLdefaultValue(updatedShop.shopContractServices, updatedShop.RNLdefaultOn);

  if (updatedShop?.contextId?.value) updatedShop.contextId = updatedShop?.contextId.value;
  if (updatedShop?.retailerProductId?.value)
    updatedShop.retailerProductId = updatedShop.retailerProductId.value;
  if (updatedShop?.shopPackageSystemLocations?.length > 0) {
    updatedShop.shopPackageSystemLocationIds = updatedShop?.shopPackageSystemLocations.map(
      ({ value }) => value
    );
    delete updatedShop.shopPackageSystemLocations;
  }

  delete updatedShop.RNL;
  delete updatedShop.RNLdefaultOn;
  delete updatedShop.ASB;
  delete updatedShop.ASA;
  delete updatedShop.A1;

  return updatedShop;
};

export const shopHasContractService = (shop, contractServiceCode) =>
  !!shop?.shopContractServices.find(
    (shopContractService) => shopContractService.code === contractServiceCode
  );
