import { FC } from 'react';
import { isEmpty } from 'lodash';

import InfoBox from 'js/components/InfoBox';

import { listBody } from 'js/components/CollapseV2/variants/listBody';
import { formatAddress, formatPhone } from '../../utils';
import { ApiOrderSimpleParty } from 'src/interfaces/interfaces.generated';

interface Props {
  party: ApiOrderSimpleParty;
}

const PartyBody: FC<Props> = ({ party }) => {
  if (!party || isEmpty(party)) return <InfoBox text='ingen.info' />;

  return listBody(
    [
      {
        label: 'name',
        content: party?.name ?? '-',
        orientation: 'row'
      },
      {
        label: 'address',
        content: formatAddress(party).toLowerCase(),
        orientation: 'row',
        itemClassName: 'address'
      },
      {
        label: 'telephone',
        content: formatPhone(party?.phone1),
        orientation: 'row'
      },
      {
        label: 'email',
        content: party?.email ?? '-',
        orientation: 'row'
      },
      {
        label: 'reference',
        content: party?.reference ?? '-',
        orientation: 'row'
      },
      {
        label: 'externId',
        content: party?.externalId ?? '-',
        orientation: 'row'
      },
      {
        label: 'additionalAddress',
        content: party?.coAddress ?? '-',
        orientation: 'row'
      },
      {
        label: 'additionalTelephone',
        content: formatPhone(party?.phone2),
        orientation: 'row'
      }
    ],
    'orderDetails.accordion.reciever.'
  );
};

export default PartyBody;
