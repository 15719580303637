import { FC, PropsWithChildren } from 'react';
import Label from 'js/components/Label';
import { tu } from 'js/utils/translate';

interface TuLabeledItemProps {
  label: string;
  className: string;
  translationKeyPrefix: string;
}

const TuLabeledItem: FC<PropsWithChildren<TuLabeledItemProps>> = ({
  label,
  className = '',
  translationKeyPrefix = '',
  children
}) => (
  <div className={className}>
    <Label label={tu(`${translationKeyPrefix}${label}`)} />
    {children}
  </div>
);

export default TuLabeledItem;
