import { FC, ChangeEvent } from 'react';

import './index.sass';

interface Props {
  name: string;
  label: string;
  value: string;
  checked?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Radio: FC<Props> = ({ name, label, value, checked, onChange }) => (
  <div className='radio'>
    <label>
      <input type='radio' name={name} value={value} checked={checked} onChange={onChange} />
      <span className='styled-radio'>{label}</span>
    </label>
  </div>
);

export default Radio;
