import { FC } from 'react';

import ButtonV2 from 'js/components/ButtonV2';
import NotFoundImg from '../../../images/icons/NotFound.svg';

import t from 'js/utils/translate';

import './index.sass';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: FC = () => {
  const navigate = useNavigate();
  return (
    <div className='not-found-page'>
      <div className='not-found-page__content'>
        <h1>{t('not.found.page.text')}</h1>
        <NotFoundImg />
        <div className='button-wrapper'>
          <ButtonV2
            className='not-found-page-button-reload'
            text={t('not.found.page.button.reload')}
            variant='primary'
            onClick={() => {
              location.reload();
            }}
          />
          <ButtonV2
            className='not-found-page-button-home'
            text={t('not.found.page.button.home')}
            variant='inverse'
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
