import { findShopById, findServiceSupplierById } from 'js/utils/find';
import { Option } from 'js/components/SelectV2/interfaces';
import {
  ApiShopServiceSupplier,
  ApiServiceSupplier,
  ApiFlowShopSimple
} from 'src/interfaces/interfaces.generated';

const findServiceSupplierShops = (serviceSupplier: ApiServiceSupplier) =>
  serviceSupplier?.shopAndExternalId?.map((sne) => sne.shopId);

export const findSelectedServiceSupplierShopsById = (
  serviceSuppliers: ApiServiceSupplier[],
  serviceSupplierId: number,
  shops: ApiFlowShopSimple[]
): Option[] | undefined =>
  findServiceSupplierShops(findServiceSupplierById(serviceSuppliers, serviceSupplierId))?.map(
    (id): Option => ({ value: id, label: findShopById(shops, id).name })
  );

export const findSelectedServiceSupplierShops = (serviceSupplier, shops) =>
  findServiceSupplierShops(serviceSupplier)?.map((id) => ({
    value: id,
    label: findShopById(shops, id).name
  }));

export const findNewElements = (selectList, selectedShops) =>
  selectList.filter((element) => !selectedShops.find((shop) => element.value === shop.shopId));

export const mapToShopServiceSuppliers = (list: Option[]): ApiShopServiceSupplier[] =>
  list.map((element) => ({
    shopId: element.value ? +element.value : undefined,
    externalSupplierId: undefined
  }));
