import { useFormContext } from '@distribution-innovation/form-hooks';
import SelectV2 from 'js/components/Form/components/SelectV2';
import { languages, tu } from 'js/utils/translate';
import { FC, useEffect } from 'react';
import { ApiUser } from 'src/interfaces/interfaces.generated';
import { languagesOptions } from 'js/components/Select/utils';

interface Props {
  name: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  user?: ApiUser;
}

const SelectLanguage: FC<Props> = ({ name, className, disabled, placeholder, user }) => {
  const context = useFormContext();

  useEffect(() => {
    if (user) context.setValue(name, user.language);
  }, []);

  return (
    <SelectV2
      label={tu('general.language')}
      className={className}
      disabled={disabled}
      optional={disabled}
      options={languagesOptions(languages)}
      name={name}
      placeholder={placeholder}
      hideOptional
    />
  );
};

export default SelectLanguage;
