import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as api from '../../api';
import { reloadShop } from '../shopReducer';

export interface EditShopReducerState {
  error: unknown;
  isSaving: boolean;
}

const initialState: EditShopReducerState = {
  error: null,
  isSaving: false
};

const editShopSlice = createSlice({
  name: 'EditShop',
  initialState,
  reducers: {
    editShopRequest(state) {
      state.error = null;
      state.isSaving = true;
    },
    editShopSuccess(state) {
      state.isSaving = false;
    },
    editShopFailed(state, action) {
      state.error = action.payload;
      state.isSaving = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { editShopRequest, editShopSuccess, editShopFailed } = editShopSlice.actions;

export default editShopSlice.reducer;

export const editShop =
  (data, shopId: number | undefined) =>
  async (dispatch): Promise<boolean | undefined> => {
    if (!shopId) return false;
    try {
      dispatch(editShopRequest());
      await api.updateShopv2(data, shopId);
      dispatch(reloadShop(shopId));
      dispatch(editShopSuccess());
      return true;
    } catch (error) {
      dispatch(editShopFailed(error));
    }
  };
