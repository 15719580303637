import { findAdminRights } from 'js/scenes/Freight/components/Users/components/SelectAdminRights/utils';
import { ApiUser, ApiWebservice } from 'src/interfaces/interfaces.generated';

export const resetValues = (
  name: string,
  context,
  user: ApiUser,
  index: number,
  isApiContext?: boolean
): void => {
  context.setValue(`${name}[${index}][language]`, user.language);
  if (isApiContext) {
    context.setValue(`${name}[${index}][email]`, user.email);
    context.setValue(
      `${name}[${index}][apis]`,
      user.apiWebserviceList?.map((ws: ApiWebservice) => ws.webserviceId)
    );
  } else {
    context.setValue(`${name}[${index}][adminRights]`, findAdminRights(user));
  }
};
