import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import SelectV2 from 'js/components/Form/components/SelectV2';

import { getConcerns } from 'js/scenes/Freight/scenes/Concerns/store/getConcernsReducer';

import { concernOptions } from 'js/components/Select/utils';

import { tu } from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface Props {
  name: string;
  disabled?: boolean;
}

const SelectConcerns: FC<Props> = ({ name, disabled }) => {
  const dispatch = useAppDispatch();

  const concerns = useAppSelector(
    (state: RootState) => state?.freight?.concerns?.getConcerns?.data
  );
  const concernsLoaded = useAppSelector(
    (state: RootState) => state.freight?.concerns?.getConcerns?.isLoaded
  );

  useEffect(() => {
    if (!concernsLoaded) dispatch(getConcerns());
  }, []);

  return (
    <SelectV2
      name={name}
      isMulti
      selectAll
      label={tu('general.concerns')}
      options={concernOptions(concerns)}
      isLoading={!(disabled ?? concernsLoaded)}
      disabled={disabled}
      optional
    />
  );
};

export default SelectConcerns;
