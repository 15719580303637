import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import InfoBox from 'js/components/InfoBox';
import Spinner from 'js/components/Spinner';
import ConcernForm from '../../components/ConcernForm';

import { getConcerns } from '../../store/getConcernsReducer';
import { editConcern } from './store/editConcernReducer';
import { findConcernById } from 'js/utils/find';
import t from 'js/utils/translate';
import { ApiConcern } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate, useParams } from 'react-router-dom';

const EditConcern: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const concerns: ApiConcern[] = useAppSelector(
    (state: RootState) => state?.freight?.concerns?.getConcerns?.data
  );
  const concernsLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.concerns?.getConcerns?.isLoaded
  );

  const isSaving = useAppSelector(
    (state: RootState) => state?.freight?.concerns?.editConcern?.isSaving
  );
  const error = useAppSelector((state: RootState) => state?.freight?.concerns?.editConcern?.error);

  const concernId = params?.concernId;

  useEffect(() => {
    if (!concernsLoaded) dispatch(getConcerns());
  }, [concernsLoaded]);

  if (!concernId) return <InfoBox text={t('not.possible')} />;

  if (!concernsLoaded) return <Spinner />;

  const redirectTo = {
    pathname: '/freight',
    state: { activeTab: 'concerns' }
  };

  const redirect = () => {
    navigate(redirectTo);
  };

  const handleSubmit = (values: ApiConcern) => {
    dispatch(editConcern(values)).then((response: boolean | undefined) => {
      if (response) {
        dispatch(getConcerns()).then((response: boolean | undefined) => {
          if (response) redirect();
        });
      }
    });
  };

  const initialValues = findConcernById(concerns, params?.concernId);

  return (
    <SplitPage>
      <PageWrapper className='edit-concern'>
        <ConcernForm
          concernId={+concernId}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          type='save'
          loading={isSaving}
          error={error}
          onCancel={redirect}
        />
      </PageWrapper>
    </SplitPage>
  );
};

export default EditConcern;
