import { FC } from 'react';

import t from 'js/utils/translate';

import './index.sass';

interface Props {
  text: string;
}

const DashboardDataText: FC<Props> = ({ text }) => (
  <span className='dashboard-data-text'>{t(`mainPanel.statistics.orders.${text}`)}</span>
);

export default DashboardDataText;
