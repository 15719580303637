import { FC } from 'react';

import FormV2 from 'js/components/FormV2';
import Users from 'js/scenes/Freight/components/Users';

import { ApiUser } from 'src/interfaces/interfaces.generated';

interface Props {
  users: ApiUser[];
  // eslint-disable-next-line no-unused-vars
  onSubmit: (values: any) => void;
  error: unknown;
  isSubmitting: boolean;
  redirectTo: string;
  isApiContext?: boolean;
}

const UserForm: FC<Props> = ({
  users,
  onSubmit,
  error,
  isSubmitting,
  redirectTo,
  isApiContext
}) => (
  <FormV2
    initialValues={{ users }}
    onSubmit={onSubmit}
    error={error}
    loading={isSubmitting}
    redirectTo={redirectTo}
  >
    <Users users={users} isApiContext={isApiContext} />
  </FormV2>
);

export default UserForm;
