import { useMemo } from 'react';

import PageWrapper from 'js/components/PageWrapper';
import InfoBox from 'js/components/InfoBox';
import Spinner from 'js/components/Spinner';
import TableV2 from 'js/components/TableV2';

import t, { tu } from 'js/utils/translate';
import { findShopById } from 'js/utils/find';
import CommaSeparatedNames from 'js/components/CommaSeparatedNames';
import { useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

const ConcernsTable = ({ concerns, shops, rowIcon, concernsLoaded }) => {
  const navigate = useNavigate();
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);

  const onClickRow = (concernId) => {
    navigate(`concern/${concernId}/edit`);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('general.concerns'),
        accessor: 'name',
        inHeader: true
      },
      {
        Header: tu('general.shops'),
        accessor: (concern) => (
          <CommaSeparatedNames ids={concern.shopIds} list={shops} find={findShopById} />
        )
      }
    ],
    []
  );

  if (!concernsLoaded) return <Spinner />;

  if (concerns.length === 0) {
    return (
      <PageWrapper>
        <InfoBox text={t('freight.concerns.notFound')} />
      </PageWrapper>
    );
  }

  return (
    <TableV2
      className={isDesktop ? 'page-padding' : 'concerns-mobile-table'}
      mobileTableVariant='withSmallEditIcon'
      columns={columns}
      data={concerns}
      rowIcon={rowIcon}
      goToLabel={t('freight.concerns.edit')}
      onClickRow={onClickRow}
      idAccessor='concernId'
      pagination
    />
  );
};

export default ConcernsTable;
