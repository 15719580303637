import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import FirstStep from './scenes/FirstStep';
import PartyStep from './scenes/PartyStep';
import ParcelsStep from './scenes/ParcelsStep';
import FormV2 from 'js/components/FormV2';
import ActionButtons from './components/ActionButtons';
import ValidationErrors from './components/ValidationErrors';
import StepIndicator from './components/StepIndicator';
import Spinner from 'js/components/Spinner';

import { createNewOrder } from './store/newOrderReducer';
import { getFullOrder } from './store/getFullOrderReducer';
import {
  buildSubmit,
  emptyInitialValues,
  findCurrentView,
  fromOrderInitialValues,
  fromOrderParty
} from './utils';

import { RootState } from 'js/store/reducer';
import { NewOrderFormValues } from './interfaces';
import { ApiBookingResponse, ApiOrder } from 'src/interfaces/interfaces.generated';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate } from 'react-router-dom';
import { PartyType } from 'js/scenes/Orders/scenes/NewOrder/index.types';

const NewOrder: FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [maxSteps, setMaxSteps] = useState(4);

  const [consigneeType, setConsigneeType] = useState<PartyType>('custom');
  const [consignorType, setConsignorType] = useState<PartyType>('custom');

  const [nextDisabled, setNextDisabled] = useState(false);

  const originalOrder = useAppSelector((state: RootState) => state?.orders?.getFullOrder?.data);
  const error = useAppSelector((state: RootState) => state?.orders?.newOrder?.error);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    consigneeType === 'shop' || consignorType === 'shop' ? setMaxSteps(3) : setMaxSteps(4);
  }, [consigneeType, consignorType]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const [servicePointId, setServicePointId] = useState<string>();

  const servicePoint = useAppSelector(
    (state: RootState) =>
      state?.orders?.servicePoints.data.find(
        (servicePoint) => servicePoint.servicePointId === servicePointId
      )
  );

  const handleSubmit = (values: NewOrderFormValues) => {
    const order: ApiOrder = buildSubmit(values, servicePoint);
    dispatch(createNewOrder(order)).then((response: ApiBookingResponse | undefined) => {
      if (response) {
        const shipmentId = response.shipmentId?.slice(5);
        navigate(`/orderSearch/${shipmentId}`);
      }
    });
  };

  const [initialValues, setInitialValues] = useState<unknown | undefined>();

  useEffect(() => {
    if (location?.state?.fromOrder) {
      dispatch(getFullOrder(location?.state?.fromOrder)).then((order: ApiOrder | undefined) => {
        if (order) setInitialValues(fromOrderInitialValues(order));
        else setInitialValues(emptyInitialValues);
      });
    } else setInitialValues(emptyInitialValues);
  }, []);

  const currentView = findCurrentView(
    currentStep,
    consigneeType !== 'shop',
    consignorType !== 'shop'
  );

  if (!initialValues) return <Spinner />;

  return (
    <div className='new-order'>
      <FormV2 onSubmit={handleSubmit} initialValues={initialValues} actions={false} error={error}>
        {currentView === 'firstStep' && (
          <FirstStep
            consigneeType={consigneeType}
            consignorType={consignorType}
            setConsigneeType={setConsigneeType}
            setConsignorType={setConsignorType}
            setNextDisabled={setNextDisabled}
          />
        )}
        {currentView === 'consigneeStep' && (
          <PartyStep
            party='consignee'
            initialValues={
              (consigneeType === 'consignee' || consigneeType === 'consignor') && originalOrder
                ? fromOrderParty(originalOrder?.[consigneeType])
                : undefined
            }
            setNextDisabled={setNextDisabled}
            servicePointChanged={setServicePointId}
          />
        )}
        {currentView === 'consignorStep' && (
          <PartyStep
            party='consignor'
            initialValues={
              (consignorType === 'consignee' || consignorType === 'consignor') && originalOrder
                ? fromOrderParty(originalOrder?.[consignorType])
                : undefined
            }
            setNextDisabled={setNextDisabled}
          />
        )}
        {currentView === 'finalStep' && <ParcelsStep />}
        <div className='wrapper'>
          <StepIndicator currentStep={currentStep} maxSteps={maxSteps} />
          <ActionButtons
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            nextDisabled={nextDisabled}
            nextIsSubmit={currentStep === maxSteps}
            fromOrder={location?.state?.fromOrder}
          />
          <ValidationErrors />
        </div>
      </FormV2>
    </div>
  );
};

export default NewOrder;
