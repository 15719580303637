import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import MessageForUser from 'js/components/MessageForUser';
import LinkV2 from 'js/components/LinkV2';
import PageWrapper from 'js/components/PageWrapper';

import { findChangeStat } from './utils';
import { deleteCostPrices } from 'js/scenes/Economy/scenes/CostPrices/store';
import t from 'js/utils/translate';

import { RootState } from 'js/store/reducer';
import { TransportPriceTable } from 'src/interfaces/interfaces.generated';

import './index.sass';

interface Props {
  shopId: number;
  freightProductId: number;
  freightProductPrices: TransportPriceTable;
  redirect: () => void;
}

const DeleteFuturePrices: FC<Props> = ({
  shopId,
  freightProductId,
  freightProductPrices,
  redirect
}) => {
  const dispatch = useAppDispatch();

  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const changeStatistic = findChangeStat(freightProductPrices);

  const error = useAppSelector((state: RootState) => state?.economy?.costPrices?.deleteError);

  const handleClick = async () =>
    await (changeStatistic?.fromDate &&
      dispatch(deleteCostPrices(shopId, freightProductId, changeStatistic.fromDate)));

  return (
    <PageWrapper className='delete-future-prices' withoutPadding={isDesktop}>
      <MessageForUser
        variant='info'
        message={t(
          'economy.edit.costprice.future.change.added.by',
          changeStatistic?.changeUserFirstName ?? '-',
          changeStatistic?.changeUserLastName ?? '-',
          changeStatistic?.changeUserUserName ?? '-'
        )}
      >
        <LinkV2
          onClick={() => handleClick}
          text={t('economy.edit.costprice.delete.future.price')}
          error={!!error}
        />
      </MessageForUser>
      <LinkV2
        className='delete-future-prices__cancel-link'
        onClick={() => {
          redirect();
        }}
        text={t('general.cancel')}
      />
    </PageWrapper>
  );
};

export default DeleteFuturePrices;
