import Icon from 'js/components/Icon';

import './index.sass';

const LabelHistoryDate = ({ fromDate, toDate, fromText, toText }) =>
  fromDate && (
    <div className='label-history-date'>
      <div className='label-history-date__text-wrapper'>
        <div className='label-history-date--text'>{fromText}</div>
        <Icon icon='calendar-alt' spanClassName='calendar' />
        <div>{fromDate}</div>
      </div>
      <div className='label-history-date__text-wrapper'>
        <div className='label-history-date--text'>{toText}</div>
        <Icon icon='calendar-alt' spanClassName='calendar' />
        <div>{toDate}</div>
      </div>
    </div>
  );

export default LabelHistoryDate;
