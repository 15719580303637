import { createSlice } from '@reduxjs/toolkit';
import { ApiOrderSimple } from 'src/interfaces/interfaces.generated';
import * as api from '../../api';
import { locationChanged } from 'js/store/actions';

export interface OrderReducerState {
  error: unknown;
  data: ApiOrderSimple;
  isLoaded: boolean;
}

const initialState: OrderReducerState = {
  error: null,
  data: {},
  isLoaded: false
};

const orderSlice = createSlice({
  name: 'Order',
  initialState,
  reducers: {
    setOrder(state, action) {
      state.data = action.payload;
      state.isLoaded = true;
    },
    getOrderRequest(state) {
      state.error = null;
      state.isLoaded = false;
    },
    getOrderSuccess(state, action) {
      state.data = action.payload;
      state.isLoaded = true;
    },
    getOrderFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { setOrder, getOrderRequest, getOrderSuccess, getOrderFailed } = orderSlice.actions;

export default orderSlice.reducer;

export const setSelectedOrder = (order: ApiOrderSimple) => (dispatch) => dispatch(setOrder(order));

export const getOrder =
  (trackingReference: string) =>
  async (dispatch): Promise<ApiOrderSimple | undefined> => {
    try {
      dispatch(getOrderRequest());
      const response: ApiOrderSimple[] = await api.getOrder(trackingReference);
      if (response.length > 0) {
        const order = response[0];
        dispatch(getOrderSuccess(order));
        return order;
      } else {
        dispatch(getOrderSuccess({}));
        return {};
      }
    } catch (error) {
      dispatch(getOrderFailed(error));
    }
  };
