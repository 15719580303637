import { FC, useMemo } from 'react';

import InfoBox from 'js/components/InfoBox';
import LabeledItem from 'js/components/LabeledItem';
import TableV2 from 'js/components/TableV2';
import DateTime from 'js/components/DateTime';

import t from 'js/utils/translate';

import { ApiLoadCarrier } from './interfaces';
import { RootState } from 'js/store/reducer';

import './index.sass';
import { safariSafeDateString } from 'js/utils/dateTime';
import { useAppSelector } from 'js/utils/hooks';
import { Column } from 'js/components/TableV2/interfaces';

interface LoadCarrierBodyProps {
  loadCarriers: ApiLoadCarrier[];
}

const LoadCarrierBody: FC<LoadCarrierBodyProps> = ({ loadCarriers }) => {
  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const columns: Array<Column<ApiLoadCarrier>> = useMemo(
    () => [
      {
        Header: t('orderDetails.accordion.loadCarrier.parcelNumber'),
        accessor: ({ loadCarrierParcelNumber }) => <div>{loadCarrierParcelNumber}</div>,
        inHeader: true
      },
      {
        Header: t('orderDetails.accordion.loadCarrier.added'),
        accessor: ({ added }) =>
          isDesktop ? (
            <DateTime datetime={new Date(safariSafeDateString(added))} row />
          ) : (
            <LabeledItem
              label={t('orderDetails.accordion.loadCarrier.added')}
              item={<DateTime datetime={new Date(safariSafeDateString(added))} />}
            />
          )
      },
      {
        Header: t('orderDetails.accordion.loadCarrier.removed'),
        accessor: ({ removed }) =>
          isDesktop ? (
            <DateTime datetime={new Date(safariSafeDateString(removed))} row />
          ) : (
            <LabeledItem
              label={t('orderDetails.accordion.loadCarrier.removed')}
              item={<DateTime datetime={new Date(safariSafeDateString(removed))} />}
            />
          )
      }
    ],
    [isDesktop]
  );

  if (!loadCarriers || loadCarriers.length === 0)
    return <InfoBox text={t('general.noInformationFound')} />;

  return (
    <TableV2
      className={isDesktop ? '' : 'load-carriers-mobile-table'}
      columns={columns}
      data={loadCarriers}
      mobileTableVariant='withSmallEditIcon'
    />
  );
};

export default LoadCarrierBody;
