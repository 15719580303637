import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as api from '../../api';

export interface RemoveServiceSupplierUserReducerState {
  error: unknown;
  id: number | null;
  isRemoving: boolean;
  isRemoved: boolean;
}

const initialState: RemoveServiceSupplierUserReducerState = {
  error: null,
  id: null,
  isRemoving: false,
  isRemoved: false
};

const removeServiceSupplierUserSlice = createSlice({
  name: 'removeServiceSupplierUser',
  initialState,
  reducers: {
    removeServiceSupplierUserRequest(state, action) {
      state.error = null;
      state.id = action.payload;
      state.isRemoved = false;
      state.isRemoving = true;
    },
    removeServiceSupplierUserSuccess(state) {
      state.isRemoved = true;
      state.isRemoving = false;
    },
    removeServiceSupplierUserFailed(state, action) {
      state.error = action.payload;
      state.isRemoving = false;
      state.isRemoved = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  removeServiceSupplierUserRequest,
  removeServiceSupplierUserSuccess,
  removeServiceSupplierUserFailed
} = removeServiceSupplierUserSlice.actions;

export default removeServiceSupplierUserSlice.reducer;

export const removeServiceSupplierUser =
  (serviceSupplierId: number, userId: number) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(removeServiceSupplierUserRequest(userId));
      await api.removeUserFromServiceSupplier(serviceSupplierId, userId);
      dispatch(removeServiceSupplierUserSuccess());
      return true;
    } catch (error) {
      dispatch(removeServiceSupplierUserFailed(error));
    }
  };
