import { matchPath } from 'react-router-dom';

import Login from 'js/scenes/Login';
import NotFoundPage from 'js/components/NotFoundPage';
import Settings from 'js/scenes/Settings';

import Home from 'js/scenes/Home';
import Reports from 'js/scenes/Home/scenes/Reports';
import OrderSearch from 'js/scenes/Home/scenes/OrderSearch';
import ParcelDetails from 'js/scenes/Home/scenes/OrderDetails';
import EditOrderParty from 'js/scenes/Home/scenes/OrderDetails/scenes/EditOrderParty';
import EditServicePoint from 'js/scenes/Home/scenes/OrderDetails/scenes/EditServicePoint';
import EditShipment from 'js/scenes/Home/scenes/OrderDetails/scenes/EditShipment';
import EditOrder from 'js/scenes/Home/scenes/OrderDetails/scenes/EditOrder';
import EditStatus from 'js/scenes/Home/scenes/OrderDetails/scenes/EditStatus/index';
import Freight from 'js/scenes/Freight';

import NewServiceSupplier from 'js/scenes/Freight/scenes/ServiceSuppliers/scenes/NewServiceSupplier';
import EditServiceSupplier from 'js/scenes/Freight/scenes/ServiceSuppliers/scenes/EditServiceSupplier';
import NewConcern from 'js/scenes/Freight/scenes/Concerns/scenes/NewConcern';
import EditConcern from 'js/scenes/Freight/scenes/Concerns/scenes/EditConcern';
import TransportSolutions from 'js/scenes/Freight/scenes/TransportSolutions';
import NewTransportSolution from 'js/scenes/Freight/scenes/TransportSolutions/scenes/NewTransportSolution';
import EditTransportSolution from 'js/scenes/Freight/scenes/TransportSolutions/scenes/EditTransportSolution';
import FreightProducts from 'js/scenes/Freight/scenes/FreightProducts';
import EditFreightProduct from 'js/scenes/Freight/scenes/FreightProducts/scenes/EditFreightProduct';

import Shop from 'js/scenes/Freight/scenes/Shops/scenes/Shop';
import NewShop from 'js/scenes/Freight/scenes/Shops/scenes/NewShop';
import RemoveShop from 'js/scenes/Freight/scenes/Shops/scenes/Shop/scenes/RemoveShop';
import EditShop from 'js/scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditShop';
import EditShopTransportSolutions from './scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditShopTransportSolutions';
import EditShopFreightProducts from './scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditShopFreightProducts';
import EditShopProofOfDelivery from './scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditShopProofOfDelivery';
import EditShopCustomerSystems from './scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditCustomerSystem';
import EditShopEconomy from './scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditShopEconomy';
import EditShopUsers from './scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditShopUsers';
import EditApiUsers from './scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditApiUsers';
import EditShopConfigurations from './scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditShopConfigurations';
import EditCustomerCommunication from './scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditCustomerCommunication';

import Orders from './scenes/Orders';
import NewOrder from './scenes/Orders/scenes/NewOrder';
import Labels from './scenes/Orders/scenes/Labels';
import BulkOrder from 'js/scenes/Orders/scenes/BulkOrder';
import NewBulkOrder from 'js/scenes/Orders/scenes/BulkOrder/scenes/NewBulkOrder';
import LabelHistory from 'js/scenes/Orders/scenes/LabelHistory';

import Economy from './scenes/Economy';
import CostPrices from 'js/scenes/Economy/scenes/CostPrices';
import EditCostPrices from 'js/scenes/Economy/scenes/CostPrices/scenes/EditCostPrices';
import InvoiceBatchesForShop from 'js/scenes/Economy/scenes/InvoiceBatchesForShop';
import InvoiceBatches from 'js/scenes/Economy/scenes/InvoiceBatches';
import NewInvoice from 'js/scenes/Economy/scenes/InvoiceBatches/scenes/NewInvoice';
import InvoiceSetup from 'js/scenes/Economy/scenes/InvoiceSetup';
import EditInvoiceSetup from 'js/scenes/Economy/scenes/InvoiceSetup/scenes/EditInvoiceSetup';
import PriceAdjustments from 'js/scenes/Economy/scenes/PriceAdjustments';
import NewPriceAdjustment from 'js/scenes/Economy/scenes/PriceAdjustments/scenes/NewPriceAdjustment';
import EditPriceAdjustment from 'js/scenes/Economy/scenes/PriceAdjustments/scenes/EditPriceAdjustment';

import NewOrderIcon from 'images/icons/NewOrder.svg';
import Jobs from 'js/scenes/Jobs';
import JobDetail from 'js/scenes/Jobs/scenes/JobDetail';
import UserAdministration from 'js/scenes/UserAdministration/Users.page';
import CreateOrEditUser from 'js/scenes/UserAdministration/UserActions/CreateAndEdit/UserForm.page';
import ConnectUser from 'js/scenes/UserAdministration/UserActions/Connect/UserForm.page';

const routes = [
  {
    name: 'Home',
    path: '/',
    component: <Home />,
    includeInHeader: true
  },
  {
    name: 'Order Search',
    path: '/orderSearch',
    component: <OrderSearch />,
    parent: 'Home',
    pageHeaderActionsRoleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin,
    pageHeaderActions: [
      {
        text: 'new.order',
        path: '/orders/newOrder',
        mobileIcon: NewOrderIcon,
        variant: 'inverse'
      }
    ]
  },
  {
    name: 'Order Details',
    path: '/orderSearch/:trackingReference',
    displayName: 'orderSearch',
    component: <ParcelDetails />,
    parent: 'Home/orderSearch',
    excludeFromDropdown: true
  },
  {
    name: 'Edit Status',
    path: '/orderSearch/:trackingReference/edit/status',
    component: <EditStatus />,
    parent: 'Home/OrderSearch/:trackingReference',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.qualityAdmin
  },
  {
    name: 'Edit Order',
    path: '/orderSearch/:trackingReference/edit/order',
    component: <EditOrder />,
    parent: 'Home/OrderSearch/:trackingReference',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin
  },
  {
    name: 'Edit Recipient',
    path: '/orderSearch/:trackingReference/edit/recipient',
    component: <EditOrderParty />,
    parent: 'Home/OrderSearch/:trackingReference',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin
  },
  {
    name: 'Edit Sender',
    path: '/orderSearch/:trackingReference/edit/sender',
    component: <EditOrderParty />,
    parent: 'Home/OrderSearch/:trackingReference',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin
  },
  {
    name: 'Edit Service Point',
    path: '/orderSearch/:trackingReference/edit/servicePoint',
    component: <EditServicePoint />,
    parent: 'Home/OrderSearch/:trackingReference',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin
  },
  {
    name: 'Edit Shipment',
    path: '/orderSearch/:trackingReference/edit/shipment',
    component: <EditShipment />,
    parent: 'Home/OrderSearch/:trackingReference',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin
  },
  {
    name: 'Reports',
    path: '/reports',
    component: <Reports />,
    parent: 'Home'
  },
  {
    name: 'Freight',
    path: '/freight',
    component: <Freight />,
    includeInHeader: true,
    pageHeaderActionsRoleAccess: (roles) => roles?.parcelAdmin,
    pageHeaderActions: [
      { text: 'new.shop', path: '/freight/shop/new', onlyDesktop: true },
      {
        text: 'new.concern',
        path: '/freight/concern/new',
        variant: 'inverse',
        onlyDesktop: true
      },
      {
        text: 'new.servicesupplier',
        path: '/freight/servicesupplier/new',
        variant: 'inverse',
        onlyDesktop: true
      }
    ]
  },
  {
    name: 'New Shop',
    path: '/freight/shop/new',
    component: <NewShop />,
    parent: 'Freight',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'New Concern',
    path: '/freight/concern/new',
    component: <NewConcern />,
    parent: 'Freight',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Edit Concern',
    path: '/freight/concern/:concernId/edit',
    component: <EditConcern />,
    parent: 'Freight',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'New Service Supplier',
    path: '/freight/servicesupplier/new',
    component: <NewServiceSupplier />,
    parent: 'Freight',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Edit Service Supplier',
    path: '/freight/servicesupplier/:serviceSupplierId/edit',
    component: <EditServiceSupplier />,
    parent: 'Freight',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Shop',
    path: '/freight/shop/:shopId',
    component: <Shop />,
    parent: 'Freight',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin || roles?.shop
  },
  {
    name: 'Remove Shop',
    path: '/freight/shop/:shopId/remove',
    component: <RemoveShop />,
    parent: 'Freight/Shop/:shopId',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Edit Shop Info',
    path: '/freight/shop/:shopId/edit/shopInfo',
    component: <EditShop />,
    parent: 'Freight/Shop/:shopId',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Edit Shop Transport Solutions',
    path: '/freight/shop/:shopId/edit/transportSolutions',
    component: <EditShopTransportSolutions />,
    parent: 'Freight/Shop/:shopId',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Edit Shop Freight Products',
    path: '/freight/shop/:shopId/edit/freightProducts',
    component: <EditShopFreightProducts />,
    parent: 'Freight/Shop/:shopId',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Edit Shop Proof Of Delivery',
    path: '/freight/shop/:shopId/edit/proofOfDelivery',
    component: <EditShopProofOfDelivery />,
    parent: 'Freight/Shop/:shopId',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Edit Shop User Api Access',
    path: '/freight/shop/:shopId/edit/api',
    component: <EditApiUsers />,
    parent: 'Freight/Shop/:shopId',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Edit Shop Users',
    path: '/freight/shop/:shopId/edit/users',
    component: <EditShopUsers />,
    parent: 'Freight/Shop/:shopId',
    excludeFromDropdown: true,
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Edit Shop Configurations',
    path: '/freight/shop/:shopId/edit/configurations',
    component: <EditShopConfigurations />,
    parent: 'Freight/Shop/:shopId',
    excludeFromDropdown: true
  },
  {
    name: 'Edit Customer Communication',
    path: '/freight/shop/:shopId/edit/customerCommunication',
    component: <EditCustomerCommunication />,
    parent: 'Freight/Shop/:shopId',
    excludeFromDropdown: true
  },
  {
    name: 'Edit Shop Customer Systems',
    path: '/freight/shop/:shopId/edit/customerSystem',
    component: <EditShopCustomerSystems />,
    parent: 'Freight/Shop/:shopId',
    excludeFromDropdown: true
  },
  {
    name: 'Edit Shop Economy',
    path: '/freight/shop/:shopId/edit/economy',
    component: <EditShopEconomy />,
    parent: 'Freight/Shop/:shopId',
    excludeFromDropdown: true
  },
  {
    name: 'Transport Solutions',
    path: '/freight/transportSolutions',
    component: <TransportSolutions />,
    parent: 'Freight',
    roleAccess: (roles) => roles?.parcelAdmin,
    pageHeaderActionsRoleAccess: (roles) => roles?.parcelAdmin,
    pageHeaderActions: [
      {
        text: 'new.transportsolution',
        path: '/freight/transportSolutions/new',
        onlyDesktop: true
      }
    ]
  },
  {
    name: 'New Transport Solution',
    path: '/freight/transportSolutions/new',
    component: <NewTransportSolution />,
    parent: 'Freight/Transportsolutions',
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Edit Transport Solution',
    path: '/freight/transportSolutions/:transportSolutionId/edit',
    component: <EditTransportSolution />,
    parent: 'Freight/TransportSolutions',
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Freight Products',
    path: '/freight/freightProducts',
    component: <FreightProducts />,
    parent: 'Freight',
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Edit Freight Product',
    path: '/freight/freightProducts/:freightProductId/edit',
    component: <EditFreightProduct />,
    parent: 'Freight/FreightProducts',
    roleAccess: (roles) => roles?.parcelAdmin
  },
  {
    name: 'Orders',
    path: '/orders',
    component: <Orders />,
    includeInHeader: true,
    roleAccess: (roles) =>
      roles?.parcelAdmin || roles?.shopAdmin || (roles?.serviceSupplier && roles?.label)
  },
  {
    name: 'Labels',
    path: '/orders/labels',
    component: <Labels />,
    parent: 'Orders',
    roleAccess: (roles) =>
      roles?.parcelAdmin ||
      (roles?.shopAdmin && roles?.label) ||
      (roles?.serviceSupplier && roles?.label)
  },
  {
    name: 'Label History',
    path: '/orders/labelHistory',
    component: <LabelHistory />,
    parent: 'Orders',
    roleAccess: (roles) =>
      roles?.parcelAdmin ||
      (roles?.shopAdmin && roles?.label) ||
      (roles?.serviceSupplier && roles?.label)
  },
  {
    name: 'New Order',
    path: '/orders/newOrder',
    component: <NewOrder />,
    parent: 'Orders',
    roleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin
  },
  {
    name: 'Bulk Order',
    path: '/orders/bulkOrder',
    component: <BulkOrder />,
    parent: 'Orders',
    pageHeaderActions: [
      {
        text: 'new.bulk.order',
        path: '/orders/bulkOrder/new',
        onlyDesktop: true
      }
    ],
    roleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin
  },
  {
    name: 'New Bulk Order',
    path: '/orders/bulkOrder/new',
    component: <NewBulkOrder />,
    parent: 'Orders/BulkOrder',
    roleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin
  },
  {
    name: 'Economy',
    path: '/economy',
    component: <Economy />,
    includeInHeader: true,
    roleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin || roles?.invoice
  },
  {
    name: 'Invoice',
    path: '/economy/invoice',
    component: <InvoiceBatchesForShop />,
    parent: 'Economy',
    roleAccess: (roles) => roles?.parcelAdmin || roles?.shopAdmin || roles?.invoice
  },
  {
    name: 'Generate Invoice',
    path: '/economy/generateInvoice',
    component: <InvoiceBatches />,
    parent: 'Economy',
    roleAccess: (roles) => roles?.parcelAdmin && roles?.invoice,
    pageHeaderActions: [
      {
        text: 'economy.invoice.new',
        path: '/economy/generateInvoice/new',
        onlyDesktop: true
      }
    ]
  },
  {
    name: 'New invoice',
    path: '/economy/generateInvoice/new',
    component: <NewInvoice />,
    parent: 'Economy/GenerateInvoice',
    roleAccess: (roles) => roles?.parcelAdmin && roles?.invoice
  },
  {
    name: 'Price Adjustments',
    path: '/economy/priceAdjustments',
    component: <PriceAdjustments />,
    parent: 'Economy',
    roleAccess: (roles) => roles?.parcelAdmin && roles?.invoice,
    pageHeaderActions: [
      {
        text: 'economy.priceAdjustments.new',
        path: '/economy/priceAdjustments/new',
        onlyDesktop: true
      }
    ]
  },
  {
    name: 'New Price Adjustment',
    path: '/economy/priceAdjustments/new',
    component: <NewPriceAdjustment />,
    parent: '/Economy/PriceAdjustments',
    roleAccess: (roles) => roles?.parcelAdmin && roles?.invoice
  },
  {
    name: 'Edit Price Adjustment',
    path: '/economy/priceAdjustments/:priceAdjustmentId/edit',
    component: <EditPriceAdjustment />,
    parent: '/Economy/PriceAdjustments',
    roleAccess: (roles) => roles?.parcelAdmin && roles?.invoice
  },
  {
    name: 'Cost Prices',
    path: '/economy/costPrices',
    component: <CostPrices />,
    parent: 'Economy',
    roleAccess: (roles) => roles?.parcelAdmin || roles?.invoice
  },
  {
    name: 'Edit Cost Prices',
    path: '/economy/costPrices/edit/:shopId/:freightProductId',
    component: <EditCostPrices />,
    parent: 'Economy/CostPrices',
    customLocationHeader: 'Rediger fremtidige priser',
    roleAccess: (roles) => roles?.parcelAdmin || roles?.invoice
  },
  {
    name: 'Invoice Setup',
    path: '/economy/invoiceSetup',
    component: <InvoiceSetup />,
    parent: 'Economy',
    roleAccess: (roles) => roles?.parcelAdmin && roles?.invoice
  },
  {
    name: 'Edit Invoice Setup',
    path: '/economy/invoiceSetup/freightProducts/:freightProductId/edit',
    component: <EditInvoiceSetup />,
    parent: 'Economy/InvoiceSetup',
    roleAccess: (roles) => roles?.parcelAdmin && roles?.invoice
  },
  {
    name: 'User Administration',
    path: '/users',
    component: <UserAdministration />,
    includeInHeader: true,
    roleAccess: (roles) => roles?.userAdmin,
    pageHeaderActionsRoleAccess: (roles) => roles?.userAdmin,
    pageHeaderActions: [
      {
        text: 'userAdministration.create',
        path: '/users/create'
      },
      {
        text: 'userAdministration.connect',
        path: '/users/connect'
      }
    ]
  },
  {
    name: 'New User',
    path: '/users/create',
    component: <CreateOrEditUser />,
    roleAccess: (roles) => roles?.userAdmin
  },
  {
    name: 'Edit User',
    path: '/users/edit/:id',
    component: <CreateOrEditUser />,
    roleAccess: (roles) => roles?.userAdmin
  },
  {
    name: 'Connect User',
    path: '/users/connect',
    component: <ConnectUser />,
    roleAccess: (roles) => roles?.userAdmin
  },
  {
    name: 'Login',
    path: '/login',
    component: <Login />
  },
  {
    name: 'Jobs',
    path: '/jobs',
    component: <Jobs />,
    pageHeaderActionsRoleAccess: (roles) => roles?.di,
    pageHeaderActions: []
  },
  {
    name: 'Job Detail',
    path: '/jobs/:jobId',
    component: <JobDetail />,
    excludeFromDropdown: true,
    parent: 'Jobs',
    pageHeaderActions: [],
    roleAccess: (roles) => roles?.di
  },
  {
    name: 'Settings',
    path: '/settings',
    component: <Settings />,
    parent: 'Home',
    excludeFromDropdown: true
  },
  {
    name: 'NotFound',
    path: '/notFound',
    component: <NotFoundPage />,
    excludeFromDropdown: true
  }
];

export const findNavLinks = (userRoles, activeOwnerParty) => {
  const subPages = routes
    .filter(
      (route) =>
        route.parent &&
        !route.excludeFromDropdown &&
        (route?.roleAccess ? route?.roleAccess(userRoles, activeOwnerParty) : true)
    )
    .map((route) => ({
      to: route.path,
      parent: route.parent
    }));

  return routes
    .filter(
      (route) =>
        route.includeInHeader &&
        (route?.roleAccess ? route?.roleAccess(userRoles, activeOwnerParty) : true)
    )
    .map((route) => ({
      name: route.name,
      to: route.path,
      dropdownSections: subPages.filter(
        (subPage) => subPage.parent.toLowerCase() === route.name.toLowerCase()
      )
    }));
};

export const findParentPage = (currentPathname) => {
  const currentRoute = routes.find((route) => matchPath(route, currentPathname));

  const parent = currentRoute?.parent ?? false;

  const name = currentRoute?.path.slice(1).includes('/')
    ? currentRoute?.path?.substr(1, currentRoute?.path.lastIndexOf('/') - 1)
    : currentRoute?.displayName || currentRoute?.path.slice(1);

  if (name === 'Home' || !parent) return false;

  return parent.toLowerCase();
};

export const findPath = (pathname) => {
  const currentRoute = routes.find((route) => matchPath(route, pathname));
  return currentRoute?.path;
};

export const findRouteName = (pathname) => {
  const currentRoute = routes.find((route) => matchPath(route, pathname));
  return currentRoute.name;
};

export const hasLocationHeader = (pathname) => {
  const currentRoute = routes.find((route) => matchPath(route, pathname));

  const pagesExcludedFromHeader = ['Home', 'Orders', 'Economy'];
  const currentPageExcluded = pagesExcludedFromHeader.some((page) => currentRoute?.name === page);

  return !currentPageExcluded;
};

export const findPageHeaderActions = (currentPathname, roles) => {
  const currentRoute = routes.find((route) => matchPath(route, currentPathname));
  if (
    currentRoute?.pageHeaderActionsRoleAccess &&
    !currentRoute?.pageHeaderActionsRoleAccess(roles)
  )
    return false;
  return currentRoute?.pageHeaderActions ?? false;
};

export const routeAccess = (path, roles, activeOwnerParty) => {
  const currentRoute = routes.find((route) => matchPath(route, path));
  const parentPage = findParentPage(path) && `/${findParentPage(path)}`;

  if (parentPage) {
    const parentRoute = routes.find((route) => matchPath(route, parentPage));
    // user don't have access to parent route
    if (parentRoute?.roleAccess && !parentRoute?.roleAccess(roles, activeOwnerParty)) return false;
  }

  if (currentRoute?.roleAccess) {
    return currentRoute?.roleAccess && !!currentRoute?.roleAccess(roles, activeOwnerParty);
  } else {
    // if route doesn't have the property roleAccess everyone has access
    return true;
  }
};

export const findRouteByName = (name) =>
  routes.find((route) => route?.name.toLowerCase() === name?.toLowerCase());

export default routes;
