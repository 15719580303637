import { combineReducers } from 'redux';

import wsReducer, { WebservicesReducerState } from './store/wsReducer';
import wsUsersReducer, { WebserviceUsersReducerState } from './store/wsUsersReducer';
import wsUserReducer, {
  WebserviceUserReducerState
} from './scenes/EditApiUsers/store/wsUserReducer';
import shopUsersReducer, { ShopUsersReducerState } from './store/shopUsersReducer';
import shopUserReducer, {
  ShopUserReducerState
} from './scenes/EditShopUsers/store/shopUserReducer';
import removeShopUserReducer, {
  RemoveShopUserState
  // eslint-disable-next-line max-len
} from 'js/scenes/Freight/components/Users/components/EditUsers/components/User/components/UserActions/components/RemoveUser/store/removeShopUser';
import _shopReducer, { ShopReducerState as _ShopReducerState } from './store/shopReducer';
import webserviceUserPasswordReducer, {
  WebserviceUserPasswordReducerState
  // eslint-disable-next-line max-len
} from 'js/scenes/Freight/components/Users/components/EditUsers/components/User/components/UserActions/components/NewWebserviceUserPassword/store/webserviceUserPasswordReducer';
import userPasswordReducer, {
  UserPasswordReducerState
  // eslint-disable-next-line max-len
} from 'js/scenes/Freight/components/Users/components/EditUsers/components/User/components/UserActions/components/NewUserPassword/store/userPasswordReducer';
import contractServicesReducer, {
  ContractServicesReducerState
} from './scenes/EditShopConfigurations/store/contractServicesReducer';
import packageSystemLocationsReducer, {
  PackageSystemLocationsReducerState
} from './scenes/EditShopConfigurations/store/packageSystemLocationsReducer';
import contextsReducer, {
  ContextsReducerState
} from './scenes/EditShopConfigurations/store/contextsReducer';
import retailerProductsReducer, {
  RetailerProductsReducerState
} from './scenes/EditShopConfigurations/store/retailerProductsReducer';
import editShopReducer, { EditShopReducerState } from './store/editShopReducer';
import customerSystemsReducer, {
  CustomerSystemsReducerState
} from './store/customerSystemsReducer';
import customerSystemReducer, {
  CustomerSystemReducerState
} from './scenes/EditCustomerSystem/index.reducer';
import shopFreightProductsReducer, {
  ShopFreightProductsReducerState
} from './store/shopFreightProductReducer';
import shopTransportSolutionsReducer, {
  ShopTransportSolutionsReducerState
} from './store/shopTransportSolutionsReducer';
import customerCommunicationReducer, {
  CustomerCommunicationReducerState
} from './store/customerCommunicationReducer';
import shopProofOfDeliveryReducer, {
  ShopProofOfDeliveryReducerState
} from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/proofOfDeliveryReducer';

const shopReducer = combineReducers({
  webservices: wsReducer,
  webserviceUsers: wsUsersReducer,
  webserviceUser: wsUserReducer,
  shopUsers: shopUsersReducer,
  shopUser: shopUserReducer,
  removeShopUser: removeShopUserReducer,
  shop: _shopReducer,
  webserviceUserPassword: webserviceUserPasswordReducer,
  userPassword: userPasswordReducer,
  contractServices: contractServicesReducer,
  packageSystemLocations: packageSystemLocationsReducer,
  contexts: contextsReducer,
  retailerProducts: retailerProductsReducer,
  editShop: editShopReducer,
  customerSystems: customerSystemsReducer,
  customerSystem: customerSystemReducer,
  shopFreightProducts: shopFreightProductsReducer,
  shopTransportSolutions: shopTransportSolutionsReducer,
  customerCommunication: customerCommunicationReducer,
  shopProofOfDelivery: shopProofOfDeliveryReducer
});

export default shopReducer;

export interface ShopReducerState {
  webservices: WebservicesReducerState;
  webserviceUsers: WebserviceUsersReducerState;
  webserviceUser: WebserviceUserReducerState;
  shopUsers: ShopUsersReducerState;
  shopUser: ShopUserReducerState;
  removeShopUser: RemoveShopUserState;
  shop: _ShopReducerState;
  webserviceUserPassword: WebserviceUserPasswordReducerState;
  userPassword: UserPasswordReducerState;
  contractServices: ContractServicesReducerState;
  packageSystemLocations: PackageSystemLocationsReducerState;
  contexts: ContextsReducerState;
  retailerProducts: RetailerProductsReducerState;
  editShop: EditShopReducerState;
  customerSystems: CustomerSystemsReducerState;
  customerSystem: CustomerSystemReducerState;
  shopFreightProducts: ShopFreightProductsReducerState;
  shopTransportSolutions: ShopTransportSolutionsReducerState;
  customerCommunication: CustomerCommunicationReducerState;
  shopProofOfDelivery: ShopProofOfDeliveryReducerState;
}
