import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';
import {
  ApiLabelGenerationHistory,
  LabelGenerationHistory
} from 'src/interfaces/interfaces.generated';

export interface LabelHistoryReducerState {
  error: unknown;
  data: LabelGenerationHistory[];
  isLoaded: boolean;
  numRows: null | number;
}

const initialState: LabelHistoryReducerState = {
  error: null,
  data: [],
  isLoaded: false,
  numRows: null
};

const labelHistorySlice = createSlice({
  name: 'LabelHistory',
  initialState,
  reducers: {
    getLabelHistoryRequest(state) {
      state.error = null;
      state.isLoaded = false;
    },
    getLabelHistorySuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload?.labelGenerationHistory ?? [];
      state.numRows = action.payload?.totalRows;
    },
    getLabelHistoryFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  }
});

export const { getLabelHistoryRequest, getLabelHistorySuccess, getLabelHistoryFailed } =
  labelHistorySlice.actions;

export default labelHistorySlice.reducer;

export const getLabelHistory =
  (page: number) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getLabelHistoryRequest());
      const response: ApiLabelGenerationHistory = await rest.getLabelHistory(page);
      dispatch(getLabelHistorySuccess(response));
      return true;
    } catch (error) {
      dispatch(getLabelHistoryFailed(error));
    }
  };
