import { FC } from 'react';

import ButtonV2 from 'js/components/ButtonV2';

import t from 'js/utils/translate';

import { RootState } from 'js/store/reducer';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

const SearchButton: FC = () => {
  const isSearching = useAppSelector((state: RootState) => state?.home?.orderSearch?.isSearching);

  return (
    <div className='order-search-button'>
      <ButtonV2
        className='order-search-button'
        text={t('general.search')}
        submit
        loading={isSearching}
      />
    </div>
  );
};

export default SearchButton;
