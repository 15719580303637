import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useTimeout } from 'js/utils/hooks';
import { isEmpty } from 'lodash';
import { RootState } from 'js/store/reducer';

import Spinner from 'js/components/Spinner';
import InfoBox from 'js/components/InfoBox';
import PageWrapper from 'js/components/PageWrapper';
import ServiceSupplierForm from '../../components/ServiceSupplierForm';

import { ApiServiceSupplier } from 'src/interfaces/interfaces.generated';
import { ExistingUser, NewUser } from '../../../../interfaces';
import { findServiceSupplierById } from 'js/utils/find';
import { getServiceSuppliers } from 'js/scenes/Freight/scenes/ServiceSuppliers/store/serviceSuppliersReducer';
import {
  connectServiceSupplierUser,
  createServiceSupplierUser
} from '../../store/serviceSupplierUserReducer';
import { editServiceSupplier } from './store/editServiceSupplierReducer';
import { editUser } from '../../../../store/userReducer';
import t from 'js/utils/translate';
import { buildUser } from 'js/scenes/Freight/components/Users/utils';
import { useNavigate, useParams } from 'react-router-dom';

const EditServiceSupplier: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { serviceSupplierId } = useParams();

  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const serviceSuppliers: ApiServiceSupplier[] = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSuppliers?.data
  );
  const serviceSuppliersLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSuppliers?.isLoaded
  );
  const createServiceSupplierError = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.newServiceSupplier?.error
  );
  const editServiceSupplierError = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.editServiceSupplier?.error
  );
  const serviceSupplierUserError = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSupplierUser?.error
  );

  const serviceSupplier: ApiServiceSupplier | undefined = findServiceSupplierById(
    serviceSuppliers,
    serviceSupplierId
  );

  useEffect(() => {
    if (!serviceSuppliersLoaded) dispatch(getServiceSuppliers());
  }, [serviceSuppliersLoaded]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const error = createServiceSupplierError || editServiceSupplierError || serviceSupplierUserError;

  useEffect(() => {
    if (!isEmpty(error)) setIsSubmitting(false);
  }, [error]);

  const redirect = () => {
    navigate('/freight', {
      state: {
        activeTab: {
          name: 'serviceSuppliers',
          label: 'general.serviceSuppliers'
        }
      }
    });
  };

  useTimeout(
    () => {
      setIsSubmitting(false);
      redirect();
      dispatch(getServiceSuppliers());
    },
    isSubmitting ? 4000 : null
  );

  // TODO finn ut av typtingen av denne, extender ApiUser med admingRights og active
  const editActiveUser = (user): void => {
    if (user.active && user.userId) dispatch(editUser(user.userId, buildUser(user)));
  };

  const createServiceSupplierUserAsync = async (user, serviceSupplierId) => {
    await dispatch(createServiceSupplierUser(+serviceSupplierId, buildUser(user)));
  };

  const handleSubmit = ({
    serviceSupplier,
    newUsers,
    connectedUsers
  }: {
    serviceSupplier: ApiServiceSupplier;
    newUsers: NewUser[];
    connectedUsers: ExistingUser[];
  }) => {
    if (serviceSupplierId && serviceSupplier) {
      setIsSubmitting(true);
      dispatch(editServiceSupplier(serviceSupplier, +serviceSupplierId)).then(
        (response?: boolean) => {
          if (response) {
            if (serviceSupplier.users) {
              serviceSupplier.users.forEach((user) => {
                editActiveUser(user);
              });
            }
            if (newUsers) {
              newUsers.forEach((user) => {
                createServiceSupplierUserAsync(user, serviceSupplierId);
              });
            }
            if (connectedUsers) {
              connectedUsers.forEach((user) => {
                dispatch(
                  connectServiceSupplierUser(+serviceSupplierId, {
                    userId: user.userId
                  })
                ).then((response?: boolean) => {
                  if (response) editActiveUser(user);
                });
              });
            }
          }
        }
      );
    }
  };

  if (!serviceSuppliersLoaded && !isSubmitting) return <Spinner />;

  if ((isEmpty(serviceSupplier) || !serviceSupplier) && !isSubmitting) {
    return (
      <InfoBox text={t('serviceSupplier.empty.or.undefined')} /> // TODO fiks text
    );
  }

  return (
    <PageWrapper withoutPadding={!isDesktop}>
      <ServiceSupplierForm
        isSubmitting={isSubmitting}
        initialValues={{ serviceSupplier }}
        handleSubmit={handleSubmit}
        type='save'
        serviceSupplier={serviceSupplier}
        onCancel={() => {
          redirect();
        }}
        error={error}
        editMode
      />
    </PageWrapper>
  );
};

export default EditServiceSupplier;
