import { ReactNode } from 'react';
import { RootState } from 'js/store/reducer';

import DesktopTable from './components/DesktopTable';
import MobileTable from './components/MobileTable';

import { Column, SortBy } from './interfaces';
import { Row } from 'react-table';
import { useAppSelector } from 'js/utils/hooks';

type SubRowCallback = (callback: { row: Row }) => ReactNode;

interface Props<DataType extends object> {
  className?: string;
  columns: Array<Column<DataType>>;
  data: DataType[];
  mobileTableVariant?: 'withSmallEditIcon' | 'withLargeEditButton';
  rowIcon?: string;
  onClickRow?: any;
  goToLabel?: string;
  idAccessor?: string;
  pagination?: boolean;
  sortBy?: SortBy[];
  customMobileTable?: any;
  subRows?: SubRowCallback[];
}

const TableV2 = <DataType extends object = object>({
  className,
  columns,
  data,
  mobileTableVariant,
  rowIcon,
  onClickRow,
  goToLabel,
  idAccessor,
  sortBy,
  pagination,
  customMobileTable,
  subRows
}: Props<DataType>) => {
  const isDesktop: boolean = useAppSelector((state: RootState) => state.mediaDevice.isDesktop);

  if (isDesktop) {
    return (
      <DesktopTable
        className={className}
        columns={columns}
        data={data}
        goToLabel={goToLabel}
        rowIcon={rowIcon}
        onClickRow={
          onClickRow
            ? (values, event) => {
                if (values) {
                  if (idAccessor) {
                    onClickRow(values.original[`${idAccessor}`]);
                  } else {
                    onClickRow(values, event);
                  }
                }
              }
            : undefined
        }
        pagination={pagination}
        sortBy={sortBy}
        subRows={subRows}
      />
    );
  }

  if (mobileTableVariant) {
    return (
      <MobileTable
        className={className}
        columns={columns}
        data={data}
        variant={mobileTableVariant}
        rowIcon={rowIcon}
        onClickRow={
          onClickRow
            ? (values, event) => {
                if (values) {
                  if (idAccessor) {
                    onClickRow(values.original[`${idAccessor}`]);
                  } else {
                    onClickRow(values, event);
                  }
                }
              }
            : undefined
        }
        idAccessor={idAccessor}
        pagination={pagination}
      />
    );
  }

  if (customMobileTable) return customMobileTable;

  return <></>;
};

export default TableV2;
