import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import CollapseV2, { Props as Collapse } from 'js/components/CollapseV2';
import Spinner from 'js/components/Spinner';
import ToggleAllButton from './components/ToggleAllButton';

import { getAccordionStates, setAccordionStates } from './store/accordionReducer';
import { RootState } from 'js/store/reducer';
import { AccordionStates } from './interfaces';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  className?: string;
  name: string;
  accordion: Collapse[];
  paddingSize?: 'small' | 'large';
  withToggleAllOption?: boolean;
  withRememberedStates?: boolean;
}

const AccordionV2: FC<Props> = ({
  className,
  name,
  accordion,
  paddingSize = 'small',
  withToggleAllOption = false,
  withRememberedStates = false
}) => {
  const storageName = withRememberedStates ? `accordion.state.${name}` : undefined;

  const dispatch = useAppDispatch();

  const states: AccordionStates = useAppSelector((state: RootState) => state?.accordion?.data);
  const initialStates: AccordionStates = accordion?.map((item) => item.expanded ?? false);

  const [openStates, setOpenStates] = useState<AccordionStates>(
    withRememberedStates ? states : initialStates
  );

  useEffect(() => {
    dispatch(getAccordionStates(storageName)).then((response: AccordionStates | undefined) => {
      if (!response) {
        dispatch(setAccordionStates(storageName, initialStates));
      }
    });
  }, []);

  useEffect(() => {
    setOpenStates(states);
  }, [states]);

  useEffect(() => {
    if (withRememberedStates && openStates !== states) {
      dispatch(setAccordionStates(storageName, openStates));
    }
  }, [openStates]);

  const toggleOpenState = (collapseIndex: number): void => {
    setOpenStates(openStates.map((openState, i) => (i === collapseIndex ? !openState : openState)));
  };

  if (!openStates || openStates.length === 0) return <Spinner />;

  return (
    <div
      className={classNames('accordion-v2', `accordion-v2--${paddingSize}-side-padding`, className)}
    >
      {withToggleAllOption && <ToggleAllButton storageName={storageName} className={className} />}
      {accordion.map((collapse, index) => (
        <CollapseV2
          key={index}
          variant={collapse.variant}
          expanded={collapse.expanded}
          title={collapse.title}
          info={collapse.info}
          body={collapse.body}
          canEdit={collapse.canEdit}
          onEditClick={collapse.onEditClick}
          inAccordion
          accordionOpen={openStates[index]}
          accordionToggleOpen={toggleOpenState}
          collapseIndex={index}
          className={collapse.className}
        />
      ))}
    </div>
  );
};

export default AccordionV2;
