import { ApiOrder, ApiParty, ApiServicePoint } from 'src/interfaces/interfaces.generated';
import { NewOrderFormValues, NewOrderParty } from '../interfaces';

export const emptyInitialValues: NewOrderFormValues = {
  shopId: 0,
  transportSolutionId: 0,
  consigneeType: 'custom',
  consignee: {
    name: '',
    emailToggle: true
  },
  consignorType: 'custom',
  consignor: {
    name: '',
    emailToggle: true
  },
  parcels: [],
  messageToConsignee: ''
};

export const fromOrderParty = (party: ApiParty): NewOrderParty => ({
  smsToggle: !!party?.phone,
  emailToggle: !!party?.email,
  ...party
});

export const fromOrderInitialValues = ({
  shopId,
  transportSolutionId,
  consignee,
  consignor,
  messageToConsignee,
  parcels
}: ApiOrder): NewOrderFormValues => ({
  shopId,
  transportSolutionId,
  consigneeType: 'custom',
  consignee: {
    smsToggle: false,
    emailToggle: false,
    name: ''
  },
  consignorType: 'custom',
  consignor: {
    smsToggle: false,
    emailToggle: false,
    name: ''
  },
  messageToConsignee: messageToConsignee || '',
  parcels
});

const findParty = ({
  smsToggle, // eslint-disable-line no-unused-vars
  emailToggle, // eslint-disable-line no-unused-vars
  ...rest
}: NewOrderParty): ApiParty => rest;

function createApiPartyFromServicePoint(servicePoint?: ApiServicePoint): ApiParty | undefined {
  if (!servicePoint) return undefined;

  return {
    ...servicePoint,
    id: servicePoint.servicePointId,
    address: {
      ...servicePoint.deliveryAddress,
      zip: servicePoint.deliveryAddress.postalCode,
      streetNumber: +servicePoint.deliveryAddress.streetNumber
    }
  } as ApiParty;
}

export const buildSubmit = (
  values: NewOrderFormValues,
  servicePoint?: ApiServicePoint
): ApiOrder => ({
  ...values,
  consignee: findParty(values.consignee),
  consignor: findParty(values.consignor),
  servicePoint: createApiPartyFromServicePoint(servicePoint)
});

export const findCurrentView = (
  currentStep: number,
  customConsignee: boolean,
  customConsignor: boolean
): string | undefined => {
  switch (currentStep) {
    case 1:
      return 'firstStep';
    case 2:
      if (customConsignee) return 'consigneeStep';
      else if (customConsignor) return 'consignorStep';
      break;
    case 3:
      if (customConsignee && customConsignor) return 'consignorStep';
      return 'finalStep';
    case 4:
      if (customConsignee && customConsignor) return 'finalStep';
  }
};
