import { FC, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { RootState } from 'js/store/reducer';

import Spinner from 'js/components/Spinner';
import InfoBox from 'js/components/InfoBox';
import TableV2 from 'js/components/TableV2';

import t, { tu } from 'js/utils/translate';
import { findFreightProductById } from 'js/utils/find';
import { getFreightProducts } from 'js/store/freightProductsReducer';
import { findRouteByName, routeAccess } from 'js/routes';
import { ApiTransportSolution } from 'src/interfaces/interfaces.generated';
import Tag from 'js/components/Tag';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

interface Props {
  filteredTransportSolutions: ApiTransportSolution[];
}

const TransportSolutionsTable: FC<Props> = ({ filteredTransportSolutions }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRoles = useAppSelector((state: RootState) => state?.user?.data?.roles);

  const route = findRouteByName('Edit Transport Solution');
  const hasAccess = routeAccess(route?.path, userRoles);

  const freightProducts = useAppSelector((state: RootState) => state.freightProducts?.data);
  const freightProductsLoaded = useAppSelector(
    (state: RootState) => state.freightProducts?.isLoaded
  );

  useEffect(() => {
    if (!freightProductsLoaded) dispatch(getFreightProducts());
  }, [freightProductsLoaded]);

  const columns = useMemo(
    () => [
      {
        Header: tu('general.id'),
        accessor: 'transportSolutionId',
        inHeader: true
      },
      {
        Header: tu('general.name'),
        accessor: 'name',
        inHeader: true
      },
      {
        Header: tu('general.freightProducts'),
        accessor: (transportSolution) => (
          <div className='tag-list'>
            {transportSolution.freightProductIds.map((fpid) => (
              <Tag key={fpid} text={findFreightProductById(freightProducts, fpid)?.name} />
            ))}
          </div>
        )
      },
      {
        Header: tu('freight.transportSolutions.description'),
        accessor: 'description',
        inHeader: true
      },
      {
        Header: tu('freight.transportSolutions.ediCode'),
        accessor: 'ediCode',
        inHeader: true
      },
      {
        Header: tu('freight.transportSolutions.ownerParty'),
        accessor: 'ownerPartyName'
      }
    ],
    []
  );

  const onClickRow = (transportSolutionId: number) => {
    if (hasAccess) navigate(`${transportSolutionId}/edit`);
  };

  const rowIcon = hasAccess ? 'pencil-alt' : '';

  if (isEmpty(filteredTransportSolutions))
    return <InfoBox text={t('freight.transportSolutions.search.none')} />;

  if (!freightProductsLoaded) return <Spinner />;

  return (
    <TableV2
      columns={columns}
      data={filteredTransportSolutions}
      rowIcon={rowIcon}
      onClickRow={onClickRow}
      goToLabel={t('freight.transportSolutions.edit')}
      idAccessor='transportSolutionId'
      mobileTableVariant='withSmallEditIcon'
      sortBy={[{ id: 'transportSolutionId', desc: false }]}
      pagination
    />
  );
};

export default TransportSolutionsTable;
