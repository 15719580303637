import { FC, useEffect, useState } from 'react';

import SearchFilter from 'js/components/SearchFilter';
import Button from 'js/components/Button';
import Spinner from 'js/components/Spinner';
import TransportSolutionsTable from './components/TransportSolutionsTable';
import InfoBox from 'js/components/InfoBox';
import PageWrapper from 'js/components/PageWrapper';
import NotFound from 'js/components/NotFound';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import { filterTransportSolutions } from './utils';
import t from 'js/utils/translate';

import {
  clearTransportSolutionErrors,
  getTransportSolutions,
  useTransportSolutions
} from 'js/store/transportSolutionsReducer';
import { RootState } from 'js/store/reducer';

import './index.sass';
import { useNavigate } from 'react-router-dom';

const TransportSolutions: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const freightProductsLoaded = useAppSelector(
    (state: RootState) => state?.freightProducts.isLoaded
  );

  const transportSolutionsState = useTransportSolutions();
  const transportSolutionsLoaded = transportSolutionsState.isLoaded;
  const transportSolutions = transportSolutionsState.data;
  const transportSolutionsError = transportSolutionsState.error;

  const [search, setSearch] = useState('');
  const [filteredTransportSolutions, setFilteredTransportSolutions] = useState(transportSolutions);

  useEffect(() => {
    if (!transportSolutionsLoaded) dispatch(getTransportSolutions());
  }, [transportSolutionsLoaded]);

  useEffect(() => {
    setFilteredTransportSolutions(filterTransportSolutions(transportSolutions, search));
  }, [search, transportSolutions]);

  useEffect(() => {
    dispatch(clearTransportSolutionErrors());
  }, [location]);

  if (!transportSolutionsLoaded || !freightProductsLoaded) return <Spinner />;
  if (transportSolutionsError) return <NotFound />;

  return (
    <div className='transport-solutions'>
      {!isDesktop && (
        <div className='page-header-actions-mobile page-padding'>
          <Button
            handleClick={() => {
              navigate('/freight/transportSolution/new');
            }}
            name={t('new.transportsolution')}
          />
        </div>
      )}
      <SearchFilter
        info={t('freight.transportSolutions.search.info')}
        inputValue={search}
        setSearch={setSearch}
      />
      <PageWrapper withoutPadding={!isDesktop}>
        {transportSolutions.length === 0 ? (
          <InfoBox text={t('freight.transportSolutions.none')} />
        ) : (
          <TransportSolutionsTable filteredTransportSolutions={filteredTransportSolutions} />
        )}
      </PageWrapper>
    </div>
  );
};

export default TransportSolutions;
