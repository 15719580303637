import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../api';
import { ApiConcern } from 'src/interfaces/interfaces.generated';

export interface EditConcernReducerState {
  error: unknown;
  isSaving: boolean;
  isSaved: boolean;
}

const initialState: EditConcernReducerState = {
  error: null,
  isSaving: false,
  isSaved: false
};

const editConcernSlice = createSlice({
  name: 'EditConcern',
  initialState,
  reducers: {
    editConcernRequest(state) {
      state.error = null;
      state.isSaving = true;
      state.isSaved = false;
    },
    editConcernSuccess(state) {
      state.isSaving = false;
      state.isSaved = true;
    },
    editConcernFailed(state, action) {
      state.error = action.payload;
      state.isSaving = false;
      state.isSaved = false;
    }
  }
});

export const { editConcernRequest, editConcernSuccess, editConcernFailed } =
  editConcernSlice.actions;

export default editConcernSlice.reducer;

export const editConcern =
  (concern: ApiConcern) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(editConcernRequest());
      await api.editConcern(concern);
      dispatch(editConcernSuccess());
      return true;
    } catch (error) {
      dispatch(editConcernFailed(error));
    }
  };
