import t from 'js/utils/translate';

import './index.sass';

const DesktopFreightProductHeader = () => (
  <div className='shop-freight-product__headers'>
    <div>{t('shop.freightProduct.freightProduct')}</div>
    <div>{t('shop.freightProduct.labelRecipient')}</div>
    <div>{t('shop.freightProduct.maxValue')}</div>
    <div>{t('shop.freightProduct.weight')}</div>
    <div>{t('shop.freightProduct.active')}</div>
  </div>
);

export default DesktopFreightProductHeader;
