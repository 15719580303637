import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import CustomerSystemForm from '../CustomerSystemForm';

import { buildJsonPatchRequest } from 'js/utils/ajax/utils';
import { editCustomerSystem } from './store/editCustomerSystemReducer';
import { getCustomerSystems } from '../../../../store/customerSystemsReducer';
import { ApiFlowShop } from 'src/interfaces/interfaces.generated';
import { editShop } from '../../../../store/editShopReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

interface Props {
  shop: ApiFlowShop;
  redirectTo: string;
  initialValues: unknown;
}

const ExistingCustomerSystem: FC<Props> = ({ shop, redirectTo, initialValues }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const error = useAppSelector(
    (state: RootState) => state?.freight?.shop?.customerSystem?.editCustomerSystem?.error
  );
  const isSavingCustomerSystem = useAppSelector(
    (state: RootState) => state?.freight?.shop?.customerSystem?.editCustomerSystem?.isSaving
  );
  const isSavingShop = useAppSelector(
    (state: RootState) => state?.freight?.shop?.editShop?.isSaving
  );

  const handleSubmit = ({ editedCustomerSystem }) => {
    dispatch(editCustomerSystem(editedCustomerSystem)).then((response: boolean | undefined) => {
      if (response) {
        if (shop?.shopId) {
          const patch = buildJsonPatchRequest(
            { customerSysId: shop?.customerSysId },
            { customerSysId: editedCustomerSystem.customerSystemId }
          );
          dispatch(editShop(patch, shop.shopId)).then((response: boolean | undefined) => {
            if (response) {
              navigate(redirectTo);
              dispatch(getCustomerSystems());
            }
          });
        }
      }
    });
  };

  return (
    <CustomerSystemForm
      namePrefix='edited'
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      error={error}
      type='save'
      isLoading={isSavingCustomerSystem || isSavingShop}
      redirectTo={redirectTo}
    />
  );
};

export default ExistingCustomerSystem;
