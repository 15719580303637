import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface UserPasswordReducerState {
  error: unknown;
  id: null | number;
  isResetting: boolean;
  isReset: boolean;
}

const initialState = {
  error: null,
  id: null,
  isResetting: false,
  isReset: false
};

const userPasswordSlice = createSlice({
  name: 'userPassword',
  initialState,
  reducers: {
    resetPasswordRequest(state, action) {
      state.error = null;
      state.id = action.payload;
      state.isResetting = true;
      state.isReset = false;
    },
    resetPasswordSuccess(state) {
      state.isResetting = false;
      state.isReset = true;
    },
    resetPasswordFailed(state, action) {
      state.error = action.payload;
      state.isResetting = false;
      state.isReset = false;
    },
    resetState(state) {
      state.error = null;
      state.id = null;
      state.isResetting = false;
      state.isReset = false;
    }
  }
});

export const { resetPasswordRequest, resetPasswordSuccess, resetPasswordFailed, resetState } =
  userPasswordSlice.actions;

export default userPasswordSlice.reducer;

export const resetPassword = (userId: number) => async (dispatch) => {
  try {
    dispatch(resetPasswordRequest(userId));
    const response = await rest.resetPassword(userId);
    dispatch(resetPasswordSuccess());
    return response;
  } catch (error) {
    dispatch(resetPasswordFailed(error));
  }
};

export const clearUserPasswordState = () => (dispatch) => {
  dispatch(resetState());
};
