import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import InfoBox from 'js/components/InfoBox';
import Row from './components/Row';
import DesktopTableHeader from './components/DesktopTableHeader';

import { Column } from './interfaces';
import { ApiInvoiceBatch } from 'src/interfaces/interfaces.generated';
import t, { tLowerFirst } from 'js/utils/translate';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';

interface Props {
  invoiceBatches?: ApiInvoiceBatch[];
  context: 'handled' | 'unhandled' | 'shop';
  columns: Column[];
}

const InvoiceBatchesTable: FC<Props> = ({ invoiceBatches, context, columns }) => {
  const isDesktop: boolean = useAppSelector((state: RootState) => state.mediaDevice.isDesktop);

  return (
    <div className='invoice-batches-table'>
      {isDesktop && <DesktopTableHeader columns={columns} />}
      {!invoiceBatches || invoiceBatches.length === 0 ? (
        <InfoBox
          text={t(
            'economy.invoice.generateInvoice.table.none',
            context === 'handled'
              ? tLowerFirst('general.handled')
              : tLowerFirst('general.unhandled')
          )}
        />
      ) : (
        invoiceBatches.map((invoiceBatch) => (
          <Row
            key={invoiceBatch.invoiceBatchId}
            columns={columns}
            invoiceBatch={invoiceBatch}
            context={context}
          />
        ))
      )}
    </div>
  );
};

export default InvoiceBatchesTable;
