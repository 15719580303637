import ColoredSectionWithHeader from '../../../ColoredSectionWithHeader';

import t, { tu } from 'js/utils/translate';
import { showCheckmarkWithNameIfActive } from '../../../../utils';

const B2B = ({ translateKeyPrefix, shop }) => (
  <ColoredSectionWithHeader header={tu(`${translateKeyPrefix}.b2b`)}>
    {shop.retailerProductId ? (
      <div>
        <div>
          {showCheckmarkWithNameIfActive(
            shop.preTransportRequired,
            'configurations.b2b.pretransport.required'
          )}
        </div>
        <div>
          {showCheckmarkWithNameIfActive(
            shop.transportCutOff,
            'configurations.b2b.transportCutOff'
          )}
        </div>
      </div>
    ) : (
      <div>{t('general.not.active')}</div>
    )}
  </ColoredSectionWithHeader>
);

export default B2B;
