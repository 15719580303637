import { format } from 'date-fns';

export const buildSubmit = ({
  deliveryDateToggle,
  desiredDeliveryDates,
  importDateToggle,
  importedDates,
  emailForLabels,
  freightProductIds,
  includeExportedLabels,
  includeOnlyAsaLabelOrders,
  orderByList,
  shopIds,
  serviceSupplierIds,
  mainCarNumbers,
  mainCarPlannedDepartures
}) => {
  const dateFormat = 'yyyy-MM-dd';

  const orderBy = orderByList?.map((orderByItem) => orderByItem.value);

  const fromDesiredDeliveryDateString = deliveryDateToggle
    ? format(desiredDeliveryDates.fromDate, dateFormat)
    : false;
  const toDesiredDeliveryDateString = deliveryDateToggle
    ? format(desiredDeliveryDates.toDate, dateFormat)
    : false;

  const fromImportedDateString = importDateToggle
    ? format(importedDates.fromDate, dateFormat)
    : false;
  const toImportedDateString = importDateToggle ? format(importedDates.toDate, dateFormat) : false;

  const submitData = {
    fromDesiredDeliveryDateString,
    toDesiredDeliveryDateString,
    fromImportedDateString,
    toImportedDateString,
    emailForLabels,
    freightProductIds,
    includeExportedLabels,
    includeOnlyAsaLabelOrders,
    orderBy,
    shopIds,
    serviceSupplierIds,
    mainCarNumbers,
    mainCarPlannedDepartures
  };

  const removeFalsyDateStrings = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((prop) => {
      if (
        obj[prop] !== false &&
        (obj[prop] !== 'includeExportedLabels' || obj[prop] !== 'includeOnlyAsaLabelOrders')
      ) {
        newObj[prop] = obj[prop];
      }
      if (prop === 'includeExportedLabels' || prop === 'includeOnlyAsaLabelOrders') {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  };
  return removeFalsyDateStrings(submitData);
};
