import { FC } from 'react';

import './index.sass';
import classNames from 'classnames';

interface Props {
  className?: string;
  text: string;
}

const Tooltip: FC<Props> = ({ className, text }) => (
  <span className={classNames('tooltip', className)} data-text={text}>
    <a className='tooltip-icon'>
      <i className='far fa-question-circle' />
    </a>
  </span>
);

export default Tooltip;
