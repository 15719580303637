import { useEffect } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import MessageForUser from 'js/components/MessageForUser';

import { singleAddressCheck } from '../../../../store/singleAddressCheckReducer';
import t from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const AddressCheckResult = ({ addressComplete, address }) => {
  const dispatch = useAppDispatch();

  const addressCheckFreightProduct = useAppSelector(
    (state) => state?.orders?.singleAddressCheck?.data
  );
  const addressChecked = useAppSelector((state) => state?.orders?.singleAddressCheck?.isChecked);
  const checkingAddress = useAppSelector((state) => state?.orders?.singleAddressCheck?.isChecking);
  const addressCheckError = useAppSelector(
    (state) => state?.orders?.singleAddressCheck?.error?.response
  );

  const hasSupport = !!addressCheckFreightProduct?.freightProductId;

  const context = useFormContext();

  useEffect(() => {
    if (addressComplete && address && !checkingAddress) {
      dispatch(singleAddressCheck(address));
    }
  }, [addressComplete, address]);

  useEffect(() => {
    hasSupport
      ? context.setError('addressCheck', undefined)
      : context.setError('addressCheck', 'noSupport');
  }, [hasSupport]);

  if (checkingAddress) {
    return (
      <MessageForUser
        variant='info'
        message={t('address.check.checking.coverage')}
        className='address-check'
      />
    );
  }
  if (addressCheckError?.key) {
    return (
      <MessageForUser
        variant='error'
        message={<div>{t(addressCheckError.key)}</div>}
        className='address-check'
      />
    );
  }
  if (!addressChecked) return <></>;
  return (
    addressChecked && (
      <MessageForUser
        className='address-check'
        variant={hasSupport ? 'info' : 'warning'}
        message={
          hasSupport ? (
            <div>{t('address.check.has.coverage', addressCheckFreightProduct?.name)}</div>
          ) : (
            <div>{t('address.check.no.coverage')}</div>
          )
        }
      />
    )
  );
};

export default AddressCheckResult;
