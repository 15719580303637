import { combineReducers } from 'redux';

import orderDetailsReducer, { OrderDetailsReducerState } from './scenes/OrderDetails/index.reducer';
import reportsReducer, { ReportsReducerState } from './scenes/Reports/store';
import orderSearchReducer, {
  OrderSearchReducerState
} from './scenes/OrderSearch/store/orderSearchReducer';
import dashboardPackageDataReducer, {
  DashboardPackageDataReducerState
} from './components/MainPanel/components/PackageDashboard/store/dashboardPackageDataReducer';

const homeReducer = combineReducers({
  orderDetails: orderDetailsReducer,
  reports: reportsReducer,
  orderSearch: orderSearchReducer,
  dashboardPackageData: dashboardPackageDataReducer
});

export default homeReducer;

export interface HomeReducerState {
  orderDetails: OrderDetailsReducerState;
  reports: ReportsReducerState;
  orderSearch: OrderSearchReducerState;
  dashboardPackageData: DashboardPackageDataReducerState;
}
