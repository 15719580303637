import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';
import { ApiInvoiceSetup } from 'src/interfaces/interfaces.generated';

export interface FreightProductInvoiceSetupReducerState {
  error: unknown;
  data: ApiInvoiceSetup[];
  isLoaded: boolean;
  isSaving: boolean;
}

const initialState: FreightProductInvoiceSetupReducerState = {
  error: null,
  data: [],
  isLoaded: false,
  isSaving: false
};

const freightProductInvoiceSetupSlice = createSlice({
  name: 'FreightProductInvoiceSetup',
  initialState,
  reducers: {
    getFreightProductInvoiceSetupsRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getFreightProductInvoiceSetupsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getFreightProductInvoiceSetupsFailed(state, action) {
      state.isLoaded = false;
      state.error = action;
    },
    editInvoiceSetupRequest(state) {
      state.isSaving = true;
    },
    editInvoiceSetupSuccess(state, action) {
      state.isSaving = false;
      state.data.map((invoiceSetup) =>
        invoiceSetup.freightProductId === action.payload.freightProductId
          ? action.payload
          : invoiceSetup
      );
    },
    editInvoiceSetupFailed(state, action) {
      state.isSaving = false;
      state.error = action;
    }
  }
});

export const {
  getFreightProductInvoiceSetupsRequest,
  getFreightProductInvoiceSetupsSuccess,
  getFreightProductInvoiceSetupsFailed,
  editInvoiceSetupRequest,
  editInvoiceSetupSuccess,
  editInvoiceSetupFailed
} = freightProductInvoiceSetupSlice.actions;

export default freightProductInvoiceSetupSlice.reducer;

export const getFreightProductInvoiceSetups = () => async (dispatch) => {
  try {
    dispatch(getFreightProductInvoiceSetupsRequest());
    const response = await rest.getInvoiceSetups();
    dispatch(getFreightProductInvoiceSetupsSuccess(response));
  } catch (err) {
    dispatch(getFreightProductInvoiceSetupsFailed(err));
  }
};

export const editInvoiceSetup = (freightProductId, invoiceSetup) => async (dispatch) => {
  try {
    dispatch(editInvoiceSetupRequest());
    await rest.editInvoiceSetup(freightProductId, invoiceSetup);
    dispatch(editInvoiceSetupSuccess(invoiceSetup));
  } catch (err) {
    dispatch(editInvoiceSetupFailed(err));
  }
};
