import { activeOwnerPartyLogoAttributes } from 'js/utils/activeOwnerPartyLogoAttributes';

export const setFavicon = (ownerPartyId) => {
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';

  link.href = activeOwnerPartyLogoAttributes('faviconLogo', ownerPartyId);

  if (oldLink) {
    document.head.removeChild(oldLink);
  }

  document.head.appendChild(link);
};
