import { findGroupById } from './utils';

import t from 'js/utils/translate';

const Group = ({ group, groups, id }) => (
  <div className='freight-product-invoice-setup-group'>
    {t(`economy.invoiceSetup.${group}`, findGroupById(groups, id)?.name ?? t('general.none'))}
  </div>
);

export default Group;
