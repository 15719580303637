import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';
import { ApiIdName } from 'interfaces/interfaces.generated';

export interface InvoiceSystemsReducerState {
  error: unknown;
  data: ApiIdName;
  isLoaded: boolean;
}

const initialState: InvoiceSystemsReducerState = {
  error: null,
  data: {},
  isLoaded: false
};

const invoiceSystemsSlice = createSlice({
  name: 'InvoiceSystems',
  initialState,
  reducers: {
    getInvoiceSystemsRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getInvoiceSystemsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getInvoiceSystemsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  }
});

export const { getInvoiceSystemsRequest, getInvoiceSystemsSuccess, getInvoiceSystemsFailed } =
  invoiceSystemsSlice.actions;

export default invoiceSystemsSlice.reducer;

export const getInvoiceSystems = () => async (dispatch) => {
  try {
    dispatch(getInvoiceSystemsRequest());
    const invoiceSystems = await rest.getInvoiceSystems();
    dispatch(getInvoiceSystemsSuccess(invoiceSystems));
  } catch (err) {
    dispatch(getInvoiceSystemsFailed(err));
  }
};
