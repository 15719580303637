import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import Spinner from 'js/components/Spinner';
import { orderAccordion } from './components/OrderAccordion';
import AccordionV2 from 'js/components/AccordionV2';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

const OrderInfo = ({ order, canEditOrder }) => {
  const location = useLocation();

  const navigate = useNavigate();

  const fromNewOrderPage = location?.state?.fromNewOrder ?? false;
  const orderLoaded = useAppSelector((state) => state?.home?.orderDetails?.order?.isLoaded);
  const shipmentInfoRef = useRef(null);

  useEffect(() => {
    if (fromNewOrderPage && shipmentInfoRef.current)
      window.scrollTo(0, shipmentInfoRef?.current?.offsetTop - 150);
  }, []);

  return (
    <div className='order-info'>
      {!orderLoaded ? (
        <Spinner />
      ) : (
        <AccordionV2
          className='order-accordion'
          name='orderInfoAccordion'
          accordion={orderAccordion(
            order,
            canEditOrder,
            fromNewOrderPage,
            shipmentInfoRef,
            navigate
          )}
          withToggleAllOption
        />
      )}
    </div>
  );
};

export default OrderInfo;
