import { createSlice, Dispatch } from '@reduxjs/toolkit';
import * as api from '../../api';
import { locationChanged } from 'js/store/actions';

export interface LinkedParcelNumbersReducerState {
  error: unknown;
  data: string[];
}

const initialState: LinkedParcelNumbersReducerState = {
  error: null,
  data: []
};

const linkedParcelNumbersSlice = createSlice({
  name: 'LinkedParcelNumbers',
  initialState,
  reducers: {
    getLinkedParcelNumbersRequest(state) {
      state.error = null;
    },
    getLinkedParcelNumbersSuccess(state, action) {
      state.data = action.payload;
    },
    getLinkedParcelNumbersFailed(state, action) {
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  getLinkedParcelNumbersRequest,
  getLinkedParcelNumbersSuccess,
  getLinkedParcelNumbersFailed
} = linkedParcelNumbersSlice.actions;

export default linkedParcelNumbersSlice.reducer;

export const getLinkedParcelNumbers =
  (trackingReference: string) =>
  async (dispatch: Dispatch): Promise<string[] | undefined> => {
    try {
      dispatch(getLinkedParcelNumbersRequest());
      const response: string[] = await api.getLinkedParcelNumbers(trackingReference);
      dispatch(getLinkedParcelNumbersSuccess(response));
      return response;
    } catch (error) {
      dispatch(getLinkedParcelNumbersFailed(error));
    }
  };
