import { FC, Fragment } from 'react';
import { ApiFreightPrice } from 'src/interfaces/interfaces.generated';
import { findPrice, samePrice } from '../../../../utils';

interface FreightProductPriceRowProps {
  weightClass: string;
  index: number;
  priceRow?: ApiFreightPrice[];
  currentPrices: boolean;
  selectedFilter: string | undefined;
  showGrayMatrix: boolean;
}

const FreightProductPriceRow: FC<FreightProductPriceRowProps> = ({
  weightClass,
  priceRow,
  currentPrices,
  selectedFilter,
  showGrayMatrix
}) => (
  <tr className={showGrayMatrix ? 'gray' : ''}>
    <td>{weightClass}</td>
    {priceRow?.map((price, index) => (
      <Fragment key={`${price?.zoneId} ${price.weightId}`}>
        <td>
          <span>
            {samePrice(currentPrices, priceRow, selectedFilter)
              ? '-'
              : findPrice(currentPrices, price?.costPrices, selectedFilter)}
          </span>
        </td>
        {priceRow?.length === index + 1 && (
          <td>
            <span>
              {samePrice(currentPrices, priceRow, selectedFilter)
                ? findPrice(currentPrices, price?.costPrices, selectedFilter)
                : '-'}
            </span>
          </td>
        )}
      </Fragment>
    ))}
  </tr>
);

export default FreightProductPriceRow;
