import { Children, cloneElement } from 'react';
import classNames from 'classnames';
import { InputGroup as _InputGroup, withError } from '@distribution-innovation/form-hooks';

import Label from 'js/components/Label';
import InputError from 'js/components/Form/components/Validation/InputError';

const childName = (child, name, i) => {
  if (child.props.name != null) return child.props.name;
  if (child.type.name === 'Radio') return name;
  return `${name}[${i}]`;
};

const InputGroup = ({
  className,
  name,
  label,
  info,
  optional,
  errorMessage,
  error,
  children,
  ...props
}) => (
  <div className={classNames(className)}>
    {label && <Label label={label} info={info} optional={optional} />}
    <_InputGroup name={name} {...props}>
      {Children.map(
        children,
        (child, i) =>
          child &&
          cloneElement(child, {
            className: classNames(child.props.className),
            name: childName(child, name, i)
          })
      )}
    </_InputGroup>
    <InputError message={errorMessage} error={error} />
  </div>
);

export default withError()(InputGroup);
