import { FC } from 'react';
import { User, UserType } from 'js/scenes/UserAdministration/Users.types';
import t from 'js/utils/translate';
import AsyncSelect from 'react-select/async';
import Label from 'js/components/Label';
import useUserSearchQuery from 'js/scenes/UserAdministration/api/useUserSearchQuery';
import {
  useFormContext,
  useValue,
  useRegister,
  useError
} from '@distribution-innovation/form-hooks';
import classNames from 'classnames';

import './UserSearch.sass';
import InputError from 'js/components/Form/components/Validation/InputError';

const Component: FC<{ userType: UserType; searchInputRef: any }> = ({
  userType,
  searchInputRef
}) => {
  const context = useFormContext();
  const searchFieldName = 'search';

  const [value, setValue] = useValue<User[] | null>(searchFieldName);
  const error = useError(searchFieldName);

  const searchUser = useUserSearchQuery(userType, value);

  const validate = (users: User[] | null) =>
    users && users?.length > 0 ? undefined : 'valueMissing';

  const handleBlur = () =>
    context.getTouched(searchFieldName) || context.setTouched(searchFieldName);

  const handleChange = (selected: any) => {
    setValue(selected.map((selectedOption) => selectedOption.value));
  };

  useRegister({
    name: searchFieldName,
    validate
  });

  return (
    <div>
      <Label label={t('userAdministration.connect.search')} />
      <AsyncSelect
        ref={searchInputRef}
        className={classNames('async-select', {
          'async-select--invalid': error
        })}
        isMulti
        isClearable
        placeholder={t('userAdministration.connect.placeholder')}
        name={searchFieldName}
        loadOptions={searchUser}
        onChange={handleChange}
        onBlur={handleBlur}
        noOptionsMessage={() => t('userAdministration.connect.noOptions')}
        loadingMessage={() => t('userAdministration.connect.loadingOptions')}
        required
      />
      {error && <InputError error={error} />}
    </div>
  );
};

export default Component;
