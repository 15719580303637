import { FC, useState } from 'react';

import EditUser from './components/EditUser';
import UserActions from './components/UserActions';

import { ApiUser } from 'src/interfaces/interfaces.generated';

interface Props {
  user: ApiUser;
  index: number;
  isApiContext?: boolean;
  domain?: 'serviceSupplier';
}

const User: FC<Props> = ({ user, index, isApiContext, domain }) => {
  const [editUserActive, setEditUserActive] = useState<boolean | null>(null);

  const name = domain ? `${domain}.users` : 'users';

  return (
    <div className='user'>
      <EditUser
        name={name}
        editUserActive={editUserActive}
        setEditUserActive={setEditUserActive}
        user={user}
        index={index}
        isApiContext={isApiContext}
      />
      <UserActions
        user={user}
        domain={domain}
        editUserActive={editUserActive}
        setEditUserActive={setEditUserActive}
        isApiContext={isApiContext}
      />
    </div>
  );
};

export default User;
