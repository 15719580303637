import { FC } from 'react';
import { toFloat } from '@distribution-innovation/form-hooks';

import Input from 'js/components/Form/components/Input';

import { PriceType } from '../../../../../../interfaces';

import { tu } from 'js/utils/translate';

interface Props {
  priceType: PriceType;
  priceTypeIndex: number;
  weightClassIndex: number;
  disabled: boolean;
}

const PriceForAllZones: FC<Props> = ({ priceType, priceTypeIndex, weightClassIndex, disabled }) => (
  <Input
    className='freight-price'
    name={`weightClassPricesForAllZones[${priceTypeIndex}][${weightClassIndex}]`}
    label={tu(
      'economy.edit.costprice.all.zones',
      priceType === 'ALL' ? '' : tu(`general.${priceType}`)
    )}
    type='number'
    step='0.01'
    convert={toFloat}
    disabled={disabled}
    optional
    hideOptional
  />
);

export default PriceForAllZones;
