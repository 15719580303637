import { FC } from 'react';

import { logout } from 'js/store/authenticationReducer';
import t from 'js/utils/translate';

import './index.sass';
import { useAppDispatch } from 'js/utils/hooks';

const LogoutButton: FC = () => {
  const dispatch = useAppDispatch();

  const handleLogOut = () => {
    dispatch(logout());
  };

  return (
    <div className='logout-button'>
      <div onClick={handleLogOut}>{t('general.logout')}</div>
    </div>
  );
};

export default LogoutButton;
