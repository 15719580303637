import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import { tu } from 'js/utils/translate';
import { findParentPage } from 'js/routes';
import { getShops } from 'js/scenes/Freight/store/shopsReducer';
import { findShopById } from 'js/utils/find';
import { findShopId } from './utils';

import './index.sass';

const Breadcrumb = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const route = findParentPage(location.pathname);
  const currentOrder = location?.state?.currentOrder;
  const [trackingReference, setTrackingReference] = useState('');

  useEffect(() => {
    currentOrder
      ? setTrackingReference(location?.state?.currentOrder?.trackingReference)
      : setTrackingReference('' + location.pathname.split('/').find((item, i) => i === 2));
  }, [location]);

  const shops = useAppSelector((state) => state?.freight?.shops?.data);
  const shopsLoaded = useAppSelector((state) => state?.freight?.shops?.isLoaded);

  useEffect(() => {
    if (!shopsLoaded) dispatch(getShops());
  }, []);

  if (!route) return <></>;

  const pathArray = route.split('/');

  const findLink = (route, i, name, pathArray) =>
    i === 0 ? (
      <Link to={name === 'home' ? '/' : `/${name}`}>{tu(`pageHeader.breadCrumb.${name}`)}</Link>
    ) : (
      <Link to={pathArray.includes('home') ? `/${pathArray[1]}` : `/${route}`}>
        {tu(`pageHeader.breadCrumb.${name}`)}
      </Link>
    );
  if (route.includes(':trackingreference')) {
    return (
      <div className='breadcrumb'>
        {pathArray.map((name, i) => (
          <div className='breadcrumb__link' key={name}>
            {name === ':trackingreference' ? (
              <Link to={`/orderSearch/${trackingReference}`}>{trackingReference}</Link>
            ) : (
              findLink(route, i, name, pathArray)
            )}
            <span>{i < pathArray.length - 1 && '/'}</span>
          </div>
        ))}
      </div>
    );
  } else if (route.includes(':shopid')) {
    return (
      <div className='breadcrumb'>
        {pathArray.map((name, i) => {
          if (name === 'shop') return <Fragment key={name} />;
          return (
            <div className='breadcrumb__link' key={name}>
              {name === ':shopid' ? (
                <Link to={`/freight/shop/${findShopId(pathArray)}`}>
                  {findShopById(shops, findShopId(pathArray))?.name?.toUpperCase()}
                </Link>
              ) : (
                <Link to={`/${name}`}>{tu(`pageHeader.breadCrumb.${name}`)}</Link>
              )}
              {i < pathArray.length - 1 && name !== 'shop' && <span>/</span>}
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <div className='breadcrumb'>
        {pathArray.map((name, i) => {
          if (name === '') return <></>;
          return (
            <div className='breadcrumb__link' key={name}>
              {findLink(route, i, name, pathArray)}
              <span>{i < pathArray.length - 1 && '/'}</span>
            </div>
          );
        })}
      </div>
    );
  }
};

export default Breadcrumb;
