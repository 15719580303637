import { useEffect } from 'react';
import { RootState } from 'js/store/reducer';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';

import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';

import FormV2 from 'js/components/FormV2';
import ToggleGroup from 'js/components/Form/components/ToggleGroup';
import MessageForUser from 'js/components/MessageForUser';
import InfoBox from 'js/components/InfoBox';
import Spinner from 'js/components/Spinner';

import { ApiPackageInfo } from 'src/interfaces/interfaces.generated';

import t, { tu } from 'js/utils/translate';

import { extendReturnDateToggleLabel, extendReturnDateToggleSubLabel } from './utils';

import { getPackageInfo } from '../../store/packageInfoReducer';
import { extendPackagePickupDeadline } from './store/extendPickupDeadlineReducer';
import { getOrder } from '../../store/orderReducer';

import './index.sass';
// eslint-disable-next-line max-len
import { resendPickupCode } from 'js/scenes/Home/scenes/OrderDetails/scenes/EditServicePoint/store/resendPickupCodeReducer';
import { ApiCommunicationPreference } from 'js/scenes/Home/scenes/OrderDetails/scenes/EditServicePoint/interfaces';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate, useParams } from 'react-router-dom';

export const sendPickupCodeByToggles = (consignee) => [
  {
    name: 'sendByEmail',
    label: t('general.email'),
    sublabel: consignee?.email || t('order.edit.servicepoint.missing.email'),
    disabled: !consignee?.email && true
  },
  {
    name: 'sendBySMS',
    label: 'SMS',
    sublabel: consignee?.phone1 || consignee?.phone2 || t('order.edit.servicepoint.missing.number'),
    disabled: !(consignee?.phone1 || consignee?.phone2) && true
  }
];

export const extendReturnDateToggle = (label, sublabel, disabled) => [
  {
    name: 'extendReturnDate',
    label,
    sublabel,
    disabled
  }
];

const EditServicePoint = () => {
  const { trackingReference } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const packageInfoLoaded: boolean = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.packageInfo?.isLoaded
  );
  const packageInfoLoading: boolean = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.packageInfo?.isLoading
  );
  const packageInfo: ApiPackageInfo = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.packageInfo?.data
  );
  const getPackageInfoError = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.packageInfo?.error
  );
  const isSaving = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.extendPickupDeadline?.isExtending
  );
  const extendError = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.extendPickupDeadline?.error
  );
  const isExtended = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.extendPickupDeadline?.isExtended
  );
  const sentPickupCode = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.resendPickupCode?.sentPickupCode
  );
  const resendError = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.resendPickupCode.error
  );

  const orderLoaded = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.order?.isLoaded
  );
  const order = useAppSelector((state: RootState) => state?.home?.orderDetails?.order?.data);

  const redirectTo = `/orderSearch/${trackingReference}`;

  useEffect(() => {
    dispatch(getPackageInfo(trackingReference!));
  }, []);

  useEffect(() => {
    dispatch(getOrder(trackingReference!));
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = async ({ sendByEmail, sendBySMS, extendReturnDate }) => {
    if (
      extendReturnDate &&
      packageInfoLoaded &&
      packageInfo.deadlineChecksum &&
      !packageInfo.deadlineExtended
    ) {
      await dispatch(extendPackagePickupDeadline(packageInfo.deadlineChecksum));
    }
    if (sendByEmail || sendBySMS) {
      const communicationPreference: ApiCommunicationPreference = {
        email: sendByEmail,
        sms: sendBySMS
      };
      await dispatch(resendPickupCode(trackingReference!, communicationPreference));
    }
    if ((isExtended || sentPickupCode) && (!extendError || !resendError)) {
      navigate(redirectTo);
    }
  };

  if (packageInfoLoading || !orderLoaded) return <Spinner />;
  return (
    <SplitPage>
      <PageWrapper className='edit-service-point'>
        <MessageForUser variant='info' message={t('order.edit.servicepoint.info.message')} />
        {!isEmpty(getPackageInfoError) ? (
          <InfoBox text={t('orderDetails.edit.servicePoint.extend.not.possible')} />
        ) : (
          <FormV2
            onSubmit={handleSubmit}
            initialValues={{
              sendByEmail: false,
              sendBySMS: false,
              extendReturnDate: packageInfo?.deadlineExtended
            }}
            redirectTo={redirectTo}
            error={extendError || resendError}
            loading={isSaving}
          >
            <ToggleGroup
              label={tu('send.pickup.code')}
              toggles={sendPickupCodeByToggles(order?.consignee)}
              groupDisabled={!packageInfoLoaded}
              align='row'
            />
            <ToggleGroup
              label={tu('return.extend')}
              toggles={extendReturnDateToggle(
                packageInfo
                  ? extendReturnDateToggleLabel(packageInfo)
                  : t('orderDetails.edit.servicePoint.extend.not.possible'),
                packageInfo?.deliveryDeadline
                  ? extendReturnDateToggleSubLabel(
                      format(new Date(packageInfo.deliveryDeadline), 'dd-MM-yyyy HH:mm')
                    )
                  : t('orderDetails.edit.servicePoint.not.found'),
                packageInfo?.deadlineExtended
              )}
              groupDisabled={!packageInfoLoaded}
            />
          </FormV2>
        )}
      </PageWrapper>
    </SplitPage>
  );
};

export default EditServicePoint;
