import jiff from 'jiff';
import cloneDeep from 'lodash/cloneDeep';

const { NODE_ENV, FLOW_API_URL } = process.env;

const trimToNull = (value) => (typeof value === 'string' ? value.trim() || null : value);

const encodeParam = (key, value) =>
  `${encodeURIComponent(key)}=${encodeURIComponent(trimToNull(value))}`;

export const encode = (params) => {
  if (!params) return '';

  return (
    '?' +
    Object.keys(params) // eslint-disable-line prefer-template
      .filter((key) => trimToNull(params[key]) != null)
      .map((key) =>
        Array.isArray(params[key])
          ? params[key].map((value) => encodeParam(key, value)).join('&')
          : encodeParam(key, params[key])
      )
      .join('&')
  );
};

export const stringify = (data) => data && JSON.stringify(data, (_, value) => trimToNull(value));

export function createQuery(params) {
  const encode = encodeURIComponent;

  if (!params) return '';

  return `?${Object.keys(params)
    .filter((key) => params[key] != null)
    .map((key) => `${encode(key)}=${encode(params[key])}`)
    .join('&')}`;
}

export function getReportUrl(url, data) {
  return FLOW_API_URL + url + createQuery(data);
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function setJwtTokenCookieIfLocalOrMock(token) {
  // eslint-disable-next-line no-constant-condition
  if (NODE_ENV === 'local' || 'mock') {
    return (document.cookie = `jwt-token=${token}; max-age=86400; path=/;`);
  }
}

export function removeJwtTokenCookie() {
  return (document.cookie = 'jwt-token=; max-age=-86400;');
}

const setEmptyStringsToNullIfNotUpdated = (oldObject, newObject) => {
  for (const [key, value] of Object.entries(newObject)) {
    if (value === '' && oldObject[key] === null) newObject[key] = null;
    if (value === undefined && oldObject[key] === null) newObject[key] = null;
  }
  return newObject;
};

export const buildJsonPatchRequest = (oldObject, newObject) => {
  const parsedNewObject = setEmptyStringsToNullIfNotUpdated(oldObject, cloneDeep(newObject));
  return jiff.diff(oldObject, parsedNewObject);
};
