import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

import { ApiLabelQuery } from 'src/interfaces/interfaces.generated';
import { locationChanged } from 'js/store/actions';

export interface LabelsReducerState {
  error: unknown;
  isLoading: boolean;
  isFound?: boolean;
}

const initialState: LabelsReducerState = {
  error: null,
  isLoading: false,
  isFound: undefined
};

const labelsSlice = createSlice({
  name: 'Labels',
  initialState,
  reducers: {
    getLabelsRequest(state) {
      state.error = null;
      state.isLoading = true;
      state.isFound = undefined;
    },
    getLabelsSuccess(state, action) {
      state.isLoading = false;
      state.isFound = action.payload;
    },
    getLabelsFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
      state.isFound = undefined;
    });
  }
});

export const { getLabelsRequest, getLabelsSuccess, getLabelsFailed } = labelsSlice.actions;

export default labelsSlice.reducer;

export const getLabels =
  (data: ApiLabelQuery) =>
  async (dispatch): Promise<boolean> => {
    try {
      dispatch(getLabelsRequest());
      const response: boolean = await rest.orderLabels(data);
      dispatch(getLabelsSuccess(response));
      return response;
    } catch (err) {
      dispatch(getLabelsFailed(err));
      return false;
    }
  };
