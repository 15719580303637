import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';
import {
  editCustomerCommunication,
  getCustomerCommunication
} from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/customerCommunicationReducer';
// eslint-disable-next-line max-len
import CustomerMessage from 'js/scenes/Freight/scenes/Shops/scenes/Shop/scenes/EditCustomerCommunication/components/CustomerMessage';
import FormV2 from 'js/components/FormV2';
import Spinner from 'js/components/Spinner';
import Input from 'js/components/Form/components/Input';
import { ApiCustomerCommunication } from 'interfaces/interfaces.generated';
import t, { tu } from 'js/utils/translate';
import './index.sass';
import { reloadShop } from '../../store/shopReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate, useParams } from 'react-router-dom';

const EditCustomerCommunication: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shopId } = useParams();

  const customerCommunicationLoaded = useAppSelector(
    (state: RootState) => state.freight.shop.customerCommunication.isLoaded
  );
  const customerCommunication: ApiCustomerCommunication = useAppSelector(
    (state: RootState) => state.freight.shop.customerCommunication.data
  );
  const customerCommunicationSaving = useAppSelector(
    (state: RootState) => state.freight.shop.customerCommunication.isSaving
  );
  const customerCommunicationError = useAppSelector(
    (state: RootState) => state.freight.shop.customerCommunication.error
  );

  const redirectTo = `/freight/shop/${shopId}`;

  useEffect(() => {
    if (!customerCommunicationLoaded) dispatch(getCustomerCommunication(shopId));
  }, []);

  const handleSubmit = async (values) => {
    await dispatch(editCustomerCommunication(shopId, values)).then(
      (response: boolean | undefined) => {
        if (response) {
          dispatch(reloadShop(+shopId!));
          navigate(redirectTo);
        }
      }
    );
  };

  if (!customerCommunicationLoaded) return <Spinner />;

  return (
    <div className='edit-customer-message-page'>
      <FormV2
        initialValues={customerCommunication}
        onSubmit={handleSubmit}
        loading={customerCommunicationSaving}
        error={customerCommunicationError}
      >
        <Input
          className='edit-customer-message-page__customer-facing-name'
          label={t('customer.communication.customer.facing.name')}
          name='customerFriendlyName'
        />
        <h2>{tu('customer.communication.messages')}</h2>
        {customerCommunication.customerMessages.map((customerMessage, index) => (
          <CustomerMessage key={customerMessage.customerMessageId} index={index} />
        ))}
      </FormV2>
    </div>
  );
};

export default EditCustomerCommunication;
