import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../api';
import { locationChanged } from 'js/store/actions';

export interface EditShipmentReducerState {
  error: unknown;
  isSaving: boolean;
}

const initialState: EditShipmentReducerState = {
  error: null,
  isSaving: false
};

const editShipmentSlice = createSlice({
  name: 'EditShipment',
  initialState,
  reducers: {
    editShipmentRequest(state) {
      state.isSaving = true;
      state.error = null;
    },
    editShipmentSuccess(state) {
      state.isSaving = false;
    },
    editShipmentFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { editShipmentRequest, editShipmentSuccess, editShipmentFailed } =
  editShipmentSlice.actions;

export default editShipmentSlice.reducer;

export const editShipment =
  (trackingReference: string, shipment) =>
  async (dispatch): Promise<boolean | unknown> => {
    try {
      dispatch(editShipmentRequest());
      await api.editShipment(trackingReference, shipment);
      dispatch(editShipmentSuccess());
      return true;
    } catch (error) {
      dispatch(editShipmentFailed(error));
    }
  };
