import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { FlowUserInfo, UserInfo } from 'src/interfaces/interfaces.generated';

import { mapRoles } from 'js/utils/roles';

import * as api from '../../utils/rest';

export interface UserReducerState {
  error: unknown;
  data: UserInfo;
  isLoaded: boolean;
  isUpdating: boolean;
  isUpdated: boolean;
}

const initialState: UserReducerState = {
  error: null,
  data: {
    activeOwnerParty: 0,
    email: '',
    language: 'NO',
    ownerParties: [],
    roles: {},
    shortcuts: [],
    userId: 0,
    showPasswordExpireWarning: false
  },
  isLoaded: false,
  isUpdating: false,
  isUpdated: false
};

const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    getUserRequest(state) {
      state.error = null;
      state.isLoaded = false;
    },
    getUserSuccess(state, action) {
      state.isLoaded = true;
      const { roles, ...user } = action.payload;
      state.data = { roles: mapRoles(roles), ...user };
    },
    getUserFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    },
    updateUserRequest(state) {
      state.error = null;
      state.isUpdating = true;
      state.isUpdated = false;
    },
    updateUserSuccess(state) {
      state.isUpdating = false;
      state.isUpdated = true;
    },
    updateUserFailed(state, action) {
      state.error = action.payload;
      state.isUpdating = false;
      state.isUpdated = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  getUserRequest,
  getUserSuccess,
  getUserFailed,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailed
} = userSlice.actions;

export default userSlice.reducer;

export const getUser =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getUserRequest());
      const response: UserInfo = await api.getUser();
      dispatch(getUserSuccess(response));
      return true;
    } catch (error) {
      dispatch(getUserFailed(error));
    }
  };

export const updateUser =
  (user: FlowUserInfo) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(updateUserRequest());
      await api.updateUser(user);
      dispatch(updateUserSuccess());
      return true;
    } catch (error) {
      dispatch(updateUserFailed(error));
    }
  };
