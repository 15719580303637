import { FC } from 'react';

import Input from 'js/components/Form/components/Input';
import SelectLanguage from 'js/scenes/Freight/components/Users/components/SelectLanguage';
import SelectAdminRights from 'js/scenes/Freight/components/Users/components/SelectAdminRights';

import t, { tu } from 'js/utils/translate';

interface Props {
  parentName: string;
  index: number;
}

const CreateUserForm: FC<Props> = ({ parentName, index }) => (
  <div className='create-user__form-wrapper'>
    <Input label={tu('general.firstName')} name={`${parentName}[${index}]firstName`} />
    <Input label={tu('general.lastName')} name={`${parentName}[${index}]lastName`} />
    <Input label={tu('general.username')} name={`${parentName}[${index}]username`} />
    <Input label={tu('general.email')} name={`${parentName}[${index}]email`} type='email' />
    <SelectAdminRights
      name={`${parentName}[${index}]adminRights`}
      placeholder={t('freight.shop.users.create.select.placeholder.adminRights')}
    />
    <SelectLanguage
      name={`${parentName}[${index}]language`}
      placeholder={t('freight.shop.users.create.select.placeholder.language')}
    />
  </div>
);

export default CreateUserForm;
