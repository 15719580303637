/* eslint-disable react/jsx-pascal-case */
import { FC, useMemo } from 'react';
import classNames from 'classnames';
import {
  useRegister,
  useValue,
  withError,
  useFormContext
} from '@distribution-innovation/form-hooks';

import _Datepicker from 'js/components/Datepicker';
import InputError from 'js/components/Form/components/Validation/InputError';
import Label from 'js/components/Label';

import './index.sass';

interface Props {
  className?: string;
  name: string;
  label: string;
  sublabel?: string;
  info?: string;
  optional?: boolean;
  error;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  showTimeInput?: boolean;
}

const Datepicker: FC<Props> = ({
  className,
  name,
  label,
  sublabel,
  info,
  optional,
  error,
  minDate,
  maxDate,
  disabled,
  showTimeInput
}) => {
  const context = useFormContext();

  const [selectedDate, setSelectedDate] = useValue(name);

  const validate = (value: Date | undefined) => {
    if (optional) return undefined;
    else if (!value) return 'valueMissing';
  };

  const handleBlur = () => context.getTouched(name) || context.setTouched(name);

  useRegister({ name, value: selectedDate, validate });

  return useMemo(
    () => (
      <div className={classNames('form-datepicker', className)}>
        <Label label={label} info={info} optional={optional} />
        <_Datepicker
          date={selectedDate}
          setDate={setSelectedDate}
          minDate={minDate}
          maxDate={maxDate}
          handleBlur={handleBlur}
          disabled={disabled}
          isClearable={optional}
          showTimeInput={showTimeInput}
          error={error}
        />
        {sublabel && <div className='form-datepicker__sublabel'>{sublabel}</div>}
        {error && <InputError error={error} />}
      </div>
    ),
    [error, selectedDate, disabled]
  );
};

export default withError()(Datepicker);
