import { useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import PageWrapper from 'js/components/PageWrapper';
import InvoiceSetupTable from './components/InvoiceSetupTable';
import InfoBox from 'js/components/InfoBox';

import t from 'js/utils/translate';
import { notFullAccess } from './utils';

import { getFreightProductInvoiceSetups } from './store/freightProductInvoiceSetup';
import { getWeightGroups } from './store/weightGroupsReducer';
import { getDimensionGroups } from './store/dimensionGroupsReducer';
import { getZoneIds } from './store/zoneIdsReducer';
import { getZipGroups } from './store/zipGroupsReducer';
import { getInvoiceSystems } from './store/invoiceSystemsReducer';
import Spinner from 'js/components/Spinner';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const InvoiceSetup = () => {
  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  const freightProducts = useAppSelector((state: RootState) => state.freightProducts?.data);
  const freightProductsLoaded = useAppSelector(
    (state: RootState) => state.freightProducts?.isLoaded
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getZipGroups());
    dispatch(getDimensionGroups());
    dispatch(getWeightGroups());
    dispatch(getZoneIds());
    dispatch(getInvoiceSystems());
    dispatch(getFreightProductInvoiceSetups());
  }, []);

  if (!freightProductsLoaded) return <Spinner />;

  return (
    <PageWrapper className='invoice-setup-page' withoutPadding={!isDesktop}>
      {notFullAccess(freightProducts) ? (
        <InfoBox text={t('general.noInformationFound')} />
      ) : (
        <InvoiceSetupTable />
      )}
    </PageWrapper>
  );
};

export default InvoiceSetup;
