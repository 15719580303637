import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { RootState } from 'js/store/reducer';
import { isEmpty } from 'lodash';

import LinkV2 from 'js/components/LinkV2';

import { downloadInvoice } from './store/downloadInvoiceReducer';
import t from 'js/utils/translate';

interface Props {
  invoiceId?: number;
}

const DownloadInvoice: FC<Props> = ({ invoiceId }) => {
  const dispatch = useAppDispatch();

  const id = useAppSelector((state: RootState) => state?.economy?.downloadInvoice?.id);
  const isDownloading = useAppSelector(
    (state: RootState) => state?.economy?.downloadInvoice?.isDownloading
  );
  const error = useAppSelector((state: RootState) => state?.economy?.downloadInvoice?.error);

  const handleClick = () => {
    if (invoiceId) dispatch(downloadInvoice(invoiceId));
  };

  return (
    <LinkV2
      className='download-invoice-basis-link'
      text={t('economy.generateInvoice.invoice.download')}
      onClick={handleClick}
      loading={isDownloading && invoiceId === id}
      error={!isEmpty(error) && invoiceId === id}
    />
  );
};

export default DownloadInvoice;
