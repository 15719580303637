import { useState } from 'react';

import t from 'js/utils/translate';

import './index.sass';

const FreightProductExplanations = () => {
  const translateKeyPrefix = 'shop.accordion.freightProduct.info';
  const [open, setOpen] = useState(false);

  return (
    <div className='freight-product-explanations-section'>
      {open && (
        <div className='explanations'>
          <div className='explanation'>{t(`${translateKeyPrefix}.cost.prices`)}</div>
          <div className='border' />
          <div className='explanation'>{t(`${translateKeyPrefix}.label.receipent`)}</div>
          <div className='border' />
          <div className='explanation'>{t(`${translateKeyPrefix}.max.value`)}</div>
          <div className='border' />
          <div className='explanation'>{t(`${translateKeyPrefix}.standard.weight`)}</div>
          <div className='border' />
          <div className='explanation'>{t(`${translateKeyPrefix}.active`)}</div>
        </div>
      )}
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className='link show-explanations-link'
      >
        {open ? t(`${translateKeyPrefix}.close`) : t(`${translateKeyPrefix}.open`)}
      </div>
    </div>
  );
};

export default FreightProductExplanations;
