import { useEffect, useState } from 'react';
import { RootState } from 'js/store/reducer';
import isEmpty from 'lodash/isEmpty';

import OrderHistory from './components/OrderHistory';
import OrderInfo from './components/OrderInfo';
import OrderTracking from './components/Tracking';
import NotFound from 'js/components/NotFound';

import { getOrder } from './store/orderReducer';

import { ApiOrderSimple } from 'src/interfaces/interfaces.generated';
import StatusHeader from 'js/components/StatusHeader';
import t, { tu } from 'js/utils/translate';
import { formatDate, safariSafeDateString } from 'js/utils/dateTime';
import { getLinkedParcelNumbers } from 'js/scenes/Home/scenes/OrderDetails/store/linkedParcelNumbersReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useNavigate, useParams } from 'react-router-dom';

const OrderDetails = () => {
  const { trackingReference } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const order: ApiOrderSimple = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.order?.data
  );
  const orderLoaded = useAppSelector(
    (state: RootState) => state?.home?.orderDetails?.order?.isLoaded
  );
  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);
  const userRoles = useAppSelector((state: RootState) => state?.user?.data?.roles);
  const linkedParcelNumbers = useAppSelector(
    (state: RootState) => state?.home?.orderDetails.linkedParcelNumbers.data
  );

  useEffect(() => {
    if (trackingReference) dispatch(getLinkedParcelNumbers(trackingReference));
  }, [trackingReference]);
  const canEditOrder = userRoles?.di || userRoles?.parcelAdmin || userRoles?.shopAdmin;

  const goToEditOrderPage = (page: string) => {
    navigate(`/orderSearch/${order.trackingReference}/edit/${page}`);
  };

  useEffect(() => {
    if (trackingReference) dispatch(getOrder(trackingReference));
  }, [trackingReference]);

  const [viewOrderHistory, setViewOrderHistory] = useState(false);
  const toggleViewOrderHistory = () => {
    setViewOrderHistory(!viewOrderHistory);
  };

  const buttons = [
    {
      className: 'order-edit-buttons__edit-order',
      text: t('orderDetails.edit.order.buttons.edit.order'),
      disabled: !canEditOrder || !trackingReference,
      variant: 'inverse',
      onClick: () => {
        goToEditOrderPage('order');
      }
    },
    {
      className: 'order-edit-buttons__edit-status',
      text: t('orderDetails.edit.order.buttons.edit.status'),
      disabled: !trackingReference,
      onClick: () => {
        goToEditOrderPage('status');
      },
      variant: 'inverse'
    },
    {
      className: 'order-edit-buttons__new-order',
      text: t('general.new.order'),
      variant: 'inverse',
      disabled: !trackingReference,
      onClick: () => {
        navigate('/orders/newOrder', {
          state: {
            fromOrder: trackingReference
          }
        });
      }
    }
  ];
  const orderStatus = () => {
    if (order?.parcelStatus) {
      return order?.pickup
        ? `${tu(order?.parcelStatus)} / ${
            order?.pickup?.senderDropDate &&
            formatDate(safariSafeDateString(order?.pickup?.senderDropDate))
          }`
        : `${tu(order?.parcelStatus)}`;
    }
    return '';
  };

  return (
    <div className='order-details'>
      <StatusHeader
        info={
          !!linkedParcelNumbers.length && (
            <OrderTracking linkedParcelNumbers={linkedParcelNumbers} />
          )
        }
        color='green'
        statusText={orderStatus()}
        buttons={buttons}
        mobileToggleSection={toggleViewOrderHistory}
        showMobileSection={viewOrderHistory}
      />
      <div className='order-details__body'>
        {orderLoaded && isEmpty(order) && <NotFound />}
        {orderLoaded && !isEmpty(order) && (
          <>
            {(isDesktop || viewOrderHistory) && (
              <OrderHistory trackingReference={order.trackingReference} />
            )}
            <OrderInfo order={order} canEditOrder={canEditOrder} />
          </>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
