import Ajax from 'js/utils/ajax/ajax';

import { ApiContractService, ApiRetailerProduct } from 'src/interfaces/interfaces.generated';
import { PackageSystemLocation, Context } from '../interfaces';

export const getContractServices = (): Promise<ApiContractService[]> =>
  Ajax.get('/shops/v1/contractservices');

export const getPackageSystemLocations = (): Promise<PackageSystemLocation[]> =>
  Ajax.get('/shops/v1/packagesystemlocations');

export const getContexts = (): Promise<Context[]> => Ajax.get('/shops/v1/contexts');

export const getRetailerProducts = (): Promise<ApiRetailerProduct[]> =>
  Ajax.get('/shops/v1/retailerproducts');
