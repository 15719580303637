import { FC } from 'react';
import Icon from 'js/components/Icon';
import './index.sass';

interface Props {
  index: number;
  disabled: boolean;
  className: string;
  setEditingSystemMessageIndex: (index) => typeof index;
}

export const SystemMessageIcons: FC<Props> = ({
  index,
  disabled,
  className,
  setEditingSystemMessageIndex
}) =>
  disabled ? (
    <button key={`disabled${disabled}`} className={`${className} submit-icon`} type='button'>
      <Icon
        spanClassName='system-info-icon'
        icon='pen'
        handleClick={() => setEditingSystemMessageIndex(index)}
      />
    </button>
  ) : (
    <button key={`disabled${disabled}`} className={`${className} submit-icon`} type='submit'>
      <Icon icon='save' />
    </button>
  );

export default SystemMessageIcons;
