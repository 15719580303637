import { FC } from 'react';
import { useValue } from '@distribution-innovation/form-hooks';

import ColoredSection from 'js/components/ColoredSection';
import Article from './components/Article';

import t from 'js/utils/translate';

interface Props {
  parcelIndex: number;
}

const Articles: FC<Props> = ({ parcelIndex }) => {
  const [articles, setArticles] = useValue(`parcels[${parcelIndex}]articles`);

  const addArticle = () => setArticles([...(articles || []), {}]);

  const removeArticle = (index) => setArticles(articles?.filter((article, i) => i !== index));

  return (
    <ColoredSection className='articles'>
      <div>
        {articles?.map((article, index) => (
          <Article
            key={index}
            index={index}
            parcelIndex={parcelIndex}
            removeArticle={removeArticle}
          />
        ))}
      </div>
      <div className='link add' onClick={addArticle}>
        {t('orders.new.order.add.article')}
      </div>
    </ColoredSection>
  );
};

export default Articles;
