import ColoredSectionWithHeader from '../../../ColoredSectionWithHeader';

import t, { tu } from 'js/utils/translate';

const AdditionalServices = ({ translateKeyPrefix, shop }) => (
  <ColoredSectionWithHeader header={tu(`${translateKeyPrefix}.shopContractServices`)}>
    {shop?.shopContractServices.length > 0 ? (
      shop.shopContractServices?.map((shopContractService) => (
        <div key={shopContractService.name}>
          <div>{`${shopContractService.name} (${shopContractService.code})`}</div>
        </div>
      ))
    ) : (
      <div>{t('general.not.active')}</div>
    )}
  </ColoredSectionWithHeader>
);

export default AdditionalServices;
