import { ShortcutFooterBox } from '../interfaces';

import Labels from 'images/icons/Labels.svg';
import Economy from 'images/icons/Economy.svg';
import Freight from 'images/icons/Freight.svg';
import Orders from 'images/icons/Orders.svg';
import TransportSolutions from 'images/icons/Transportlosninger.svg';
import Invoice from 'images/icons/Fakturagrunnlag.svg';
import InvoiceSetup from 'images/icons/Fakturaoppsett.svg';
import LabelHistory from 'images/icons/LabelHistory.svg';
import NewOrder from 'images/icons/NewOrder.svg';
import BulkOrder from 'images/icons/BulkOrder.svg';
import PriceAdjustments from 'images/icons/Prisjusteringer.svg';
import CostPrices from 'images/icons/Kostpriser.svg';

// NB Må oppdateres når endringer skjer i backend på GET /api/flow/userinfo/v1/shortcuts
export const mainPanelShortcuts: any[] = [
  {
    id: 1,
    headerText: 'freight',
    link: '/freight',
    icon: Freight
  },
  {
    id: 5,
    headerText: 'transportSolutions',
    link: '/freight/transportSolutions',
    icon: TransportSolutions
  },
  {
    id: 6,
    headerText: 'freightProducts',
    link: '/freight/freightProducts',
    icon: Freight
  },
  {
    id: 2,
    headerText: 'orders',
    link: '/orders',
    icon: Orders
  },
  {
    id: 4,
    headerText: 'labels',
    link: '/orders/labels',
    icon: Labels
  },
  {
    id: 7,
    headerText: 'labelHistory',
    link: '/orders/labelHistory',
    icon: LabelHistory
  },
  {
    id: 8,
    headerText: 'bulkOrder',
    link: '/orders/bulkOrder',
    icon: BulkOrder
  },
  {
    id: 9,
    headerText: 'newOrder',
    link: '/orders/newOrder',
    icon: NewOrder
  },
  {
    id: 3,
    headerText: 'economy',
    link: '/economy',
    icon: Economy
  },
  {
    id: 10,
    headerText: 'invoice',
    link: '/economy/invoice',
    icon: Invoice
  },
  {
    id: 11,
    headerText: 'priceAdjustments',
    link: '/economy/priceAdjustments',
    icon: PriceAdjustments
  },
  {
    id: 12,
    headerText: 'costPriceForFreight',
    link: '/economy/costPrices',
    icon: CostPrices
  },
  {
    id: 13,
    headerText: 'invoiceSetup',
    link: '/economy/invoiceSetup',
    icon: InvoiceSetup
  }
];

export const findSelectedShortcutFooterboxes = (shortcuts: number[]): ShortcutFooterBox[] =>
  mainPanelShortcuts.filter((mainPanelShortcut) =>
    shortcuts.some((shortcutId) => mainPanelShortcut.id === shortcutId)
  );
