import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import * as api from '../../api';

export interface RemoveShopUserState {
  error: unknown;
  id: number | null;
  isRemoving: boolean;
  isRemoved: boolean;
}

const initialState: RemoveShopUserState = {
  error: null,
  id: null,
  isRemoving: false,
  isRemoved: false
};

const removeShopUserSlice = createSlice({
  name: 'RemoveShopUser',
  initialState,
  reducers: {
    removeShopUserRequest(state, action) {
      state.error = null;
      state.isRemoving = true;
      state.isRemoved = false;
      state.id = action.payload;
    },
    removeShopUserSuccess(state) {
      state.isRemoved = true;
      state.isRemoving = false;
    },
    removeShopUserFailed(state, action) {
      state.error = action.payload;
      state.isRemoved = false;
      state.isRemoving = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { removeShopUserRequest, removeShopUserSuccess, removeShopUserFailed } =
  removeShopUserSlice.actions;

export default removeShopUserSlice.reducer;

export const removeShopUser =
  (shopId: number, userId: number) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(removeShopUserRequest(userId));
      await api.removeUser(shopId, userId);
      dispatch(removeShopUserSuccess());
      return true;
    } catch (error) {
      dispatch(removeShopUserFailed(error));
    }
  };
