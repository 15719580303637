import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import MobileMatrix from 'js/scenes/Economy/scenes/CostPrices/components/CostPrice/components/MobileMatrix';
import DesktopMatrix from 'js/scenes/Economy/scenes/CostPrices/components/CostPrice/components/DesktopMatrix';

import { MatrixProps } from '../../interfaces';
import { useAppSelector } from 'js/utils/hooks';

const Matrix: FC<MatrixProps> = ({
  freightProductPrices,
  currentPrices = true,
  selectedFilter,
  showGrayMatrix
}) => {
  const isDesktop: boolean = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  return isDesktop ? (
    <DesktopMatrix
      freightProductPrices={freightProductPrices}
      currentPrices={currentPrices}
      selectedFilter={selectedFilter}
      showGrayMatrix={showGrayMatrix}
    />
  ) : (
    <MobileMatrix
      freightProductPrices={freightProductPrices}
      currentPrices={currentPrices}
      selectedFilter={selectedFilter}
      showGrayMatrix={showGrayMatrix}
    />
  );
};

export default Matrix;
