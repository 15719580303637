import { createSlice } from '@reduxjs/toolkit';

import * as api from '../../api';

const initialState = {
  error: null,
  isCreating: false,
  isLoading: false,
  isLoaded: false,
  isHandled: false,
  data: []
};

const bulkOrderSlice = createSlice({
  name: 'BulkOrder',
  initialState,
  reducers: {
    createBulkOrderRequest(state) {
      state.error = null;
      state.isCreating = true;
    },
    createBulkOrderSuccess(state) {
      state.isCreating = false;
    },
    createBulkOrderFailed(state, action) {
      state.error = action.payload;
      state.isCreating = false;
    },
    getBulkOrdersRequest(state) {
      state.error = null;
      state.isLoading = true;
      state.isLoaded = false;
    },
    getBulkOrdersSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.isLoaded = true;
    },
    getBulkOrdersFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
      state.isLoaded = false;
    },
    handleBulkOrderRequest(state) {
      state.error = null;
      state.isHandled = false;
    },
    handleBulkOrderSuccess(state) {
      state.isHandled = true;
    },
    handleBulkOrderFailed(state, action) {
      state.error = action.payload;
      state.isHandled = false;
    }
  }
});

export const {
  createBulkOrderRequest,
  createBulkOrderSuccess,
  createBulkOrderFailed,
  getBulkOrdersRequest,
  getBulkOrdersSuccess,
  getBulkOrdersFailed,
  handleBulkOrderRequest,
  handleBulkOrderSuccess,
  handleBulkOrderFailed
} = bulkOrderSlice.actions;

export default bulkOrderSlice.reducer;

export const createBulkOrder = (shopId, transportSolutionId, formData) => async (dispatch) => {
  try {
    dispatch(createBulkOrderRequest());
    const response = await api.createBulkOrder(shopId, transportSolutionId, formData);
    dispatch(createBulkOrderSuccess(response));
    return response;
  } catch (err) {
    dispatch(createBulkOrderFailed(err));
  }
};

export const getBulkOrders = () => async (dispatch) => {
  try {
    dispatch(getBulkOrdersRequest());
    const response = await api.getBulkOrders();
    dispatch(getBulkOrdersSuccess(response));
    return response;
  } catch (err) {
    dispatch(getBulkOrdersFailed(err));
  }
};

export const handleBulkOrder = (importFileId, action) => async (dispatch) => {
  try {
    dispatch(handleBulkOrderRequest());
    const response = await api.handleBulkOrder(importFileId, action);
    dispatch(handleBulkOrderSuccess(response));
    return response;
  } catch (err) {
    dispatch(handleBulkOrderFailed(err));
  }
};
