import { FC } from 'react';
import { toFloat, useFormContext } from '@distribution-innovation/form-hooks';

import Input from 'js/components/Form/components/Input';

import { tu } from 'js/utils/translate';
import { findNameIndex } from './utils';

import { ApiFreightPrice } from 'src/interfaces/interfaces.generated';
import { PriceType } from '../../../../../../interfaces';

interface Props {
  priceType: PriceType;
  zone: string;
  zoneId: number;
  weightId: number;
}

const FreightPrice: FC<Props> = ({ priceType, zoneId, zone, weightId }) => {
  const { getValue } = useFormContext();
  const freightPrices: ApiFreightPrice[] = getValue('freightPrices');

  const nameIndex = findNameIndex(freightPrices, zoneId, priceType, weightId);

  return (
    <Input
      className='freight-price'
      label={tu(
        'economy.edit.costprice.zone',
        zone,
        priceType === 'ALL' ? '' : tu(`general.${priceType}`)
      )}
      name={`freightPrices[${nameIndex}].costPrices[0].costPrice`}
      type='number'
      step='0.01'
      convert={toFloat}
      optional
      hideOptional
    />
  );
};

export default FreightPrice;
