import { FC } from 'react';

import Icon from 'js/components/Icon';

import './index.sass';

interface Props {
  eventGroupId?: number;
}

const EventIcon: FC<Props> = ({ eventGroupId }) => {
  const eventGroupIconMap = new Map([
    [1, 'download'],
    [2, 'truck'],
    [3, 'envelope'],
    [4, 'heartbeat'],
    [5, 'barcode'],
    [6, 'exchange'],
    [7, 'external-link'],
    [8, 'thumb-tack'],
    [9, 'camera']
  ]);

  return (
    <div className='event-icon'>
      <Icon fixedWidth icon={eventGroupId ? eventGroupIconMap.get(eventGroupId) : undefined} />
    </div>
  );
};

export default EventIcon;
