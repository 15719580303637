import { createSlice } from '@reduxjs/toolkit';

import * as rest from '../../api';

export interface DimensionGroupsReducerState {
  error: unknown;
  data: any[];
  isLoaded: boolean;
}

const initialState: DimensionGroupsReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const dimensionGroupsSlice = createSlice({
  name: 'DimensionGroups',
  initialState,
  reducers: {
    getDimensionGroupsRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getDimensionGroupsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getDimensionGroupsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  }
});

export const { getDimensionGroupsRequest, getDimensionGroupsSuccess, getDimensionGroupsFailed } =
  dimensionGroupsSlice.actions;

export default dimensionGroupsSlice.reducer;

export const getDimensionGroups = () => async (dispatch) => {
  try {
    dispatch(getDimensionGroupsRequest());
    const dimensionGroups = await rest.getDimensionGroups();
    dispatch(getDimensionGroupsSuccess(dimensionGroups));
  } catch (err) {
    dispatch(getDimensionGroupsFailed(err));
  }
};
