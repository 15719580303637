import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import Select from 'js/components/Form/components/Select';

import t, { tu } from 'js/utils/translate';
import { getWebserviceUsers } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/wsUsersReducer';
import { getShopUsers } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/shopUsersReducer';
import {
  filteredShopUsers,
  filteredWebserviceUsers
} from 'js/scenes/Freight/scenes/Shops/scenes/Shop/utils';
import { findNotSelectedUsers } from '../../utils';
import { findUserById } from 'js/utils/find';
import { customUserFilter, userOptions } from 'js/components/Select/utils';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useParams } from 'react-router-dom';

interface Props {
  isApiContext?: boolean;
  authUsers;
  setSelectedUser;
}

const SelectShopUser: FC<Props> = ({ isApiContext, authUsers, setSelectedUser }) => {
  const { shopId } = useParams();
  const dispatch = useAppDispatch();

  const wsUsers = useAppSelector((state: RootState) => state?.freight?.shop?.webserviceUsers?.data);
  const wsUsersLoaded = useAppSelector(
    (state: RootState) => state?.freight?.shop?.webserviceUsers?.isLoaded
  );
  const shopUsers = useAppSelector((state: RootState) => state?.freight?.shop?.shopUsers?.data);
  const shopUsersLoaded = useAppSelector(
    (state: RootState) => state?.freight?.shop?.shopUsers?.isLoaded
  );

  useEffect(() => {
    if (!wsUsersLoaded) dispatch(getWebserviceUsers());
    if (!shopUsersLoaded && shopId) dispatch(getShopUsers(+shopId));
  }, []);

  const users = isApiContext
    ? findNotSelectedUsers(wsUsers, filteredWebserviceUsers(shopUsers))
    : findNotSelectedUsers(authUsers, filteredShopUsers(shopUsers));

  const isLoading = !users;

  const handleSelectChange = (e) => {
    if (users) setSelectedUser(e.value ? findUserById(users, e.value) : {});
  };

  return (
    <Select
      className='select-connect-user'
      name='connectUser'
      label={tu('general.username')}
      placeholder={
        isLoading
          ? ''
          : t(
              'freight.shop.users.connect.select.placeholder.user',
              isApiContext ? t('general.apiUser') : t('general.user')
            )
      }
      filterOption={customUserFilter}
      isLoading={isLoading}
      options={userOptions(users)}
      onChange={(e) => {
        handleSelectChange(e);
      }}
    />
  );
};

export default SelectShopUser;
