import { FC, useEffect, useState } from 'react';

import Spinner from 'js/components/Spinner';
import SearchFilter from 'js/components/SearchFilter';

import FreightProductsTable from './components/FreightProductsTable';

import { filterFreightProducts } from './utils';
import t from 'js/utils/translate';
import { RootState } from 'js/store/reducer';
import { FreightProduct } from 'src/interfaces/interfaces.generated';
import { useAppSelector } from 'js/utils/hooks';

const FreightProducts: FC = () => {
  const freightProductsLoaded: boolean = useAppSelector(
    (state: RootState) => state.freightProducts?.isLoaded
  );
  const freightProducts: FreightProduct[] = useAppSelector(
    (state: RootState) => state.freightProducts?.data
  );

  const [search, setSearch] = useState('');
  const [filteredFreightProducts, setFilteredFreightProducts] =
    useState<FreightProduct[]>(freightProducts);

  useEffect(() => {
    setFilteredFreightProducts(filterFreightProducts(freightProducts, search.toLowerCase()));
  }, [search, freightProducts]);

  if (!freightProductsLoaded) return <Spinner />;

  return (
    <div>
      <SearchFilter
        info={t('freight.search.freightProduct.info')}
        inputValue={search}
        setSearch={setSearch}
      />
      <FreightProductsTable freightProducts={filteredFreightProducts} />
    </div>
  );
};

export default FreightProducts;
