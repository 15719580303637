import { FC } from 'react';

import FormV2 from 'js/components/FormV2';
import ServiceSupplierShops from './components/ServiceSupplierShops';
import ServiceSupplierUsers from './components/ServiceSupplierUsers';

import { ApiServiceSupplier } from 'src/interfaces/interfaces.generated';

import './index.sass';

interface Props {
  isSubmitting?: boolean;
  className?: string;
  handleSubmit: any;
  initialValues?: any; // FormInitialValues;
  type: 'save' | 'create';
  serviceSupplier?: ApiServiceSupplier;
  editMode?: boolean;
  onCancel: () => void;
  error;
}

const ServiceSupplierForm: FC<Props> = ({
  isSubmitting,
  className,
  handleSubmit,
  initialValues,
  type,
  serviceSupplier,
  editMode,
  onCancel,
  error
}) => (
  <FormV2
    className={className}
    onSubmit={handleSubmit}
    initialValues={initialValues}
    type={type}
    onCancel={onCancel}
    error={error}
    loading={isSubmitting}
  >
    <ServiceSupplierShops serviceSupplier={serviceSupplier} />
    <ServiceSupplierUsers serviceSupplierId={editMode ? serviceSupplier?.id : undefined} />
  </FormV2>
);

export default ServiceSupplierForm;
