import { FC, useEffect } from 'react';
import { RootState } from 'js/store/reducer';

import SelectV2 from 'js/components/Form/components/SelectV2';

import { getShops } from 'js/scenes/Freight/store/shopsReducer';

import { shopOptions } from 'js/components/Select/utils';

import { tu } from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface Props {
  name: string;
  disabled?: boolean;
}

const SelectShops: FC<Props> = ({ name, disabled }) => {
  const dispatch = useAppDispatch();

  const shopsLoaded = useAppSelector((state: RootState) => state?.freight?.shops?.isLoaded);
  const shops = useAppSelector((state: RootState) => state?.freight?.shops?.data);

  useEffect(() => {
    if (!shopsLoaded) dispatch(getShops());
  }, []);

  return (
    <SelectV2
      name={name}
      isMulti
      selectAll
      label={tu('general.shops')}
      options={shopOptions(shops)}
      isLoading={!(disabled ?? shopsLoaded)}
      disabled={disabled}
    />
  );
};

export default SelectShops;
