import { FC } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import LinkV2 from 'js/components/LinkV2';
import ColoredSection from 'js/components/ColoredSection';

import { deleteFuturePriceAdjustment } from './store/deletePriceAdjustmentReducer';
import { getFuturePriceAdjustments } from '../../../../store/priceAdjustmentsReducer';
import t from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import { RootState } from 'js/store/reducer';

import './index.sass';
import { useNavigate, useParams } from 'react-router-dom';

const PriceAdjustmentInfo: FC = () => {
  const { priceAdjustmentId } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const context = useFormContext();
  const values = context.getValues();

  const isDeleting: boolean = useAppSelector(
    (state: RootState) => state?.economy?.deletePriceAdjustment?.isDeleting
  );
  const error: boolean = !!useAppSelector(
    (state: RootState) => state?.economy?.deletePriceAdjustment?.error
  );

  const handleRemove = () => {
    if (priceAdjustmentId) {
      dispatch(deleteFuturePriceAdjustment(+priceAdjustmentId)).then(
        (response: boolean | undefined) => {
          if (response) {
            dispatch(getFuturePriceAdjustments()).then((response: boolean | undefined) => {
              if (response) navigate('/economy/priceAdjustments');
            });
          }
        }
      );
    }
  };

  return (
    <ColoredSection className='price-adjustment-info'>
      <div>
        {t(
          'economy.priceAdjustments.edit.info.addedBy',
          `${values?.changeUserFirstName ?? '-'} ${values?.changeUserLastName ?? '-'}`,
          values?.changeUserUserName ?? '-'
        )}
      </div>
      <LinkV2
        text={t('economy.priceAdjustments.edit.remove')}
        onClick={handleRemove}
        loading={isDeleting}
        error={error}
      />
    </ColoredSection>
  );
};

export default PriceAdjustmentInfo;
