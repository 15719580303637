import { FC } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';

import CollapseV2 from 'js/components/CollapseV2';

import { RootState } from 'js/store/reducer';
import t from 'js/utils/translate';
import { ApiFlowShopSimple, TransportPriceTable } from 'src/interfaces/interfaces.generated';

import './index.sass';
import CostPriceCollapseBody from './components/CostPriceCollapseBody';
import { useAppSelector } from 'js/utils/hooks';
import { useNavigate } from 'react-router-dom';

const CostPriceChanges: FC = () => {
  const navigate = useNavigate();
  const { getValue } = useFormContext();
  const freightProductId: number | undefined = getValue('freightProductId');
  const selectedShop: ApiFlowShopSimple | undefined = getValue('selectedShop');

  const freightProductPricesLoaded: boolean = useAppSelector(
    (state: RootState) => state.economy.costPrices.isLoaded
  );
  const freightProductPrices: TransportPriceTable = useAppSelector(
    (state: RootState) => state.economy.costPrices.data
  );

  if (!freightProductPricesLoaded) return <></>;

  return (
    <CollapseV2
      className='cost-price-collapse'
      title={t('economy.costprice.adjustment')}
      info={t('economy.costprice.show.adjustments')}
      body={<CostPriceCollapseBody freightProductPrices={freightProductPrices} />}
      canEdit
      onEditClick={() => {
        navigate(`/economy/costPrices/edit/${selectedShop?.shopId}/${freightProductId}`);
      }}
    />
  );
};

export default CostPriceChanges;
