import { combineReducers } from 'redux';

import editCustomerSystemReducer, {
  EditCustomerSystemReducerState
} from './components/ExistingCustomerSystem/store/editCustomerSystemReducer';
import createCustomerSystemReducer, {
  CreateCustomerSystemReducerState
} from './components/NewCustomerSystem/store/createCustomerSystemReducer';

const customerSystemReducer = combineReducers({
  editCustomerSystem: editCustomerSystemReducer,
  createCustomerSystem: createCustomerSystemReducer
});

export default customerSystemReducer;

export interface CustomerSystemReducerState {
  editCustomerSystem: EditCustomerSystemReducerState;
  createCustomerSystem: CreateCustomerSystemReducerState;
}
