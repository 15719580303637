import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../api';
import { ApiConcern } from 'src/interfaces/interfaces.generated';

export interface GetConcernsReducerState {
  error: unknown;
  data: ApiConcern[];
  isLoaded: boolean;
}

const initialState: GetConcernsReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const getConcernsSlice = createSlice({
  name: 'GetConcerns',
  initialState,
  reducers: {
    getConcernsRequest(state) {
      state.error = null;
      state.data = [];
      state.isLoaded = false;
    },
    getConcernsSuccess(state, action) {
      state.data = action.payload;
      state.isLoaded = true;
    },
    getConcernsFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    }
  }
});

export const { getConcernsRequest, getConcernsSuccess, getConcernsFailed } =
  getConcernsSlice.actions;

export default getConcernsSlice.reducer;

export const getConcerns =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getConcernsRequest());
      const response: ApiConcern[] = await api.getConcerns();
      dispatch(getConcernsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getConcernsFailed(error));
    }
  };
