import { FC, useEffect, useState } from 'react';
import { RootState } from 'js/store/reducer';

import FilterOrderSearchResult from './components/FilterOrderSearchResult';
import OrderSearchResultTable from './components/OrderSearchResultTable';
import InfoBox from 'js/components/InfoBox';

import { ApiOrderSimple } from 'src/interfaces/interfaces.generated';
import t from 'js/utils/translate';
import { useAppSelector } from 'js/utils/hooks';
import { Amplitude } from 'js/scenes/Home/scenes/OrderSearch/amplitude';
import * as amplitude from '@amplitude/analytics-browser';

const OrderSearchResult: FC = () => {
  const orders: ApiOrderSimple[] = useAppSelector(
    (state: RootState) => state?.home?.orderSearch?.data
  );
  const ordersLoaded: boolean = useAppSelector(
    (state: RootState) => state?.home?.orderSearch?.isLoaded
  );
  const searchError = useAppSelector((state: RootState) => state?.home?.orderSearch?.error);

  const [filteredOrders, setFilteredOrders] = useState<ApiOrderSimple[]>([]);

  useEffect(() => {
    if (ordersLoaded && orders) {
      amplitude.track(Amplitude.SEARCH_RESULTS_FOUND, {
        [Amplitude.SEARCH_RESULTS_FOUND__COUNT]: orders.length
      });
    }
  }, [orders, ordersLoaded]);

  if (searchError) {
    return (
      <InfoBox
        text={
          searchError?.response?.key
            ? t(`orderSearch.error.${searchError.response.key}`)
            : t('form.error.oops.something.went.wrong')
        }
      />
    );
  }

  if (!ordersLoaded) return <></>;

  if (orders.length === 0) return <InfoBox text={t('orderSearch.result.empty')} />;

  return (
    <div className='order-search-result'>
      <FilterOrderSearchResult orders={orders} setFilteredOrders={setFilteredOrders} />
      <OrderSearchResultTable orders={filteredOrders} />
    </div>
  );
};

export default OrderSearchResult;
