import { FC } from 'react';
import classNames from 'classnames';

import Icon from 'js/components/Icon';
import Error from './components/Error';

interface Props {
  className?: string;
  text: string;
  loading?: boolean;
  error?: boolean;
  onClick: () => void;
  disabled?: boolean;
}

const LinkV2: FC<Props> = ({ className, text, loading, error, onClick, disabled }) => {
  return (
    <div className={classNames(className)}>
      {loading ? (
        <Icon icon='spin' className='fa-spinner' />
      ) : (
        <div>
          <div
            className={classNames('link', { 'link--disabled': disabled })}
            onClick={disabled ? undefined : onClick}
          >
            {text}
          </div>
          {error && <Error />}
        </div>
      )}
    </div>
  );
};

export default LinkV2;
