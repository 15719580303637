import { FC } from 'react';
import PageWrapper from 'js/components/PageWrapper';
import UserForm from 'js/scenes/UserAdministration/UserActions/Connect/UserForm';

const ConnectUser: FC = () => {
  return (
    <PageWrapper>
      <UserForm />
    </PageWrapper>
  );
};

export default ConnectUser;
