import Ajax from 'js/utils/ajax/ajax.js';
import { ApiFlowShop, ApiFlowShopSimple, ApiUser } from 'src/interfaces/interfaces.generated';

export const getShops = () => Ajax.get('/shops/v1');

export const editUser = (userId: number, user: ApiUser) =>
  Ajax.patch(`/useradmin/v1/users/${userId}`, user);

export const giveShopAccess = (shopId: number, user: ApiUser) =>
  Ajax.post(`/useradmin/v1/user/shops/${shopId}`, user);

export const getAuthUsers = () => Ajax.get('/useradmin/v1/users');

export const newShop = (shop: ApiFlowShop): Promise<ApiFlowShopSimple> =>
  Ajax.post('/shops/v1', shop);
