import { FC, useState } from 'react';
import { addHours } from 'date-fns';

import { updateSystemInfo } from 'js/scenes/Settings/components/SystemMessages/store';
import FormV2 from 'js/components/FormV2';
import Spinner from 'js/components/Spinner';
import { SystemMessagesCollapse } from './components/SystemMessagesCollapse';

import { RootState } from 'js/store/reducer';

import { SystemInfo } from 'js/scenes/Settings/components/SystemMessages/interfaces';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

export const SystemMessages: FC = () => {
  const [editingSystemMessageIndex, setEditingSystemMessageIndex] = useState<number | any>(
    undefined
  );

  const dispatch = useAppDispatch();

  const systemMessages: SystemInfo[] = useAppSelector((state: RootState) => state.systemInfo.data);
  const systemMessagesLoaded = useAppSelector((state: RootState) => state.systemInfo.isLoaded);
  const error = useAppSelector((state: RootState) => state.systemInfo.error);

  const buildSubmit = ({ dates, ...rest }) => ({
    fromDate: dates?.fromDate ? addHours(dates.fromDate, 1) : undefined,
    toDate: dates?.toDate ? addHours(dates.toDate, 1) : undefined,
    ...rest
  });

  const handleUpdateSystemInfo = ({ systemInfo }) => {
    dispatch(updateSystemInfo(buildSubmit(systemInfo[editingSystemMessageIndex]))).then(
      (response: boolean | undefined) => {
        if (response) setEditingSystemMessageIndex(undefined);
      }
    );
  };

  const newMessageIndex = systemMessages.length;

  const convertDateFormats = (systemMessages: SystemInfo[]) =>
    systemMessages.map(({ fromDate, toDate, importantFromDate, ...rest }) => ({
      dates: {
        fromDate: new Date(fromDate),
        toDate: new Date(toDate)
      },
      importantFromDate: importantFromDate ? new Date(importantFromDate) : undefined,
      ...rest
    }));

  if (!systemMessagesLoaded) return <Spinner />;

  return (
    <FormV2
      initialValues={{ systemInfo: convertDateFormats(systemMessages) }}
      onSubmit={handleUpdateSystemInfo}
      actions={false}
      error={error}
    >
      <SystemMessagesCollapse
        systemMessages={systemMessages}
        editingSystemMessageIndex={editingSystemMessageIndex}
        newMessageIndex={newMessageIndex}
        setEditingSystemMessageIndex={setEditingSystemMessageIndex}
      />
    </FormV2>
  );
};
