import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import FormV2 from 'js/components/FormV2';
import Input from 'js/components/Form/components/Input';
import SplitPage from 'js/components/SplitPage';
import PageWrapper from 'js/components/PageWrapper';
import MessageForUser from 'js/components/MessageForUser';
import ColoredSection from 'js/components/ColoredSection';

import { createShop } from 'js/scenes/Freight/store/shopsReducer';
import { ApiFlowShopSimple } from 'src/interfaces/interfaces.generated';

import t, { tu } from 'js/utils/translate';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate } from 'react-router-dom';
import ZipCode from 'js/scenes/Freight/scenes/Shops/scenes/NewShop/components/ZipCode';
import Toggle from 'js/components/Form/components/Toggle';
import Label from 'js/components/Label';

const NewShop: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    await dispatch(createShop(values)).then((response: ApiFlowShopSimple | undefined) => {
      if (response) {
        navigate(`/freight/shop/${response.shopId}`);
      }
    });
  };

  const savingShop = useAppSelector((state: RootState) => state?.freight?.shops?.isSaving);
  const savingShopError = useAppSelector((state: RootState) => state?.freight?.shops?.error);

  return (
    <SplitPage>
      <PageWrapper className='new-shop-page'>
        <FormV2
          className='new-shop-form'
          onSubmit={handleSubmit}
          error={savingShopError}
          type='create'
          redirectTo='/freight'
          loading={savingShop}
        >
          <Input className='shop-name' name='name' label={tu('general.name')} />
          <ColoredSection label={tu('new.shop.warehouse')} className='shop-address'>
            <ZipCode />
            <Input name='warehouseCity' className='postal-city' label={tu('general.postalCity')} />
            <Input
              className='warehouse-country'
              name='warehouseCountry'
              label={tu('general.countryCode')}
              maxLength={2}
            />
          </ColoredSection>
          <div className='customer-system-settings'>
            <Label label={tu('freight.shop.new.customerSystem')} />
            <Toggle
              className='toggle'
              name='isAddressCollector'
              label={t('freight.shop.new.customerSystem.addressCollector.label')}
            />
          </div>
        </FormV2>
      </PageWrapper>
      <PageWrapper>
        <MessageForUser variant='info' message={t('freight.shop.new.info')} />
      </PageWrapper>
    </SplitPage>
  );
};

export default NewShop;
