import FormV2 from 'js/components/FormV2';
import PageWrapper from 'js/components/PageWrapper';
import MessageForUser from 'js/components/MessageForUser';
import SplitPage from 'js/components/SplitPage';

import { removeShop } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/shopReducer';

import t from 'js/utils/translate';

import { RootState } from 'js/store/reducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate, useParams } from 'react-router-dom';

const RemoveShop = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shopId } = useParams();

  const removingShop = useAppSelector((state: RootState) => state.freight.shop.shop.removing);
  const removeShopError = useAppSelector((state: RootState) => state.freight.shop.shop.error);

  const handleRemoveShop = async () =>
    await (shopId &&
      dispatch(removeShop(+shopId)).then((response) => {
        if (response) navigate('/freight');
      }));

  return (
    <SplitPage className='remove-shop-page'>
      <PageWrapper>
        <FormV2
          submitButtonVariant='danger'
          onSubmit={handleRemoveShop}
          type='remove'
          loading={removingShop}
          redirectTo={`/freight/shop/${shopId}`}
          error={removeShopError}
        >
          <MessageForUser variant='warning' message={t('shop.remove.info')} />
        </FormV2>
      </PageWrapper>
    </SplitPage>
  );
};

export default RemoveShop;
