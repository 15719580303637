import classNames from 'classnames';
import { useFormContext } from '@distribution-innovation/form-hooks';

import t from 'js/utils/translate';
import Toggle from 'js/components/Form/components/Toggle';
import { FC, useEffect } from 'react';
import { ShopProofOfDelivery } from 'interfaces/interfaces.generated';
// eslint-disable-next-line max-len
import SelectProofOfDelivery from 'js/scenes/Freight/scenes/FreightProducts/scenes/EditFreightProduct/components/SelectProofOfDelivery';

interface EditShopProofOfDeliveryRowProps {
  proofOfDelivery: ShopProofOfDelivery;
  isDesktop: boolean;
  index: number;
}

const EditShopProofOfDeliveryRow: FC<EditShopProofOfDeliveryRowProps> = ({
  proofOfDelivery,
  isDesktop,
  index
}) => {
  const { getValue } = useFormContext();
  const { setValue } = useFormContext();

  const toggleName = `shopProofOfDeliveryData[${index}]followFreightProductRule`;
  const selectName = `shopProofOfDeliveryData[${index}]shopProofOfDelivery`;

  const followFreightProductRuleToggle = getValue(toggleName);
  const dropdownSelectValue = getValue(selectName);

  useEffect(() => {
    if (!followFreightProductRuleToggle) {
      setValue(toggleName, followFreightProductRuleToggle);
    }

    if (followFreightProductRuleToggle) {
      setValue(selectName, proofOfDelivery.freightProductProofOfDelivery);
    }
  }, [followFreightProductRuleToggle]);

  return (
    <div className='shop-freight-product__row'>
      <div className={classNames({ 'mobile-header': !isDesktop })}>
        {proofOfDelivery?.freightProductName}
      </div>
      <div className={classNames({ 'mobile-header': !isDesktop })}>
        {t(
          'edit.freightProduct.proofOfDelivery.options.' +
            proofOfDelivery?.freightProductProofOfDelivery
        )}
      </div>
      <Toggle name={toggleName} />
      <SelectProofOfDelivery
        name={selectName}
        initialValue={dropdownSelectValue}
        disabled={followFreightProductRuleToggle}
      />
    </div>
  );
};

export default EditShopProofOfDeliveryRow;
