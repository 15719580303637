import PropTypes from 'prop-types';

import { content } from '../../utils';

const AddressLine1 = ({ streetName, streetNumber }) => {
  if (content(streetName) === '-') return '-';

  return (
    <div>
      <span> {streetName} </span>
      <span> {streetNumber} </span>
    </div>
  );
};

const AddressLine2 = ({ zipCode, city }) => {
  if (content(zipCode) === '-' && content(city) === '-') return '-';

  return (
    <div>
      <span> {content(zipCode)} </span>
      <span> {content(city).toUpperCase()} </span>
    </div>
  );
};

const Address = ({ data, accessorPrefix }) => {
  if (!data) return '-';

  return (
    <div>
      <AddressLine1
        streetName={data[`${accessorPrefix}StreetName`]}
        streetNumber={data[`${accessorPrefix}StreetNumber`]}
      />
      <AddressLine2
        zipCode={data[`${accessorPrefix}PostalCode`]}
        city={data[`${accessorPrefix}City`]}
      />
      <div> {content(data[`${accessorPrefix}Country`]).toUpperCase()} </div>
    </div>
  );
};

export default Address;

Address.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  accessorPrefix: PropTypes.string
};
