import { createSlice } from '@reduxjs/toolkit';

import { Job, JobHistoryEntry } from 'src/interfaces/interfaces.generated';

import * as api from '../../utils/rest';

export interface JobsReducerState {
  error: unknown;
  data: Job[];
  isLoaded: boolean;
  isSaving: boolean;
  currentJobHistory: JobHistoryEntry[];
  isHistoryLoaded: boolean;
}

const initialState: JobsReducerState = {
  error: null,
  data: [],
  isLoaded: false,
  isSaving: false,
  currentJobHistory: [],
  isHistoryLoaded: false
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    getJobsRequest(state) {
      state.isLoaded = false;
      state.error = null;
      state.data = [];
    },
    getJobsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getJobsFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    },
    getJobHistoryRequest(state) {
      state.isHistoryLoaded = false;
      state.error = null;
      state.currentJobHistory = [];
    },
    getJobHistorySuccess(state, action) {
      state.isHistoryLoaded = true;
      state.currentJobHistory = action.payload;
    },
    getJobHistoryFailed(state, action) {
      state.isHistoryLoaded = false;
      state.error = action.payload;
    },
    getJobRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getJobSuccess(state, action) {
      state.isLoaded = true;
      let existingData = false;
      state.data = state.data.map((job) => {
        if (job.jobId === action.payload.jobId) {
          existingData = true;
          return action.payload;
        } else {
          return job;
        }
      });
      if (!existingData) {
        state.data.push(action.payload);
      }
    },
    getJobFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    },
    resetJobError(state) {
      state.error = null;
    },
    editJobRequest(state) {
      state.isSaving = true;
    },
    editJobSuccess(state, action) {
      state.isSaving = false;
      state.data = state.data.map((job: Job) =>
        job.jobId === action.payload.jobId ? { ...job, ...action.payload } : { ...job }
      );
      state.error = null;
    },
    editJobFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    },
    activateJobScheduleRequest(state) {
      state.isSaving = true;
    },
    activateJobScheduleSuccess(state) {
      state.isSaving = false;
      state.error = null;
    },
    activateJobScheduleFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    },
    deactivateJobScheduleRequest(state) {
      state.isSaving = true;
    },
    deactivateAllJobsTemporaryRequest(state) {
      state.isSaving = true;
    },
    activateTemporaryDeactivatedJobsRequest(state) {
      state.isSaving = true;
    },
    deactivateJobScheduleSuccess(state) {
      state.isSaving = false;
      state.error = null;
    },
    activateTemporaryDeactivatedJobsSuccess(state) {
      state.isSaving = false;
      state.error = null;
    },
    deactivateAllJobsTemporarySuccess(state) {
      state.isSaving = false;
      state.error = null;
    },
    deactivateJobScheduleFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    },
    deactivateAllJobsTemporaryFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    },
    activateTemporaryDeactivatedJobsFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    },
    triggerJobExecutionRequest(state) {
      state.isSaving = true;
    },
    triggerJobExecutionSuccess(state) {
      state.isSaving = false;
      state.error = null;
    },
    triggerJobExecutionFailed(state, action) {
      state.isSaving = false;
      state.error = action.payload;
    }
  }
});

export const {
  getJobsRequest,
  getJobsSuccess,
  getJobsFailed,
  getJobRequest,
  getJobSuccess,
  getJobFailed,
  getJobHistoryRequest,
  getJobHistorySuccess,
  getJobHistoryFailed,
  editJobRequest,
  editJobSuccess,
  editJobFailed,
  resetJobError,
  activateJobScheduleRequest,
  activateJobScheduleSuccess,
  deactivateAllJobsTemporarySuccess,
  activateJobScheduleFailed,
  deactivateAllJobsTemporaryFailed,
  deactivateJobScheduleRequest,
  deactivateAllJobsTemporaryRequest,
  deactivateJobScheduleSuccess,
  deactivateJobScheduleFailed,
  activateTemporaryDeactivatedJobsRequest,
  activateTemporaryDeactivatedJobsSuccess,
  activateTemporaryDeactivatedJobsFailed,
  triggerJobExecutionRequest,
  triggerJobExecutionSuccess,
  triggerJobExecutionFailed
} = jobsSlice.actions;

export default jobsSlice.reducer;

export const getJobs =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getJobsRequest());
      const response: Job[] = await api.getJobs();
      dispatch(getJobsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getJobsFailed(error));
    }
  };

export const getJob =
  (jobId) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getJobRequest());
      const response: Job = await api.getJob(jobId);
      dispatch(getJobSuccess(response));
      return true;
    } catch (error) {
      dispatch(getJobFailed(error));
    }
  };

export const getJobHistory =
  (jobId) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getJobHistoryRequest());
      const response: JobHistoryEntry[] = await api.getJobHistory(jobId);
      dispatch(getJobHistorySuccess(response));
      return true;
    } catch (error) {
      dispatch(getJobHistoryFailed(error));
    }
  };

export const editJob =
  (jobId) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(editJobRequest());
      const response: Job = await api.editJob(jobId);
      dispatch(editJobSuccess(response));
      return true;
    } catch (error) {
      dispatch(editJobFailed(error));
    }
  };

export const activateJobSchedule =
  (job) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(activateJobScheduleRequest());
      await api.activateJobSchedule(job);
      dispatch(activateJobScheduleSuccess());
      return true;
    } catch (error) {
      dispatch(activateJobScheduleFailed(error));
    }
  };

export const deactivateJobSchedule =
  (job) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(deactivateJobScheduleRequest());
      await api.deactivateJobSchedule(job);
      dispatch(deactivateJobScheduleSuccess());
      return true;
    } catch (error) {
      dispatch(deactivateJobScheduleFailed(error));
    }
  };

export const deactivateAllJobsTemporary =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(deactivateAllJobsTemporaryRequest());
      await api.deactivateAllJobsTemporary();
      dispatch(deactivateAllJobsTemporarySuccess());
      return true;
    } catch (error) {
      dispatch(deactivateAllJobsTemporaryFailed(error));
    }
  };

export const activateTemporaryDeactivatedJobs =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(activateTemporaryDeactivatedJobsRequest());
      await api.activateTemporaryDeactivatedJobs();
      dispatch(activateTemporaryDeactivatedJobsSuccess());
      return true;
    } catch (error) {
      dispatch(activateTemporaryDeactivatedJobsFailed(error));
    }
  };

export const triggerJobExecution =
  (job) =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(triggerJobExecutionRequest());
      await api.triggerJobExecution(job);
      dispatch(triggerJobExecutionSuccess());
      return true;
    } catch (error) {
      dispatch(triggerJobExecutionFailed(error));
    }
  };
