import './index.sass';

import { Link } from 'react-router-dom';

import Icon from 'js/components/Icon';

import t from 'js/utils/translate';

const SettingsButton = () => (
  <div className='settings-button'>
    <Link to='/settings'>
      <Icon icon='cog' size='1x' />
      <div className='settings-button__text'>{t('settings')}</div>
    </Link>
  </div>
);

export default SettingsButton;
