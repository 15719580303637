import { AnyAction, createSlice, Dispatch } from '@reduxjs/toolkit';

export interface MediaDeviceReducerState {
  isDesktop: boolean;
}

const initialState: MediaDeviceReducerState = {
  isDesktop: window.innerWidth > 1100
};

const mediaDeviceSlice = createSlice({
  name: 'MediaDevice',
  initialState,
  reducers: {
    setIsDesktop(state, action) {
      state.isDesktop = action.payload;
    }
  }
});

export const { setIsDesktop } = mediaDeviceSlice.actions;

export const setMediaDevice =
  (isDesktop: boolean) =>
  (dispatch: Dispatch): AnyAction =>
    dispatch(setIsDesktop(isDesktop));

export default mediaDeviceSlice.reducer;
