import { FC, Fragment, useEffect } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';
import { RootState } from 'js/store/reducer';

import Toggle from 'js/components/Form/components/Toggle';
import ASB from './components/ASB';

import t from 'js/utils/translate';
import { getContractServices } from '../../../../store/contractServicesReducer';
import { ApiContractService } from 'src/interfaces/interfaces.generated';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const AdditionalService: FC = () => {
  const dispatch = useAppDispatch();
  const context = useFormContext();
  const asbActive = context.getValue('ASB');
  const rnlActive = context.getValue('RNL');

  const contractServices: ApiContractService[] = useAppSelector(
    (state: RootState) => state?.freight?.shop?.contractServices?.data
  );
  const contractServicesLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.shop?.contractServices?.isLoaded
  );

  useEffect(() => {
    if (!contractServicesLoaded) dispatch(getContractServices());
  }, []);

  return (
    <div className='additional-service'>
      {contractServices.map((contractService) => (
        <Fragment key={contractService.name}>
          <Toggle
            name={contractService.code!}
            label={t(`shop.accordion.config.${contractService.code}`)}
          />
          {contractService.code === 'ASB' && asbActive && <ASB asbActive={asbActive} />}
          {contractService.code === 'RNL' && rnlActive && (
            <Toggle
              className='rnl-default-on-toggle'
              name='RNLdefaultOn'
              label={t('shop.accordion.config.RNL.option.label')}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default AdditionalService;
