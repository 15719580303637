import { FC, useRef } from 'react';
import en from 'date-fns/locale/en-US';
import nb from 'date-fns/locale/nb';
import sv from 'date-fns/locale/sv';
import DatePicker, { registerLocale } from 'react-datepicker';
import CustomInput from './components/CustomInput';

import './index.sass';
import { language } from 'js/utils/translate';

registerLocale('no', nb);
registerLocale('en', en);
registerLocale('sv', sv);

interface Props {
  date?: Date;
  setDate;
  minDate?: Date;
  maxDate?: Date;
  handleBlur?: () => void;
  disabled?: boolean;
  isClearable?: boolean;
  showTimeInput?: boolean;
  error?: boolean;
  popperPlacement?: 'bottom-start' | 'bottom-end';
}

const Datepicker: FC<Props> = ({
  date,
  setDate,
  minDate,
  maxDate,
  handleBlur,
  disabled,
  isClearable,
  showTimeInput,
  error,
  popperPlacement = 'bottom-start'
}) => {
  const ref = useRef(null);

  return (
    <DatePicker
      selected={date}
      onChange={(date: Date | undefined) => setDate(date)}
      dateFormat={showTimeInput ? 'dd.MM.yyyy HH:mm:ss' : 'dd.MM.yyyy'}
      locale={language?.toLowerCase()}
      minDate={minDate}
      maxDate={maxDate}
      customInput={
        <CustomInput ref={ref} disabledStyling={disabled} handleBlur={handleBlur} error={error} />
      }
      isClearable={isClearable && !disabled}
      disabled={disabled}
      showTimeInput={showTimeInput}
      injectTimes={!showTimeInput && date && [date.setHours(12)]}
      shouldCloseOnSelect={!showTimeInput}
      popperPlacement={popperPlacement}
    />
  );
};

export default Datepicker;
