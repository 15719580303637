import { createSlice } from '@reduxjs/toolkit';
import { ApiBudBuddyPod } from 'src/interfaces/interfaces.generated';
import * as api from '../../api';

export interface BudBuddyImagesReducerState {
  error: unknown;
  data: ApiBudBuddyPod[];
  isLoaded: boolean;
}

const initialState: BudBuddyImagesReducerState = {
  error: null,
  data: [],
  isLoaded: false
};

const budBuddyImagesSlice = createSlice({
  name: 'BudBuddyImages',
  initialState,
  reducers: {
    getBudBuddyImagesRequest(state) {
      state.isLoaded = false;
      state.error = null;
    },
    getBudBuddyImagesSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getBudBuddyImagesFailed(state, action) {
      state.isLoaded = false;
      state.error = action.payload;
    }
  }
});

export const { getBudBuddyImagesRequest, getBudBuddyImagesSuccess, getBudBuddyImagesFailed } =
  budBuddyImagesSlice.actions;

export default budBuddyImagesSlice.reducer;

export const getBudBuddyImages = (trackingReference: string) => async (dispatch) => {
  try {
    dispatch(getBudBuddyImagesRequest());
    const response: ApiBudBuddyPod[] = await api.getBudBuddyImages(trackingReference);
    dispatch(getBudBuddyImagesSuccess(response));
  } catch (error) {
    dispatch(getBudBuddyImagesFailed(error));
  }
};
