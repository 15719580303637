import { FC } from 'react';
import t from 'js/utils/translate';
import { useFormContext } from '@distribution-innovation/form-hooks';
import Input from 'js/components/Form/components/Input';
import UsernameInput from 'js/scenes/UserAdministration/UserActions/CreateAndEdit/UsernameInput';
import SelectV2 from 'js/components/Form/components/SelectV2';
import {
  roleOptions,
  shopOptions,
  webServiceOptions
} from 'js/scenes/UserAdministration/utils/utils';
import RadioGroup from 'js/components/RadioGroup';
import { UserType } from 'js/scenes/UserAdministration/Users.types';
import { UserFormProps } from 'js/scenes/UserAdministration/UserActions/CreateAndEdit/UserForm.types';
import DisconnectUserModal from 'js/scenes/UserAdministration/UserActions/Disconnect/DisconnectUser.modal';
import { Amplitude } from 'js/scenes/UserAdministration/utils/amplitude';

const UserFormView: FC<UserFormProps> = ({ availableUserOptions, isEditMode, setUserType }) => {
  const context = useFormContext();

  const userType = context?.getValue('type');

  // Reset values that are different between the 2 user types
  function resetValues() {
    context?.setValue('webServices', []);
    context?.setValue('roles', []);
    context?.setValue('firstName', '');
    context?.setValue('lastName', '');
  }

  const handleChange = (value: UserType) => {
    context?.setValue('type', value);
    setUserType(value);
    resetValues();
  };

  return (
    <div className='user-form'>
      {isEditMode ? (
        <DisconnectUserModal />
      ) : (
        <RadioGroup
          name='type'
          label={t('userAdministration.userType')}
          onChange={(e) => {
            handleChange(e.target.value as UserType);
          }}
          radios={[
            {
              label: t('userAdministration.flow.tabName'),
              value: 'flow',
              checked: userType === 'flow'
            },
            {
              label: t('userAdministration.api.tabName'),
              value: 'api',
              checked: userType === 'api'
            }
          ]}
        />
      )}
      <div className='user-form'>
        {userType === 'flow' && (
          <>
            <Input
              disabled={isEditMode}
              hideOptional
              name='firstName'
              label={t('general.firstName')}
            />
            <Input
              disabled={isEditMode}
              hideOptional
              name='lastName'
              label={t('general.lastName')}
            />
          </>
        )}
        <UsernameInput isEditMode={isEditMode} />
        <Input
          disabled={isEditMode}
          hideOptional
          name='email'
          type='email'
          label={t('general.email')}
        />
        <SelectV2
          name='shops'
          isLoading={availableUserOptions.isLoading}
          options={shopOptions(availableUserOptions.data?.shops)}
          label={t('general.shops')}
          isMulti
          selectAll
          optional
          onChangeTrack={
            isEditMode &&
            Amplitude.getMetricParameters(Amplitude.USER_EDIT_FIELD, userType, 'shops')
          }
          onSelectAllTrack={
            isEditMode &&
            Amplitude.getMetricParameters(Amplitude.USER_EDIT_FIELD, userType, 'shops: select all')
          }
        />
        <SelectV2
          name='roles'
          isLoading={availableUserOptions.isLoading}
          options={roleOptions(availableUserOptions.data?.roles)}
          label={t('general.roles')}
          isMulti
          selectAll
          optional
          onChangeTrack={
            isEditMode &&
            Amplitude.getMetricParameters(Amplitude.USER_EDIT_FIELD, userType, 'roles')
          }
          onSelectAllTrack={
            isEditMode &&
            Amplitude.getMetricParameters(Amplitude.USER_EDIT_FIELD, userType, 'roles: select all')
          }
        />
        {userType === 'api' && (
          <SelectV2
            name='webServices'
            isLoading={availableUserOptions.isLoading}
            options={webServiceOptions(availableUserOptions.data?.webServices)}
            label={t('general.apiAccess')}
            isMulti
            selectAll
            optional
            onChangeTrack={
              isEditMode &&
              Amplitude.getMetricParameters(Amplitude.USER_EDIT_FIELD, userType, 'web services')
            }
            onSelectAllTrack={
              isEditMode &&
              Amplitude.getMetricParameters(
                Amplitude.USER_EDIT_FIELD,
                userType,
                'web services: select all'
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default UserFormView;
