import { isAfter, differenceInDays } from 'date-fns';

export const fromDateIsAfterToDate = (fromDate: Date, toDate: Date): boolean =>
  fromDate && toDate && isAfter(fromDate, toDate);

export const selectedIsOutsideInterval = (
  fromDate: Date,
  toDate: Date,
  interval: number | undefined
): boolean => {
  if (fromDate && toDate && interval) {
    return Math.abs(differenceInDays(fromDate, toDate)) > interval;
  } else return false;
};

export const requiredAndMissing = (
  fromDate: Date,
  toDate: Date,
  optional: boolean | undefined
): boolean => !optional && !(fromDate && toDate);
