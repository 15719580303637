import { FC } from 'react';
import { useFormContext } from '@distribution-innovation/form-hooks';
import { RootState } from 'js/store/reducer';

import ButtonV2 from 'js/components/ButtonV2';

import t from 'js/utils/translate';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';

const LoginButton: FC = () => {
  const context = useFormContext();

  const isLoading = useAppSelector((state: RootState) => state.authentication.isLoading);

  return (
    <ButtonV2
      className='login-button'
      text={t('general.login')}
      name={t('general.login')}
      submit
      loading={isLoading}
      disabled={!context?.isValid}
    />
  );
};

export default LoginButton;
