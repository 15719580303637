import Ajax from 'js/utils/ajax/ajax.js';
import {
  ApiFlowShop,
  ApiUser,
  ApiWebservice,
  PatchedShop,
  ApiCustomerSystem
} from 'src/interfaces/interfaces.generated';

export const getShop = (shopId: number): Promise<ApiFlowShop> => Ajax.get(`/shops/v1/${shopId}`);

export const removeShop = (shopId: number): Promise<boolean> => Ajax.delete(`/shops/v1/${shopId}`);

export const getShopUsers = (shopId: number): Promise<ApiUser[]> =>
  Ajax.get(`/shops/v1/${shopId}/users`);

export const getWSUsers = (): Promise<ApiUser[]> => Ajax.get('/useradmin/v1/wsusers');

export const getWebservices = (): Promise<ApiWebservice[]> => Ajax.get('/webservices/v1');

export const updateShopv2 = (data, shopId: number): Promise<PatchedShop> =>
  Ajax.jsonPatch(`/shops/v2/${shopId}`, data);

export const getCustomerSystems = (): Promise<ApiCustomerSystem[]> =>
  Ajax.get('/customersystems/v1');

export const getShopFreightProducts = (shopId) =>
  Ajax.get(`/shops/v2/${shopId}/freightproductproperties`);

export const editShopFreightProducts = (shopId, freightProducts) =>
  Ajax.patch(`/shops/v2/${shopId}/freightproductproperties`, freightProducts);

export const getShopTransportSolutions = (shopId) =>
  Ajax.get(`/shops/v1/${shopId}/transportsolutions`);

export const editShopTransportSolutions = (shopId, shopTransportSolutions) =>
  Ajax.patch(`/shops/v1/${shopId}/transportsolutions`, shopTransportSolutions);

export const getCustomerCommunication = (shopId) =>
  Ajax.get(`/shops/v2/${shopId}/customermessages`);

export const editCustomerCommunication = (shopId, customerCommunication) =>
  Ajax.patch(`/shops/v2/${shopId}/customermessages`, customerCommunication);

export const getShopProofOfDelivery = (shopId) =>
  Ajax.get(`/shops/v2/${shopId}/proofofdeliveryproperties`);

export const editShopProofOfDelivery = (shopId, proofOfDelivery) =>
  Ajax.patch(`/shops/v2/${shopId}/proofofdeliveryproperties`, proofOfDelivery);
