import t from 'js/utils/translate';
import './index.sass';

const Error = ({ error }) => {
  const message = (error?.response?.message || error?.response?.key)?.toLowerCase();

  return (
    <div className='form-message form-error'>
      <div>{t('form.error')}</div>
      <div className='message'>
        {message ? t(`form.error.${message}`) : t('form.error.oops.something.went.wrong')}
      </div>
    </div>
  );
};

export default Error;
