import { FC, useEffect } from 'react';

import Select from 'js/components/Select';
import { RootState } from 'js/store/reducer';

import t, { tu } from 'js/utils/translate';
import { userOptions } from 'js/components/Select/utils';
import { findNotSelectedUsers } from '../../utils';
import { findServiceSupplierById, findUserById } from 'js/utils/find';
import { getServiceSuppliers } from 'js/scenes/Freight/scenes/ServiceSuppliers/store/serviceSuppliersReducer';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useParams } from 'react-router-dom';

interface Props {
  authUsers;
  setSelectedUser;
}

const SelectServiceSupplierUser: FC<Props> = ({ authUsers, setSelectedUser }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const serviceSupplierId = params?.serviceSupplierId ?? false;

  const serviceSuppliers = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSuppliers?.data
  );
  const serviceSuppliersLoaded = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSuppliers?.isLoaded
  );

  useEffect(() => {
    if (!serviceSuppliersLoaded) dispatch(getServiceSuppliers());
  }, [serviceSuppliersLoaded]);

  const users = findNotSelectedUsers(
    authUsers,
    findServiceSupplierById(serviceSuppliers, serviceSupplierId)?.users
  );

  const isLoading = !users;

  const handleSelectChange = (e) => {
    if (users) setSelectedUser(e.value ? findUserById(users, e.value) : {});
  };

  return (
    <Select
      className='select-connect-user'
      label={tu('general.username')}
      placeholder={
        isLoading ? '' : t('freight.shop.users.connect.select.placeholder.user', t('general.user'))
      }
      // filterOption={customUserFilter} // TODO implementer for ikke-form-select
      isLoading={isLoading}
      options={userOptions(users)}
      onChange={(e) => {
        handleSelectChange(e);
      }}
    />
  );
};

export default SelectServiceSupplierUser;
