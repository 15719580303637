import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, withError } from '@distribution-innovation/form-hooks';
import classNames from 'classnames';

import Input from '../Input';
import Label from 'js/components/Label';
import InputError from '../Validation/InputError';

import t from 'js/utils/translate';
import { extractFilename } from './utils';

import './index.sass';
import { useAppSelector } from 'js/utils/hooks';

const FileInput = ({ setFile, name, label, info, optional, accept, className, error }) => {
  const isDesktop = useAppSelector((state) => state?.mediaDevice?.isDesktop);

  const [filename, setFilename] = useState();

  const context = useFormContext();

  const handleBlur = () => context.getTouched(name) || context.setTouched(name);

  const handleChange = (event) => {
    if (event.target.files.length === 1) setFile(event.target.files[0]);
    setFilename(extractFilename(event?.target?.value));
  };

  return (
    <div className='file-input-wrapper'>
      {label && <Label label={label} info={info} optional={optional} />}
      <div className='file-input'>
        <div
          className={classNames('file-input__text-field', className, {
            'file-input__text-field--invalid': error
          })}
        >
          {filename && `${filename}`}
        </div>
        <label className='file-input__button' htmlFor='file-input'>
          {/* {btnText ? btnText : t('file.input.browse')} */}
          {isDesktop ? t('file.input.browse') : '...'}
        </label>
        <Input
          name={name}
          id='file-input'
          type='file'
          required={!optional}
          accept={accept}
          onChange={handleChange}
          onBlur={handleBlur}
          error={false}
        />
      </div>
      {error && <InputError error={error} />}
    </div>
  );
};

export default withError()(FileInput);

FileInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  info: PropTypes.string,
  optional: PropTypes.bool,
  accept: PropTypes.string
};
