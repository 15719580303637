import { FC } from 'react';
import classNames from 'classnames';

import Datepicker from 'js/components/Datepicker';
import Label from 'js/components/Label';

import { RootState } from 'js/store/reducer';

import { findMaxDate } from './utils';
import { tu } from 'js/utils/translate';
import { useAppSelector } from 'js/utils/hooks';

import './index.sass';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  align?: 'row' | 'column';
  format?: string;
  fromDate?: Date;
  setFromDate;
  toDate?: Date;
  setToDate;
  minDate?: Date;
  maxDate?: Date;
  handleBlur?: () => void;
  interval?: number;
  disabled?: boolean;
  showTimeInput?: boolean;
  error?: boolean;
}

const DatepickerRange: FC<Props> = ({
  className,
  align = 'row',
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  minDate,
  maxDate,
  handleBlur,
  interval,
  disabled,
  showTimeInput,
  error
}) => {
  const isDesktop = useAppSelector((state: RootState) => state?.mediaDevice?.isDesktop);

  useNavigate();

  return (
    <div className={classNames('range-datepicker', `range-datepicker--${align}`, className)}>
      <div>
        <Label label={tu('general.fromDate')} />
        <Datepicker
          date={fromDate}
          setDate={setFromDate}
          minDate={minDate}
          maxDate={maxDate}
          handleBlur={handleBlur}
          disabled={disabled}
          showTimeInput={showTimeInput}
          error={error}
        />
      </div>
      <div>
        <Label label={tu('general.toDate')} />
        <Datepicker
          date={toDate}
          setDate={setToDate}
          minDate={fromDate ?? minDate}
          maxDate={findMaxDate(interval, fromDate, maxDate)}
          handleBlur={handleBlur}
          disabled={disabled}
          showTimeInput={showTimeInput}
          error={error}
          popperPlacement={!isDesktop && align === 'row' ? 'bottom-end' : 'bottom-start'}
        />
      </div>
    </div>
  );
};

export default DatepickerRange;
