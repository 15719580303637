import { FC } from 'react';

import classNames from 'classnames';
import t from 'js/utils/translate';

import './index.sass';

const errorMessage = (message, error, { min, max, minLength, maxLength, interval }) => {
  if (typeof message === 'function') return message(error);
  if (message) return message;

  switch (error) {
    case 'rangeOverflow':
      return t('input.error.rangeOverflow', max);
    case 'rangeUnderflow':
      return t('input.error.rangeUnderflow', min);
    case 'tooLong':
      return t('input.error.tooLong', maxLength);
    case 'tooShort':
      return t('input.error.tooShort', minLength);
    case 'valueMissing':
      return t('input.error.valueMissing');
    case 'outsideInterval':
      return t('input.error.outsideInterval', interval);
    case 'addressNotComplete':
      return t('input.error.addressNotComplete');
    case 'atLeastOne':
      return t('input.error.atLeastOne');
    case 'onlyPrecedingDatesAllowed':
      return t('input.error.onlyPrecedingDatesAllowed');
    case 'fromDateCannotBeAfterToDate':
      return t('input.error.fromDateCannotBeAfterToDate');
    case 'usernameUnavailable':
      return t('input.error.usernameUnavailable');
    case 'usernameInvalid':
      return t('input.error.usernameIllegal');
    default:
      return t('input.error.invalid');
  }
};

interface Props {
  className?: string;
  message?: string;
  error: any;
  min?: any;
  max?: any;
  minLength?: any;
  maxLength?: any;
  interval?: any;
}

const InputError: FC<Props> = ({
  className,
  message,
  error,
  min,
  max,
  minLength,
  maxLength,
  interval
}) =>
  error && message !== '' ? (
    <div className={classNames(className, 'input-error')}>
      {errorMessage(message, error, {
        min,
        max,
        minLength,
        maxLength,
        interval
      })}
    </div>
  ) : (
    <></>
  );

export default InputError;
