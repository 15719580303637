import { createSlice } from '@reduxjs/toolkit';
import { locationChanged } from 'js/store/actions';

import { ApiFlowShop, ApiFlowShopSimple } from 'src/interfaces/interfaces.generated';

import * as api from '../../api';

export interface ShopsReducerState {
  error: unknown;
  isLoaded: boolean;
  isSaving: boolean;
  data: ApiFlowShopSimple[];
}

const initialState: ShopsReducerState = {
  error: null,
  isLoaded: false,
  isSaving: false,
  data: []
};

const shopsSlice = createSlice({
  name: 'Shops',
  initialState,
  reducers: {
    getShopsRequest(state) {
      state.error = null;
      state.isLoaded = false;
      state.data = [];
    },
    getShopsSuccess(state, action) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    getShopsFailed(state, action) {
      state.error = action.payload;
      state.isLoaded = false;
    },
    createShopRequest(state) {
      state.error = null;
      state.isSaving = true;
    },
    createShopSuccess(state, action) {
      state.isSaving = false;
      state.data.push(action.payload);
    },
    createShopFailed(state, action) {
      state.error = action.payload;
      state.isSaving = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const {
  getShopsRequest,
  getShopsSuccess,
  getShopsFailed,
  createShopRequest,
  createShopSuccess,
  createShopFailed
} = shopsSlice.actions;

export default shopsSlice.reducer;

export const getShops =
  () =>
  async (dispatch): Promise<boolean | undefined> => {
    try {
      dispatch(getShopsRequest());
      const response: ApiFlowShopSimple[] = await api.getShops();
      dispatch(getShopsSuccess(response));
      return true;
    } catch (error) {
      dispatch(getShopsFailed(error));
    }
  };

export const createShop =
  (shop: ApiFlowShop) =>
  async (dispatch): Promise<ApiFlowShopSimple | undefined> => {
    try {
      dispatch(createShopRequest());
      const response: ApiFlowShopSimple = await api.newShop(shop);
      dispatch(createShopSuccess(response));
      return response;
    } catch (error) {
      dispatch(createShopFailed(error));
    }
  };
